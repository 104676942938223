import { SERVICE } from './constants'

export const UPLOAD_INVOICE = `${SERVICE}/UPLOAD_INVOICE`
export const UPLOAD_INVOICE_START = `${SERVICE}/UPLOAD_INVOICE_START`
export const UPLOAD_INVOICE_SUCCESS = `${SERVICE}/UPLOAD_INVOICE_SUCCESS`
export const UPLOAD_INVOICE_ERROR = `${SERVICE}/UPLOAD_INVOICE_ERROR`
export const UPLOAD_INVOICE_CLEAR = `${SERVICE}/UPLOAD_INVOICE_CLEAR`

export const SET_INVOICE_DATA = `${SERVICE}/SET_INVOICE_DATA`
export const CLEAR_INVOICE_DATA = `${SERVICE}/CLEAR_INVOICE_DATA`
