import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  price: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(20),
  },
  priceNumber: {
    display: 'flex',
    alignItems: 'baseline',
    '& .MuiTypography-root + .MuiTypography-root': {
      marginLeft: theme.spacing(0.5),
    },
  },
  prices: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export default useStyles
