import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import Link from 'ui/components/link'
import { setIsNewSupply } from 'services/customer/actions'
import { getIsOnlineChannel } from 'services/operator/selectors'
import { getRouterQueries } from 'services/analytics/selectors'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { getCustomerData, getUserData } from 'services/customer/selectors'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'

const ConfirmNewSupply = ({
  handleClose,
  data: { completeSupplyPoint, supplyData, supplyModeService, step, onSubmit, tariffData },
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { address, service, technicalInfo } = supplyData

  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const queries = useSelector(getRouterQueries)
  const userData = useSelector(getUserData)
  const customerData = useSelector(getCustomerData)
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const documentType = userData?.documentType || customerData?.idDocumentType

  const showNewSupplyModal = () => {
    handleClose()
    dispatch(
      sendGAEvent(GA_EVENT_NAMES.openNewClientModal, { currentStep: STEPS.supply }),
      sendGAEvent(GA_EVENT_NAMES.checkNNSS, { currentStep: STEPS.supply })
    )
    if (!isEmptyObject(address)) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.newSupplyTechnical,
          modalData: {
            onSubmit,
            service,
            tariffData,
            technicalInfo,
            address,
          },
        }),
        sendGAEvent(GA_EVENT_NAMES.newSupplyTechModal, { currentStep: STEPS.supply })
      )
    } else {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.newSupplyAddress,
          modalData: {
            completeSupplyPoint,
            service,
            supplyModeService,
            addressCups: address,
            step,
            energyStep: service,
            userDocumentNumber,
            documentType,
          },
        })
      )
    }
  }

  const handleOnClickCancel = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.cancelNewSupply, { currentStep: STEPS.supply }))
    if (!isOnlineChannel || (isOnlineChannel && !queries?.nnss)) dispatch(setIsNewSupply(false))
    handleClose()
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('newSupplyPointExisting')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="basic">{t('newSupplyPointExistingConfirm')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Link component="button" onClick={handleOnClickCancel}>
          {t('common.cancel')}
        </Link>
        <Button color="secondary" variant="contained" onClick={showNewSupplyModal}>
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmNewSupply
