import React, { useEffect, useState } from 'react'
import Typography from '../components/typography'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'
import AddressForm from './address'
import { useDispatch, useSelector } from 'react-redux'
import { getCompany } from 'services/customer/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { COMPANY_CODES, STEPS } from 'utils/constants'
import * as NATLOC from 'natloc-component'
import { ELECTRICITY, NEWCO, PROVIDER_CODES } from '@zatopek/core/src/utils/constants'
import mapEmergyaAddress from '@zatopek/core/src/utils/mapEmergyaAddress'
import { mapAddressToCard, mapNewBillingAddress } from '@zatopek/core/src/utils/mapNewBillingAddress'
import { widgetTranslations } from '@zatopek/core/src/utils/widgetTranslations'
import { Alert } from '@material-ui/lab'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import BillingAddressCard from '@zatopek/core/src/ui/components/billingAddressCard'
import useCalculatorTabs from '@zatopek/core/src/hooks/useCalculatorTabs'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { getModalType } from 'services/modal/selectors'
import uuid from 'react-uuid'
import { useFormikContext } from 'formik'
import { EMERGYA_EVENTS_TYPES_RESTRICTED } from 'utils/constants'

const AddressWrapper = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    stateElectricity,
    dispatchElectricity,
    reducerTypes: { COMPLETE, TOGGLE_EDIT },
  } = useCalculatorTabs()
  const [newAddress, setNewAddress] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const { setFieldValue, values, setValues } = useFormikContext()

  const company = useSelector(getCompany)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const modalType = useSelector(getModalType)
  const selectedCompany = company || selectedProduct[0]?.company
  const { classes, errors } = props
  const fields =
    selectedCompany?.code === COMPANY_CODES.newco
      ? ['postalCode', 'city', 'province', 'streetType', 'street', 'number', 'portal', 'stair', 'floor']
      : ['postalCode', 'city', 'province', 'streetType', 'street', 'number', 'portal', 'stair', 'floor', 'door']
  const errorsInAddress = !isEmptyObject(errors) && fields.some((field) => Object.keys(errors).some((key) => key === field))
  const isActive = props?.isActive ?? true

  useEffect(() => {
    if (modalType === null && !values.postalCode) {
      setValues({ ...values, ...newAddress })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType])

  const completeSupplyPoint = (technicalInfo, address, calculatorInfo, handleClose) => {
    const { data } = address
    const mappedAddress = mapNewBillingAddress(data)
    const auxValuesObj = {
      postalCode: '',
      city: '',
      province: '',
      provinceCode: '',
      municipalityCode: '',
      streetType: '',
      street: '',
      number: '',
      portal: '',
      stair: '',
      floor: '',
      door: '',
      extraData: '',
    }
    Object.keys(values).forEach((valueKey) => {
      if (Object.hasOwn(mappedAddress, valueKey) && mappedAddress[valueKey] != null) {
        auxValuesObj[valueKey] = mappedAddress[valueKey]
      }
    })
    if (mappedAddress['municipalityCode']) {
      auxValuesObj['municipalityCode'] = mappedAddress['municipalityCode']
    }
    if (mappedAddress['provinceCode']) {
      auxValuesObj['provinceCode'] = mappedAddress['provinceCode']
    }
    setValues({ ...values, ...auxValuesObj })
    setNewAddress({ ...values, ...auxValuesObj })
    dispatchElectricity(COMPLETE)
    setIsEditing(false)
    handleClose()
  }

  const handleBillingAddress = (address) => {
    if (!address?.requestParams && EMERGYA_EVENTS_TYPES_RESTRICTED.includes(address?.type)) return
    if (address?.result?.results?.length) {
      const { code, results, status } = address.result
      if (code === 201) {
        setFieldValue('isNewAddress', true)
      }
      if (selectedCompany.code === NEWCO) {
        if (code === 0 && !results[0]?.postCode) {
          dispatch(
            showModal({
              modalType: MODAL_TYPES.newSupplyAddress,
              modalData: {
                completeSupplyPoint,
                service: ELECTRICITY,
                supplyModeService: ELECTRICITY,
                addressCups: '',
                shouldGetCalculations: false,
                step: 5,
                energyStep: '',
                supplyTechnicalInfo: {},
              },
            })
          )
          return
        } else if (status === 'noVertical') {
          return null
        }
        if (code === 0 && selectedCompany.code === NEWCO) {
          if (!results[0]?.postCode) {
            dispatch(
              showModal({
                modalType: MODAL_TYPES.newSupplyAddress,
                modalData: {
                  completeSupplyPoint,
                  service: ELECTRICITY,
                  supplyModeService: ELECTRICITY,
                  addressCups: '',
                  shouldGetCalculations: false,
                  step: 5,
                  energyStep: '',
                  supplyTechnicalInfo: {},
                },
              })
            )
            return
          } else if (status === 'noVertical') {
            return null
          }
        }
        const emergyaAddress = results[0]
        const mappedAddress = mapEmergyaAddress(emergyaAddress, STEPS.billing)
        const auxValuesObj = {
          postalCode: '',
          city: '',
          province: '',
          streetType: '',
          municipalityCode: '',
          provinceCode: '',
          street: '',
          number: '',
          portal: '',
          stair: '',
          floor: '',
          door: '',
          extraData: '',
        }
        Object.keys(values).forEach((valueKey) => {
          if (Object.hasOwn(mappedAddress, valueKey) && mappedAddress[valueKey] != null) {
            auxValuesObj[valueKey] = mappedAddress[valueKey]
          }
        })
        if (mappedAddress['municipalityCode']) {
          auxValuesObj['municipalityCode'] = mappedAddress['municipalityCode']
        }
        if (mappedAddress['provinceCode']) {
          auxValuesObj['provinceCode'] = mappedAddress['provinceCode']
        }
        setValues({ ...values, ...auxValuesObj })
        setNewAddress({ ...values, ...auxValuesObj })
        setIsEditing(false)
        dispatchElectricity(COMPLETE)
      }
    }
  }

  const showErrors = () => {
    const keys = Object.keys(errors)
    let resString = ''
    keys.forEach((key, index) => {
      if (keys.length === 1) {
        resString += `${key}`
      } else if (index === keys.length - 1 && keys.length > 1) {
        resString += `y ${key}`
      } else {
        resString += `${key}, `
      }
    })
    return resString
  }

  const AlertMessage = (
    <Alert icon={false} severity="warning">
      <Typography className={classes.errorList} variant="basic">
        Error en el campo {showErrors()}
      </Typography>
    </Alert>
  )

  const handleEdit = () => {
    setIsEditing(!isEditing)
    dispatchElectricity(TOGGLE_EDIT)
  }

  return isActive ? (
    <Box className={classes.block}>
      {!props.isOnlyToppings && (
        <Box mb={3}>
          <Typography variant="h4" color="textPrimary">
            {t('billingAddress')}
          </Typography>
        </Box>
      )}
      {selectedCompany?.code === COMPANY_CODES.newco ? (
        <>
          <BillingAddressCard
            type={ELECTRICITY}
            collapsed={stateElectricity.isFormClosed}
            isCompleted={stateElectricity.completed}
            onEdit={handleEdit}
            data={mapAddressToCard(newAddress)}
            hide={stateElectricity.hideCard}
            isEditing={isEditing}
          >
            <NATLOC
              sessionId={uuid()}
              step={5}
              provider={PROVIDER_CODES[selectedCompany?.code] || 'newco'}
              category={'none'}
              finishEvent={(address) => {
                handleBillingAddress(address)
              }}
              translations={widgetTranslations(t)}
            />
          </BillingAddressCard>
          {errorsInAddress && AlertMessage}
        </>
      ) : (
        <AddressForm {...props} />
      )}
    </Box>
  ) : null
}

export default AddressWrapper
