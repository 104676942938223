import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, DialogContent } from '@material-ui/core'

import useStyles from './styles'

const ErrorModal = ({ data: { translationLabel } }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={2}>
          <Typography
            variant="basic"
            color="textPrimary"
            dangerouslySetInnerHTML={{ __html: t(`errorModal.${translationLabel}`) }}
          />
        </Box>
      </DialogContent>
    </>
  )
}

export default ErrorModal
