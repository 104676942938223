import React from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import useStyles from './styles'

const ManualItem = ({ manualUrl, manualText }) => {
  const classes = useStyles()

  return (
    <a className={classes.fileDownloadItem} href={manualUrl}>
      <MenuItem>
        <ListItemIcon className={classes.fileIconContainer}>
          <DescriptionOutlinedIcon className={classes.fileIcon} color="primary" />
        </ListItemIcon>
        <ListItemText primary={manualText} />
      </MenuItem>
    </a>
  )
}

export default ManualItem
