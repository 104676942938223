export const mapAddressToCard = (address) => {
  const { province, city, street, ...rest } = address
  return {
    provinceName: province,
    municipalityName: city,
    streetName: street,
    ...rest,
  }
}
export const mapNewBillingAddress = (address) => {
  const { provinceName, municipalityName, streetName, ...rest } = address
  return {
    province: provinceName,
    city: municipalityName,
    street: streetName,
    ...rest,
  }
}
