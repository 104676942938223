import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button } from '@material-ui/core'

const SessionExpiredModal = ({ handleClose }) => {
  const { t } = useTranslation()

  const acceptAction = () => {
    handleClose()
  }

  return (
    <Box px={5} pb={6}>
      <Box mb={3}>
        <Typography variant="h3" color="primary">
          {t('modal-session-expired-title')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="basic" color="textPrimary">
          {t('modal-session-expired-description')}
        </Typography>
      </Box>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={acceptAction}>
          {t('common.accept')}
        </Button>
      </Box>
    </Box>
  )
}

export default SessionExpiredModal
