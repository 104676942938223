import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { getCompany } from 'services/customer/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { COMPANY_CODES } from 'utils/constants'
import useStyles from './styles'

const ScanInvoiceInfo = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const company = useSelector(getCompany)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const selectedCompany = company || selectedProduct[0]?.company
  const companyCode = selectedCompany?.code ?? COMPANY_CODES.gns

  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography variant="h4" color="primary">
          {t('scanInvoiceInfoTitle')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div dangerouslySetInnerHTML={{ __html: t(`scanInvoiceInfoDescription-${companyCode.toLowerCase()}`) }} />
      </DialogContent>
    </>
  )
}

export default ScanInvoiceInfo
