import * as actionTypes from './action-types'

export const setTypes = (types) => ({
  type: actionTypes.SET_TYPES,
  payload: types,
})

export const resetTypes = () => ({
  type: actionTypes.CLEAR_TYPES,
})
