import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

/* eslint-disable-next-line */
export default combineReducers({
  requestOnlineChannel: redukers.request({
    start: actionTypes.START_ONLINE_CHANNEL,
    success: actionTypes.SUCCESS_ONLINE_CHANNEL,
    error: actionTypes.ERROR_ONLINE_CHANNEL,
    clear: actionTypes.CLEAR_ONLINE_CHANNEL,
  }),
  requestLoginOnlineChannel: redukers.request({
    start: actionTypes.START_LOGIN_ONLINE_CHANNEL,
    success: actionTypes.SUCCESS_LOGIN_ONLINE_CHANNEL,
    error: actionTypes.ERROR_LOGIN_ONLINE_CHANNEL,
    clear: actionTypes.CLEAR_LOGIN_ONLINE_CHANNEL,
  }),
  metadata: redukers.value(
    {
      set: actionTypes.SET_META_DATA,
      clear: actionTypes.CLEAR_META_DATA,
    },
    {}
  ),
  loginErrorByUser: redukers.value(
    {
      set: actionTypes.SET_LOGIN_ERROR_BY_USER,
      clear: actionTypes.CLEAR_LOGIN_ERROR_BY_USER,
    },
    false
  ),
})
