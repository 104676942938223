import i18n from 'i18n'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import * as actionTypes from './action-types'
import { SHOW as SHOW_MODAL } from '../../services/modal/action-types'
import * as stepperActionTypes from '../stepper/action-types'
import * as onlineChannelActionTypes from '../onlineChannel/action-types'
import * as orderActionTypes from '../order/action-types'
import * as thankYouActionTypes from '../thankYou/action-types'
import * as loginActionTypes from '../login/action-types'
import * as dashboardActionTypes from '../dashboard/action-types'

import { sendGAEvent, sendGAPageView } from './actions'
import { getActiveStep, getAllStepsValid } from 'services/stepper/selectors'
import { getSelectedRate, getIsOnlyToppings } from 'services/products/selectors'
import { getSelectedSingleToppings } from 'services/toppings/selectors'
import { getIsOnlineChannel, getReferenceSegment, getSelectedChannel } from 'services/operator/selectors'
import { getCupsSearchByCups } from 'services/cupsSearcher/selectors'

import { getRouterPathname, getRouterQueries } from './selectors'
import {
  mapProductsAndServices,
  mapServicesNames,
  trackEvent,
  trackPage,
  trackEcommerce,
  mapCheckoutServices,
  getCheckoutObjectRate,
  getEcommerceData,
} from './business'
import { GA_EVENT_NAMES, /*PHONE_CHANNELS,*/ SPF_NAME, GA_FILTERS_SERVICE, ELECTRICITY, GAS, BOTH } from 'utils/constants'
import { getCRMId } from 'services/order/selectors'
import { get, remove, set } from 'services/sessionStorage'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { getCompany, getSupplyAddress, getUserData } from 'services/customer/selectors'
import { getSignParams, handlerDevice } from 'utils/commons'

function* sendPageViewSaga(payload) {
  const modalType = payload?.payload?.type
  const areAllStepsCompleted = yield select(getAllStepsValid)
  const productArray = yield select(getSelectedRate)
  const activeStep = yield select(getActiveStep)
  const services = yield select(getSelectedSingleToppings)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const isOnlyToppings = yield select(getIsOnlyToppings)
  // const isPhoneChannel = yield select(getIsPhoneChannel)
  const selectedChannel = yield select(getSelectedChannel)
  const queries = yield select(getRouterQueries)
  const selectedService = productArray.length > 1 ? BOTH : productArray[0]?.energyType || ELECTRICITY
  const segmentD = yield select(getReferenceSegment)
  const segment = segmentD === 'residential' ? 'residencial' : 'negocio'
  const userLog = yield select(getUserData)

  let virtualPageStep = payload?.payload?.stepId || activeStep
  const virtualPage =
    payload?.type !== 'MODAL/SHOW' ? `paso-${virtualPageStep < 0 ? 'completados' : virtualPageStep + 1}` : modalType

  const originUrl = window.location.origin
  const pathNameUrl = window.location.pathname.at(-1) === '/' ? window.location.pathname : `${window.location.pathname}/`

  const completeUrl = virtualPage ? `${originUrl}${pathNameUrl}${virtualPage}/${window.location.search}` : window.location.href
  const partialUrl = window.location.origin + window.location.pathname
  let formattedData
  /**
   *  Right now we only enter in COL from selecting product
   * TODO: add variable when we can enter the funnel through service selection to measure
   * cross selling.
   * cross selling criteria:
   * - Enter through a product and select service
   * - Enter through a service and select product (not possible yet)
   */
  if (productArray.length > 0) {
    productArray.forEach((product) => {
      const isCrossSelling = isOnlineChannel && !isEmptyObject(services || {})
      const params = {
        sel: queries?.sel || undefined,
        id: queries?.id || undefined,
        tipo: queries?.tipo || undefined,
        origen: queries?.origen || undefined,
        nnss: queries?.nnss || undefined,
      }
      /*TODO: rellenar los campos que faltan */
      const hasSPF = services && mapServicesNames(services)?.includes(SPF_NAME)
      formattedData = {
        ...params,
        eventCategory: document.title.split('|')[0].trim(),
        eventLabel: undefined,
        idioma: i18n.language,
        areaNegocio: 'Darwin', //<Naturgy.es| Naturgy.pt| Darwin | Solar |COR| Area_privada|Landing>
        categoria: getCategory(productArray, services), //<Luz|Gas|LuzyGas|Solar|Servicios>
        segmento: segment,
        telefonoLanding: undefined, //<900869110|900699708>
        numeroTelefonoPaginaNoAsistido: undefined,
        dominio: window.location.hostname,
        urlCompleta: completeUrl,
        url: partialUrl,
        paginaVirtual: `/${virtualPage}`, // active step < 0 means all steps are closed => validation step
        seccion: document.title.split('|')[0].trim(),
        dispositivo: handlerDevice(), //<desktop|mobile>
        sevicio: services ? mapServicesNames(services).join('|') : undefined,
        tarifa: getTariff(productArray) || undefined,
        dist: GA_FILTERS_SERVICE[selectedService],
        vn: queries?.vn || undefined, //Parámetro de identificación de usuario en la sesión
        clientId: getClientIdFromGA() || undefined,
        userId: undefined,
        userType: isOnlineChannel ? 'noasistido' : 'asistido',
        'subcategoría del asistido': !isOnlineChannel ? selectedChannel.name : undefined,
        lgd: undefined,
        cliente: undefined,
        spf: hasSPF || false,
        xs: isCrossSelling || undefined,
        userLog: userLog?.firstName ? 'si' : 'no', //[si|no]
        familiaContrato: getFamily(productArray, services), //[luz|gas|solar|servicio]
        productoContrato:
          isOnlyToppings || (!productArray.length && !services)
            ? i18n.t('common.contractOnlyMaintenance')
            : mapProductsAndServices(productArray, services),
        segmentoCliente: segment, //[residencial|comunidades|negocio]
      }

      if (areAllStepsCompleted) {
        set('transactionPageView', JSON.stringify(formattedData))
      }

      // TODO isOnlyToppings define activeStep === -2 and don't send pageView
    })
  } else {
    const isCrossSelling = isOnlineChannel && !isEmptyObject(services || {})
    const params = {
      sel: queries?.sel || undefined,
      nnss: queries?.nnss || undefined,
      id: queries?.id || undefined,
      tipo: queries?.tipo || undefined,
      origen: queries?.origen || undefined,
    }
    const hasSPF = services && mapServicesNames(services)?.includes(SPF_NAME)
    formattedData = {
      ...params,
      eventCategory: document.title.split('|')[0].trim(),
      eventLabel: undefined,
      idioma: i18n.language,
      areaNegocio: 'Darwin', //<Naturgy.es| Naturgy.pt| Darwin | Solar |COR| Area_privada|Landing>
      categoria: getCategory(productArray, services), //<Luz|Gas|LuzyGas|Solar|Servicios>
      segmento: segment,
      telefonoLanding: undefined, //<900869110|900699708>
      numeroTelefonoPaginaNoAsistido: undefined,

      dominio: window.location.hostname,
      urlCompleta: completeUrl,
      url: partialUrl,
      paginaVirtual: `/${virtualPage}`, // active step < 0 means all steps are closed => validation step
      seccion: document.title.split('|')[0].trim(),
      dispositivo: handlerDevice(), //<desktop|mobile>
      sevicio: services ? mapServicesNames(services).join('|') : undefined,
      tarifa: getTariff(productArray) || undefined,

      dist: GA_FILTERS_SERVICE[selectedService],
      vn: queries?.vn || undefined, //Parámetro de identificación de usuario en la sesión
      clientId: getClientIdFromGA() || undefined,
      userId: undefined,
      userType: isOnlineChannel ? 'noasistido' : 'asistido',
      lgd: undefined,
      cliente: undefined,
      spf: hasSPF || false,
      xs: isCrossSelling,
      userLog: userLog?.firstName ? 'si' : 'no', //[si|no]
      familiaContrato: getFamily(productArray, services), //[luz|gas|solar|servicio]
      // assisted: isPhoneChannel ? PHONE_CHANNELS[selectedChannel?.type] : '',
      productoContrato:
        isOnlyToppings || (!productArray.length && !services)
          ? i18n.t('common.contractOnlyMaintenance')
          : mapProductsAndServices(productArray, services),
      'subcategoría del asistido': !isOnlineChannel ? selectedChannel.name : undefined,
      segmentoCliente: segment,
    }

    if (areAllStepsCompleted) {
      set('transactionPageView', JSON.stringify(formattedData))
    }
  }
  if (window.location.pathname === '/thank-you') {
    const selectedProductArray = yield select(getSelectedRate)
    const rate = selectedProductArray[0]
    const supplyAddress = yield select(getSupplyAddress)
    const company = yield select(getCompany)
    const selectedCompanyCode = company?.code || rate?.company?.code
    const pathname = yield select(getRouterPathname)

    formattedData.paginaVirtual = `${pathname}`
    formattedData.urlCompleta = `${originUrl}${pathname}/${window.location.search}`
    formattedData.userType = 'noasistido'
    const paramsSign = getSignParams(rate, services, supplyAddress, selectedCompanyCode)
    trackPage({ ...formattedData, ...paramsSign })
    yield put(sendGAPageView())
  } else {
    trackPage(formattedData)
    yield put(sendGAPageView())
  }
}
const getCategory = (productArray, services) => {
  let category = ''
  if (productArray.length > 0) {
    productArray.forEach((product, index) => {
      if (product?.energyType) {
        if (product.energyType === ELECTRICITY) {
          category += `Luz ${index < product.length - 1 ? ' | ' : ''}`
        } else if (product.energyType === GAS) {
          category += `Gas ${index < product.length - 1 ? ' | ' : ''}`
        } else {
          category += 'LuzyGas'
        }
      }
    })
  }
  if (services) {
    if (category) {
      category += ' | '
    }
    category += 'Servicios'
  }
  if (category?.endsWith(' ')) {
    return category.slice(0, -1)
  }
  if (category === '') {
    return undefined
  }

  return category
}
const getTariff = (productArray) => {
  let tariff = ''
  if (productArray.length > 0) {
    productArray.forEach((product, index) => {
      if (product?.variants) {
        tariff += `${product.variants[0]?.tariff?.type} ${index < product.length - 1 ? ' | ' : ''}`
      }
    })
  }
  //delete lasta ' ' character
  if (tariff.endsWith(' ')) {
    return tariff.slice(0, -1)
  }

  return tariff
}

const getFamily = (productArray, services) => {
  let family = ''
  if (productArray.length > 0) {
    productArray.forEach((product, index) => {
      if (product?.energyType) {
        if (product.energyType === ELECTRICITY) {
          family += `luz ${index < product.length - 1 ? ' | ' : ''}`
        } else if (product.energyType === GAS) {
          family += `gas ${index < product.length - 1 ? ' | ' : ''}`
        } else {
          family += 'luz | gas'
        }
      }
    })
  }
  if (services) {
    if (family) {
      family += ' | '
    }
    family += 'servicio'
  }
  return family
}

function* sendGAEventSaga({ payload: { eventCallbackName, data } }) {
  const productArray = yield select(getSelectedRate)
  const activeStep = yield select(getActiveStep)
  const services = yield select(getSelectedSingleToppings)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const isOnlyToppings = yield select(getIsOnlyToppings)
  const selectedChannel = yield select(getSelectedChannel)
  let queries = yield select(getRouterQueries)
  const selectedService = productArray.length > 1 ? BOTH : productArray[0]?.energyType || ELECTRICITY
  const segmentD = yield select(getReferenceSegment)
  const segment = segmentD === 'residential' ? 'residencial' : 'negocio'
  const userLog = yield select(getUserData)
  let virtualPageStep = activeStep
  const virtualPage =
    eventCallbackName !== 'thankYouPage' ? `paso-${virtualPageStep < 0 ? 'completados' : virtualPageStep + 1}` : 'thank-you'

  const originUrl = window.location.origin
  const pathNameUrl = window.location.pathname.at(-1) === '/' ? window.location.pathname : `${window.location.pathname}/`
  const partialUrl = window.location.origin + window.location.pathname
  const completeUrl = virtualPage ? `${originUrl}${pathNameUrl}${virtualPage}/${window.location.search}` : window.location.href

  if (eventCallbackName === 'thankYouPage') {
    const url = window.location.href
    const decodedUrl = decodeURIComponent(url)
    const urlParams = new URLSearchParams(decodedUrl)
    queries = {
      sel: urlParams.get('sel'),
      nnss: urlParams.get('nnss'),
      id: urlParams.get('id'),
      tipo: urlParams.get('tipo'),
      origen: urlParams.get('origen'),
      vn: urlParams.get('vn'),
    }
  }

  const isCrossSelling = isOnlineChannel && !isEmptyObject(services || {})
  const params = {
    sel: queries?.sel || undefined,
    nnss: queries?.nnss || undefined,
    id: queries?.id || undefined,
    tipo: queries?.tipo || undefined,
    origen: queries?.origen || undefined,
  }
  const hasSPF = services && mapServicesNames(services)?.includes(SPF_NAME)
  const commonParams = {
    ...params,
    idioma: i18n.language,
    areaNegocio: 'Darwin', //<Naturgy.es| Naturgy.pt| Darwin | Solar |COR| Area_privada|Landing>
    categoria: getCategory(productArray, services), //<Luz|Gas|LuzyGas|Solar|Servicios>
    segmento: segment,
    telefonoLanding: undefined, //<900869110|900699708>
    numeroTelefonoPaginaNoAsistido: undefined,
    dominio: window.location.hostname,
    urlCompleta: completeUrl,
    url: partialUrl,
    paginaVirtual: `/${virtualPage}`, // active step < 0 means all steps are closed => validation step
    seccion: document.title.split('|')[0].trim(),
    dispositivo: handlerDevice(), //<desktop|mobile>
    sevicio: services ? mapServicesNames(services).join('|') : undefined,
    tarifa: getTariff(productArray) || undefined,

    dist: GA_FILTERS_SERVICE[selectedService],
    vn: queries?.vn || undefined, //Parámetro de identificación de usuario en la sesión
    clientId: getClientIdFromGA() || undefined,
    userId: undefined,
    userType: isOnlineChannel || eventCallbackName === 'thankYouPage' ? 'noasistido' : 'asistido',
    lgd: undefined,
    cliente: undefined,
    spf: hasSPF || false,
    xs: isCrossSelling,
    userSbId: undefined,
    userLog: userLog?.firstName ? 'si' : 'no', //[si|no]
    familiaContrato: getFamily(productArray, services), //[luz|gas|solar|servicio]
    productoContrato:
      isOnlyToppings || (!productArray.length && !services)
        ? i18n.t('common.contractOnlyMaintenance')
        : mapProductsAndServices(productArray, services),
    'subcategoría del asistido': !isOnlineChannel ? selectedChannel.name : undefined,
    segmentoCliente: segment,
  }
  const byCups = yield select(getCupsSearchByCups)
  if (eventCallbackName === 'checkoutEvent' && byCups && data?.ecommerce?.checkout?.products) {
    data.ecommerce.checkout.products = data.ecommerce.checkout.products.map((product) => ({
      ...product,
      ...(product.id.replace('/middleware/families/', '') in byCups
        ? {
            byCups: Object.values(byCups[product.id.replace('/middleware/families/', '')]).includes(true),
            byAddress: Object.values(byCups[product.id.replace('/middleware/families/', '')]).includes(false),
          }
        : {}),
    }))
  }
  trackEvent(eventCallbackName, commonParams, data)
}
const getItems = (selectedProductArray, selectedService) => {
  const products =
    selectedProductArray.length > 0
      ? selectedProductArray.map((product) => ({
          id: product.id,
          name: product.name,
          item_brand: 'Darwin',
          item_category: product?.energyType === 'ELECTRICITY' ? 'Luz' : product?.energyType === 'GAS' ? 'Gas' : 'LuzyGas',
          item_category2: undefined,
          item_category5: undefined,
          item_list_id: undefined,
          item_list_name: undefined,
          price: 0,
          quantity: 1,
        }))
      : []
  const services =
    selectedService && Object.entries(selectedService)?.length > 0
      ? Object.entries(selectedService).map(([key, value]) => {
          return {
            id: key,
            name: value.CRMid,
            item_brand: 'Darwin',
            item_category: undefined,
            item_category2: 'servicio',
            item_category5: undefined,
            item_list_id: undefined,
            item_list_name: undefined,
            price: 0,
            quantity: 1,
          }
        })
      : []

  return [...products, ...services]
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}

const getClientIdFromGA = () => {
  // Obtén la cookie _ga
  const gaCookie = getCookie('_ga')

  if (!gaCookie) {
    //console.error('No se encontró la cookie _ga');
    return null
  }

  const gaParts = gaCookie.split('.')
  if (gaParts.length < 4) {
    console.error('Formato inesperado de la cookie _ga')
    return null
  }

  const clientId = `GA${gaParts[2]}.${gaParts[3]}`
  return clientId
}

function* sendGAEcommerceEventSaga({ payload }) {
  const selectedProductArray = yield select(getSelectedRate)
  const selectedService = yield select(getSelectedSingleToppings)
  let commonParams = {}
  if (payload === 'begin_checkout') {
    commonParams = {
      currency: 'EUR',
      value: 0,
      items: getItems(selectedProductArray, selectedService),
    }

    const eventData = {
      event: `begin_checkout`,
      ecommerce: commonParams,
    }
    trackEcommerce(eventData)
  } else if (payload === 'purchase') {
    const eventData = {
      event: `purchase`,
      ecommerce: {
        currency: 'EUR',
        value: 0,
        items: getItems(selectedProductArray, selectedService),
      },
    }
    trackEcommerce(eventData)
  }
}

function* sendGACheckoutEventSaga() {
  const selectedProductArray = yield select(getSelectedRate)
  const rate = selectedProductArray[0]
  const services = yield select(getSelectedSingleToppings)
  const step = yield select(getActiveStep)
  const areAllStepsCompleted = yield select(getAllStepsValid)
  const arrayServices = []
  const rateArray = []
  if (rate) {
    rateArray.push(getCheckoutObjectRate(rate, step))
  }
  if (services) {
    arrayServices.push(...mapCheckoutServices(services, step))
  }
  const productsAndServices = [...rateArray, ...arrayServices].map((item, position) => ({ ...item, position }))
  const result = {
    ecommerce: getEcommerceData(productsAndServices, step + 1, 'checkout'),
  }

  if (areAllStepsCompleted) {
    set('transactionCheckout', JSON.stringify(result))
  }

  if (productsAndServices.length) {
    // yield put(sendGAEvent(GA_EVENT_NAMES.checkoutEvent, result))
  }
}

function* sendGASignEventSaga() {
  const selectedProductArray = yield select(getSelectedRate)
  const rate = selectedProductArray[0]
  const services = yield select(getSelectedSingleToppings)
  const CRMid = yield select(getCRMId)
  const step = yield select(getActiveStep)
  const supplyAddress = yield select(getSupplyAddress)
  const company = yield select(getCompany)
  const selectedCompanyCode = company?.code || rate?.company?.code
  const arrayServices = []
  const rateArray = []
  const productArray = yield select(getSelectedRate)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const isOnlyToppings = yield select(getIsOnlyToppings)
  // const isPhoneChannel = yield select(getIsPhoneChannel)
  const selectedChannel = yield select(getSelectedChannel)
  const queries = yield select(getRouterQueries)
  const selectedService = productArray.length > 1 ? BOTH : productArray[0]?.energyType || ELECTRICITY
  const segmentD = yield select(getReferenceSegment)
  const segment = segmentD === 'residential' ? 'residencial' : 'negocio'
  const userLog = yield select(getUserData)

  const virtualPage = 'validacion'

  const originUrl = window.location.origin
  const pathNameUrl = window.location.pathname.at(-1) === '/' ? window.location.pathname : `${window.location.pathname}/`

  const completeUrl = virtualPage
    ? `${originUrl}${pathNameUrl}paso-${virtualPage}/${window.location.search}`
    : window.location.href
  const partialUrl = window.location.origin + window.location.pathname

  let formattedData
  const paramsSign = getSignParams(rate, services, supplyAddress, selectedCompanyCode)
  formattedData = {
    ...paramsSign,
    eventCategory: document.title.split('|')[0].trim(),
    eventLabel: undefined,
    idioma: i18n.language,
    areaNegocio: 'Darwin', //<Naturgy.es| Naturgy.pt| Darwin | Solar |COR| Area_privada|Landing>
    categoria: getCategory(productArray, services), //<Luz|Gas|LuzyGas|Solar|Servicios>
    segmento: segment,
    telefonoLanding: undefined, //<900869110|900699708>
    numeroTelefonoPaginaNoAsistido: undefined,
    dominio: window.location.hostname,
    urlCompleta: completeUrl,
    url: partialUrl,
    paginaVirtual: `/${virtualPage}`, // active step < 0 means all steps are closed => validation step
    seccion: document.title.split('|')[0].trim(),
    dispositivo: handlerDevice(), //<desktop|mobile>
    sevicio: services ? mapServicesNames(services).join('|') : undefined,
    tarifa: getTariff(productArray) || undefined,
    dist: GA_FILTERS_SERVICE[selectedService],
    vn: queries?.vn || undefined, //Parámetro de identificación de usuario en la sesión
    clientId: getClientIdFromGA() || undefined,
    userId: undefined,
    userType: isOnlineChannel ? 'noasistido' : 'asistido',
    'subcategoría del asistido': !isOnlineChannel ? selectedChannel.name : undefined,
    lgd: undefined,
    cliente: undefined,
    spf: false,
    xs: undefined,
    userLog: userLog?.firstName ? 'si' : 'no', //[si|no]
    familiaContrato: getFamily(productArray, services), //[luz|gas|solar|servicio]
    productoContrato:
      isOnlyToppings || (!productArray.length && !services)
        ? i18n.t('common.contractOnlyMaintenance')
        : mapProductsAndServices(productArray, services),
    segmentoCliente: segment, //[residencial|comunidades|negocio]
  }

  trackPage(formattedData)
  yield put(sendGAPageView())

  if (rate) {
    rateArray.push(getCheckoutObjectRate(rate))
  }
  if (services) {
    const servicesArray = mapCheckoutServices(services)
    arrayServices.push(...servicesArray)
  }

  const products = [...rateArray, ...arrayServices]
  if (products.length) {
    const revenue = products.reduce((acc, value) => acc + value.price, 0)
    const productsToSend = [...rateArray, ...arrayServices].map((item, position) => ({
      ...item,
      revenue: revenue.toFixed(2),
      transactionId: CRMid || '',
      position,
    }))
    const result = {
      event: 'event.ecommerce',
      ecommerce: getEcommerceData(productsToSend, step, 'checkout'),
    }

    yield put(sendGAEvent(GA_EVENT_NAMES.checkoutEvent, result))
    const byCups = result.ecommerce?.checkout?.products?.some(({ byCups }) => byCups)
    const byAddress = result.ecommerce?.checkout?.products?.some(({ byAddress }) => byAddress)
    if (byCups || byAddress) {
      const eventLabel = byCups && !byAddress ? 'byCups' : byAddress && !byCups ? 'byAddress' : 'byCupsAndAddress'
      yield put(
        sendGAEvent(GA_EVENT_NAMES.checkoutEvent, {
          eventLabel,
          eventCategory: 'ecommerce',
          eventAction: 'sign-product',
        })
      )
    }
  }
}

function* thankYouPageEventSaga() {
  // const selectedProductArray = yield select(getSelectedRate)
  // const rate = selectedProductArray[0]
  //const services = yield select(getSelectedSingleToppings)
  // const supplyAddress = yield select(getSupplyAddress)
  // const company = yield select(getCompany)
  //const selectedCompanyCode = company?.code || rate?.company?.code

  const checkoutData = JSON.parse(get('transactionCheckout'))
  const pageViewData = JSON.parse(get('transactionPageView'))
  const pathname = yield select(getRouterPathname)

  if (pageViewData && !isEmptyObject(pageViewData)) {
    pageViewData.paginaVirtual = `${pathname}`
  }

  if (checkoutData && !isEmptyObject(checkoutData)) {
    checkoutData.ecommerce.checkout.actionField.step = 'compra'
  }

  //const paramsSign = getSignParams(rate, services, supplyAddress, selectedCompanyCode)

  // PageView of thank yoy page
  // trackPage({ ...pageViewData, ...paramsSign })
  //yield put(sendGAPageView())
  yield put(sendGAEvent(GA_EVENT_NAMES.checkoutEvent, checkoutData))
  remove('transactionCheckout')
  remove('transactionPageView')
}

// eslint-disable-next-line require-yield
function* loginPageEventSaga() {
  // const pathname = yield select(getRouterPathname)
  const pageViewData = {
    userType: 'asistido',
  }
  trackPage(pageViewData)
}

// eslint-disable-next-line require-yield
function* dashboardPageEventSaga() {
  // const pathname = yield select(getRouterPathname)
  const pageViewData = {
    userType: 'asistido',
  }
  trackPage(pageViewData)
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.GA_SEND_EVENT], sendGAEventSaga),
    takeLatest(
      [
        stepperActionTypes.SET_ACTIVE_STEP,
        stepperActionTypes.CLEAR_ACTIVE_STEP,
        stepperActionTypes.PRESELECTED_STEP,
        onlineChannelActionTypes.COMPLETE_ANALYTICS_ONLINE_CHANNEL,
        SHOW_MODAL,
        thankYouActionTypes.LOAD_THANK_YOU_PAGE,
      ],
      sendPageViewSaga
    ),
    takeLatest([actionTypes.GA_SEND_ECOMMERCE_EVENT], sendGAEcommerceEventSaga),
    takeLatest([stepperActionTypes.GO_NEXT_STEP], sendGACheckoutEventSaga),
    takeLatest([orderActionTypes.START_SIGN], sendGASignEventSaga),
    takeLatest([thankYouActionTypes.LOAD_THANK_YOU_PAGE], thankYouPageEventSaga),
    takeLatest([loginActionTypes.LOAD_LOGIN_PAGE], loginPageEventSaga),
    takeLatest([dashboardActionTypes.LOAD_DASHBOARD_PAGE], dashboardPageEventSaga),
  ])
}
