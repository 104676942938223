import * as customerActionTypes from 'services/customer/action-types'
import { STEPS } from 'utils/constants'

export const SERVICE = 'LEAD'

export const LEAD_IRI = '/middleware/leads'
export const ORDER_IRI = '/middleware/orders'
export const ORDER_LINE_IRI = '/middleware/order_lines'
export const PRODUCT_VARIANTS_IRI = '/middleware/product_variants'
export const SERVICE_IRI = '/middleware/services'
export const CHANNEL_OFFERS_IRI = '/middleware/channel_offers'
export const SELFCONSUMPTION_CUPS_SUBSECTION_TYPE_IRI = '/middleware/self_consumption_subsection_types'
export const SELFCONSUMPTION_CUPS_TYPE_IRI = '/middleware/self_consumption_types'
export const CHANNEL_IRI = '/middleware/channels'
export const USER_IRI = '/middleware/users'
export const CAMPAIGN_IRI = '/middleware/campaigns'
export const TECHNICAL_INFO_IRI = {
  electricity: '/middleware/technical_info_electricities',
  gas: '/middleware/technical_info_gas',
}
export const PRICING_CALCULATION_IRI = '/middleware/pricing_calculations'
export const PRICING_IRI = '/middleware/pricing'
export const ACTIONS_STEPS = {
  [customerActionTypes.SET_DATA]: STEPS.owner,
  [customerActionTypes.SUBMIT_BILLING_STEP]: STEPS.billing,
  [`${SERVICE}/FETCH`]: STEPS.supply,
}
