import dayjs from 'dayjs'
import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import * as controlActionTypes from 'services/control/action-types'
import * as api from './api'
import * as operatorApi from 'services/operator/api'
import {
  loadControlStart,
  loadControlSuccess,
  loadControlError,
  loadControlVisualizationStart,
  loadControlVisualizationSuccess,
  loadControlVisualizationError,
  loadControlVisualizationClear,
  loadControlExportStart,
  loadControlExportSuccess,
  loadControlExportError,
  setVisualization,
  setExport,
} from 'services/control/actions'
import { setProducts, setChannels, setStatus, setAgencies } from 'services/control/actions'
import { resetContract, setUserData } from 'services/operator/actions'
import { setNotificationState } from 'services/notification/actions'
import { getFilters, getExport } from 'services/control/selectors'
import { getIsAdmin, getOperator, getAgencyCodes as getOperatorAgencies, getUser } from 'services/operator/selectors'
import { parseExport, getMotivoKO, reduce as reduceProduct } from './business'
import { DATE_FORMAT, COMPANY_NAMES } from '../../utils/constants'
import { SOLD_ORDER_STATUS_GROUPS, PRODUCTS_PER_PAGE, MAX_EXPORT_TIME_S, MAX_EXPORT_ORDERS } from './constants'

function* fetchFiltersConfigurationSaga() {
  const userSesion = yield select(getUser)

  yield put(loadControlStart())
  yield put(resetContract())
  const [user] = userSesion ? [userSesion] : yield call(operatorApi.getUser)
  if (!userSesion) {
    yield put(setUserData(user))
  }
  try {
    const operator = (yield select(getOperator)) || {}
    const isAdmin = (yield select(getIsAdmin)) || false
    const operatorAgencies = (yield select(getOperatorAgencies)) || []

    const orders = yield call(api.getOrderFiltersConfig)
    const { types: products } = yield call(api.getProducts)
    const status = yield call(api.getOrderStatus)
    let agencies = yield call(api.getAgencies)

    yield put(setProducts(products.filter(({ id }) => !!id)))
    yield put(setStatus(status.map(({ stateGroup }) => ({ id: stateGroup, name: stateGroup }))))

    if (!isAdmin) {
      agencies = [agencies.find(({ code }) => operatorAgencies.includes(code))]
    }
    yield put(setAgencies(agencies.map(({ id, externalId }) => ({ id, name: externalId }))))
    if (operator?.user?.channelOffers) {
      const channelsArray = []
      const channelsNameArray = []
      orders.setup.channels = operator.user.channelOffers
        .map(({ channel }) => {
          if (channelsArray.includes(channel.id) || channelsNameArray.includes(channel.name)) {
            // eslint-disable-next-line array-callback-return
            return
          }
          channelsArray.push(channel.id)
          channelsNameArray.push(channel.name)
          return {
            id: channel.id,
            name: channel.name,
          }
        })
        .filter((elm) => {
          return elm
        })
    }
    yield put(setChannels(orders.setup.channels))
    yield put(loadControlSuccess())
  } catch (e) {
    console.error(e)
    yield put(loadControlError())
  }
}

function* getQueryParams({ isExport } = { isExport: false }) {
  const filters = (yield select(getFilters)) || {}
  const validStatusFilter = []
  filters.status?.forEach((status) => {
    if (SOLD_ORDER_STATUS_GROUPS.includes(status.id) && status.id !== 'signed' && status.id !== 'finished') {
      validStatusFilter.push(status)
    }
    if (status.id === 'signed') {
      validStatusFilter.push({ id: 'submitted', name: 'Finalizado' })
      validStatusFilter.push({ id: 'timeout', name: 'Finalizado' })
    }
    if (status.id === 'finished') {
      validStatusFilter.push({ id: 'succeeded', name: 'Finalizado' })
      validStatusFilter.push({ id: 'succeeded_inactive', name: 'Finalizado' })
    }
  })
  const queryVisualizationParams = {
    stateGroup: validStatusFilter?.length > 0 ? validStatusFilter : [],
    channel: filters.channel,
    agency: filters.agency,
    product: filters.product,
    contractType: filters.contractType,
    companyName: filters.company?.length === 1 ? COMPANY_NAMES[filters.company[0].id] || filters.company[0].name : '',
  }
  const queryPaginationParams = isExport
    ? {
        page: 1,
        nbItems: PRODUCTS_PER_PAGE,
      }
    : {}

  queryVisualizationParams.createdTo = filters.toDate || dayjs().format(DATE_FORMAT)
  queryVisualizationParams.createdFrom =
    filters.fromDate ||
    (filters.toDate ? dayjs(filters.toDate, DATE_FORMAT) : dayjs()).add(1, 'day').subtract(1, 'month').format(DATE_FORMAT)

  queryVisualizationParams.failedDateFrom = filters.failedDateFrom || ''
  queryVisualizationParams.failedDateTo = filters.failedDateTo || ''
  return {
    ...queryVisualizationParams,
    ...queryPaginationParams,
  }
}

function* fetchVisualizationSaga() {
  yield put(loadControlVisualizationStart())
  yield put(
    setVisualization({
      totalOrders: 0,
      loadedOrders: 0,
      ...Object.keys(reduceProduct).reduce((acc, key) => ({ ...acc, [key]: {} }), {}),
    })
  )

  try {
    const queryParams = yield getQueryParams()
    const { 'hydra:member': visualizations } = yield call(api.getVisualizationOrderProducts, queryParams)
    const updatedVisualization = visualizations.pop()

    yield put(
      setVisualization({
        totalSold: updatedVisualization.total_sold.reduce(
          (acc, { energy_type, total_sold }) => ({ ...acc, [energy_type || 'OTHERS']: total_sold }),
          {}
        ),
        totalFailed: updatedVisualization.total_ko.reduce((acc, { ko_reason, total_ko }) => {
          try {
            const key = getMotivoKO(JSON.parse(ko_reason ?? '{}'))
            return { ...acc, [key]: total_ko + (acc[key] ?? 0) }
          } catch {
            return acc
          }
        }, {}),
        totalProducts: updatedVisualization.total_transitory_messaging.reduce(
          (acc, { contract_type, total_transitory_messaging }) => ({ ...acc, [contract_type]: total_transitory_messaging }),
          {}
        ),
        totalFailedOnActivation: updatedVisualization.ko_ativation.reduce(
          (acc, { energy_type, ko_ativation }) => ({ ...acc, [energy_type || 'OTHERS']: ko_ativation }),
          {}
        ),
        activationTime: updatedVisualization.activation_time.reduce(
          (acc, { energy_type, age, total }) => ({ ...acc, [energy_type || 'OTHERS']: { age, total } }),
          {}
        ),
        totalActive: updatedVisualization.total_active.reduce(
          (acc, { energy_type, total }) => ({ ...acc, [energy_type || 'OTHERS']: total }),
          {}
        ),
      })
    )
    yield put(loadControlVisualizationSuccess())
  } catch (error) {
    yield put(loadControlVisualizationError())
  }
}

function* stopVisualizationSaga() {
  yield put(loadControlVisualizationClear())
}

function* fetchExportSaga() {
  yield put(loadControlExportStart())

  const deadline = Date.now() + MAX_EXPORT_TIME_S * 1000
  const maxOrderPage = MAX_EXPORT_ORDERS / PRODUCTS_PER_PAGE

  try {
    const queryParams = yield getQueryParams({ isExport: true })

    let nextLines = call(api.getExportOrderProducts, queryParams)
    let isOverload = false

    while (nextLines) {
      const exportData = yield select(getExport)
      let { 'hydra:member': exportLines } = yield nextLines
      const { next: page } = exportLines.pop()
      exportLines = exportLines.map(parseExport)
      yield put(setExport(exportData.concat(exportLines)))

      isOverload = (page && page > maxOrderPage) || Date.now() > deadline
      nextLines = page && !isOverload ? call(api.getExportOrderProducts, { ...queryParams, page }) : false
    }

    if (isOverload) {
      yield put(
        setNotificationState({
          message: 'notificationAlerts.controlExportOverload',
          type: 'warning',
          isVisible: true,
        })
      )
    }

    yield put(loadControlExportSuccess())
  } catch (error) {
    yield put(loadControlExportError())
  }
}

function* clearExportSaga() {
  yield put(loadControlVisualizationClear())
}

export default function* rootSaga() {
  yield all([
    takeLatest([controlActionTypes.FETCH_FILTERS_CONFIGURATION], fetchFiltersConfigurationSaga),
    takeLatest([controlActionTypes.FETCH_VISUALIZATION], fetchVisualizationSaga),
    takeLatest([controlActionTypes.STOP_VISUALIZATION], stopVisualizationSaga),
    takeLatest([controlActionTypes.FETCH_EXPORT], fetchExportSaga),
    takeLatest([controlActionTypes.CLEAR_EXPORT], clearExportSaga),
  ])
}
