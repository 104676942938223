const { makeStyles } = require('@material-ui/core')

const useStyles = makeStyles((theme) => ({
  field: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radio: {
    '& .MuiIconButton-label': {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      '& .MuiSvgIcon-root': {
        transform: 'scale(0.8)',
      },
    },
  },
}))

export default useStyles
