import { combineReducers } from 'redux'
import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { WITOUT_SELFCONSUMPTION } from 'services/selfconsumption/constants'

/* eslint-disable-next-line */
export default combineReducers({
  data: redukers.value(
    {
      set: actionTypes.SET_DATA,
      reset: actionTypes.CLEAR_DATA,
    },
    {}
  ),
  billingAccount: redukers.value({
    set: actionTypes.SET_BILLING_ACCOUNT,
    reset: actionTypes.CLEAR_BILLING_ACCOUNT,
  }),
  normalizedAddress: redukers.value(
    {
      set: actionTypes.SET_NORMALIZED_ADDRESS,
      reset: actionTypes.CLEAR_NORMALIZED_ADDRESS,
    },
    false
  ),
  supplyAddress: redukers.value(
    {
      set: actionTypes.SET_SUPPLY_ADDRESS,
      reset: actionTypes.CLEAR_SUPPLY_ADDRESS,
    },
    null
  ),
  isChangeBillingAddress: redukers.value(
    {
      set: actionTypes.SET_CHANGE_BILLING_ADDRESS,
      reset: actionTypes.CLEAR_CHANGE_BILLING_ADDRESS,
    },
    false
  ),
  birthdayDate: redukers.value(
    {
      set: actionTypes.SET_BIRTHDAY_DATE,
      reset: actionTypes.CLEAR_BIRTHDAY_DATE,
    },
    null
  ),
  billingAddress: redukers.value({
    set: actionTypes.SET_BILLING_ADDRESS,
    reset: actionTypes.CLEAR_BILLING_ADDRESS,
  }),
  supplyPoints: redukers.value({
    set: actionTypes.SET_SUPPLY_POINT,
    reset: actionTypes.CLEAR_SUPPLY_POINTS,
  }),
  isNewSupply: redukers.value(
    {
      set: actionTypes.SET_IS_NEW_SUPPLY_POINT,
      reset: actionTypes.CLEAR_IS_NEW_SUPPLY_POINT,
    },
    false
  ),
  company: redukers.value(
    {
      set: actionTypes.SET_COMPANY,
      reset: actionTypes.CLEAR_COMPANY,
    },
    null
  ),
  onlineInvoice: redukers.value(
    {
      set: actionTypes.SET_ONLINE_INVOICE,
      reset: actionTypes.CLEAR_ONLINE_INVOICE,
    },
    false
  ),
  referralCode: redukers.value(
    {
      set: actionTypes.SET_REFERRAL_CODE,
      reset: actionTypes.CLEAR_REFERRAL_CODE,
    },
    null
  ),
  productReferralCode: redukers.value(
    {
      set: actionTypes.SET_PRODUCT_REFERRAL_CODE,
      reset: actionTypes.CLEAR_PRODUCT_REFERRAL_CODE,
    },
    null
  ),
  buyapowaCode: redukers.value(
    {
      set: actionTypes.SET_BUYAPOWA_CODE,
      reset: actionTypes.CLEAR_BUYAPOWA_CODE,
    },
    null
  ),
  userDataRequest: redukers.request({
    start: actionTypes.USER_DATA_REQUEST_START,
    success: actionTypes.USER_DATA_REQUEST_SUCCESS,
    error: actionTypes.USER_DATA_REQUEST_ERROR,
    clear: actionTypes.USER_DATA_REQUEST_CLEAR,
  }),
  userData: redukers.value({
    set: actionTypes.SET_USER_DATA,
    reset: actionTypes.RESET_USER_DATA,
  }),
  selectedTenure: redukers.value(
    {
      set: actionTypes.SET_SELECTED_TENURE,
      reset: actionTypes.CLEAR_SELECTED_TENURE,
    },
    {}
  ),
  selectedCompany: redukers.value(
    {
      set: actionTypes.SET_SELECTED_COMPANY,
      reset: actionTypes.CLEAR_SELECTED_COMPANY,
    },
    {}
  ),
  isTenureSelectionError: redukers.value(
    {
      set: actionTypes.SET_TENURE_SELECTION_ERROR,
      clear: actionTypes.CLEAR_TENURE_SELECTION_ERROR,
    },
    false
  ),
  isExClient: redukers.value(
    {
      set: actionTypes.SET_EX_CLIENT,
      reset: actionTypes.RESET_EX_CLIENT,
    },
    false
  ),
  onBehalfContactId: redukers.value(
    {
      set: actionTypes.SET_ON_BEHALF_CONTACT_ID,
      reset: actionTypes.CLEAR_ON_BEHALF_CONTACT_ID,
    },
    null
  ),
  isMultiPoint: redukers.value(
    {
      set: actionTypes.SET_IS_MULTI_POINT,
      reset: actionTypes.CLEAR_IS_MULTI_POINT,
    },
    false
  ),
  autosave: redukers.value(
    {
      set: actionTypes.SET_AUTOSAVE,
    },
    false
  ),
  differentBillingAddress: redukers.value(
    {
      set: actionTypes.SET_DIFFERENT_BILLING_ADDRESS,
      reset: actionTypes.CLEAR_DIFFERENT_BILLING_ADDRESS,
    },
    false
  ),
  cupsOK: redukers.value(
    {
      set: actionTypes.SET_CUPS_OK,
    },
    true
  ),
  selfconsumptionType: redukers.value(
    {
      set: actionTypes.SET_SELFCONSUMPTION_TYPE,
      reset: actionTypes.CLEAR_SELFCONSUMPTION_TYPE,
    },
    WITOUT_SELFCONSUMPTION
  ),
  initialSelfconsumptionType: redukers.value(
    {
      set: actionTypes.SET_INITIAL_SELFCONSUMPTION_TYPE,
      reset: actionTypes.CLEAR_INITIAL_SELFCONSUMPTION_TYPE,
    },
    null
  ),
  collective: redukers.value(
    {
      set: actionTypes.SET_COLLECTIVE,
      reset: actionTypes.CLEAR_COLLECTIVE,
    },
    false
  ),
  subsection: redukers.value(
    {
      set: actionTypes.SET_SUBSECTION,
      reset: actionTypes.CLEAR_SUBSECTION,
    },
    null
  ),
  installedPower: redukers.value(
    {
      set: actionTypes.SET_INSTALLED_POWER,
      reset: actionTypes.CLEAR_INSTALLED_POWER,
    },
    0
  ),
  cupsType: redukers.value(
    {
      set: actionTypes.SET_CUPS_TYPE,
      reset: actionTypes.CLEAR_CUPS_TYPE,
    },
    null
  ),
  signUpForSelfConsumption: redukers.value(
    {
      set: actionTypes.SET_SIGNUP_FOR_SELFCONSUMPTION,
      reset: actionTypes.CLEAR_SIGNUP_FOR_SELFCONSUMPTION,
    },
    false
  ),
  isCIERequired: redukers.value(
    {
      set: actionTypes.SET_IS_CIE_REQUIRED,
    },
    false
  ),
  recordUFD: redukers.value(
    {
      set: actionTypes.SET_RECORD_UFD,
    },
    false
  ),
  portabilityNewcoCheck: redukers.value(
    {
      set: actionTypes.SET_PORTABILITY_NEWCO_CHECK,
    },
    false
  ),
  addresDualAlert: redukers.value(
    {
      set: actionTypes.SET_ADDRESS_DUAL_ALERT,
    },
    false
  ),
  changeSupplyOwnership: redukers.value(
    {
      set: actionTypes.SET_CHANGE_SUPPLY_OWNERSHIP,
      reset: actionTypes.CLEAR_CHANGE_SUPPLY_OWNERSHIP,
    },
    {}
  ),
  changeOwnerCups: redukers.value(
    {
      set: actionTypes.SET_CHANGE_OWNER_CUPS,
      reset: actionTypes.CLEAR_CHANGE_OWNER_CUPS,
    },
    []
  ),
})
