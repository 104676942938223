import * as actionTypes from './action-types'

export const setNotificationState = (notification) => ({
  type: actionTypes.SET_NOTIFICATION_STATE,
  payload: notification,
})

export const clearNotificationState = () => ({
  type: actionTypes.CLEAR_NOTIFICATION_STATE,
})

export const setErrorNotification = (notification) => ({
  type: actionTypes.SET_ERROR_NOTIFICATION,
  payload: notification,
})

export const clearErrorNotification = () => ({
  type: actionTypes.CLEAR_ERROR_NOTIFICATION,
})
