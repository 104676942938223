import * as actionTypes from './action-types'

export const setCampaigns = (campaigns) => ({
  type: actionTypes.SET_CAMPAIGNS,
  payload: campaigns,
})

export const clearCampaigns = () => ({
  type: actionTypes.CLEAR_CAMPAIGNS,
})

export const setAppliedCampaign = (campaign) => ({
  type: actionTypes.SET_APPLIED_CAMPAIGN,
  payload: campaign,
})

export const clearAppliedCampaign = () => ({
  type: actionTypes.CLEAR_APPLIED_CAMPAIGN,
})

export const startCampaignRequest = () => ({
  type: actionTypes.START_CAMPAIGN_REQUEST,
})

export const successCampaignRequest = () => ({
  type: actionTypes.SUCCESS_CAMPAIGN_REQUEST,
})

export const errorCampaignRequest = () => ({
  type: actionTypes.ERROR_CAMPAIGN_REQUEST,
})

export const clearCampaignRequest = () => ({
  type: actionTypes.CLEAR_CAMPAIGN_REQUEST,
})
