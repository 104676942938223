import { createMuiTheme } from '@material-ui/core/styles'
import palette from '../base/palette'

const defaultTheme = createMuiTheme()

const tabs = {
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${palette.primary.light1}`,
      },
    },
    MuiTab: {
      root: {
        fontSize: 18,
        fontWeight: 400,
        textTransform: 'none',
        minWidth: 0,
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 0,
        },
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
    MuiTabPanel: {
      root: {
        padding: defaultTheme.spacing(3, 0),
      },
    },
  },
}

export default tabs
