import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { history } from 'redux/store'
import * as actionTypes from './action-types'

import { getIsOnlineChannel, getIsPhoneChannel, getIsFaceToFaceChannel } from 'services/operator/selectors'
import { getCRMId, getOrders } from './selectors'
import {
  getIsDraft,
  getIsRecoveringDuplicateOrder,
  getIsRecoveringDuplicateOrderSuccess,
  getIsPhoneChannelDraft,
} from 'services/draftCopy/selectors'
import { getLeadId } from 'services/lead/selectors'
import { getCompany, getSupplyAddress } from 'services/customer/selectors'

import {
  successForwardSms,
  errorForwardSms,
  successSign,
  errorSign,
  clearSign,
  clearOrder,
  startAttachDoc,
  successAttachDoc,
  errorAttachDoc,
} from './actions'
import { setErrorNotification } from 'services/notification/actions'
import { clearLead, errorLead, startLead, successLead } from 'services/lead/actions'
import { fetchOrders } from 'services/backoffice/actions'

import { getSignErrorMessage } from './business'
import { sendLead } from 'services/lead/api'
import { triggerSign, finishLocution, cancelLocution, forwardSms, attachDocument } from './api'
import { checkScoring } from 'services/scoring/api'
import { getErrorMessage, getSignParams } from 'utils/commons'

import { ORDER_IRI } from 'services/lead/constants'
import { getSelectedSingleToppings } from 'services/toppings/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { getOnlineChannelUrlParams } from 'services/onlineChannel/business'
import { getRouterSearch } from 'services/analytics/selectors'
import { setOnlineChannelMetaData } from 'services/onlineChannel/actions'

function* checkScoringSaga(leadId) {
  try {
    const scoringResult = yield call(checkScoring, leadId)
    return scoringResult
  } catch (error) {
    throw error
  }
}
function* triggerSignSaga(leadId, signByWhatsapp) {
  try {
    const signResponse = yield call(triggerSign, leadId, signByWhatsapp)
    return signResponse
  } catch (error) {
    throw error
  }
}

function* signSaga({ payload: signByWhatsapp = false }) {
  const leadId = yield select(getLeadId)
  const isDraft = yield select(getIsDraft)
  const isPhoneChannel = yield select(isDraft ? getIsPhoneChannelDraft : getIsPhoneChannel)
  const isFaceToFaceChannel = yield select(getIsFaceToFaceChannel)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const CRMId = yield select(getCRMId)
  const isRecoveringDuplicateOrderRequesting = yield select(getIsRecoveringDuplicateOrder)
  const isRecoveringDuplicateOrderSuccess = yield select(getIsRecoveringDuplicateOrderSuccess)
  const selectedProductArray = yield select(getSelectedRate)
  const rate = selectedProductArray
  const services = yield select(getSelectedSingleToppings)
  const supplyAddress = yield select(getSupplyAddress)
  const company = yield select(getCompany)
  const search = yield select(getRouterSearch)

  const selectedCompanyCode = company?.code || rate[0]?.company?.code
  const onlineChannelParams = getOnlineChannelUrlParams(search)
  const signParams = getSignParams(rate, services, supplyAddress, selectedCompanyCode)
  const metadataParams = { ...onlineChannelParams, ...signParams }
  yield put(setOnlineChannelMetaData(metadataParams))

  try {
    let queryParams =
      Object.entries(signParams)
        ?.map((param) => param.join('='))
        .join('&') || ''
    if (!leadId) throw new Error('No existe id de lead')
    if (!isRecoveringDuplicateOrderRequesting && !isRecoveringDuplicateOrderSuccess) {
      yield call(checkScoringSaga, leadId)
    }
    const signResponse = yield call(triggerSignSaga, leadId, signByWhatsapp)

    yield put(successSign())

    if (isPhoneChannel) {
      yield call(history.push, '/locution')
    } else if (signResponse?.url) {
      window.location.href = `${signResponse.url}`
    } else if (isFaceToFaceChannel) {
      yield call(history.push, `/dashboard`)
    } else if (CRMId) {
      yield call(history.push, `/thank-you?crmId=${CRMId}&${queryParams}`)
    } else {
      // TODO: remove spinner but pending to define flow
      yield put(clearSign())
    }
  } catch (e) {
    console.error(e)
    if (!e) return new Error('Undefined Error')
    const errorMessage = e?.status ? yield call(getErrorMessage, e) : { result: 'Se ha producido un error firmando el contrato' }
    yield put(errorSign())
    if (!isOnlineChannel) {
      yield put(
        setErrorNotification({
          message: getSignErrorMessage(e?.status || 500, errorMessage.result),
          type: 'error',
          isVisible: true,
        })
      )
    }
  }
}

function* finishLocutionSaga({ payload: { data, resumeLocution, callbackResumeLocution } }) {
  const leadId = yield select(getLeadId)
  const orders = yield select(getOrders)
  try {
    yield put(startLead())
    if (!leadId) throw new Error('No existe id de lead')
    const locutionOrderData = orders?.map((order) => ({ id: `${ORDER_IRI}/${order.id}`, ...data }))
    const locutionLead = { orders: locutionOrderData }
    yield call(sendLead, locutionLead, leadId, false)
    yield call(finishLocution, leadId)

    yield put(successLead())
    yield put(clearLead())
    yield put(clearOrder())

    if (resumeLocution) {
      if (callbackResumeLocution) {
        callbackResumeLocution()
        yield put(fetchOrders())
      }
    } else {
      yield call(history.push, '/dashboard')
    }
  } catch (e) {
    console.error(e)
    yield put(errorLead())
  }
}

function* cancelLocutionSaga() {
  const leadId = yield select(getLeadId)
  yield call(cancelLocution, leadId)
  yield call(history.push, '/dashboard')
}

function* forwardSmsSaga({ payload: leadId }) {
  try {
    yield call(forwardSms, leadId)
    yield put(successForwardSms())
  } catch (e) {
    console.error(e)
    const errorMessage = yield call(getErrorMessage, e)
    yield put(errorForwardSms())
    yield put(
      setErrorNotification({
        message: getSignErrorMessage(e.status || e?.response?.status || '', errorMessage.result),
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* attachDocumentSaga({ payload: { data, callbackResume } }) {
  try {
    yield put(startAttachDoc())
    yield call(attachDocument, data.orderId, data.type, data.file)
    yield put(successAttachDoc())
    if (callbackResume) {
      callbackResume()
    }
  } catch (e) {
    console.error(e)
    yield put(errorAttachDoc())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.START_SIGN], signSaga),
    takeLatest([actionTypes.FINISH_LOCUTION], finishLocutionSaga),
    takeLatest([actionTypes.CANCEL_LOCUTION], cancelLocutionSaga),
    takeLatest([actionTypes.START_FORWARD_SMS], forwardSmsSaga),
    takeLatest([actionTypes.ATTACH_DOCUMENT], attachDocumentSaga),
  ])
}
