import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setAppliedCampaign } from 'services/campaigns/actions'

import { DialogActions, DialogContent, DialogTitle, Box, Grid, Button, Radio } from '@material-ui/core'
import Typography from 'ui/components/typography'
import Link from 'ui/components/link'
import useStyles from './styles'
import { fetchLead } from 'services/lead/actions'

const DiscountSelector = ({ handleClose, data: { allCampaigns, appliedCampaign } }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [campaignSelected, setCampaignSelected] = useState(appliedCampaign?.id)

  const handleAccept = () => {
    if (campaignSelected) {
      const newCampaign = allCampaigns.find((campaign) => campaign.id === campaignSelected)
      dispatch(setAppliedCampaign(newCampaign))
      dispatch(fetchLead())
    }
    handleClose()
  }

  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography variant="h3">{t('selectDiscount')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mt={1} mb={4}>
          {!!allCampaigns.length &&
            allCampaigns.map((campaign) => (
              <Grid
                key={campaign.id}
                container
                direction="row"
                alignItems="center"
                className={clsx(classes.content, campaignSelected === campaign.id && 'selected')}
                onClick={() => {
                  setCampaignSelected(campaign.id)
                }}
              >
                <Grid item className={classes.radioContainer}>
                  <Radio color="primary" checked={campaignSelected === campaign.id} name="checkbox-rate" value={campaign.id} />
                </Grid>
                <Grid item>
                  <Typography variant="h5">{campaign.name}</Typography>
                </Grid>
              </Grid>
            ))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box mr={3}>
          <Link component="button" variant="link" onClick={handleClose}>
            {t('common.cancel')}
          </Link>
        </Box>
        <Button color="secondary" variant="contained" onClick={handleAccept}>
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default DiscountSelector
