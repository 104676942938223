import * as actionTypes from './action-types'
export const setPhone = (phoneNumber) => ({
  type: actionTypes.SET_PHONE_NUMBER,
  payload: phoneNumber,
})

export const setLead = (lead) => ({
  type: actionTypes.SET_CALL_ME_BACK_LEAD,
  payload: lead,
})

export const setIdCampaign = (idCampaign) => ({
  type: actionTypes.SET_ID_CAMPAIGN,
  payload: idCampaign,
})

export const requestPhoneNumber = () => ({
  type: actionTypes.REQUEST_PHONE_NUMBER,
})

export const init = () => ({
  type: actionTypes.INIT,
})

export const sendLead = (lead) => ({
  type: actionTypes.SEND_CALL_ME_BACK_LEAD,
  payload: lead,
})

export const setIsSendLeadRequesting = (bool) => ({
  type: actionTypes.SET_IS_SEND_LEAD_REQUESTING,
  payload: bool,
})
