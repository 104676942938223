import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(7),
    justifyContent: 'flex-end',
  },
}))

export default useStyles
