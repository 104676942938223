import alerts from './alerts'
import backdrop from './backdrop'
import buttons from './buttons'
import chips from './chips'
import dialog from './dialog'
import forms from './forms'
import icons from './icons'
import linearProgress from './linearProgress'
import menu from './menu'
import stepper from './stepper'
import svgIcon from './svgIcon'
import tabs from './tabs'
import table from './table'
import tooltip from './tooltip'
import typography from './typography'
import Switch from './switch'

import { mergeDeep } from '../base/utils'

export default mergeDeep(
  alerts,
  backdrop,
  buttons,
  chips,
  dialog,
  forms,
  icons,
  linearProgress,
  menu,
  stepper,
  svgIcon,
  tabs,
  table,
  tooltip,
  typography,
  Switch
)
