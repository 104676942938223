import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    border: `${theme.border.style.medium} solid ${theme.palette.primary.light3}`,
    boxShadow: theme.shadows[3],
    '&.selected': {
      border: `${theme.border.style.medium} solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
    cursor: 'pointer',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(3, 2, 2, 9),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  checkboxContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  batteryAlertStyle: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.5,
  },
}))

export default useStyles
