import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  notificationState: redukers.value(
    {
      set: actionTypes.SET_NOTIFICATION_STATE,
      reset: actionTypes.CLEAR_NOTIFICATION_STATE,
    },
    {
      isVisible: false,
      type: '',
      message: '',
    }
  ),
  errorNotification: redukers.value(
    {
      set: actionTypes.SET_ERROR_NOTIFICATION,
      reset: actionTypes.CLEAR_ERROR_NOTIFICATION,
    },
    {
      isVisible: false,
      type: '',
      message: '',
    }
  ),
})
