export const TEMPLATE_TYPES = {
  orderExport: 0,
  orderView: 1,
}

export const ORDERS_VIEW_TEMPLATE_TAB = 'ordersListTemplateTab'
export const ORDERS_EXPORT_TEMPLATE_TAB = 'ordersExportTemplateTab'

export const DEFAULT_BO_TEMPLATES_ROUTE = '/settings/backoffice-templates'

export const TEMPLATE_CONFIG = {
  [TEMPLATE_TYPES.orderExport]: {
    titleNewKey: 'settings.backofficeTemplates.form.orderExport.create',
    titleEditKey: 'settings.backofficeTemplates.form.orderExport.edit',
    subtitleKey: 'settings.backofficeTemplates.form.orderExport.subtitle',
    url: `${DEFAULT_BO_TEMPLATES_ROUTE}/order-export`,
    tab: ORDERS_EXPORT_TEMPLATE_TAB,
  },
  [TEMPLATE_TYPES.orderView]: {
    titleNewKey: 'settings.backofficeTemplates.form.orderView.create',
    titleEditKey: 'settings.backofficeTemplates.form.orderView.edit',
    subtitleKey: 'settings.backofficeTemplates.form.orderView.subtitle',
    url: `${DEFAULT_BO_TEMPLATES_ROUTE}/order-view`,
    tab: ORDERS_VIEW_TEMPLATE_TAB,
  },
}
