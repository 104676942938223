import i18n from 'i18n'
import { SERVICES, TOPPINGS_SERVICE_TYPE, GA_FILTERS_SERVICE, GA_EVENT_NAMES } from '../../utils/constants'

// Util functions
const parseServicesTitle = (title, type) => {
  return `${i18n.t(TOPPINGS_SERVICE_TYPE[type])} - ${i18n.t(title)}`
}

const parseTitle = (title, type) => {
  return `${i18n.t(SERVICES[type])} - ${i18n.t(title)}`
}

const mapServices = (services) =>
  Object.keys(services).reduce((acc, value) => {
    const { title, type } = services[value]
    acc.push(parseServicesTitle(title, type))
    return acc
  }, [])

export const mapServicesNames = (services) =>
  Object.keys(services).reduce((acc, value) => {
    acc.push(services[value].name)
    return acc
  }, [])

const mapProducts = (product) => {
  let parsedTitle = ''
  if (product.length > 0) {
    product.forEach((item, index) => {
      const { title, energyType } = item
      parsedTitle += `${parseTitle(title, energyType)}${index < product.length - 1 ? ' | ' : ''}`
    })
  } else {
    const { title, energyType } = product
    parsedTitle = parseTitle(title, energyType)
  }
  return parsedTitle
}

export const mapProductsAndServices = (product, services) => {
  const mappedServices = services ? mapServices(services).join('|') : ''
  const mappedProducts =
    product && services
      ? `${mapProducts(product)}|`
      : !services && product
      ? mapProducts(product)
      : i18n.t('common.contractOnlyMaintenance')

  const mappedProductsAndServices = `${mappedProducts}${mappedServices}`
  return mappedProductsAndServices
}

export const mapCheckoutServices = (services, step) =>
  Object.keys(services).reduce((acc, value) => {
    const {
      name,
      title,
      type,
      servicePrices: [{ priceAmount: price }],
      residential,
      pyme,
    } = services[value]

    const customSrc = residential && !pyme ? 'hogar' : !residential && pyme ? 'pyme' : residential && pyme ? 'hogar - pyme' : ''
    const category = `${customSrc} / servicio / ${name} / ${i18n.t(TOPPINGS_SERVICE_TYPE[type]).toLowerCase()} / ${i18n
      .t(title)
      .toLowerCase()}`
    const service = {
      name: i18n.t(title).toLowerCase(),
      price,
      quantity: 1,
      category,
      id: value,
      list: 'checkout',
    }

    if (!!step) {
      service.step = step + 1
    }
    acc.push(service)
    return acc
  }, [])

export const getCheckoutObjectRate = (rate, step) => {
  const { title, name, energyType, id, residential, pyme } = rate
  let price = 0
  let quantity = 1
  if (rate.subProducts) {
    price = rate.subProducts.reduce((a, v) => {
      if (v.calculatedCost) {
        return a + v.calculatedCost[v.energyType]
      } else {
        return a
      }
    }, 0)
    quantity = rate.subProducts.length
  } else if (rate.calculatedCost) {
    price = rate.calculatedCost[energyType]
  }
  const customSrc = residential && !pyme ? 'hogar' : !residential && pyme ? 'pyme' : residential && pyme ? 'hogar - pyme' : ''
  const category = `${customSrc} / producto / ${name} / ${i18n.t(SERVICES[energyType]).toLowerCase()} / ${i18n
    .t(title)
    .toLowerCase()}`

  const result = {
    name: i18n.t(title).toLowerCase(),
    price,
    quantity,
    category,
    id: id.split('/').pop(),
    list: 'checkout',
  }

  if (!!step) {
    result.step = step + 1
  }

  return result
}

/**
 * Gets the information for the ecommerce data of the event
 *
 * @param {Array} products - List of products or services
 * @param {Number | String} step
 * @returns {Object} - The ecommerce info
 */
export const getEcommerceData = (products, step, type) => {
  let actionStep = step
  // Sign step (-1) becomes 'validacion'
  if (actionStep !== 'compra') {
    actionStep = step < 0 ? 'validacion' : step + 1
  }

  const result = {
    [type]: {
      actionField: {
        step: actionStep,
        option: 'general',
      },
      products: products.map((product) => {
        // reference: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=es#checkoutstep
        const { name, id, price, category, quantity } = product
        return {
          name,
          id, // transaction id if it is purchase or refund, else product/service id
          price,
          brand: 'Naturgy',
          category,
          quantity,
        }
      }),
    },
  }

  return result
}

// Analytics
const datalayer = window.dataLayer

export const trackPage = (data) => {
  if (!datalayer || !datalayer.push) return

  // TODO Refine: https://redradix.atlassian.net/browse/NATS2-1289
  datalayer.push({
    event: 'naturgy.pageview',
    eventName: 'pagina_vista',
    eventAction: 'navegacion',
    ...data,
  })
}

export const trackEcommerce = (data) => {
  if (!datalayer || !datalayer.push) return
  datalayer.push(data)
}

export const trackEvent = (event, data, params) => {
  const formattedData = { ...data, ...params }
  if (!datalayer || !datalayer.push || !trackEvents[event]) return
  const eventData = trackEvents[event](formattedData)
  datalayer.push({ event: 'naturgy.event', ...eventData, ...data })
}

// Event Names

const {
  logo,
  autoSave,
  autoSavePopUp,
  cancelPopUp,
  savePopUp,
  closePopUp,
  ownerAlert,
  menuLanguage,
  selectionLanguage,
  weCallYou,
  weCallYouPopUp,
  callMePopUp,
  telNum,
  clickPrices,
  showPricesModal,
  closePricesModal,
  conditionsProductClick,
  conditionsPopUp,
  cancelConditionsPopUp,
  closeConditionsPopUp,
  discountsConditionsClick,
  discountsConditionsPopUp,
  cancelDiscountPopUp,
  closeDiscountPopUp,
  checkChangeSupplyOwnership,
  privacyPolicy,
  checkAcceptancePrivacyPolicy,
  clickBonoSocial,
  clickContinue,
  openHelpModal,
  aceptoModal,
  noAceptoModal,
  clickTopping,
  validateAndSign,
  thankYouPage,
  editStep,
  uploadInvoiceImage,
  popUpUploadImage,
  clickSelectFile,
  clickContinueUploadImage,
  popUpSelectFile,
  selectCupsByCups,
  selectCupsByAddress,
  clickNNSS,
  checkNNSS,
  continueNNSS,
  enterCheckState,
  infoInvoice,
  openInfoModal,
  closeInfoModal,
  cupsInfo,
  openCupsInfo,
  closeCupsInfo,
  editSupplyPoint,
  openNewClientModal,
  cancelNewSupply,
  newSupplyTechModal,
  editCupsPopUp,
  saveCupsPopUp,
  cancelCupsPopUp,
  finishCupsPopUp,
  errorCupsPopUp,
  loginClick,
  differentBilling,
  clickSEPA,
  clickServiceConditions,
  openServiceConditions,
  closeServiceConditions,
  clickSericeSegment,
  openPopUpTramit,
  closeDetailModal,
  leadError,
} = GA_EVENT_NAMES

// Page tags
const translateService = (service) => GA_FILTERS_SERVICE[service]
// const parseCampaignEventAction = (campaign) => `promocion_${campaign}`
// const parseProductConditionEventAction = (product) => `condiciones_${product}`
// const formattedAction = (eventAction) => `${eventAction}_proceso contratacion`
//const language = i18n.language
const currentUrl = window.location.href
const trackEvents = {
  [logo]: ({ segmento, nextUrl }) => ({
    eventName: 'select_header',
    eventAction: 'logo',
    eventCategory: 'logo',
    eventLabel: `logo-select_header-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [autoSave]: ({ segmento, nextUrl }) => ({
    eventName: 'select_header',
    eventAction: 'autoguardado',
    eventCategory: 'enlace_texto',
    eventLabel: `autoguardado-select_header-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [autoSavePopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'autoguardado',
    eventCategory: 'popup',
    eventLabel: `autoguardado-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [cancelPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'cancelar',
    eventCategory: 'calltoaction',
    eventLabel: `cancelar-autoguardado-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [savePopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'guardar_borrador',
    eventCategory: 'calltoaction',
    eventLabel: `guardar_borrador-autoguardado-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [closePopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar-autoguardado-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [ownerAlert]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'necesario',
    eventCategory: 'alerta',
    eventLabel: `alerta-necesario-autoguardado-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [menuLanguage]: ({ segmento, nextUrl }) => ({
    eventName: 'select_header',
    eventAction: 'idioma',
    eventCategory: 'desplegable',
    eventLabel: `idioma-select_header-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [selectionLanguage]: ({ segmento, nextUrl, idioma }) => ({
    eventName: 'select_header',
    eventAction: `idioma_${idioma}`,
    eventCategory: 'enlace_texto',
    eventLabel: `idioma_${idioma}-select_header-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [weCallYou]: ({ segmento, nextUrl }) => ({
    eventName: 'select_header',
    eventAction: 'te_llamamos',
    eventCategory: 'enlace_texto',
    eventLabel: `te_llamamos-select_header-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [weCallYouPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'te_llamamos',
    eventCategory: 'popup',
    eventLabel: `te_llamamos-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [callMePopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'llamadme',
    eventCategory: 'calltoaction',
    eventLabel: `llamadme-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [telNum]: ({ telNum, segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: telNum, //indicar el nº clickado por el usuario
    eventCategory: 'enlace_texto',
    eventLabel: `${telNum}-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [clickPrices]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'ver_precios',
    eventCategory: 'enlace_texto',
    eventLabel: `ver_precios-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [showPricesModal]: ({ tariff, segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'ver_precios',
    eventCategory: 'popup',
    eventLabel: `ver_precios-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
    tarifa: tariff,
  }),
  [closePricesModal]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar-ver_precios-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [conditionsProductClick]: ({ segmento, service, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'ver_condiciones',
    eventCategory: 'enlace_texto',
    eventLabel: `ver_condiciones-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
    servicio: translateService(service),
  }),
  [conditionsPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'ver_condiciones',
    eventCategory: 'popup',
    eventLabel: `ver_condiciones-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [cancelConditionsPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'calltoaction',
    eventLabel: `cerrar_condiciones-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [closeConditionsPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar_condiciones-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [discountsConditionsClick]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'ver_condiciones_oferta',
    eventCategory: 'enlace_texto',
    eventLabel: `ver_condiciones_oferta-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [discountsConditionsPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'show_darwin',
    eventAction: 'ver_condiciones_oferta',
    eventCategory: 'popup',
    eventLabel: `ver_condiciones_oferta-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [cancelDiscountPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'calltoaction',
    eventLabel: `cerrar_condiciones_oferta-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [closeDiscountPopUp]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar_condiciones_oferta-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [checkChangeSupplyOwnership]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'titular_diferente',
    eventCategory: 'checkbox',
    eventLabel: `titular_diferente-select_darwin-${segmento}-${currentStep}`,
  }),
  [privacyPolicy]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'politica_privacidad',
    eventCategory: 'enlace_texto',
    eventLabel: `politica_privacidad-select_darwin-${segmento}-${currentStep}`,
  }),
  [checkAcceptancePrivacyPolicy]: ({ segmento, currentStep, checkName }) => ({
    eventName: 'select_darwin',
    eventAction: checkName, // [datos|comunicados|perfil]
    eventCategory: 'checkbox',
    eventLabel: `${checkName}-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickBonoSocial]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'bono_social',
    eventCategory: 'enlace_texto',
    eventLabel: `bono_social-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickContinue]: ({ segmento, currentStep, nextStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'continuar',
    eventCategory: 'calltoaction',
    eventLabel: `continuar-select_darwin-${segmento}-${currentStep}-${nextStep}`,
  }),
  [openHelpModal]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'ayuda',
    eventCategory: 'popup',
    eventLabel: `ayuda-show_darwin-${segmento}-${currentStep}`,
  }),
  [noAceptoModal]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'no_acepto',
    eventCategory: 'calltoaction',
    eventLabel: `no_acepto-ayuda-select_darwin-${segmento}-${currentStep}`,
  }),

  [aceptoModal]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'acepto',
    eventCategory: 'calltoaction',
    eventLabel: `acepto-ayuda-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickTopping]: ({ topping, segmento, currentStep }) => ({
    event: 'naturgy.event',
    eventName: 'select_darwin',
    eventCategory: 'checkbox',
    eventAction: topping?.title,
    eventLabel: `${topping?.title}-select_darwin-${segmento}-${currentStep}`,
  }),
  [validateAndSign]: ({ segmento, currentStep, nextStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'validar_y_firmar',
    eventCategory: 'calltoaction',
    eventLabel: `validar_y_firmar-select_darwin-${segmento}-${currentStep}-${nextStep}`,
  }),
  [thankYouPage]: ({ segmento }) => ({
    eventName: 'select_darwin',
    eventAction: 'thank_you',
    eventCategory: 'goal',
    eventLabel: `thank_you-select_darwin-${segmento}-thank_you`,
  }),
  [editStep]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: 'editar',
    eventCategory: 'calltoaction',
    eventLabel: `editar-select_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  [uploadInvoiceImage]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'subir_imagen',
    eventCategory: 'calltoaction',
    eventLabel: `subir_imagen-select_darwin-${segmento}-${currentStep}`,
  }),
  [popUpUploadImage]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'subir_imagen',
    eventCategory: 'popup',
    eventLabel: `recuperacion-datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [popUpSelectFile]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'seleccionar_archivo',
    eventCategory: 'popup',
    eventLabel: `seleccionar_archivo-show_darwin-${segmento}-${currentStep}`,
  }),
  [clickSelectFile]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'seleccionar_archivo',
    eventCategory: 'calltoaction',
    eventLabel: `selecionar_archivo-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),

  [clickContinueUploadImage]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'continuar',
    eventCategory: 'calltoaction',
    eventLabel: `continuar-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [selectCupsByCups]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'comprobar',
    eventCategory: 'calltoaction',
    eventLabel: `comprobar-select_darwin-${segmento}-${currentStep}`,
  }),
  [selectCupsByAddress]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'comprobar_direccion',
    eventCategory: 'calltoaction',
    eventLabel: `comprobar_direccion-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickNNSS]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'nueva_alta',
    eventCategory: 'calltoaction',
    eventLabel: `nueva_alta-select_darwin-${segmento}-${currentStep}`,
  }),
  [checkNNSS]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'confirmar',
    eventCategory: 'calltoaction',
    eventLabel: `confirmar-verifica_alta_nuevo-select_darwin-${segmento}-${currentStep}`,
  }),
  [continueNNSS]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'continuar',
    eventCategory: 'calltoaction',
    eventLabel: `continuar-mas_datos_suministro-select_darwin-${segmento}-${currentStep}`,
  }),
  [enterCheckState]: ({ segmento }) => ({
    eventName: 'select_darwin',
    eventAction: 'entrar',
    eventCategory: 'calltoaction',
    eventLabel: `entrar-select_darwin-${segmento}`,
  }),
  [infoInvoice]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'informacion',
    eventCategory: 'icono',
    eventLabel: `informacion-recuperacion-datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [openInfoModal]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'lopd',
    eventCategory: 'popup',
    eventLabel: `lopd-show_darwin-${segmento}-${currentStep}`,
  }),
  [closeInfoModal]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar-lopd-select_darwin-${segmento}-${currentStep}`,
  }),
  [cupsInfo]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'informacion_cups',
    eventCategory: 'icono',
    eventLabel: `informacion-cups-select_darwin-${segmento}-${currentStep}`,
  }),
  [openCupsInfo]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'cups',
    eventCategory: 'popup',
    eventLabel: `cups-show_darwin-${segmento}-${currentStep}`,
  }),
  [closeCupsInfo]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'cerrar',
    eventCategory: 'enlace_texto',
    eventLabel: `cerrar-informacion_cups-select_darwin-${segmento}-${currentStep}`,
  }),
  [editSupplyPoint]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'editar',
    eventCategory: 'calltoaction',
    eventLabel: `editar_direccion-select_darwin-${segmento}-${currentStep}`,
  }),
  [openNewClientModal]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'verifica_alta_nuevo',
    eventCategory: 'popup',
    eventLabel: `verifica_alta_nuevo-show_darwin-${segmento}-${currentStep}`,
  }),
  [cancelNewSupply]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'cancelar',
    eventCategory: 'enlace_texto',
    eventLabel: `cancelar-verifica_alta_nuevo-select_darwin-${segmento}-${currentStep}`,
  }),
  [newSupplyTechModal]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'carga',
    eventCategory: 'popup',
    eventLabel: `mas_datos_suministro-show_darwin-${segmento}-${currentStep}`,
  }),
  [editCupsPopUp]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'editar_cups',
    eventCategory: 'enlace_texto',
    eventLabel: `editar_cups-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [saveCupsPopUp]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'guardar_cups',
    eventCategory: 'enlace_texto',
    eventLabel: `guardar_cups-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [cancelCupsPopUp]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'cancelar',
    eventCategory: 'calltoaction',
    eventLabel: `cancelar-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [finishCupsPopUp]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'finalizar',
    eventCategory: 'calltoaction',
    eventLabel: `finalizar-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [errorCupsPopUp]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: 'error_formato_cups',
    eventCategory: 'alerta',
    eventLabel: `error_formato_cups-recuperacion_datos-select_darwin-${segmento}-${currentStep}`,
  }),
  [loginClick]: ({ segmento }) => ({
    eventName: 'select_darwin',
    eventAction: 'iniciar_sesion',
    eventCategory: 'calltoaction',
    eventLabel: `iniciar_sesion-select_darwin-${segmento}-login`,
  }),
  [differentBilling]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'diferente_direccion_facturacion',
    eventCategory: 'checkbox',
    eventLabel: `diferente_direccion_facturacion-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickSEPA]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: 'SEPA',
    eventCategory: 'enlace_texto',
    eventLabel: `SEPA-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickServiceConditions]: ({ segmento, currentStep, service }) => ({
    eventName: 'select_darwin',
    eventAction: `ver_condiciones_${service}`,
    eventCategory: 'enlace_texto',
    eventLabel: `ver_condiciones_${service}-select_darwin-${segmento}-${currentStep}`,
  }),
  [openServiceConditions]: ({ segmento, currentStep, service }) => ({
    eventName: 'show_darwin',
    eventAction: `condiciones_${service}`,
    eventCategory: 'popup',
    eventLabel: `condiciones_${service}-show_darwin-${segmento}-${currentStep}`,
  }),
  [closeServiceConditions]: ({ segmento, currentStep, service }) => ({
    eventName: 'select_darwin',
    eventAction: `cerrar`,
    eventCategory: 'calltoaction',
    eventLabel: `cerrar_${service}-select_darwin-${segmento}-${currentStep}`,
  }),
  [clickSericeSegment]: ({ segmento, currentStep, service }) => ({
    eventName: 'select_darwin',
    eventAction: `${service}`,
    eventCategory: 'calltoaction',
    eventLabel: `${service}-select_darwin-${segmento}-${currentStep}`,
  }),
  [openPopUpTramit]: ({ segmento, currentStep }) => ({
    eventName: 'show_darwin',
    eventAction: `tramite_previo`,
    eventCategory: 'popup',
    eventLabel: `tramite_previo-show_darwin-${segmento}-${currentStep}`,
  }),
  [closeDetailModal]: ({ segmento, currentStep }) => ({
    eventName: 'select_darwin',
    eventAction: `cerrar`,
    eventCategory: 'calltoaction',
    eventLabel: `cerrar-tramite_previo-select_darwin-${segmento}-${currentStep}`,
  }),
  [leadError]: ({ segmento, nextUrl }) => ({
    eventName: 'select_darwin',
    eventAction: `error_contratacion`,
    eventCategory: 'alerta',
    eventLabel: `error_contratacion-show_darwin-${segmento}-${currentUrl}-${nextUrl || currentUrl}`,
  }),
  // [changeCampaignClick]: ({ service, campaign }) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: parseCampaignEventAction(campaign),
  //   eventLabel: translateService(service),
  // }),
  // [selectRate]: (rate) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `contratacion_${i18n.t(rate.title)}`,
  //   eventLabel: translateService(rate.energyType),
  // }),
  // [chooseAnotherRate]: (text) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `ko_contratacion`,
  //   eventLabel: text,
  // }),
  // [callMeBackButton]: (text) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `call me back ko`,
  //   eventLabel: text,
  // }),
  // [nextStep]: ({ service, activeStep, product }) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `siguiente step-${activeStep + 1}`,
  //   eventLabel: product ? `${product}_${translateService(service)}` : translateService(service),
  // }),
  // [newContract]: (channel) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'dar de alta',
  //   eventLabel: channel,
  // }),
  // [calculatorEvents]: ({ eventAction, eventLabel }) => ({
  //   event: 'event.calculadora',
  //   eventCategory: 'calculadora',
  //   eventAction: formattedAction(eventAction),
  //   eventLabel: eventLabel,
  // }),
  // [checkoutEvent]: (data) => ({
  //   event: 'event.ecommerce',
  //   ...data,
  // }),
  // [signContractEvent]: (data) => ({
  //   ...data,
  // }),
  // [callMeBackClick]: () => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'llamadme',
  //   eventLabel: 'contratacion_superior',
  // }),
  // [serviceDetails]: (data) => {
  //   const productStepIndex = CONTRACT_STEPS.findIndex((step) => step.id === STEPS.rate)
  //   const parsedService = mapCheckoutServices([data], productStepIndex)
  //   const ecommerce = getEcommerceData(parsedService, productStepIndex, 'detail')
  //   return {
  //     event: 'view-details',
  //     eventCategory: 'ecommerce',
  //     eventAction: 'product click',
  //     ecommerce,
  //   }
  // },
  // [clickServiceDetails]: ({ product, service }) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `ver detalle_${product}`,
  //   eventLabel: service,
  // }),
  // [selectTopping]: ({ product, service }) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: `contratacion_${product}`,
  //   eventLabel: service,
  // }),
  //
  // [callMeBackClickPhone]: (phone) => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'llamanos',
  //   eventLabel: `ventas_${phone}`,
  // }),
  // [clickLogin]: () => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'inicia sesion',
  //   eventLabel: 'ventas - areaclientes',
  // }),
  // [sircaFail]: () => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'cancelar proceso contratacion',
  //   eventLabel: 'fallo scoring',
  // }),
  // [callMeBackCallMe]: () => ({
  //   event: 'event.naturgy',
  //   eventCategory: 'callToAction',
  //   eventAction: 'llamadme',
  //   eventLabel: 'cliente:ok-ventas',
  // }),
  // [loadThankYouPage]: (eventData) => ({
  //   event: 'purchase',
  //   ...eventData,
  // }),
}
