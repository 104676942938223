import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paperShow: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 2),
    },
  },
  paperHide: {
    boxShadow: 'none',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
  labelText: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  check: {
    marginRight: theme.spacing(1),
    color: theme.palette.feedback.success,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  cancelContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
}))

export default useStyles
