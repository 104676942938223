import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import CallMeBackForm from 'ui/forms/callMeBack'

import CallMeBackView from 'ui/modals/callMeBack'
import { validatePhone } from 'utils/validators'
import { useDispatch, useSelector } from 'react-redux'
import { init, sendLead } from 'services/callMeBack/actions'
import { getIdCampaign, getLead, getPhoneNumber, getIsSendLeadRequesting } from 'services/callMeBack/selectors'
import { Connect } from 'services/callMeBack/business'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES } from 'utils/constants'

const CallMeBack = ({ handleClose }) => {
  const { t } = useTranslation()
  const idCampaign = useSelector(getIdCampaign)
  const lead = useSelector(getLead)
  const phone = useSelector(getPhoneNumber)
  const isSendLeadRequesting = useSelector(getIsSendLeadRequesting)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['phone']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })

    if (!errors.phone && !validatePhone(values.phone)) {
      errors.phone = t('form.errors.invalidFormat')
    }

    return errors
  }

  const handleFormSubmit = async (formData, { resetForm }) => {
    const formLead = { ...lead }
    formLead.user.phone = formData.phone
    formLead.form.type = 'CMB'
    formLead.source.campaignId = idCampaign
    formLead.source.url = window.location.href
    try {
      setError(false)
      setSuccess(false)
      dispatch(sendLead(formLead))
      dispatch(sendGAEvent(GA_EVENT_NAMES.callMePopUp))
      dispatch(sendGAEvent(GA_EVENT_NAMES.callMeBackCallMe))
      setSuccess(true)
      resetForm({})
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }

  useEffect(() => {
    if (!Connect.token) {
      dispatch(init())
    }
    dispatch(sendGAEvent(GA_EVENT_NAMES.weCallYouPopUp))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickPhone = (phone) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.telNum, { telNum: phone }))
  }

  return (
    <CallMeBackView
      error={error}
      phone={phone}
      success={success}
      isSendLeadRequesting={isSendLeadRequesting}
      onClickPhone={handleClickPhone}
    >
      <Formik initialValues={{ phone: '' }} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
        {({ handleChange, touched, errors, values }) => (
          <>
            <Form noValidate>
              <CallMeBackForm
                onChange={handleChange}
                onCancel={handleClose}
                touched={touched}
                errors={errors}
                values={values}
                isFetching={isSendLeadRequesting}
              />
            </Form>
          </>
        )}
      </Formik>
    </CallMeBackView>
  )
}

export default CallMeBack
