export const SERVICE = 'CONTROL'
export const SOLD_ORDER_STATUS_GROUPS = [
  'in_activation',
  'failed',
  // "missing_failed-failed_without_recovery",
  'signed',
  'finished',
  // "missing_in_activation-sent_to_retry",
  // "missing_failed-sent_to_retry",
  // "pending_verification"
]

export const VISUALIZATION_TEMPLATE = '5c9098ac-9441-4fc5-83b2-87dfe18faeaf'
export const EXPORT_TEMPLATE = ''
export const MAX_EXPORT_TIME_S = 360
export const MAX_EXPORT_ORDERS = 1_000_000
export const PRODUCTS_PER_PAGE = 5_000

export const DOCOUT_SOURCE_ERRORS = ['SIEBEL', 'EVOLUCIONA']

export const FAILED_MANAGED_STATE = 'failed_managed'
export const FAILED_WITHOUT_RECOVERY_STATE = 'failed_without_recovery'

export const FAILED_STATES = [
  'failed',
  'signature_requested-locution_failed',
  'locution_failed-signature_requested',
  'signature_success-locution_failed',
  'locution_failed-signature_success',
  'signature_failed-locution_success',
  'locution_success-signature_failed',
  'signature_failed-locution_requested',
  'locution_requested-signature_failed',
  FAILED_MANAGED_STATE,
  FAILED_WITHOUT_RECOVERY_STATE,
]

export const INACTIVE_STATE = 'in_activation'

export const PRODUCT_TRANSITION_STATUS = {
  ATR_ORDER: 'Solicitud ATR',
  ORDER_SENT: 'Solicitud enviada',
  ORDER_ACCEPTED: 'Solicitud aceptada',
  ORDER_REJECTED: 'Solicitud rechazada',
}

export const PRODUCT_KO_GDA_STATUS = {
  INACTIVE: 'Inactivo',
}

export const TRANSACTIONAL_PRODUCT_STATE = {
  REFUSED: 'Rechazado',
}
