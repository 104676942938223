import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  legalTerms: {
    '& table': {
      backgroundColor: theme.palette.primary.light3,
      color: theme.palette.primary.main,
      borderCollapse: 'separate',
      borderColor: 'white',
      borderLeft: 0,
      borderRadius: theme.spacing(1),
    },
    '& th': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      verticalAlign: 'middle',
      padding: theme.spacing(2),
      lineHeight: 1.42857143,
    },
    '& td': {
      verticalAlign: 'middle',
      padding: theme.spacing(2),
      lineHeight: 1.42857143,
    },
  },
}))

export default useStyles
