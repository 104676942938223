import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  signUpForSelfConsumption: {
    fontWeight: 300,
    // fontSize: 12,
  },
  collective: {
    fontWeight: 300,
  },
  centered: {
    alignItems: 'center',
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      margin: 0,
    }
  },
}))

export default useStyles
