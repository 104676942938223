export const widgetTranslations = (t) => {
  return {
    address: {
      placeholder: t('widget.address.placeholder'),
      submit: t('widget.address.submit'),
      loadingSubmit: t('widget.address.loadingSubmit'),
      noResults: t('widget.address.noResults'),
      mainLabel: t('widget.address.mainLabel'),
      label: t('widget.address.label'),
      secondLabel: t('widget.address.secondLabel'),
      loading: t('widget.address.loading'),
      wrongCup: t('widget.address.wrongCup'),
      serverError: t('widget.address.serverError'),
      serverErrorNoCups: t('widget.address.serverErrorNoCups'),
      unknownAddress: t('widget.address.unknownAddress'),
      placeHolderNumber: t('widget.address.placeHolderNumber'),
      labelNumber: t('widget.address.labelNumber'),
      noValidNumber: t('widget.address.noValidNumber'),
      noFoundNumber: t('widget.address.noFoundNumber'),
      noFoundAddress: t('widget.address.noFoundAddress'),
      selectPlaceholder: t('widget.address.selectPlaceholder'),
      selectLabel: t('widget.address.selectLabel'),
      selectNoResults: t('widget.address.selectNoResults'),
      selectUniqueAddress: t('widget.address.selectUniqueAddress'),
      selectBuilding: t('widget.address.selectBuilding'),
      selectStaircase: t('widget.address.selectStaircase'),
      selectFloor: t('widget.address.selectFloor'),
      selectDoor: t('widget.address.selectDoor'),
      newRegister: {
        newRegisterLabel: t('widget.address.newRegister.newRegisterLabel'),
        extraDataLabel: t('widget.address.newRegister.extraDataLabel'),
        placeHolderBuilding: t('widget.address.newRegister.placeHolderBuilding'),
        placeHolderStaircase: t('widget.address.newRegister.placeHolderStaircase'),
        placeHolderFloor: t('widget.address.newRegister.placeHolderFloor'),
        placeHolderDoor: t('widget.address.newRegister.placeHolderDoor'),
        placeHolderExtraData: t('widget.address.newRegister.placeHolderExtraData'),
        formatErrorBuilding: t('widget.address.newRegister.formatErrorBuilding'),
        formatErrorStaircase: t('widget.address.newRegister.formatErrorStaircase'),
        formatErrorFloor: t('widget.address.newRegister.formatErrorFloor'),
        formatErrorDoor: t('widget.address.newRegister.formatErrorDoor'),
        tooltipTitle: t('widget.address.newRegister.tooltipTitle'),
        tooltipContentText: t('widget.address.newRegister.tooltipContentText'),
      },
      warningNewRegisterNewco: t('widget.address.warningNewRegisterNewco'),
      warningNewRegisterIberia: t('widget.address.warningNewRegisterIberia'),
      warningNewco: t('widget.address.warningNewco'),
      warningIberia: t('widget.address.warningIberia'),
      warningGeneric: t('widget.address.warningGeneric'),
      warningCUPS: t('widget.address.warningCUPS'),
    },
    cups: {
      placeholder: t('widget.cups.placeholder'),
      submit: t('widget.cups.submit'),
      noResults: t('widget.cups.noResults'),
      mainLabel: t('widget.cups.mainLabel'),
      label: t('widget.cups.label'),
      mainLabelElectricity: t('widget.cups.mainLabelElectricity'),
      mainLabelGas: t('widget.cups.mainLabelGas'),
      labelElectricity: t('widget.cups.labelElectricity'),
      labelGas: t('widget.cups.labelGas'),
      secondLabel: t('widget.cups.secondLabel'),
      wrongCup: t('widget.cups.wrongCup'),
      invalidCup: t('widget.cups.invalidCup'),
      unknownCup: t('widget.cups.unknownCup'),
      switchText: t('widget.cups.switchText'),
      tooltipTitle: t('widget.cups.tooltipTitle'),
      tooltipContentText: t('widget.cups.tooltipContentText'),
      tooltipContentImg: t('widget.cups.tooltipContentImg'),
      serverError: t('widget.cups.serverError'),
    },
  }
}

export default widgetTranslations
