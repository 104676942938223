import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import * as actionTypes from './action-types'
import * as customerActionTypes from 'services/customer/action-types'

import {
  getCustomer,
  getSupplyPoint,
  getCompany,
  getCustomerRetargeting,
  getIsAutosave,
  getSelectedTenure,
  getCustomerData,
  getUserData,
  getReferralCode,
} from 'services/customer/selectors'
import { getProducts, getSelectedRate } from 'services/products/selectors'
import {
  getOperator,
  getIsPhoneChannel,
  getIsOnlineChannel,
  getReferenceSegment,
  getSelectedChannel,
} from 'services/operator/selectors'
import { getGdprSent, getGuidelineDate, getOrder, getScoringSent } from 'services/order/selectors'
import { getToppings } from 'services/toppings/selectors'
import { getCampaigns } from 'services/campaigns/selectors'

import { getEnergies } from 'services/energies/selectors'
import { getConsumptionByEnergy } from 'services/energies/business'
import { getSupplyPointConsumption, getProductReferralCode } from 'services/customer/business'

import { setStepUncompleted, setActiveStepById } from 'services/stepper/actions'
import { startSendDraftData } from 'services/draftCopy/actions'

import { sendLead } from './api'
import { getIsLeadFetching, getLead, getLeadId, getBillingAddressId, getSupplyAddressesIds } from './selectors'
import { ACTIONS_STEPS } from './constants'
import {
  startLead,
  setLeadId,
  successLead,
  errorLead,
  clearLead,
  setLeadErrorMessage,
  setBillingAddressId,
  setSupplyAddressesIds,
} from './actions'
import { getOrders, mapLeadData } from './business'
import {
  getDraftLead,
  getDraftOrder,
  getOnlineChannelDraft,
  getIsDraftOnlineChannelFetching,
  getDuplicateOrderDraft,
  getIsDuplicateOrderDraft,
  getIsRecoveringDuplicateOrder,
  getIsRecoveringDuplicateOrderSuccess,
  getIsDraft,
} from 'services/draftCopy/selectors'
import { PYME, ID_NATURGY_IMPERIAL, ELECTRICITY, GAS } from 'utils/constants'
import { getOnlineChannelMetadata } from 'services/onlineChannel/selectors'
import { setOnBehalfContactId, setProductReferralCode } from 'services/customer/actions'
import { setCRMId, setGdprSent, setOrders, setScoringSent } from 'services/order/actions'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { checkScoring } from 'services/scoring/api'
import { triggerGdpr } from 'services/order/api'
import { getErrorMessage } from 'utils/commons'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { getAllProductVariants } from 'services/productVariant/api'
import { getTypes } from 'services/selfconsumption/selectors'
import { getCupsSearchByCups } from 'services/cupsSearcher/selectors'
import { setCupsSearcherByCups } from 'services/cupsSearcher/actions'
import { getActiveStep, getActiveStepDuplicate } from 'services/stepper/selectors'
import { fetchVirtualBattery } from 'services/toppings/actions'

function* fetchSaga(action) {
  let byCups = yield select(getCupsSearchByCups)
  const billingAddressId = yield select(getBillingAddressId)
  const supplyAddressesIds = yield select(getSupplyAddressesIds)
  const customer = yield select(getCustomer)
  const company = yield select(getCompany)
  const lead = yield select(getLead)
  const tenure = yield select(getSelectedTenure)
  const isDraftLead = yield select(getDraftLead)
  const isDraftOrder = yield select(getDraftOrder)
  const activeStep = yield select(getActiveStep)
  const activeStepDuplicate = yield select(getActiveStepDuplicate)
  const isDraft = yield select(getIsDraft)
  const customerData = yield select(getCustomerData)
  const userData = yield select(getUserData)
  const order =
    Object.keys(isDraftLead).length > 0 &&
    Object.keys(isDraftOrder).length > 0 &&
    (!customer?.supplyPoints?.length >= 2 || customer.supplyPoints === null)
      ? yield select(getDraftOrder)
      : yield select(getOrder)

  const orderDraft = yield select(getDraftOrder)
  const duplicateOrdeDraft = yield select(getDuplicateOrderDraft)
  const products = yield select(getProducts)
  const operator = yield select(getOperator)
  const toppings = yield select(getToppings)
  const campaigns = yield select(getCampaigns)
  const isPhoneChannel = yield select(getIsPhoneChannel)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const referenceSegment = yield select(getReferenceSegment)
  const guidelineDate = yield select(getGuidelineDate)
  const draftLead = yield select(getDraftLead)
  const isLeadFetching = yield select(getIsLeadFetching)
  const onlineChannelMetaData = yield select(getOnlineChannelMetadata)
  const supplyPoint = yield select(getSupplyPoint)
  const selectedProduct = yield select(getSelectedRate)
  const energies = yield select(getEnergies)
  const retargeting = yield select(getCustomerRetargeting)
  const autosave = yield select(getIsAutosave)

  const isDraftOnlineChannelFetching = yield select(getIsDraftOnlineChannelFetching)
  const isDuplicateOrderDraft = yield select(getIsDuplicateOrderDraft)

  const selfconsumptionTypes = yield select(getTypes)
  const referralCode = yield select(getReferralCode)
  const productVariants = yield call(getAllProductVariants)
  const onlineChannelDraft = yield select(getOnlineChannelDraft)
  const isPyme = referenceSegment === PYME
  const hasContactData = customer?.data?.phone
  if ((isDuplicateOrderDraft || isDraft) && activeStepDuplicate !== -1) return

  if (byCups === null) {
    const draft = yield select(getDraftOrder)
    if (draft && draft.orderlines && draft.orderlines.length > 0) {
      const searchByCups = {}
      draft.orderlines.forEach((orderLine) => {
        if (orderLine?.productVariant?.product?.energyType === ELECTRICITY) {
          searchByCups.electricity = orderLine.byCups
        } else if (orderLine?.productVariant?.product?.energyType === GAS) {
          searchByCups.gas = orderLine.byCups
        }
      })
      byCups = searchByCups
      yield put(setCupsSearcherByCups(searchByCups))
    }
  }
  if (!hasContactData) return
  yield put(startLead())
  const storeLeadId = lead?.id ? lead.id : draftLead?.id ? draftLead.id : ''
  const userId = operator?.user?.id
  const selectedChannel = operator.channels.selected

  const ordersData =
    getOrders(
      products,
      order,
      toppings,
      customer,
      selectedChannel,
      campaigns,
      userId,
      guidelineDate,
      isPyme,
      isPhoneChannel,
      selfconsumptionTypes,
      orderDraft,
      onlineChannelDraft,
      duplicateOrdeDraft,
      byCups
    ) || []

  if (referralCode) {
    const selectedProducts = yield select(getSelectedRate)
    const selectedTenure = yield select(getSelectedTenure)
    const productReferralCode = getProductReferralCode({ selectedProducts, selectedTenure })
    yield put(setProductReferralCode(productReferralCode))
  }
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const leadData = mapLeadData({ customer, isOnlineChannel, tenure })
  const { type: actionType } = action
  // Mount lead
  const fullLead = {
    ...leadData.customerData,
    ...leadData.billingData,
    ...(ordersData.length && {
      orders: ordersData,
    }),
    company: company?.id ? `/middleware/companies/${company.id}` : `/middleware/companies/${ID_NATURGY_IMPERIAL}`, // gns id in case of no company selected
    onlineInvoice: leadData.onlineInvoice,
    pyme: isPyme,
    metadata: isOnlineChannel ? onlineChannelMetaData : {},
  }
  if (billingAddressId && fullLead.billingAddress && !fullLead.billingAddress?.id) {
    fullLead.billingAddress = {
      ...fullLead.billingAddress,
      id: billingAddressId,
    }
  }

  if (supplyAddressesIds && fullLead?.orders?.length > 0 && !fullLead?.orders[0]?.supplyAddress?.id) {
    fullLead.orders = fullLead.orders.map((order) => ({
      ...order,
      supplyAddress: supplyAddressesIds[order.id]
        ? {
            ...order.supplyAddress,
            id: supplyAddressesIds[order.id],
          }
        : order.supplyAddress,
    }))
  }

  const productsCompaniesOk = checkCompany(fullLead, productVariants, company)
  if (!productsCompaniesOk) {
    yield put(errorLead())
  }

  try {
    if (!isLeadFetching) {
      const leadResponse = yield call(sendLead, fullLead, storeLeadId)

      if (leadResponse?.billingAddress?.id) {
        yield put(setBillingAddressId(`/middleware/addresses/${leadResponse.billingAddress.id}`))
      }

      const isSupplyAddressDefined = leadResponse?.orders.some(({ supplyAddress }) => supplyAddress?.id)
      if (isSupplyAddressDefined) {
        const supplyAddressesIds = leadResponse?.orders.reduce(
          (ids, { id, supplyAddress }) => ({
            ...ids,
            ...(supplyAddress?.id ? { [`/middleware/orders/${id}`]: `/middleware/addresses/${supplyAddress?.id}` } : {}),
          }),
          {}
        )
        yield put(setSupplyAddressesIds(supplyAddressesIds))
      }

      if (!storeLeadId && leadResponse.id) {
        yield put(setLeadId(leadResponse.id))
      }

      if (leadResponse.orders?.[0]?.compositeCrmId) {
        const isVersionedDuplicateOrder = leadResponse.orders?.[0]?.compositeCrmId?.includes('.')
        const crmId = isVersionedDuplicateOrder
          ? leadResponse.orders?.[0]?.compositeCrmId
          : leadResponse.orders?.[0]?.compositeCrmId?.split('-')[0]
        yield put(setCRMId(crmId))
      }

      if (leadResponse.orders?.length && !isDuplicateOrderDraft) {
        let orders = []
        leadResponse.orders.forEach(({ id }, index) => {
          orders.push({ id, cups: supplyPoint?.[index]?.cups })
        })
        yield put(setOrders(orders))
      }

      if (leadResponse.contact) {
        yield put(setOnBehalfContactId(leadResponse.contact))
      }
      yield put(successLead())

      if (leadResponse && userDocumentNumber && company?.code === 'GNS' && (activeStep === 2 || activeStep === 0)) {
        yield put(fetchVirtualBattery())
      }

      if (isOnlineChannel && (retargeting || autosave) && !isDraftOnlineChannelFetching) {
        yield put(startSendDraftData(leadResponse.id))
      }
    }
    yield put(clearLead())
  } catch (e) {
    console.log('error', e)
    const error = yield call(getErrorMessage, e)

    const stepToDisable = ACTIONS_STEPS[error.code || actionType]

    yield put(errorLead())
    if (error.message) {
      yield put(setLeadErrorMessage(error.message))
    }
    if (stepToDisable) {
      yield put(setStepUncompleted(stepToDisable))
      yield put(setActiveStepById(stepToDisable))
    }

    if ([error.code, actionType].includes(actionTypes.FETCH_LEAD) && isPyme) {
      const consumptionByEnergy = getConsumptionByEnergy(energies, selectedProduct)
      const sumConsumption = getSupplyPointConsumption(supplyPoint)
      if (!!consumptionByEnergy && sumConsumption > consumptionByEnergy) {
        yield put(
          showModal({
            modalType: MODAL_TYPES.maxConsumptionAllowed,
            modalData: {
              maxConsumptionAllowed: consumptionByEnergy,
            },
          })
        )
      }
    }
    yield put(errorLead())
  }
}

function checkCompany(fullLead, productVariants, company) {
  const companiesProductsOfOrder = []

  fullLead?.orders?.forEach((order) => {
    order?.orderLines?.forEach((orderLine) => {
      if (!orderLine?.productVariant) return
      const productVariantIdOfOrder = orderLine?.productVariant.split('/').at(-1)
      const productVariant = productVariants.find((productVariant) => productVariant.id === productVariantIdOfOrder)
      companiesProductsOfOrder.push(productVariant?.product?.company)
    })
  })
  return companiesProductsOfOrder.every((companyOfProduct) => companyOfProduct.split('/').at(-1) === company.id)
}

function* leadChecksSaga() {
  // If we have a document number we start checking the scoring and gdpr information for the current order
  const leadId = yield select(getLeadId)
  // If theres no lead ID don't do anything
  if (!leadId) return

  const selectedChannel = yield select(getSelectedChannel)
  const isGdprSent = yield select(getGdprSent)
  const isScoringSent = yield select(getScoringSent)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const isPortabilityChannel = selectedChannel?.name === 'Portabilidad'
  const isDraftLead = yield select(getDraftLead)
  const draftOrder = yield select(getDraftOrder)
  const isDuplicateOrder = yield select(getIsDuplicateOrderDraft)
  const isRecoveringDuplicateOrderRequesting = yield select(getIsRecoveringDuplicateOrder)
  const isRecoveringDuplicateOrderSuccess = yield select(getIsRecoveringDuplicateOrderSuccess)
  const isDraftState = draftOrder?.states && Object.keys(draftOrder.states)?.includes('draft')
  const isSircaSuccessDraftState = draftOrder?.states && Object.keys(draftOrder.states)?.includes('sirca_success')
  // Send legal accepts to user
  if (!isGdprSent && !isSircaSuccessDraftState && !isOnlineChannel && !isPortabilityChannel) {
    try {
      const channelId = selectedChannel?.channel?.id || selectedChannel.channelId
      yield call(triggerGdpr, leadId, channelId)
      yield put(setGdprSent())
    } catch (error) {
      console.error(error)
    }
  }

  // request Scoring if it hasn't been requested before and if is draft and the draft state isn't draft
  if (
    (isScoringSent && !isDraftState) ||
    (typeof isDraftLead === 'object' ? !isEmptyObject(isDraftLead) : isDraftLead) ||
    isDuplicateOrder
  )
    return

  try {
    if (!isRecoveringDuplicateOrderRequesting && !isRecoveringDuplicateOrderSuccess) {
      const scoring = yield call(checkScoring, leadId)

      if (scoring?.result && scoring?.result !== '0000') {
        switch (scoring.result) {
          case '0001':
            yield put(
              showModal({
                modalType: MODAL_TYPES.scoring,
                modalData: {
                  result: scoring.result,
                },
              })
            )
            break
          case '0003':
            yield put(
              showModal({
                modalType: MODAL_TYPES.scoring,
                modalData: {
                  result: scoring.result,
                },
              })
            )
            break
          case '0004':
            yield put(
              showModal({
                modalType: MODAL_TYPES.scoring,
                modalData: {
                  result: scoring.result,
                },
              })
            )
            break
          case '0005':
            yield put(
              showModal({
                modalType: MODAL_TYPES.scoring,
                modalData: {
                  result: scoring.result,
                },
              })
            )
            break
          default:
            break
        }
      }
    }
    yield put(setScoringSent())
  } catch (e) {
    console.error(e)
    if (e.status === 500) yield put(showModal({ modalType: MODAL_TYPES.sirca }))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.FETCH_LEAD, customerActionTypes.SET_DATA], fetchSaga),
    takeLatest([actionTypes.SET_LEAD_ID], leadChecksSaga),
  ])
}
