import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

/* eslint-disable-next-line */
export default combineReducers({
  isLoading: redukers.value(
    {
      set: actionTypes.SET_IS_LOADING,
    },
    false
  ),
})
