import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Paper, Checkbox, Divider } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import clsx from 'clsx'

import Typography from 'ui/components/typography'
import Filters from 'ui/components/filters'
import Products from 'ui/components/products'
import Topping from 'ui/components/topping'
import useStyles from './styles'
import { Alert } from '@material-ui/lab'

import { setStepCompleted, setStepAutocompleted, setStepDisabled } from 'services/stepper/actions'
import { setServiceSelection } from 'services/toppings/actions'
import { getIsMultiPoint, getSelectedTenure } from 'services/customer/selectors'
import { STEPS } from 'utils/constants'
import { getVirtualBatteryStatus } from 'services/toppings/selectors'
import { getSelectedRate } from 'services/products/selectors'
const SelectTopping = ({
  toppings,
  selected,
  onChangeTopping,
  onContinue,
  showRequiredAlert,
  isDisabled,
  isOnlineChannel,
  maintenanceType,
  setMaintenanceType,
  getFilteredProducts,
  maintenanceTypeOptions,
  isOnlyToppings,
  isPortability,
  duplicatedServiceType,
  isFidelizationAllowed,
  companyCode,
  serviceDisallowedMessage,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const virtualBattery = useSelector(getVirtualBatteryStatus)
  const [filterOptions, setFilterOptions] = useState({})
  const selectedTenure = useSelector(getSelectedTenure)
  const isMultiPoint = useSelector(getIsMultiPoint)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedRates = selectedProductArray
  const [disabled, setDisabled] = useState(false)
  const hasSelection = selected === null || Object.keys(selected).length

  const noServiceTenure = selectedTenure?.services?.length === 0
  const tenureElectricity = selectedTenure?.variants?.find(({ type }) => type === 'Electricidad')
  const tenureElectricityNoDarwin = selectedTenure?.products?.find(({ type }) => type === 'ELECTRICITY')
  useEffect(() => {
    const typeOptions = { ...maintenanceTypeOptions }
    delete typeOptions.all
    const isAllServicesDisallowed =
      Object.keys(typeOptions).length === 0 && !isFidelizationAllowed && !isPortability && selectedTenure?.services?.length > 0

    if (isAllServicesDisallowed) {
      dispatch(setStepCompleted(STEPS.topping))
      dispatch(setStepAutocompleted(STEPS.topping))
      dispatch(setStepDisabled(STEPS.topping))
      dispatch(
        setServiceSelection({
          disallowed: true,
          message: `selectionServiceDisallowed_${companyCode}`,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenure?.services])

  useEffect(() => {
    setFilterOptions(maintenanceTypeOptions)
  }, [maintenanceTypeOptions])

  useEffect(() => {
    const typeOptions = { ...maintenanceTypeOptions }
    if (maintenanceType === 'OTR') {
      if (virtualBattery?.codResult === '03' || isMultiPoint) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    } else {
      setDisabled(false)
    }

    if (virtualBattery && virtualBattery.codResult === '04') {
      delete typeOptions.OTR
    } else if (typeOptions.OTR && typeOptions.all && virtualBattery && virtualBattery.codResult) {
      if (
        toppings.find(({ externalCode }) => externalCode === '2-HUCHA-1') &&
        !isOnlineChannel &&
        ((selectedRates.length === 1 && selectedRates[0]?.energy?.type === 'GAS') || selectedRates.length === 0) &&
        (Object.keys(selectedTenure).length === 0 || (!tenureElectricity && !tenureElectricityNoDarwin))
      ) {
        delete typeOptions.OTR
      } else {
        delete typeOptions.all
      }
    }
    if (!virtualBattery && toppings.find(({ externalCode }) => externalCode === '2-HUCHA-1' && isOnlineChannel)) {
      delete typeOptions.all
    }
    if (!virtualBattery && toppings.find(({ externalCode }) => externalCode === '2-HUCHA-1' && !isOnlineChannel)) {
      delete typeOptions.OTR
    }
    setFilterOptions(typeOptions)
  }, [
    maintenanceType,
    virtualBattery,
    maintenanceTypeOptions,
    toppings,
    selectedRates,
    selectedTenure,
    tenureElectricity,
    tenureElectricityNoDarwin,
    isOnlineChannel,
    isMultiPoint,
  ])

  const noMaintenanceButton = (
    <Paper elevation={3} className={clsx(classes.root, !hasSelection && 'selected')} onClick={() => onChangeTopping(null)}>
      <Box className={classes.content}>
        <Box className={clsx(classes.checkboxContainer)}>
          <Checkbox
            classes={{ root: 'big' }}
            checkedIcon={<CheckBoxIcon color="primary" />}
            checked={!hasSelection}
            value=""
            name="checkbox-rate"
          />
        </Box>
        <Box mt={1} mb={2}>
          <Typography variant="h6" color="primary">
            {t('noMaintenance')}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )

  return (
    <Box>
      <Box>
        {serviceDisallowedMessage ? (
          <Box mb={4}>
            <Typography variant="h6" color="textPrimary">
              <span
                dangerouslySetInnerHTML={{
                  __html: t(serviceDisallowedMessage),
                }}
              />
            </Typography>
          </Box>
        ) : null}

        <Box mb={4}>
          <Typography variant="h6" color="textPrimary">
            {t('selectMaintenanceText')}
          </Typography>
        </Box>

        <Box mb={4} mt={!isOnlineChannel ? 4 : 0}>
          <Filters fullWidth options={filterOptions} selected={maintenanceType} setOption={setMaintenanceType} />
        </Box>

        {getFilteredProducts(toppings, maintenanceType).map((filteredProducts, key) => {
          const orderedProducts = filteredProducts
            .filter(({ type }) => Object.keys(maintenanceTypeOptions).includes(type))
            // eslint-disable-next-line array-callback-return
            .filter(({ externalCode }) => {
              if (selectedTenure && selectedTenure.services && selectedTenure.services.length > 0) {
                return !selectedTenure.services.some((service) => externalCode === service.productId)
              } else {
                return true
              }
            })
            .sort((a, b) => {
              return a.priority - b.priority
            })

          return (
            !!orderedProducts?.length && (
              <React.Fragment key={key}>
                <Divider className={classes.divider} />
                <Products
                  products={orderedProducts}
                  selectedProducts={selected}
                  onChangeProduct={onChangeTopping}
                  isOnlineChannel={isOnlineChannel}
                  Component={Topping}
                  disabled={disabled}
                />
              </React.Fragment>
            )
          )
        })}

        {((!isOnlyToppings && !isPortability) || noServiceTenure || (isPortability && !noServiceTenure)) && noMaintenanceButton}

        {showRequiredAlert && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <Typography variant="basic">{t('form.errors.optionRequired')}</Typography>
          </Alert>
        )}
        {duplicatedServiceType && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <Typography variant="basic">{t('form.errors.duplicatedServiceType')}</Typography>
          </Alert>
        )}
        {virtualBattery?.codResult === '02' && maintenanceType === 'OTR' && !isMultiPoint && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <span
              variant="basic"
              className={classes.batteryAlertStyle}
              dangerouslySetInnerHTML={{ __html: t('form.errors.virtualBattery02') }}
            ></span>
          </Alert>
        )}
        {virtualBattery?.codResult === '01' && maintenanceType === 'OTR' && !isMultiPoint && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <span
              variant="basic"
              className={classes.batteryAlertStyle}
              dangerouslySetInnerHTML={{ __html: t('form.errors.virtualBattery01') }}
            ></span>
          </Alert>
        )}
        {virtualBattery?.codResult === '03' && maintenanceType === 'OTR' && !isMultiPoint && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <span
              variant="basic"
              className={classes.batteryAlertStyle}
              dangerouslySetInnerHTML={{ __html: t('form.errors.virtualBattery03') }}
            ></span>
          </Alert>
        )}
        {isMultiPoint && maintenanceType === 'OTR' && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <span
              variant="basic"
              className={classes.batteryAlertStyle}
              dangerouslySetInnerHTML={{ __html: t('form.errors.virtualBattery06') }}
            ></span>
          </Alert>
        )}
        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={4}>
          <Button type="button" variant="contained" color="secondary" onClick={onContinue} disabled={isDisabled}>
            {t('common.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectTopping
