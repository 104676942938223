import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import useStyles from './styles'

const EmailConfirm = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isUserValid, setIsUserValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleContinue = async () => {
    setHasSubmitted(false)
    setIsSubmitting(true)

    try {
      setIsUserValid(true)
      handleClose()
    } catch (error) {
      console.error(error)
      setIsUserValid(false)
    }

    setIsSubmitting(false)
    setHasSubmitted(true)
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('confirmEmail.userMustConfirm')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('confirmEmail.weHaveSentEmail1')}
          </Typography>
          <Typography variant="basic" color="textPrimary">
            {t('confirmEmail.weHaveSentEmail2')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleContinue} disabled={isSubmitting}>
          {t('common.continue')}
        </Button>
      </DialogActions>
      {!isUserValid && hasSubmitted && (
        <Box className={classes.alertWrapper}>
          <Alert icon={false} severity="warning" className={classes.alert}>
            <Typography variant="basic">
              <strong>{t('confirmEmail.userHasNotConfirmed')}</strong>
            </Typography>
            <Typography variant="basic">{t('confirmEmail.cannotContinue')}</Typography>
          </Alert>
        </Box>
      )}
    </>
  )
}

export default EmailConfirm
