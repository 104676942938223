import { SERVICE } from './constants'

export const FETCH = `${SERVICE}/FETCH`
export const SET_ALL_TOPPINGS = `${SERVICE}/SET_ALL_TOPPINGS`
export const CLEAR_TOPPINGS = `${SERVICE}/CLEAR_TOPPINGS`
export const CLEAR_ALL_SELECTED_TOPPINGS = `${SERVICE}/CLEAR_ALL_SELECTED_TOPPINGS`
export const SET_TOPPINGS = `${SERVICE}/SET_TOPPINGS`
export const ADD_TO_SELECTION = `${SERVICE}/ADD_TO_SELECTION`
export const REMOVE = `${SERVICE}/REMOVE`
export const SET_DUPLICATED_SERVICE_TYPE = `${SERVICE}/SET_DUPLICATED_SERVICE_TYPE`
export const CLEAR_DUPLICATED_SERVICE_TYPE = `${SERVICE}/CLEAR_DUPLICATED_SERVICE_TYPE`
export const SET_SERVICE_SELECTION = `${SERVICE}/SET_SERVICE_SELECTION`
export const CLEAR_SERVICE_SELECTION = `${SERVICE}/CLEAR_SERVICE_SELECTION`
export const SET_VIRTUAL_BATTERY = `${SERVICE}/SET_VIRTUAL_BATTERY`
export const CLEAR_VIRTUAL_BATTERY = `${SERVICE}/CLEAR_VIRTUAL_BATTERY`
export const FETCH_VIRTUAL_BATTERY = `${SERVICE}/FETCH_VIRTUAL_BATTERY`
