import * as actionTypes from './action-types'

export const sendGAPageView = () => ({
  type: actionTypes.GA_PAGE_VIEW,
})

export const sendGAecommerceEvent = (data) => {
  return {
    type: actionTypes.GA_SEND_ECOMMERCE_EVENT,
    payload: data,
  }
}

export const sendGAEvent = (eventCallbackName, data) => {
  return {
    type: actionTypes.GA_SEND_EVENT,
    payload: { eventCallbackName, data },
  }
}
