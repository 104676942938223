import React from 'react'
import clsx from 'clsx'
import MuiLink from '@material-ui/core/Link'
import { ReactComponent as IconFile } from '@zatopek/core/src/assets/icons/file.svg'

import useStyles from './styles'

const Link = ({ variant, children, iconLeft, iconRight, classNames = [], disabled, ...props }) => {
  const classes = useStyles()

  return (
    <MuiLink {...props} className={clsx(classes.root, classes[variant], disabled && classes.disabled, ...classNames)}>
      {iconLeft ? <IconFile className={classes.iconLeft} /> : null}
      {children}
      {iconRight ? <IconFile className={classes.iconRight} /> : null}
    </MuiLink>
  )
}

export default Link
