import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { history } from 'redux/store'

import * as toppingsActionTypes from 'services/toppings/action-types'
import * as customerActionTypes from 'services/customer/action-types'
import * as actionTypes from './action-types'

import getUniqueObjects from '@zatopek/core/src/utils/getUniqueObjects'
import getEntityLangKeys from 'utils/getEntityLangKeys'

import {
  getBlacklistedProducts,
  getProductSimulatorFile,
  getProductVariants,
  getProductVariantsAlt,
  getProducts,
  getProductById,
} from 'services/products/api'
import { getSelectedTenure, getSupplyPoint, getCompany } from 'services/customer/selectors'
import { getAllEnergies } from 'services/energies/api'
import { getAllTypes as getAllSelfconsumptionTypes } from 'services/selfconsumption/api'
import {
  getSelectedRate,
  getProductsValidation,
  getRates,
  getIsOnlyToppings,
  getFetchedVariants,
} from 'services/products/selectors'
import { getSelectedSingleToppings } from 'services/toppings/selectors'
import { getIsOnlineChannel, getSelectedChannel } from 'services/operator/selectors'
import {
  setProductsValidation,
  setProductVariants,
  setProducts,
  setProduct,
  startSimulatorFile,
  successSimulatorFile,
  clearIsOnlyToppings,
  clearSelectedProduct,
  setFetchedVariants,
  clearFetchedVariants,
} from 'services/products/actions'
import { removeToppings } from 'services/toppings/actions'
import { clearSupplyPoints, setSelectedTenure } from 'services/customer/actions'
import {
  setStepCompleted,
  setActiveStepById,
  setStepEnabled,
  setStepDisabled,
  setStepUncompleted,
} from 'services/stepper/actions'
import { showModal } from 'services/modal/actions'
import { setNotificationState } from 'services/notification/actions'
import { completeStepsOnlineChannel } from 'services/onlineChannel/actions'
import { setEnergies } from 'services/energies/actions'
import { setTypes as setSelfconsumptionTypes } from 'services/selfconsumption/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { BOTH, BUSINESS, ELECTRICITY, GAS, HOME, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL, RESIDENTIAL, STEPS } from 'utils/constants'
import { getActiveStepDuplicate } from 'services/stepper/selectors'
import {
  getMonthlyCostByService,
  addCalculateRateToProduct,
  getReducedMonthlyCostByService,
  getIsProductAvailableForNewSupply,
  getMonthlyCostWithoutTaxesByService,
} from './business'

function* blacklistSaga(action) {
  const supplyPoint = yield select(getSupplyPoint)
  const selectedProduct = yield select(getSelectedRate)
  const selectedToppings = yield select(getSelectedSingleToppings)

  const billingAddress = yield select((state) => state.customer.billingAddress)
  const technicalInfo =
    Array.isArray(supplyPoint) && selectedProduct.length > 0
      ? supplyPoint.find((point) => selectedProduct.map((product) => product.energyType === point.energyType))
      : null

  if ((!technicalInfo && !billingAddress) || (selectedProduct.length === 0 && !selectedToppings)) {
    return
  }
  yield put(setProductsValidation(false))

  let selectedProductsIds = []

  if (selectedProduct.length > 0) {
    if (selectedProduct.length > 1) {
      const isCombinedRate = !!selectedProduct.subProducts
      selectedProductsIds = isCombinedRate ? selectedProduct.subProducts.map((product) => product.id) : [selectedProduct.id]
    } else {
      selectedProduct.forEach((product) => {
        const isCombinedRate = !!product.subProducts
        selectedProductsIds = isCombinedRate ? product.subProducts.map((product) => product.id) : [product.id]
      })
    }
  }

  const selectedToppingsIds = selectedToppings ? Object.keys(selectedToppings) : []
  const postalCode = technicalInfo?.postalCode || billingAddress?.postalCode

  // Products and services blacklist check
  let blacklistedProducts = {}
  if (postalCode && (selectedProduct.length > 0 || selectedToppings)) {
    try {
      blacklistedProducts = yield call(getBlacklistedProducts, {
        postalCode,
        productsIds: selectedProductsIds,
        toppingsIds: selectedToppingsIds,
      })
    } catch (error) {
      console.error(error)
      blacklistedProducts = { products: [], services: [] }
    }
  }
  const { products: invalidProducts, services: invalidServices } = blacklistedProducts.length ? blacklistedProducts[0] : {}

  const hasInvalidProducts = invalidProducts && invalidProducts.length
  const hasInvalidServices = invalidServices && invalidServices.length
  let addressLines = []
  if (technicalInfo) {
    addressLines = [
      technicalInfo.addressLine,
      `${technicalInfo.postalCode} - ${technicalInfo.municipalityName}`,
      technicalInfo.provinceName,
    ]
  } else if (billingAddress) {
    addressLines = [
      billingAddress.streetName,
      `${billingAddress.postalCode} - ${billingAddress.municipalityName}`,
      billingAddress.provinceName,
    ]
  }
  const productsNames = selectedProduct.map((product) => product.name)
  // Invalid products modal
  if (hasInvalidProducts) {
    yield put(
      showModal({
        modalType: MODAL_TYPES.rateNotAvailable,
        modalData: {
          productName: productsNames,
          addressLines,
        },
      })
    )
  }

  // Invalid services modal
  if (hasInvalidServices) {
    const invalidServicesIds = invalidServices.map((service) => service.id)

    // Show invalid services modal only if invalid products was not shown
    if (!hasInvalidProducts) {
      const invalidServicesNames = Object.keys(selectedToppings)
        .filter((toppingId) => invalidServicesIds.indexOf(toppingId) !== -1)
        .map((toppingId) => selectedToppings[toppingId].name)

      yield put(
        showModal({
          modalType: MODAL_TYPES.serviceNotAvailable,
          modalData: {
            serviceNames: invalidServicesNames,
            addressLines,
          },
        })
      )
    }

    // Remove invalid services from store
    yield put(removeToppings(invalidServicesIds))
  }
  if (!hasInvalidProducts && !hasInvalidServices) {
    yield put(setProductsValidation(true))
  }
}

function* variantsSaga() {
  const areProductsValid = yield select(getProductsValidation)
  const technicalInfo = yield select(getSupplyPoint)
  const selectedProductArray = yield select(getSelectedRate)
  const selectedProduct = selectedProductArray
  const selectedTenure = yield select(getSelectedTenure)

  if (!areProductsValid || !technicalInfo || selectedProduct.length === 0) {
    return
  }
  const isCombinedRate = !!selectedProduct[0].subProducts || selectedProduct.length > 1
  const selectedChannel = yield select(getSelectedChannel)
  const selectedCompany = yield select(getCompany)

  try {
    yield all(
      technicalInfo.map((techInfo, index) =>
        call(fetchVariants, {
          techInfo,
          isCombinedRate,
          selectedProduct: selectedProduct.length === 1 ? selectedProduct[0] : selectedProduct,
          selectedChannel,
          selectedCompany,
          selectedTenure,
        })
      )
    )
    const fetchedVariants = yield select(getFetchedVariants)

    if (fetchedVariants.length) {
      const uniqueProducts = getUniqueObjects(fetchedVariants, 'id')
      yield put(clearFetchedVariants())
      yield put(setProductVariants(uniqueProducts))
    }
  } catch (e) {
    console.error(e)
    yield put(
      setNotificationState({
        message: 'notificationAlerts.supplyStepError',
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* updateCalculatedRates() {
  const technicalInfo = yield select(getSupplyPoint)
  const products = yield select(getRates)
  const selectedRate = yield select(getSelectedRate)

  const prices = {
    [GAS]: getMonthlyCostByService(technicalInfo, GAS),
    [ELECTRICITY]: getMonthlyCostByService(technicalInfo, ELECTRICITY),
  }
  const reducedPrices = {
    [GAS]: getReducedMonthlyCostByService(technicalInfo, GAS),
    [ELECTRICITY]: getReducedMonthlyCostByService(technicalInfo, ELECTRICITY),
  }

  const priceWithoutTaxes = {
    [GAS]: getMonthlyCostWithoutTaxesByService(technicalInfo, GAS),
    [ELECTRICITY]: getMonthlyCostWithoutTaxesByService(technicalInfo, ELECTRICITY),
  }
  const newCalculation = technicalInfo[0]?.newCalculation

  const updatedProducts = products.map((product) => {
    if (!product.flatRate) {
      return product
    }
    return addCalculateRateToProduct(prices, product, reducedPrices, priceWithoutTaxes, newCalculation)
  })
  yield put(setProducts(updatedProducts))

  if (selectedRate.length > 0) {
    const updatedSelectedRate = []
    selectedRate.forEach((product) => {
      addCalculateRateToProduct(prices, product, reducedPrices)
      updatedSelectedRate.push(product)
    })
    yield put(setProduct(updatedSelectedRate))
  }
}

function* fetchVariants({ techInfo, isCombinedRate, selectedProduct, selectedChannel, selectedCompany, selectedTenure }) {
  let newProduct = null
  const energy = techInfo.energyType
  let selectedEnergyProduct
  if (isCombinedRate) {
    if (selectedProduct.subProducts) {
      selectedEnergyProduct = selectedProduct.subProducts.find(
        (product) => product.energyType === energy || product.energyType === BOTH
      )
    } else {
      selectedEnergyProduct = selectedProduct.find((product) => product.energyType === energy)
    }
  } else {
    selectedEnergyProduct = selectedProduct
  }
  const selectedEnergyProductId = [selectedEnergyProduct.id]

  if (!selectedEnergyProduct.energyType.includes(techInfo.energyType)) {
    return
  }

  // Check product variants for current energy
  let productVariants = []
  // await no funciona en cambio de producto en el último paso.
  productVariants = yield call(getProductVariants, {
    productsIds: selectedEnergyProductId,
    tariffType: techInfo.accessTariff,
    channelId: selectedChannel.id,
    selectedTenure: selectedTenure,
    company: selectedCompany,
  })
  // If there is no variants, then is a tariff change
  if (!productVariants.length) {
    const productVariants = yield call(getProductVariants, {
      productsIds: selectedEnergyProductId,
      tariffType: null,
      channelId: selectedChannel.id,
      selectedTenure: selectedTenure,
      company: selectedCompany,
    })
    if (productVariants.length) {
      newProduct = productVariants[0]
    }
  } else {
    newProduct = productVariants[0]
  }

  if (newProduct) {
    newProduct.energyType = energy
    const fetchedVariants = yield select(getFetchedVariants)
    yield put(setFetchedVariants([...fetchedVariants, newProduct]))
  }
}

function* fetchProductsSaga({
  payload: { channel, referenceSegment, tenure, params, isNewSupply, hasChannelOrSegmentChanged = false, company },
}) {
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const selectedProductArray = yield select(getSelectedRate)
  const selectedProduct = selectedProductArray
  const companySelected = yield select(getCompany)

  if (isOnlineChannel && ![HOME, BUSINESS].includes(params?.src)) {
    history.replace(ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.referenceSegmentNotValid)
  }

  if (!isOnlineChannel) {
    try {
      const energies = yield call(getAllEnergies)
      yield put(setEnergies(energies))
    } catch (error) {
      console.error(error)
    }
  }

  try {
    const selfConsumptionTypes = yield call(getAllSelfconsumptionTypes)
    yield put(setSelfconsumptionTypes(selfConsumptionTypes))
  } catch (error) {
    console.error(error)
  }

  // TODO: Remove online channel condition when back enable this endpoint
  // if (!isOnlineChannel) {
  //   try {
  //     const energies = yield call(getAllEnergies)
  //     yield put(setEnergies(energies))
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  try {
    let allProducts = []
    const tenureVariantFilter =
      tenure?.variants?.filter((product) => {
        return product.productId
      }) || []
    const tenurePortability =
      tenure?.company &&
      company?.code &&
      tenureVariantFilter.length &&
      tenure.company.toLowerCase() !== company.code.toLowerCase()

    if (tenurePortability) {
      const altProducts = yield call(getProductVariantsAlt, {
        channelId: channel || '',
        products: tenureVariantFilter || [],
        company: companySelected,
      }) || []
      const productsArr = []

      for (const idProduct in altProducts) {
        productsArr.push(altProducts[idProduct].energy)
        for (const prop in altProducts[idProduct].productVariantAlternatives) {
          const product = altProducts[idProduct].productVariantAlternatives[prop]
          const productById = yield call(getProductById, product.product.id)
          if (productById) {
            productById.isRecommended = true
            allProducts.push(productById)
            productsArr.push({
              id: productById.id,
              name: productById.name,
            })
          }
        }
      }
      tenure.products = productsArr
      yield put(setSelectedTenure(tenure))
    }

    const productArr = yield call(getProducts, {
      channel: channel || '',
      referenceSegment: referenceSegment || RESIDENTIAL,
      tenure: tenure || {},
      params,
      isNewSupply,
      company: companySelected,
    }) || []

    productArr.forEach((product) => allProducts.push(product))

    if (!allProducts.length) {
      yield put(setProducts(allProducts))
      isOnlineChannel
        ? yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.productNotFound)
        : yield call(history.push, '/dashboard')
      if (!hasChannelOrSegmentChanged) {
        yield put(
          setNotificationState({
            message: 'notificationAlerts.noProductsError',
            type: 'error',
            isVisible: true,
          })
        )
      }
      return
    }

    const formattedProducts = allProducts.map((product) => ({ ...product, ...getEntityLangKeys(product.name) }))

    // Group products by family
    let products = formattedProducts.filter((product) => !product.family)
    const productsToGroup = formattedProducts.filter((product) => product.family)
    const uniqFamilyIds = [...new Set(productsToGroup.map((product) => product.family))]

    uniqFamilyIds.forEach((familyId) => {
      const productsInFamily = productsToGroup
        .filter((product) => product.family === familyId)
        .sort((a, b) => {
          if (a === b) return 0
          if (a.energyType === ELECTRICITY) return -1
          if (b.energyType === ELECTRICITY) return 1
          return 0
        })

      if (productsInFamily.length > 1) {
        products.push({
          id: familyId,
          name: productsInFamily[0].name,
          title: productsInFamily[0].title,
          subtitle: productsInFamily[0].subtitle,
          energyType: BOTH,
          subProducts: productsInFamily,
          isRecommended: productsInFamily.some((elm) => elm.isRecommended),
          flatRate: productsInFamily[0].flatRate,
          image: productsInFamily[0].image,
        })
        products.push(...productsInFamily)
      } else {
        products.push(productsInFamily[0])
      }
    })

    if (isNewSupply && selectedProduct.length > 0 && !getIsProductAvailableForNewSupply(selectedProduct)) {
      yield put(setStepUncompleted(STEPS.rate))
      yield put(clearSelectedProduct())
      yield put(
        setNotificationState({
          message: 'notificationAlerts.productNotAvailable',
          type: 'error',
          isVisible: true,
        })
      )
    }

    if (tenurePortability) {
      const energyArr = tenure.products.map((energy) => energy.type).filter((elm) => elm)

      products = products.filter((product) => {
        return product.energyType === BOTH || (energyArr.length !== 2 && energyArr.includes(product.energyType))
      })
    }

    yield put(setProducts(products))
    if (isOnlineChannel) yield put(completeStepsOnlineChannel({ ...(params?.sel && { products }), params }))
  } catch (e) {
    console.error(e)
  }
}

function* fetchSimulatorFileSaga({ payload: productId }) {
  const selectedChannel = yield select(getSelectedChannel)
  yield put(startSimulatorFile())
  yield call(getProductSimulatorFile, { channelId: selectedChannel.id, productId })
  yield put(successSimulatorFile())
}

function* setProductWithAttributeSaga({ payload: { product, attributes } }) {
  const supplyPoint = yield select(getSupplyPoint)
  if (product?.subProducts || product[0]?.subProducts) {
    if (product?.subProducts) {
      product.subProducts = product.subProducts.map((subproduct) => ({ ...subproduct, attributes: attributes[subproduct.id] }))
    } else if (product[0]?.subProducts) {
      product[0].subProducts = product[0]?.subProducts.map((subproduct) => ({
        ...subproduct,
        attributes: attributes[subproduct.id],
      }))
    }
  } else {
    if (product.length > 0) {
      product?.forEach((elem, index) => {
        product[index].attributes = attributes[elem?.id]
      })
    } else if (product.id) {
      product.attributes = attributes[product.id]
    }
  }
  const productAux = Array.isArray(product) ? product : [product]
  yield put(setProduct(productAux))
  if (supplyPoint) {
    yield put(setProductsValidation(true))
  }
  let isOnlyToppings = yield select(getIsOnlyToppings)
  if (isOnlyToppings) {
    yield put(clearIsOnlyToppings())
  }
}

function* isOnlyToppingsSaga() {
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const selectedTenure = yield select(getSelectedTenure)
  const activeStepDuplicate = yield select(getActiveStepDuplicate)
  yield put(setStepCompleted(STEPS.rate))
  yield put(setStepCompleted(STEPS.supply))
  yield put(setStepDisabled(STEPS.supply))
  if (isOnlineChannel) {
    yield put(setStepDisabled(STEPS.rate))
  } else {
    yield put(clearSelectedProduct())
    if (!selectedTenure || Object.keys(selectedTenure).length === 0) {
      yield put(clearSupplyPoints())
    }
    if (activeStepDuplicate === -1) {
      yield put(setActiveStepById(STEPS.topping))
    }
  }
}

function* clearIsOnlyToppingsSaga() {
  yield put(setStepEnabled(STEPS.supply))
  yield put(setStepUncompleted(STEPS.supply))
}

function* resetCalculatedCostSaga() {
  const products = yield select(getRates)
  if (!products.length) return
  const productsWithoutCost = products.slice()?.reduce((acc, { calculatedCost, reducedCost, subProducts, ...product }) => {
    const subProductsObject = subProducts?.length
      ? { subProducts: subProducts.map(({ calculatedCost, reducedCost, ...item }) => item) }
      : {}
    acc.push({
      ...product,
      ...subProductsObject,
    })
    return acc
  }, [])

  yield put(setProducts(productsWithoutCost))
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [toppingsActionTypes.SET_TOPPINGS, customerActionTypes.SET_SUPPLY_POINT, customerActionTypes.SET_BILLING_ADDRESS],
      blacklistSaga
    ),
    takeLatest([actionTypes.SET_PRODUCTS_VALIDATION], variantsSaga),
    takeLatest([customerActionTypes.SET_SUPPLY_POINT], updateCalculatedRates),
    takeLatest([actionTypes.FETCH_PRODUCTS], fetchProductsSaga),
    takeLatest([actionTypes.FETCH_REQUEST_SIMULATOR_FILE], fetchSimulatorFileSaga),
    takeLatest([actionTypes.SET_SELECTED_PRODUCT_WITH_ATTRIBUTE], setProductWithAttributeSaga),
    takeLatest([actionTypes.SET_IS_ONLY_TOPPINGS], isOnlyToppingsSaga),
    takeLatest([actionTypes.CLEAR_IS_ONLY_TOPPINGS], clearIsOnlyToppingsSaga),
    takeLatest([actionTypes.RESET_CALCULATED_COST], resetCalculatedCostSaga),
  ])
}
