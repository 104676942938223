import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 3, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7, 4),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiButton-root': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 7, 5),
    },
  },
}))

export default useStyles
