import { request } from 'redukers'

export const getOrder = (state) => state.order
export const getOrders = (state) => state.order.orders
export const getOrderId = (state) => state.order.id
export const getGdprSent = (state) => state.order.isGdprSent
export const getScoringSent = (state) => state.order.isScoringSent
export const getCRMId = (state) => state.order.CRMId
export const getGuidelineDate = (state) => state.order.guidelineDate
export const getSignatureDate = (state) => state.order.signatureDate

export const getIsSignRequesting = (state) => {
  const signRequest = state.order.signRequest
  return request.getIsRequesting(signRequest)
}

export const getIsSignSuccess = (state) => {
  const signRequest = state.order.signRequest
  return request.getIsRequestSucceeded(signRequest)
}

export const getIsSignError = (state) => {
  const signRequest = state.order.signRequest
  return request.getIsError(signRequest)
}

export const getIsForwardSmsRequesting = (state) => {
  const forwardSmsRequest = state.order.forwardSmsRequest
  return request.getIsRequesting(forwardSmsRequest)
}

export const getIsForwardSmsSuccess = (state) => {
  const forwardSmsRequest = state.order.forwardSmsRequest
  return request.getIsRequestSucceeded(forwardSmsRequest)
}

export const getIsForwardSmsError = (state) => {
  const forwardSmsRequest = state.order.forwardSmsRequest
  return request.getIsError(forwardSmsRequest)
}

export const getIsAttachDocRequesting = (state) => request.getIsRequesting(state.order.attachDocRequest)

export const getIsAttachDocSuccess = (state) => request.getIsRequestSucceeded(state.order.attachDocRequest)

export const getIsAttachDocError = (state) => request.getIsError(state.order.attachDocRequest)
