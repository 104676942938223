import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

export default combineReducers({
  viewOrdersTemplateFields: redukers.value(
    {
      set: actionTypes.SET_VIEW_ORDERS_TEMPLATE_FIELDS,
      reset: actionTypes.CLEAR_VIEW_ORDERS_TEMPLATE_FIELDS,
    },
    {}
  ),
  exportOrdersTemplateFields: redukers.value(
    {
      set: actionTypes.SET_EXPORT_ORDERS_TEMPLATE_FIELDS,
      reset: actionTypes.CLEAR_EXPORT_ORDERS_TEMPLATE_FIELDS,
    },
    {}
  ),
  fetchTemplateFieldsRequest: redukers.request({
    start: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_START,
    success: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_SUCCESS,
    error: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_ERROR,
    clear: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_CLEAR,
  }),
})
