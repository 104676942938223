import { LogLevel } from '@azure/msal-browser'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `${process.env.REACT_APP_AZURE_AUDIENCE}/${process.env.REACT_APP_TENTANT_ID}`,
    redirectUri: '/', // https://front-funnel.zapotek-dev.adn.naturgy.com Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
}

export const loginRequest = {
  scopes: [`api://${process.env.REACT_APP_AZURE_CLIENT_ID}//custom.Read`, 'openid'],
}
