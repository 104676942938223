import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Box, Button, FormHelperText, FormControl, Grid, InputLabel, Select, MenuItem } from '@material-ui/core'
import { DialogContent } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { get, post } from 'services/client'
import { useDispatch } from 'react-redux'
import { fetchOrdersFail } from 'services/backoffice/actions'
import { useSnackbar } from 'context/SnackbarContext'

import useStyles from './styles'

const ChangeFailReason = ({ handleClose, data: { info } }) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles()
  const { t } = useTranslation()
  const [failList, setFailList] = React.useState([])
  const dispatch = useDispatch()
  const { setSnackMessage } = useSnackbar()

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['failType']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })
    return errors
  }
  useEffect(() => {
    get('tablekolist').then((res) => {
      if (res && res.length > 0) {
        setFailList(res.sort((a, b) => a.failedreason.localeCompare(b.failedreason)))
      }
      else setFailList([])
    })
  }, [])

  const handleFormSubmit = (formData) => {
    const { orderId } = info
    const { failType } = formData
    post(`update_tablekolist/${orderId}`, { failedReason: failType })
      .then((res) => {
        if (res) {
          dispatch(fetchOrdersFail())
          setSnackMessage(t('changeFailReason.success'))
          handleClose()
        }
      })
      .catch((err) => {
        setSnackMessage(t('changeFailReason.error'), 'error')
        console.log(err)
      })
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('changeFailReason.title')}
          </Typography>
        </Box>

        <Box mb={2}>
          <Formik
            initialValues={{
              failType: '',
            }}
            validate={validateForm}
            onSubmit={handleFormSubmit}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  {/* --- DOC TYPE --- */}
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <InputLabel id="failType" error={touched.docummentType && errors.failType}>
                        {t('changeFailReason.currentFail')}
                      </InputLabel>
                      <Select
                        labelId="currentFail"
                        fullWidth
                        value={values.failType}
                        error={touched.docummentType && errors.failType}
                        onChange={handleChange('failType')}
                        disabled={failList.length === 0}
                      >
                        {failList?.map(({ failedreason, id }) => {
                          return (
                            <MenuItem key={id} value={failedreason}>
                              {failedreason}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {touched.docummentType && errors.failType ? <FormHelperText error>{errors.failType}</FormHelperText> : null}
                  </Grid>
                </Grid>
                <Box className={classes.actions} mt={4}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button type="submit" color="secondary" variant="contained" disabled={!values.failType}>
                      {t('common.continue')}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </>
  )
}

export default ChangeFailReason
