import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getSingleToppings, getSelectedSingleToppings, getDuplicatedServiceType } from 'services/toppings/selectors'
import { getIsOnlineChannel } from '../services/operator/selectors'

import { setStepCompleted, setStepUncompleted, setStepDisabled, setStepEnabled } from 'services/stepper/actions'
import {
  removeAllSelectedToppings,
  setToppings,
  clearDuplicatedServiceType,
  fetchVirtualBattery,
  clearVirtualBattery,
} from 'services/toppings/actions'
import { fetchLead } from 'services/lead/actions'
import { getIsLeadFetching } from 'services/lead/selectors'

import { STEPS, MAINTENANCE_CONSTANTS, GA_EVENT_NAMES, ELECTRICITY, TOPPING_TYPES, GAS, COMPANY_CODES } from 'utils/constants'
import { isEmptyObject } from '@zatopek/core/src/utils/isEmptyObject'

import SelectToppingView from '../ui/views/selectTopping'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_SERVICES } from '@zatopek/core/src/utils/constants'
import { getIsOnlyToppings, getSelectedRate } from 'services/products/selectors'
import { setNotificationState } from 'services/notification/actions'
import { getIsDraft } from 'services/draftCopy/selectors'
import { MODAL_TYPES } from 'services/modal/constants'
import { getActiveStep } from 'services/stepper/selectors'
import { getCompany, getIsMultiPoint, getSelectedTenure, getCustomerData, getUserData } from 'services/customer/selectors'

import { getIsFidelizationFetching, getIsFidelizationAllowed } from 'services/fidelization/selectors'
import { fetchFidelization } from 'services/fidelization/actions'
import { CircularProgress } from '@material-ui/core'
import Loader from '@zatopek/core/src/ui/components/loader'

import useStep from '@zatopek/funnel/src/hooks/useStep'
import { getVirtualBatteryStatus } from 'services/toppings/selectors'
import { showModal } from 'services/modal/actions'

const SelectTopping = ({ onContinue }) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const currentStep = useStep({ stepIndex: 3 })

  const activeStep = useSelector(getActiveStep)
  const toppings = useSelector(getSingleToppings)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const toppingsSelected = useSelector(getSelectedSingleToppings)
  const isOnlyToppings = useSelector(getIsOnlyToppings)
  const company = useSelector(getCompany)
  const tenure = useSelector(getSelectedTenure)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const isMultiPoint = useSelector(getIsMultiPoint)
  const duplicatedServiceType = useSelector(getDuplicatedServiceType)
  const isDraft = useSelector(getIsDraft)
  const hasEmptyOption = toppings.some((topping) => !Object.keys(topping).length)
  const [showRequiredAlert, setShowRequiredAlert] = useState(false)
  const [preSelectedToppings, setPreSelectedToppings] = useState(toppingsSelected)
  const [maintenanceType, setMaintenanceType] = useState(MAINTENANCE_CONSTANTS.defaultMaintenance)
  const [hasToppings, setHasToppings] = useState(true)
  const customerData = useSelector(getCustomerData)
  const userData = useSelector(getUserData)
  const virtualBattery = useSelector(getVirtualBatteryStatus)
  const { t } = useTranslation()
  const [btnState, setBtnState] = useState(false)
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const maintenanceOptions =
    company?.code === COMPANY_CODES.newco
      ? { ...MAINTENANCE_CONSTANTS.maintenanceOptionsNewco }
      : { ...MAINTENANCE_CONSTANTS.maintenanceOptionsIberia }
  const isPortability = Boolean(company?.code && tenure?.company && company.code.toLowerCase() !== tenure.company.toLowerCase())

  const isFidelizationFetching = useSelector(getIsFidelizationFetching)
  const isFidelizationAllowed = useSelector(getIsFidelizationAllowed)

  useEffect(() => {
    if (company && !isFidelizationFetching) {
      dispatch(fetchFidelization())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id])

  useEffect(() => {
    return () => {
      setPreSelectedToppings(toppingsSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (activeStep === 3 && userDocumentNumber && company?.code === COMPANY_CODES.gns && selectedProduct.length === 0) {
      dispatch(fetchVirtualBattery())
    } else if (company?.code === COMPANY_CODES.newco) {
      if (virtualBattery) {
        dispatch(clearVirtualBattery())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, userDocumentNumber])

  useEffect(() => {
    setPreSelectedToppings(toppingsSelected)
  }, [toppingsSelected])

  useEffect(() => {
    const toppingPreSelected = toppings.filter((elm) => elm.isRecommended)

    if (toppingPreSelected.length) {
      const parsedTopping = {}

      toppingPreSelected.forEach((elm) => {
        if (elm?.id) {
          parsedTopping[elm.id] = {
            title: `entity-${elm.name}-title`,
            subtitle: `entity-${elm.name}-subtitle`,
            ...elm,
          }
        }
      })
      setPreSelectedToppings(parsedTopping)
    }

    if (!isOnlineChannel) {
      if (!toppings.length && hasToppings) {
        setHasToppings(false)
        dispatch(setStepCompleted(STEPS.topping))
        dispatch(setStepDisabled(STEPS.topping))
      }

      if (!!toppings.length && !isDraft) {
        setHasToppings(true)
        dispatch(setStepUncompleted(STEPS.topping))
        dispatch(setStepEnabled(STEPS.topping))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toppings])

  if (!toppings.length) {
    return null
  }

  // Add an empty option to allow "no maintenance" selection
  if (toppings && !hasEmptyOption) {
    toppings[isOnlineChannel ? 'unshift' : 'push']({})
  }

  const toppingsList = [...toppings]

  // Remove SPF from toppings
  if (toppingsList.length) {
    const spfIndex = toppingsList.findIndex((topping) => topping.service && topping.service.type === 'SPF')
    if (spfIndex !== -1) {
      toppingsList.splice(spfIndex, 1)
    }
  }

  const handleChangeTopping = (toppingId) => {
    if (showRequiredAlert) {
      setShowRequiredAlert(false)
    }
    dispatch(clearDuplicatedServiceType())

    const hasMaintenance = !!toppingId

    toppingId || !hasMaintenance ? setBtnState(true) : setBtnState(false)

    let selectedToppings = { ...preSelectedToppings }

    if (!hasMaintenance) {
      selectedToppings = {}
    } else {
      const isUnselecting = !!selectedToppings?.[toppingId]
      if (isUnselecting) {
        delete selectedToppings?.[toppingId]
      } else {
        const newTopping = toppings.find((topping) => topping?.service?.id === toppingId).service
        const selectedToppingsEntries = !isEmptyObject(selectedToppings)
          ? Object.entries(selectedToppings).filter((topping) => topping[1].type !== newTopping.type)
          : []
        selectedToppings = Object.fromEntries(selectedToppingsEntries)

        const { id, ...restTopping } = newTopping
        selectedToppings = { ...selectedToppings, [toppingId]: restTopping }
      }
    }

    const selectedTopping = selectedToppings[toppingId] || {}
    if (isPortability) {
      const hasServiceSelected = Object.keys(selectedToppings).length > 0
      if (!hasServiceSelected) selectedToppings = {}
    }
    const eventData = {
      topping: selectedTopping,
      currentStep: STEPS.topping,
    }
    // Analytics: click on topping
    dispatch(sendGAEvent(GA_EVENT_NAMES.clickTopping, eventData))
    setPreSelectedToppings(selectedToppings)
  }

  const handleContinue = () => {
    const tenureServiceType = tenure?.services?.map((service) => service.type) || []

    if (
      (preSelectedToppings === null && !isPortability) ||
      (preSelectedToppings && Object.keys(preSelectedToppings).length === 0 && isOnlyToppings)
    ) {
      setShowRequiredAlert(true)
    } else {
      if (isPortability) {
        for (const selectedService in preSelectedToppings) {
          const indexTenure = tenureServiceType.indexOf(preSelectedToppings[selectedService]?.type)
          if (indexTenure >= 0) {
            tenureServiceType.splice(indexTenure, 1)
          }
        }

        /* if (
          tenureServiceType.length !== 0 &&
          tenureServiceType.length === 1 &&
          tenureServiceType[0] !== 'OTR' &&
          company?.code === 'NewCo'
        ) {
          setSnackMessage('Debes contratar los mismos servicios contratados para la portabilidad', 'error')
          return
        }*/
      }
      if (!duplicatedServiceType) {
        const selectedService = preSelectedToppings ? preSelectedToppings : {}
        const spfServiceType = toppings?.find((topping) => topping?.service?.type === 'SPF')
        if (spfServiceType) {
          const spfTopping = toppingsSelected?.[spfServiceType.id]
            ? { [spfServiceType.id]: toppingsSelected[spfServiceType.id] }
            : {}
          dispatch(setToppings({ ...selectedService, ...spfTopping }))
        } else {
          dispatch(setToppings(selectedService))
        }

        const toppingName = Object.values(selectedService)[0]?.title
        const service = Object.values(selectedService)[0]?.CRMCode === 'SVE' ? GA_SERVICES['ELECTRICITY'] : GA_SERVICES['GAS']

        dispatch(setStepCompleted(STEPS.topping))
        dispatch(fetchLead())
        dispatch(sendGAEvent(GA_EVENT_NAMES.selectTopping, { product: t(toppingName), service }))
        if (virtualBattery && virtualBattery.codResult === '01') {
          const isVirtualBattery =
            Object.keys(preSelectedToppings).includes('17a57e2a-fb05-4d55-87c7-46f11e8cc12a') ||
            Object.keys(preSelectedToppings).includes('aa0efb53-56da-4fb1-89a0-b323fad47ff1')
          if (isVirtualBattery) {
            dispatch(
              showModal({
                modalType: MODAL_TYPES.warningModal,
                modalData: {
                  titleKey: 'virtualBatterySelectedIncorrectProductTittle',
                  textKey: `virtualBatterySelectedIncorrectProduct`,
                  acceptButtonTextKey: 'accept',
                },
              })
            )
          } else {
            onContinue()
          }
        } else {
          onContinue()
        }
      }
    }
  }

  const getFilteredProducts = (allToppings, maintenance) => {
    const types = Object.keys(maintenanceOptions)
    types.pop()
    const filteredToppings =
      maintenance === 'all'
        ? types.map((type) => allToppings.filter(({ service }) => service?.type === type && service.externalCode !== '2-HUCHA-1'))
        : [allToppings.filter(({ service }) => service?.type === maintenance)]

    return [...filteredToppings]
  }

  const maintenanceTypeOptions = (() => {
    // Se clona la constante para evitar alterarla con un delete
    let filteredOptions = { ...maintenanceOptions }

    if (!isPortability && !isFidelizationAllowed) {
      tenure?.services?.forEach(({ type }) => delete filteredOptions[type])
    }

    // Se eliminan los tipos de mantenimiento que no tengan ninguna opción contratable
    if (!isEmptyObject(filteredOptions)) {
      let optionsKeys = Object.keys(filteredOptions)
      optionsKeys.pop()
      optionsKeys = optionsKeys?.filter((k) => !toppings.some((t) => t.type === k))
      optionsKeys?.forEach((k) => delete filteredOptions[k])
    }

    // Filter services by energy type when is multipoint
    if (isMultiPoint && !isEmptyObject(filteredOptions)) {
      let serviceType

      if (selectedProduct?.find((product) => product?.energyType === ELECTRICITY)) {
        filteredOptions = Object.fromEntries(
          Object.entries(filteredOptions).filter(([_, value]) => value === filteredOptions.SVE || value === filteredOptions.OTR)
        )
        serviceType = TOPPING_TYPES[ELECTRICITY]
      } else {
        filteredOptions = Object.fromEntries(
          Object.entries(filteredOptions).filter(([_, value]) => value === filteredOptions.SVG)
        )
        serviceType = TOPPING_TYPES[GAS]
      }

      const hasSelectedInvalidService =
        toppingsSelected && Object.values(toppingsSelected).some((topping) => topping.type !== serviceType)

      if (hasSelectedInvalidService) {
        dispatch(setStepUncompleted(STEPS.topping))
        dispatch(removeAllSelectedToppings())
        dispatch(
          setNotificationState({
            message: 'Los servicios seleccionados no son contratables para varias suministros. Selecciona uno de nuevo.',
            type: 'error',
            isVisible: true,
          })
        )
      }
    }

    if (!filteredOptions[maintenanceType]) {
      //Si no hay productos para el tipo de mantenimiento por defecto, se marca el siguiente tipo de mantenimiento con productos
      setMaintenanceType(Object.keys(filteredOptions || {})?.[0])
    }
    return filteredOptions
  })()

  return isFidelizationFetching ? (
    <div style={{ position: 'relative', height: '150px' }}>
      <Loader open opacity={0.5}>
        <CircularProgress thickness={4} />
      </Loader>
    </div>
  ) : (
    <SelectToppingView
      isOnlineChannel={isOnlineChannel}
      maintenanceTypeOptions={maintenanceTypeOptions}
      toppings={toppingsList}
      selected={preSelectedToppings}
      onChangeTopping={handleChangeTopping}
      onContinue={handleContinue}
      showRequiredAlert={showRequiredAlert}
      isDisabled={isLeadFetching || isFidelizationFetching || !btnState}
      maintenanceType={maintenanceType}
      setMaintenanceType={setMaintenanceType}
      getFilteredProducts={getFilteredProducts}
      isOnlyToppings={isOnlyToppings}
      isPortability={isPortability}
      duplicatedServiceType={duplicatedServiceType}
      isFidelizationAllowed={isFidelizationAllowed}
      companyCode={company?.code}
      serviceDisallowedMessage={
        !isFidelizationAllowed && tenure?.services?.length > 0 ? `serviceDisallowed_${company?.code}` : ''
      }
    />
  )
}

export default SelectTopping
