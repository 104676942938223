export const findSupplyPoint = (supplyPoint, cups, energyType) => {
  return (
    supplyPoint &&
    supplyPoint.find(
      (item) =>
        (item[`${energyType.toLowerCase()}Cups`] === cups || item.cups === cups) && item.energyType === energyType.toUpperCase()
    )
  )
}

export default findSupplyPoint
