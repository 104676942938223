import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@material-ui/core'
import FileUpload from 'ui/components/fileUpload'
import Typography from 'ui/components/typography'
import { uploadInvoice } from 'services/invoice/actions'
import ScanInvoiceContext from './scanInvoiceContext'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'

const ScanInvoiceForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { setInvoiceDataFromFile } = useContext(ScanInvoiceContext)
  const [selectedFiles, setSelectedFiles] = useState([])

  const callbackResume = (response) => setInvoiceDataFromFile(response)
  const sendFile = () => {
    dispatch(
      uploadInvoice({
        data: { file: selectedFiles[0] },
        callbackResume,
      })
    )
  }
  const onFileUpload = (files) => {
    setSelectedFiles(files)
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="basic" color="textPrimary">
          {t('scanInvoiceModalDescription')}
        </Typography>
      </Box>
      <Box mb={4}>
        <FileUpload showPreview onFileUpload={onFileUpload} maxSize={5242880} />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={4}>
        <Button
          onClick={() => {
            sendFile()
            dispatch(sendGAEvent(GA_EVENT_NAMES.clickContinueUploadImage, { currentStep: STEPS.supply }))
          }}
          disabled={selectedFiles.length === 0}
          variant="contained"
          color="secondary"
        >
          {t('common.continue')}
        </Button>
      </Box>
    </>
  )
}

export default ScanInvoiceForm
