import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

const ErrorRetargetingModal = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onCloseModal = () => {
    handleClose()
    window.location.replace('https://www.naturgy.es')
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('retargetingModal.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('retargetingModal.description')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={onCloseModal}>
          {t('sirca.cancel')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ErrorRetargetingModal
