import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  newSupplyText: {
    fontSize: '18px',
    fontWeight: '300',
  },
}))

export default useStyles
