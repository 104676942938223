import SelectRate from 'containers/SelectRate'
import SelectTopping from 'containers/SelectTopping'
import OwnerStep from 'containers/OwnerStep'
import SupplyStep from 'containers/SupplyStep'
import BillingStep from 'containers/BillingStep'

import { STEPS } from 'utils/constants'

export const CONTRACT_STEPS = [
  {
    id: STEPS.owner,
    title: 'ownerData',
    titleCompleted: 'ownerData',
    isCompleted: false,
    isDisabled: false,
    Component: OwnerStep,
  },
  {
    id: STEPS.rate,
    title: 'chooseRate',
    titleCompleted: 'selectedRate',
    isCompleted: false,
    isDisabled: false,
    isAutocompleted: false,
    Component: SelectRate,
  },
  {
    id: STEPS.supply,
    title: 'supplyPointData',
    titleCompleted: 'supplyPointData',
    isCompleted: false,
    isDisabled: true,
    Component: SupplyStep,
  },
  {
    id: STEPS.topping,
    title: 'selectTopping',
    titleCompleted: 'selectedTopping',
    isCompleted: false,
    isDisabled: false,
    Component: SelectTopping,
  },
  {
    id: STEPS.billing,
    title: 'billingData',
    titleCompleted: 'billingData',
    isCompleted: false,
    isDisabled: false,
    Component: BillingStep,
  },
]
