import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Typography from 'ui/components/typography'
import { Box, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'
import { maskCups } from '@zatopek/core/src/utils/maskCups'

const MultiPointSummary = ({ handleClose, data: { supplyPoint } }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('multiPointSummary.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('multiPointSummary.text')}
          </Typography>
          <Box mt={2}>
            {supplyPoint?.map(
              ({ cups, energyType }, index) =>
                cups && (
                  <Box mb={1}>
                    <Typography key={cups} variant="basic">
                      <strong>
                        {t('multiPointSummary.supply', { energy: t(`common.${energyType?.toLowerCase()}`), index: index + 1 })}
                      </strong>{' '}
                      {maskCups(cups)}
                    </Typography>
                  </Box>
                )
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Link onClick={handleClose}>
          <Typography variant="link" color="primary" component="span">
            {t('common.returnContract')}
          </Typography>
        </Link>
      </DialogActions>
    </>
  )
}

export default MultiPointSummary
