import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { clearIsMultiPoint, setSupplyPoint } from 'services/customer/actions'
import { setStepUncompleted } from 'services/stepper/actions'

import { getSupplyPoint } from 'services/customer/selectors'
import { getCupsSearchByCups } from 'services/cupsSearcher/selectors'
import { getSelectedRate } from 'services/products/selectors'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

import CardContent from '@zatopek/core/src/ui/components/cardContent'
import { STEPS } from 'utils/constants'
import { getOrder } from 'services/order/selectors'
import { setOrders } from 'services/order/actions'
import { setInvoiceData } from 'services/invoice/actions'
import { setCupsSearcherByCups } from 'services/cupsSearcher/actions'

const RemoveSupplyPoint = ({ handleClose, data: { cups, index, invoiceData } }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const supplyPoint = useSelector(getSupplyPoint)
  const order = useSelector(getOrder)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const cupsSearchByCups = useSelector(getCupsSearchByCups)

  const selectedSupplyPoint = supplyPoint?.find((supplyPoint) => supplyPoint.cups === cups)

  const handleRemoveSupplyPoint = () => {
    const multiSupplyPoint = supplyPoint?.filter((supply) => supply.cups !== cups && !!supply.cups)
    const ordersData = order.orders?.filter((order) => order.cups !== cups)
    const byCups = { ...(cupsSearchByCups ?? {}) }
    if (selectedProductArray.length === 1) {
      const selectedProductId = selectedProduct[0]?.id?.replace('/middleware/families/', '')
      if (Object.keys(byCups).length !== 0 && selectedProductId in byCups && byCups[selectedProductId] !== undefined) {
        delete byCups[selectedProductId][cups]
        dispatch(setCupsSearcherByCups(byCups))
      }
    } else if (selectedProductArray.length > 1) {
      selectedProductArray.forEach((product) => {
        const selectedProductId = product?.id?.replace('/middleware/families/', '')
        if (Object.keys(byCups).length !== 0 && selectedProductId in byCups && byCups[selectedProductId] !== undefined) {
          delete byCups[selectedProductId][cups]
          dispatch(setCupsSearcherByCups(byCups))
        }
      })
    }

    dispatch(setSupplyPoint(multiSupplyPoint))
    if (ordersData) dispatch(setOrders(ordersData))
    dispatch(setStepUncompleted(STEPS.supply))
    if (multiSupplyPoint?.length === 1) dispatch(clearIsMultiPoint())

    const invoiceIndex = index ?? Object.keys(invoiceData).find((key) => invoiceData[key]?.cups === cups)

    if (invoiceIndex >= 0 && invoiceIndex in invoiceData) {
      delete invoiceData[invoiceIndex]
      Object.keys(invoiceData)
        .filter((key) => key > invoiceIndex)
        .sort()
        .forEach((index) => {
          invoiceData[index - 1] = invoiceData[index]
            ? {
                ...invoiceData[index],
                index: index - 1,
              }
            : null
          delete invoiceData[index]
        })
      dispatch(setInvoiceData(invoiceData))
    }

    handleClose()
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            Vas a eliminar un suministro añadido a la contratación
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            ¿Estás seguro que deseas continuar y eliminar este suministro de la contratación?
          </Typography>
          <Box mt={5}>
            <Box mb={1.5}>
              <Typography variant="h5" color="primary">
                {t('addressType', { type: t(`common.${selectedSupplyPoint?.energyType?.toLowerCase()}`) })}
              </Typography>
            </Box>
            <CardContent
              data={{ ...selectedSupplyPoint?.supplyAddress, cups: selectedSupplyPoint?.cups }}
              showCardContent
              isMultiPoint
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleRemoveSupplyPoint}>
          Eliminar suministro
        </Button>
        <Link onClick={handleClose}>
          <Typography variant="link" color="primary" component="span">
            Volver sin eliminar
          </Typography>
        </Link>
      </DialogActions>
    </>
  )
}

export default RemoveSupplyPoint
