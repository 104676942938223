import palette from '../base/palette'

const linearProgress = {
  overrides: {
    MuiLinearProgress: {
      root: {
        height: 8,
      },
      colorPrimary: {
        backgroundColor: palette.primary.light3,
      },
    },
  },
}

export default linearProgress
