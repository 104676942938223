import { createMuiTheme, fade } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import border from '../base/border'
import fonts from '../base/fonts'
import palette from '../base/palette'

const defaultTheme = createMuiTheme()

const forms = {
  props: {
    MuiCheckbox: {
      disableRipple: true,
      icon: <span />,
      checkedIcon: <CheckIcon color="primary" />,
    },
    MuiTextField: {
      variant: 'filled',
    },
    MuiSelect: {
      variant: 'filled',
    },
    MuiFormControl: {
      variant: 'filled',
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiRadio: {
      disableRipple: true,
      icon: <span />,
      checkedIcon: <FiberManualRecordIcon color="primary" />,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: palette.neutral.light2,
      },
    },
    MuiInputBase: {
      root: {
        borderColor: palette.neutral.light2,
        backgroundColor: defaultTheme.palette.common.white,
        '&$disabled': {
          background: palette.primary.light3,
          backgroundColor: palette.primary.light3,
        },
        '&.Mui-error': {
          borderColor: palette.feedback.danger,
        },
      },
      input: {
        fontSize: 16,
        lineHeight: '1.2 !important',
        fontWeight: 300,
        '&::placeholder': {
          opacity: 0.5,
          color: palette.primary.main,
        },
        '&[type=number].not("numericField")': {
          MozAppearance: 'textfield',
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100px ${defaultTheme.palette.common.white} inset`,
          borderRadius: defaultTheme.shape.borderRadius,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: defaultTheme.palette.common.white,
        border: `1px solid ${palette.neutral.light2}`,
        borderRadius: defaultTheme.shape.borderRadius,
        '&$disabled': {
          background: palette.primary.light3,
          backgroundColor: palette.primary.light3,
        },
        '&$focused': {
          borderColor: palette.primary.main,
          backgroundColor: defaultTheme.palette.common.white,
          // boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
        },
        '&:hover': {
          borderColor: palette.primary.main,
          backgroundColor: defaultTheme.palette.common.white,
          '&.Mui-error': {
            borderColor: palette.feedback.danger,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        color: palette.neutral.main,
      },
      shrink: {
        transform: 'scale(1)',
        marginBottom: 5,
        color: palette.primary.main,
        opacity: '0.7',
      },
    },
    MuiFormLabel: {
      root: {
        display: 'block',
        marginBottom: defaultTheme.spacing(1),
        fontWeight: 300,
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
      },
      label: {
        paddingTop: defaultTheme.spacing(1),
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: defaultTheme.palette.common.white,
          borderRadius: 'inherit',
        },
      },
      icon: {
        width: defaultTheme.spacing(3),
        height: defaultTheme.spacing(3),
      },
    },
    MuiCheckbox: {
      root: {
        '&:hover, &.MuiCheckbox-colorSecondary.Mui-checked:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiIconButton-label': {
          height: defaultTheme.spacing(3),
          width: defaultTheme.spacing(3),
          border: `${border.style.thin} solid ${palette.primary.light1}`,
          borderRadius: defaultTheme.shape.borderRadius,
        },
        '&.big .MuiIconButton-label': {
          height: defaultTheme.spacing(4),
          width: defaultTheme.spacing(4),
          '& .MuiSvgIcon-root': {
            height: defaultTheme.spacing(5),
            width: defaultTheme.spacing(5),
          },
        },
        '&.table .MuiIconButton-label': {
          '& .MuiSvgIcon-root': {
            height: defaultTheme.spacing(4),
            width: defaultTheme.spacing(4),
          },
        },
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            background: palette.primary.light3,
            borderColor: palette.primary.light3,
          },
          '& .MuiSvgIcon-colorPrimary': {
            color: palette.primary.light1,
          },
        },
      },
      colorSecondary: {
        '&.Mui-checked .MuiIconButton-label': {
          borderColor: palette.primary.main,
        },
      },
    },
    MuiRadio: {
      root: {
        '& .MuiIconButton-label': {
          height: defaultTheme.spacing(4),
          width: defaultTheme.spacing(4),
          boxShadow: `0 0 0 1px ${palette.primary.light1}`,
          borderRadius: '50%',
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.3)',
          },
        },
        '&.Mui-checked .MuiIconButton-label': {
          boxShadow: `0 0 0 2px ${palette.primary.main}`,
          background: palette.primary.light2,
        },
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            background: palette.primary.light3,
            boxShadow: `0 0 0 2px ${palette.primary.light2}`,
          },
          '& .MuiSvgIcon-root': {
            color: palette.primary.light2,
          },
        },
      },
    },
    MuiAutocomplete: {
      popper: {
        fontSize: 16,
      },
      paper: {
        fontSize: 16,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1em',
      },
    },
  },
}

export default forms
