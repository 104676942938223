import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'

import { Box, FormControl, FormHelperText, Grid, InputLabel, TextField as MuiTextField, Select, Tooltip } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { InfoOutlined } from '@material-ui/icons'
import { ELECTRICITY } from 'utils/constants'

const TechnicalDataForm = ({ values, energyType, onChange, touched, errors, setFieldValue, setFieldError }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h5" color="primary">
          Datos de la instalación
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* --- TARIFF --- */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="accessTariff">{t('newSupplyPoint.form.tariff')}</InputLabel>
            <Field component={Select} fullWidth native id="accessTariff" name="accessTariff" value={values.accessTariff} disabled>
              <option aria-label="None" value={values.accessTariff}>
                {values.accessTariff}
              </option>
            </Field>
          </FormControl>
          <ErrorMessage component={FormHelperText} name="accessTariff" error />
        </Grid>

        {/* --- ANNUAL CONSUMPTION --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            id="consumption12Months"
            label={t('Consumo anual')}
            name="consumption12Months"
            value={values.consumption12Months}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <Tooltip placement="top" title={t('pymeTooltip.consumption12Months')}>
                  <InfoOutlined color="primary" />
                </Tooltip>
              ),
            }}
          />
          <ErrorMessage component={FormHelperText} name="consumption12Months" error />
        </Grid>

        {energyType === ELECTRICITY && (
          <Grid item xs={12}>
            <Box mt={2}>
              <Box mb={2}>
                <Typography variant="h5" color="primary">
                  {t('supply.yourContractedPowerTitle')}
                </Typography>
              </Box>
              <Typography variant="basic">{t('supply.yourContractedPowerDetails')}</Typography>
            </Box>
          </Grid>
        )}

        {Object.entries(values)?.map(
          ([key, capacity]) =>
            key.includes('capacityP') &&
            !!capacity && (
              <Grid item key={key} xs={12} sm={4}>
                <Field
                  component={MuiTextField}
                  fullWidth
                  value={capacity}
                  variant="outlined"
                  label={t(`supply.${key}`)}
                  name={key}
                  type="number"
                  disabled
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <ErrorMessage component={FormHelperText} name={capacity} error />
              </Grid>
            )
        )}
      </Grid>
    </>
  )
}

export default TechnicalDataForm
