import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

const RequiredDocuments = ({ handleClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('necessaryDocumentation')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="basic">{t('necessaryDocumentationText')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t('common.understood')}
        </Button>
      </DialogActions>
    </>
  )
}

export default RequiredDocuments
