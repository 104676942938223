import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: theme.spacing(6),
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(2, 1),
    backgroundColor: theme.palette.neutral.light4,
    border: `${theme.border.style.thin} solid ${theme.palette.primary.light1}`,
    borderRadius: theme.border.radius.high,
    ...theme.typography.h6,
    color: theme.palette.primary.light1,
    '& .MuiChip-label': {
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  disabled: {
    background: theme.palette.primary.light3,
    border: `${theme.border.style.medium} solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&$disabled': {
      opacity: 1,
    },
  },
  unselectable: {
    background: theme.palette.primary.light3,
    border: `${theme.border.style.medium} solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&$disabled': {
      opacity: 0.5,
    },
  },
}))

export default useStyles
