import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'

import { Box, Button, FormHelperText, TextField as MuiTextField, CircularProgress } from '@material-ui/core'

import InputPassword from 'ui/components/inputPassword'

import useStyles from './styles'

const LoginForm = ({ onChange, onCancel, touched, values, errors, isFetching }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box className={classes.fields}>
        <Box>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.nif && errors.nif)}
            id="nif"
            label={t('form.fields.nif')}
            name="nif"
            value={values.nif}
            onChange={onChange('nif')}
          />
          <ErrorMessage component={FormHelperText} name="nif" error />
        </Box>

        <Box>
          <Field
            component={InputPassword}
            required
            fullWidth
            error={!!(touched.password && errors.password)}
            id="password"
            label={t('form.fields.password')}
            name="password"
            value={values.password}
            onChange={onChange('password')}
          />
          <ErrorMessage component={FormHelperText} name="password" error />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" mt={4}>
        <Button className={classes.loginButton} type="submit" variant="contained" color="secondary" disabled={isFetching}>
          {isFetching ? <CircularProgress size={24} thickness={1.4} color="inherit" /> : t('login')}
        </Button>
      </Box>
    </>
  )
}

export default LoginForm
