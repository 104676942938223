import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Button } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { maskCups } from '../../../utils/maskCups'
import { BOTH } from '@zatopek/core/src/utils/constants'

import useStyles from './styles'
import isEmptyObject from '../../../utils/isEmptyObject'

const MaskedCups = ({ data, secondaryData, normalizedAddress, openCupsModal, type }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const CupsTooltip = () => (
    <Button onClick={openCupsModal}>
      <InfoOutlinedIcon />
    </Button>
  )

  const showCupsTextObject = (service) => {
    return (
      <>
        <strong className={classes.emphasize}>CUPS {t(`services.${service}`).toUpperCase()}:</strong>
        {normalizedAddress[`${service}Cups`] && maskCups(normalizedAddress[`${service}Cups`])}
        {openCupsModal && <CupsTooltip />}
      </>
    )
  }

  const showCupsTextArray = (service) => {
    return (
      <>
        <strong className={classes.emphasize}>CUPS {t(`services.${service}`).toUpperCase()}:</strong>
        {normalizedAddress[0][`${service}Cups`] && maskCups(normalizedAddress[0][`${service}Cups`])}
        {openCupsModal && <CupsTooltip />}
      </>
    )
  }

  return normalizedAddress && !isEmptyObject(normalizedAddress) ? (
    normalizedAddress.length ? (
      <>
        {normalizedAddress[0].electricityCups && showCupsTextArray('electricity')}
        <br />
        {type === BOTH && normalizedAddress[0].gasCups && showCupsTextArray('gas')}
      </>
    ) : (
      <>
        {normalizedAddress.electricityCups && showCupsTextObject('electricity')}
        <br />
        {type === BOTH && normalizedAddress.gasCups && showCupsTextObject('gas')}
      </>
    )
  ) : (
    <>
      {data && data.cups && <strong className={classes.emphasize}>CUPS {secondaryData && 'LUZ'}:</strong>}
      {data && data.cups && maskCups(data.cups)}
      {openCupsModal && <CupsTooltip />}
      {secondaryData && secondaryData.cups && (
        <>
          <br />
          <strong className={classes.emphasize}>CUPS GAS:</strong>
          {maskCups(secondaryData.cups)}
          {openCupsModal && <CupsTooltip />}
        </>
      )}
    </>
  )
}

export default MaskedCups
