import { call, put, select, all, takeLatest } from 'redux-saga/effects'
import { getFidelizationAvailable } from './api'
import { setContractStatus, loadContractStatusStart, loadContractStatusSuccess, loadContractStatusError } from './actions'
import { getContractStatus } from './selectors'
import * as actionTypes from './action-types'

function* fetchContractStatusSaga({ payload: { nif, contractNumber } }) {
  const current = (yield select(getContractStatus)) || {}
  if (current.nif === nif && current.contractNumber === contractNumber) {
    return
  }
  yield put(loadContractStatusStart())
  try {
    const response = yield call(getFidelizationAvailable, { nif, contractNumber })
    yield put(setContractStatus(response.data))
    yield put(loadContractStatusSuccess())
  } catch (error) {
    console.error(error)
    yield put(loadContractStatusError())
  }
}

export default function* rootSaga() {
  yield all([takeLatest([actionTypes.FETCH_CONTRACT_STATUS], fetchContractStatusSaga)])
}
