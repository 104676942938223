import { ORDER_PORTABILITY_ALLOWED_FILTERS } from 'utils/constants'
import { pick } from 'utils/commons'
import { get } from '../client'

const getPortability = async (filters) => {
  let auxFilters = {}
  if (filters) {
    auxFilters = {
      ...filters,
      page: filters.page + 1 || 1,
      nbItems: filters.rowsPerPage || 10,
      createdTo: filters.toDate || '',
      createdFrom: filters.fromDate || '',
    }
  }
  const allowedFiltersKeys = [...ORDER_PORTABILITY_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(auxFilters, allowedFiltersKeys))
  const searchParams = new URLSearchParams([...allowedFilters]).toString()
  const response = await get(`portabilities?${searchParams}`, true)
  return response.data
}

export { getPortability }
