import CryptoJS from 'crypto-js'

const Sha256 = CryptoJS.SHA256
const Hex = CryptoJS.enc.Hex
const Utf8 = CryptoJS.enc.Utf8
const Base64 = CryptoJS.enc.Base64
const AES = CryptoJS.AES

const secret_key = process.env.REACT_APP_ONLINE_CHANNEL_LOGIN_SECRET_KEY
const secret_iv = process.env.REACT_APP_ONLINE_CHANNEL_LOGIN_SECRET_IV

const key = Sha256(secret_key).toString(Hex).substr(0, 32) // Use the first 32 bytes (see 2.)
const iv = Sha256(secret_iv).toString(Hex).substr(0, 16)

export const encryptPassword = (formPassword) => {
  const output = AES.encrypt(formPassword, Utf8.parse(key), {
    iv: Utf8.parse(iv),
  }).toString() // First Base64 encoding, by default (see 1.)

  const output2ndB64 = Utf8.parse(output).toString(Base64)

  return output2ndB64
}
