import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

export default combineReducers({
  loadControlRequest: redukers.request({
    start: actionTypes.LOAD_CONTROL_START,
    success: actionTypes.LOAD_CONTROL_SUCCESS,
    error: actionTypes.LOAD_CONTROL_ERROR,
    clear: actionTypes.LOAD_CONTROL_CLEAR,
  }),
  loadControlVisualizationRequest: redukers.request({
    start: actionTypes.LOAD_CONTROL_VISUALIZATION_START,
    success: actionTypes.LOAD_CONTROL_VISUALIZATION_SUCCESS,
    error: actionTypes.LOAD_CONTROL_VISUALIZATION_ERROR,
    clear: actionTypes.LOAD_CONTROL_VISUALIZATION_CLEAR,
  }),
  loadControlExportRequest: redukers.request({
    start: actionTypes.LOAD_CONTROL_EXPORT_START,
    success: actionTypes.LOAD_CONTROL_EXPORT_SUCCESS,
    error: actionTypes.LOAD_CONTROL_EXPORT_ERROR,
    clear: actionTypes.LOAD_CONTROL_EXPORT_CLEAR,
  }),
  channels: redukers.value(
    {
      set: actionTypes.SET_CHANNELS,
    },
    []
  ),
  products: redukers.value(
    {
      set: actionTypes.SET_PRODUCTS,
    },
    []
  ),
  status: redukers.value(
    {
      set: actionTypes.SET_STATUS,
    },
    []
  ),
  agencies: redukers.value(
    {
      set: actionTypes.SET_AGENCIES,
    },
    []
  ),
  filters: redukers.value(
    {
      set: actionTypes.SET_FILTERS,
      reset: actionTypes.CLEAR_FILTERS,
    },
    {}
  ),
  visualization: redukers.value(
    {
      set: actionTypes.SET_VISUALIZATION,
      reset: actionTypes.CLEAR_VISUALIZATION,
    },
    {}
  ),
  export: redukers.value(
    {
      set: actionTypes.SET_EXPORT,
      reset: actionTypes.CLEAR_EXPORT,
    },
    []
  ),
})
