import * as actionTypes from './action-types'

export const fetchTemplateFields = (templateType) => ({
  type: actionTypes.FETCH_TEMPLATE_FIELDS,
  payload: templateType,
})

export const fetchTemplateFieldsStart = () => ({
  type: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_START,
})

export const fetchTemplateFieldsSuccess = () => ({
  type: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_SUCCESS,
})

export const fetchTemplateFieldsError = () => ({
  type: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_ERROR,
})

export const fetchTemplateFieldsClear = () => ({
  type: actionTypes.FETCH_TEMPLATE_FIELDS_REQUEST_CLEAR,
})

export const setExportOrdersFields = (templateFields) => ({
  type: actionTypes.SET_EXPORT_ORDERS_TEMPLATE_FIELDS,
  payload: templateFields,
})

export const setViewOrdersFields = (templateFields) => ({
  type: actionTypes.SET_VIEW_ORDERS_TEMPLATE_FIELDS,
  payload: templateFields,
})
