import { createMuiTheme } from '@material-ui/core/styles'

import cssBaseline from './base/cssBaseline'
import palette from './base/palette'
import fonts from './base/fonts'
import border from './base/border'
import shadows from './base/shadows'
import { mergeDeep } from './base/utils'
import elements from './elements'

const theme = createMuiTheme({
  palette,
  typography: fonts,
  border,
  shadows,
  ...mergeDeep({ overrides: { ...cssBaseline } }, elements),
})

export default theme
