import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Link, DialogActions, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'
import useStyles from './styles'
import { resetFunnelContract } from 'services/operator/actions'

const ResetContract = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleResetContract = () => {
    dispatch(resetFunnelContract())
    handleClose()
  }

  return (
    <>
      <Box mb={4} mr={4} ml={4}>
        <DialogTitle disableTypography id="customized-dialog-title">
          <Typography variant="h3" color="primary">
            {t('modalResetContractTitle')}
          </Typography>
          <Box mt={4}>
            <Typography variant="basicBig">
              <span dangerouslySetInnerHTML={{ __html: t('modalResetContractDescription') }} />
            </Typography>
          </Box>
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <Box mr={4}>
            <Link component="button" variant="link" color="secondary" underline="always" onClick={handleClose}>
              {t('common.cancel')}
            </Link>
          </Box>
          <Button color="secondary" variant="contained" onClick={handleResetContract}>
            {t('common.continue')}
          </Button>
        </DialogActions>
      </Box>
    </>
  )
}

export default ResetContract
