import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import CssBaseline from '@material-ui/core/CssBaseline'

import { theme } from '@zatopek/core'
import { I18nextProvider } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import { ConnectedRouter } from 'connected-react-router'
import { Auth0Provider } from '@auth0/auth0-react'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './authConfig'

import i18n from './i18n'
import store, { history } from './redux/store'
import { set } from './services/sessionStorage'
import { AUTHENTICATION_AZURE } from 'utils/constants'

// Hack to hide console logs on production build
if (process.env.NODE_ENV !== 'development' && typeof window.console != 'undefined') {
  window.console.debug = () => {}
  window.console.dir = () => {}
  window.console.error = () => {}
  window.console.info = () => {}
  window.console.log = () => {}
  window.console.table = () => {}
  window.console.warn = () => {}
}

const onRedirectCallback = () => {
  set('logged', 'ok')
  history.push(window.location.pathname)
}

let msalInstance
let providerConfig

if (AUTHENTICATION_AZURE) {
  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   */
  msalInstance = new PublicClientApplication(msalConfig)

  // // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })
} else {
  providerConfig = {
    cacheLocation: 'localstorage',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
    onRedirectCallback,
  }
}

ReactDOM.render(
  <Provider store={store}>
    <QueryParamProvider history={history}>
      <ConnectedRouter history={history}>
        <I18nextProvider i18n={i18n}>
          {AUTHENTICATION_AZURE && (
            <MsalProvider instance={msalInstance}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </MuiThemeProvider>
            </MsalProvider>
          )}
          {!AUTHENTICATION_AZURE && (
            <Auth0Provider {...providerConfig}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </MuiThemeProvider>
            </Auth0Provider>
          )}
        </I18nextProvider>
      </ConnectedRouter>
    </QueryParamProvider>
  </Provider>,
  document.getElementById('root')
)
