import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionsResumeLocution: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 0),
  },
}))

export default useStyles
