import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  all: redukers.value(
    {
      set: actionTypes.SET_PRODUCTS,
      reset: actionTypes.CLEAR_PRODUCTS,
    },
    []
  ),
  selected: redukers.value(
    {
      set: actionTypes.SET_SELECTED_PRODUCT,
      reset: actionTypes.CLEAR_SELECTED_PRODUCT,
    },
    []
  ),
  selectedVariants: redukers.value(
    {
      set: actionTypes.SET_PRODUCT_VARIANTS,
      reset: actionTypes.CLEAR_PRODUCT_VARIANTS,
    },
    null
  ),
  areProductsValid: redukers.value(
    {
      set: actionTypes.SET_PRODUCTS_VALIDATION,
      reset: actionTypes.CLEAR_PRODUCTS_VALIDATION,
    },
    false
  ),
  requestSimulatorFile: redukers.request({
    start: actionTypes.START_REQUEST_SIMULATOR_FILE,
    success: actionTypes.SUCCESS_REQUEST_SIMULATOR_FILE,
    error: actionTypes.ERROR_REQUEST_SIMULATOR_FILE,
    clear: actionTypes.CLEAR_REQUEST_SIMULATOR_FILE,
  }),
  isOnlyToppings: redukers.value(
    {
      set: actionTypes.SET_IS_ONLY_TOPPINGS,
      reset: actionTypes.CLEAR_IS_ONLY_TOPPINGS,
    },
    false
  ),
  fetchedVariants: redukers.value(
    {
      set: actionTypes.SET_FETCHED_VARIANTS,
      reset: actionTypes.CLEAR_FETCHED_VARIANTS,
    },
    []
  ),
  selection: redukers.value(
    {
      set: actionTypes.SET_PRODUCT_SELECTION,
      reset: actionTypes.CLEAR_PRODUCT_SELECTION,
    },
    {}
  ),
})
