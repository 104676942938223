import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'
import { ORDERS } from 'utils/constants'

export default combineReducers({
  summary: redukers.value(
    {
      set: actionTypes.SET_SUMMARY,
      reset: actionTypes.CLEAR_SUMMARY,
    },
    {}
  ),
  ordersSummary: redukers.entities({
    updateById: actionTypes.UPDATE_ORDER_SUMMARY,
  }),
  productsSummary: redukers.entities({
    updateById: actionTypes.UPDATE_PRODUCT_SUMMARY,
  }),
  orders: redukers.value(
    {
      set: actionTypes.SET_ORDERS,
    },
    []
  ),
  ordersFail: redukers.value(
    {
      set: actionTypes.SET_ORDERS_FAIL,
    },
    []
  ),
  orderDetails: redukers.entities({
    updateById: actionTypes.UPDATE_ORDER_DETAIL,
  }),
  products: redukers.value(
    {
      set: actionTypes.SET_PRODUCTS,
    },
    []
  ),
  notifications: redukers.value(
    {
      set: actionTypes.SET_NOTIFICATIONS,
    },
    null
  ),
  totalOrders: redukers.value(
    {
      set: actionTypes.SET_TOTAL_ORDERS,
    },
    null
  ),
  totalOrdersFail: redukers.value(
    {
      set: actionTypes.SET_TOTAL_ORDERS_FAIL,
    },
    null
  ),
  totalProducts: redukers.value(
    {
      set: actionTypes.SET_TOTAL_ORDER_LINES,
    },
    null
  ),
  totalNotifications: redukers.value(
    {
      set: actionTypes.SET_TOTAL_NOTIFICATIONS,
    },
    null
  ),
  totalUnmanagedNotifications: redukers.value(
    {
      set: actionTypes.SET_TOTAL_UNMANAGED_NOTIFICATIONS,
    },
    null
  ),
  filtersConfiguration: redukers.entities({
    updateById: actionTypes.UPDATE_FILTER_CONFIG,
  }),
  exportRequest: redukers.request({
    start: actionTypes.EXPORT_REQUEST_START,
    success: actionTypes.EXPORT_REQUEST_SUCCESS,
    error: actionTypes.EXPORT_REQUEST_ERROR,
    clear: actionTypes.EXPORT_REQUEST_CLEAR,
  }),
  orderAttachmentExportRequest: redukers.request({
    start: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_START,
    success: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_SUCCESS,
    error: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_ERROR,
    clear: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_CLEAR,
  }),
  orderAttachmentExportId: redukers.value(
    {
      set: actionTypes.ORDER_ATTACHMENTS_EXPORT_ID,
    },
    null
  ),
  cancelOrderRequest: redukers.request({
    start: actionTypes.CANCEL_ORDER_START,
    success: actionTypes.CANCEL_ORDER_SUCCESS,
    error: actionTypes.CANCEL_ORDER_ERROR,
    clear: actionTypes.CANCEL_ORDER_CLEAR,
  }),
  verifyOrderRequest: redukers.request({
    start: actionTypes.VERIFY_ORDER_START,
    success: actionTypes.VERIFY_ORDER_SUCCESS,
    error: actionTypes.VERIFY_ORDER_ERROR,
    clear: actionTypes.VERIFY_ORDER_CLEAR,
  }),
  loadBackofficeRequest: redukers.request({
    start: actionTypes.LOAD_BACKOFFICE_START,
    success: actionTypes.LOAD_BACKOFFICE_SUCCESS,
    error: actionTypes.LOAD_BACKOFFICE_ERROR,
    clear: actionTypes.LOAD_BACKOFFICE_CLEAR,
  }),
  orderSummaryRequest: redukers.request({
    start: actionTypes.FETCH_ORDER_SUMMARY_START,
    success: actionTypes.FETCH_ORDER_SUMMARY_SUCCESS,
    error: actionTypes.FETCH_ORDER_SUMMARY_ERROR,
    clear: actionTypes.FETCH_ORDER_SUMMARY_CLEAR,
  }),
  productsSummaryRequest: redukers.request({
    start: actionTypes.PRODUCTS_SUMMARY_START,
    success: actionTypes.PRODUCTS_SUMMARY_SUCCESS,
    error: actionTypes.PRODUCTS_SUMMARY_ERROR,
    clear: actionTypes.PRODUCTS_SUMMARY_CLEAR,
  }),
  fetchTableDataRequest: redukers.request({
    start: actionTypes.FETCH_TABLE_DATA_START,
    success: actionTypes.FETCH_TABLE_DATA_SUCCESS,
    error: actionTypes.FETCH_TABLE_DATA_ERROR,
    clear: actionTypes.FETCH_TABLE_DATA_CLEAR,
  }),
  fetchNotificationsRequest: redukers.request({
    start: actionTypes.FETCH_NOTIFICATIONS_START,
    success: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
    error: actionTypes.FETCH_NOTIFICATIONS_ERROR,
    clear: actionTypes.FETCH_NOTIFICATIONS_CLEAR,
  }),
  deleteNotificationsRequest: redukers.request({
    start: actionTypes.DELETE_NOTIFICATIONS_START,
    success: actionTypes.DELETE_NOTIFICATIONS_SUCCESS,
    error: actionTypes.DELETE_NOTIFICATIONS_ERROR,
    clear: actionTypes.DELETE_NOTIFICATIONS_CLEAR,
  }),
  manageNotificationsRequest: redukers.request({
    start: actionTypes.MANAGE_NOTIFICATIONS_START,
    success: actionTypes.MANAGE_NOTIFICATIONS_SUCCESS,
    error: actionTypes.MANAGE_NOTIFICATIONS_ERROR,
    clear: actionTypes.MANAGE_NOTIFICATIONS_CLEAR,
  }),
  relaunchOrderRequest: redukers.request({
    start: actionTypes.RELAUNCH_ORDER_START,
    success: actionTypes.RELAUNCH_ORDER_SUCCESS,
    error: actionTypes.RELAUNCH_ORDER_ERROR,
    clear: actionTypes.RELAUNCH_ORDER_CLEAR,
  }),
  failedWithoutRecoveryRequest: redukers.request({
    start: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_START,
    success: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_SUCCESS,
    error: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_ERROR,
    clear: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_CLEAR,
  }),
  searchFilters: redukers.value(
    {
      set: actionTypes.SET_SEARCH_FILTERS,
      reset: actionTypes.CLEAR_SEARCH_FILTERS,
    },
    {}
  ),
  selectedTab: redukers.value(
    {
      set: actionTypes.SET_SELECTED_TAB,
      reset: actionTypes.CLEAR_SELECTED_TAB,
    },
    ORDERS
  ),
  selectedExportTemplateId: redukers.value(
    {
      set: actionTypes.SET_SELECTED_EXPORT_TEMPLATE_ID,
      reset: actionTypes.CLEAR_SELECTED_EXPORT_TEMPLATE_ID,
    },
    null
  ),
  notificationState: redukers.value(
    {
      set: actionTypes.SET_NOTIFICATION_STATE,
      reset: actionTypes.CLEAR_NOTIFICATION_STATE,
    },
    {
      isVisible: false,
      type: '',
      message: '',
    }
  ),
  errorNotification: redukers.value(
    {
      set: actionTypes.SET_ERROR_NOTIFICATION,
      reset: actionTypes.CLEAR_ERROR_NOTIFICATION,
    },
    {
      isVisible: false,
      type: '',
      message: '',
    }
  ),
})
