import CommercialPolicy from 'ui/modals/commercialPolicy'
import ServiceTerms from 'ui/modals/serviceTerms'
import DiscountSelector from 'ui/modals/discountSelector'
import Detail from 'ui/modals/detail'
import NoService from 'ui/modals/noService'
import ServiceNotAvailable from 'ui/modals/noService/serviceNotAvailable'
import RateNotAvailable from 'ui/modals/noService/rateNotAvailable'
import PowerChange from 'ui/modals/powerChange'
import Sepa from 'ui/modals/sepa'
import Exit from 'ui/modals/exit'
import Sirca from 'ui/modals/sirca'
import Iframe from 'ui/modals/iframe'
import EmailConfirm from 'ui/modals/emailConfirm'
import Login from 'containers/modals/Login'
import CallMeBack from 'containers/modals/CallMeBack'
import PrivacyPolicy from 'ui/modals/privacyPolicy'
import PrivacyPolicyConditions from 'ui/modals/privacyPolicyConditions'
import CupsInfo from 'ui/modals/cupsInfo'
import CancelOrder from 'containers/modals/CancelOrder'
import ResetContract from 'ui/modals/resetContract'
import Calculator from 'containers/modals/Calculator'
import TenureSeletorModal from 'containers/modals/TenureSelectorModal'
import ExistClientOnline from 'ui/modals/existClientOnline'
import ConfirmationModal from 'containers/modals/ConfirmationModal'
import AcceptModal from 'containers/modals/AcceptModal'
import WarningModal from 'containers/modals/WarningModal'
import ExportTemplateSelector from 'containers/modals/ExportTemplateSelector'
import Retargeting from 'ui/modals/retargeting'
import BonoSocial from 'containers/modals/BonoSocial'
import NewSupplyAddress from 'containers/modals/NewSupplyAddress'
import ConfirmNewSupply from 'ui/modals/confirmNewSupply'
import RequiredDocuments from 'ui/modals/requiredDocuments'
import ResumeLocution from 'ui/modals/resumeLocution'
import TechnicalData from 'containers/modals/TechnicalData'
import RemoveSupplyPoint from 'ui/modals/removeSupplyPoint'
import MultiPointSummary from 'ui/modals/multiPointSummary'
import MaxConsumptionAllowed from 'ui/modals/maxConsumptionAllowed'
import Autosave from 'ui/modals/autosave'
import Portability from 'ui/modals/portability'
import MemberGetMemberBenefits from 'ui/modals/memberGetMemberBenefits'
import MemberGetMemberConditions from 'ui/modals/memberGetMemberConditions'
import NewSupplyTechnical from 'containers/modals/NewSupplyTechnical'
import CheckCups from 'ui/modals/checkCups'
import ProductIncompatible from 'ui/modals/productIncompatible'
import SelfconsumptionTypeMigration from 'ui/modals/selfconsumptionTypeMigration'
import ErrorModal from 'ui/modals/error'
import AttachFile from 'ui/modals/attachFile'
import ScanInvoice from 'ui/modals/scanInvoice'
import ScanInvoiceInfo from 'ui/modals/scanInvoiceInfo'
import SessionExpiredModal from 'containers/modals/SessionExpiredModal'
import EmailLimit from 'ui/modals/mailLimit'
import PhoneLimit from 'ui/modals/phoneLimit'
import LimitChannel from 'ui/modals/limitChannel'
import CompleteEmail from 'ui/modals/completeEmail'
import ScoringModal from 'ui/modals/scoring'
import IbanRecoverInfo from 'ui/modals/ibanRecoverInfo'
import CheckAddress from 'containers/modals/CheckAddress'
import OwnerModal from 'containers/modals/OwnerModal'
import ChangeFailReason from 'ui/modals/changeFailReason'
import ErrorRetargetingModal from 'ui/modals/errorRetargeting'

export const SERVICE = 'MODAL'

const SIZES = {
  large: 'lg',
  medium: 'md',
  small: 'sm',
}

export const MODAL_TYPES = {
  autosave: 'autosave',
  bonoSocial: 'bonoSocial',
  calculatorModal: 'calculatorModal',
  callMeBack: 'callMeBack',
  cancelOrder: 'cancelOrder',
  checkCups: 'checkCups',
  checkNewSupplyPoint: 'checkNewSupplyPoint',
  commercialPolicy: 'commercialPolicy',
  confirmationModal: 'confirmationModal',
  acceptModal: 'acceptModal',
  warningModal: 'warningModal',
  newSupply: 'newSupply',
  confirmNewSupply: 'confirmNewSupply',
  cups: 'cups',
  detail: 'detail',
  discountSelector: 'discountSelector',
  emailConfirm: 'emailConfirm',
  error: 'error',
  existClientOnline: 'existClientOnline',
  exit: 'exit',
  exportTemplateSelector: 'templateSelector',
  iframe: 'iframe',
  login: 'login',
  sessionExpired: 'sessionExpired',
  maxConsumptionAllowed: 'MaxConsumptionAllowed',
  memberGetMemberBenefits: 'memberGetMemberBenefits',
  memberGetMemberConditions: 'memberGetMemberConditions',
  multiPointSummary: 'multiPointSummary',
  newSupplyAddress: 'newSupplyAddress',
  newSupplyTechnical: 'newSupplyTechnical',
  noService: 'noService',
  portability: 'portability',
  powerChange: 'powerChange',
  privacyPolicy: 'privacyPolicy',
  privacyPolicyConditions: 'privacyPolicyConditions',
  rateNotAvailable: 'rateNotAvailable',
  removeSupplyPoint: 'removeSupplyPoint',
  requiredDocuments: 'requiredDocuments',
  resetContract: 'resetContract',
  resumeLocution: 'resumeLocution',
  retargeting: 'retargeting',
  sepa: 'sepa',
  serviceNotAvailable: 'serviceNotAvailable',
  serviceTerms: 'serviceTerms',
  sirca: 'sirca',
  scoring: 'scoring',
  technicalData: 'technicalData',
  productIncompatible: 'productIncompatible',
  selfconsumptionTypeMigration: 'selfconsumptionTypeMigration',
  tenureSelector: 'tenureSelector',
  attachFile: 'attachFile',
  scanInvoice: 'scanInvoice',
  scanInvoiceInfo: 'uploadFileInfo',
  ibanRecoverInfo: 'ibanRecoverInfo',
  emailLimit: 'emailLimit',
  phoneLimit: 'phoneLimit',
  limitChannel: 'limitChannel',
  completeEmail: 'completeEmail',
  checkAddress: 'checkAddress',
  changeFailReason: 'changeFailReason',
  ownerModal: 'ownerModal',
  errorRetargeting: 'errorRetargeting',
}

export const MODALS = {
  [MODAL_TYPES.scanInvoiceInfo]: { component: ScanInvoiceInfo, size: SIZES.medium, gaEvent: 'closeInfoModal' },
  [MODAL_TYPES.ibanRecoverInfo]: { component: IbanRecoverInfo, size: SIZES.medium },
  [MODAL_TYPES.autosave]: { component: Autosave, size: SIZES.medium, gaEvent: 'closePopUp' },
  [MODAL_TYPES.bonoSocial]: { component: BonoSocial, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.calculatorModal]: { component: Calculator, size: SIZES.medium },
  [MODAL_TYPES.callMeBack]: { component: CallMeBack, size: SIZES.small },
  [MODAL_TYPES.cancelOrder]: { component: CancelOrder, size: SIZES.large },
  [MODAL_TYPES.checkCups]: { component: CheckCups, size: SIZES.medium },
  [MODAL_TYPES.commercialPolicy]: { component: CommercialPolicy, size: SIZES.medium },
  [MODAL_TYPES.confirmationModal]: { component: ConfirmationModal, size: SIZES.medium },
  [MODAL_TYPES.confirmNewSupply]: { component: ConfirmNewSupply, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.cups]: { component: CupsInfo, size: SIZES.medium, gaEvent: 'closeCupsInfo' },
  [MODAL_TYPES.detail]: { component: Detail, size: SIZES.medium, gaEvent: 'closeDetailModal' },
  [MODAL_TYPES.discountSelector]: { component: DiscountSelector, size: SIZES.medium },
  [MODAL_TYPES.emailConfirm]: { component: EmailConfirm, size: SIZES.medium },
  [MODAL_TYPES.error]: { component: ErrorModal, size: SIZES.medium },
  [MODAL_TYPES.existClientOnline]: { component: ExistClientOnline, size: SIZES.medium },
  [MODAL_TYPES.confirmationModal]: { component: ConfirmationModal, size: SIZES.medium },
  [MODAL_TYPES.acceptModal]: { component: AcceptModal, size: SIZES.medium },
  [MODAL_TYPES.exit]: { component: Exit, size: SIZES.medium },
  [MODAL_TYPES.warningModal]: { component: WarningModal, size: SIZES.medium },
  [MODAL_TYPES.exportTemplateSelector]: { component: ExportTemplateSelector, size: SIZES.medium },
  [MODAL_TYPES.iframe]: { component: Iframe, size: SIZES.large, gaEvent: 'closePricesModal' },
  [MODAL_TYPES.login]: { component: Login, size: SIZES.small },
  [MODAL_TYPES.maxConsumptionAllowed]: { component: MaxConsumptionAllowed, size: SIZES.medium },
  [MODAL_TYPES.memberGetMemberBenefits]: { component: MemberGetMemberBenefits, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.memberGetMemberConditions]: { component: MemberGetMemberConditions, size: SIZES.medium },
  [MODAL_TYPES.multiPointSummary]: { component: MultiPointSummary, size: SIZES.medium },
  [MODAL_TYPES.newSupplyAddress]: { component: NewSupplyAddress, size: SIZES.medium },
  [MODAL_TYPES.newSupplyTechnical]: { component: NewSupplyTechnical, size: SIZES.medium },
  [MODAL_TYPES.noService]: { component: NoService, size: SIZES.medium },
  [MODAL_TYPES.portability]: { component: Portability, size: SIZES.medium },
  [MODAL_TYPES.powerChange]: { component: PowerChange, size: SIZES.medium },
  [MODAL_TYPES.privacyPolicy]: { component: PrivacyPolicy, size: SIZES.medium },
  [MODAL_TYPES.privacyPolicyConditions]: { component: PrivacyPolicyConditions, size: SIZES.large },
  [MODAL_TYPES.rateNotAvailable]: { component: RateNotAvailable, size: SIZES.medium },
  [MODAL_TYPES.removeSupplyPoint]: { component: RemoveSupplyPoint, size: SIZES.medium },
  [MODAL_TYPES.requiredDocuments]: { component: RequiredDocuments, size: SIZES.medium },
  [MODAL_TYPES.resetContract]: { component: ResetContract, size: SIZES.large },
  [MODAL_TYPES.resumeLocution]: { component: ResumeLocution, size: SIZES.medium },
  [MODAL_TYPES.retargeting]: { component: Retargeting, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.sepa]: { component: Sepa, size: SIZES.medium },
  [MODAL_TYPES.serviceNotAvailable]: { component: ServiceNotAvailable, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.serviceTerms]: { component: ServiceTerms, size: SIZES.medium, gaEvent: 'closeConditionsPopUp' },
  [MODAL_TYPES.sirca]: { component: Sirca, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.technicalData]: { component: TechnicalData, size: SIZES.medium },
  [MODAL_TYPES.productIncompatible]: { component: ProductIncompatible, size: SIZES.small },
  [MODAL_TYPES.selfconsumptionTypeMigration]: { component: SelfconsumptionTypeMigration, size: SIZES.medium },
  [MODAL_TYPES.tenureSelector]: { component: TenureSeletorModal, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.attachFile]: { component: AttachFile, size: SIZES.medium },
  [MODAL_TYPES.scanInvoice]: { component: ScanInvoice, size: SIZES.medium },
  [MODAL_TYPES.sessionExpired]: { component: SessionExpiredModal, size: SIZES.large },
  [MODAL_TYPES.emailLimit]: { component: EmailLimit, size: SIZES.medium },
  [MODAL_TYPES.phoneLimit]: { component: PhoneLimit, size: SIZES.medium },
  [MODAL_TYPES.limitChannel]: { component: LimitChannel, size: SIZES.medium },
  [MODAL_TYPES.completeEmail]: { component: CompleteEmail, size: SIZES.medium },
  [MODAL_TYPES.scoring]: { component: ScoringModal, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.checkAddress]: { component: CheckAddress, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.ownerModal]: { component: OwnerModal, size: SIZES.medium, persistent: true },
  [MODAL_TYPES.changeFailReason]: { component: ChangeFailReason, size: SIZES.large },
  [MODAL_TYPES.errorRetargeting]: { component: ErrorRetargetingModal, size: SIZES.medium, persistent: true },
}
