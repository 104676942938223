import { request } from 'redukers'

import { getIsOnlineChannel, getReferenceSegment } from 'services/operator/selectors'
import { getRouterSearch } from 'services/analytics/selectors'
import { getCustomerFormData, getEnergyUseFromParam } from './business'
import { getOnlineChannelUrlParams } from 'services/onlineChannel/business'
import { COMMERCIAL, PYME, SELECTOR_LANGUAGES, COMPANY_CODES } from 'utils/constants'
import { INITIAL_FORM_DATA, NOT_CLIENT_ERROR, NOT_CONTRACT_ERROR, NOT_TENURE_ERROR, SERVER_ERROR } from './constants'
import {
  WITOUT_SELFCONSUMPTION,
  WITH_SURPLUS,
  WITHOUT_SURPLUS,
  WITH_SURPLUS_WITH_COMPENSATION,
  WITHOUT_SURPLUS_WITH_COMPENSATION,
  INDIVIDUAL,
} from 'services/selfconsumption/constants'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'

const USER_DATA_REQUEST_CONTROLLED_ERRORS = [NOT_CLIENT_ERROR, NOT_TENURE_ERROR, SERVER_ERROR, NOT_CONTRACT_ERROR]

export const getCustomer = (state) => state.customer
export const getCustomerData = (state) => state.customer.data
export const getCustomerDataPolicies = (state) => {
  const { thirdPartyPolicy, publicityPolicy, complexProfilePolicy } = state.customer.data
  return thirdPartyPolicy && publicityPolicy && complexProfilePolicy
}
export const getCustomerRetargeting = (state) => state.customer.data.retargeting
export const getBillingAccount = (state) => state.customer.billingAccount
export const getBillingAddress = (state) => state.customer.billingAddress
export const getSupplyPoint = (state) => state.customer.supplyPoints
export const getIsUserDataFetching = (state) => {
  const userDataRequest = state.customer.userDataRequest
  return request.getIsRequesting(userDataRequest)
}
export const getUserDataError = (state) => {
  const userDataRequest = state.customer.userDataRequest
  return request.getError(userDataRequest)
}
export const getUserDataChecked = (state) => {
  const userDataRequest = state.customer.userDataRequest
  return (
    request.getIsRequestSucceeded(userDataRequest) ||
    USER_DATA_REQUEST_CONTROLLED_ERRORS.includes(request.getError(userDataRequest))
  )
}
export const getUserData = (state) => state.customer.userData || state.customer.data
export const getSelectedTenure = (state) => state.customer.selectedTenure
export const getSelectedCompany = (state) => state.customer.selectedCompany
export const getIsTenureSelected = (state) => !isEmptyObject(getSelectedTenure(state))
export const getOnlineInvoice = (state) => state.customer.onlineInvoice
export const getIsExClient = (state) => state.customer.isExClient
export const getIsAutosave = (state) => state.customer.autosave
export const getReferralCode = (state) => state.customer.referralCode
export const getPortabilityNewcoCheck = (state) => state.customer.portabilityNewcoCheck
export const getChangeSuppyOwnerShip = (state) => state.customer.changeSupplyOwnership
export const getChangeOwnerCups = (state) => state.customer.changeOwnerCups
export const getFormData = (state) => {
  const userData = getUserData(state)
  const formData = getCustomerFormData(userData)
  const customerData = getCustomerData(state)
  const selectedTenure = getSelectedTenure(state)
  const isOnlineChannel = getIsOnlineChannel(state)
  const referenceSegment = getReferenceSegment(state)
  const search = getRouterSearch(state)
  const onlineChannelParams = getOnlineChannelUrlParams(search)
  // TODO: move to selector
  const leadDraftData = state.draft.lead

  const isPyme = referenceSegment === PYME

  if (!isEmptyObject(leadDraftData)) {
    const {
      documentType,
      documentNumber,
      checkGDPR1,
      checkGDPR2,
      checkGDPR3,
      retargeting,
      firstName,
      lastName,
      secondLastName,
      email,
      phone,
      preferredLanguage,
      cnae,
      contact,
    } = leadDraftData

    return {
      ...INITIAL_FORM_DATA,
      idDocumentType: documentType ? documentType : isPyme ? 'CIF' : 'NIF',
      idDocumentNumber: documentNumber,
      complexProfilePolicy: checkGDPR1,
      publicityPolicy: checkGDPR2,
      thirdPartyPolicy: checkGDPR3,
      retargeting,
      name: firstName,
      lastName,
      secondLastName,
      email,
      phone,
      language: preferredLanguage,
      cnae,
      ...(contact && {
        contactName: contact.name,
        contactLastName: contact.lastName,
        contactSecondLastName: contact.secondLastName,
        contactDegree: contact.degree,
        contactDocumentNumber: contact.documentNumber,
        contactTelephone: contact.phone,
        contactMobilePhone: contact.mobilePhone,
        contactFax: contact.fax,
        contactEmail: contact.email,
      }),
    }
  }
  return {
    ...INITIAL_FORM_DATA,
    ...formData,
    ...customerData,
    idDocumentType: formData?.idDocumentType || (isPyme ? 'CIF' : 'NIF'),
    energyUse: isOnlineChannel && isPyme ? getEnergyUseFromParam(onlineChannelParams?.usoenergia) : COMMERCIAL,
    iban: selectedTenure?.iban || '',
    language: selectedTenure?.preferredLanguage || SELECTOR_LANGUAGES.spanish.value,
  }
}

export const getNormalizedAddress = (state) => state.customer.normalizedAddress
export const getSupplyAddress = (state) => state.customer.supplyAddress
export const getIsChangeBillingAddress = (state) => state.customer.isChangeBillingAddress
export const isTenureSelectionError = (state) => state.customer.isTenureSelectionError

export const getIsNewSupply = (state) => state.customer.isNewSupply

export const getCompany = (state) => state.customer.company
export const getAddresDualAlert = (state) => state.customer.addresDualAlert
export const getIsCIERequired = (state) => state.customer.isCIERequired

export const getOnBehalfContactId = (state) => state.customer.onBehalfContactId

export const getIsMultiPoint = (state) => state.customer.isMultiPoint

export const getDifferentBillingAddress = (state) => state.customer.differentBillingAddress
export const getCustomerReferralCode = (state) => state.customer.referralCode
export const getCustomerBuyapowaCode = (state) => state.customer.buyapowaCode
export const getCupsOK = (state) => state.customer.cupsOK

export const getInitialSelfconsumptionType = (state) => state.customer.initialSelfconsumptionType
export const getSelfconsumptionType = (state) => state.customer.selfconsumptionType ?? WITOUT_SELFCONSUMPTION
export const getSelfconsumptionTypes = (state) => state.selfconsumption.types
export const getSelfconsumptionTypeDetail = (state) => {
  const selfconsumptionType = getSelfconsumptionType(state)
  return state.selfconsumption.types?.find(({ code }) => code === selfconsumptionType)
}
export const getCurrentSelfconsumptionType = (state) =>
  state.customer?.supplyPoints?.length > 0
    ? state.customer?.supplyPoints[0]?.selfConsumptionType ?? WITOUT_SELFCONSUMPTION
    : WITOUT_SELFCONSUMPTION
export const getCollective = (state) => state.customer.collective
export const getCurrentCollective = (state) =>
  state.customer?.supplyPoints?.length > 0 ? state.customer?.supplyPoints[0]?.collective ?? INDIVIDUAL : INDIVIDUAL
export const getCupsType = (state) => state.customer.cupsType
export const getCurrentCupsType = (state) =>
  state.customer?.supplyPoints?.length > 0 ? state.customer?.supplyPoints[0]?.cupsType : null
export const getCurrentInstalledPower = (state) =>
  state.customer?.supplyPoints?.length > 0 ? state.customer?.supplyPoints[0]?.installedPower : null
export const getInstalledPower = (state) => state.customer.installedPower
export const getSignUpForSelfConsumption = (state) => state.customer.signUpForSelfConsumption
export const getSubsection = (state) => state.customer.subsection

export const getCurrentSubsection = (state) => {
  if (!state.customer?.supplyPoints?.length > 0) return null
  if (state.customer?.supplyPoints[0]?.subsection) return state.customer?.supplyPoints[0]?.subsection

  const cupsType = getCupsType(state) ?? getCurrentCupsType(state)
  let subsection = null

  switch (cupsType) {
    case WITH_SURPLUS:
      subsection = state.customer.subsection?.selfConsumptionSubsectionType?.find(
        ({ code }) => code === WITH_SURPLUS_WITH_COMPENSATION
      )
      break
    case WITHOUT_SURPLUS:
      subsection = state.customer.subsection?.selfConsumptionSubsectionType?.find(
        ({ code }) => code === WITHOUT_SURPLUS_WITH_COMPENSATION
      )
      break
    default:
      subsection = null
  }

  return subsection
}

export const getIsSelfconsumptionTypeChange = (state) => getSelfconsumptionType(state) !== getCurrentSelfconsumptionType(state)
export const getIsSelfconsumptionTypeMigration = (state) => {
  const selfconsumptionType = getSelfconsumptionType(state)
  const currentSelfconsumptionType = getCurrentSelfconsumptionType(state)
  return (
    selfconsumptionType !== WITOUT_SELFCONSUMPTION &&
    currentSelfconsumptionType !== WITOUT_SELFCONSUMPTION &&
    currentSelfconsumptionType !== selfconsumptionType
  )
}

export const getTenuresInCurrentCompany = (state) => {
  const company = getCompany(state)
  const tenures = company ? getUserData(state)?.tenure ?? [] : []

  return tenures.filter(({ origin }) => COMPANY_CODES[origin.toLowerCase()] === company.code).flatMap(({ tenure }) => tenure)
}

export const getHasTenuresInCurrentCompany = (state) => {
  const tenures = getTenuresInCurrentCompany(state)

  return tenures.length > 0
}

export const getRecordUFD = (state) => state.customer.recordUFD
