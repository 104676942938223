import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Typography from 'ui/components/typography'
import deleteEmptyFieldsObject from '@zatopek/core/src/utils/deleteEmptyFieldsObject'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import useStyles from './styles'

const AddressErrorsAlert = ({ errors, touched }) => {
  const classes = useStyles()

  const { portal, stair, floor, door } = errors
  const addressFormErrors = { portal, stair, floor, door }
  deleteEmptyFieldsObject(addressFormErrors)

  return (
    !isEmptyObject(addressFormErrors) &&
    !isEmptyObject(touched) && (
      <Box mt={2} mb={3}>
        <Alert icon={false} severity="warning">
          {Object.values(addressFormErrors).map((error, index) => (
            <Typography key={index} className={classes.errorList} variant="basic" dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </Alert>
      </Box>
    )
  )
}

export default AddressErrorsAlert
