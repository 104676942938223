import React from 'react'

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import useStyles from './styles'

const RadioField = ({ name, value, values, onChange, disabled }) => {
  const classes = useStyles()

  const handleChange = (e) => onChange(e.target.value)

  return (
    <FormControl component="fieldset">
      <RadioGroup className={classes.radioGroup} name={name} value={value} onChange={handleChange}>
        {values.map((value) => (
          <FormControlLabel
            key={`radio-${value}`}
            value={value}
            disabled={disabled}
            control={<Radio className={classes.radio} color="primary" />}
            label={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioField
