import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setAutosave } from 'services/customer/actions'
import { startSendDraftData } from 'services/draftCopy/actions'
import { setNotificationState } from 'services/notification/actions'
import { getLeadId } from 'services/lead/selectors'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES } from 'utils/constants'

const Retargeting = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const leadId = useSelector(getLeadId)

  const submitAutosave = () => {
    dispatch(setAutosave(true))
    dispatch(sendGAEvent(GA_EVENT_NAMES.savePopUp))
    if (leadId) {
      dispatch(startSendDraftData(leadId))
    } else {
      dispatch(
        sendGAEvent(GA_EVENT_NAMES.ownerAlert),
        setNotificationState({
          message: 'notificationAlerts.activatedAutosave',
          type: 'warning',
          isVisible: true,
        })
      )
    }
    handleClose()
  }
  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography variant="h4">{t('autosave.title')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="basic">{t('autosave.text')}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            dispatch(sendGAEvent(GA_EVENT_NAMES.cancelPopUp))
            handleClose()
          }}
        >
          {t('common.cancel')}
        </Button>
        <Button type="submit" color="secondary" variant="contained" onClick={() => submitAutosave()}>
          {t('autosave.save')}
        </Button>
      </DialogActions>
    </>
  )
}

export default Retargeting
