import * as actionTypes from './action-types'

export const loadControlStart = () => ({
  type: actionTypes.LOAD_CONTROL_START,
})

export const loadControlSuccess = () => ({
  type: actionTypes.LOAD_CONTROL_SUCCESS,
})

export const loadControlError = () => ({
  type: actionTypes.LOAD_CONTROL_ERROR,
})

export const loadControlVisualizationStart = () => ({
  type: actionTypes.LOAD_CONTROL_VISUALIZATION_START,
})

export const loadControlVisualizationSuccess = () => ({
  type: actionTypes.LOAD_CONTROL_VISUALIZATION_SUCCESS,
})

export const loadControlVisualizationError = () => ({
  type: actionTypes.LOAD_CONTROL_VISUALIZATION_ERROR,
})

export const loadControlVisualizationClear = () => ({
  type: actionTypes.LOAD_CONTROL_VISUALIZATION_CLEAR,
})

export const loadControlExportStart = () => ({
  type: actionTypes.LOAD_CONTROL_EXPORT_START,
})

export const loadControlExportSuccess = () => ({
  type: actionTypes.LOAD_CONTROL_EXPORT_SUCCESS,
})

export const loadControlExportError = () => ({
  type: actionTypes.LOAD_CONTROL_EXPORT_ERROR,
})

export const loadControlExportClear = () => ({
  type: actionTypes.LOAD_CONTROL_EXPORT_CLEAR,
})

export const setChannels = (channels) => ({
  type: actionTypes.SET_CHANNELS,
  payload: channels,
})

export const setProducts = (products) => ({
  type: actionTypes.SET_PRODUCTS,
  payload: products,
})

export const setStatus = (status) => ({
  type: actionTypes.SET_STATUS,
  payload: status,
})

export const setAgencies = (agencies) => ({
  type: actionTypes.SET_AGENCIES,
  payload: agencies,
})

export const fetchFiltersConfiguration = () => ({
  type: actionTypes.FETCH_FILTERS_CONFIGURATION,
})

export const fetchVisualization = () => ({
  type: actionTypes.FETCH_VISUALIZATION,
})

export const stopVisualization = () => ({
  type: actionTypes.STOP_VISUALIZATION,
})

export const fetchExport = () => ({
  type: actionTypes.FETCH_EXPORT,
})

export const setFilters = (filters) => ({
  type: actionTypes.SET_FILTERS,
  payload: filters,
})

export const clearFilters = () => ({
  type: actionTypes.CLEAR_FILTERS,
})

export const setVisualization = (payload) => ({
  type: actionTypes.SET_VISUALIZATION,
  payload: payload,
})

export const clearVisualization = () => ({
  type: actionTypes.CLEAR_VISUALIZATION,
})

export const setExport = (payload) => ({
  type: actionTypes.SET_EXPORT,
  payload: payload,
})

export const clearExport = () => ({
  type: actionTypes.CLEAR_EXPORT,
})
