// overrides
import { emeric, emericBold, emericLight } from './fonts'

const cssBaseline = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [emeric, emericBold, emericLight],
      html: {
        fontSize: 16,
      },
      body: {
        backgroundColor: 'white',
      },
    },
  },
}

export default cssBaseline
