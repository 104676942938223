import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

export default combineReducers({
  phoneNumber: redukers.value(
    {
      set: actionTypes.SET_PHONE_NUMBER,
      reset: actionTypes.CLEAR_PHONE_NUMBER,
    },
    ''
  ),
  idCampaign: redukers.value(
    {
      set: actionTypes.SET_ID_CAMPAIGN,
      reset: actionTypes.CLEAR_ID_CAMPAIGN,
    },
    ''
  ),
  lead: redukers.value(
    {
      set: actionTypes.SET_CALL_ME_BACK_LEAD,
      reset: actionTypes.CLEAR_CALL_ME_BACK_LEAD,
    },
    ''
  ),
  isSendLeadRequesting: redukers.value(
    {
      set: actionTypes.SET_IS_SEND_LEAD_REQUESTING,
    },
    false
  ),
})
