export const ADDRESS = 'address'
export const BOTH = 'both'
export const BOTH_ENUM = 'electricity,gas'
export const CUPS = 'cups'
export const ELECTRICITY = 'electricity'
export const GAS = 'gas'
export const PYME = 'pyme'
export const NEWCO = 'NewCo'
export const GNS = 'GNS'
export const NATURGY = 'Naturgy'
export const NATURGY_CLIENTS = 'Naturgy Clientes'

export const COMPANY_CODES = {
  newco: 'NewCo',
  gns: 'GNS',
}

export const NATURGY_HOME_URL = 'https://www.naturgy.es/'
export const SERVICES = {
  [ELECTRICITY]: 'electricity',
  [GAS]: 'gas',
  [BOTH]: 'both',
}

export const SERVICES_CHIP = {
  [ELECTRICITY]: 'electricity',
  [GAS]: 'gas',
  [BOTH_ENUM]: 'both',
}

export const PROVIDER_CODES = {
  [NEWCO]: 'newco',
  [GNS]: 'iberia',
}

export const GA_SERVICES = {
  [ELECTRICITY]: 'Luz',
  [GAS]: 'Gas',
  [BOTH]: 'Luzygas',
}

export const ADDRESS_OPTIONS = {
  [CUPS]: 'supply.cups',
  [ADDRESS]: 'supply.address',
}

export const CHANNEL_NAMES = {
  onlineSale: 'Venta Telefónica',
  vtOutbound: 'Venta Telefónica Outbound',
  vtInbound: 'Venta Telefónica Inbound',
  shops: 'Tiendas',
  operations: 'Operaciones',
  expansion: 'Expansión',
  salesForce: 'Fuerza de Ventas',
  newCollaborators: 'Nuevos Colaboradores',
}

export const DELAY_TIME = 500

export const SERVICE_CUPS_MAP = {
  [ELECTRICITY]: 'electricityCups',
  [GAS]: 'gasCups',
}

export const MAX_CUPS_LENGTH = 22

export const STREET_TYPE_DEFAULT = 'CALLE'
