import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 4, 4),
    width: '900px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  examine: {
    marginTop: '28px',
  },
}))

export default useStyles
