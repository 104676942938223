import { createMuiTheme, fade } from '@material-ui/core/styles'
import palette from '../base/palette'

const defaultTheme = createMuiTheme()

const alerts = {
  overrides: {
    MuiAlert: {
      root: {
        padding: defaultTheme.spacing(3, 2),
        alignItems: 'center',
      },
      message: {
        padding: 0,
        '& .MuiLink-root': {
          whiteSpace: 'nowrap',
        },
      },
      standardInfo: {
        backgroundColor: palette.primary.light3,
        '& .MuiAlert-message': {
          color: palette.primary.main,
          fontWeight: 300,
        },
      },
      standardWarning: {
        backgroundColor: fade(palette.feedback.warning, 0.2),
      },
      icon: {
        marginRight: defaultTheme.spacing(2),
      },
    },
  },
}

export default alerts
