import * as actionTypes from './action-types'

export const setEnergies = (energies) => ({
  type: actionTypes.SET_ENERGIES,
  payload: energies,
})

export const resetEnergies = () => ({
  type: actionTypes.CLEAR_ENERGIES,
})
