import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const CommercialPolicy = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography variant="h4">{t('legal.commercialPolicyTitle')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography gutterBottom>{t('legal.commercialPolicyText')}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default CommercialPolicy
