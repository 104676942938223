import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, DialogContent } from '@material-ui/core'
import Typography from 'ui/components/typography'
import useStyles from './styles'

const EmailLimit = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <DialogContent className={classes.dialogContent}>
      <Box mb={3}>
        <Typography variant="h3" color="primary">
          {t('common.emailLimitTitle')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="basic" color="textPrimary">
          {t('common.emailLimitDescription')}
        </Typography>
      </Box>
    </DialogContent>
  )
}

export default EmailLimit
