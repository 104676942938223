import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  image: {
    display: 'block',
    height: 'auto',
    margin: '0 auto',
    maxWidth: '100%',
    padding: theme.spacing(4, 0, 8),
  },
}))

export default useStyles
