import * as actionTypes from './action-types'

export const setDraftOrder = (order) => ({
  type: actionTypes.SET_DRAFT_ORDER,
  payload: order,
})

export const clearDraftOrder = () => ({
  type: actionTypes.CLEAR_DRAFT_ORDER,
})

export const setDraftLead = (lead) => ({
  type: actionTypes.SET_DRAFT_LEAD,
  payload: lead,
})

export const clearDraftLead = () => ({
  type: actionTypes.CLEAR_DRAFT_LEAD,
})

export const setDraftUserId = (userId) => ({
  type: actionTypes.SET_DRAFT_USER_ID,
  payload: userId,
})

export const clearDraftUserId = () => ({
  type: actionTypes.CLEAR_DRAFT_USER_ID,
})

export const setDraftChannelId = (channelId) => ({
  type: actionTypes.SET_DRAFT_CHANNEL_ID,
  payload: channelId,
})

export const clearDraftChannelId = () => ({
  type: actionTypes.CLEAR_DRAFT_CHANNEL_ID,
})

export const setDraftChannelOfferId = (channelOfferId) => ({
  type: actionTypes.SET_DRAFT_CHANNEL_OFFER_ID,
  payload: channelOfferId,
})

export const clearDraftChannelOfferId = () => ({
  type: actionTypes.CLEAR_DRAFT_CHANNEL_OFFER_ID,
})

export const startDraft = (leadId, company, referenceSegment) => ({
  type: actionTypes.START_DRAFT,
  payload: { leadId, company, referenceSegment },
})

export const successDraft = () => ({
  type: actionTypes.SUCCESS_DRAFT,
})

export const errorDraft = () => ({
  type: actionTypes.ERROR_DRAFT,
})

export const clearDraft = () => ({
  type: actionTypes.CLEAR_DRAFT,
})

export const startFillFunnelWithDraft = (referenceSegment, company) => ({
  type: actionTypes.START_FILL_FUNNEL_WITH_DRAFT,
  payload: { referenceSegment, company },
})

export const successFillFunnelWithDraft = () => ({
  type: actionTypes.SUCCESS_FILL_FUNNEL_WITH_DRAFT,
})

export const errorFillFunnelWithDraft = () => ({
  type: actionTypes.ERROR_FILL_FUNNEL_WITH_DRAFT,
})

export const clearFillFunnelWithDraft = () => ({
  type: actionTypes.CLEAR_FILL_FUNNEL_WITH_DRAFT,
})

export const setDraftOnlineChannel = (draftData) => ({
  type: actionTypes.SET_DRAFT_ONLINE_CHANNEL,
  payload: draftData,
})

export const startDraftOnlineChannel = (draftId) => ({
  type: actionTypes.START_DRAFT_ONLINE_CHANNEL,
  payload: draftId,
})

export const successDraftOnlineChannel = () => ({
  type: actionTypes.SUCCESS_DRAFT_ONLINE_CHANNEL,
})

export const errorDraftOnlineChannel = () => ({
  type: actionTypes.ERROR_DRAFT_ONLINE_CHANNEL,
})

export const clearDraftOnlineChannel = () => ({
  type: actionTypes.CLEAR_DRAFT_ONLINE_CHANNEL,
})

export const setDuplicateOrderDraft = (duplicateOrder) => ({
  type: actionTypes.SET_DUPLICATE_ORDER_DRAFT,
  payload: duplicateOrder,
})

export const clearDuplicateOrderDraft = () => ({
  type: actionTypes.CLEAR_DUPLICATE_ORDER_DRAFT,
})

export const startDraftDuplicateOrder = (orderId, channelName, isScoringFail) => ({
  type: actionTypes.START_DRAFT_DUPLICATE_ORDER,
  payload: { orderId, channelName, isScoringFail },
})

export const successDraftDuplicateOrder = () => ({
  type: actionTypes.SUCCESS_DRAFT_DUPLICATE_ORDER,
})

export const errorDraftDuplicateOrder = () => ({
  type: actionTypes.ERROR_DRAFT_DUPLICATE_ORDER,
})

export const clearDraftDuplicateOrder = () => ({
  type: actionTypes.CLEAR_DRAFT_DUPLICATE_ORDER,
})

export const recoverDraftDuplicateOrder = (dpOrderId) => ({
  type: actionTypes.START_RECOVER_DRAFT_DUPLICATE_ORDER,
  payload: dpOrderId,
})

export const successRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.SUCCESS_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const errorRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.ERROR_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const clearRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.CLEAR_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const startSendDraftData = (idLead) => ({
  type: actionTypes.START_SEND_DRAFT_DATA,
  payload: idLead,
})

export const successSendDraftData = () => ({
  type: actionTypes.SUCCESS_SEND_DRAFT_DATA,
})

export const errorSendDraftData = () => ({
  type: actionTypes.ERROR_SEND_DRAFT_DATA,
})

export const clearSendDraftData = () => ({
  type: actionTypes.CLEAR_SEND_DRAFT_DATA,
})

export const clearDraftId = () => ({
  type: actionTypes.CLEAR_DRAFT_ID,
})

export const setDraftId = (draftId) => ({
  type: actionTypes.SET_DRAFT_ID,
  payload: draftId,
})

export const setDraftToken = (token) => ({
  type: actionTypes.SET_DRAFT_TOKEN,
  payload: token,
})

export const clearDraftToken = () => ({
  type: actionTypes.CLEAR_DRAFT_TOKEN,
})

export const fillInOwnerDataFromRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.FILL_IN_OWNER_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const fillInProductSelectionFromRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.FILL_IN_PRODUCT_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const fillInSupplyPointFromRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.FILL_IN_SUPPLY_POINT_FROM_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const fillInServiceSelectionFromRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.FILL_IN_SERVICE_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const fillInBillingDataFromRecoverDraftDuplicateOrder = () => ({
  type: actionTypes.FILL_IN_BILLING_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER,
})

export const clearDraftFull = () => ({
  type: actionTypes.CLEAR_DRAFT_FULL,
})
