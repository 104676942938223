import { VALID_CNAES } from './constants'

/**
 * validateEmail
 * @param {String} value - email value
 *
 * Function that validates email format
 */
export const validateEmail = (value) => {
  // eslint-disable-next-line no-control-regex
  const email =
    /^(([a-z\d!#$%&'*+\-/=?^_`{|}~]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
  return email.test(value.toLowerCase())
}

/**
 * validateMobilePhone
 * @param {String} value - Mobile phone value
 *
 * Function that validates if mobile phone has 9 digits, ignoring spaces
 */
export const validateMobilePhone = (value) => {
  const mobilePhone = /^(6|7)[0-9]{8}$/gi
  const validFormat = mobilePhone.test(value)
  return validFormat
}

/**
 * validateMobilePhone
 * @param {String} value - Mobile phone value
 *
 * Function that validates if mobile phone has 9 digits, ignoring spaces
 */
export const validateTelephone = (value) => {
  const telephone = /^(9)[0-9]{8}$/gi
  const validFormat = telephone.test(value)
  return validFormat
}

/**
 * validatePhoneNumber
 * @param {String} value - Mobile phone value
 *
 * Function that validates if mobile phone has 9 digits, ignoring spaces
 */
export const validatePhone = (value) => {
  const mobilePhone = /^(6|7|8|9)[0-9]{8}$/gi
  const validFormat = mobilePhone.test(value)
  return validFormat
}

/**
 * validateNifNie
 * @param {String} value
 *
 * Function that validates if NIF/NIE is valid
 */
export const validateNifNie = (value) => {
  const regFormat = /^((([X-Z|x-z])|([LM|lm])){1}((\d){7})([A-Z|a-z]{1}))$|^((\d{8})([A-Z|a-z]))$/
  const validFormat = regFormat.test(value)
  const firstDigit = value.substr(0, 1)
  const controlDigit = value.substr(-1, 1).toUpperCase()
  if (!validFormat) return false

  let numberCheck
  switch (firstDigit) {
    case 'X':
      numberCheck = value.substr(1, value.length - 1)
      break
    case 'Y':
      numberCheck = `1${value.substr(1, value.length - 1)}`
      break
    case 'Z':
      numberCheck = `2${value.substr(1, value.length - 1)}`
      break
    default:
      numberCheck = value.substr(0, value.length - 1)
  }

  const validDigit = 'TRWAGMYFPDXBNJZSQVHLCKET'.substr(parseInt(numberCheck, 10) % 23, 1) === controlDigit
  if (!validDigit) return false
  return true
}

export const validateNif = (value) => {
  const firstDigit = value.substr(0, 1)
  return !isNaN(firstDigit) && validateNifNie(value)
}

export const validateNie = (value) => {
  const firstDigit = value.substr(0, 1)
  return isNaN(firstDigit) && validateNifNie(value)
}

export const validatePassport = (value) => {
  return !/[^a-zA-Z0-9]/.test(value)
}

export const validateCif = (value) => {
  if (!value || value.length !== 9) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = value.substr(1, value.length - 2)
  const letter = value.substr(0, 1)
  const control = value.substr(value.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

export const validateNames = (value) => /(?=\S)(?=^[A-Za-zÀ-ÿ-ª-º ]*$)/.test(value)

export const validateBusinessNames = (value) => /(?=\S)/.test(value)

/**
 * Validates spanish postal code
 * @param {String} postalCode
 */
export const validatePostalCode = (postalCode) => /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/.test(postalCode)

/**
 * Validates birth date
 * @param {String} birthDate - Date in ISO_8601 format YYYY-MM-DD
 * @param {Number} minAge
 * @param {Number} maxAge
 */
export const validateBirthDate = (birthDate, minAge = 18, maxAge = 0) => {
  const dateSplit = birthDate.split('-')
  const birthYear = Number(dateSplit[0])
  const birthMonth = Number(dateSplit[1])
  const birthDay = Number(dateSplit[2])
  const today = new Date()
  const minDate = new Date(birthYear + minAge, birthMonth - 1, birthDay)
  const maxDate = new Date(birthYear + maxAge, birthMonth - 1, birthDay)

  return maxAge ? isBetweenDates(minDate, maxDate, today) : minDate <= today
}

export const validateActivationDate = (activationDate) => {
  const minDate = new Date()
  const selectedDate = new Date(activationDate)
  const diffDate = Math.abs(selectedDate - minDate)
  const diffDays = Math.ceil(diffDate / (1000 * 60 * 60 * 24))

  return selectedDate.toISOString().substring(0, 10) >= minDate.toISOString().substring(0, 10) && diffDays >= 5 && diffDays <= 30
}

const isBetweenDates = (dateStart, dateEnd, date) => date > dateStart && date < dateEnd

export const validateAlphanumeric = (text) => /^[a-z0-9]+$/i.test(text)

/**
 * Validate CNAE
 * @param {Number} cnae
 */
export const validateCnae = (cnae) => /^[0-9]{4}$/.test(cnae) && Object.keys(VALID_CNAES).includes(cnae)

/**
 * Validate number
 * @param {String} number
 */
export const validateNumber = (number) => /^([1-9][0-9]{0,3}|S\/N)$/.test(number)

/**
 * Validate portal
 * @param {String} portal
 */
export const validatePortal = (portal) => /^([1-9][0-9]{0,1}|[A-Z]{1}|BIS)$/.test(portal)

/**
 * Validate stair
 * @param {String} stair
 */
export const validateStair = (stair) => /^([1-9][0-9]{0,1}|[A-Z]{1}|INT|EXT|IZQ|DER)$/.test(stair)

/**
 * Validate floor
 * @param {String} floor
 */
export const validateFloor = (floor) => /^([1-9][0-9]{0,1}|AL|AT|A[1-9]{1}|BJ|BU|EN|ET|PR|PT|SA|SS|ST|S[1-9]{1}|TR)$/.test(floor)

/**
 * Validate stair
 * @param {String} door
 */
export const validateDoor = (door) => /^([1-9][0-9]{0,1}|[A-Z]{1}|IZQ|DER)$/.test(door)

/**
 * Validate annual conssumption
 * @param {Number} annualConsumption
 */
export const validateAnnualConsumption = (annualConsumption, minAnnualConsumption, maxAnnualConsumption) =>
  annualConsumption >= minAnnualConsumption && annualConsumption <= maxAnnualConsumption

/**
 * Validate installer code
 * @param {Number} installerCode
 */
export const validateInstallerCode = (installerCode) => /^[a-zA-Z0-9]{1,6}$/.test(installerCode)

/**
 * Validate iri certification date
 * @param {Date} iriCertificateDate
 */
export const validateIriCertificateDate = (iriCertificateDate) => {
  if (iriCertificateDate.split('-')[0].length > 4) {
    return false
  }
  const currentDate = new Date()
  const selectedDate = new Date(iriCertificateDate)
  return selectedDate.toISOString().substring(0, 10) < currentDate.toISOString().substring(0, 10)
}

/**
 * Validate referral code
 */
export const validateReferralCode = (referralCode) => /^RAF[\w]{4}$/.test(referralCode)
