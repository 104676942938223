import { SERVICE } from './constants'

export const SUBMIT_OWNER_STEP = `${SERVICE}/SUBMIT_OWNER_STEP`
export const SUBMIT_OWNER_STEP_DRAFT = `${SERVICE}/SUBMIT_OWNER_STEP_DRAFT`
export const SUBMIT_OWNER_STEP_DUPLICATE = `${SERVICE}/SUBMIT_OWNER_STEP_DUPLICATE`
export const SUBMIT_BILLING_STEP = `${SERVICE}/SUBMIT_BILLING_STEP`
export const SET_DATA = `${SERVICE}/SET_DATA`
export const CLEAR_DATA = `${SERVICE}/CLEAR_DATA`
export const SET_BILLING_ACCOUNT = `${SERVICE}/SET_BILLING_ACCOUNT`
export const CLEAR_BILLING_ACCOUNT = `${SERVICE}/CLEAR_BILLING_ACCOUNT`
export const SET_BILLING_ADDRESS = `${SERVICE}/SET_BILLING_ADDRESS`
export const CLEAR_BILLING_ADDRESS = `${SERVICE}/CLEAR_BILLING_ADDRESS`
export const SET_ONLINE_INVOICE = `${SERVICE}/SET_ONLINE_INVOICE`
export const CLEAR_ONLINE_INVOICE = `${SERVICE}/CLEAR_ONLINE_INVOICE`
export const SET_SUPPLY_POINT = `${SERVICE}/SET_SUPPLY_POINT`
export const CLEAR_SUPPLY_POINTS = `${SERVICE}/CLEAR_SUPPLY_POINTS`
export const SET_REFERRAL_CODE = `${SERVICE}/SET_REFERRAL_CODE`
export const CLEAR_REFERRAL_CODE = `${SERVICE}/CLEAR_REFERRAL_CODE`
export const SET_PRODUCT_REFERRAL_CODE = `${SERVICE}/SET_PRODUCT_REFERRAL_CODE`
export const CLEAR_PRODUCT_REFERRAL_CODE = `${SERVICE}/CLEAR_PRODUCT_REFERRAL_CODE`
export const SET_BUYAPOWA_CODE = `${SERVICE}/SET_BUYAPOWA_CODE`
export const CLEAR_BUYAPOWA_CODE = `${SERVICE}/CLEAR_BUYAPOWA_CODE`
export const USER_DATA_REQUEST_START = `${SERVICE}/USER_DATA_REQUEST_START`
export const USER_DATA_REQUEST_SUCCESS = `${SERVICE}/USER_DATA_REQUEST_SUCCESS`
export const USER_DATA_REQUEST_ERROR = `${SERVICE}/USER_DATA_REQUEST_ERROR`
export const USER_DATA_REQUEST_CLEAR = `${SERVICE}/USER_DATA_REQUEST_CLEAR`
export const SET_USER_DATA = `${SERVICE}/SET_USER_DATA`
export const RESET_USER_DATA = `${SERVICE}/RESET_USER_DATA`
export const SET_SELECTED_TENURE = `${SERVICE}/SET_SELECTED_TENURE`
export const SET_SELECTED_COMPANY = `${SERVICE}/SET_SELECTED_COMPANY`
export const CLEAR_SELECTED_TENURE = `${SERVICE}/CLEAR_SELECTED_TENURE`
export const CLEAR_SELECTED_COMPANY = `${SERVICE}/CLEAR_SELECTED_COMPANY`
export const SET_SUPPLY_POINT_BY_ID_CAL = `${SERVICE}/SET_SUPPLY_POINT_BY_ID_CAL`
export const SET_NORMALIZED_ADDRESS = `${SERVICE}/SET_NORMALIZED_ADDRESS`
export const CLEAR_NORMALIZED_ADDRESS = `${SERVICE}/CLEAR_NORMALIZED_ADDRESS`
export const SET_SUPPLY_ADDRESS = `${SERVICE}/SET_SUPPLY_ADDRESS`
export const CLEAR_SUPPLY_ADDRESS = `${SERVICE}/CLEAR_SUPPLY_ADDRESS`
export const SET_CHANGE_BILLING_ADDRESS = `${SERVICE}/SET_CHANGE_BILLING_ADDRESS`
export const CLEAR_CHANGE_BILLING_ADDRESS = `${SERVICE}/CLEAR_CHANGE_BILLING_ADDRESS`
export const START_TENURE_SELECTION = `${SERVICE}/START_TENURE_SELECTION`
export const START_COMPANY_SELECTION = `${SERVICE}/START_COMPANY_SELECTION`
export const CLEAR_COMPANY_SELECTION = `${SERVICE}/CLEAR_COMPANY_SELECTION`
export const SET_TENURE_SELECTION_ERROR = `${SERVICE}/SET_TENURE_SELECTION_ERROR`
export const CLEAR_TENURE_SELECTION_ERROR = `${SERVICE}/CLEAR_TENURE_SELECTION_ERROR`
export const SET_EX_CLIENT = `${SERVICE}/SET_EX_CLIENT`
export const RESET_EX_CLIENT = `${SERVICE}/RESET_EX_CLIENT`
export const SET_IS_NEW_SUPPLY_POINT = `${SERVICE}/SET_IS_NEW_SUPPLY_POINT`
export const CLEAR_IS_NEW_SUPPLY_POINT = `${SERVICE}/CLEAR_IS_NEW_SUPPLY_POINT`
export const SET_ON_BEHALF_CONTACT_ID = `${SERVICE}/SET_ON_BEHALF_CONTACT_ID`
export const CLEAR_ON_BEHALF_CONTACT_ID = `${SERVICE}/CLEAR_ON_BEHALF_CONTACT_ID`
export const SET_BIRTHDAY_DATE = `${SERVICE}/SET_BIRTHDAY_DATE`
export const CLEAR_BIRTHDAY_DATE = `${SERVICE}/CLEAR_BIRTHDAY_DATE`
export const SET_IS_MULTI_POINT = `${SERVICE}/SET_IS_MULTI_POINT`
export const CLEAR_IS_MULTI_POINT = `${SERVICE}/CLEAR_IS_MULTI_POINT`
export const SET_AUTOSAVE = `${SERVICE}/SET_AUTOSAVE`
export const SET_COMPANY = `${SERVICE}/SET_COMPANY`
export const CLEAR_COMPANY = `${SERVICE}/CLEAR_COMPANY`
export const SET_DIFFERENT_BILLING_ADDRESS = `${SERVICE}/SET_DIFFERENT_BILLING_ADDRESS`
export const CLEAR_DIFFERENT_BILLING_ADDRESS = `${SERVICE}/CLEAR_DIFFERENT_BILLING_ADDRESS`
export const SET_CUPS_OK = `${SERVICE}/SET_CUPS_OK`
export const SET_SELFCONSUMPTION_TYPE = `${SERVICE}/SET_SELFCONSUMPTION_TYPE`
export const CLEAR_SELFCONSUMPTION_TYPE = `${SERVICE}/CLEAR_SELFCONSUMPTION_TYPE`
export const SET_INITIAL_SELFCONSUMPTION_TYPE = `${SERVICE}/SET_INITIAL_SELFCONSUMPTION_TYPE`
export const CLEAR_INITIAL_SELFCONSUMPTION_TYPE = `${SERVICE}/CLEAR_INITIAL_SELFCONSUMPTION_TYPE`
export const SET_SIGNUP_FOR_SELFCONSUMPTION = `${SERVICE}/SET_SIGNUP_FOR_SELFCONSUMPTION`
export const CLEAR_SIGNUP_FOR_SELFCONSUMPTION = `${SERVICE}/CLEAR_SIGNUP_FOR_SELFCONSUMPTION`
export const SET_COLLECTIVE = `${SERVICE}/SET_COLLECTIVE`
export const CLEAR_COLLECTIVE = `${SERVICE}/CLEAR_COLLECTIVE`
export const SET_SUBSECTION = `${SERVICE}/SET_SUBSECTION`
export const CLEAR_SUBSECTION = `${SERVICE}/CLEAR_SUBSECTION`
export const SET_INSTALLED_POWER = `${SERVICE}/SET_INSTALLED_POWER`
export const CLEAR_INSTALLED_POWER = `${SERVICE}/CLEAR_INSTALLED_POWER`
export const SET_CUPS_TYPE = `${SERVICE}/SET_CUPS_TYPE`
export const CLEAR_CUPS_TYPE = `${SERVICE}/CLEAR_CUPS_TYPE`
export const SET_IS_CIE_REQUIRED = `${SERVICE}/SET_IS_CIE_REQUIRED`
export const SET_RECORD_UFD = `${SERVICE}/SET_RECORD_UFD`
export const SET_PORTABILITY_NEWCO_CHECK = `${SERVICE}/SET_PORTABILITY_NEWCO_CHECK`
export const SET_ADDRESS_DUAL_ALERT = `${SERVICE}/SET_ADDRESS_DUAL_ALERT`
export const SET_CHANGE_SUPPLY_OWNERSHIP = `${SERVICE}/SET_IS_CHANGE_SUPPLY_OWNERSHIP`
export const CLEAR_CHANGE_SUPPLY_OWNERSHIP = `${SERVICE}/CLEAR_IS_CHANGE_SUPPLY_OWNERSHIP`
export const SET_CHANGE_OWNER_CUPS = `${SERVICE}/SET_CHANGE_OWNER_CUPS`
export const CLEAR_CHANGE_OWNER_CUPS = `${SERVICE}/CLEAR_CHANGE_OWNER_CUPS`
