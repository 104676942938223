import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  all: redukers.value(
    {
      set: actionTypes.SET_ALL_TOPPINGS,
      reset: actionTypes.CLEAR_TOPPINGS,
    },
    null
  ),
  selected: redukers.value(
    {
      set: actionTypes.SET_TOPPINGS,
      reset: actionTypes.CLEAR_ALL_SELECTED_TOPPINGS,
    },
    null
  ),
  duplicatedServiceType: redukers.value(
    {
      set: actionTypes.SET_DUPLICATED_SERVICE_TYPE,
      reset: actionTypes.CLEAR_DUPLICATED_SERVICE_TYPE,
    },
    false
  ),
  selection: redukers.value(
    {
      set: actionTypes.SET_SERVICE_SELECTION,
      reset: actionTypes.CLEAR_SERVICE_SELECTION,
    },
    {}
  ),
  virtualBattery: redukers.value(
    {
      set: actionTypes.SET_VIRTUAL_BATTERY,
      reset: actionTypes.CLEAR_VIRTUAL_BATTERY,
    },
    null // <-- this is the default value
  ),
})
