import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'
import { cpProvinces } from '@zatopek/core/src/utils/enums'

import { FormControl, FormHelperText, Grid, InputLabel, TextField as MuiTextField, Select } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const AddressForm = ({
  onChange,
  onPostalCodeChange,
  onStreetTypeChange,
  setFieldValue,
  cities,
  cityInputValue,
  setCityInputValue,
  streetSuggestions,
  streetTypes,
  values,
  errors,
  touched,
  notFoundAddress,
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      {/* --- POSTAL CODE --- */}
      <Grid item xs={12} sm={4}>
        <Field
          component={MuiTextField}
          required
          fullWidth
          id="postalCode"
          label={t('form.fields.postalCode')}
          name="postalCode"
          value={values.postalCode}
          onChange={(e) => {
            onChange(e)
            onPostalCodeChange(e, setFieldValue)
          }}
          autoComplete="nope"
          data-cy="postalCode"
        />
        <ErrorMessage component={FormHelperText} name="postalCode" error />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Grid container spacing={3}>
          {/* --- PROVINCE --- */}
          <Grid item xs={12} sm={6}>
            <FormControl disabled fullWidth required>
              <InputLabel htmlFor="province">{t('form.fields.province')}</InputLabel>
              <Field
                component={Select}
                native
                id="province"
                name="province"
                value={values.province}
                onChange={onChange('province')}
                data-cy="province"
              >
                <option aria-label="None" value="" />
                {Object.entries(cpProvinces).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Field>
            </FormControl>
            <ErrorMessage component={FormHelperText} name="province" error />
          </Grid>

          {/* --- CITY --- */}
          <Grid item xs={12} sm={6}>
            <Field
              component={Autocomplete}
              required
              id="city"
              fullWidth
              name="city"
              onChange={(_, newValue) => {
                const value = newValue ? newValue.id : ''
                setFieldValue('city', value)
              }}
              options={cities}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  inputProps={{ ...params.inputProps, autoComplete: 'nope' }}
                  label={t('form.fields.city')}
                />
              )}
              inputValue={cityInputValue}
              onInputChange={(_, newInputValue) => {
                setCityInputValue(newInputValue)
              }}
              disabled={!(cities && cities.length)}
              data-cy="city"
            />
            <ErrorMessage component={FormHelperText} name="city" error />
          </Grid>
        </Grid>
      </Grid>

      {/* --- STREET TYPE --- */}
      <Grid item xs={12} sm={3}>
        <FormControl disabled={!values.city || !streetTypes.length} fullWidth required>
          <InputLabel htmlFor="streetType">{t('form.fields.streetType')}</InputLabel>
          <Field
            component={Select}
            native
            id="streetType"
            name="streetType"
            value={values.streetType}
            onChange={(e) => {
              onChange(e)
              onStreetTypeChange(e.target.value)
            }}
            data-cy="streetType"
          >
            <option aria-label="None" value="" />
            {Object.entries(streetTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </Field>
        </FormControl>
        <ErrorMessage component={FormHelperText} name="streetType" error />
      </Grid>

      {/* --- STREET --- */}
      <Grid item xs={12} sm={6}>
        {!notFoundAddress ? (
          <Field
            component={Autocomplete}
            key={values.streetType}
            required
            fullWidth
            id="street"
            name="street"
            onChange={(_, newValue) => {
              const value = newValue ? newValue.externalId : ''
              setFieldValue('street', value)
            }}
            options={streetSuggestions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <MuiTextField
                value={values.street}
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'nope' }}
                label={t('form.fields.street')}
              />
            )}
            disabled={!values.streetType}
            data-cy="street"
          />
        ) : (
          <Field
            component={MuiTextField}
            required
            fullWidth
            id="street"
            label={t('form.fields.street')}
            name="street"
            value={values.street}
            onChange={onChange('street')}
            data-cy="street"
          />
        )}
        <ErrorMessage component={FormHelperText} name="street" error />
      </Grid>

      {/* --- NUMBER --- */}
      <Grid item xs={12} sm={3}>
        <Field
          component={MuiTextField}
          required
          fullWidth
          id="number"
          label={t('form.fields.number')}
          name="number"
          value={values.number}
          onChange={onChange('number')}
          disabled={!values.street}
          data-cy="number"
        />
        <ErrorMessage component={FormHelperText} name="number" error />
      </Grid>

      {/* --- PORTAL --- */}
      <Grid item xs={12} sm={4}>
        <Field
          component={MuiTextField}
          fullWidth
          id="portal"
          label={t('form.fields.portal')}
          name="portal"
          value={values.portal}
          onChange={onChange('portal')}
          error={touched.portal && errors.portal}
          disabled={!values.street}
          data-cy="portal"
        />
      </Grid>

      {/* --- STAIR --- */}
      <Grid item xs={12} sm={3}>
        <Field
          component={MuiTextField}
          fullWidth
          id="stair"
          label={t('form.fields.stair')}
          name="stair"
          value={values.stair}
          onChange={onChange('stair')}
          error={touched.stair && errors.stair}
          disabled={!values.street}
          data-cy="stair"
        />
      </Grid>

      {/* --- FLOOR --- */}
      <Grid item xs={12} sm={2}>
        <Field
          component={MuiTextField}
          fullWidth
          id="floor"
          label={t('form.fields.floor')}
          name="floor"
          value={values.floor}
          onChange={onChange('floor')}
          error={touched.floor && errors.floor}
          disabled={!values.street}
          data-cy="floor"
        />
      </Grid>

      {/* --- DOOR --- */}
      <Grid item xs={12} sm={3}>
        <Field
          component={MuiTextField}
          fullWidth
          id="door"
          label={t('form.fields.door')}
          name="door"
          value={values.door}
          onChange={onChange('door')}
          error={touched.door && errors.door}
          disabled={!values.street}
          data-cy="door"
        />
      </Grid>
    </Grid>
  )
}

export default AddressForm
