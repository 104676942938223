import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'
import useStyles from './styles'

const CupsInfo = ({ handleClose, data: { url } }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <DialogTitle disableTypography id="customized-dialog-title">
        <Typography variant="h4">{t('modal-info-cups-title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: t('modal-info-cups-description') }} />
        <img className={classes.image} src={url} alt="Descripción Cups" />
      </DialogContent>
    </>
  )
}

export default CupsInfo
