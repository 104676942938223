import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { startTenureSelection } from 'services/customer/actions'

import TenureSelector from 'containers/TenureSelector'
import { getUserData, getCompany } from 'services/customer/selectors'
import { getSelectedChannel, getIsOnlineChannel } from 'services/operator/selectors'
import { getIsFidelizationFetching, getIsFidelizationAllowed } from 'services/fidelization/selectors'
import { fetchFidelization } from 'services/fidelization/actions'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Loader from '@zatopek/core/src/ui/components/loader'
import Typography from 'ui/components/typography'

const TenureSeletorModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userData = useSelector(getUserData)
  const selectedChannel = useSelector(getSelectedChannel)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const isFidelizationFetching = useSelector(getIsFidelizationFetching)
  const isFidelizationAllowed = useSelector(getIsFidelizationAllowed)
  const company = useSelector(getCompany)

  useEffect(() => {
    if (company && isOnlineChannel && !isFidelizationFetching) {
      dispatch(fetchFidelization())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id])

  const handleClickContinue = (selectedTenure) => {
    dispatch(startTenureSelection(selectedTenure))
  }

  const FidelizationLoader = () => (
    <div style={{ height: '150px' }}>
      <Loader open opacity={0.5}>
        <CircularProgress thickness={4} />
      </Loader>
    </div>
  )

  return (
    <Box padding={5}>
      <Box>
        <Typography variant="h3" color="primary">
          {t('tenure.modal.title')}
        </Typography>
        <Typography>{t('tenure.modal.subtitle')}</Typography>
      </Box>
      {isFidelizationFetching ? (
        <FidelizationLoader />
      ) : (
        <TenureSelector
          onContinue={handleClickContinue}
          showTitle={false}
          userData={userData}
          selectedChannel={selectedChannel}
          isFidelizationAllowed={isFidelizationAllowed}
        />
      )}
    </Box>
  )
}

export default TenureSeletorModal
