import { SERVICE } from './constants'

export const SET_LEAD_ID = `${SERVICE}/SET_ID`
export const CLEAR_LEAD_ID = `${SERVICE}/CLEAR_ID`
export const SET_LEAD_ERROR_MESSAGE = `${SERVICE}/SET_ERROR_MESSAGE`
export const CLEAR_LEAD_ERROR_MESSAGE = `${SERVICE}/CLEAR_ERROR_MESSAGE`
export const START_LEAD = `${SERVICE}/START`
export const SUCCESS_LEAD = `${SERVICE}/SUCCESS`
export const ERROR_LEAD = `${SERVICE}/ERROR`
export const FETCH_LEAD = `${SERVICE}/FETCH`
export const CLEAR_LEAD = `${SERVICE}/CLEAR`
export const SET_BILLING_ADDRESS_ID = `${SERVICE}/SET_BILLING_ADDRESS_ID`
export const CLEAR_BILLING_ADDRESS_ID = `${SERVICE}/CLEAR_BILLING_ADDRESS_ID`
export const SET_SUPPLY_ADDRESSES_IDS = `${SERVICE}/SET_SUPPLY_ADDRESSES_IDS`
export const CLEAR_SUPPLY_ADDRESSES_IDS = `${SERVICE}/CLEAR_SUPPLY_ADDRESSES_IDS`
