import { get, getFile, post, put } from '../client'
import { pick } from '../../utils/commons'
import {
  NOTIFICATIONS_ALLOWED_FILTERS,
  NOTIFICATIONS_SPECIFIC_ALLOWED_FILTERS,
  ORDER_ALLOWED_FILTERS,
  ORDER_LINES_ALLOWED_FILTERS,
  ORDER_SUMMARIES_ALLOWED_FILTERS,
  SPECIFIC_ALLOWED_FILTERS,
} from 'utils/constants'

const getSummary = async (type, { states = [], channel = [], ...filters }) => {
  const searchParams = new URLSearchParams([
    ...Object.entries(pick(filters, ORDER_SUMMARIES_ALLOWED_FILTERS)),
    ...states.map((state) => ['stateGroup', state]),
    ...channel.map((channelId) => ['channelIds[]', channelId]),
    ['type', type],
  ]).toString()
  return await get(`order_summaries?${searchParams}`, true)
}

const getOrderSummary = async () => await get('order_statistics', true)

const getProductSummary = async () => await get('order_line_statistics', true)

const getOrders = async ({ stateGroup = '', channel = [], ...filters } = {}) => {
  const stateGroupParam = stateGroup && `&stateGroup=${stateGroup}`
  const allowedFiltersKeys = [...SPECIFIC_ALLOWED_FILTERS, ...ORDER_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  const searchParams = new URLSearchParams([...allowedFilters, ...channel.map(({ id }) => ['channelIds[]', id])]).toString()
  return await get(`order_lists?${searchParams}${stateGroupParam}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrdersFail = async ({ stateGroup = '', channel = [], agency = [], contractType = [], ...filters } = {}) => {
  const stateGroupParam = '&stateGroup=failed'
  const allowedFiltersKeys = [...SPECIFIC_ALLOWED_FILTERS, ...ORDER_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  const searchParams = new URLSearchParams([
    ...allowedFilters,
    ...channel.map(({ id }) => ['channelIds[]', id]),
    ...agency.map(({ id }) => ['agencyIds[]', id]),
    ...contractType.map(({ id }) => ['contractType[]', id]),
  ]).toString()
  return await get(`order_lists_failed?${searchParams}${stateGroupParam}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrdersIds = async ({ orderIds, states = [], channel = [], ...filters } = {}) => {
  const searchParams = new URLSearchParams([
    ...Object.entries(pick(filters, ORDER_ALLOWED_FILTERS)),
    ...states.map((state) => ['states[]', state]),
    ...channel.map(({ id }) => ['channelIds[]', id]),
    ...orderIds.map((orderId) => ['orderIds[]', orderId]),
  ]).toString()
  return await get(`order_exports/export?${searchParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrdersFailedIds = async ({
  orderIds,
  pageItem,
  states = [],
  channel = [],
  agency = [],
  contractType = [],
  ...filters
} = {}) => {
  const stateGroupParam = `&stateGroup=failed&nbItems=1000&page=${pageItem}`
  const searchParams = new URLSearchParams([
    ...Object.entries(pick(filters, ORDER_ALLOWED_FILTERS)),
    ...states.map((state) => ['states[]', state]),
    ...channel.map(({ id }) => ['channelIds[]', id]),
    ...orderIds.map((orderId) => ['orderIds[]', orderId]),
    ...agency.map(({ id }) => ['agencyIds[]', id]),
    ...contractType.map(({ id }) => ['contractType[]', id]),
  ]).toString()
  return await get(`order_exports_failed/export?${searchParams}${stateGroupParam}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrderLinesLDJson = async ({ stateGroup = '', ...filters } = {}) => {
  const stateGroupParam = stateGroup && `&stateGroup=${stateGroup}`
  const allowedFiltersKeys = [...SPECIFIC_ALLOWED_FILTERS, ...ORDER_LINES_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  const searchParams = new URLSearchParams([...allowedFilters]).toString()
  return await get(`order_line_lists?${searchParams}${stateGroupParam}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrderLineIds = async ({ products, states = [], ...filters } = {}) => {
  const searchParams = new URLSearchParams([
    ...Object.entries(pick(filters, ORDER_LINES_ALLOWED_FILTERS)),
    ...states.map((state) => ['states[]', state]),
    ...products.map((product) => ['products[]', product]),
  ]).toString()
  return await get(`order_line_exports?${searchParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getOrderLines = async (params) => {
  const queryParams =
    Object.entries(params)
      ?.filter((prop) => {
        const [value] = prop
        return value !== null && value !== 'undefined'
      })
      .map((prop) => prop.join('='))
      .join('&') || ''
  return await get(`order_line_lists?${queryParams}`, true)
}

const getOrderLinesFailed = async (params) => {
  const queryParams =
    Object.entries(params)
      ?.filter((prop) => {
        const [value] = prop
        return value !== null && value !== 'undefined'
      })
      .map((prop) => prop.join('='))
      .join('&') || ''
  return await get(`order_line_lists_faileds?${queryParams}`, true)
}

const getOrderDetailById = async (orderId) => {
  return await get(`orders/${orderId}`)
}

const getOrderStateLogByOrderId = async (orderId) => {
  return await get(`orders/${orderId}/order_state_logs`)
}

const getOrderFiltersConfig = async () => {
  return await get(`order_lists/setup`, true)
}

const getProductFiltersConfig = async () => {
  return await get(`order_line_lists/setup`, true)
}

const getOrderAttachments = (orderId) => getFile(`orders/${orderId}/files`, true)

const cancelOrder = ({ leadId, cancelReason, cancelDetails }) =>
  put(`leads/${leadId}/cancel`, {
    cancelReason,
    cancelDetails,
  })

const verifyOrder = (orderId) => put(`orders/${orderId}/validate`, {})

const relaunchOrder = ({ orderId }) => put(`orders/${orderId}/relaunch`, {})

const setOrderStateFailedWithoutRecovery = ({ orderId }) => put(`orders/${orderId}/failed_without_recovery`, {})

const getNotifications = ({ stateGroup = '', ...filters } = {}) => {
  const stateGroupParam = stateGroup && `&stateGroup=${stateGroup}`
  const allowedFiltersKeys = [...NOTIFICATIONS_SPECIFIC_ALLOWED_FILTERS, ...NOTIFICATIONS_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  const searchParams = new URLSearchParams([...allowedFilters]).toString()
  return get(`alerts?${searchParams}${stateGroupParam}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const manageNotification = (notificationId, managed) => put(`alerts/${notificationId}`, managed, true)

const manageNotifications = (notificationIds) => post(`alerts/bulk_update`, notificationIds, true)

const deleteNotifications = (notificationsIds) => post('alerts/bulk_delete', notificationsIds, true)

const getTotalNotificationsUnmanaged = () => get('alerts/tooltip')

export {
  getSummary,
  getOrders,
  getOrdersIds,
  getOrdersFailedIds,
  getOrderLinesLDJson,
  getOrderLineIds,
  getOrderLines,
  getOrderLinesFailed,
  getProductSummary,
  getOrderSummary,
  getProductFiltersConfig,
  getOrderFiltersConfig,
  getOrderAttachments,
  cancelOrder,
  verifyOrder,
  getOrderDetailById,
  getOrderStateLogByOrderId,
  relaunchOrder,
  setOrderStateFailedWithoutRecovery,
  getNotifications,
  manageNotification,
  manageNotifications,
  deleteNotifications,
  getTotalNotificationsUnmanaged,
  getOrdersFail,
}
