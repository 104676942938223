export const SERVICE = 'SELF_CONSUMPTION'

export const SELFCONSUMPTION_DISABLED = true

export const WITOUT_SELFCONSUMPTION = '00'
export const WITOUT_SELFCONSUMPTION_ID = '0089646a-458c-11ed-b878-0242ac120002'
export const WITH_SURPLUS = '12'
export const WITHOUT_SURPLUS = '11'

export const WITH_SURPLUS_WITH_COMPENSATION = '21'
export const WITHOUT_SURPLUS_WITHOUT_COMPENSATION = '10'
export const WITHOUT_SURPLUS_WITH_COMPENSATION = '11'

export const CUPS_TYPE_01_CONSUM = { code: '01', description: 'Consumo' }
export const CUPS_TYPE_02_AUX = { code: '02', description: 'Servicios auxiliares' }

export const COLLECTIVE = 'Y'
export const INDIVIDUAL = 'N'

export const DEFAULT_TYPE_SUBSECTION = {
  [WITH_SURPLUS]: WITH_SURPLUS_WITH_COMPENSATION,
  [WITHOUT_SURPLUS]: WITHOUT_SURPLUS_WITH_COMPENSATION,
}
