import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setProductsValidation, clearSelectedProduct } from 'services/products/actions'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'
import { setActiveStepById } from 'services/stepper/actions'

import Typography from 'ui/components/typography'
import useStyles from './styles'
import { Box, Button, DialogContent, DialogActions, Grid } from '@material-ui/core'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

const NoService = ({ handleClose, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleAccept = () => {
    dispatch(setProductsValidation(true))
    dispatch(setActiveStepById(STEPS.topping))
    handleClose()
  }

  const handleCallMeButton = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.callMeBackButton, 'servicio no disponible'))
    dispatch(clearSelectedProduct())
    dispatch(showModal({ modalType: MODAL_TYPES.callMeBack }))
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('serviceNotAvailable', {
              count: data.serviceNames.length,
              service: data.serviceNames.map((name) => t(`entity-${name}-title`)).join(', '),
            })}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant="basic" color="textPrimary">
              <strong>{t('supply.address')}</strong>
            </Typography>
            <Typography variant="basic" color="textPrimary">
              {data.addressLines.map((addressLine, index) => (
                <span key={index}>
                  {addressLine}
                  <br />
                </span>
              ))}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3} mb={1}>
          <Typography variant="basic">
            <strong>{t('doubts')}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="primary.light3">
          <Typography variant="basic" color="textPrimary">
            <strong>{t('common.contactUs')} </strong>
            {t('common.weHelpYou')}
          </Typography>
          <Button color="secondary" variant="outlined" onClick={handleCallMeButton}>
            {t('common.callMe')}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleAccept}>
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default NoService
