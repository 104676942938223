import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Typography from 'ui/components/typography'
import { Box, DialogContent } from '@material-ui/core'

import MemberGetMemberForm from 'ui/forms/memberGetMember'
import { validateReferralCode } from 'utils/validators'

import useStyles from './styles'
import { setReferralCode } from 'services/customer/actions'
import { getIsLeadError, getIsLeadFetching } from 'services/lead/selectors'
import { getCustomerReferralCode } from 'services/customer/selectors'

const MemberGetMemberBenefits = ({ handleClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const isLeadError = useSelector(getIsLeadError)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const referralCode = useSelector(getCustomerReferralCode)

  const validateForm = (values) => {
    const errors = {}

    if (values.referralCode && !validateReferralCode(values.referralCode)) {
      errors.referralCode = t('mgmReferralCodeIncorrectFormat')
    }

    return errors
  }

  const handleFormSubmit = ({ referralCode }) => {
    dispatch(setReferralCode(referralCode))
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3} mt={3}>
          <Typography variant="h3" color="primary">
            {t('mgmRecomendationBenefits')}
          </Typography>
        </Box>
        <Box mb={3}>
          <span dangerouslySetInnerHTML={{ __html: t('mgmCodePresent') }}></span>
        </Box>
        <Box mb={3}>
          <Formik
            initialValues={{
              referralCode,
            }}
            validate={validateForm}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ handleChange, handleBlur, values, errors, touched }) => (
              <Form>
                <MemberGetMemberForm
                  handleClose={handleClose}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  errors={errors}
                  isLeadFetching={isLeadFetching}
                  isLeadError={isLeadError}
                  resumeLocution={true}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </>
  )
}

export default MemberGetMemberBenefits
