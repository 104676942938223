import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { setIdCampaign, setIsSendLeadRequesting, setLead, setPhone } from './actions'
import * as callMeBackActionTypes from 'services/callMeBack/action-types'
import * as api from './api'
import { Connect } from './business'
import { getRouterQueries } from 'services/analytics/selectors'

const idCampaignDefaultValues = {
  residential: '907008005',
  pymes: '907008006',
}

function* callMeBackInitSaga() {
  const queries = yield select(getRouterQueries)
  // TODO: Check how we are going to implement detection of pymes
  const clientType = queries?.client_type ? queries.client_type : 'residential'
  const idCampaignValue = queries?.vn ? queries.vn : idCampaignDefaultValues[clientType]

  yield api.initCallMeBack()

  yield put(setLead(Connect.lead))
  yield put(setIdCampaign(idCampaignValue))

  const phoneNumber = yield call(api.getCallMeBackPhone, idCampaignValue)
  yield put(setPhone(phoneNumber))
}

function* sendCallMeBackLead({ payload: lead }) {
  yield put(setIsSendLeadRequesting(true))
  yield call(api.sendLead, lead)
  yield put(setIsSendLeadRequesting(false))
}

export default function* rootSaga() {
  yield all([
    takeLatest([callMeBackActionTypes.INIT], callMeBackInitSaga),
    takeLatest([callMeBackActionTypes.SEND_CALL_ME_BACK_LEAD], sendCallMeBackLead),
  ])
}
