import { request } from 'redukers'
import { CUPS_ACTIVE_ASSOCIATED_WITH_CLIENT } from 'utils/constants'

export const getFidelization = (state) => state.fidelization.fidelization
export const getIsFidelizationAllowed = (state) => state.fidelization.fidelization?.allowed

export const getIsFidelizationFetching = (state) => request.getIsRequesting(state.fidelization.loadControlRequest)
export const getIsFidelizationError = (state) => request.getIsError(state.fidelization.loadControlRequest)

export const getIsPortability = (state) => {
  const tenure = state.customer.selectedTenure
  const company = state.customer.company || state.products.selected[0]?.company
  const isPortability = Boolean(company?.code && tenure?.company && company.code.toLowerCase() !== tenure.company.toLowerCase())

  return isPortability
}

export const getIsDisallowedNewAddressProductChange = (state) =>
  state.fidelization.newAddressProductChange === CUPS_ACTIVE_ASSOCIATED_WITH_CLIENT &&
  !getIsPortability(state) &&
  !getIsFidelizationAllowed(state)

export const getIsDisallowedNewAddressServiceChange = (state) => state.fidelization.newAddressServiceChange
