import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, title, ...rest }) => {
  useEffect(() => {
    document.title = title
  })
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default PublicRoute
