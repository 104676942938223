import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import saveAs from 'file-saver'
import i18n from 'i18n'

export const saveFile = (file, name) => {
  file.xlsx.writeBuffer().then(function (buffer) {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `exportedData${name}.xlsx`)
  })
}

export const formatTableDataIntoExcel = (data, titles) => {
  const { headers, normalizedHeaders } = titles
  const ExcelJSWorkbook = new ExcelJS.Workbook()
  const worksheet = ExcelJSWorkbook.addWorksheet('ExcelJS sheet')
  const headerRow = worksheet.addRow()

  headers.map((header, index) => {
    let cell = headerRow.getCell(index + 1)
    worksheet.getColumn(index + 1).width = 25
    return (cell.value = header.label)
  })

  const filteredDataRows = data.map((row) =>
    normalizedHeaders.map((key) => (key === 'status' ? i18n.t(`backOffice.state.${row[key]}`) : row[key]))
  )

  filteredDataRows.forEach((row) => {
    const dataRow = worksheet.addRow()
    row.forEach((data, index) => {
      let cell = dataRow.getCell(index + 1)
      cell.value = data
    })
  })

  return ExcelJSWorkbook
}

export const rawTableDataIntoExcel = (info) => {
  const ExcelJSWorkbook = new ExcelJS.Workbook()
  const worksheet = ExcelJSWorkbook.addWorksheet('ExcelJS sheet')
  const headerRow = worksheet.addRow()
  const headers = Object.keys(info[0])

  headers.map((key, index) => {
    let cell = headerRow.getCell(index + 1)
    worksheet.getColumn(index + 1).width = 25
    return (cell.value = key)
  })

  info.forEach((row) => {
    const dataRow = worksheet.addRow()
    headers.forEach((data, index) => {
      let cell = dataRow.getCell(index + 1)
      cell.value = row[data]
    })
  })

  return ExcelJSWorkbook
}

export const rawUserTableDataIntoExcel = (info) => {
  const ExcelJSWorkbook = new ExcelJS.Workbook()
  const worksheet = ExcelJSWorkbook.addWorksheet('ExcelJS sheet')
  const headerRow = worksheet.addRow()
  const headers = Object.keys(info[0])

  headerRow.font = { bold: true, size: 12 }
  headerRow.alignment = { vertical: 'middle', horizontal: 'center' }
  headerRow.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c9daf8' } }

  worksheet.getColumn(4).width = 35
  worksheet.getColumn(5).width = 35
  worksheet.getColumn(7).width = 55

  headers.map((key, index) => {
    let cell = headerRow.getCell(index + 1)
    if (index + 1 !== 4 && index + 1 !== 5 && index + 1 !== 7) {
      worksheet.getColumn(index + 1).width = 15
    }
    return (cell.value = key)
  })

  info.forEach((row) => {
    const dataRow = worksheet.addRow()
    headers.forEach((data, index) => {
      let cell = dataRow.getCell(index + 1)
      cell.value = row[data]
    })
  })

  return ExcelJSWorkbook
}
