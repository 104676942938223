import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  fileDownloadItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  fileIconContainer: {
    minWidth: 'auto',
  },
  fileIcon: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
}))

export default useStyles
