import { all, takeLatest, call, put } from 'redux-saga/effects'
import * as actionTypes from './action-types'
import { uploadInvoice } from './api'
import { uploadInvoiceStart, uploadInvoiceSuccess, uploadInvoiceError } from './actions'

function* fetchUploadInvoiceSaga({ payload: { data, callbackResume } }) {
  let invoice
  try {
    yield put(uploadInvoiceStart())
    invoice = yield call(uploadInvoice, data.file)
    if (!invoice.cups) throw new Error('Unkwon')
    yield put(uploadInvoiceSuccess())
    if (callbackResume) {
      callbackResume(invoice)
    }
  } catch (e) {
    console.error(e)
    yield put(uploadInvoiceError())
  } finally {
    if (callbackResume) {
      callbackResume(invoice)
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest([actionTypes.UPLOAD_INVOICE], fetchUploadInvoiceSaga)])
}
