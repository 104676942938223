import * as actionTypes from './action-types'

export const checkOnlineChannel = () => ({
  type: actionTypes.CHECK_ONLINE_CHANNEL,
})

export const startOnlineChannel = () => ({
  type: actionTypes.START_ONLINE_CHANNEL,
})

export const successOnlineChannel = () => ({
  type: actionTypes.SUCCESS_ONLINE_CHANNEL,
})

export const errorOnlineChannel = () => ({
  type: actionTypes.ERROR_ONLINE_CHANNEL,
})

export const clearOnlineChannel = () => ({
  type: actionTypes.CLEAR_ONLINE_CHANNEL,
})

export const startLoginOnlineChannel = (email, password) => ({
  type: actionTypes.START_LOGIN_ONLINE_CHANNEL,
  payload: { email, password },
})

export const successLoginOnlineChannel = () => ({
  type: actionTypes.SUCCESS_LOGIN_ONLINE_CHANNEL,
})

export const errorLoginOnlineChannel = () => ({
  type: actionTypes.ERROR_LOGIN_ONLINE_CHANNEL,
})

export const completeStepsOnlineChannel = ({ products, toppings, params }) => ({
  type: actionTypes.COMPLETE_STEPS_ONLINE_CHANNEL,
  payload: { products, toppings, params },
})
export const completeAnalyticsOnlineChannel = ({ stepId }) => ({
  type: actionTypes.COMPLETE_ANALYTICS_ONLINE_CHANNEL,
  payload: { stepId },
})

export const clearLoginOnlineChannel = () => ({
  type: actionTypes.CLEAR_LOGIN_ONLINE_CHANNEL,
})

export const setPymeOnlineChannel = (isPyme) => ({
  type: actionTypes.SET_PYME_ONLINE_CHANNEL,
  payload: isPyme,
})

export const setOnlineChannelMetaData = (metadata) => ({
  type: actionTypes.SET_META_DATA,
  payload: metadata,
})

export const clearOnlineChannelMetaData = () => ({
  type: actionTypes.CLEAR_META_DATA,
})

export const setLoginErrorByUser = (error) => ({
  type: actionTypes.SET_LOGIN_ERROR_BY_USER,
  payload: error,
})

export const clearLoginErrorByUser = () => ({
  type: actionTypes.CLEAR_LOGIN_ERROR_BY_USER,
})
