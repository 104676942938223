import groupBy from 'lodash.groupby'

export const getToppings = (state) => state.toppings
export const getAllToppings = (state) => state.toppings.all
export const getSingleToppings = (state) =>
  state && state.toppings && state.toppings.all && state.toppings.all.length ? state.toppings.all[0].toppings : []
export const getSelectedSingleToppings = (state) => state.toppings.selected
export const getSelectedToppingsType = (state) =>
  state.toppings.selected ? groupBy(Object.values(state.toppings.selected), 'type') : {}
export const getDuplicatedServiceType = (state) => state.toppings.duplicatedServiceType
export const getIsSelectionNotAllowed = (state) => !!state.toppings.selection?.disallowed
export const getSelectionDisallowedMessage = (state) => state.toppings.selection?.message
export const getVirtualBatteryStatus = (state) => state.toppings.virtualBattery
