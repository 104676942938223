import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { hideModal } from 'services/modal/actions'

import Typography from 'ui/components/typography'
import { Box, Button } from '@material-ui/core'

const AcceptModal = ({ data }) => {
  const { titleKey, textKey, callback, callbackArgs } = data
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const acceptAction = () => {
    if (typeof callback === 'function') {
      callback(callbackArgs)
    }
    dispatch(hideModal())
  }

  return (
    <Box px={5} pb={6}>
      <Box textAlign="center">
        {titleKey && (
          <Typography variant="h3" color="primary">
            {t(titleKey)}
          </Typography>
        )}
        {textKey && <Typography>{t(textKey)}</Typography>}
      </Box>
      <Box mt={3} display="flex" justifyContent="space-evenly">
        <Button variant="contained" color="secondary" onClick={acceptAction}>
          {t('common.accept')}
        </Button>
      </Box>
    </Box>
  )
}

export default AcceptModal
