import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, ClickAwayListener, ListItem } from '@material-ui/core'
import clsx from 'clsx'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const NotificationItem = ({ date, time, isManaged, orderId, orderCrmId, orderState, onClickAway }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const notificationMessage = t('notifications.notificationMessage', {
    orderCrmId,
    orderState: t(`backOffice.state.${orderState}`),
    date,
    time,
  })

  const handleGoOrderDetail = () => {
    history.push(`/backoffice/order/${orderId}`)
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <ListItem
        className={clsx(classes.notificationsListItem, !isManaged && classes.uncheckedItem)}
        button
        onClick={handleGoOrderDetail}
      >
        <Box display="flex" flexDirection="column" position="relative">
          <Box display="flex" flexDirection="row" alignItems="center">
            {!isManaged && <FiberManualRecordIcon className={classes.dotIcon} color="secondary" />}
            <Typography className={classes.label}>{date}</Typography>
          </Box>

          <Typography variant="h6">{notificationMessage}</Typography>
        </Box>
      </ListItem>
    </ClickAwayListener>
  )
}

export default NotificationItem
