import { WITOUT_SELFCONSUMPTION, CUPS_TYPE_01_CONSUM, CUPS_TYPE_02_AUX } from './constants'
import { ELECTRICITY, VARIANT_TYPES } from 'utils/constants'

export const getTypes = (state) => state.selfconsumption.types
export const getValidTypes = (state) => state.selfconsumption.types.filter(({ valid }) => valid)
export const getSubsections = (state) =>
  state.customer.selfconsumptionType
    ? state.selfconsumption.types?.find(({ code }) => code === state.customer.selfconsumptionType)
        ?.selfConsumptionSubsectionType ?? []
    : []
export const getCupsTypes = (_state) => [CUPS_TYPE_01_CONSUM, CUPS_TYPE_02_AUX]
export const getDefaultCupsType = (_state) => CUPS_TYPE_01_CONSUM
export const getDefaultType = (state) => state.selfconsumption.types.find(({ code }) => code === WITOUT_SELFCONSUMPTION)
export const getIsDisallowed = (state) => {
  const tenure = state.customer.selectedTenure
  const company = state.customer.company || state.products[0].selected?.company
  const isProductChange = Boolean(company?.code && tenure?.company && company.code.toLowerCase() === tenure.company.toLowerCase())
  const hasTenureElectricity = tenure?.variants?.some(({ type }) => type === VARIANT_TYPES[ELECTRICITY])

  return isProductChange && hasTenureElectricity
}
