import palette from '../base/palette'

const stepper = {
  props: {
    MuiStepContent: {
      TransitionProps: { unmountOnExit: false },
    },
  },
  overrides: {
    MuiStepper: {
      root: {
        background: 'transparent',
        padding: 0,
      },
    },
    MuiStep: {
      root: {
        background: palette.neutral.light4,
      },
    },
    MuiStepContent: {
      root: {
        borderLeft: 'none',
        marginTop: 0,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}

export default stepper
