import { SERVICE } from './constants'

export const LOAD_CONTROL_START = `${SERVICE}/LOAD_CONTROL_START`
export const LOAD_CONTROL_SUCCESS = `${SERVICE}/LOAD_CONTROL_SUCCESS`
export const LOAD_CONTROL_ERROR = `${SERVICE}/LOAD_CONTROL_ERROR`
export const LOAD_CONTROL_CLEAR = `${SERVICE}/LOAD_CONTROL_CLEAR`
export const FETCH_FIDELIZATION = `${SERVICE}/FETCH_FIDELIZATION`
export const SET_FIDELIZATION = `${SERVICE}/SET_FIDELIZATION`
export const CLEAR_FIDELIZATION = `${SERVICE}/CLEAR_FIDELIZATION`
export const SET_NEW_ADDRESS_PRODUCT_CHANGE = `${SERVICE}/SET_NEW_ADDRESS_PRODUCT_CHANGE`
export const CLEAR_NEW_ADDRESS_PRODUCT_CHANGE = `${SERVICE}/CLEAR_NEW_ADDRESS_PRODUCT_CHANGE`
export const SET_NEW_ADDRESS_SERVICE_CHANGE = `${SERVICE}/SET_NEW_ADDRESS_SERVICE_CHANGE`
export const CLEAR_NEW_ADDRESS_SERVICE_CHANGE = `${SERVICE}/CLEAR_NEW_ADDRESS_SERVICE_CHANGE`
