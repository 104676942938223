import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'
import { Box, FormControl, FormHelperText, Grid, InputLabel, TextField as MuiTextField, Select, Tooltip } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { InfoOutlined } from '@material-ui/icons'

import {
  cieSupplyTypeOptions,
  cieValidityScopeOptions,
  cieVoltageOptions,
  gnsCieVoltageOptionsValues,
  gnsCieValidityScopeOptionsValues,
} from '../../utils/constants'

import { PLANNED_DISCHARGE_DATE, PYME } from 'utils/constants'

const ElectricityNewSupply = ({
  values,
  touched,
  errors,
  onChange,
  setFieldValue,
  onTariffChange,
  onMaxPowerChange,
  tariffs,
  getVariantInfo,
  plannedDischargeDate,
  onPlannedDischargeDateChange,
  newSupply,
  referenceSegment,
  valueDefaultAnnualConsumption,
  isNaturgy,
  isOptional,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h5" color="primary">
          {t('newSupplyPoint.electricityTitle')}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* --- TARIFF --- */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="accessTariff">{t('newSupplyPoint.form.tariff')}</InputLabel>
            <Field
              component={Select}
              fullWidth
              native
              id="accessTariff"
              name="accessTariff"
              value={values.accessTariff}
              onChange={(e) => {
                onChange(e)
                onTariffChange(e, setFieldValue)
              }}
              error={!!(touched.accessTariff && errors.accessTariff)}
            >
              <option aria-label="None" value="" />
              {tariffs &&
                tariffs.map((tariff, index) => (
                  <option key={index} value={tariff}>
                    {tariff}
                  </option>
                ))}
            </Field>
          </FormControl>
          <ErrorMessage component={FormHelperText} name="accessTariff" error />
        </Grid>

        {/* --- ANNUAL CONSUMPTION --- */}
        {values.accessTariff && (
          <Grid item xs={12} sm={6}>
            <Field
              component={MuiTextField}
              required
              fullWidth
              disabled
              id="consumption12Months"
              label={t('form.fields.annualConsumption')}
              name="consumption12Months"
              value={values.consumption12Months}
              onChange={onChange('consumption12Months')}
              error={!!(touched.consumption12Months && errors.consumption12Months)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <Tooltip
                    placement="top"
                    title={
                      valueDefaultAnnualConsumption
                        ? t('pymeTooltip.defaultConsumption12Months')
                        : t('pymeTooltip.consumption12Months')
                    }
                  >
                    <InfoOutlined color="primary" />
                  </Tooltip>
                ),
              }}
            />
            <ErrorMessage component={FormHelperText} name="consumption12Months" error />
          </Grid>
        )}
        {/* --- MAXIMUM CAPACITY --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            id="maxCapacity"
            label={t('newSupplyPoint.form.maxPowerKW')}
            name="maxCapacity"
            value={values.maxCapacity}
            type="number"
            onChange={(e) => {
              onChange(e)
              onMaxPowerChange(e, setFieldValue, values)
            }}
            error={!!(touched.maxCapacity && errors.maxCapacity)}
            InputProps={{ inputProps: { min: 0 } }}
          />
          <ErrorMessage component={FormHelperText} name="maxCapacity" error />
        </Grid>
        {/* --- ELECTRONIC CIE --- */}
        {newSupply && (
          <>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                {/* --- CIE CODE --- */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={MuiTextField}
                    fullWidth
                    id="cieCode"
                    label={isOptional ? t('newSupplyPoint.form.cieCode') : t('newSupplyPoint.form.cieCodeOpt')}
                    name="cieCode"
                    value={values.cieCode}
                    onChange={onChange('cieCode')}
                    error={!!(touched.cieCode && errors.cieCode)}
                    InputProps={{ inputProps: { maxLength: 35 } }}
                  />
                  <ErrorMessage component={FormHelperText} name="cieCode" error />
                </Grid>
                {/* ---CIE ISSUE DATE --- */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={MuiTextField}
                    fullWidth
                    id="cieIssueDate"
                    name="cieIssueDate"
                    label={isOptional ? t('newSupplyPoint.form.cieIssueDate') : t('newSupplyPoint.form.cieIssueDateOpt')}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange('cieIssueDate')}
                    error={!!(touched.cieIssueDate && errors.cieIssueDate)}
                  />
                  <ErrorMessage component={FormHelperText} name="cieIssueDate" error />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                {/* --- CIE EXPIRATION DATE --- */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={MuiTextField}
                    fullWidth
                    id="cieExpirationDate"
                    label={
                      isOptional ? t('newSupplyPoint.form.cieExpirationDate') : t('newSupplyPoint.form.cieExpirationDateOpt')
                    }
                    name="cieExpirationDate"
                    value={values.cieExpirationDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    format={'yyyy/MM/dd'}
                    onChange={onChange('cieExpirationDate')}
                    error={!!(touched.cieExpirationDate && errors.cieExpirationDate)}
                  />
                  <ErrorMessage component={FormHelperText} name="cieExpirationDate" error />
                </Grid>
                {/* ---CIE NIF --- */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={MuiTextField}
                    fullWidth
                    id="cieNif"
                    name="cieNif"
                    label={isOptional ? t('newSupplyPoint.form.cieNif') : t('newSupplyPoint.form.cieNifOpt')}
                    InputProps={{ inputProps: { maxLength: 14 } }}
                    onChange={onChange('cieNif')}
                    error={!!(touched.cieNif && errors.cieNif)}
                  />
                  <ErrorMessage component={FormHelperText} name="cieNif" error />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                {/* --- CIE VOLTAGE --- */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="cieVoltage">
                      {isOptional ? t('newSupplyPoint.form.cieVoltage') : t('newSupplyPoint.form.cieVoltageOpt')}
                    </InputLabel>
                    <Field
                      component={Select}
                      fullWidth
                      native
                      id="cieVoltage"
                      label={isOptional ? t('newSupplyPoint.form.cieVoltage') : t('newSupplyPoint.form.cieVoltageOpt')}
                      name="cieVoltage"
                      value={values.cieVoltage}
                      onChange={onChange('cieVoltage')}
                      error={!!(touched.cieVoltage && errors.cieVoltage)}
                    >
                      <option aria-label="None" value="" />
                      {Object.entries(cieVoltageOptions)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, value]) => (
                          <option key={key} value={isNaturgy ? gnsCieVoltageOptionsValues[value] : key}>
                            {t(value)}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage component={FormHelperText} name="cieVoltage" error />
                  </FormControl>
                </Grid>
                {/* ---CIE SUPPLY TYPE --- */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="cieSupplyType">
                      {isOptional ? t('newSupplyPoint.form.cieSupplyType') : t('newSupplyPoint.form.cieSupplyTypeOpt')}
                    </InputLabel>
                    <Field
                      component={Select}
                      fullWidth
                      native
                      id="cieSupplyType"
                      name="cieSupplyType"
                      label={isOptional ? t('newSupplyPoint.form.cieSupplyType') : t('newSupplyPoint.form.cieSupplyTypeOpt')}
                      value={values.cieSupplyType}
                      onChange={onChange('cieSupplyType')}
                      error={!!(touched.cieSupplyType && errors.cieSupplyType)}
                    >
                      <option aria-label="None" value="" />
                      {Object.entries(cieSupplyTypeOptions)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, value]) => (
                          <option key={key} value={key}>
                            {t(value)}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage component={FormHelperText} name="cieSupplyType" error />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="cieValidityScope">
                  {isOptional ? t('newSupplyPoint.form.cieValidityScope') : t('newSupplyPoint.form.cieValidityScopeOpt')}
                </InputLabel>
                <Field
                  component={Select}
                  fullWidth
                  native
                  id="cieValidityScope"
                  name="cieValidityScope"
                  label={isOptional ? t('newSupplyPoint.form.cieValidityScope') : t('newSupplyPoint.form.cieValidityScopeOpt')}
                  value={values.cieValidityScope}
                  onChange={onChange('cieValidityScope')}
                  error={!!(touched.cieValidityScope && errors.cieValidityScope)}
                >
                  <option aria-label="None" value="" />
                  {Object.entries(cieValidityScopeOptions).map(([key, value]) => (
                    <option key={key} value={isNaturgy ? gnsCieValidityScopeOptionsValues[value] : key}>
                      {t(value)}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={FormHelperText} name="cieValidityScope" error />
              </FormControl>
            </Grid>
          </>
        )}
        {referenceSegment === PYME && !newSupply && (
          <Grid container>
            <Grid item xs={12}>
              <Box width={{ xs: '100%', sm: '50%' }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="plannedDischargeDate">{t('newSupplyPoint.form.plannedDischargeDate')}</InputLabel>
                  <Field
                    component={Select}
                    fullWidth
                    native
                    id="plannedDischargeDate"
                    name="plannedDischargeDate"
                    value={values.plannedDischargeDate}
                    error={!!(touched.plannedDischargeDate && errors.plannedDischargeDate)}
                    onChange={(e) => {
                      onPlannedDischargeDateChange(e)
                      setFieldValue('activationDate', null, false)
                    }}
                    disabled
                  >
                    {Object.entries(PLANNED_DISCHARGE_DATE).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <ErrorMessage component={FormHelperText} name="plannedDischargeDate" error />
              </Box>
            </Grid>
            {plannedDischargeDate === PLANNED_DISCHARGE_DATE.fixedDate && (
              <Grid item xs={12}>
                <Box width={{ xs: '100%', sm: '50%' }} mt={2.5} pl={4}>
                  <Field
                    component={MuiTextField}
                    fullWidth
                    id="activationDate"
                    name="activationDate"
                    label={t('newSupplyPoint.form.fixedDate')}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChange('activationDate')}
                    error={!!(touched.activationDate && errors.activationDate)}
                  />
                  <ErrorMessage component={FormHelperText} name="activationDate" error />
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {values.accessTariff && !!getVariantInfo(values.accessTariff)?.capacities?.length && (
          <Box p={1.5}>
            <Typography variant="basic">{t('newSupplyPoint.form.capacitiesDescription')}</Typography>
          </Box>
        )}

        <Box p={1.5}>
          <Grid container spacing={3}>
            {getVariantInfo(values.accessTariff)?.capacities?.map((capacity, index) => (
              <Grid item key={index} xs={12} sm={4}>
                <Field
                  component={MuiTextField}
                  fullWidth
                  id={capacity}
                  onChange={onChange(capacity)}
                  value={values[capacity]}
                  variant="outlined"
                  label={t(`supply.${capacity}`)}
                  name={capacity}
                  type="number"
                  error={!!(touched[capacity] && errors[capacity])}
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <ErrorMessage component={FormHelperText} name={capacity} error />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default ElectricityNewSupply
