import { SERVICE } from './constants'

export const FETCH_SUMMARY = `${SERVICE}/FETCH_SUMMARY`
export const SET_SUMMARY = `${SERVICE}/SET_SUMMARY`
export const CLEAR_SUMMARY = `${SERVICE}/CLEAR_SUMMARY`

export const FETCH_ORDER_DETAIL = `${SERVICE}/FETCH_ORDER_DETAIL`
export const FETCH_ORDER_DETAIL_FAIL = `${SERVICE}/FETCH_ORDER_DETAIL_FAIL`
export const UPDATE_ORDER_DETAIL = `${SERVICE}/UPDATE_ORDER_DETAIL`
export const FETCH_ORDER_SUMMARY = `${SERVICE}/FETCH_ORDER_SUMMARY`
export const UPDATE_ORDER_SUMMARY = `${SERVICE}/UPDATE_ORDER_SUMMARY`

export const UPDATE_PRODUCT_SUMMARY = `${SERVICE}/UPDATE_PRODUCT_SUMMARY`
export const FETCH_PRODUCT_SUMMARY = `${SERVICE}/FETCH_PRODUCT_SUMMARY`

export const FETCH_ORDERS = `${SERVICE}/FETCH_ORDERS`
export const FETCH_ORDERS_FAIL = `${SERVICE}/FETCH_ORDERS_FAIL`
export const FETCH_ORDER_LINES = `${SERVICE}/FETCH_ORDER_LINES`
export const FETCH_NOTIFICATIONS = `${SERVICE}/FETCH_NOTIFICATIONS`
export const SET_ORDERS = `${SERVICE}/SET_ORDERS`
export const SET_ORDERS_FAIL = `${SERVICE}/SET_ORDERS_FAIL`
export const SET_PRODUCTS = `${SERVICE}/SET_PRODUCTS`
export const SET_NOTIFICATIONS = `${SERVICE}/SET_NOTIFICATIONS`

export const SET_TOTAL_ORDERS = `${SERVICE}/SET_TOTAL_ORDERS`
export const SET_TOTAL_ORDERS_FAIL = `${SERVICE}/SET_TOTAL_ORDERS_FAIL`
export const SET_TOTAL_ORDER_LINES = `${SERVICE}/SET_TOTAL_ORDER_LINES`
export const SET_TOTAL_NOTIFICATIONS = `${SERVICE}/SET_TOTAL_NOTIFICATIONS`

export const FETCH_TOTAL_UNMANAGED_NOTIFICATIONS = `${SERVICE}/FETCH_TOTAL_UNMANAGED_NOTIFICATIONS`
export const SET_TOTAL_UNMANAGED_NOTIFICATIONS = `${SERVICE}/SET_TOTAL_UNMANAGED_NOTIFICATIONS`

export const FETCH_FILTERS_CONFIGURATION = `${SERVICE}/FETCH_FILTERS_CONFIGURATION`
export const UPDATE_FILTER_CONFIG = `${SERVICE}/UPDATE_FILTER_CONFIG`

export const EXPORT_CSV = `${SERVICE}/EXPORT_CSV`
export const EXPORT_REQUEST_START = `${SERVICE}/EXPORT_REQUEST_START`
export const EXPORT_REQUEST_SUCCESS = `${SERVICE}/EXPORT_REQUEST_SUCCESS`
export const EXPORT_REQUEST_CLEAR = `${SERVICE}/EXPORT_REQUEST_CLEAR`
export const EXPORT_REQUEST_ERROR = `${SERVICE}/EXPORT_REQUEST_ERROR`

export const EXPORT_CSV_FAILED = `${SERVICE}/EXPORT_CSV_FAILED`

export const CREATE_CSV = `${SERVICE}/CREATE_CSV`
export const SAVE_CSV = `${SERVICE}/SAVE_CSV`

export const ORDER_ATTACHMENTS_EXPORT_ID = `${SERVICE}/ORDER_ATTACHMENTS_EXPORT_ID`
export const EXPORT_ORDER_ATTACHMENTS = `${SERVICE}/EXPORT_ORDER_ATTACHMENTS`
export const ORDER_ATTACHMENTS_EXPORT_REQUEST_START = `${SERVICE}/ORDER_ATTACHMENTS_EXPORT_REQUEST_START`
export const ORDER_ATTACHMENTS_EXPORT_REQUEST_SUCCESS = `${SERVICE}/ORDER_ATTACHMENTS_EXPORT_REQUEST_SUCCESS`
export const ORDER_ATTACHMENTS_EXPORT_REQUEST_CLEAR = `${SERVICE}/ORDER_ATTACHMENTS_EXPORT_REQUEST_CLEAR`
export const ORDER_ATTACHMENTS_EXPORT_REQUEST_ERROR = `${SERVICE}/ORDER_ATTACHMENTS_EXPORT_REQUEST_ERROR`

export const CANCEL_ORDER = `${SERVICE}/CANCEL_ORDER`
export const CANCEL_ORDER_START = `${SERVICE}/CANCEL_ORDER_START`
export const CANCEL_ORDER_SUCCESS = `${SERVICE}/CANCEL_ORDER_SUCCESS`
export const CANCEL_ORDER_CLEAR = `${SERVICE}/CANCEL_ORDER_CLEAR`
export const CANCEL_ORDER_ERROR = `${SERVICE}/CANCEL_ORDER_ERROR`

export const VERIFY_ORDER = `${SERVICE}/VERIFY_ORDER`
export const VERIFY_ORDER_CLEAR = `${SERVICE}/VERIFY_ORDER_CLEAR`
export const VERIFY_ORDER_START = `${SERVICE}/VERIFY_ORDER_START`
export const VERIFY_ORDER_SUCCESS = `${SERVICE}/VERIFY_ORDER_SUCCESS`
export const VERIFY_ORDER_ERROR = `${SERVICE}/VERIFY_ORDER_ERROR`

export const LOAD_BACKOFFICE_START = `${SERVICE}/LOAD_BACKOFFICE_START`
export const LOAD_BACKOFFICE_SUCCESS = `${SERVICE}/LOAD_BACKOFFICE_SUCCESS`
export const LOAD_BACKOFFICE_CLEAR = `${SERVICE}/LOAD_BACKOFFICE_CLEAR`
export const LOAD_BACKOFFICE_ERROR = `${SERVICE}/LOAD_BACKOFFICE_ERROR`

export const FETCH_ORDER_SUMMARY_START = `${SERVICE}/FETCH_ORDER_SUMMARY_START`
export const FETCH_ORDER_SUMMARY_SUCCESS = `${SERVICE}/FETCH_ORDER_SUMMARY_SUCCESS`
export const FETCH_ORDER_SUMMARY_ERROR = `${SERVICE}/FETCH_ORDER_SUMMARY_ERROR`
export const FETCH_ORDER_SUMMARY_CLEAR = `${SERVICE}/FETCH_ORDER_SUMMARY_CLEAR`

export const PRODUCTS_SUMMARY_START = `${SERVICE}/PRODUCTS_SUMMARY_START`
export const PRODUCTS_SUMMARY_SUCCESS = `${SERVICE}/PRODUCTS_SUMMARY_SUCCESS`
export const PRODUCTS_SUMMARY_ERROR = `${SERVICE}/PRODUCTS_SUMMARY_ERROR`
export const PRODUCTS_SUMMARY_CLEAR = `${SERVICE}/PRODUCTS_SUMMARY_CLEAR`

export const FETCH_TABLE_DATA_START = `${SERVICE}/FETCH_TABLE_DATA_START`
export const FETCH_TABLE_DATA_SUCCESS = `${SERVICE}/FETCH_TABLE_DATA_SUCCESS`
export const FETCH_TABLE_DATA_ERROR = `${SERVICE}/FETCH_TABLE_DATA_ERROR`
export const FETCH_TABLE_DATA_CLEAR = `${SERVICE}/FETCH_TABLE_DATA_CLEAR`

export const SET_SEARCH_FILTERS = `${SERVICE}/SET_SEARCH_FILTERS`
export const CLEAR_SEARCH_FILTERS = `${SERVICE}/CLEAR_SEARCH_FILTERS`
export const SET_SELECTED_TAB = `${SERVICE}/SET_SELECTED_TAB`
export const CLEAR_SELECTED_TAB = `${SERVICE}/CLEAR_SELECTED_TAB`

export const SET_SELECTED_EXPORT_TEMPLATE_ID = `${SERVICE}/SET_SELECTED_EXPORT_TEMPLATE_ID`
export const CLEAR_SELECTED_EXPORT_TEMPLATE_ID = `${SERVICE}/CLEAR_SELECTED_EXPORT_TEMPLATE_ID`

export const RELAUNCH_ORDER_START = `${SERVICE}/RELAUNCH_ORDER_START`
export const RELAUNCH_ORDER_SUCCESS = `${SERVICE}/RELAUNCH_ORDER_SUCCESS`
export const RELAUNCH_ORDER_CLEAR = `${SERVICE}/RELAUNCH_ORDER_CLEAR`
export const RELAUNCH_ORDER_ERROR = `${SERVICE}/RELAUNCH_ORDER_ERROR`

export const SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_START = `${SERVICE}/SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_START`
export const SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_SUCCESS = `${SERVICE}/SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_SUCCESS`
export const SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_CLEAR = `${SERVICE}/SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_CLEAR`
export const SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_ERROR = `${SERVICE}/SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_ERROR`

export const FETCH_NOTIFICATIONS_START = `${SERVICE}/FETCH_NOTIFICATIONS_START`
export const FETCH_NOTIFICATIONS_SUCCESS = `${SERVICE}/FETCH_NOTIFICATIONS_SUCCESS`
export const FETCH_NOTIFICATIONS_ERROR = `${SERVICE}/FETCH_NOTIFICATIONS_ERROR`
export const FETCH_NOTIFICATIONS_CLEAR = `${SERVICE}/FETCH_NOTIFICATIONS_CLEAR`

export const DELETE_NOTIFICATIONS = `${SERVICE}/DELETE_NOTIFICATIONS`
export const DELETE_NOTIFICATIONS_START = `${SERVICE}/DELETE_NOTIFICATIONS_START`
export const DELETE_NOTIFICATIONS_SUCCESS = `${SERVICE}/DELETE_NOTIFICATIONS_SUCCESS`
export const DELETE_NOTIFICATIONS_ERROR = `${SERVICE}/DELETE_NOTIFICATIONS_ERROR`
export const DELETE_NOTIFICATIONS_CLEAR = `${SERVICE}/DELETE_NOTIFICATIONS_START`

export const MANAGE_NOTIFICATION = `${SERVICE}/MANAGE_NOTIFICATION`
export const MANAGE_NOTIFICATIONS = `${SERVICE}/MANAGE_NOTIFICATIONS`
export const MANAGE_NOTIFICATIONS_START = `${SERVICE}/MANAGE_NOTIFICATIONS_START`
export const MANAGE_NOTIFICATIONS_SUCCESS = `${SERVICE}/MANAGE_NOTIFICATIONS_SUCCESS`
export const MANAGE_NOTIFICATIONS_ERROR = `${SERVICE}/MANAGE_NOTIFICATIONS_ERROR`
export const MANAGE_NOTIFICATIONS_CLEAR = `${SERVICE}/MANAGE_NOTIFICATIONS_START`

export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE'
export const CLEAR_NOTIFICATION_STATE = 'CLEAR_NOTIFICATION_STATE'
export const SET_ERROR_NOTIFICATION = 'SET_ERROR_NOTIFICATION'
export const CLEAR_ERROR_NOTIFICATION = 'CLEAR_ERROR_NOTIFICATION'
