import * as actionTypes from './action-types'

export const fetchCompanies = () => ({
  type: actionTypes.FETCH_COMPANIES,
})

export const setCompanies = (companies) => ({
  type: actionTypes.SET_COMPANIES,
  payload: companies,
})

export const clearCompanies = () => ({
  type: actionTypes.CLEAR_COMPANIES,
})
