export const getSignErrorMessage = (statusCode, errorMessage) => {
  const statusMessage = {
    498: `Error 498 - Error de Aviva: ${errorMessage}`,
    499: `Error 499 - Error comunicando datos a la agencia: ${errorMessage}`,
    500: `Error 500 - ${errorMessage || 'El servidor no responde. Inténtalo de nuevo más tarde.'}`,
  }
  return statusMessage[statusCode] || `Error ${statusCode} - Ha ocurrido un error. Inténtalo de nuevo.`
}

export const mapP0Data = (supplyPoints) =>
  Object.assign(
    {},
    ...supplyPoints.filter((supplyPoint) => !!supplyPoint.distributor).map(({ cups, distributor }) => ({ [cups]: distributor }))
  )
