import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles'

const Forbidden = ({ redirect }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.container} mb={8}>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>403 - Forbidden</h1>
        <p>You do not have permission to access this resource.</p>
      </div>
    </Box>
  )
}

export default Forbidden
