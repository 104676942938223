import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

import { CONTRACT_STEPS } from './constants'

/* eslint-disable-next-line */
export default combineReducers({
  activeStep: redukers.value(
    {
      set: actionTypes.SET_ACTIVE_STEP,
      reset: actionTypes.CLEAR_ACTIVE_STEP,
    },
    0
  ),
  activeStepDuplicate: redukers.value(
    {
      set: actionTypes.SET_ACTIVE_STEP_DUPLICATE,
      reset: actionTypes.CLEAR_ACTIVE_STEP_DUPLICATE,
    },
    -1
  ),
  steps: redukers.value(
    {
      set: actionTypes.SET_STEPS,
      reset: actionTypes.CLEAR_STEPS,
    },
    CONTRACT_STEPS
  ),
})
