import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  id: redukers.value(
    {
      set: actionTypes.SET_LEAD_ID,
      reset: actionTypes.CLEAR_LEAD_ID,
    },
    null
  ),
  billingAddressId: redukers.value(
    {
      set: actionTypes.SET_BILLING_ADDRESS_ID,
      reset: actionTypes.CLEAR_BILLING_ADDRESS_ID,
    },
    null
  ),
  supplyAddressesIds: redukers.value(
    {
      set: actionTypes.SET_SUPPLY_ADDRESSES_IDS,
      reset: actionTypes.CLEAR_SUPPLY_ADDRESSES_IDS,
    },
    null
  ),
  request: redukers.request({
    start: actionTypes.START_LEAD,
    success: actionTypes.SUCCESS_LEAD,
    error: actionTypes.ERROR_LEAD,
    clear: actionTypes.CLEAR_LEAD,
  }),
  errorMessage: redukers.value(
    {
      set: actionTypes.SET_LEAD_ERROR_MESSAGE,
      reset: actionTypes.CLEAR_LEAD_ERROR_MESSAGE,
    },
    null
  ),
})
