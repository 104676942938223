import dayjs from 'dayjs'

import {
  PRODUCT_TRANSITION_STATUS,
  FAILED_STATES,
  FAILED_MANAGED_STATE,
  FAILED_WITHOUT_RECOVERY_STATE,
  INACTIVE_STATE,
  DOCOUT_SOURCE_ERRORS,
  PRODUCT_KO_GDA_STATUS,
  TRANSACTIONAL_PRODUCT_STATE,
} from './constants'
import { DATE_FORMAT, CONTROL_VISUALIZATION } from '../../utils/constants'

export const getMotivoKO = ({ source, error_messages }) => `${source}: ${error_messages}`

export const parseExport = (exportLine) => {
  return {
    Id_Venta: exportLine.crmId,
    Canal: exportLine.channelName,
    Fecha_Contratacion: exportLine.createdAt ? dayjs(exportLine.createdAt).format(DATE_FORMAT) : '',
    Fecha_Ultimo_Cambio_Estado: exportLine.lastStateChange ? dayjs(exportLine.lastStateChange).format(DATE_FORMAT) : '',
    Ultimo_Estado: exportLine.state,
    'Estado_Siebel/Salesforce': exportLine.transactionalState,
    Producto_Servicio: exportLine.productName ?? exportLine.serviceName,
    Tipo_Producto_Servicio: exportLine.energyType,
    Agente: exportLine.sellerCode,
    Codigo_Vendedor: exportLine.sellerCode,
    Agencia: exportLine.agencyCode,
    Usuario_Grabador: exportLine.usuarioGrabador,
    NIF_NIE_Pasaporte: exportLine.customerNif,
    CUPS: exportLine.cups,
    Motivo_KO: exportLine.koReason ? getMotivoKO(JSON.parse(exportLine.koReason)) : '',
    Empresa: exportLine.companyName,
    Tipo_Contratacion: exportLine.contractType,
    'Fecha_Baja/Activación': exportLine.actionDate ? dayjs(exportLine.actionDate).format(DATE_FORMAT) : '',
    Línea_de_contrato: exportLine.contractLine,
  }
}

export const getPageFromNextUrl = (url) => {
  const search = new URLSearchParams(url)
  return search.get('page')
}

export const isOrderFailed = ({ orderStates }) => FAILED_STATES.some((state) => orderStates[state] === 1)

export const isOrderFailedManaged = ({ orderStates }) => orderStates[FAILED_MANAGED_STATE] === 1

export const isOrderFailedWithoutRecovery = ({ orderStates }) => orderStates[FAILED_WITHOUT_RECOVERY_STATE] === 1

export const isOrderInactive = ({ orderStates }) => orderStates[INACTIVE_STATE] === 1

export const isOrderDocoutError = (order) =>
  DOCOUT_SOURCE_ERRORS.includes(order.koInfo?.source) && !isOrderFailedManaged(order) && !isOrderFailedWithoutRecovery(order)

export const filterOrders = (orders, sellerCode) => {
  const ownOrders = sellerCode ? orders.filter((order) => order.sellerCode === sellerCode) : orders
  return ownOrders.filter((order) => !isOrderFailed(order) || isOrderDocoutError(order))
}

export const filterTotalFailed = ({ order }) => isOrderFailed(order) && isOrderDocoutError(order)
export const filterTotalProducts = ({ order, ...product }) =>
  !filterTotalFailed({ order }) && Object.values(PRODUCT_TRANSITION_STATUS).includes(product.clientProductState)
export const filterTotalFailedOnActivation = ({ order, ...product }) =>
  product.clientProductState === PRODUCT_KO_GDA_STATUS.INACTIVE &&
  product.crmProductState === PRODUCT_KO_GDA_STATUS.INACTIVE &&
  product.transactionalProductState === TRANSACTIONAL_PRODUCT_STATE.REFUSED
export const filterTotalActive = (orderProduct) =>
  !(filterTotalFailed(orderProduct) || filterTotalProducts(orderProduct) || filterTotalFailedOnActivation(orderProduct))

export const reduceTotalSold = (acc, { order, ...product }) => ({
  ...acc,
  [product.type || 'OTHERS']: (acc[product.type || 'OTHERS'] ?? 0) + 1,
})
export const reduceTotalFailed = (acc, { order }) => ({
  ...acc,
  [order.koInfo?.error_messages]: (acc[order.koInfo?.error_messages] ?? 0) + 1,
})
export const reduceTotalProducts = (acc, { order, ...product }) => ({
  ...acc,
  [product.clientProductState]: (acc[product.clientProductState] ?? 0) + 1,
})
export const reduceTotalFailedOnActivation = (acc, { order, ...product }) => ({
  ...acc,
  [product.type || 'OTHERS']: (acc[product.type || 'OTHERS'] ?? 0) + 1,
})
export const reduceTotalActive = (acc, { order, ...product }) => ({
  ...acc,
  [product.type || 'OTHERS']: (acc[product.type || 'OTHERS'] ?? 0) + 1,
})
export const reduceActivationTime = (acc, { order, ...product }) => ({
  ...acc,
  [product.type || 'OTHERS']: (acc[product.type || 'OTHERS'] ?? 0) + product.age,
})

export const filter = {
  [CONTROL_VISUALIZATION.totalSold]: null,
  [CONTROL_VISUALIZATION.totalFailed]: filterTotalFailed,
  [CONTROL_VISUALIZATION.totalProducts]: filterTotalProducts,
  [CONTROL_VISUALIZATION.totalFailedOnActivation]: filterTotalFailedOnActivation,
  [CONTROL_VISUALIZATION.activationTime]: null,
  [CONTROL_VISUALIZATION.totalActive]: filterTotalActive,
}

export const reduce = {
  [CONTROL_VISUALIZATION.totalSold]: reduceTotalSold,
  [CONTROL_VISUALIZATION.totalFailed]: reduceTotalFailed,
  [CONTROL_VISUALIZATION.totalProducts]: reduceTotalProducts,
  [CONTROL_VISUALIZATION.totalFailedOnActivation]: reduceTotalFailedOnActivation,
  [CONTROL_VISUALIZATION.activationTime]: reduceActivationTime,
  [CONTROL_VISUALIZATION.totalActive]: reduceTotalActive,
}
