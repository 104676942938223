const palette = {
  primary: {
    main: '#004571',
    light1: 'rgba(0, 69, 113, 0.5)',
    light2: 'rgba(0, 69, 113, 0.2)',
    light3: 'rgba(0, 69, 113, 0.08)',
  },
  secondary: {
    main: '#E57200',
    light1: 'rgba(229, 114, 0, 0.5)',
    light2: 'rgba(229, 114, 0, 0.2)',
    dark1: '#DC6016',
  },
  neutral: {
    main: '#403D3A',
    light1: '#BFB8AE',
    light2: '#D9D1C5',
    light3: '#F9F8F7',
    light4: '#FFFEFD',
    light5: '#F1F3F4',
    light6: '#F9F8F7',
  },
  feedback: {
    success: '#A2AD00',
    warning: '#FFD833',
    danger: '#D3222A',
    info: '#0066CC',
  },
}

export default palette
