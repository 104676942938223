import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Hidden } from '@material-ui/core'
import Typography from '@zatopek/core/src/ui/components/typography'

import groupByKey from '@zatopek/core/src/utils/groupByKey'
import assoc from '@zatopek/core/src/utils/assoc'
import camelize from '@zatopek/core/src/utils/camelize'

import useStyles from './styles'
import { getInitialValues } from './utils'
import { FIELDS } from './constants'
import { RESIDENTIAL } from 'utils/constants'

// TOD: Remove duplicate code

const ProductAttributes = ({ schema, values, setValues, productAttributes, referenceSegment }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValues(getInitialValues(schema)), [schema])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValues(getInitialValues(schema)), [])

  const groupedFields = useMemo(() => groupByKey(schema, 'label'), [schema])

  const updateValues = (name, value) => {
    const newValues = assoc(name, value, values)
    setValues(newValues)
  }

  return values ? (
    <>
      {referenceSegment === RESIDENTIAL ? (
        <Hidden xsUp>
          {Object.entries(groupedFields).map(([label, fields]) => (
            <Box mb={3} key={label} className={classes.field}>
              <Box mb={1}>
                <Typography variant="h6">{t(camelize(label))}</Typography>
              </Box>
              <Box px={1}>
                {fields.map(({ attribute: { name }, ...field }) => {
                  const Field = FIELDS[field.field]
                  return (
                    <Field
                      key={`field-${name}`}
                      {...field}
                      name={name}
                      value={values[name]}
                      onChange={(e) => updateValues(name, e)}
                    />
                  )
                })}
              </Box>
            </Box>
          ))}
        </Hidden>
      ) : (
        Object.entries(groupedFields).map(([label, fields]) => (
          <Box mb={3} key={label} className={classes.field}>
            <Box mb={1}>
              <Typography variant="h6">{t(camelize(label))}</Typography>
            </Box>
            <Box px={1}>
              {fields.map(({ attribute: { name }, ...field }) => {
                const Field = FIELDS[field.field]
                return (
                  <Field
                    key={`field-${name}`}
                    {...field}
                    name={name}
                    value={values[name]}
                    defaultValue={productAttributes && productAttributes[name]}
                    onChange={(e) => updateValues(name, e)}
                  />
                )
              })}
            </Box>
          </Box>
        ))
      )}
    </>
  ) : null
}

export default ProductAttributes
