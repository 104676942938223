import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { history } from 'redux/store'
import { clearSelectedProduct, setIsOnlyToppings, setProducts, setProductWithAttribute } from 'services/products/actions'
import {
  getDraftBillingAddress,
  getDraftLead,
  getDraftSelectedProduct,
  getDraftSupplyAddress,
  getDraftSelectedServices,
  getDraftId,
  getDraftToken,
  getDuplicateOrderDraft,
} from './selectors'
import * as actionTypes from './action-types'

import { getChannels as getAllChannels, getReferenceSegment, getSelectedChannel } from 'services/operator/selectors'
import {
  getCustomerData,
  getBillingAccount,
  getBillingAddress,
  getSupplyPoint,
  getOnlineInvoice,
  getIsChangeBillingAddress,
  getIsAutosave,
  getIsNewSupply,
  getOnBehalfContactId,
  getSupplyAddress,
  getCompany,
} from 'services/customer/selectors'
import { getSelectedRate, getIsOnlyToppings, getProductAttributes } from 'services/products/selectors'
import { getLeadId } from 'services/lead/selectors'
import { getSingleToppings, getSelectedSingleToppings, getAllToppings as getToppings } from 'services/toppings/selectors'
import { getCRMId, getOrders } from 'services/order/selectors'
import {
  getIsSupplyStepCompleted,
  getIsOwnerStepCompleted,
  getIsToppingStepCompleted,
  getIsRateStepCompleted,
  getIsBillingStepCompleted,
} from 'services/stepper/selectors'
import { getRouterSearch } from 'services/analytics/selectors'
import { setCupsSearcherByCups } from 'services/cupsSearcher/actions'

import {
  successDraft,
  errorDraft,
  setDraftChannelId,
  setDraftChannelOfferId,
  setDraftLead,
  setDraftId,
  setDraftOrder,
  setDraftUserId,
  errorFillFunnelWithDraft,
  startFillFunnelWithDraft,
  errorSendDraftData,
  successSendDraftData,
  setDraftOnlineChannel,
  successDraftOnlineChannel,
  successDraftDuplicateOrder,
  errorDraftDuplicateOrder,
  setDuplicateOrderDraft,
  successRecoverDraftDuplicateOrder,
} from './actions'
import { addToppingToSelection, removeToppings, setAllToppings, setToppings } from 'services/toppings/actions'
import {
  goNextStepDuplicate,
  goNextStep,
  setActiveStepDuplicate,
  setActiveStep,
  setStepCompleted,
  setStepDisabled,
  setStepEnabled,
} from 'services/stepper/actions'
import { setNotificationState } from 'services/notification/actions'
import {
  setBillingAccount,
  setBillingAddress,
  setBirthdayDate,
  setIsMultiPoint,
  setIsNewSupply,
  setOnBehalfContactId,
  setOnlineInvoice,
  setSupplyPoint,
  submitBillingStep,
  submitOwnerStepDuplicate,
  submitOwnerStepDraft,
  submitOwnerStep,
  setAutosave,
  setCompany,
  setCustomer,
  setSelectedCompany,
  setSupplyAddress,
  setReferralCode,
} from 'services/customer/actions'
import { resetContract, setSelectedChannel, setChannels, setReferenceSegment } from 'services/operator/actions'
import { getChannels } from 'services/operator/api'
import { showModal } from 'services/modal/actions'
import { setCRMId, setOrders, setScoringSent } from 'services/order/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { setIsLoading } from 'services/loading/actions'
import { setLeadId } from 'services/lead/actions'

import { getDraftOnlineChannelData } from './business'
import { getOnlineChannelUrlParams } from 'services/onlineChannel/business'
import { set as setItemSessionStorage } from 'services/sessionStorage'

import { getDraft, getIncompleteOrder, saveDraft, putDuplicateOrder } from './api'
import { getProducts } from 'services/products/api'
import { getAllToppings } from 'services/toppings/api'

import { BOTH, ELECTRICITY, PYME, STEPS, RESIDENTIAL, CHANNEL_SESSION_STORAGE_KEY, GAS, ERROR_RETARGETING } from 'utils/constants'

import getEntityLangKeys from 'utils/getEntityLangKeys'
import { getAllCompanies } from 'services/company/api'
import { getCompanies } from 'services/company/selectors'

import {
  clearDraftOrder,
  clearDraftLead,
  clearDraftUserId,
  clearDraftChannelId,
  clearDraftChannelOfferId,
  clearDraft,
  clearFillFunnelWithDraft,
  clearDraftOnlineChannel,
  clearDuplicateOrderDraft,
  clearDraftDuplicateOrder,
  clearRecoverDraftDuplicateOrder,
  clearSendDraftData,
  clearDraftId,
} from 'services/draftCopy/actions'

function* recoverDraftSaga({ payload: { leadId, company } }) {
  try {
    yield put(setIsLoading(true))
    let companies = yield select(getCompanies)
    if (!companies.length) {
      companies = yield call(getAllCompanies)
    }
    const channels = yield call(getChannels)
    yield put(setChannels(channels))
    const draft = yield call(getDraft, leadId)
    const channel = channels.find((channel) => channel.id === draft.channelOffer?.id)
    const selectedCompany = companies.find((companyObj) => companyObj.code === company)
    const referenceSegment = draft.lead.pyme ? PYME : RESIDENTIAL

    if (draft) {
      yield put(setActiveStepDuplicate(0))
      yield put(setSelectedCompany({ name: selectedCompany?.name, id: selectedCompany?.id }))
      yield put(setDraftOrder(draft.orders[0]))
      yield put(setDraftLead(draft.lead))
      yield put(setDraftUserId(draft.user?.id))
      yield put(setDraftChannelId(draft.channel?.id))
      yield put(setDraftChannelOfferId(draft.channelOffer?.id))
      yield put(setSelectedChannel(channel))
      if (draft?.lead?.referralCode) {
        yield put(setReferralCode(draft.lead.referralCode))
      }
      yield put(setReferenceSegment(referenceSegment))

      const isSircaSuccess = draft.order?.states && Object.keys(draft.order.states)?.includes('sirca_success')
      if (isSircaSuccess) {
        yield put(setScoringSent())
      }

      // yield put(setOrderId(draft.order?.id))
      yield put(setLeadId(draft.lead?.id))
    }

    yield put(successDraft())
    yield put(startFillFunnelWithDraft(referenceSegment, company))
  } catch (e) {
    console.error(e)
    yield put(setIsLoading(false))
    yield put(errorDraft())
    yield put(resetContract())
    yield put(showModal({ modalType: MODAL_TYPES.draft }))
  }
}

function* recoverSendDraftSaga() {
  /* Recover steps state */
  const isOwnerStepCompleted = yield select(getIsOwnerStepCompleted)
  const isRateStepCompleted = yield select(getIsRateStepCompleted)
  const isSupplyStepCompleted = yield select(getIsSupplyStepCompleted)
  const isServiceStepCompleted = yield select(getIsToppingStepCompleted)
  const isBillingStepCompleted = yield select(getIsBillingStepCompleted)

  const steps = {
    isOwnerStepCompleted,
    isRateStepCompleted,
    isSupplyStepCompleted,
    isServiceStepCompleted,
    isBillingStepCompleted,
  }

  /* Recover data to send the draft, the incomplete order */
  const leadId = yield select(getLeadId)
  const search = yield select(getRouterSearch)
  const onlineChannelParams = getOnlineChannelUrlParams(search)
  const customerData = yield select(getCustomerData)
  const supplyAddress = yield select(getSupplyAddress)
  const onBehalfContactId = yield select(getOnBehalfContactId)
  const autosaveCustomerDraft = yield select(getIsAutosave)
  const isNewSupply = yield select(getIsNewSupply)
  const billingAccount = yield select(getBillingAccount)
  const billingAddress = yield select(getBillingAddress)
  const isChangeBillingAddress = yield select(getIsChangeBillingAddress)
  const selectedProductArray = yield select(getSelectedRate)
  const selectedRate = selectedProductArray
  const toppingsSelected = yield select(getSelectedSingleToppings)
  const supplyPoints = yield select(getSupplyPoint)
  const isOnlyToppings = yield select(getIsOnlyToppings)
  const onlineInvoice = yield select(getOnlineInvoice)
  const productAttributes = yield select(getProductAttributes)
  const orderCRMId = yield select(getCRMId)
  const orders = yield select(getOrders)

  /** Add draft information */
  const draftInfo = {
    url: window.location.href,
    leadId,
    onlineChannelParams,
    toppingsSelected,
    customer: {
      data: customerData,
      billingAccount,
      billingAddress,
      supplyPoints,
      onlineInvoice,
      isChangeBillingAddress,
      autosaveCustomerDraft,
      isNewSupply,
      onBehalfContactId,
      supplyAddress,
    },
    product: {
      isOnlyToppings,
      selectedRate,
      attributes: productAttributes,
    },
    order: {
      CRMId: orderCRMId,
      orders,
    },
  }

  /** Get composed draft data prepared to send */
  const drafData = getDraftOnlineChannelData(draftInfo, steps)

  try {
    const incompleteOrderId = yield select(getDraftId)
    const responseDraft = yield call(saveDraft, drafData, incompleteOrderId)

    const { id } = responseDraft
    if (id) {
      yield put(setDraftId(id))
    }
    yield put(successSendDraftData())
  } catch (e) {
    yield put(errorSendDraftData())
    yield put(setAutosave(false))
    yield put(
      setNotificationState({
        message: 'notificationAlerts.autosave',
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* fillFunnelWithDraftSaga({ payload: { referenceSegment, company } }) {
  try {
    /**
     * Select Product
     */
    company = { code: company }

    const channel = yield select(getSelectedChannel)
    // TODO: add parameter to pass pyme referenceSegment
    const allProducts = yield call(getProducts, { channel: channel.id, company: company, referenceSegment })
    const formattedProducts = allProducts.map((product) => ({ ...product, ...getEntityLangKeys(product.name) }))
    // Group products by family
    const products = formattedProducts.filter((product) => !product.family)
    const productsToGroup = formattedProducts.filter((product) => product.family)
    const uniqFamilyIds = [...new Set(productsToGroup.map((product) => product.family))]

    uniqFamilyIds.forEach((familyId) => {
      const productsInFamily = productsToGroup
        .filter((product) => product.family === familyId)
        .sort((product) => (product.energyType === ELECTRICITY ? -1 : 0))

      if (productsInFamily.length > 1) {
        products.push({
          id: familyId,
          name: productsInFamily[0].name,
          title: productsInFamily[0].title,
          subtitle: productsInFamily[0].subtitle,
          energyType: BOTH,
          subProducts: productsInFamily,
          flatRate: productsInFamily[0].flatRate,
          image: productsInFamily[0].image,
        })
        products.push(...productsInFamily)
      } else {
        products.push(productsInFamily[0])
      }
    })
    yield put(setProducts(products))
    const draftSelectedProduct = yield select(getDraftSelectedProduct) || []

    if (draftSelectedProduct?.length === 1) {
      const { duration, online, eco } = draftSelectedProduct[0]
      const selectedProduct = products.find((product) => product.id === draftSelectedProduct[0].productVariant.product.id)
      if (selectedProduct) {
        yield put(setProductWithAttribute(selectedProduct, { [selectedProduct.id]: { duration, online, eco } }))
        if (selectedProduct.energyType === ELECTRICITY) {
          setCupsSearcherByCups({ electricity: draftSelectedProduct[0].byCups })
        } else if (selectedProduct.energyType === GAS) {
          setCupsSearcherByCups({ gas: draftSelectedProduct[0].byCups })
        }
      }
      yield put(setStepCompleted(STEPS.rate))
    } else if (draftSelectedProduct?.length === 2) {
      // This means is dual product
      const byCups = { electricity: null, gas: null }
      if (draftSelectedProduct[0].productVariant.product.energyType === ELECTRICITY) {
        byCups.electricity = draftSelectedProduct[0].byCups
        byCups.gas = draftSelectedProduct[1].byCups
      } else if (draftSelectedProduct[0].productVariant.product.energyType === GAS) {
        byCups.electricity = draftSelectedProduct[1].byCups
        byCups.gas = draftSelectedProduct[0].byCups
      }
      setCupsSearcherByCups(byCups)
      const dualProducts = products.filter((product) => !!product.subProducts)
      const selectedDualProduct = dualProducts.find(
        (dualProduct) =>
          (dualProduct.subProducts[0].id === draftSelectedProduct[0].productVariant.product.id ||
            dualProduct.subProducts[1].id === draftSelectedProduct[0].productVariant.product.id) &&
          (dualProduct.subProducts[0].id === draftSelectedProduct[1].productVariant.product.id ||
            dualProduct.subProducts[1].id === draftSelectedProduct[1].productVariant.product.id)
      )
      if (selectedDualProduct) {
        let attributes = selectedDualProduct.subProducts.map((subproduct, index) => ({
          [subproduct.id]: {
            'product-duration': draftSelectedProduct[index]?.duration,
            'product-online': draftSelectedProduct[index]?.online,
            'product-eco': draftSelectedProduct[index]?.eco,
          },
        }))
        attributes = { ...attributes[0], ...attributes[1] }
        yield put(setProductWithAttribute(selectedDualProduct, { ...attributes }))
        yield put(setStepCompleted(STEPS.rate))
      } else {
        const selectedProduct = draftSelectedProduct.map((product) =>
          products.find((prod) => prod.id === product.productVariant.product.id)
        )
        let attributes = selectedProduct.map((subproduct, index) => ({
          [subproduct.id]: {
            'product-duration': draftSelectedProduct[index]?.duration,
            'product-online': draftSelectedProduct[index]?.online,
            'product-eco': draftSelectedProduct[index]?.eco,
          },
        }))
        attributes = { ...attributes[0], ...attributes[1] }
        yield put(setProductWithAttribute(selectedProduct, { ...attributes }))
        yield put(setStepCompleted(STEPS.rate))
      }
    } else {
      //yield put(setStepUncompleted(STEPS.rate))
      yield put(clearSelectedProduct())
    }

    // TODO: add parameter to pass pyme referenceSegment
    let toppings = yield call(getAllToppings, { selectedChannel: channel, referenceSegment, company: company.code } || {})

    toppings =
      toppings.map((topping) => ({
        ...topping,
        service: { ...topping, ...getEntityLangKeys(topping.name) },
      })) || []

    yield put(setAllToppings([{ toppings }]))

    yield put(goNextStepDuplicate())

    /**
     * Select Service
     */
    const services = yield select(getSingleToppings)
    const draftSelectedServices = yield select(getDraftSelectedServices) || []

    // TODO: refactor this maps and remove item.id from the value
    const selectedServices =
      draftSelectedServices
        ?.flatMap((draftSelectedService) =>
          services.filter((service) => service.id === draftSelectedService.service.id).map((service) => service.service)
        )
        ?.reduce((obj, item) => ({ ...obj, [item.id]: item }), {}) || {}
    const spfServiceType = services?.find((topping) => topping?.service?.type === 'SPF')

    if (spfServiceType) {
      const spfTopping =
        selectedServices && selectedServices[spfServiceType.id]
          ? { [spfServiceType.id]: selectedServices[spfServiceType.id] }
          : {}
      yield put(setToppings({ ...selectedServices, ...spfTopping }))
      yield put(setStepCompleted(STEPS.topping))
    } else {
      yield put(setToppings(selectedServices))
      if (Object.keys(selectedServices).length > 0) {
        yield put(setStepCompleted(STEPS.topping))
      }
    }
    //  yield put(setStepCompleted(STEPS.topping))
    yield put(goNextStepDuplicate())

    if (Object.keys(selectedServices ?? {}).length > 0 && draftSelectedProduct?.length === 0) {
      const draftSupplyAddress = yield select(getDraftSupplyAddress)
      const draftBillingAddress = yield select(getDraftBillingAddress)
      if (!draftSupplyAddress?.id || draftBillingAddress?.id) {
        // We suppouse that it have been contract just a service
        yield put(setStepCompleted(STEPS.rate))
        yield put(setStepDisabled(STEPS.supply))
        yield put(setStepCompleted(STEPS.supply))
        yield put(setIsOnlyToppings(true))
      }
    }

    /**
     * Set Owner Data
     */
    let {
      documentType,
      documentNumber,
      firstName,
      lastName,
      secondLastName,
      email,
      accountHolder,
      accountHolderSurnames,
      accountHolderLastName,
      accountHolderSecondLastName,
      accountHolderDocumentNumber,
      accountHolderDocumentType,
      accountIban,
      onlineInvoice,
      phone,
      birthdayDate,
      checkGDPR1,
      checkGDPR2,
      checkGDPR3,
      retargeting,
      preferredLanguage,
      partialAccountIban,
      contact,
    } = yield select(getDraftLead)

    birthdayDate = birthdayDate?.date.substring(0, 10)

    if (contact?.id) {
      yield put(setOnBehalfContactId(`/middleware/contacts/${contact.id}`))
    }

    yield put(
      submitOwnerStepDraft({
        idDocumentType: documentType,
        idDocumentNumber: documentNumber,
        name: firstName,
        lastName,
        secondLastName,
        email,
        iban: accountIban || '',
        phone,
        complexProfilePolicy: checkGDPR1,
        publicityPolicy: checkGDPR2,
        thirdPartyPolicy: checkGDPR3,
        retargeting,
        language: preferredLanguage,
        ...(contact && {
          contactName: contact.name,
          contactLastName: contact.lastName,
          contactSecondLastName: contact.secondLastName,
          contactDegree: contact.degree,
          contactDocumentNumber: contact.documentNumber,
          contactTelephone: contact.phone,
          contactMobilePhone: contact.mobilePhone,
          contactFax: contact.fax,
          contactEmail: contact.email,
        }),
      })
    )

    /**
     * Set Billing Data
     */
    const draftBillingAddress = yield select(getDraftBillingAddress)
    if (accountHolder) {
      const { postalCode, provinceName, municipalityName, townName, streetType, streetName, number, portal, stair, floor, door } =
        draftBillingAddress

      yield put(setOnlineInvoice(onlineInvoice))
      yield put(
        setBillingAccount({
          accountHolder,
          accountHolderSurnames,
          accountHolderDocumentNumber,
          accountHolderDocumentType,
          accountHolderLastName,
          accountHolderSecondLastName,
          accountIban,
          partialAccountIban,
        })
      )
      yield put(
        setBillingAddress({
          postalCode,
          birthdayDate, // Get just the date
          provinceName,
          municipalityName,
          townName,
          streetType: streetType.toUpperCase(),
          streetName,
          number,
          portal,
          stair,
          floor,
          door,
        })
      )
      // TODO: we need the billing protection service value from back
      if (spfServiceType) {
        birthdayDate ? yield put(addToppingToSelection(spfServiceType.service)) : yield put(removeToppings([spfServiceType.id]))
      }
      yield put(setStepCompleted(STEPS.topping))
      yield put(setStepCompleted(STEPS.billing))
      yield put(submitBillingStep()) // TODO: move all submit logic to saga. This is a provisional fix
    }
    yield put(setActiveStepDuplicate(-1))
    yield put(setActiveStep(-1))
    yield put(setIsLoading(false))
  } catch (e) {
    console.error(e)
    yield put(setIsLoading(false))
    yield put(errorFillFunnelWithDraft())
    yield put(resetContract())
    yield put(showModal({ modalType: MODAL_TYPES.draft }))
  }
}

function* draftOnlineChannelSaga({ payload: incompleteOrderId }) {
  const urlParams = new URLSearchParams(window.location.search)
  const tokenId = urlParams.get('incomplete_order_token')
  const referenceSegment = yield select(getReferenceSegment)
  const selectedProductArray = yield select(getSelectedRate)
  const selectedProduct = selectedProductArray[0]
  const customerData = yield select(getCustomerData)
  const token = yield select(getDraftToken)

  yield put(setDraftId(incompleteOrderId))
  const draftData = yield call(getIncompleteOrder, incompleteOrderId, token || tokenId)
  const customerDataUpdate = { ...customerData, retargeting: draftData?.type_retargeting }
  if (draftData?.detail === ERROR_RETARGETING) {
    yield put(
      showModal({
        modalType: MODAL_TYPES.errorRetargeting,
      })
    )
    return
  }

  yield put(setCustomer(customerDataUpdate))
  yield put(setAutosave(draftData?.type_draft))
  yield put(setDraftOnlineChannel(draftData.data))
  yield put(setLeadId(draftData.data.id))

  const { ownerStep, supplyStep, serviceStep, billingStep } = draftData.data

  /**
   * Set Owner Data
   */
  const {
    documentType,
    documentNumber,
    firstName,
    lastName,
    secondLastName,
    email,
    phone,
    checkGDPR1,
    checkGDPR2,
    checkGDPR3,
    retargeting,
    preferredLanguage,
    energyUse,
    cnae,
    contact,
  } = ownerStep

  if (contact?.id) {
    yield put(setOnBehalfContactId(`/middleware/contacts/${contact.id}`))
  }

  yield put(
    submitOwnerStep({
      idDocumentType: documentType,
      idDocumentNumber: documentNumber,
      name: firstName,
      lastName,
      secondLastName,
      email,
      phone,
      complexProfilePolicy: checkGDPR1,
      publicityPolicy: checkGDPR2,
      thirdPartyPolicy: checkGDPR3,
      retargeting,
      language: preferredLanguage,
      ...(contact && { ...contact, energyUse, cnae }),
    })
  )

  /**
   * New Supply Check
   */
  if (supplyStep?.electricity || supplyStep?.gas) {
    // const orders = supplyStep.electricity
    //   ? supplyStep.electricity.supplies.map(({ orderId, technicalInfo }) => ({ id: orderId, cups: technicalInfo.cups }))
    //   : supplyStep.gas.supplies.map(({ orderId, cups }) => ({ orderId, cups }))
    yield put(setIsNewSupply(supplyStep.electricity ? supplyStep.electricity.nnss : supplyStep.gas.nnss))
    // yield put(setOrders(orders))
    if (!selectedProduct?.flatRate) yield put(setStepEnabled(STEPS.supply))
    yield put(setStepCompleted(STEPS.supply))
    yield put(goNextStep())
  }

  /**
   * Multipoint
   */
  if (supplyStep && !selectedProduct?.flatRate) {
    const supplies = supplyStep.electricity?.supplies || supplyStep.gas?.supplies
    if (supplies.length >= 3) {
      yield put(setIsMultiPoint())
      let supplyPoints = []
      supplies.forEach((supply) => {
        supplyPoints = [...supplyPoints, { ...supply.technicalInfo, supplyAddress: supply.address }]
      })
      yield put(setSupplyPoint(supplyPoints))
    }
  }

  /**
   * Select Service
   */
  const services = yield select(getSingleToppings)
  if (serviceStep) {
    if (serviceStep.services.length) {
      yield put(setStepEnabled(STEPS.topping))
      const selectedServices =
        serviceStep.services
          ?.flatMap((selectedService) =>
            services.filter((service) => service.col === selectedService).map((service) => service.service)
          )
          ?.reduce((obj, item) => ({ ...obj, [item.id]: item }), {}) || {}
      const spfServiceType = services?.find((topping) => topping?.service?.type === 'SPF')

      if (spfServiceType) {
        const spfTopping =
          selectedServices && selectedServices[spfServiceType.id]
            ? { [spfServiceType.id]: selectedServices[spfServiceType.id] }
            : {}

        yield put(setToppings({ ...selectedServices, ...spfTopping }))
      } else {
        yield put(setToppings(selectedServices))
      }
      yield put(setStepCompleted(STEPS.topping))
    } else {
      yield put(setToppings({}))
    }

    yield put(goNextStep())
  }

  /**
   * Set Billing Data
   */
  if (billingStep) {
    const {
      accountHolder,
      accountHolderSurnames,
      partialAccountIban,
      accountHolderDocumentNumber,
      accountHolderDocumentType,
      accountHolderLastName,
      accountHolderSecondLastName,
      accountIban,
      onlineInvoice,
      eco,
      spfService,
      spfBirthdayDate,
    } = billingStep

    if (accountHolder) {
      yield put(setStepEnabled(STEPS.billing))
      yield put(
        setBillingAccount({
          accountHolder,
          accountHolderSurnames,
          partialAccountIban,
          accountHolderDocumentNumber,
          accountHolderDocumentType,
          accountHolderLastName,
          accountHolderSecondLastName,
          accountIban,
        })
      )
      yield put(setOnlineInvoice(onlineInvoice))

      if (referenceSegment === PYME && eco) {
        yield put(
          setProductWithAttribute(selectedProduct, {
            [selectedProduct.id]: { ...selectedProduct.attributes, 'product-eco': true },
          })
        )
      }

      const spfServiceType = services?.find((topping) => topping?.service?.type === 'SPF')

      if (spfService) {
        yield put(addToppingToSelection(spfServiceType.service))
        yield put(setBirthdayDate(new Date(spfBirthdayDate).toISOString()))
      }
      yield put(setStepCompleted(STEPS.billing))
      yield put(goNextStep())
    }
  }
  yield put(setActiveStepDuplicate(-1))
  yield put(setActiveStep(-1))
  yield put(successDraftOnlineChannel())
}

function* startDuplicateOrderSaga({ payload: { orderId, channelName, isScoringFail } }) {
  try {
    const channels = yield select(getAllChannels)
    const selectedChannel = yield select(getSelectedChannel) || {}

    if (!selectedChannel.id) {
      const channel = channels.find((channel) => channel.name === channelName)
      yield put(setSelectedChannel(channel))
      setItemSessionStorage(CHANNEL_SESSION_STORAGE_KEY, JSON.stringify(channel))
    }

    const dpOrder = yield call(putDuplicateOrder, orderId, isScoringFail)
    yield put(setActiveStepDuplicate(0))

    yield call(history.replace, `/new-contract?dp_order_id=${dpOrder['incomplete_order_id']}`)
    yield put(successDraftDuplicateOrder())
  } catch (error) {
    console.error(error)
    yield put(errorDraftDuplicateOrder())
    setNotificationState({
      message: 'notificationAlerts.setDuplicateOrderFailed',
      type: 'error',
      isVisible: true,
    })
  }
}

function* recoverDuplicateOrderSaga({ payload: dpOrderId }) {
  try {
    const orderData = yield call(getIncompleteOrder, dpOrderId)
    const { channel, company, pyme, crmId, orderId, supplyStep, referralCode } = orderData.data
    const leadId = orderData.lead.split('/middleware/leads/')[1]
    const channels = yield select(getAllChannels)
    const allChannels = yield call(getChannels)
    if (referralCode) {
      yield put(setReferralCode(referralCode))
    }
    const selectedChannel = channels?.length
      ? channels.find((ch) => ch.channelId === channel)
      : allChannels.find((ch) => ch.id === channel || ch.channel.id === channel)
    yield put(setSelectedChannel(selectedChannel))

    const referenceSegment = pyme ? PYME : RESIDENTIAL
    yield put(setReferenceSegment(referenceSegment))

    const companies = yield select(getCompanies)
    const selectedCompany = companies.find((comp) => comp.code === company)
    yield put(setCompany(selectedCompany))
    yield put(setSelectedCompany({ name: selectedCompany?.name, id: selectedCompany?.id }))
    yield put(setLeadId(leadId))
    yield put(setCRMId(crmId))
    yield put(setScoringSent())
    yield put(
      setOrders([
        {
          id: orderId,
          cups:
            supplyStep?.electricity?.supplies?.[0]?.technicalInfo?.cups || supplyStep?.gas?.supplies?.[0]?.technicalInfo?.cups,
        },
      ])
    )
    yield put(setDuplicateOrderDraft(orderData.data))
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  }
}

function* fillInOwnerDataFromRecoverDraftDuplicateOrderSaga() {
  try {
    //yield put(setIsLoading(true))
    const orderData = yield select(getDuplicateOrderDraft)
    const {
      documentType,
      documentNumber,
      firstName,
      lastName,
      secondLastName,
      email,
      phone,
      checkGDPR1,
      checkGDPR2,
      checkGDPR3,
      retargeting,
      preferredLanguage,
      energyUse,
      cnae,
      contact,
    } = orderData?.ownerStep

    if (contact?.id) {
      yield put(setOnBehalfContactId(`/middleware/contacts/${contact.id}`))
    }

    yield put(
      submitOwnerStepDuplicate({
        idDocumentType: documentType,
        idDocumentNumber: documentNumber,
        name: firstName,
        lastName,
        secondLastName,
        email,
        phone,
        complexProfilePolicy: checkGDPR1,
        publicityPolicy: checkGDPR2,
        thirdPartyPolicy: checkGDPR3,
        retargeting,
        language: preferredLanguage,
        ...(contact && { ...contact, energyUse, cnae }),
      })
    )

    yield put(setStepDisabled(STEPS.owner))
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  }
}

function* fillInProductSelectionFromRecoverDraftDuplicateOrderSaga() {
  try {
    const { rateStep, billingStep, serviceStep, supplyStep } = yield select(getDuplicateOrderDraft)
    const selectedChannel = yield select(getSelectedChannel)
    const selectedCompany = yield select(getCompany)
    const referenceSegment = yield select(getReferenceSegment)

    // TODO: add parameter to pass pyme referenceSegment
    const allProducts = yield call(getProducts, { channel: selectedChannel.id, company: selectedCompany, referenceSegment })
    const formattedProducts = allProducts.map((product) => ({ ...product, ...getEntityLangKeys(product.name) }))

    // Group products by family
    const products = formattedProducts.filter((product) => !product.family)
    const productsToGroup = formattedProducts.filter((product) => product.family)
    const uniqFamilyIds = [...new Set(productsToGroup.map((product) => product.family))]

    uniqFamilyIds.forEach((familyId) => {
      const productsInFamily = productsToGroup
        .filter((product) => product.family === familyId)
        .sort((product) => (product.energyType === ELECTRICITY ? -1 : 0))

      if (productsInFamily.length > 1) {
        products.push({
          id: familyId,
          name: productsInFamily[0].name,
          title: productsInFamily[0].title,
          subtitle: productsInFamily[0].subtitle,
          energyType: BOTH,
          subProducts: productsInFamily,
          flatRate: productsInFamily[0].flatRate,
          image: productsInFamily[0].image,
        })
        products.push(...productsInFamily)
      } else {
        products.push(productsInFamily[0])
      }
    })
    yield put(setProducts(products))

    if (rateStep?.product?.length === 1) {
      const { eco } = billingStep
      const selectedProduct = products.find((product) => product.id === rateStep.product[0])
      yield put(setProductWithAttribute(selectedProduct, { [selectedProduct.id]: { duration: '12 meses', online: false, eco } }))
    } else if (rateStep?.product?.length === 2) {
      // This means is dual product
      const dualProducts = products.filter((product) => !!product.subProducts)
      const selectedDualProduct = dualProducts.find(
        (dualProduct) =>
          dualProduct.subProducts[0].id === rateStep.product[0] || dualProduct.subProducts[0].id === rateStep.product[1]
      )
      if (selectedDualProduct) {
        let attributes = selectedDualProduct.subProducts.map((subproduct) => ({
          [subproduct.id]: {
            'product-duration': subproduct?.duration,
            'product-online': subproduct?.online,
            'product-eco': subproduct?.eco,
          },
        }))
        attributes = { ...attributes[0], ...attributes[1] }
        yield put(setProductWithAttribute(selectedDualProduct, { ...attributes }))
      } else {
        const selectedProduct = rateStep.product.map((product) => products.find((prod) => prod.id === product))
        let attributes = selectedProduct.map((subproduct) => ({
          [subproduct.id]: {
            'product-duration': subproduct?.duration,
            'product-online': subproduct?.online,
            'product-eco': subproduct?.eco,
          },
        }))
        attributes = { ...attributes[0], ...attributes[1] }
        yield put(setProductWithAttribute(selectedProduct, { ...attributes }))
      }
    } else {
      yield put(clearSelectedProduct())
    }

    if (supplyStep?.electricity || supplyStep?.gas || serviceStep?.services?.length > 0) {
      yield put(setStepCompleted(STEPS.rate))
      if (supplyStep?.electricity || supplyStep?.gas) {
        yield put(setStepEnabled(STEPS.supply))
      } else {
        yield put(setStepDisabled(STEPS.supply))
        yield put(setStepCompleted(STEPS.supply))
        yield put(setIsOnlyToppings(true))
      }
      yield put(goNextStepDuplicate())
    } else {
      yield put(successRecoverDraftDuplicateOrder())
    }
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  }
}

function* fillInSupplyPointFromRecoverDraftDuplicateOrderSaga() {
  try {
    const { supplyStep, billingStep } = yield select(getDuplicateOrderDraft)

    if (supplyStep?.electricity || supplyStep?.gas) {
      yield put(setIsNewSupply(!!(supplyStep?.electricity?.nnss || supplyStep?.gas?.nnss)))
      yield put(setStepEnabled(STEPS.supply))
    } else {
      yield put(setStepDisabled(STEPS.supply))
      yield put(setIsOnlyToppings(true))
    }
    yield put(setBillingAddress(billingStep?.address))
    yield put(
      setSupplyAddress(
        supplyStep?.electricity?.supplies?.[0]?.address || supplyStep?.gas?.supplies?.[0]?.address || billingStep?.address
      )
    )
    if (billingStep) {
      yield put(setStepCompleted(STEPS.supply))
      yield put(goNextStepDuplicate())
    } else {
      yield put(successRecoverDraftDuplicateOrder())
    }
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  }
}

function* fillInServiceSelectionFromRecoverDraftDuplicateOrderSaga() {
  try {
    const { serviceStep } = yield select(getDuplicateOrderDraft)
    const selectedChannel = yield select(getSelectedChannel)
    const selectedCompany = yield select(getCompany)
    const referenceSegment = yield select(getReferenceSegment)

    let toppings = yield call(getAllToppings, { selectedChannel, company: selectedCompany?.code, referenceSegment } || {})

    toppings =
      toppings.map((topping) => ({
        ...topping,
        service: { ...topping, ...getEntityLangKeys(topping.name) },
      })) || []

    yield put(setAllToppings([{ toppings }]))

    if (serviceStep) {
      if (serviceStep.services.length) {
        yield put(setStepEnabled(STEPS.topping))
        const selectedServices =
          serviceStep.services
            ?.flatMap((selectedService) =>
              toppings.filter((service) => service.id === selectedService).map((service) => service.service)
            )
            ?.reduce((obj, item) => ({ ...obj, [item.id]: item }), {}) || {}
        const spfServiceType = toppings?.find((topping) => topping?.service?.type === 'SPF')

        if (spfServiceType) {
          const spfTopping =
            selectedServices && selectedServices[spfServiceType.id]
              ? { [spfServiceType.id]: selectedServices[spfServiceType.id] }
              : {}

          yield put(setToppings({ ...selectedServices, ...spfTopping }))
        } else {
          yield put(setToppings(selectedServices))
        }
      } else {
        yield put(setToppings({}))
      }
      yield put(setStepEnabled(STEPS.topping))
      yield put(setStepCompleted(STEPS.topping))
      yield put(goNextStepDuplicate())
    } else {
      yield put(successRecoverDraftDuplicateOrder())
    }
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  }
}

function* fillInBillingDataFromRecoverDraftDuplicateOrderSaga() {
  try {
    const { billingStep, supplyStep } = yield select(getDuplicateOrderDraft)
    const voidSupplyAddress = !supplyStep?.electricity && !supplyStep?.gas
    const toppings = (yield select(getToppings))[0]?.toppings

    if (billingStep) {
      const {
        accountHolder,
        accountHolderSurnames,
        accountHolderDocumentNumber,
        accountHolderDocumentType,
        accountHolderLastName,
        accountHolderSecondLastName,
        accountIban,
        onlineInvoice,
        spfService,
        spfBirthdayDate,
        addressDifferentCheck,
        address,
        partialAccountIban,
      } = billingStep

      if (accountHolder) {
        yield put(
          setBillingAccount({
            accountHolder,
            accountHolderSurnames,
            accountHolderDocumentNumber,
            accountHolderDocumentType,
            accountHolderLastName,
            accountHolderSecondLastName,
            accountIban,
            partialAccountIban,
          })
        )
        yield put(setBillingAddress(address))
        yield put(
          setDraftLead({
            accountHolder,
            accountHolderSurnames,
            partialAccountIban,
            accountHolderDocumentNumber,
            accountHolderDocumentType,
            accountHolderLastName,
            accountHolderSecondLastName,
            accountIban,
            ...(spfBirthdayDate && { birthdayDate: { date: spfBirthdayDate } }),
            addressDifferentCheck,
            ...((addressDifferentCheck || voidSupplyAddress) && { billingAddress: address }),
          })
        )
        yield put(setOnlineInvoice(onlineInvoice))

        const spfServiceType = toppings?.find((topping) => topping?.service?.type === 'SPF')

        if (spfService) {
          yield put(addToppingToSelection(spfServiceType.service))
          yield put(setBirthdayDate(new Date(spfBirthdayDate).toISOString()))
        }

        yield put(setStepCompleted(STEPS.billing))
        //yield put(fetchLead())
        yield put(goNextStepDuplicate())
      }
    }
    // yield put(setIsLoading(false))
  } catch (e) {
    console.error(e)
    yield put(errorDraftDuplicateOrder())
  } finally {
    yield put(successRecoverDraftDuplicateOrder())
  }
}

function* clearDraftFullSaga() {
  yield put(clearDraftOrder())
  yield put(clearDraftLead())
  yield put(clearDraftUserId())
  yield put(clearDraftChannelId())
  yield put(clearDraftChannelOfferId())
  yield put(clearDraft())
  yield put(clearFillFunnelWithDraft())
  yield put(clearDraftOnlineChannel())
  yield put(clearDuplicateOrderDraft())
  yield put(clearDraftDuplicateOrder())
  yield put(clearRecoverDraftDuplicateOrder())
  yield put(clearSendDraftData())
  yield put(clearDraftId())
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.START_DRAFT], recoverDraftSaga),
    takeLatest([actionTypes.START_FILL_FUNNEL_WITH_DRAFT], fillFunnelWithDraftSaga),
    takeLatest([actionTypes.START_DRAFT_ONLINE_CHANNEL], draftOnlineChannelSaga),
    takeLatest([actionTypes.START_SEND_DRAFT_DATA], recoverSendDraftSaga),
    takeLatest([actionTypes.START_DRAFT_DUPLICATE_ORDER], startDuplicateOrderSaga),
    takeLatest([actionTypes.START_RECOVER_DRAFT_DUPLICATE_ORDER], recoverDuplicateOrderSaga),
    takeLatest(
      [actionTypes.FILL_IN_OWNER_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER],
      fillInOwnerDataFromRecoverDraftDuplicateOrderSaga
    ),
    takeLatest(
      [actionTypes.FILL_IN_PRODUCT_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER],
      fillInProductSelectionFromRecoverDraftDuplicateOrderSaga
    ),
    takeLatest(
      [actionTypes.FILL_IN_SUPPLY_POINT_FROM_RECOVER_DRAFT_DUPLICATE_ORDER],
      fillInSupplyPointFromRecoverDraftDuplicateOrderSaga
    ),
    takeLatest(
      [actionTypes.FILL_IN_SERVICE_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER],
      fillInServiceSelectionFromRecoverDraftDuplicateOrderSaga
    ),
    takeLatest(
      [actionTypes.FILL_IN_BILLING_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER],
      fillInBillingDataFromRecoverDraftDuplicateOrderSaga
    ),
    takeLatest([actionTypes.CLEAR_DRAFT_FULL], clearDraftFullSaga),
  ])
}
