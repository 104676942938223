import palette from '../base/palette'

const typography = {
  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        color: palette.neutral.main,
      },
    },
  },
}

export default typography
