import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import Filters from 'ui/components/filters'
import Typography from 'ui/components/typography'
import Products from 'ui/components/products'
import Link from 'ui/components/link'
import Rate from 'ui/components/rate'
import useStyles from './styles'

import { getIsSelectionNotAllowed } from 'services/toppings/selectors'

import ProductAttributes from 'ui/components/productAttributes'
import { getSelectedTenure } from 'services/customer/selectors'
import { NEWCO } from '@zatopek/core/src/utils/constants'

const SelectRate = ({
  services,
  selectedService,
  setService,
  rates,
  rateSelected,
  onChangeRate,
  onContinue,
  onFlatRateCalculate,
  onSkipStep,
  showRequiredAlert,
  onSimulatorClick,
  loadingSimulatorLink,
  isDisabled,
  openModal,
  attributes,
  setAttributes,
  productAttributes,
  isOnlineChannel,
  referenceSegment,
  isPortability,
  energyDisallowedMessage,
  hasCalculatedCost,
  company,
}) => {
  const isSelectionNotAllowed = useSelector(getIsSelectionNotAllowed)
  const tenureProducts = useSelector(getSelectedTenure)
  const [flatRateCheck, setFlatRateCheck] = useState(false)

  const { t } = useTranslation()
  const classes = useStyles()
  let ratesList = rates
  const getProductsLines = (rateArray) => {
    let productLines = []
    rateArray.forEach((rate) => {
      if (rate.subProducts) {
        rate.subProducts.forEach((subProduct) => {
          productLines.push(subProduct)
        })
      } else {
        productLines.push(rate)
      }
    })
    return productLines
  }
  let productLines = getProductsLines(rateSelected)

  if (tenureProducts && tenureProducts.variants && tenureProducts.variants.length > 0) {
    // eslint-disable-next-line array-callback-return
    const fitleredRates = rates.filter((rate) => {
      if (tenureProducts.variants) {
        // eslint-disable-next-line array-callback-return
        return !tenureProducts?.variants.some((tenureProduct) => {
          if (rate?.variants) {
            return rate?.variants.some((rateProduct) => {
              return rateProduct?.externalCode === tenureProduct.productId
            })
          }
          if (rate?.subProducts) {
            //eslint-disable-next-line array-callback-return
            return rate?.subProducts.some((rateProduct) => {
              if (rateProduct?.variants) {
                return rateProduct?.variants.some((rateProductVariant) => {
                  return rateProductVariant?.externalCode === tenureProduct.productId
                })
              }
            })
          }
        })
      }
    })
    ratesList = fitleredRates
  }

  useEffect(() => {
    if (Object.keys(services ?? {}).length === 1 && (!selectedService || !(selectedService in services))) {
      setService(Object.keys(services)[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, selectedService])

  useEffect(() => {
    const flatRate = rateSelected?.find((rate) => rate?.flatRate)
    if (flatRate) {
      if (!hasCalculatedCost(flatRate?.energyType)) {
        setFlatRateCheck(true)
      } else {
        setFlatRateCheck(false)
      }
    } else {
      setFlatRateCheck(false)
    }
  }, [rateSelected, hasCalculatedCost])

  return (
    <Box component="section">
      {energyDisallowedMessage && (
        <Typography variant="h6" color="textPrimary" paragraph>
          {t(energyDisallowedMessage)}
        </Typography>
      )}

      <Typography variant="h6" color="textPrimary" paragraph>
        {t('wichEnergy')}
      </Typography>

      <Filters fullWidth options={services} disabled={isOnlineChannel} selected={selectedService} setOption={setService} />

      {rateSelected
        ? productLines?.map((product, index) => (
            <Box mt={4} key={`attributes-${product.id}`}>
              <ProductAttributes
                schema={product.productAttributes}
                values={attributes[product.id]}
                setValues={(values) => setAttributes(values, product.id)}
                referenceSegment={referenceSegment}
                productAttributes={rateSelected[index]?.productAttributes}
              />
            </Box>
          ))
        : null}

      <Box mt={4}>
        {rates.length ? (
          <Typography variant="h6" color="textPrimary">
            {t('allYourOptions')}
          </Typography>
        ) : null}
        <Products
          products={ratesList}
          selectedProducts={rateSelected}
          onChangeProduct={onChangeRate}
          openModal={openModal}
          onSimulatorClick={onSimulatorClick}
          loadingSimulatorLink={loadingSimulatorLink}
          Component={Rate}
          onFlatRateCalculate={onFlatRateCalculate}
        />
        {showRequiredAlert && (
          <Alert icon={false} severity="warning" className={classes.alert}>
            <Typography variant="basic">{t('form.errors.optionRequired')}</Typography>
          </Alert>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isOnlineChannel || (isPortability && company?.code !== NEWCO) ? 'flex-end' : 'space-between'}
          mb={4}
        >
          {!isOnlineChannel && (!isPortability || company?.code === NEWCO) && (
            <Link component="button" variant="link" color="secondary" onClick={onSkipStep} disabled={isSelectionNotAllowed}>
              {t('common.contractOnlyMaintenance')}
            </Link>
          )}
          <Button variant="contained" color="secondary" onClick={() => onContinue(rateSelected)} disabled={isDisabled}>
            {flatRateCheck ? t('common.check') : t('common.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectRate
