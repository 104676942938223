import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as actionTypes from './action-types'
import { setToppings, setAllToppings, setVirtualBattery, clearVirtualBattery } from './actions'
import { getAllToppings, getAllToppingsAlternatives, getVirtualBaterry } from './api'
import { getSelectedSingleToppings, getAllToppings as getToppings } from './selectors'
import { MODAL_TYPES } from 'services/modal/constants'
import { showModal } from 'services/modal/actions'
import { getActiveStep } from 'services/stepper/selectors'

import getEntityLangKeys from 'utils/getEntityLangKeys'
import {
  getSelectedTenure,
  getUserData,
  getIsNewSupply,
  getCustomerData,
  getCompany,
  getSupplyAddress,
  getCustomer,
  getBillingAddress,
} from 'services/customer/selectors'
import { getReferenceSegment, getSelectedChannel } from 'services/operator/selectors'
import { getRates } from 'services/products/selectors'
import { completeStepsOnlineChannel } from 'services/onlineChannel/actions'
import { getLeadId } from 'services/lead/selectors'
import { isNotEmpty } from 'utils/commons'

function* addToppingToSelectionSaga({ payload: topping }) {
  const selectedToppings = yield select(getSelectedSingleToppings)
  const { id, ...rest } = topping

  yield put(
    setToppings({
      ...selectedToppings,
      [id]: rest,
    })
  )
}

function* removeToppingsSaga({ payload: toppingIds }) {
  let selectedToppings = yield select(getSelectedSingleToppings)
  if (selectedToppings) {
    toppingIds.forEach((toppingId) => {
      if (selectedToppings[toppingId]) {
        delete selectedToppings[toppingId]
      }
    })

    yield put(setToppings(selectedToppings))
  }
}

// TODO: Refactor when we implement getAllToppingsOnlineChannel to all the channels
function* fetchToppingsSaga({ payload: { referenceSegment: referenceSegmentParam, params } }) {
  const referenceSegment = referenceSegmentParam ? referenceSegmentParam : yield select(getReferenceSegment)
  const userData = yield select(getUserData)
  const selectedTenure = yield select(getSelectedTenure)
  const selectedChannel = yield select(getSelectedChannel)
  const isNewSupply = yield select(getIsNewSupply)
  const company = yield select(getCompany)
  const products = yield select(getRates)

  let data = []
  let serviceTenure = []

  if (userData?.tenure) {
    serviceTenure = selectedTenure.services || []
  }

  const portability = Boolean(
    selectedTenure?.services?.length &&
      selectedTenure?.company &&
      company?.code &&
      selectedTenure.company.toLowerCase() !== company.code.toLowerCase()
  )

  if (portability) {
    const altServices = yield call(
      getAllToppingsAlternatives,
      { selectedTenure, referenceSegment, company: company ? company.code : '' } || {}
    )

    for (const idService in altServices) {
      altServices[idService].serviceAlternatives.forEach((elm) => {
        elm.isRecommended = true
        elm.portability = true
        elm.tenureServiceId = selectedTenure.services.find((service) => service.type === elm.type)?.id || null
        serviceTenure.push(elm)
        data.push(elm)
      })
    }
  }
  const allServices = yield call(
    getAllToppings,
    { selectedChannel, serviceTenure, referenceSegment, isNewSupply, company: company ? company.code : '' } || {}
  )
  allServices.forEach((elm) => {
    if (portability) {
      const tenureService = selectedTenure.services.find((service) => service.type === elm.type)?.id || null
      elm.portability = Boolean(tenureService)
      elm.tenureServiceId = tenureService
    } else {
      elm.portability = false
      elm.tenureServiceId = null
    }
    data.push(elm)
  })
  const toppings =
    data.slice().map((topping) => ({
      ...topping,
      service: { ...topping, ...getEntityLangKeys(topping.name) },
    })) || []

  yield put(setAllToppings([{ toppings }]))

  if (params?.sva) {
    yield put(completeStepsOnlineChannel({ products, toppings, params }))
  }
}

function* fetchVirtualBattery() {
  const userData = yield select(getUserData)
  const customerData = yield select(getSelectedTenure)
  const customerDocument = yield select(getCustomerData)
  const docNumber = userData?.documentNumber || customerData?.idDocumentNumber || customerDocument?.idDocumentNumber
  const leadId = yield select(getLeadId)
  const allToppings = yield select(getToppings)
  const selectedToppings = yield select(getSelectedSingleToppings)
  const customer = yield select(getCustomer)
  const isOnlineChannel = yield select(getSelectedChannel)
  const activeStep = yield select(getActiveStep)

  const isBatterySelected = selectedToppings
    ? Object.values(selectedToppings).some((topping) => topping.externalCode === '2-HUCHA-1')
    : false

  let selfConsumptionTypeCode = null
  if (customer && customer.supplyPoints && customer.supplyPoints.length > 0) {
    selfConsumptionTypeCode = customer?.supplyPoints[0]?.selfConsumptionType
  } else if (customer && customer.selfconsumptionType) {
    selfConsumptionTypeCode = customer?.selfconsumptionType
  }
  const virtualBatteryExternalCode = allToppings[0].toppings.find(
    (topping) =>
      topping.id === '17a57e2a-fb05-4d55-87c7-46f11e8cc12a' ||
      topping.id === 'aa0efb53-56da-4fb1-89a0-b323fad47ff1' ||
      topping.externalCode === '2-HUCHA-1'
  )?.externalCode
  const supplyAddress = yield select(getSupplyAddress)
  const billingAddress = yield select(getBillingAddress)

  const address = {
    postalCode: supplyAddress?.postalCode || billingAddress?.postalCode || customerData?.postalCode,
    provinceName: supplyAddress?.provinceName || billingAddress?.provinceName || customerData?.provinceName,
    municipalityName: supplyAddress?.municipalityName || billingAddress?.municipalityName || customerData?.municipalityName,
    townName: supplyAddress?.townName || supplyAddress?.addressTown || billingAddress?.townName || customerData?.addressTown,
    streetType: supplyAddress?.streetType,
    streetName:
      supplyAddress?.streetName || supplyAddress?.addressStreet || billingAddress?.streetName || customerData?.addressStreet,
    number: supplyAddress?.number || supplyAddress?.addressNumber || billingAddress?.number || customerData?.addressNumber,
    portal: supplyAddress?.portal || supplyAddress?.addressPortal || billingAddress?.portal,
    stair: supplyAddress?.stair || billingAddress?.stair || customerData?.addressStaircase,
    floor: supplyAddress?.floor || supplyAddress?.addressFloor || billingAddress?.floor || customerData?.addressFloor,
    door: supplyAddress?.door || supplyAddress?.addressDoor || billingAddress?.door || customerData?.addressDoor,
  }

  if (
    docNumber &&
    virtualBatteryExternalCode &&
    isNotEmpty(address) &&
    leadId &&
    (supplyAddress || billingAddress || customerData)
  ) {
    const virtualBattery = yield call(getVirtualBaterry, {
      docNumber,
      leadId,
      virtualBatteryExternalCode,
      address,
      selfConsumptionTypeCode,
    })
    if (virtualBattery && !supplyAddress && billingAddress && isOnlineChannel && activeStep === 4 && isBatterySelected) {
      if (virtualBattery.codResult && virtualBattery.codResult !== '00' && virtualBattery.codResult !== '02') {
        yield put(
          showModal({
            modalType: MODAL_TYPES.warningModal,
            modalData: {
              titleKey: 'form.errors.virtualBatteryTitle',
              textKey: 'form.errors.virtualBattery04',
            },
          })
        )
      } else if (virtualBattery.codResult && virtualBattery.codResult === '02') {
        yield put(
          showModal({
            modalType: MODAL_TYPES.warningModal,
            modalData: {
              titleKey: 'form.errors.virtualBatteryTitle',
              textKey: 'form.errors.virtualBattery05',
            },
          })
        )
      }
    }

    yield put(setVirtualBattery(virtualBattery))
  } else {
    yield put(clearVirtualBattery())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.ADD_TO_SELECTION], addToppingToSelectionSaga),
    takeLatest([actionTypes.REMOVE], removeToppingsSaga),
    takeLatest([actionTypes.FETCH], fetchToppingsSaga),
    takeLatest([actionTypes.FETCH_VIRTUAL_BATTERY], fetchVirtualBattery),
  ])
}
