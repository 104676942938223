const set = (key, value) => {
  try {
    sessionStorage.setItem(key, value)
  } catch (err) {
    console.error(err)
    throw new Error(`Error setting ${key} on localStorage`)
  }
}

const get = (key) => {
  try {
    return sessionStorage.getItem(key)
  } catch (err) {
    console.error(err)
    throw new Error(`Error getting ${key} on localStorage`)
  }
}

const remove = (key) => {
  try {
    return sessionStorage.removeItem(key)
  } catch (err) {
    console.error(err)
    throw new Error(`Error removing ${key} on localStorage`)
  }
}

export { set, get, remove }
