import { createStore, applyMiddleware, compose } from 'redux'
import createRootReducer from './rootReducer'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(applyMiddleware(routerMiddleware(history)), applyMiddleware(sagaMiddleware))

const store = createStore(createRootReducer(history), enhancer)

sagaMiddleware.run(rootSaga)

export default store
