import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllTemplates } from 'services/templates/api'

import { InputLabel, MenuItem, FormControl, Select, Box } from '@material-ui/core'

import useStyles from './styles'

const DEFAULT_TEMPLATE = 'default'

const TemplateSelector = ({ templateType, onTemplateChange, onLoadingChange }) => {
  const { t } = useTranslation()
  const [templates, setTemplates] = useState([])
  const [selectedTemplateId, setSelectedTemplateId] = useState('')

  const classes = useStyles()

  const fetchData = () => {
    onLoadingChange && onLoadingChange(true)
    setTemplates([])
    getAllTemplates({ type: templateType })
      .then((response) => {
        const templates = response.data
        setTemplates(templates)
        const defaultTemplateId = templates?.find((template) => template.default)?.id
        setSelectedTemplateId(defaultTemplateId || DEFAULT_TEMPLATE)
      })
      .catch((e) => {
        setSelectedTemplateId(DEFAULT_TEMPLATE)
        // setSnackMessage(t('snackbar.errors.generic'), 'error')
      })
      .finally(() => {
        onLoadingChange && onLoadingChange(false)
      })
  }

  const handleTemplateChange = (templateId) => {
    setSelectedTemplateId(templateId)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedTemplateId !== '') {
      const templateId = selectedTemplateId === DEFAULT_TEMPLATE ? null : selectedTemplateId
      onTemplateChange(templateId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId])

  return (
    <Box className={classes.root}>
      <FormControl fullWidth size="small">
        <InputLabel id="temaplate-selector-label">{t('settings.backofficeTemplates.form.label')}</InputLabel>
        <Select
          id="templateSelector"
          labelId="temaplate-selector-label"
          value={selectedTemplateId}
          onChange={(e) => handleTemplateChange(e.target.value)}
        >
          <MenuItem value={DEFAULT_TEMPLATE}>{t('settings.backofficeTemplates.form.defaultTemplate')}</MenuItem>
          {templates &&
            templates.map(({ id, name }) => {
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default TemplateSelector
