import { all, call } from 'redux-saga/effects'
import campaignsSagas from 'services/campaigns/sagas'
import leadSagas from 'services/lead/sagas'
import orderSagas from 'services/order/sagas'
import productsSagas from 'services/products/sagas'
import operatorSagas from 'services/operator/sagas'
import customerSagas from 'services/customer/sagas'
import companySagas from 'services/company/sagas'
import stepperSagas from 'services/stepper/sagas'
import toppingsSaga from 'services/toppings/sagas'
import analyticsSaga from 'services/analytics/sagas'
import backofficeSagas from 'services/backoffice/sagas'
import controlSagas from 'services/control/sagas'
import callMeBackSagas from 'services/callMeBack/sagas'
import onlineChannelSagas from 'services/onlineChannel/sagas'
import draftSagas from 'services/draftCopy/sagas'
import templateSagas from 'services/templates/sagas'
import fidelizationSagas from 'services/fidelization/sagas'
import invoiceUploadSagas from 'services/invoice/sagas'
import contractStatusSaga from 'services/contractStatus/sagas'
import portabilitySaga from 'services/portability/sagas'

function* allSagas() {
  try {
    yield all([
      leadSagas(),
      productsSagas(),
      campaignsSagas(),
      companySagas(),
      orderSagas(),
      operatorSagas(),
      customerSagas(),
      stepperSagas(),
      toppingsSaga(),
      analyticsSaga(),
      backofficeSagas(),
      controlSagas(),
      callMeBackSagas(),
      onlineChannelSagas(),
      draftSagas(),
      templateSagas(),
      fidelizationSagas(),
      invoiceUploadSagas(),
      contractStatusSaga(),
      portabilitySaga(),
    ])
  } catch (e) {
    console.error(e)
  }
}

export default function* rootSaga() {
  try {
    yield call(allSagas)
  } catch (e) {
    console.error(e)
  }
}
