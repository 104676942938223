import palette from '../base/palette'

const icons = {
  overrides: {
    MuiSvgIcon: {
      root: {
        '.MuiListItemIcon-root &': {
          marginLeft: 'auto',
        },
      },
      colorAction: {
        color: palette.primary.main,
      },
    },
  },
}

export default icons
