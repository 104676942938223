import { get, postError } from '../client'

// TODO: Implement the request to services instead of topping_scenarios for the other channels
// DEPRECATED
// export const getAllToppings = async ({
//   energy,
//   newCustomer,
//   existingCustomerWithElectricity,
//   existingCustomerWithGas,
//   selectedChannel,
//   serviceTenure = [],
// }) => {
//   const searchParams = new URLSearchParams([
//     ...energy.split(',').map((ordering) => ['ordering[]', ordering]),
//     ...serviceTenure.map((service) => ['services.id[]', service.id]),
//     ['newCustomer', newCustomer],
//     ['existingCustomerWithElectricity', existingCustomerWithElectricity],
//     ['existingCustomerWithGas', existingCustomerWithGas],
//     ['channelOffers.id[]', selectedChannel.id],
//   ]).toString()

//   const response = await get(`topping_scenarios?${searchParams}`)

//   return response
// }

export const getAllToppings = async ({ selectedChannel, serviceTenure, referenceSegment, params, isNewSupply, company }) => {
  const referenceSegmentString = `&${referenceSegment}=true`
  const isNewSupplyString = isNewSupply ? `&availableForNewSupplies=true` : ''
  const selectedCompany = company ? `&company.code=${company.toLowerCase()}` : '&company.code=gns'
  let referenceCodes = params?.sva ? params.sel.split('-') : []
  let selectedTenure = serviceTenure || []

  const queryParams = new URLSearchParams([
    ...selectedTenure.map((service) => ['tenure[]', service?.id]),
    ...referenceCodes.map((sva) => ['variants.referenceCode[]', sva]),
  ])

  return await get(
    `channel_offers/${selectedChannel?.id}/services?enabled=true&${queryParams}${selectedCompany}${referenceSegmentString}${isNewSupplyString}`
  )
}

export const getAllToppingsAlternatives = async ({ selectedTenure, referenceSegment, company }) => {
  const referenceSegmentString = `&${referenceSegment}=true`
  const selectedCompany = company ? `&alt.company.code=${company.toLowerCase()}` : ''

  const searchParams = selectedTenure?.services
    ? new URLSearchParams([...selectedTenure.services.map((service) => ['id[]', service?.productId])]).toString()
    : ''

  return await get(`services/alternatives?${searchParams}${selectedCompany}${referenceSegmentString}`)
}

export const getVirtualBaterry = async ({ docNumber, leadId, virtualBatteryExternalCode, address, selfConsumptionTypeCode }) => {
  const params = {
    docNumber,
    leadId,
    virtualBatteryExternalCode,
    address,
    selfConsumptionTypeCode,
  }
  return await postError(`virtualBatteryAllowed`, params)
}
