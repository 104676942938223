import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogTitle, Box } from '@material-ui/core'
import Typography from 'ui/components/typography'

const CancelOrder = ({ children }) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogTitle disableTypography id="customized-dialog-title">
        <Typography variant="h4">{t('modalCancelOrder.title')}</Typography>
        <Box mt={2}>
          <Typography variant="basic">{t('modalCancelOrder.description')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </>
  )
}

export default CancelOrder
