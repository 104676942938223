import { reduce } from 'lodash'
import { getCupsCheck } from 'services/customer/api'
import { getVariants } from 'services/products/api'
import { getSelectedProductId } from 'services/products/business'
import { BOTH, ELECTRICITY, GAS, ORDER_FILTERS } from './constants'

// TODO: remove this function (is already in core)
export const pick = (object, ...props) =>
  Object.fromEntries(
    Object.entries(object).filter(([key, value]) => props.flat().includes(key) && value !== '' && value !== null)
  )

export const formatAddress = (
  floor,
  door,
  streetType,
  streetName,
  number,
  postalCode,
  townName,
  provinceName,
  municipalityName,
  portal,
  stair
) => {
  if (
    floor === undefined &&
    door === undefined &&
    streetType === undefined &&
    streetName === undefined &&
    number === undefined &&
    postalCode === undefined &&
    townName === undefined &&
    provinceName === undefined
  ) {
    return undefined
  }

  const floorPart = floor ? `, ${floor}º ${door}` : ''
  const streetTypeFormat = `${streetType !== '' && streetType !== null && streetType !== undefined ? `${streetType}/` : ''}`
  const streetLine = `${streetTypeFormat} ${streetName || ''} ${number || ''} ${portal || ''} ${stair || ''}${floorPart}`
  const townLine = `${postalCode || ''}${townName ? ` ${townName}` : ''}${municipalityName ? ` ${municipalityName}` : ''}${
    provinceName ? `, ${provinceName}` : ''
  }`
  const address = [streetLine, townLine].join('\n')
  return address
}

export const formatName = (customerFirstName, customerLastName, customerSecondLastName) => {
  if (customerFirstName === undefined && customerLastName === undefined) {
    return undefined
  }
  return [customerFirstName, customerLastName, customerSecondLastName].join(' ')
}

export const formatNumber = (number) => {
  let formatNumber = number
  const floatValues = /^(?!0\d)\d*(,\d+)?$/
  if (floatValues.test(formatNumber)) {
    const hasSpanishDecimals = typeof formatNumber === 'string' && formatNumber.includes(',')
    if (hasSpanishDecimals) {
      formatNumber = formatNumber.replace(',', '.')
    }
    return parseFloat(formatNumber)
  }
  return false
}

export const formatDate = (date) => {
  const d = new Date(date)
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
}

export const formatDateTime = (date) => {
  const d = new Date(date)
  var datestring =
    ('0' + d.getDate()).slice(-2) +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    d.getFullYear() +
    ' ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)

  return datestring
}

export const formatOrderLinesData = (products) => {
  return products.map((product) => {
    return {
      info: {
        ...product,
        // type: TOPPING_TYPES[product.type] || product.type,
        orderDate: formatDate(product.orderDate),
        lastStateChange: formatDate(product.lastStateChange),
      },
    }
  })
}

export const formatOrdersData = (orders) => {
  return orders.map((order) => {
    return {
      ...order,
      titular: formatName(order.customerFirstName, order.customerLastName, order.customerSecondLastName),
      status: stateToStatusOrders(order.orderStates),
      address: formatAddress(
        order.billingFloor,
        order.billingDoor,
        order.billingStreetType,
        order.billingStreetName,
        order.billingNumber,
        order.billingPostalCode,
        order.billingTownName,
        order.billingProvinceName,
        order.billingMunicipalityName,
        order.billingPortal,
        order.billingStair
      ),
      supplyAddress: formatAddress(
        order.supplyFloor,
        order.supplyDoor,
        order.supplyStreetType,
        order.supplyStreetName,
        order.supplyNumber,
        order.supplyPostalCode,
        order.supplyTownName,
        order.supplyProvinceName,
        order.supplyMunicipalityName,
        order.supplyPortal,
        order.supplyStair
      ),
    }
  })
}

export const stateToStatusOrders = (orderStates, inProcess = false) => {
  const [state] = Object.keys(orderStates || {})
  return (
    (inProcess && 'inprogress') ||
    reduce(ORDER_FILTERS, (finalState, states, filter) => finalState || (states.includes(state) && filter), null) ||
    state
  )
}

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const onlyNumbsString = (str) => {
  return str.replace(/[^0-9]/g, '')
}

export const getErrorMessage = async (e) => await e.json()

export const checkCups = async (cups, userDocumentNumber, selectedCompany) => {
  let resCode = '00'
  let resIsOk = true
  const wrongCupsCodes = ['01', '03', '04', '05']
  try {
    if (Array.isArray(cups)) {
      const filteredCups = cups.filter((cupsItem) => cupsItem)
      for (let cupsItem of filteredCups) {
        const { code } = await getCupsCheck(cupsItem, userDocumentNumber, selectedCompany?.code)
        resCode = code
        if (wrongCupsCodes.includes(code)) {
          resIsOk = false
          break
        }
      }
    }
    return { isOk: resIsOk, code: resCode }
  } catch (e) {
    console.error(e)
    return { isOk: resIsOk, code: resCode }
  }
}

export const getSignParams = (rate, services, supplyAddress, selectedCompany) => {
  const paramsObject = {}
  const paramsKeys = { GAS: 'e7', ELECTRICITY: 'e8', SVG: 'e9', SVE: 'e10', SVH: 'e11', SPF: 'e12' }
  const energyTypes = [GAS, ELECTRICITY]

  if (rate?.length > 0) {
    energyTypes.forEach((energy) => {
      const product = rate[0].energyType === BOTH ? rate[0] : rate.find((rate) => rate.energyType === energy)
      if (
        product?.energyType?.includes(energy) ||
        product?.subProducts?.some((subProduct) => subProduct?.energyType === energy)
      ) {
        if (
          supplyAddress?.variants?.length === 0 ||
          !supplyAddress?.[`${energy.toLowerCase()}Cups`] ||
          supplyAddress?.company?.toLowerCase() !== selectedCompany?.toLowerCase()
        ) {
          paramsObject[paramsKeys[energy]] = 's'
        } else {
          paramsObject[paramsKeys[energy]] = 'm'
        }
      }
    })
  }
  if (services && Object.keys(services).length !== 0) {
    const oldServicesTypes = supplyAddress?.services ? supplyAddress?.services.map((oldServ) => oldServ?.type) : []
    Object.values(services).forEach((service) => {
      if (service?.type === 'SPF') {
        paramsObject[paramsKeys[service?.type]] = 'c'
      } else {
        paramsObject[paramsKeys[service?.type]] = oldServicesTypes.includes(service?.type) ? 'm' : 'c'
      }
    })
  }
  return paramsObject
}

export const getTariffData = async (service, selectedProduct, channel) => {
  const product = Array.isArray(selectedProduct)
    ? selectedProduct.length > 1
      ? selectedProduct.find((product) => product.energyType.toUpperCase() === service.toUpperCase())
      : selectedProduct[0]
    : selectedProduct
  let productVariants
  const selectedProductId = getSelectedProductId(product, service.toUpperCase())
  try {
    productVariants = await getVariants(channel?.id, selectedProductId)
  } catch (error) {
    console.log(error)
  }
  return productVariants?.map(({ tariff }) => tariff)
}

export const isNotEmpty = (obj) => Object.values(obj).some((value) => value)
export const handlerDevice = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  return isMobile ? 'mobile' : 'desktop'
}
