import * as actionTypes from './action-types'

export const setCupsSearcherByCups = (payload) => ({
  type: actionTypes.SET_CUPS_SEARCHER_BY_CUPS,
  payload,
})

export const clearCupsSearcherByCups = (payload) => ({
  type: actionTypes.CLEAR_CUPS_SEARCHER_BY_CUP,
  payload,
})
