import { all, put, takeLatest, select } from 'redux-saga/effects'
import { completeAnalyticsOnlineChannel } from 'services/onlineChannel/actions'

import { getIsOnlineChannel } from 'services/operator/selectors'
import { getIsOnlyToppings, getSelectedRate } from 'services/products/selectors'
import { getIsDraft } from 'services/draftCopy/selectors'

import { BOTH } from 'utils/constants'

import * as actionTypes from './action-types'
import { setActiveStep, setStepEnabled, setSteps, setPreselectedStep, setActiveStepDuplicate } from './actions'
import { getSteps, getActiveStep, getActiveStepDuplicate } from './selectors'

function* setActiveStepByIdSaga({ payload: stepId }) {
  const isOnlineChannel = yield select(getIsOnlineChannel)
  let steps = yield select(getSteps)
  const stepIndex = steps.findIndex((step) => step.id === stepId)
  if (isOnlineChannel) {
    yield all([put(setStepEnabled(stepId)), put(setActiveStep(stepIndex))])
  } else {
    yield put(setActiveStep(stepIndex))
  }
}

function* preselectStep(step) {
  yield put(setPreselectedStep(step))
}

function* goNextStepSaga({ payload: productData }) {
  const isDraft = yield select(getIsDraft)
  const contractSteps = yield select(getSteps)
  const activeStep = yield select(getActiveStep)

  const productArray = yield select(getSelectedRate)
  const service = productArray.length > 1 ? BOTH : productArray[0]?.energyType
  const isOnlyToppings = yield select(getIsOnlyToppings)

  const eventData = {
    service,
    activeStep,
    nextStep: activeStep + 1,
  }
  const draftWithAllStepsCompleted = isDraft && contractSteps.every(({ isCompleted }) => isCompleted)
  let nextStep = activeStep + 1 < contractSteps.length && !draftWithAllStepsCompleted ? activeStep + 1 : -1

  while ((contractSteps[nextStep]?.isAutocompleted || (nextStep === 2 && isOnlyToppings)) && nextStep < contractSteps.length) {
    eventData.activeStep = nextStep
    yield put(completeAnalyticsOnlineChannel({ stepId: nextStep }))
    nextStep++
  }

  contractSteps
    .slice(nextStep)
    .filter(({ isCompleted }) => isCompleted)
    .forEach(preselectStep)

  if (!contractSteps[nextStep]) {
    yield put(setActiveStep(nextStep))
  } else {
    yield all([put(setStepEnabled(contractSteps[nextStep].id)), put(setActiveStep(nextStep))])
  }
}

function* goNextStepDuplicateSaga() {
  const contractSteps = yield select(getSteps)
  const activeStepDuplicate = yield select(getActiveStepDuplicate)
  let nextStep = activeStepDuplicate + 1 < contractSteps.length ? activeStepDuplicate + 1 : -1

  if (!contractSteps[nextStep]) {
    yield put(setActiveStepDuplicate(nextStep))
    if (nextStep === -1) {
      yield put(setActiveStep(nextStep))
    }
  } else {
    yield all([put(setStepEnabled(contractSteps[nextStep].id)), put(setActiveStepDuplicate(nextStep))])
  }
}

function* updateStepSaga({ payload: { stepId, attr, value } }) {
  let steps = yield select(getSteps)
  const stepIndex = steps.findIndex((step) => step.id === stepId)
  if (stepIndex >= 0) {
    steps[stepIndex] = { ...steps[stepIndex], [attr]: value }
  }
  yield put(setSteps(steps))
}

function* disableInactiveStepsSaga() {
  const steps = yield select(getSteps)
  const newSteps = steps.map((step, index) => (index > 0 ? { ...step, isDisabled: true } : step))
  yield put(setSteps(newSteps))
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.SET_ACTIVE_STEP_BY_ID], setActiveStepByIdSaga),
    takeLatest([actionTypes.GO_NEXT_STEP], goNextStepSaga),
    takeLatest([actionTypes.GO_NEXT_STEP_DUPLICATE], goNextStepDuplicateSaga),
    takeLatest([actionTypes.UPDATE_STEP], updateStepSaga),
    takeLatest([actionTypes.DISABLE_STEPS], disableInactiveStepsSaga),
  ])
}
