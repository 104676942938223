import * as actionTypes from './action-types'

export const showModal = ({ modalType, modalData }) => ({
  type: actionTypes.SHOW,
  payload: { type: modalType, data: modalData },
})

export const hideModal = () => ({
  type: actionTypes.HIDE,
})

export const setModalData = ({ modalData }) => ({
  type: actionTypes.SET_DATA,
  payload: modalData,
})

export const resetModalData = () => ({
  type: actionTypes.RESET_DATA,
})
