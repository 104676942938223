import * as actionTypes from './action-types'

// TODO: Change setActiveStep to allow an id instead an index
export const setActiveStep = (activeStep) => ({
  type: actionTypes.SET_ACTIVE_STEP,
  payload: activeStep,
})

export const clearActiveStep = () => ({
  type: actionTypes.CLEAR_ACTIVE_STEP,
})

export const setActiveStepDuplicate = (activeStep) => ({
  type: actionTypes.SET_ACTIVE_STEP_DUPLICATE,
  payload: activeStep,
})

export const clearActiveStepDuplicate = () => ({
  type: actionTypes.CLEAR_ACTIVE_STEP_DUPLICATE,
})

export const setActiveStepById = (stepId) => ({
  type: actionTypes.SET_ACTIVE_STEP_BY_ID,
  payload: stepId,
})

export const setSteps = (steps) => ({
  type: actionTypes.SET_STEPS,
  payload: steps,
})

export const clearSteps = () => ({
  type: actionTypes.CLEAR_STEPS,
})

export const goNextStep = (selectedProduct) => ({
  type: actionTypes.GO_NEXT_STEP,
  payload: { selectedProduct },
})

export const goNextStepDuplicate = (selectedProduct) => ({
  type: actionTypes.GO_NEXT_STEP_DUPLICATE,
  payload: { selectedProduct },
})

export const setStepDisabled = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isDisabled', value: true },
})

export const setStepEnabled = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isDisabled', value: false },
})

export const setStepCompleted = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isCompleted', value: true },
})

export const setStepUncompleted = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isCompleted', value: false },
})

export const setStepAutocompleted = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isAutocompleted', value: true },
})

export const setStepUnautocompleted = (stepId) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'isAutocompleted', value: false },
})

export const setStepMode = (stepId, mode) => ({
  type: actionTypes.UPDATE_STEP,
  payload: { stepId, attr: 'mode', value: mode },
})

export const disableInactiveSteps = () => ({
  type: actionTypes.DISABLE_STEPS,
})

export const disableSupplyStepTenure = (supplyModeService) => ({
  type: actionTypes.DISABLE_SUPPLY_STEP_TENURE,
  payload: { supplyModeService },
})

export const setPreselectedStep = (stepId) => ({
  type: actionTypes.PRESELECTED_STEP,
  payload: { stepId },
})
