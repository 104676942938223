import * as actionTypes from './action-types'

export const setLeadId = (leadId) => ({
  type: actionTypes.SET_LEAD_ID,
  payload: leadId,
})

export const clearLeadId = () => ({
  type: actionTypes.CLEAR_LEAD_ID,
})

export const setLeadErrorMessage = (leadErrorMessage) => ({
  type: actionTypes.SET_LEAD_ERROR_MESSAGE,
  payload: leadErrorMessage,
})

export const clearLeadErrorMessage = () => ({
  type: actionTypes.CLEAR_LEAD_ERROR_MESSAGE,
})

export const startLead = () => ({
  type: actionTypes.START_LEAD,
})

export const successLead = () => ({
  type: actionTypes.SUCCESS_LEAD,
})

export const errorLead = () => ({
  type: actionTypes.ERROR_LEAD,
})

export const fetchLead = () => ({
  type: actionTypes.FETCH_LEAD,
})

export const clearLead = () => ({
  type: actionTypes.CLEAR_LEAD,
})

export const setBillingAddressId = (leadId) => ({
  type: actionTypes.SET_BILLING_ADDRESS_ID,
  payload: leadId,
})

export const clearBillingAddressId = () => ({
  type: actionTypes.CLEAR_BILLING_ADDRESS_ID,
})

export const setSupplyAddressesIds = (leadId) => ({
  type: actionTypes.SET_SUPPLY_ADDRESSES_IDS,
  payload: leadId,
})

export const clearSupplyAddressesIds = () => ({
  type: actionTypes.CLEAR_SUPPLY_ADDRESSES_IDS,
})
