import { createMuiTheme } from '@material-ui/core/styles'
import fonts from '../base/fonts'
import palette from '../base/palette'
import shadows from '../base/shadows'

const defaultTheme = createMuiTheme()

const tooltip = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: defaultTheme.spacing(2),
        backgroundColor: palette.neutral.light4,
        color: palette.neutral.main,
        ...fonts.body2Sb,
        boxShadow: shadows[2],
      },
    },
  },
}

export default tooltip
