import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import productsReducer from '../services/products/reducer'
import toppingsReducer from '../services/toppings/reducer'
import customerReducer from '../services/customer/reducer'
import companyReducer from '../services/company/reducer'
import operatorReducer from '../services/operator/reducer'
import leadReducer from '../services/lead/reducer'
import orderReducer from '../services/order/reducer'
import campaignsReducer from '../services/campaigns/reducer'
import stepperReducer from '../services/stepper/reducer'
import modalReducer from '../services/modal/reducer'
import backofficeReducer from '../services/backoffice/reducer'
import controlReducer from '../services/control/reducer'
import callMeBackReducer from '../services/callMeBack/reducer'
import onlineChannelReducer from '../services/onlineChannel/reducer'
import draftReducer from '../services/draftCopy/reducer'
import loadingReducer from '../services/loading/reducer'
import notificationReducer from '../services/notification/reducer'
import backofficeTemplatesReducer from '../services/templates/reducer'
import energiesReducer from '../services/energies/reducer'
import selfconsumptionReducer from '../services/selfconsumption/reducer'
import fidelizationReducer from '../services/fidelization/reducer'
import invoiceReducer from '../services/invoice/reducer'
import cupsSearcherReducer from '../services/cupsSearcher/reducer'
import portabilityReducer from '../services/portability/reducer'
import contractStatusReducer from '../services/contractStatus/reducer'
import * as operatorActionTypes from '../services/operator/action-types'

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    products: productsReducer,
    toppings: toppingsReducer,
    customer: customerReducer,
    companies: companyReducer,
    operator: operatorReducer,
    lead: leadReducer,
    order: orderReducer,
    campaigns: campaignsReducer,
    stepper: stepperReducer,
    backoffice: backofficeReducer,
    control: controlReducer,
    callMeBack: callMeBackReducer,
    onlineChannel: onlineChannelReducer,
    draft: draftReducer,
    ...modalReducer,
    loading: loadingReducer,
    notification: notificationReducer,
    backofficeTemplates: backofficeTemplatesReducer,
    energies: energiesReducer,
    selfconsumption: selfconsumptionReducer,
    fidelization: fidelizationReducer,
    invoice: invoiceReducer,
    cupsSearcher: cupsSearcherReducer,
    contractStatus: contractStatusReducer,
    portability: portabilityReducer,
  })

const initialState = {
  products: {},
  toppings: {},
  customer: {},
  companies: {},
  operator: {},
  lead: {},
  order: {},
  campaigns: {},
  stepper: {},
  backoffice: {},
  control: {},
  callMeBack: {},
  modal: {},
  onlineChannel: {},
  draft: {},
  loading: {},
  notification: {},
  backofficeTemplates: {},
  energies: {},
  selfconsumption: {},
  fidelization: {},
  cupsSearcher: {},
  contractStatus: {},
  portability: {},
}

export const rootReducer = (history) => (state, action) => {
  if (action.type === operatorActionTypes.LOGOUT) {
    state = initialState
  }

  return appReducer(history)(state, action)
}

export default rootReducer
