import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const PriceVariant = ({ name, value, isSubPrice }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.price}>
      <Typography variant={isSubPrice ? 'helper' : 'label'} color="textPrimary">
        {t(`price-label-${name}`)}
      </Typography>
      <Box className={classes.priceNumber}>
        <Typography variant="helper" color="primary">
          {t(value)}
        </Typography>
      </Box>
    </Box>
  )
}

export default PriceVariant
