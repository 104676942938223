import { get, post } from '../client'

export const getFidelizationAvailable = async ({ companyId, channelId }) => {
  if (channelId && companyId) {
    return await get(`checkFideAllowed?company=${companyId}&channelId=${channelId}`, true)
  } else {
    return false
  }
}

export const getExistingFide = ({ companyId, docNumber, families, address }) =>
  post(
    `checkExistingFide`,
    {
      docNumber,
      address,
      families,
      company: companyId,
    },
    true
  )
