import * as actionTypes from './action-types'

export const setOperatorData = (data) => ({
  type: actionTypes.SET_DATA,
  payload: data,
})

export const setUserData = (data) => ({
  type: actionTypes.SET_USER_DATA,
  payload: data,
})

export const setChannels = (channels) => ({
  type: actionTypes.SET_CHANNELS,
  payload: channels,
})

export const setOperatorCompanies = (companies) => ({
  type: actionTypes.SET_OPERATOR_COMPANIES,
  payload: companies,
})

export const setSelectedChannel = (channel) => ({
  type: actionTypes.SET_SELECTED_CHANNEL,
  payload: channel,
})

export const clearSelectedChannel = () => ({
  type: actionTypes.CLEAR_SELECTED_CHANNEL,
})

export const setReferenceSegment = (referenceSegment) => ({
  type: actionTypes.SET_REFERENCE_SEGMENT,
  payload: referenceSegment,
})

export const fetchChannels = () => ({
  type: actionTypes.FETCH_CHANNELS,
})

export const fetchUser = () => ({
  type: actionTypes.FETCH_USER,
})
export const fetchUserAPI = (agv) => ({
  type: actionTypes.FETCH_USER_API,
  payload: agv,
})

export const logout = () => ({
  type: actionTypes.LOGOUT,
})

export const resetContract = () => ({
  type: actionTypes.RESET_CONTRACT,
})

export const resetFunnelContract = () => ({
  type: actionTypes.RESET_FUNNEL_CONTRACT,
})

export const setIsResetFunnelContract = (isResetFunnelContract) => ({
  type: actionTypes.SET_IS_RESET_FUNNEL_CONTRACT,
  payload: isResetFunnelContract,
})

export const resetOrder = () => ({
  type: actionTypes.RESET_ORDER,
})

export const resetSupplyStep = () => ({
  type: actionTypes.RESET_SUPPLY_STEP,
})
