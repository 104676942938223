import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'
import SelectCard from 'ui/components/selectCard'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const TenureFullCard = ({ supplyPoint, selected, handleSelect, disableSupplysPoints, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChange = disabled || disableSupplysPoints ? () => {} : () => handleSelect(supplyPoint)

  return (
    <SelectCard
      id={supplyPoint?.addressId}
      isSelected={selected.addressId === supplyPoint?.addressId}
      onChange={handleChange}
      disabled={disabled}
      disableSupplysPoints={disableSupplysPoints || disabled}
    >
      <Grid container spacing={2} columns={{ md: 4, sm: 6, xm: 12 }}>
        <Grid item mb={1.5}>
          <Typography variant="h5" color="textPrimary">
            {supplyPoint?.addressDescription}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {t(
              supplyPoint?.variants?.length > 0 || supplyPoint?.services?.length > 0 ? 'purchasedProducts' : 'noPurchasedProducts'
            )}
          </Typography>
          <ul>
            {supplyPoint?.variants?.map(({ name, id, state }) => {
              const stateCaption = state || null
              return (
                <li key={id}>
                  <Typography variant="basic" color="textPrimary">
                    {name}{' '}
                    {stateCaption && (
                      <span variant="basic" className={classes.stateCaption} display="inline">
                        - {stateCaption}
                      </span>
                    )}
                  </Typography>
                </li>
              )
            })}
            {supplyPoint?.services?.map(({ name, id, state }) => {
              const stateCaption = state || null
              return (
                <li key={id}>
                  <Typography variant="basic" color="textPrimary">
                    {t('purchasedService', { name })}{' '}
                    {stateCaption && (
                      <span variant="basic" className={classes.stateCaption} display="inline">
                        - {stateCaption}
                      </span>
                    )}
                  </Typography>
                </li>
              )
            })}
          </ul>
        </Grid>
      </Grid>
    </SelectCard>
  )
}

export default TenureFullCard
