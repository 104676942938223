import { get, post, put, del } from '../client'

/**
 * Check if products and services are blacklisted in give postalCode
 * @param {String} postalCode
 * @param {Array} productId
 * @param {Array} toppingsIds
 */
export const getAllCampaignsMatch = async ({
  electricityProductId,
  gasProductId,
  electricityServiceId,
  gasServiceId,
  genericServiceId,
  type,
  channelOfferId,
  documentNumber,
  tenureAddressId,
  isNewSupply,
  company,
  agv,
}) => {
  let electricityProductQueryString = electricityProductId ? { electricityProductId } : {}
  let gasProductQueryString = gasProductId ? { gasProductId } : {}
  let electricityServiceQueryString = electricityServiceId ? { electricityServiceId } : {}
  let gasServiceQueryString = gasServiceId ? { gasServiceId } : {}
  let genericServiceQueryString = genericServiceId ? { genericServiceId } : {}
  let typeQueryString = type ? { type } : {}
  let channelOfferIdString = channelOfferId ? { channelOfferId } : {}
  let tenureInfoQueryString = documentNumber && tenureAddressId ? { documentNumber, tenureAddressId } : {}
  let isNewSupplyQueryString = isNewSupply ? { availableForNewSupplies: isNewSupply } : {}
  let selectedCompany = company ? { 'company.code': company.code.toLowerCase() } : {}
  let agvQueryString = agv ? { agv } : {}

  const params = new URLSearchParams({
    ...electricityProductQueryString,
    ...gasProductQueryString,
    ...electricityServiceQueryString,
    ...gasServiceQueryString,
    ...genericServiceQueryString,
    ...typeQueryString,
    ...channelOfferIdString,
    ...tenureInfoQueryString,
    ...isNewSupplyQueryString,
    ...selectedCompany,
    ...agvQueryString,
  })

  const queryString = params.toString()

  return await get(`campaigns/matches?${queryString}`, true)
}

export const getCampaignById = (id) => get(`campaigns/${id}`)

export const getCampaignByIdLDJson = (id) =>
  get(`campaigns/${id}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

export const getAllCampaigns = () => get(`campaigns`, true)

export const getAllCampaignsLDJson = async (props) => {
  const queryParams =
    Object.entries(props)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const response = await get(`campaigns?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return {
    campaigns: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  }
}

export const putCampaign = (id, params) => put(`campaigns/${id}`, params)

export const postCampaign = (params) => post(`campaigns`, params)

export const deleteCampaign = (campaignId) => del(`campaigns/${campaignId}`)
