import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SupplyPoint from '@zatopek/core/src/containers/SupplyPoint'

import {
  BOTH,
  BOTH_CALCULATOR,
  ELECTRICITY,
  GA_EVENT_ACTIONS,
  GA_EVENT_NAMES,
  STREET_TYPE_DEFAULT,
  STEPS,
  //CUPS_SELECTOR_BY_CUPS_ACTION,
} from 'utils/constants'

import CalculatorView from 'ui/modals/calculator'

import { getSupplyStepMode } from 'services/stepper/selectors'
import { getIsOnlineChannel, getSelectedChannel } from 'services/operator/selectors'
import { getIsLeadFetching } from 'services/lead/selectors'
import { getSelectedProductId } from 'services/products/business'
import {
  getCompany,
  getIsTenureSelected,
  getNormalizedAddress,
  getSupplyPoint,
  getUserData,
  getCustomerData,
  getSelectedTenure,
  getSupplyAddress,
} from 'services/customer/selectors'
import { setNormalizedAddress, setSupplyPoint, setSupplyAddress } from 'services/customer/actions'
import { sendGAEvent } from 'services/analytics/actions'
import { getVariants } from 'services/products/api'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { getSelectedRate } from 'services/products/selectors'
import { setNewAddressProductChange } from 'services/fidelization/actions'
import { checkCups as checkCupsApi } from 'utils/commons'
import { getInvoiceData } from 'services/invoice/selectors'
import { uploadInvoiceClear } from 'services/invoice/actions'
import { setCupsSearcherByCups } from 'services/cupsSearcher/actions'
import { getCupsSearchByCups } from 'services/cupsSearcher/selectors'
import { getActiveStep } from 'services/stepper/selectors'
import { getOrders } from 'services/order/selectors'

const Calculator = ({ data, handleClose }) => {
  const dispatch = useDispatch()
  const cupsSearchByCups = useSelector(getCupsSearchByCups)
  const supplyMode = useSelector(getSupplyStepMode)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const supplyPoint = useSelector(getSupplyPoint)
  const normalizedAddress = useSelector(getNormalizedAddress)
  const isTenureSelected = useSelector(getIsTenureSelected)
  const channel = useSelector(getSelectedChannel)
  const supplyAddress = useSelector(getSupplyAddress)
  const company = useSelector(getCompany)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const userData = useSelector(getUserData)
  const customerData = useSelector(getCustomerData)
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const documentType = userData?.documentType || customerData?.idDocumentType
  const selectedCompany = company || selectedProduct[0]?.company
  const tenure = useSelector(getSelectedTenure)
  const isPortability = Boolean(company?.code && tenure?.company && company.code.toLowerCase() !== tenure.company.toLowerCase())
  const dataFromInvoice = useSelector(getInvoiceData)
  const activeStep = useSelector(getActiveStep)
  const orders = useSelector(getOrders)
  const [showCheck, setShowCheck] = useState(false)

  const setCheckFlag = (flag) => {
    setShowCheck(flag)
  }
  const checkCups = async (...args) => {
    const { code, ...others } = await checkCupsApi(...args)

    dispatch(setNewAddressProductChange(code))

    return { code, ...others }
  }

  const handleSendAnalytics = (eventAction, eventLabel) => {
    if (GA_EVENT_ACTIONS[GA_EVENT_NAMES.selectCups].includes(eventAction)) {
      if (eventAction === 'selectCupsByCups') {
        dispatch(sendGAEvent(GA_EVENT_NAMES.selectCupsByCups, { currentStep: STEPS.rate }))
        if (supplyModeService === BOTH.toLowerCase()) {
          let searchByCups =
            cupsSearchByCups === true || cupsSearchByCups === false || cupsSearchByCups === null
              ? { electricity: null, gas: null }
              : cupsSearchByCups
          if (eventLabel === 'electricity') {
            searchByCups.electricity = true
            dispatch(setCupsSearcherByCups(searchByCups))
          } else {
            searchByCups.gas = true
            dispatch(setCupsSearcherByCups({ gas: true }))
          }
        } else {
          if (supplyModeService === ELECTRICITY.toLowerCase()) {
            dispatch(setCupsSearcherByCups({ electricity: true }))
          } else {
            dispatch(setCupsSearcherByCups({ gas: true }))
          }
        }
      } else {
        dispatch(sendGAEvent(GA_EVENT_NAMES.selectCupsByAddress, { currentStep: STEPS.rate }))
        if (supplyModeService === BOTH.toLowerCase()) {
          let searchByCups =
            cupsSearchByCups === true || cupsSearchByCups === false || cupsSearchByCups === null
              ? { electricity: null, gas: null }
              : cupsSearchByCups
          if (eventLabel === 'electricity') {
            searchByCups.electricity = false
            dispatch(setCupsSearcherByCups(searchByCups))
          } else {
            searchByCups.gas = false
            dispatch(setCupsSearcherByCups(searchByCups))
          }
        } else {
          if (supplyModeService === ELECTRICITY.toLowerCase()) {
            dispatch(setCupsSearcherByCups({ electricity: false }))
          } else {
            dispatch(setCupsSearcherByCups({ gas: false }))
          }
        }
      }
    } else {
      //dispatch(sendGAEvent(GA_EVENT_NAMES.calculatorEvents, { eventAction, eventLabel, service: supplyModeService }))
    }
  }
  const { energyType = null, preSelectedRate = [], attributes, setShowRequiredAlert } = data

  const supplyModeService = useMemo(() => {
    if (energyType) {
      return energyType.toLowerCase()
    }
    if (supplyMode) {
      return (supplyMode === BOTH ? BOTH_CALCULATOR : supplyMode).toLowerCase()
    }
    return ELECTRICITY.toLowerCase()
  }, [energyType, supplyMode])

  const handleSubmit = (technicalInfo, supplyAddress) => {
    if (Object.keys(technicalInfo).length) {
      technicalInfo =
        supplyModeService === BOTH.toLowerCase()
          ? Array.isArray(technicalInfo)
            ? technicalInfo?.map((techInfo) => ({ ...techInfo, supplyAddress }))
            : [technicalInfo]
          : Array.isArray(technicalInfo)
          ? technicalInfo
          : [{ ...technicalInfo, supplyAddress }]
      dispatch(setSupplyPoint(technicalInfo))
    }
  }

  const onAutoCompletion = (technicalInfo) => {
    if (technicalInfo && Object.keys(technicalInfo).length) {
      technicalInfo = Array.isArray(technicalInfo) ? technicalInfo : [technicalInfo]
      dispatch(setSupplyPoint(technicalInfo))
    }
  }

  const handleNormalizedAddress = (address) => {
    dispatch(setNormalizedAddress(address))
  }

  const handleSupplyAddress = (supplyAddress) => {
    // TODO: Change when IBM fix streetType value
    // Right now, we receive it as a null value, this has to be fixed by them
    // This happen only in development
    dispatch(setSupplyAddress({ ...supplyAddress, streetType: supplyAddress?.streetType || STREET_TYPE_DEFAULT }))
  }

  const showNewSupplyAddressModalForm = async (completeSupplyPoint, supplyData, checkAddressObject = null) => {
    const { service, cups, energyStep, technicalInfo } = supplyData
    const preSelectedFlatRate = preSelectedRate.find((rate) => rate.flatRate && rate.energyType === service)
    dispatch(
      showModal({
        modalType: MODAL_TYPES.newSupplyAddress,
        modalData: {
          completeSupplyPoint,
          service,
          supplyModeService,
          addressCups: cups,
          shouldGetCalculations: preSelectedFlatRate,
          step: 2,
          energyStep,
          supplyTechnicalInfo: technicalInfo,
          userDocumentNumber,
          documentType,
          checkAddressObject,
        },
      })
    )
  }

  const showNewSupplyTechnicalModalForm = async (technicalInfo, service) => {
    const product = preSelectedRate.find((rate) => rate.energyType === service)
    const selectedProductId = getSelectedProductId(product, service.toUpperCase())
    const productVariants = await getVariants(channel.id, selectedProductId)
    const newSupplyData = productVariants?.map(({ tariff }) => tariff)
    dispatch(
      showModal({
        modalType: MODAL_TYPES.newSupplyTechnical,
        modalData: {
          onSubmit: handleSubmit,
          service,
          newSupplyData,
          technicalInfo,
        },
      }),
      sendGAEvent(GA_EVENT_NAMES.newSupplyTechModal, { currentStep: STEPS.supply })
    )
  }

  const showErrorModalCalculatorModal = (translationLabel) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.error,
        modalData: {
          translationLabel,
        },
      })
    )
  }

  const wrongCupsValidation = (code) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.checkCups,
        modalData: {
          sameClient: code === '01',
          cupsActive: code === '05',
        },
      })
    )
  }

  const closeInfoModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.calculatorModal,
        modalData: {
          energyType: preSelectedRate.length === 1 ? preSelectedRate[0]?.energyType : BOTH,
          preSelectedRate: preSelectedRate,
          attributes,
          setShowRequiredAlert,
        },
      })
    )
  }

  const openInfoModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.scanInvoiceInfo,
        modalData: {
          onCloseModal: closeInfoModal,
        },
      })
    )
  }

  const closeUploadImageModal = () => {
    dispatch(uploadInvoiceClear())
    dispatch(
      showModal({
        modalType: MODAL_TYPES.calculatorModal,
        modalData: {
          energyType: preSelectedRate.length === 1 ? preSelectedRate[0]?.energyType : BOTH,
          preSelectedRate: preSelectedRate,
          attributes,
          setShowRequiredAlert,
        },
      })
    )
  }
  const sendGAEventUploadInvoiceImage = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.uploadInvoiceImage, { currentStep: STEPS.supply }))
  }
  const sendGAinfoInvoiceEvent = () => {
    dispatch(
      sendGAEvent(GA_EVENT_NAMES.infoInvoice, { currentStep: STEPS.supply }),
      sendGAEvent(GA_EVENT_NAMES.openInfoModal, { currentStep: STEPS.supply })
    )
  }

  const sendEditGAEvent = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.editSupplyPoint, { currentStep: STEPS.supply }))
  }

  const openUploadImageModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.scanInvoice,
        modalData: {
          onCloseModal: closeUploadImageModal,
        },
      })
    )
  }
  const showNoCPModal = (checkTenure) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.warningModal,
        modalData: {
          titleKey: 'addressValidation.modal.title',
          textKey: checkTenure ? `addressValidation.modal.diferentCPTenure` : `addressValidation.modal.diferentCP`,
        },
      })
    )
  }
  const showNowPriceData = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.warningModal,
        modalData: {
          titleKey: 'noMonthlyPrice.modal.title',
          textKey: `noMonthlyPrice.modal.text`,
        },
      })
    )
  }
  const showNoSameAddressModal = (checkTenure) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.checkAddress,
        modalData: {
          checkTenure,
        },
      })
    )
  }
  const isFlatRateObject = {
    ELECTRICITY: undefined,
    GAS: undefined,
    'ELECTRICITY,GAS': undefined,
  }

  if (supplyModeService === BOTH.toLowerCase()) {
    isFlatRateObject['ELECTRICITY,GAS'] = true
    isFlatRateObject['ELECTRICITY'] = true
    isFlatRateObject['GAS'] = true
  } else if (supplyModeService === ELECTRICITY.toLowerCase()) {
    isFlatRateObject['ELECTRICITY'] = true
    isFlatRateObject['GAS'] = false
    isFlatRateObject['ELECTRICITY,GAS'] = false
  } else {
    isFlatRateObject['GAS'] = true
    isFlatRateObject['ELECTRICITY'] = false
    isFlatRateObject['ELECTRICITY,GAS'] = false
  }

  return (
    <CalculatorView
      supplyPoint={supplyPoint}
      supplyModeService={supplyModeService}
      isOnlineChannel={isOnlineChannel}
      preSelectedRate={preSelectedRate}
      selectedProduct={selectedProduct}
      tenure={tenure}
      isPortability={isPortability}
      handleClose={handleClose}
      attributes={attributes}
      setShowRequiredAlert={setShowRequiredAlert}
      showCheck={showCheck}
    >
      <SupplyPoint
        isEnabledCupsByInvoice={supplyModeService === 'electricity' && data.allowUploadInvoice}
        openInfoModal={openInfoModal}
        openUploadImageModal={openUploadImageModal}
        sendGAinfoInvoiceEvent={sendGAinfoInvoiceEvent}
        sendGAEventUploadInvoiceImage={sendGAEventUploadInvoiceImage}
        dataFromInvoice={dataFromInvoice ? dataFromInvoice[0] : null}
        onSendAnalytics={handleSendAnalytics}
        service={supplyModeService}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        getQuota={!!supplyMode}
        isDisabled={isLeadFetching}
        isOnlineChannel={isOnlineChannel}
        shouldGetCalculations={isFlatRateObject}
        supplyPoint={supplyPoint}
        onAutoCompletion={onAutoCompletion}
        isPopUp={true}
        handleNormalizedAddress={handleNormalizedAddress}
        handleSupplyAddress={handleSupplyAddress}
        supplyAddress={supplyAddress}
        normalizedAddress={normalizedAddress}
        showNowPriceData={showNowPriceData}
        preSelectedRate={preSelectedRate}
        energyType={data?.energyType}
        isTenureSelected={isTenureSelected}
        sendEditGAEvent={sendEditGAEvent}
        userDocumentNumber={userDocumentNumber}
        documentType={documentType}
        selectedCompany={selectedCompany}
        checkCups={checkCups}
        wrongCupsValidation={wrongCupsValidation}
        showNewSupplyAddressModalForm={showNewSupplyAddressModalForm}
        showNewSupplyTechnicalModalForm={showNewSupplyTechnicalModalForm}
        step={2}
        activeStepNumber={activeStep}
        showErrorModal={showErrorModalCalculatorModal}
        channel={channel}
        selectedProduct={selectedProduct}
        showNoCPModal={showNoCPModal}
        showNoSameAddressModal={showNoSameAddressModal}
        tenure={tenure}
        orders={orders}
        setShowCheck={setCheckFlag}
      />
    </CalculatorView>
  )
}

export default Calculator
