import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import CancelOrderForm from 'ui/forms/cancelOrderForm'
import CancelOrderView from 'ui/modals/cancelOrder'

import { useDispatch, useSelector } from 'react-redux'
import { getIsCancelOrderRequesting } from 'services/backoffice/selectors'
import { cancelOrder } from 'services/backoffice/actions'
import { CANCEL_ORDER_DETAILS_CHARACTER_LIMIT } from 'utils/constants'

const CancelOrder = ({ handleClose, data: { leadId } }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const isCancelOrderRequesting = useSelector(getIsCancelOrderRequesting)
  const dispatch = useDispatch()

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['cancelReason', 'cancelDetails']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })
    if (!errors.cancelDetails && values.cancelDetails.length > CANCEL_ORDER_DETAILS_CHARACTER_LIMIT) {
      errors.cancelDetails = t('form.errors.maxLength', { length: CANCEL_ORDER_DETAILS_CHARACTER_LIMIT })
    }

    return errors
  }

  const handleFormSubmit = (formData, { resetForm }) => {
    const { cancelReason, cancelDetails } = formData
    try {
      setError(false)
      dispatch(cancelOrder(leadId, cancelReason, cancelDetails))
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }

  return (
    <CancelOrderView error={error} isCancelOrderRequesting={isCancelOrderRequesting}>
      <Formik
        initialValues={{ cancelReason: '', cancelDetails: '' }}
        validate={validateForm}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ handleChange, touched, errors, values }) => (
          <>
            <Form noValidate>
              <CancelOrderForm
                onChange={handleChange}
                onCancel={handleClose}
                touched={touched}
                errors={errors}
                values={values}
                isCancelling={isCancelOrderRequesting}
              />
            </Form>
          </>
        )}
      </Formik>
    </CancelOrderView>
  )
}

export default CancelOrder
