import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ErrorFocus = ({ errorWrapperClass }) => {
  const { isSubmitting, isValidating, errors } = useFormikContext()

  useEffect(() => {
    // Get all keys of the error messages.
    const keys = Object.keys(errors)

    // Whenever there are errors and the form is submitting but finished validating.
    if (keys.length > 0 && isSubmitting && !isValidating) {
      // We grab the first input element that error by its id.
      const errorElement = document.querySelector(`.${errorWrapperClass} #${keys[0]}`)

      if (errorElement) {
        // When there is an input, scroll this input into view.
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [isSubmitting, isValidating, errors, errorWrapperClass])

  // This component does not render anything by itself.
  return null
}

export default ErrorFocus
