import sortby from 'lodash.sortby'

export const ADDRESS = 'address'
export const CUPS = 'cups'
export const ELECTRICITY = 'ELECTRICITY'
export const GAS = 'GAS'
export const BOTH = 'ELECTRICITY,GAS'
export const BOTH_CALCULATOR = 'BOTH'
export const ORDERS = 'orders'
export const ORDER_FAIL = 'orderFail'
export const ORDER_LINES = 'orderLines'
export const PRODUCTS = 'products'
export const NOTIFICATIONS = 'notifications'
export const RATE = 'rate'
export const CAMPAIGN = 'campaign'
export const TOPPINGS = 'toppings'
export const TOPPING = 'topping'
export const FIDELIZATION = 'Fidelización'
export const CATCHMENT = 'Captación'
export const RESIDENTIAL = 'residential'
export const PYME = 'pyme'
export const HOME = 'hogar'
export const BUSINESS = 'negocio'
export const COMMERCIAL = 'Comercial'
export const DOMESTIC = 'Doméstico'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const CONTROL_VISUALIZATION = {
  totalSold: 'totalSold',
  totalFailed: 'totalFailed',
  totalProducts: 'totalProducts',
  totalFailedOnActivation: 'totalFailedOnActivation',
  totalActive: 'totalActive',
  activationTime: 'activationTime',
  all: 'all',
}

export const ID_NATURGY_IMPERIAL = '908caa91-238b-4fd3-85fd-9b0adbc67aae'
export const ID_NATURGY_CLIENTES = '2a1f6a03-d056-4e8d-af97-7603b1375c9f'

export const ID_ELECTRICITY = 'd4e58cad-2ed9-4f90-b342-4b1e59747d0e'

export const DICTIONARY = {
  state: 'state',
  fromDate: 'createdFrom',
  toDate: 'createdTo',
  failedDateTo: 'failedDateTo',
  failedDateFrom: 'failedDateFrom',
  minimumDays: 'ageFrom',
  maximumDays: 'ageTo',
  orderType: 'orderType',
  orderColumn: 'orderColumn',
  page: 'page',
  rowsPerPage: 'nbItems',
  search: 'searchText',
  channel: 'channel',
  type: 'productType',
  family: 'family',
  contractType: 'contractType',
  templateId: 'templateId',
  supplyType: 'supplyType',
  company: 'company',
  segment: 'segment',
  product: 'product',
  agency: 'agency',
}

export const CHANNEL_SESSION_STORAGE_KEY = 'selectedChannel'
export const COMPANY_SESSION_STORAGE_KEY = 'operatorCompanies'
export const REFERENCE_SEGMENT_SESSION_STORAGE_KEY = 'referenceSegment'

export const STEPS = {
  rate: 'rate',
  topping: 'topping',
  supply: 'supply',
  owner: 'owner',
  billing: 'billing',
}
export const SERVICES_SEGMENT = [
  {
    value: 'SVE',
    label: 'servielectric',
  },
  {
    value: 'SVG',
    label: 'servigas',
  },
  {
    value: 'SVH',
    label: 'servihogar',
  },
  {
    value: 'OTR',
    label: 'solar',
  },
]

export const ID_DOC_TYPES = {
  nif: {
    label: 'form.fields.nifdni',
    value: 'NIF',
  },
  nie: {
    label: 'form.fields.nie',
    value: 'NIE',
  },
  passport: {
    label: 'form.fields.passport',
    value: 'Pasaporte',
  },
}

export const SPAIN_NATIONALITY = { value: 'ES', label: 'ESPAÑA' }
export const ALL_NATIONALITIES = [
  { value: 'SB', label: 'ISLAS SALOMON' },
  { value: 'SC', label: 'ISL.SEYCHELLES' },
  { value: 'SD', label: 'SUDAN' },
  { value: 'SA', label: 'ARABIA SAUDI' },
  { value: 'RE', label: 'REUNION' },
  { value: 'QA', label: 'QATAR' },
  { value: 'PY', label: 'PARAGUAY' },
  { value: 'RW', label: 'RUANDA' },
  { value: 'RU', label: 'FEDERACION RUSA' },
  { value: 'RO', label: 'RUMANIA' },
  { value: 'SM', label: 'SAN MARINO' },
  { value: 'SL', label: 'SIERRA LEONA' },
  { value: 'SN', label: 'SENEGAL' },
  { value: 'SR', label: 'SURINAM' },
  { value: 'SO', label: 'SOMALIA' },
  { value: 'SK', label: 'ESLOVAQUIA' },
  { value: 'SG', label: 'SINGAPUR' },
  { value: 'SE', label: 'SUECIA' },
  { value: 'SH', label: 'SANTA ELENA' },
  { value: 'SJ', label: 'SVALBARD AND JAN MAYEN' },
  { value: 'SI', label: 'ESLOVENIA' },
  { value: 'PW', label: 'PALAU' },
  { value: 'NU', label: 'ISLAS NIUE' },
  { value: 'NR', label: 'NAURU' },
  { value: 'NZ', label: 'NUEVA ZELANDA' },
  { value: 'PA', label: 'PANAMA' },
  { value: 'OM', label: 'OMAN' },
  { value: 'NI', label: 'NICARAGUA' },
  { value: 'NG', label: 'NIGERIA' },
  { value: 'NL', label: 'HOLANDA' },
  { value: 'NP', label: 'NEPAL' },
  { value: 'NO', label: 'NORUEGA' },
  { value: 'PE', label: 'PERU' },
  { value: 'PN', label: 'ISLAS PITCAIRN' },
  { value: 'PM', label: 'SAINT PIERRE AND MIQUELON' },
  { value: 'PR', label: 'PUERTO RICO' },
  { value: 'PT', label: 'PORTUGAL' },
  { value: 'PS', label: 'PALESTINA, TERRITORIO OCUPADO DE' },
  { value: 'PG', label: 'PAPUA NUEVA GUINEA' },
  { value: 'PF', label: 'POLINESIA FRANCESA' },
  { value: 'PH', label: 'FILIPINAS' },
  { value: 'PL', label: 'POLONIA' },
  { value: 'PK', label: 'PAQUISTAN' },
  { value: 'VC', label: 'SAN VICENTE Y LAS GRANADINAS' },
  { value: 'VA', label: 'CIUDAD DEL VATICANO' },
  { value: 'VE', label: 'VENEZUELA' },
  { value: 'VI', label: 'ISLAS VIRGENES(ESTADOUNIDENSES)' },
  { value: 'VG', label: 'ISLAS VIRGENES(INGLESAS)' },
  { value: 'UM', label: 'ISLAS MINOR OUTLYNG' },
  { value: 'UG', label: 'UGANDA' },
  { value: 'US', label: 'ESTADOS UNIDOS' },
  { value: 'UZ', label: 'UZBEKISTAN' },
  { value: 'UY', label: 'URUGUAY' },
  { value: 'ZW', label: 'ZIMBABWE' },
  { value: 'WS', label: 'SAMOA' },
  { value: 'YE', label: 'YEMEN' },
  { value: 'WF', label: 'WALLIS AND FUTUNA' },
  { value: 'VN', label: 'VIETNAM' },
  { value: 'VU', label: 'VANUATU' },
  { value: 'ZM', label: 'ZAMBIA' },
  { value: 'ZR', label: 'REPUBLICA DEMOCRATICA DEL CONGO' },
  { value: 'ZA', label: 'SUDAFRICA' },
  { value: 'YT', label: 'MAYOTTE' },
  { value: 'YU', label: 'YUGOSLAVIA' },
  { value: 'UA', label: 'UCRANIA' },
  { value: 'TF', label: 'FRENCH SOUTHERN TERRITORIES' },
  { value: 'TD', label: 'CHAD' },
  { value: 'TG', label: 'TOGO' },
  { value: 'TJ', label: 'TAYIKISTAN' },
  { value: 'TH', label: 'TAILANDIA' },
  { value: 'SV', label: 'EL SALVADOR' },
  { value: 'ST', label: 'SANTO TOME Y PRINCIPE' },
  { value: 'SY', label: 'SIRIA, REPUBLICA ARABE DE' },
  { value: 'TC', label: 'ISLAS TURKS Y ISLA DE CAICOS' },
  { value: 'SZ', label: 'SWAZILANDIA' },
  { value: 'TK', label: 'ISLAS TOKELAU' },
  { value: 'TT', label: 'TRINIDAD Y TOBAGO' },
  { value: 'TR', label: 'TURQUIA' },
  { value: 'TV', label: 'TUVALU' },
  { value: 'TZ', label: 'TANZANIA, REPUBLICA DE' },
  { value: 'TW', label: 'TAIWAN, PROVINCIA DE CHINA' },
  { value: 'TM', label: 'TURKMENISTAN' },
  { value: 'TL', label: 'TIMOR ORIENTAL' },
  { value: 'TN', label: 'TUNEZ' },
  { value: 'TP', label: 'TIMOR ORIENTAL' },
  { value: 'TO', label: 'TONGA' },
  { value: 'NF', label: 'ISLA DE NORFOLK' },
  { value: 'BE', label: 'BELGICA' },
  { value: 'BF', label: 'BURKINA FASO' },
  { value: 'BB', label: 'BARBADOS, LAS' },
  { value: 'BD', label: 'BANGLADESH' },
  { value: 'BG', label: 'BULGARIA' },
  { value: 'BJ', label: 'BENIN' },
  { value: 'BM', label: 'BERMUDAS, LAS' },
  { value: 'BH', label: 'BAHREIN' },
  { value: 'BI', label: 'BURUNDI' },
  { value: 'BA', label: 'BOSNIA Y HERZEGOVINA' },
  { value: 'AQ', label: 'ANTARTICO' },
  { value: 'AR', label: 'ARGENTINA' },
  { value: 'AN', label: 'ANTILLAS HOLANDESAS' },
  { value: 'AO', label: 'ANGOLA' },
  { value: 'AS', label: 'SAMOA AMERICANA' },
  { value: 'AW', label: 'ARUBA' },
  { value: 'AZ', label: 'AZERBAIYAN' },
  { value: 'AT', label: 'AUSTRIA' },
  { value: 'AU', label: 'AUSTRALIA' },
  { value: 'CF', label: 'REPUBLICA CENTRO – AFRICANA' },
  { value: 'CG', label: 'CONGO' },
  { value: 'CC', label: 'ISLAS COCO' },
  { value: 'CD', label: 'REPUBLICA DEMOCRATICA DEL CONGO' },
  { value: 'CH', label: 'SUIZA' },
  { value: 'IO', label: 'TERRITORIOS BRITANICOS DEL OCEANO INDICO' },
  { value: 'IQ', label: 'IRAK' },
  { value: 'CI', label: 'COSTA DE MARFIL' },
  { value: 'CK', label: 'ISLAS COOK' },
  { value: 'CA', label: 'CANADA' },
  { value: 'BR', label: 'BRASIL' },
  { value: 'BS', label: 'BAHAMAS, LAS' },
  { value: 'BN', label: 'BRUNEI(DARUSSALAM)' },
  { value: 'BO', label: 'BOLIVIA' },
  { value: 'BT', label: 'BUTAN' },
  { value: 'BY', label: 'BIELORRUSIA' },
  { value: 'BZ', label: 'BELICE' },
  { value: 'BV', label: 'ISLAS BOUVET' },
  { value: 'BW', label: 'BOTSWANA' },
  { value: 'AM', label: 'ARMENIA' },
  { value: 'GM', label: 'GAMBIA' },
  { value: 'GN', label: 'GUINEA' },
  { value: 'GI', label: 'GIBRALTAR' },
  { value: 'GL', label: 'GROENLANDIA' },
  { value: 'GP', label: 'GUADALUPE' },
  { value: 'GS', label: 'GEORGIA DEL SUR Y LAS ISLAS SANDWICH' },
  { value: 'GT', label: 'GUATEMALA' },
  { value: 'GQ', label: 'GUINEA ECUATORIAL' },
  { value: 'GR', label: 'GRECIA' },
  { value: 'GH', label: 'GHANA' },
  { value: 'FO', label: 'ISLAS FAROE' },
  { value: 'FR', label: 'FRANCIA' },
  { value: 'FK', label: 'ISLAS MALVINAS' },
  { value: 'FM', label: 'MICRONESIA, ESTADOS FEDERALES DE' },
  { value: 'GA', label: 'GABON' },
  { value: 'GE', label: 'GEORGIA' },
  { value: 'GF', label: 'GUAYANA FRANCESA' },
  { value: 'GB', label: 'GRAN BRETAÑA' },
  { value: 'GD', label: 'GRANADA' },
  { value: 'IN', label: 'INDIA' },
  { value: 'AD', label: 'ANDORRA' },
  { value: 'IE', label: 'IRLANDA' },
  { value: 'IL', label: 'ISRAEL' },
  { value: 'AE', label: 'EMIRATOS ARABES(UNIDOS)' },
  { value: 'AI', label: 'ANGUILLA' },
  { value: 'AL', label: 'ALBANIA' },
  { value: 'AF', label: 'AFGANISTAN' },
  { value: 'AG', label: 'ANTIGUA Y BARBUDA' },
  { value: 'ID', label: 'INDONESIA' },
  { value: 'GY', label: 'GUAYANA' },
  { value: 'HK', label: 'HONG KONG' },
  { value: 'GU', label: 'GUAM' },
  { value: 'GW', label: 'GUINEA - BISSAU' },
  { value: 'HM', label: 'ISLA HEARD  Y ISLA MCDONALD' },
  { value: 'HT', label: 'HAITI' },
  { value: 'HU', label: 'HUNGRIA' },
  { value: 'HN', label: 'HONDURAS' },
  { value: 'HR', label: 'CROACIA' },
  { value: 'LV', label: 'LETONIA' },
  { value: 'LY', label: 'LIBIA' },
  { value: 'LT', label: 'LITUANIA' },
  { value: 'LU', label: 'LUXEMBURGO' },
  { value: 'MA', label: 'MARRUECOS' },
  { value: 'MG', label: 'MADAGASCAR' },
  { value: 'MH', label: 'ISLAS MARSHALL' },
  { value: 'MC', label: 'MONACO' },
  { value: 'MD', label: 'MOLDAVIA, REPUBLICA DE' },
  { value: 'LS', label: 'LESOTHO' },
  { value: 'KZ', label: 'KAZAJISTAN' },
  { value: 'LA', label: 'LAOS, REPUBLICA POPULAR DEMOCRATICA DE' },
  { value: 'KW', label: 'KUWAIT' },
  { value: 'KY', label: 'ISLAS CAIMAN' },
  { value: 'LB', label: 'LIBANO' },
  { value: 'LK', label: 'SRI LANKA' },
  { value: 'LR', label: 'LIBERIA' },
  { value: 'LC', label: 'SANTA LUCIA' },
  { value: 'LI', label: 'LIECHTENSTEIN' },
  { value: 'MW', label: 'MALAWI' },
  { value: 'MX', label: 'MEJICO' },
  { value: 'MU', label: 'MAURICIO(ISL.)' },
  { value: 'MV', label: 'MALDIVAS, LAS' },
  { value: 'MY', label: 'MALASIA' },
  { value: 'NC', label: 'NUEVA CALEDONIA' },
  { value: 'NE', label: 'NIGER' },
  { value: 'MZ', label: 'MOZAMBIQUE' },
  { value: 'NA', label: 'NAMIBIA' },
  { value: 'MT', label: 'MALTA' },
  { value: 'MM', label: 'MYANMAR' },
  { value: 'MN', label: 'MONGOLIA' },
  { value: 'MK', label: 'MACEDONIA' },
  { value: 'ML', label: 'MALI' },
  { value: 'MO', label: 'MACAO' },
  { value: 'MR', label: 'MAURITANIA' },
  { value: 'MS', label: 'MONTSERRAT' },
  { value: 'MP', label: 'ISLA N.MARIANA' },
  { value: 'MQ', label: 'MARTINICA' },
  { value: 'KR', label: 'COREA DEL SUR' },
  { value: 'CL', label: 'CHILE' },
  { value: 'CM', label: 'CAMERUN' },
  { value: 'KM', label: 'COMORAS' },
  { value: 'KN', label: 'SAINT KITTS AND NEVIS' },
  { value: 'CN', label: 'CHINA' },
  { value: 'CU', label: 'CUBA' },
  { value: 'CV', label: 'CABO VERDE' },
  { value: 'CO', label: 'COLOMBIA' },
  { value: 'CR', label: 'COSTA RICA' },
  { value: 'KI', label: 'KIRIBATI' },
  { value: 'JP', label: 'JAPON' },
  { value: 'JO', label: 'JORDANIA' },
  { value: 'KG', label: 'KIRGUIZISTAN' },
  { value: 'KE', label: 'KENIA' },
  { value: 'JM', label: 'JAMAICA' },
  { value: 'IR', label: 'IRAN, REPUBLICA ISLAMICA DE' },
  { value: 'KH', label: 'CAMBOYA' },
  { value: 'IT', label: 'ITALIA' },
  { value: 'IS', label: 'ISLANDIA' },
  { value: 'EH', label: 'SAHARA OCCIDENTAL' },
  { value: 'ER', label: 'ERITREA' },
  { value: 'EE', label: 'ESTONIA' },
  { value: 'EG', label: 'EGIPTO' },
  { value: 'ES', label: 'ESPAÑA' },
  { value: 'FJ', label: 'ISLAS FIJI' },
  { value: 'KP', label: 'COREA DEL NORTE' },
  { value: 'ET', label: 'ETIOPIA' },
  { value: 'FI', label: 'FINLANDIA' },
  { value: 'EC', label: 'ECUADOR' },
  { value: 'CZ', label: 'REPUBLICA CHECA' },
  { value: 'DE', label: 'ALEMANIA' },
  { value: 'CX', label: 'ISLAS NAVIDAD' },
  { value: 'CY', label: 'CHIPRE' },
  { value: 'DJ', label: 'DJIBOUTI' },
  { value: 'DO', label: 'REPUBLICA DOMINICANA' },
  { value: 'DZ', label: 'ARGELIA' },
  { value: 'DK', label: 'DINAMARCA' },
  { value: 'DM', label: 'DOMINICA' },
  { value: 'RS', label: 'SERBIA' },
]

export const NATIONALITIES = {
  [ID_DOC_TYPES.nie.value]: sortby(
    ALL_NATIONALITIES.filter(({ value }) => value !== SPAIN_NATIONALITY.value),
    ['label']
  ),
  [ID_DOC_TYPES.passport.value]: sortby(ALL_NATIONALITIES, ['label']),
}

export const ID_DOC_TYPES_PYMES = {
  cif: {
    label: 'form.fields.cif',
    value: 'CIF',
  },
  nif: {
    label: 'form.fields.nifdni',
    value: 'NIF',
  },
  nie: {
    label: 'form.fields.nie',
    value: 'NIE',
  },
  passport: {
    label: 'form.fields.passport',
    value: 'Pasaporte',
  },
}

export const LANGUAGES = {
  spanish: 'language.spanish',
  catalan: 'language.catalan',
  euskera: 'language.euskera',
  galician: 'language.galician',
}

export const SELECTOR_LANGUAGES = {
  spanish: { label: 'language.spanish', value: 'Castellano' },
  catalan: { label: 'language.catalan', value: 'Catalán' },
  euskera: { label: 'language.euskera', value: 'Euskera' },
  galician: { label: 'language.galician', value: 'Gallego' },
}

export const ENERGY_USE_TYPES = {
  commercial: 'form.fields.commercial',
  domestic: 'form.fields.domestic',
  community_of_owners: 'form.fields.comOwners',
  // official_organisms: 'form.fields.officialOrgs',
}

export const DOC_TYPES_LABEL = {
  nif: 'form.fields.nifdni',
  nie: 'form.fields.nie',
  pasaporte: 'form.fields.passport',
  cif: 'form.fields.cif',
}

export const ENERGY_USES = {
  commercial: 'Comercial',
  community_of_owners: 'Com. Propietarios',
  // official_organisms: 'Org. Oficiales',
}

export const ENERGY_USE_ONLINE_CHANNEL_PARAMS = {
  commercial: 'comercial',
  community_of_owners: 'ccpp',
}

export const LANG_CODES = ['es', 'ca', 'gl']

export const SERVICES = {
  [ELECTRICITY]: 'services.electricity',
  [GAS]: 'services.gas',
  [BOTH]: 'services.both',
}
export const SERVICES_TECH = {
  [ELECTRICITY.toLowerCase()]: 'services.electricity',
  [GAS.toLowerCase()]: 'services.gas',
}

export const GA_FILTERS_SERVICE = {
  [GAS]: 'gas',
  [ELECTRICITY]: 'luz',
  [BOTH]: 'luzygas',
}

export const GA_PRODUCT_TYPES = {
  [RATE]: 'rate',
  [CAMPAIGN]: 'campaign',
  [TOPPINGS]: 'toppings',
  [TOPPING]: 'topping',
}

export const RATE_TYPES = {
  flatRate: 'plana',
  usageFee: 'por-uso',
}

export const GA_EVENT_NAMES = {
  logo: 'logo',
  autoSave: 'autoSave',
  autoSavePopUp: 'autoSavePopUp',
  cancelPopUp: 'cancelPopUp',
  savePopUp: 'savePopUp',
  closePopUp: 'closePopUp',
  ownerAlert: 'ownerAlert',
  menuLanguage: 'menuLanguage',
  selectionLanguage: 'selectionLanguage',
  weCallYou: 'weCallYou',
  weCallYouPopUp: 'weCallYouPopUp',
  callMePopUp: 'callMePopUp',
  telNum: 'telNum',
  clickPrices: 'clickTariffDetail',
  showPricesModal: 'showTariffModal',
  closePricesModal: 'closePricesModal',
  conditionsProductClick: 'conditionsRateClick',
  conditionsPopUp: 'conditionsPopUp',
  cancelConditionsPopUp: 'cancelConditionsPopUp',
  closeConditionsPopUp: 'closeConditionsPopUp',
  discountsConditionsClick: 'discountsConditionsClick',
  discountsConditionsPopUp: 'discountsConditionsPopUp',
  cancelDiscountPopUp: 'cancelDiscountPopUp',
  closeDiscountPopUp: 'closeDiscountPopUp',
  checkChangeSupplyOwnership: 'checkChangeSupplyOwnership',
  privacyPolicy: 'privacyPolicy',
  checkAcceptancePrivacyPolicy: 'checkAcceptancePrivacyPolicy',
  clickBonoSocial: 'clickBonoSocial',
  clickContinue: 'clickContinue',
  aceptoModal: 'aceptoModal',
  noAceptoModal: 'noAceptoModal',
  openHelpModal: 'openHelpModal',
  validateAndSign: 'validateAndSign',
  signContractEvent: 'signContractEvent',
  clickTopping: 'clickTopping',
  thankYouPage: 'thankYouPage',
  editStep: 'editStep',
  uploadInvoiceImage: 'uploadInvoiceImage',
  selectCupsByCups: 'selectCupsByCups',
  selectCupsByAddress: 'selectCupsByAddress',
  clickNNSS: 'clickNNSS',
  checkNNSS: 'checkNNSS',
  continueNNSS: 'continueNNSS',
  enterCheckState: 'enterCheckState',
  infoInvoice: 'infoInvoice',
  openInfoModal: 'openInfoModal',
  closeInfoModal: 'closeInfoModal',
  cupsInfo: 'cupsInfo',
  openCupsInfo: 'openCupsInfo',
  closeCupsInfo: 'closeCupsInfo',
  editSupplyPoint: 'editSupplyPoint',
  openNewClientModal: 'openNewClientModal',
  cancelNewSupply: 'cancelNewSupply',
  newSupplyTechModal: 'newSupplyTechModal',
  popUpUploadImage: 'popUpUploadImage',
  popUpSelectFile: 'popUpSelectFile',
  clickSelectFile: 'clickSelectFile',
  clickContinueUploadImage: 'clickContinueUploadImage',
  editCupsPopUp: 'editCupsPopUp',
  saveCupsPopUp: 'saveCupsPopUp',
  cancelCupsPopUp: 'cancelCupsPopUp',
  finishCupsPopUp: 'finishCupsPopUp',
  errorCupsPopUp: 'errorCupsPopUp',
  loginClick: 'loginClick',
  differentBilling: 'differentBilling',
  clickSEPA: 'clickSEPA',
  clickServiceConditions: 'clickServiceConditions',
  openServiceConditions: 'openServiceConditions',
  closeServiceConditions: 'closeServiceConditions',
  clickSericeSegment: 'clickSericeSegment',
  openPopUpTramit: 'openPopUpTramit',
  closeDetailModal: 'closeDetailModal',
  leadError: 'leadError',
  // selectService: 'selectService',
  // changeCampaignClick: 'changeCampaignClick',
  // selectRate: 'selectRate',
  // selectCups: 'selectCups',
  // chooseAnotherRate: 'chooseAnotherRate',
  // callMeBackButton: 'callMeBackButton',
  // nextStep: 'nextStep',
  // newContract: 'newContract',
  // calculatorEvents: 'calculatorEvents',
  // checkoutEvent: 'checkoutEvent',
  // signContractEvent: 'signContractEvent',
  // serviceDetails: 'serviceDetails',
  // clickServiceDetails: 'clickServiceDetails',
  // callMeBackClick: 'callMeBackClick',
  // selectTopping: 'selectTopping',

  // callMeBackClickPhone: 'callMeBackClickPhone',
  // clickLogin: 'clickLogin',
  // sircaFail: 'sircaFail',
  // callMeBackCallMe: 'callMeBackCallMe',
  // loadThankYouPage: 'loadThankYouPage',
}

export const CUPS_SELECTOR_BY_CUPS_ACTION = 'selectCupsByCups'
export const CUPS_SELECTOR_BY_ADDRESS_ACTION = 'selectCupsByAddress'

export const GA_EVENT_ACTIONS = {
  [GA_EVENT_NAMES.selectCups]: ['selectCupsByCups', 'selectCupsByAddress'],
}

export const GA_SERVICES = {
  [ELECTRICITY]: 'Luz',
  [GAS]: 'Gas',
  [BOTH]: 'LuzyGas',
  [BOTH_CALCULATOR]: 'LuzyGas',
}

export const ADDRESS_OPTIONS = {
  [ADDRESS]: 'supply.address',
  [CUPS]: 'supply.cups',
}

export const SPF_AGE_LIMITS = {
  max: 74,
  min: 18,
}

export const CHANNEL_TYPES = {
  online: ['ONLINE'],
  phone: ['VT_INBOUND', 'VT_OUTBOUND', 'VT_VERIFICATION'],
  faceToFace: ['PRESENCIAL', 'PRESENCIAL_VERIFICATION'],
  serviceHome: ['SERVICE_HOME'],
  web: ['WEB'],
}

export const PHONE_CHANNELS = {
  VT_INBOUND: 'C2C',
  VT_OUTBOUND: 'CMB',
}

export const VT_OUTBOUND = 'VT_OUTBOUND'

export const SPF_NAME = 'seguro-proteccion-facturas'

export const CHANNEL_NAMES = {
  tiendas: { name: 'Tiendas', hasTenure: true },
  nuevosColaboradores: { name: 'Nuevos Colaboradores', hasTenure: true },
  expansion: { name: 'Expansión', hasTenure: true },
  operaciones: { name: 'Operaciones', hasTenure: true },
  ventaTelefonicaOutbound: { name: 'Venta Telefónica Outbound', hasTenure: false },
  fuerzaVentas: { name: 'Fuerza de Ventas', hasTenure: true },
  ventaRemoto: { name: 'Venta en Remoto', hasTenure: true },
  digitalPartners: { name: 'Digital Partners', hasTenure: false },
  ventaRemotoLocal: { name: 'Venta en Remoto Local', hasTenure: true },
  serviRenting: { name: 'servirenting', hasTenure: true },
  portabilidad: { name: 'Portabilidad', hasTenure: true },
  sae: { name: 'SAE', hasTenure: true },
  fuaAgente: { name: 'FUA_Agente', hasTenure: true },
  fuaCliente: { name: 'FUA_Cliente', hasTenure: true },
  mayorista: { name: 'Mayorista', hasTenure: true },
}

export const TOPPING_TYPES = {
  [ELECTRICITY]: 'SVE',
  [GAS]: 'SVG',
}

export const VARIANT_TYPES = {
  [ELECTRICITY]: 'Electricidad',
  [GAS]: 'Gas',
}

export const TOPPINGS_SERVICE_TYPE = {
  SVE: 'services.electricity',
  SVG: 'services.gas',
  SVH: 'services.home',
  SPF: 'services.insurances',
}

export const ORDER_STATES = [
  'incomplete',
  'to_sign',
  'scoring_failed',
  'cancelled',
  'pending_verification',
  'signed',
  'in_activation',
  'failed',
  'finished',
]

export const ORDER_FILTERS = {
  incomplete: ['draft', 'filled'],
  to_sign: [
    'signature_requested',
    'signature_requested-locution_success',
    'signature_success-locution_requested',
    'signature_requested-locution_requested',
    'locution_requested',
    'sirca_requested',
    'sirca_success',
    'validation_requested',
    'validation_success',
  ],
  scoring_failed: ['sirca_failed'],
  cancelled: ['cancelled'],
  pending_verification: ['pending_verification'],
  signed: ['signed', 'submitted'],
  in_activation: ['in_activation'],
  failed: [
    'failed',
    'signature_requested-locution_failed',
    'signature_success-locution_failed',
    'signature_failed-locution_success',
    'signature_failed-locution_requested',
  ],
  finished: ['succeeded'],
}

export const ORDER_ALLOWED_FILTERS = [
  'orderType',
  'orderColumn',
  'createdFrom',
  'createdTo',
  'failedDateFrom',
  'failedDateTo',
  'ageFrom',
  'ageTo',
  'searchText',
  'templateId',
  'supplyType',
  'productType',
  'company',
  'segment',
  'product',
]
export const ORDER_LINES_ALLOWED_FILTERS = [
  'orderType',
  'orderColumn',
  'createdFrom',
  'createdTo',
  'failedDateFrom',
  'failedDateTo',
  'ageFrom',
  'ageTo',
  'searchText',
  'productType',
  'family',
  'contractType',
  'templateId',
  'company',
]
export const STATES_OPTIONS = [
  {
    id: '1',
    name: 'validation_error',
    value: '0',
  },
  {
    id: '2',
    name: 'pending_insert',
    value: '1',
  },
  {
    id: '3',
    name: 'inserted',
    value: '2',
  },
  {
    id: '4',
    name: 'submitted',
    value: '3',
  },
]

export const SPECIFIC_ALLOWED_FILTERS = ['page', 'nbItems', 'pagination']
export const ORDER_PORTABILITY_ALLOWED_FILTERS = [
  'nbItems',
  'page',
  'orderType',
  'orderColumn',
  'createdFrom',
  'createdTo',
  'type',
  'state',
  'search',
  'type_search',
]
export const ORDER_SUMMARIES_ALLOWED_FILTERS = [
  'states',
  'createdFrom',
  'createdTo',
  'failedDateTo',
  'failedDateFrom',
  'ageFrom',
  'ageTo',
  'channelIds',
  'family',
  'productType',
]
export const VISUALIZATION_ALLOWED_FILTERS = [
  'createdFrom',
  'createdTo',
  'failedDateTo',
  'failedDateFrom',
  'products',
  'states',
  'stateGroup', // Overrides states
  'channelIds',
  'companyName',
]

export const NOTIFICATIONS_ALLOWED_FILTERS = ['orderColumn']

export const NOTIFICATIONS_SPECIFIC_ALLOWED_FILTERS = ['page', 'itemsPerPage']

export const PRODUCT_STATES = [
  'pending_documentation',
  'documentation_received',
  'atr_requested',
  'request_sent',
  'request_accepted',
  'request_rejected',
  'energy_activation_pending',
  'request_activated',
  'inactive',
]

export const CONTRACT_STATES = [
  'to_be_recorded_transactional',
  'pending_documentation',
  'documentation_received',
  'atr_requested',
  'request_sent',
  'request_accepted',
  'request_rejected',
  'pendign',
  'energy_activation_pending',
  'validation_pending',
  'to_be_completed',
  'action',
  'active',
  'inactive',
  'cancelled',
]

export const PRODUCT_FILTERS = {
  pending_documentation: ['pending_documentation'],
  documentation_received: ['documentation_received'],
  atr_requested: ['atr_requested'],
  request_sent: ['request_sent'],
  request_accepted: ['request_accepted'],
  request_rejected: ['request_rejected'],
  energy_activation_pending: ['energy_activation_pending'],
  request_activated: ['request_activated'],
  inactive: ['inactive'],
}

export const RECOMMENDATION_CODE_LENGTH = 9
export const EXTERNAL_WRITE_ID_LENGTH = 9

// Esta constante se está clonando con spread,
// si se añaden subniveles de props, afectará a la lógica
export const MAINTENANCE_CONSTANTS = {
  maintenanceOptionsNewco: {
    SVE: 'common.sve',
    SVG: 'common.svg',
    SVH: 'common.svh',
    OTR: 'common.otr',
  },
  maintenanceOptionsIberia: {
    SVE: 'common.sve',
    SVG: 'common.svg',
    SVH: 'common.svh',
    OTR: 'common.otr',
    all: 'common.all',
  },
  defaultMaintenance: 'SVE',
}

// Change capacity input config

export const CAPACITY_INPUT_CONFIG = {
  '2.0TD': {
    min: 0.2,
    step: 0.1,
    max: 15,
  },
  '3.0TD': {
    min: 1,
    step: 0.1,
  },
  '6.XTD': {
    min: 1,
    step: 0.1,
  },
}

export const CAPACITY_FIELD_ALLOWED_KEYS = [38, 40, 9, 13, 27, 32, 16]

export const COMMERCIAL_MANUAL_URL = 'https://docs.google.com/document/d/1FCHmWpQTVfm8M_Sxp_lhz9TKBri0yLEv/export?format=pdf'
export const BACKOFFICE_MANUAL_URL = 'https://docs.google.com/document/d/10SspDuJdJu0ttoQYQn4Qqw4znZIh3-2p/export?format=pdf'
export const PYMES_MANUAL_URL = 'https://docs.google.com/document/d/1uRGIzVDRyyMboM6iQEmNYoBV3g2dSiM6/export?format=pdf'
export const ADMIN_PANEL_MANUAL_URL = 'https://docs.google.com/document/d/1QxH9nmoJ8GCI98_a-1Yp9Hb3rv55Qmxa/export?format=pdf'
export const CHANNELS_MANUAL_URL = 'https://docs.google.com/document/d/106NUQfF8uR7jwgpKPk7teoL07fZgXP7S/export?format=pdf'

export const CANCEL_ORDER_REASONS = ['Venta duplicada', 'Usuario no confirma la venta', 'Otros']
export const CANCEL_ORDER_DETAILS_CHARACTER_LIMIT = 150

export const ONLINE_CHANNEL_PARAM_NOT_FOUND_URL = {
  productNotFound: '/product-not-found',
  calculationNotValid: '/calculation-not-valid',
  referenceSegmentNotValid: '/src-not-valid',
  userOnlineNotFound: '/user-not-found',
}

export const NEW_MODE = 'new'
export const EDIT_MODE = 'edit'

export const CUSTOM_EQUIPMENT_OWNERSHIP = {
  rental: 'Alquiler',
  ownership: 'Propiedad',
}

export const PLANNED_DISCHARGE_DATE = {
  asap: 'Cuanto antes',
  fixedDate: 'Fecha fija',
}

export const CURRENT_TARIFFS = ['2.0TD', '3.0TD', '6.1TD', '6.2TD', '6.3TD', '6.4TD']

export const REQUIRED_DOCUMENTS_ELECTRICITY = {
  CIE: 'requiredDocuments.cie',
}

export const REQUIRED_DOCUMENTS_GAS = {
  IRI: 'requiredDocuments.iri',
}

export const REQUIRED_DOCUMENTS_BOTH = {
  CIE: 'requiredDocuments.cie',
  IRI: 'requiredDocuments.iri',
}

// TODO: limpiar constantes, no hay que diferenciar entre pymes y residencial
export const REQUIRED_DOCUMENTS_ELECTRICITY_RESIDENTIAL = {
  NIF: 'NIE / Pasaporte o NIF del contratante',
  CIE: 'CIE para suministros eléctricos',
  APM: 'APM (Acta de puesta en marcha)',
  ENT: 'Enterado de industria',
}

export const REQUIRED_DOCUMENTS_GAS_RESIDENTIAL = {
  NIF: 'NIE / Pasaporte o NIF del contratante',
  IRI: 'Certificado de instalación de gas (IRI)',
  RITE: 'RITE',
  APM: 'APM (Acta de puesta en marcha)',
}

export const REQUIRED_DOCUMENTS_BOTH_RESIDENTIAL = {
  NIF: 'NIE / Pasaporte o NIF del contratante',
  CIE: 'CIE para suministros eléctricos',
  IRI: 'Certificado de instalación de gas (IRI)',
  APM: 'APM (Acta de puesta en marcha)',
  ENT: 'Enterado de industria',
  RITE: 'RITE',
}

export const REQUIRED_DOCUMENTS_ELECTRICITY_PYME = {
  CIF: 'CIF del contratante',
  NIF: 'NIE / Pasaporte o NIF del contratante',
  CIE: 'CIE para suministros eléctricos',
  APM: 'APM (Acta de puesta en marcha)',
  ENT: 'Enterado de industria',
}

export const REQUIRED_DOCUMENTS_GAS_PYME = {
  CIF: 'CIF del contratante',
  NIF: 'NIE / Pasaporte o NIF del contratante',
  IRI: 'Certificado de instalación de gas (IRI)',
  APM: 'APM (Acta de puesta en marcha)',
  RITE: 'RITE',
}

export const REQUIRED_DOCUMENTS_BOTH_PYME = {
  CIF: 'CIF del contratante',
  NIF: 'NIE / Pasaporte o NIF del contratante',
  CIE: 'CIE para suministros eléctricos',
  APM: 'APM (Acta de puesta en marcha)',
  ENT: 'Enterado de industria',
}

export const POSITIONS = ['Gerente', 'Apoderado', 'Representante', 'Administradores']

export const COL_NNSS_VALUES = {
  isNewSupply: 'true',
  isNotNewSupply: 'false',
}

export const COMPANY_CODES = {
  newco: 'NewCo',
  gns: 'GNS',
}

export const COMPANY_ID_CODES = {
  [ID_NATURGY_CLIENTES]: 'NewCo',
  [ID_NATURGY_IMPERIAL]: 'GNS',
}

export const COMPANY_NAMES = {
  [ID_NATURGY_CLIENTES]: 'Naturgy Clientes',
  [ID_NATURGY_IMPERIAL]: 'Naturgy',
}

export const COMPANY_NAMES_BY_CODE = {
  [COMPANY_CODES.newco.toLocaleUpperCase()]: COMPANY_NAMES[ID_NATURGY_CLIENTES],
  [COMPANY_CODES.gns.toLocaleUpperCase()]: COMPANY_NAMES[ID_NATURGY_IMPERIAL],
}

export const tarifs = {
  [ELECTRICITY]: [
    '2.0A',
    '2.0DHA',
    '2.0DHS',
    '2.1A',
    '2.1DHA',
    '2.1DHS',
    '3.0A',
    '3.1A',
    '6.1',
    '6.2',
    '6.3',
    '6.4',
    '2.0TD',
    '3.0TD',
    '6.1TD',
    '6.2TD',
    '6.3TD',
    '6.4TD',
  ],
  [GAS]: [
    '3.1',
    '3.2',
    '3.3',
    '3.4',
    'RL.1',
    'RL.2',
    'RL.3',
    'RL.4',
    'RLTA.5',
    'RLTB.5',
    'RLTA.6',
    'RLTB.6',
    'RLTA.7',
    'RLTB.7',
    'RL.8',
    'RL.9',
    'RL.10',
    'RL.11',
    'RLPS.1',
    'RLPS.2',
    'RLPS.3',
    'RLPS.4',
    'RLPS.5',
    'RLPS.6',
    'RLPS.7',
    'RLPS.8',
  ],
}

export const installationTypes = {
  M: 'MONOFÁSICA',
  T: 'TRIFÁSICA',
}

export const cieVoltageOptions = {
  '01': 'cieVoltage.01',
  '02': 'cieVoltage.02',
  '03': 'cieVoltage.03',
  '04': 'cieVoltage.04',
  '05': 'cieVoltage.05',
  '06': 'cieVoltage.06',
  '07': 'cieVoltage.07',
  '08': 'cieVoltage.08',
  '09': 'cieVoltage.09',
  10: 'cieVoltage.10',
  11: 'cieVoltage.11',
  12: 'cieVoltage.12',
  13: 'cieVoltage.13',
  14: 'cieVoltage.14',
  15: 'cieVoltage.15',
  16: 'cieVoltage.16',
  17: 'cieVoltage.17',
  18: 'cieVoltage.18',
  19: 'cieVoltage.19',
  20: 'cieVoltage.20',
  21: 'cieVoltage.21',
  22: 'cieVoltage.22',
  23: 'cieVoltage.23',
  24: 'cieVoltage.24',
  25: 'cieVoltage.25',
  26: 'cieVoltage.26',
  27: 'cieVoltage.27',
  28: 'cieVoltage.28',
  29: 'cieVoltage.29',
  30: 'cieVoltage.30',
  31: 'cieVoltage.31',
  32: 'cieVoltage.32',
  33: 'cieVoltage.33',
  34: 'cieVoltage.34',
  35: 'cieVoltage.35',
  36: 'cieVoltage.36',
  37: 'cieVoltage.37',
  38: 'cieVoltage.38',
  39: 'cieVoltage.39',
  40: 'cieVoltage.40',
  41: 'cieVoltage.41',
  42: 'cieVoltage.42',
  43: 'cieVoltage.43',
  44: 'cieVoltage.44',
  45: 'cieVoltage.45',
  46: 'cieVoltage.46',
  47: 'cieVoltage.47',
  48: 'cieVoltage.48',
  49: 'cieVoltage.49',
  99: 'cieVoltage.99',
}

export const cieSupplyTypeOptions = {
  AL: 'cieSupplyType.AL',
  AP: 'cieSupplyType.AP',
  AS: 'cieSupplyType.AS',
  AT: 'cieSupplyType.AT',
  BA: 'cieSupplyType.BA',
  CM: 'cieSupplyType.CM',
  EA: 'cieSupplyType.EA',
  ES: 'cieSupplyType.ES',
  FT: 'cieSupplyType.FT',
  FV: 'cieSupplyType.FV',
  GA: 'cieSupplyType.GA',
  GB: 'cieSupplyType.GB',
  HP: 'cieSupplyType.HP',
  IN: 'cieSupplyType.IN',
  IT: 'cieSupplyType.IT',
  KC: 'cieSupplyType.KC',
  LB: 'cieSupplyType.LB',
  LC: 'cieSupplyType.LC',
  OF: 'cieSupplyType.OF',
  PC: 'cieSupplyType.PC',
  RA: 'cieSupplyType.RA',
  RT: 'cieSupplyType.RT',
  SA: 'cieSupplyType.SA',
  SC: 'cieSupplyType.SC',
  SE: 'cieSupplyType.SE',
  SG: 'cieSupplyType.SG',
  SM: 'cieSupplyType.SM',
  SO: 'cieSupplyType.SO',
  TL: 'cieSupplyType.TL',
  TR: 'cieSupplyType.TR',
  UF: 'cieSupplyType.UF',
  UV: 'cieSupplyType.UV',
  VI: 'cieSupplyType.VI',
  VE: 'cieSupplyType.VE',
}
export const cieValidityScopeOptions = {
  AU: 'cieValidityScope.AU',
  ES: 'cieValidityScope.ES',
  PR: 'cieValidityScope.PR',
  LO: 'cieValidityScope.LO',
}

export const gnsCieVoltageOptionsValues = {
  'cieVoltage.01': '1X220',
  'cieVoltage.02': '1X230',
  'cieVoltage.03': '3X380',
  'cieVoltage.04': '3X380/220',
  'cieVoltage.05': '3X400',
  'cieVoltage.06': '3X400/230',
  'cieVoltage.07': '1X127',
  'cieVoltage.08': '1X133',
  'cieVoltage.09': '2X220',
  'cieVoltage.10': '2X230',
  'cieVoltage.11': '3X220',
  'cieVoltage.12': '3X220/127',
  'cieVoltage.13': '3X230',
  'cieVoltage.14': '3X230/133',
  'cieVoltage.15': '5.000',
  'cieVoltage.16': '6.000',
  'cieVoltage.17': '7.200',
  'cieVoltage.18': '8.000',
  'cieVoltage.19': '10.000',
  'cieVoltage.20': '11.000',
  'cieVoltage.21': '12.000',
  'cieVoltage.22': '13.000',
  'cieVoltage.23': '13.200',
  'cieVoltage.24': '15.000',
  'cieVoltage.25': '16.500',
  'cieVoltage.26': '17.000',
  'cieVoltage.27': '20.000',
  'cieVoltage.28': '22.000',
  'cieVoltage.29': '25.000',
  'cieVoltage.30': '26.500',
  'cieVoltage.31': '30.000',
  'cieVoltage.32': '36.000',
  'cieVoltage.33': '44.000',
  'cieVoltage.34': '45.000',
  'cieVoltage.35': '66.000',
  'cieVoltage.36': '110.000',
  'cieVoltage.37': '132.000',
  'cieVoltage.38': '220.000',
  'cieVoltage.39': '400.000',
  'cieVoltage.40': '1.200',
  'cieVoltage.41': '2.000',
  'cieVoltage.42': '5.500',
  'cieVoltage.43': '55.000',
  'cieVoltage.44': '130.000',
  'cieVoltage.45': '100',
  'cieVoltage.46': '150',
  'cieVoltage.47': '3x100',
  'cieVoltage.48': '3x150/260',
  'cieVoltage.49': '3x260',
  'cieVoltage.99': 'Otra tensión obsoleta',
}

export const gnsCieValidityScopeOptionsValues = {
  'cieValidityScope.ES': 'Estatal',
  'cieValidityScope.AU': 'Autonómico',
  'cieValidityScope.PR': 'Provincial',
  'cieValidityScope.LO': 'Local',
}

export const ADMIN_EXTERNAL_IDS = [
  '00073002',
  '00073012',
  '00073017',
  '00073263',
  '00073525',
  '00083113',
  '00012216',
  '00073522',
  '00800563',
  '00022893',
  '00070118',
  '90076458',
  '90080587',
  '90079366',
  '90076016',
  '90079935',
  '90079895',
  '90076494',
  '90076862',
  '90079224',
  '90074093',
  '00022149',
  '00083027',
  '00083014',
  '00022170',
  '00022706',
  'UF337130',
  '00011758',
  '00011868',
  'UF692070',
  '00070145',
  '00070154',
  '00070241',
  '00022368',
  '00073466',
  'UF739001',
  '00073267',
  '00012227',
  '00012226',
  '00021950',
  '00073016',
  '00073183',
  '00073032',
  '00073483',
  '00049044',
  '00022085',
  '00073034',
  'UF036550',
  '00073185',
  '90083410',
  '90079368',
  '00073341',
  '00011870',
  '00012212',
  '00012219',
  '00012215',
  '00073236',
  '00073277',
  'UF018280',
  '90083754',
  '00055003',
  '00073561',
  '00800683',
  '00073341',
  '00022710',
  '00022893',
  '90027680',
  '90012174',
  '90079607',
  '90050158',
  'aperezto',
  '00012097',
  '00073563',
  '00800740',
  '00800719',
  '90088793',
  '90089202',
  '00038407',
  'jperezpar',
  'adriangarcia',
  '00070166',
  '00070352',
  '00022822',
  '00800800',
  '90083962',
  '90083960',
  '90089631',
  '00073085',
  '00075001',
  '90051868',
  '00073547',
  'nacosta',
  '90014901',
  'UF256580',
  '90080560',
  'arminel.brito',
  '90080560',
  '00038407',
  '00070118',
  '00083113',
  '00011870',
  '00012213',
  '00011758',
  '00038407',
  '00070118',
  '00083113',
]

export const EMERGYA_EVENTS_TYPES_RESTRICTED = ['noVertical']

export const BACKOFFICE = 'backoffice'

export const MAX_NUMBER_ELECTRICITY_SUPPLY_POINTS = 40
export const MAX_NUMBER_GAS_SUPPLY_POINTS = 20

export const DEFAULT_MAX_CONSUMTION_ALLOWED = 400000

export const VALID_CNAES = {
  '01': 'Agricultura, ganadería, caza y servicios relacionados con las mismas',
  '011': 'Cultivos no perennes',
  '0111': 'Cultivo de cereales (excepto arroz), leguminosas y semillas oleaginosas',
  '0112': 'Cultivo de arroz',
  '0113': 'Cultivo de hortalizas, raíces y tubérculos',
  '0114': 'Cultivo de caña de azúcar',
  '0115': 'Cultivo de tabaco',
  '0116': 'Cultivo de plantas para fibras textiles',
  '0119': 'Otros cultivos no perennes',
  '012': 'Cultivos perennes',
  '0121': 'Cultivo de la vid',
  '0122': 'Cultivo de frutos tropicales y subtropicales',
  '0123': 'Cultivo de cítricos',
  '0124': 'Cultivo de frutos con hueso y pepitas',
  '0125': 'Cultivo de otros árboles y arbustos frutales y frutos secos',
  '0126': 'Cultivo de frutos oleaginosos',
  '0127': 'Cultivo de plantas para bebidas',
  '0128': 'Cultivo de especias, plantas aromáticas, medicinales y farmacéuticas',
  '0129': 'Otros cultivos perennes',
  '013': 'Propagación de plantas',
  '0130': 'Propagación de plantas',
  '014': 'Producción ganadera',
  '0141': 'Explotación de ganado bovino para la producción de leche',
  '0142': 'Explotación de otro ganado bovino y búfalos',
  '0143': 'Explotación de caballos y otros equinos',
  '0144': 'Explotación de camellos y otros camélidos',
  '0145': 'Explotación de ganado ovino y caprino',
  '0146': 'Explotación de ganado porcino',
  '0147': 'Avicultura',
  '0149': 'Otras explotaciones de ganado',
  '015': 'Producción agrícola combinada con la producción ganadera',
  '0150': 'Producción agrícola combinada con la producción ganadera',
  '016': 'Actividades de apoyo a la agricultura, a la ganadería y de preparación posterior a la cosecha',
  '0161': 'Actividades de apoyo a la agricultura',
  '0162': 'Actividades de apoyo a la ganadería',
  '0163': 'Actividades de preparación posterior a la cosecha',
  '0164': 'Tratamiento de semillas para reproducción',
  '017': 'Caza, captura de animales y servicios relacionados con las mismas',
  '0170': 'Caza, captura de animales y servicios relacionados con las mismas',
  '02': 'Silvicultura y explotación forestal',
  '021': 'Silvicultura y otras actividades forestales',
  '0210': 'Silvicultura y otras actividades forestales',
  '022': 'Explotación de la madera',
  '0220': 'Explotación de la madera',
  '023': 'Recolección de productos silvestres, excepto madera',
  '0230': 'Recolección de productos silvestres, excepto madera',
  '024': 'Servicios de apoyo a la silvicultura',
  '0240': 'Servicios de apoyo a la silvicultura',
  '03': 'Pesca y acuicultura',
  '031': 'Pesca',
  '0311': 'Pesca marina',
  '0312': 'Pesca en agua dulce',
  '032': 'Acuicultura',
  '0321': 'Acuicultura marina',
  '0322': 'Acuicultura en agua dulce',
  '05': 'Extracción de antracita, hulla y lignito',
  '051': 'Extracción de antracita y hulla',
  '0510': 'Extracción de antracita y hulla',
  '052': 'Extracción de lignito',
  '0520': 'Extracción de lignito',
  '06': 'Extracción de crudo de petróleo y gas natural',
  '061': 'Extracción de crudo de petróleo',
  '0610': 'Extracción de crudo de petróleo',
  '062': 'Extracción de gas natural',
  '0620': 'Extracción de gas natural',
  '07': 'Extracción de minerales metálicos',
  '071': 'Extracción de minerales de hierro',
  '0710': 'Extracción de minerales de hierro',
  '072': 'Extracción de minerales metálicos no férreos',
  '0721': 'Extracción de minerales de uranio y torio',
  '0729': 'Extracción de otros minerales metálicos no férreos',
  '08': 'Otras industrias extractivas',
  '081': 'Extracción de piedra, arena y arcilla',
  '0811': 'Extracción de piedra ornamental y para la construcción, piedra caliza, yeso, creta y pizarra',
  '0812': 'Extracción de gravas y arenas; extracción de arcilla y caolín',
  '089': 'Industrias extractivas n.c.o.p.',
  '0891': 'Extracción de minerales para productos químicos y fertilizantes',
  '0892': 'Extracción de turba',
  '0893': 'Extracción de sal',
  '0899': 'Otras industrias extractivas n.c.o.p.',
  '09': 'Actividades de apoyo a las industrias extractivas',
  '091': 'Actividades de apoyo a la extracción de petróleo y gas natural',
  '0910': 'Actividades de apoyo a la extracción de petróleo y gas natural',
  '099': 'Actividades de apoyo a otras industrias extractivas',
  '0990': 'Actividades de apoyo a otras industrias extractivas',
  10: 'Industria de la alimentación',
  101: 'Procesado y conservación de carne y elaboración de productos cárnicos',
  1011: 'Procesado y conservación de carne',
  1012: 'Procesado y conservación de volatería',
  1013: 'Elaboración de productos cárnicos y de volatería',
  102: 'Procesado y conservación de pescados, crustáceos y moluscos',
  1021: 'Procesado de pescados, crustáceos y moluscos',
  1022: 'Fabricación de conservas de pescado',
  103: 'Procesado y conservación de frutas y hortalizas',
  1031: 'Procesado y conservación de patatas',
  1032: 'Elaboración de zumos de frutas y hortalizas',
  1039: 'Otro procesado y conservación de frutas y hortalizas',
  104: 'Fabricación de aceites y grasas vegetales y animales',
  1042: 'Fabricación de margarina y grasas comestibles similares',
  1043: 'Fabricación de aceite de oliva',
  1044: 'Fabricación de otros aceites y grasas',
  105: 'Fabricación de productos lácteos',
  1052: 'Elaboración de helados',
  1053: 'Fabricación de quesos',
  1054: 'Preparación de leche y otros productos lácteos',
  106: 'Fabricación de productos de molinería, almidones y productos amiláceos',
  1061: 'Fabricación de productos de molinería',
  1062: 'Fabricación de almidones y productos amiláceos',
  107: 'Fabricación de productos de panadería y pastas alimenticias',
  1071: 'Fabricación de pan y de productos frescos de panadería y pastelería',
  1072: 'Fabricación de galletas y productos de panadería y pastelería de larga duración',
  1073: 'Fabricación de pastas alimenticias, cuscús y productos similares',
  108: 'Fabricación de otros productos alimenticios',
  1081: 'Fabricación de azúcar',
  1082: 'Fabricación de cacao, chocolate y productos de confitería',
  1083: 'Elaboración de café, té e infusiones',
  1084: 'Elaboración de especias, salsas y condimentos',
  1085: 'Elaboración de platos y comidas preparados',
  1086: 'Elaboración de preparados alimenticios homogeneizados y alimentos dietéticos',
  1089: 'Elaboración de otros productos alimenticios n.c.o.p.',
  109: 'Fabricación de productos para la alimentación animal',
  1091: 'Fabricación de productos para la alimentación de animales de granja',
  1092: 'Fabricación de productos para la alimentación de animales de compañía',
  11: 'Fabricación de bebidas',
  110: 'Fabricación de bebidas',
  1101: 'Destilación, rectificación y mezcla de bebidas alcohólicas',
  1102: 'Elaboración de vinos',
  1103: 'Elaboración de sidra y otras bebidas fermentadas a partir de frutas',
  1104: 'Elaboración de otras bebidas no destiladas, procedentes de la fermentación',
  1105: 'Fabricación de cerveza',
  1106: 'Fabricación de malta',
  1107: 'Fabricación de bebidas no alcohólicas; producción de aguas minerales y otras aguas embotelladas',
  12: 'Industria del tabaco',
  120: 'Industria del tabaco',
  1200: 'Industria del tabaco',
  13: 'Industria textil',
  131: 'Preparación e hilado de fibras textiles',
  1310: 'Preparación e hilado de fibras textiles',
  132: 'Fabricación de tejidos textiles',
  1320: 'Fabricación de tejidos textiles',
  133: 'Acabado de textiles',
  1330: 'Acabado de textiles',
  139: 'Fabricación de otros productos textiles',
  1391: 'Fabricación de tejidos de punto',
  1392: 'Fabricación de artículos confeccionados con textiles, excepto prendas de vestir',
  1393: 'Fabricación de alfombras y moquetas',
  1394: 'Fabricación de cuerdas, cordeles, bramantes y redes',
  1395: 'Fabricación de telas no tejidas y artículos confeccionados con ellas, excepto prendas de vestir',
  1396: 'Fabricación de otros productos textiles de uso técnico e industrial',
  1399: 'Fabricación de otros productos textiles n.c.o.p.',
  14: 'Confección de prendas de vestir',
  141: 'Confección de prendas de vestir, excepto de peletería',
  1411: 'Confección de prendas de vestir de cuero',
  1412: 'Confección de ropa de trabajo',
  1413: 'Confección de otras prendas de vestir exteriores',
  1414: 'Confección de ropa interior',
  1419: 'Confección de otras prendas de vestir y accesorios',
  142: 'Fabricación de artículos de peletería',
  1420: 'Fabricación de artículos de peletería',
  143: 'Confección de prendas de vestir de punto',
  1431: 'Confección de calcetería',
  1439: 'Confección de otras prendas de vestir de punto',
  15: 'Industria del cuero y del calzado',
  151: 'Preparación, curtido y acabado del cuero; fabricación de artículos de marroquinería, viaje y de guarnicionería y talabartería; preparación y teñido de pieles',
  1511: 'Preparación, curtido y acabado del cuero; preparación y teñido de pieles',
  1512: 'Fabricación de artículos de marroquinería, viaje y de guarnicionería y talabartería',
  152: 'Fabricación de calzado',
  1520: 'Fabricación de calzado',
  16: 'Industria de la madera y del corcho, excepto muebles; cestería y espartería',
  161: 'Aserrado y cepillado de la madera',
  1610: 'Aserrado y cepillado de la madera',
  162: 'Fabricación de productos de madera, corcho, cestería y espartería',
  1621: 'Fabricación de chapas y tableros de madera',
  1622: 'Fabricación de suelos de madera ensamblados',
  1623: 'Fabricación de otras estructuras de madera y piezas de carpintería y ebanistería para la construcción',
  1624: 'Fabricación de envases y embalajes de madera',
  1629: 'Fabricación de otros productos de madera; artículos de corcho, cestería y espartería',
  17: 'Industria del papel',
  171: 'Fabricación de pasta papelera, papel y cartón',
  1711: 'Fabricación de pasta papelera',
  1712: 'Fabricación de papel y cartón',
  172: 'Fabricación de artículos de papel y de cartón',
  1721: 'Fabricación de papel y cartón ondulados; fabricación de envases y embalajes de papel y cartón',
  1722: 'Fabricación de artículos de papel y cartón para uso doméstico, sanitario e higiénico',
  1723: 'Fabricación de artículos de papelería',
  1724: 'Fabricación de papeles pintados',
  1729: 'Fabricación de otros artículos de papel y cartón',
  18: 'Artes gráficas y reproducción de soportes grabados',
  181: 'Artes gráficas y servicios relacionados con las mismas',
  1811: 'Artes gráficas y servicios relacionados con las mismas',
  1812: 'Otras actividades de impresión y artes gráficas',
  1813: 'Servicios de preimpresión y preparación de soportes',
  1814: 'Encuadernación y servicios relacionados con la misma',
  182: 'Reproducción de soportes grabados',
  1820: 'Reproducción de soportes grabados',
  19: 'Coquerías y refino de petróleo',
  191: 'Coquerías',
  1910: 'Coquerías',
  192: 'Refino de petróleo',
  1920: 'Refino de petróleo',
  20: 'Industria química',
  201: 'Fabricación de productos químicos básicos, compuestos nitrogenados, fertilizantes, plásticos y caucho sintético en formas primarias',
  2011: 'Fabricación de gases industriales',
  2012: 'Fabricación de colorantes y pigmentos',
  2013: 'Fabricación de otros productos básicos de química inorgánica',
  2014: 'Fabricación de otros productos básicos de química orgánica',
  2015: 'Fabricación de fertilizantes y compuestos nitrogenados',
  2016: 'Fabricación de plásticos en formas primarias',
  2017: 'Fabricación de caucho sintético en formas primarias',
  202: 'Fabricación de pesticidas y otros productos agroquímicos',
  2020: 'Fabricación de pesticidas y otros productos agroquímicos',
  203: 'Fabricación de pinturas, barnices y revestimientos similares; tintas de imprenta y masillas',
  2030: 'Fabricación de pinturas, barnices y revestimientos similares; tintas de imprenta y masillas',
  204: 'Fabricación de jabones, detergentes y otros artículos de limpieza y abrillantamiento; fabricación de perfumes y cosméticos',
  2041: 'Fabricación de jabones, detergentes y otros artículos de limpieza y abrillantamiento',
  2042: 'Fabricación de perfumes y cosméticos',
  205: 'Fabricación de otros productos químicos',
  2051: 'Fabricación de explosivos',
  2052: 'Fabricación de colas',
  2053: 'Fabricación de aceites esenciales',
  2059: 'Fabricación de otros productos químicos n.c.o.p.',
  206: 'Fabricación de fibras artificiales y sintéticas',
  2060: 'Fabricación de fibras artificiales y sintéticas',
  21: 'Fabricación de productos farmacéuticos',
  211: 'Fabricación de productos farmacéuticos de base',
  2110: 'Fabricación de productos farmacéuticos de base',
  212: 'Fabricación de especialidades farmacéuticas',
  2120: 'Fabricación de especialidades farmacéuticas',
  22: 'Fabricación de productos de caucho y plásticos',
  221: 'Fabricación de productos de caucho',
  2211: 'Fabricación de neumáticos y cámaras de caucho; reconstrucción y recauchutado de neumáticos',
  2219: 'Fabricación de otros productos de caucho',
  222: 'Fabricación de productos de plástico',
  2221: 'Fabricación de placas, hojas, tubos y perfiles de plástico',
  2222: 'Fabricación de envases y embalajes de plástico',
  2223: 'Fabricación de productos de plástico para la construcción',
  2229: 'Fabricación de otros productos de plástico',
  23: 'Fabricación de otros productos minerales no metálicos',
  231: 'Fabricación de vidrio y productos de vidrio',
  2311: 'Fabricación de vidrio plano',
  2312: 'Manipulado y transformación de vidrio plano',
  2313: 'Fabricación de vidrio hueco',
  2314: 'Fabricación de fibra de vidrio',
  2319: 'Fabricación y manipulado de otro vidrio, incluido el vidrio técnico',
  232: 'Fabricación de productos cerámicos refractarios',
  2320: 'Fabricación de productos cerámicos refractarios',
  233: 'Fabricación de productos cerámicos para la construcción',
  2331: 'Fabricación de azulejos y baldosas de cerámica',
  2332: 'Fabricación de ladrillos, tejas y productos de tierras cocidas para la construcción',
  234: 'Fabricación de otros productos cerámicos',
  2341: 'Fabricación de artículos cerámicos de uso doméstico y ornamental',
  2342: 'Fabricación de aparatos sanitarios cerámicos',
  2343: 'Fabricación de aisladores y piezas aislantes de material cerámico',
  2344: 'Fabricación de otros productos cerámicos de uso técnico',
  2349: 'Fabricación de otros productos cerámicos',
  235: 'Fabricación de cemento, cal y yeso',
  2351: 'Fabricación de cemento',
  2352: 'Fabricación de cal y yeso',
  236: 'Fabricación de elementos de hormigón, cemento y yeso',
  2361: 'Fabricación de elementos de hormigón para la construcción',
  2362: 'Fabricación de elementos de yeso para la construcción',
  2363: 'Fabricación de hormigón fresco',
  2364: 'Fabricación de mortero',
  2365: 'Fabricación de fibrocemento',
  2369: 'Fabricación de otros productos de hormigón, yeso y cemento',
  237: 'Corte, tallado y acabado de la piedra',
  2370: 'Corte, tallado y acabado de la piedra',
  239: 'Fabricación de productos abrasivos y productos minerales no metálicos n.c.o.p.',
  2391: 'Fabricación de productos abrasivos',
  2399: 'Fabricación de otros productos minerales no metálicos n.c.o.p.',
  24: 'Metalurgia; fabricación de productos de hierro, acero y ferroaleaciones',
  241: 'Fabricación de productos básicos de hierro, acero y ferroaleaciones',
  2410: 'Fabricación de productos básicos de hierro, acero y ferroaleaciones',
  242: 'Fabricación de tubos, tuberías, perfiles huecos y sus accesorios, de acero',
  2420: 'Fabricación de tubos, tuberías, perfiles huecos y sus accesorios, de acero',
  243: 'Fabricación de otros productos de primera transformación del acero',
  2431: 'Estirado en frío',
  2432: 'Laminación en frío',
  2433: 'Producción de perfiles en frío por conformación con plegado',
  2434: 'Trefilado en frío',
  244: 'Producción de metales preciosos y de otros metales no férreos',
  2441: 'Producción de metales preciosos',
  2442: 'Producción de aluminio',
  2443: 'Producción de plomo, zinc y estaño',
  2444: 'Producción de cobre',
  2445: 'Producción de otros metales no férreos',
  2446: 'Procesamiento de combustibles nucleares',
  245: 'Fundición de metales',
  2451: 'Fundición de hierro',
  2452: 'Fundición de acero',
  2453: 'Fundición de metales ligeros',
  2454: 'Fundición de otros metales no férreos',
  25: 'Fabricación de productos metálicos, excepto maquinaria y equipo',
  251: 'Fabricación de elementos metálicos para la construcción',
  2511: 'Fabricación de estructuras metálicas y sus componentes',
  2512: 'Fabricación de carpintería metálica',
  252: 'Fabricación de cisternas, grandes depósitos y contenedores de metal',
  2521: 'Fabricación de radiadores y calderas para calefacción central',
  2529: 'Fabricación de otras cisternas, grandes depósitos y contenedores de metal',
  253: 'Fabricación de generadores de vapor, excepto calderas de calefacción central',
  2530: 'Fabricación de generadores de vapor, excepto calderas de calefacción central',
  254: 'Fabricación de armas y municiones',
  2540: 'Fabricación de armas y municiones',
  255: 'Forja, estampación y embutición de metales; metalurgia de polvos',
  2550: 'Forja, estampación y embutición de metales; metalurgia de polvos',
  256: 'Tratamiento y revestimiento de metales; ingeniería mecánica por cuenta de terceros',
  2561: 'Tratamiento y revestimiento de metales',
  2562: 'Ingeniería mecánica por cuenta de terceros',
  257: 'Fabricación de artículos de cuchillería y cubertería, herramientas y ferretería',
  2571: 'Fabricación de artículos de cuchillería y cubertería',
  2572: 'Fabricación de cerraduras y herrajes',
  2573: 'Fabricación de herramientas',
  259: 'Fabricación de otros productos metálicos',
  2591: 'Fabricación de bidones y toneles de hierro o acero',
  2592: 'Fabricación de envases y embalajes metálicos ligeros',
  2593: 'Fabricación de productos de alambre, cadenas y muelles',
  2594: 'Fabricación de pernos y productos de tornillería',
  2599: 'Fabricación de otros productos metálicos n.c.o.p.',
  26: 'Fabricación de productos informáticos, electrónicos y ópticos',
  261: 'Fabricación de componentes electrónicos y circuitos impresos ensamblados',
  2611: 'Fabricación de componentes electrónicos',
  2612: 'Fabricación de circuitos impresos ensamblados',
  262: 'Fabricación de ordenadores y equipos periféricos',
  2620: 'Fabricación de ordenadores y equipos periféricos',
  263: 'Fabricación de equipos de telecomunicaciones',
  2630: 'Fabricación de equipos de telecomunicaciones',
  264: 'Fabricación de productos electrónicos de consumo',
  2640: 'Fabricación de productos electrónicos de consumo',
  265: 'Fabricación de instrumentos y aparatos de medida, verificación y navegación; fabricación de relojes',
  2651: 'Fabricación de instrumentos y aparatos de medida, verificación y navegación',
  2652: 'Fabricación de relojes',
  266: 'Fabricación de equipos de radiación, electromédicos y electroterapéuticos',
  2660: 'Fabricación de equipos de radiación, electromédicos y electroterapéuticos',
  267: 'Fabricación de instrumentos de óptica y equipo fotográfico',
  2670: 'Fabricación de instrumentos de óptica y equipo fotográfico',
  268: 'Fabricación de soportes magnéticos y ópticos',
  2680: 'Fabricación de soportes magnéticos y ópticos',
  27: 'Fabricación de material y equipo eléctrico',
  271: 'Fabricación de motores, generadores y transformadores eléctricos, y de aparatos de distribución y control eléctrico',
  2711: 'Fabricación de motores, generadores y transformadores eléctricos',
  2712: 'Fabricación de aparatos de distribución y control eléctrico',
  272: 'Fabricación de pilas y acumuladores eléctricos',
  2720: 'Fabricación de pilas y acumuladores eléctricos',
  273: 'Fabricación de cables y dispositivos de cableado',
  2731: 'Fabricación de cables de fibra óptica',
  2732: 'Fabricación de otros hilos y cables electrónicos y eléctricos',
  2733: 'Fabricación de dispositivos de cableado',
  274: 'Fabricación de lámparas y aparatos eléctricos de iluminación',
  2740: 'Fabricación de lámparas y aparatos eléctricos de iluminación',
  275: 'Fabricación de aparatos domésticos',
  2751: 'Fabricación de electrodomésticos',
  2752: 'Fabricación de aparatos domésticos no eléctricos',
  279: 'Fabricación de otro material y equipo eléctrico',
  2790: 'Fabricación de otro material y equipo eléctrico',
  28: 'Fabricación de maquinaria y equipo n.c.o.p.',
  281: 'Fabricación de maquinaria de uso general',
  2811: 'Fabricación de motores y turbinas, excepto los destinados a aeronaves, vehículos automóviles y ciclomotores',
  2812: 'Fabricación de equipos de transmisión hidráulica y neumática',
  2813: 'Fabricación de otras bombas y compresores',
  2814: 'Fabricación de otra grifería y válvulas',
  2815: 'Fabricación de cojinetes, engranajes y órganos mecánicos de transmisión',
  282: 'Fabricación de otra maquinaria de uso general',
  2821: 'Fabricación de hornos y quemadores',
  2822: 'Fabricación de maquinaria de elevación y manipulación',
  2823: 'Fabricación de máquinas y equipos de oficina, excepto equipos informáticos',
  2824: 'Fabricación de herramientas eléctricas manuales',
  2825: 'Fabricación de maquinaria de ventilación y refrigeración no doméstica',
  2829: 'Fabricación de otra maquinaria de uso general n.c.o.p.',
  283: 'Fabricación de maquinaria agraria y forestal',
  2830: 'Fabricación de maquinaria agraria y forestal',
  284: 'Fabricación de máquinas herramienta para trabajar el metal y otras máquinas herramienta',
  2841: 'Fabricación de máquinas herramienta para trabajar el metal',
  2849: 'Fabricación de otras máquinas herramienta',
  289: 'Fabricación de otra maquinaria para usos específicos',
  2891: 'Fabricación de maquinaria para la industria metalúrgica',
  2892: 'Fabricación de maquinaria para las industrias extractivas y de la construcción',
  2893: 'Fabricación de maquinaria para la industria de la alimentación, bebidas y tabaco',
  2894: 'Fabricación de maquinaria para las industrias textil, de la confección y del cuero',
  2895: 'Fabricación de maquinaria para la industria del papel y del cartón',
  2896: 'Fabricación de maquinaria para la industria del plástico y el caucho',
  2899: 'Fabricación de otra maquinaria para usos específicos n.c.o.p.',
  29: 'Fabricación de vehículos de motor, remolques y semirremolques',
  291: 'Fabricación de vehículos de motor',
  2910: 'Fabricación de vehículos de motor',
  292: 'Fabricación de carrocerías para vehículos de motor; fabricación de remolques y semirremolques',
  2920: 'Fabricación de carrocerías para vehículos de motor; fabricación de remolques y semirremolques',
  293: 'Fabricación de componentes, piezas y accesorios para vehículos de motor',
  2931: 'Fabricación de equipos eléctricos y electrónicos para vehículos de motor',
  2932: 'Fabricación de otros componentes, piezas y accesorios para vehículos de motor',
  30: 'Fabricación de otro material de transporte',
  301: 'Construcción naval',
  3011: 'Construcción de barcos y estructuras flotantes',
  3012: 'Construcción de embarcaciones de recreo y deporte',
  302: 'Fabricación de locomotoras y material ferroviario',
  3020: 'Fabricación de locomotoras y material ferroviario',
  303: 'Construcción aeronáutica y espacial y su maquinaria',
  3030: 'Construcción aeronáutica y espacial y su maquinaria',
  304: 'Fabricación de vehículos militares de combate',
  3040: 'Fabricación de vehículos militares de combate',
  309: 'Fabricación de otro material de transporte n.c.o.p.',
  3091: 'Fabricación de motocicletas',
  3092: 'Fabricación de bicicletas y de vehículos para personas con discapacidad',
  3099: 'Fabricación de otro material de transporte n.c.o.p.',
  31: 'Fabricación de muebles',
  310: 'Fabricación de muebles',
  3101: 'Fabricación de muebles de oficina y de establecimientos comerciales',
  3102: 'Fabricación de muebles de cocina',
  3103: 'Fabricación de colchones',
  3109: 'Fabricación de otros muebles',
  32: 'Otras industrias manufactureras',
  321: 'Fabricación de artículos de joyería, bisutería y similares',
  3211: 'Fabricación de monedas',
  3212: 'Fabricación de artículos de joyería y artículos similares',
  3213: 'Fabricación de artículos de bisutería y artículos similares',
  322: 'Fabricación de instrumentos musicales',
  3220: 'Fabricación de instrumentos musicales',
  323: 'Fabricación de artículos de deporte',
  3230: 'Fabricación de artículos de deporte',
  324: 'Fabricación de juegos y juguetes',
  3240: 'Fabricación de juegos y juguetes',
  325: 'Fabricación de instrumentos y suministros médicos y odontológicos',
  3250: 'Fabricación de instrumentos y suministros médicos y odontológicos',
  329: 'Industrias manufactureras n.c.o.p.',
  3291: 'Fabricación de escobas, brochas y cepillos',
  3299: 'Otras industrias manufactureras n.c.o.p.',
  33: 'Reparación e instalación de maquinaria y equipo',
  331: 'Reparación de productos metálicos, maquinaria y equipo',
  3311: 'Reparación de productos metálicos',
  3312: 'Reparación de maquinaria',
  3313: 'Reparación de equipos electrónicos y ópticos',
  3314: 'Reparación de equipos eléctricos',
  3315: 'Reparación y mantenimiento naval',
  3316: 'Reparación y mantenimiento aeronáutico y espacial',
  3317: 'Reparación y mantenimiento de otro material de transporte',
  3319: 'Reparación de otros equipos',
  332: 'Instalación de máquinas y equipos industriales',
  3320: 'Instalación de máquinas y equipos industriales',
  35: 'Suministro de energía eléctrica, gas, vapor y aire acondicionado',
  351: 'Producción, transporte y distribución de energía eléctrica',
  3512: 'Transporte de energía eléctrica',
  3513: 'Distribución de energía eléctrica',
  3514: 'Comercio de energía eléctrica',
  3515: 'Producción de energía hidroeléctrica',
  3516: 'Producción de energía eléctrica de origen térmico convencional',
  3517: 'Producción de energía eléctrica de origen nuclear',
  3518: 'Producción de energía eléctrica de origen eólico',
  3519: 'Producción de energía eléctrica de otros tipos',
  352: 'Producción de gas; distribución por tubería de combustibles gaseosos',
  3521: 'Producción de gas',
  3522: 'Distribución por tubería de combustibles gaseosos',
  3523: 'Comercio de gas por tubería',
  353: 'Suministro de vapor y aire acondicionado',
  3530: 'Suministro de vapor y aire acondicionado',
  36: 'Captación, depuración y distribución de agua',
  360: 'Captación, depuración y distribución de agua',
  3600: 'Captación, depuración y distribución de agua',
  37: 'Recogida y tratamiento de aguas residuales',
  370: 'Recogida y tratamiento de aguas residuales',
  3700: 'Recogida y tratamiento de aguas residuales',
  38: 'Recogida, tratamiento y eliminación de residuos; valorización',
  381: 'Recogida de residuos',
  3811: 'Recogida de residuos no peligrosos',
  3812: 'Recogida de residuos peligrosos',
  382: 'Tratamiento y eliminación de residuos',
  3821: 'Tratamiento y eliminación de residuos no peligrosos',
  3822: 'Tratamiento y eliminación de residuos peligrosos',
  383: 'Valorización',
  3831: 'Separación y clasificación de materiales',
  3832: 'Valorización de materiales ya clasificados',
  39: 'Actividades de descontaminación y otros servicios de gestión de residuos',
  390: 'Actividades de descontaminación y otros servicios de gestión de residuos',
  3900: 'Actividades de descontaminación y otros servicios de gestión de residuos',
  41: 'Construcción de edificios',
  411: 'Promoción inmobiliaria',
  4110: 'Promoción inmobiliaria',
  412: 'Construcción de edificios',
  4121: 'Construcción de edificios residenciales',
  4122: 'Construcción de edificios no residenciales',
  42: 'Ingeniería civil',
  421: 'Construcción de carreteras y vías férreas, puentes y túneles',
  4211: 'Construcción de carreteras y autopistas',
  4212: 'Construcción de vías férreas de superficie y subterráneas',
  4213: 'Construcción de puentes y túneles',
  422: 'Construcción de redes',
  4221: 'Construcción de redes para fluidos',
  4222: 'Construcción de redes eléctricas y de telecomunicaciones',
  429: 'Construcción de otros proyectos de ingeniería civil',
  4291: 'Obras hidráulicas',
  4299: 'Construcción de otros proyectos de ingeniería civil n.c.o.p.',
  43: 'Actividades de construcción especializada',
  431: 'Demolición y preparación de terrenos',
  4311: 'Demolición',
  4312: 'Preparación de terrenos',
  4313: 'Perforaciones y sondeos',
  432: 'Instalaciones eléctricas, de fontanería y otras instalaciones en obras de construcción',
  4321: 'Instalaciones eléctricas',
  4322: 'Fontanería, instalaciones de sistemas de calefacción y aire acondicionado',
  4329: 'Otras instalaciones en obras de construcción',
  433: 'Acabado de edificios',
  4331: 'Revocamiento',
  4332: 'Instalación de carpintería',
  4333: 'Revestimiento de suelos y paredes',
  4334: 'Pintura y acristalamiento',
  4339: 'Otro acabado de edificios',
  439: 'Otras actividades de construcción especializada',
  4391: 'Construcción de cubiertas',
  4399: 'Otras actividades de construcción especializada n.c.o.p.',
  45: 'Venta y reparación de vehículos de motor y motocicletas',
  451: 'Venta de vehículos de motor',
  4511: 'Venta de automóviles y vehículos de motor ligeros',
  4519: 'Venta de otros vehículos de motor',
  452: 'Mantenimiento y reparación de vehículos de motor',
  4520: 'Mantenimiento y reparación de vehículos de motor',
  453: 'Comercio de repuestos y accesorios de vehículos de motor',
  4531: 'Comercio al por mayor de repuestos y accesorios de vehículos de motor',
  4532: 'Comercio al por menor de repuestos y accesorios de vehículos de motor',
  454: 'Venta, mantenimiento y reparación de motocicletas y de sus repuestos y accesorios',
  4540: 'Venta, mantenimiento y reparación de motocicletas y de sus repuestos y accesorios',
  46: 'Comercio al por mayor e intermediarios del comercio, excepto de vehículos de motor y motocicletas',
  461: 'Intermediarios del comercio',
  4611: 'Intermediarios del comercio de materias primas agrarias, animales vivos, materias primas textiles y productos semielaborados',
  4612: 'Intermediarios del comercio de combustibles, minerales, metales y productos químicos industriales',
  4613: 'Intermediarios del comercio de la madera y materiales de construcción',
  4614: 'Intermediarios del comercio de maquinaria, equipo industrial, embarcaciones y aeronaves',
  4615: 'Intermediarios del comercio de muebles, artículos para el hogar y ferretería',
  4616: 'Intermediarios del comercio de textiles, prendas de vestir, peletería, calzado y artículos de cuero',
  4617: 'Intermediarios del comercio de productos alimenticios, bebidas y tabaco',
  4618: 'Intermediarios del comercio especializados en la venta de otros productos específicos',
  4619: 'Intermediarios del comercio de productos diversos',
  462: 'Comercio al por mayor de materias primas agrarias y de animales vivos',
  4621: 'Comercio al por mayor de cereales, tabaco en rama, simientes y alimentos para animales',
  4622: 'Comercio al por mayor de flores y plantas',
  4623: 'Comercio al por mayor de animales vivos',
  4624: 'Comercio al por mayor de cueros y pieles',
  463: 'Comercio al por mayor de productos alimenticios, bebidas y tabaco',
  4631: 'Comercio al por mayor de frutas y hortalizas',
  4632: 'Comercio al por mayor de carne y productos cárnicos',
  4633: 'Comercio al por mayor de productos lácteos, huevos, aceites y grasas comestibles',
  4634: 'Comercio al por mayor de bebidas',
  4635: 'Comercio al por mayor de productos del tabaco',
  4636: 'Comercio al por mayor de azúcar, chocolate y confitería',
  4637: 'Comercio al por mayor de café, té, cacao y especias',
  4638: 'Comercio al por mayor de pescados y mariscos y otros productos alimenticios',
  4639: 'Comercio al por mayor, no especializado, de productos alimenticios, bebidas y tabaco',
  464: 'Comercio al por mayor de artículos de uso doméstico',
  4641: 'Comercio al por mayor de textiles',
  4642: 'Comercio al por mayor de prendas de vestir y calzado',
  4643: 'Comercio al por mayor de aparatos electrodomésticos',
  4644: 'Comercio al por mayor de porcelana, cristalería y artículos de limpieza',
  4645: 'Comercio al por mayor de productos perfumería y cosmética',
  4646: 'Comercio al por mayor de productos farmacéuticos',
  4647: 'Comercio al por mayor de muebles, alfombras y aparatos de iluminación',
  4648: 'Comercio al por mayor de artículos de relojería y joyería',
  4649: 'Comercio al por mayor de otros artículos de uso doméstico',
  465: 'Comercio al por mayor de equipos para las tecnologías de la información y las comunicaciones',
  4651: 'Comercio al por mayor de ordenadores, equipos periféricos y programas informáticos',
  4652: 'Comercio al por mayor de equipos electrónicos y de telecomunicaciones y sus componentes',
  466: 'Comercio al por mayor de otra maquinaria, equipos y suministros',
  4661: 'Comercio al por mayor de maquinaria, equipos y suministros agrícolas',
  4662: 'Comercio al por mayor de máquinas herramienta',
  4663: 'Comercio al por mayor de maquinaria para la minería, la construcción y la ingeniería civil',
  4664: 'Comercio al por mayor de maquinaria para la industria textil y de máquinas de coser y tricotar',
  4665: 'Comercio al por mayor de muebles de oficina',
  4666: 'Comercio al por mayor de otra maquinaria y equipo de oficina',
  4669: 'Comercio al por mayor de otra maquinaria y equipo',
  467: 'Otro comercio al por mayor especializado',
  4671: 'Comercio al por mayor de combustibles sólidos, líquidos y gaseosos, y productos similares',
  4672: 'Comercio al por mayor de metales y minerales metálicos',
  4673: 'Comercio al por mayor de madera, materiales de construcción y aparatos sanitarios',
  4674: 'Comercio al por mayor de ferretería, fontanería y calefacción',
  4675: 'Comercio al por mayor de productos químicos',
  4676: 'Comercio al por mayor de otros productos semielaborados',
  4677: 'Comercio al por mayor de chatarra y productos de desecho',
  469: 'Comercio al por mayor no especializado',
  4690: 'Comercio al por mayor no especializado',
  47: 'Comercio al por menor, excepto de vehículos de motor y motocicletas',
  471: 'Comercio al por menor en establecimientos no especializados',
  4711: 'Comercio al por menor en establecimientos no especializados, con predominio en productos alimenticios, bebidas y tabaco',
  4719: 'Otro comercio al por menor en establecimientos no especializados',
  472: 'Comercio al por menor de productos alimenticios, bebidas y tabaco en establecimientos especializados',
  4721: 'Comercio al por menor de frutas y hortalizas en establecimientos especializados',
  4722: 'Comercio al por menor de carne y productos cárnicos en establecimientos especializados',
  4723: 'Comercio al por menor de pescados y mariscos en establecimientos especializados',
  4724: 'Comercio al por menor de pan y productos de panadería, confitería y pastelería en establecimientos especializados',
  4725: 'Comercio al por menor de bebidas en establecimientos especializados',
  4726: 'Comercio al por menor de productos de tabaco en establecimientos especializados',
  4729: 'Otro comercio al por menor de productos alimenticios en establecimientos especializados',
  473: 'Comercio al por menor de combustible para la automoción en establecimientos especializados',
  4730: 'Comercio al por menor de combustible para la automoción en establecimientos especializados',
  474: 'Comercio al por menor de equipos para las tecnologías de la información y las comunicaciones en establecimientos especializados',
  4741: 'Comercio al por menor de ordenadores, equipos periféricos y programas informáticos en establecimientos especializados',
  4742: 'Comercio al por menor de equipos de telecomunicaciones en establecimientos especializados',
  4743: 'Comercio al por menor de equipos de audio y vídeo en establecimientos especializados',
  475: 'Comercio al por menor de otros artículos de uso doméstico en establecimientos especializados',
  4751: 'Comercio al por menor de textiles en establecimientos especializados',
  4752: 'Comercio al por menor de ferretería, pintura y vidrio en establecimientos especializados',
  4753: 'Comercio al por menor de alfombras, moquetas y revestimientos de paredes y suelos en establecimientos especializados',
  4754: 'Comercio al por menor de aparatos electrodomésticos en establecimientos especializados',
  4759: 'Comercio al por menor de muebles, aparatos de iluminación y otros artículos de uso doméstico en establecimientos especializados',
  476: 'Comercio al por menor de artículos culturales y recreativos en establecimientos especializados',
  4761: 'Comercio al por menor de libros en establecimientos especializados',
  4762: 'Comercio al por menor de periódicos y artículos de papelería en establecimientos especializados',
  4763: 'Comercio al por menor de grabaciones de música y vídeo en establecimientos especializados',
  4764: 'Comercio al por menor de artículos deportivos en establecimientos especializados',
  4765: 'Comercio al por menor de juegos y juguetes en establecimientos especializados',
  477: 'Comercio al por menor de otros artículos en establecimientos especializados',
  4771: 'Comercio al por menor de prendas de vestir en establecimientos especializados',
  4772: 'Comercio al por menor de calzado y artículos de cuero en establecimientos especializados',
  4773: 'Comercio al por menor de productos farmacéuticos en establecimientos especializados',
  4774: 'Comercio al por menor de artículos médicos y ortopédicos en establecimientos especializados',
  4775: 'Comercio al por menor de productos cosméticos e higiénicos en establecimientos especializados',
  4776: 'Comercio al por menor de flores, plantas, semillas, fertilizantes, animales de compañía y alimentos para los mismos en establecimientos especializados',
  4777: 'Comercio al por menor de artículos de relojería y joyería en establecimientos especializados',
  4778: 'Otro comercio al por menor de artículos nuevos en establecimientos especializados',
  4779: 'Comercio al por menor de artículos de segunda mano en establecimientos',
  478: 'Comercio al por menor en puestos de venta y en mercadillos',
  4781: 'Comercio al por menor de productos alimenticios, bebidas y tabaco en puestos de venta y en mercadillos',
  4782: 'Comercio al por menor de productos textiles, prendas de vestir y calzado en puestos de venta y en mercadillos',
  4789: 'Comercio al por menor de otros productos en puestos de venta y en mercadillos',
  479: 'Comercio al por menor no realizado ni en establecimientos, ni en puestos de venta ni en mercadillos',
  4791: 'Comercio al por menor por correspondencia o Internet',
  4799: 'Otro comercio al por menor no realizado ni en establecimientos, ni en puestos de venta ni en mercadillos',
  49: 'Transporte terrestre y por tubería',
  491: 'Transporte interurbano de pasajeros por ferrocarril',
  4910: 'Transporte interurbano de pasajeros por ferrocarril',
  492: 'Transporte de mercancías por ferrocarril',
  4920: 'Transporte de mercancías por ferrocarril',
  493: 'Otro transporte terrestre de pasajeros',
  4931: 'Transporte terrestre urbano y suburbano de pasajeros',
  4932: 'Transporte por taxi',
  4939: 'tipos de transporte terrestre de pasajeros n.c.o.p.',
  494: 'Transporte de mercancías por carretera y servicios de mudanza',
  4941: 'Transporte de mercancías por carretera',
  4942: 'Servicios de mudanza',
  495: 'Transporte por tubería',
  4950: 'Transporte por tubería',
  50: 'Transporte marítimo y por vías navegables interiores',
  501: 'Transporte marítimo de pasajeros',
  5010: 'Transporte marítimo de pasajeros',
  502: 'Transporte marítimo de mercancías',
  5020: 'Transporte marítimo de mercancías',
  503: 'Transporte de pasajeros por vías navegables interiores',
  5030: 'Transporte de pasajeros por vías navegables interiores',
  504: 'Transporte de mercancías por vías navegables interiores',
  5040: 'Transporte de mercancías por vías navegables interiores',
  51: 'Transporte aéreo',
  511: 'Transporte aéreo de pasajeros',
  5110: 'Transporte aéreo de pasajeros',
  512: 'Transporte aéreo de mercancías y transporte espacial',
  5121: 'Transporte aéreo de mercancías',
  5122: 'Transporte espacial',
  52: 'Almacenamiento y actividades anexas al transporte',
  521: 'Depósito y almacenamiento',
  5210: 'Depósito y almacenamiento',
  522: 'Actividades anexas al transporte',
  5221: 'Actividades anexas al transporte terrestre',
  5222: 'Actividades anexas al transporte marítimo y por vías navegables interiores',
  5223: 'Actividades anexas al transporte aéreo',
  5224: 'Manipulación de mercancías',
  5229: 'Otras actividades anexas al transporte',
  53: 'Actividades postales y de correos',
  531: 'Actividades postales sometidas a la obligación del servicio universal',
  5310: 'Actividades postales sometidas a la obligación del servicio universal',
  532: 'Otras actividades postales y de correos',
  5320: 'Otras actividades postales y de correos',
  55: 'Servicios de alojamiento',
  551: 'Hoteles y alojamientos similares',
  5510: 'Hoteles y alojamientos similares',
  552: 'Alojamientos turísticos y otros alojamientos de corta estancia',
  5520: 'Alojamientos turísticos y otros alojamientos de corta estancia',
  553: 'Campings y aparcamientos para caravanas',
  5530: 'Campings y aparcamientos para caravanas',
  559: 'Otros alojamientos',
  5590: 'Otros alojamientos',
  56: 'Servicios de comidas y bebidas',
  561: 'Restaurantes y puestos de comidas',
  5610: 'Restaurantes y puestos de comidas',
  562: 'Provisión de comidas preparadas para eventos y otros servicios de comidas',
  5621: 'Provisión de comidas preparadas para eventos',
  5629: 'Otros servicios de comidas',
  563: 'Establecimientos de bebidas',
  5630: 'Establecimientos de bebidas',
  58: 'Edición',
  581: 'Edición de libros, periódicos y otras actividades editoriales',
  5811: 'Edición de libros',
  5812: 'Edición de directorios y guías de direcciones postales',
  5813: 'Edición de periódicos',
  5814: 'Edición de revistas',
  5819: 'Otras actividades editoriales',
  582: 'Edición de programas informáticos',
  5821: 'Edición de videojuegos',
  5829: 'Edición de otros programas informáticos',
  59: 'Actividades cinematográficas, de vídeo y de programas de televisión, grabación de sonido y edición musical',
  591: 'Actividades cinematográficas, de vídeo y de programas de televisión',
  5912: 'Actividades de postproducción cinematográfica, de vídeo y de programas de televisión',
  5914: 'Actividades de exhibición cinematográfica',
  5915: 'Actividades de producción cinematográfica y de vídeo',
  5916: 'Actividades de producciones de programas de televisión',
  5917: 'Actividades de distribución cinematográfica y de vídeo',
  5918: 'Actividades de distribución de programas de televisión',
  592: 'Actividades de grabación de sonido y edición musical',
  5920: 'Actividades de grabación de sonido y edición musical',
  60: 'Actividades de programación y emisión de radio y televisión',
  601: 'Actividades de radiodifusión',
  6010: 'Actividades de radiodifusión',
  602: 'Actividades de programación y emisión de televisión',
  6020: 'Actividades de programación y emisión de televisión',
  61: 'Telecomunicaciones',
  611: 'Telecomunicaciones por cable',
  6110: 'Telecomunicaciones por cable',
  612: 'Telecomunicaciones inalámbricas',
  6120: 'Telecomunicaciones inalámbricas',
  613: 'Telecomunicaciones por satélite',
  6130: 'Telecomunicaciones por satélite',
  619: 'Otras actividades de telecomunicaciones',
  6190: 'Otras actividades de telecomunicaciones',
  62: 'Programación, consultoría y otras actividades relacionadas con la informática',
  620: 'Programación, consultoría y otras actividades relacionadas con la informática',
  6201: 'Actividades de programación informática',
  6202: 'Actividades de consultoría informática',
  6203: 'Gestión de recursos informáticos',
  6209: 'Otros servicios relacionados con las tecnologías de la información y la informática',
  63: 'Servicios de información',
  631: 'Proceso de datos, hosting y actividades relacionadas; portales web',
  6311: 'Proceso de datos, hosting y actividades relacionadas',
  6312: 'Portales web',
  639: 'Otros servicios de información',
  6391: 'Actividades de las agencias de noticias',
  6399: 'Otros servicios de información n.c.o.p.',
  64: 'Servicios financieros, excepto seguros y fondos de pensiones',
  641: 'Intermediación monetaria',
  6411: 'Banco central',
  6419: 'Otra intermediación monetaria',
  642: 'Actividades de las sociedades holding',
  6420: 'Actividades de las sociedades holding',
  643: 'Inversión colectiva, fondos y entidades financieras similares',
  6430: 'Inversión colectiva, fondos y entidades financieras similares',
  649: 'Otros servicios financieros, excepto seguros y fondos de pensiones',
  6491: 'Arrendamiento financiero',
  6492: 'Otras actividades crediticias',
  6499: 'Otros servicios financieros, excepto seguros y fondos de pensiones n.c.o.p.',
  65: 'Seguros, reaseguros y fondos de pensiones, excepto Seguridad Social obligatoria',
  651: 'Seguros',
  6511: 'Seguros de vida',
  6512: 'Seguros distintos de los seguros de vida',
  652: 'Reaseguros',
  6520: 'Reaseguros',
  653: 'Fondos de pensiones',
  6530: 'Fondos de pensiones',
  66: 'Actividades auxiliares a los servicios financieros y a los seguros',
  661: 'Actividades auxiliares a los servicios financieros, excepto seguros y fondos de pensiones',
  6611: 'Administración de mercados financieros',
  6612: 'Actividades de intermediación en operaciones con valores y otros activos',
  6619: 'Otras actividades auxiliares a los servicios financieros, excepto seguros y fondos de pensiones',
  662: 'Actividades auxiliares a seguros y fondos de pensiones',
  6621: 'Evaluación de riesgos y daños',
  6622: 'Actividades de agentes y corredores de seguros',
  6629: 'Otras actividades auxiliares a seguros y fondos de pensiones',
  663: 'Actividades de gestión de fondos',
  6630: 'Actividades de gestión de fondos',
  68: 'Actividades inmobiliarias',
  681: 'Compraventa de bienes inmobiliarios por cuenta propia',
  6810: 'Compraventa de bienes inmobiliarios por cuenta propia',
  682: 'Alquiler de bienes inmobiliarios por cuenta propia',
  6820: 'Alquiler de bienes inmobiliarios por cuenta propia',
  683: 'Actividades inmobiliarias por cuenta de terceros',
  6831: 'Agentes de la propiedad inmobiliaria',
  6832: 'Gestión y administración de la propiedad inmobiliaria',
  69: 'Actividades jurídicas y de contabilidad',
  691: 'Actividades jurídicas',
  6910: 'Actividades jurídicas',
  692: 'Actividades de contabilidad, teneduría de libros, auditoría y asesoría fiscal',
  6920: 'Actividades de contabilidad, teneduría de libros, auditoría y asesoría fiscal',
  70: 'Actividades de las sedes centrales; actividades de consultoría de gestión empresarial',
  701: 'Actividades de las sedes centrales',
  7010: 'Actividades de las sedes centrales',
  702: 'Actividades de consultoría de gestión empresarial',
  7021: 'Relaciones públicas y comunicación',
  7022: 'Otras actividades de consultoría de gestión empresarial',
  71: 'Servicios técnicos de arquitectura e ingeniería; ensayos y análisis técnicos',
  711: 'Servicios técnicos de arquitectura e ingeniería y otras actividades relacionadas con el asesoramiento técnico',
  7111: 'Servicios técnicos de arquitectura',
  7112: 'Servicios técnicos de ingeniería y otras actividades relacionadas con el asesoramiento técnico',
  712: 'Ensayos y análisis técnicos',
  7120: 'Ensayos y análisis técnicos',
  72: 'Investigación y desarrollo',
  721: 'Investigación y desarrollo experimental en ciencias naturales y técnicas',
  7211: 'Investigación y desarrollo experimental en biotecnología',
  7219: 'Otra investigación y desarrollo experimental en ciencias naturales y técnicas',
  722: 'Investigación y desarrollo experimental en ciencias sociales y humanidades',
  7220: 'Investigación y desarrollo experimental en ciencias sociales y humanidades',
  73: 'Publicidad y estudios de mercado',
  731: 'Publicidad',
  7311: 'Agencias de publicidad',
  7312: 'Servicios de representación de medios de comunicación',
  732: 'Estudio de mercado y realización de encuestas de opinión pública',
  7320: 'Estudio de mercado y realización de encuestas de opinión pública',
  74: 'Otras actividades profesionales, científicas y técnicas',
  741: 'Actividades de diseño especializado',
  7410: 'Actividades de diseño especializado',
  742: 'Actividades de fotografía',
  7420: 'Actividades de fotografía',
  743: 'Actividades de traducción e interpretación',
  7430: 'Actividades de traducción e interpretación',
  749: 'Otras actividades profesionales, científicas y técnicas n.c.o.p.',
  7490: 'Otras actividades profesionales, científicas y técnicas n.c.o.p.',
  75: 'Actividades veterinarias',
  750: 'Actividades veterinarias',
  7500: 'Actividades veterinarias',
  77: 'Actividades de alquiler',
  771: 'Alquiler de vehículos de motor',
  7711: 'Alquiler de automóviles y vehículos de motor ligeros',
  7712: 'Alquiler de camiones',
  772: 'Alquiler de efectos personales y artículos de uso doméstico',
  7721: 'Alquiler de artículos de ocio y deportivos',
  7722: 'Alquiler de cintas de vídeo y discos',
  7729: 'Alquiler de otros efectos personales y artículos de uso doméstico',
  773: 'Alquiler de otra maquinaria, equipos y bienes tangibles',
  7731: 'Alquiler de maquinaria y equipo de uso agrícola',
  7732: 'Alquiler de maquinaria y equipo para la construcción e ingeniería civil',
  7733: 'Alquiler de maquinaria y equipo de oficina, incluidos ordenadores',
  7734: 'Alquiler de medios de navegación',
  7735: 'Alquiler de medios de transporte aéreo',
  7739: 'Alquiler de otra maquinaria, equipos y bienes tangibles n.c.o.p.',
  774: 'Arrendamiento de la propiedad intelectual y productos similares, excepto trabajos protegidos por los derechos de autor',
  7740: 'Arrendamiento de la propiedad intelectual y productos similares, excepto trabajos protegidos por los derechos de autor',
  78: 'Actividades relacionadas con el empleo',
  781: 'Actividades de las agencias de colocación',
  7810: 'Actividades de las agencias de colocación',
  782: 'Actividades de las empresas de trabajo temporal',
  7820: 'Actividades de las empresas de trabajo temporal',
  783: 'Otra provisión de recursos humanos',
  7830: 'Otra provisión de recursos humanos',
  79: 'Actividades de agencias de viajes, operadores turísticos, servicios de reservas y actividades relacionadas con los mismos',
  791: 'Actividades de agencias de viajes y operadores turísticos',
  7911: 'Actividades de las agencias de viajes',
  7912: 'Actividades de los operadores turísticos',
  799: 'Otros servicios de reservas y actividades relacionadas con los mismos',
  7990: 'Otros servicios de reservas y actividades relacionadas con los mismos',
  80: 'Actividades de seguridad e investigación',
  801: 'Actividades de seguridad privada',
  8010: 'Actividades de seguridad privada',
  802: 'Servicios de sistemas de seguridad',
  8020: 'Servicios de sistemas de seguridad',
  803: 'Actividades de investigación',
  8030: 'Actividades de investigación',
  81: 'Servicios a edificios y actividades de jardinería',
  811: 'Servicios integrales a edificios e instalaciones',
  8110: 'Servicios integrales a edificios e instalaciones',
  812: 'Actividades de limpieza',
  8121: 'Limpieza general de edificios',
  8122: 'Otras actividades de limpieza industrial y de edificios',
  8129: 'Otras actividades de limpieza',
  813: 'Actividades de jardinería',
  8130: 'Actividades de jardinería',
  82: 'Actividades administrativas de oficina y otras actividades auxiliares a las empresas',
  821: 'Actividades administrativas y auxiliares de oficina',
  8211: 'Servicios administrativos combinados',
  8219: 'Actividades de fotocopiado, preparación de documentos y otras actividades especializadas de oficina',
  822: 'Actividades de los centros de llamadas',
  8220: 'Actividades de los centros de llamadas',
  823: 'Organización de convenciones y ferias de muestras',
  8230: 'Organización de convenciones y ferias de muestras',
  829: 'Actividades de apoyo a las empresas n.c.o.p.',
  8291: 'Actividades de las agencias de cobros y de información comercial',
  8292: 'Actividades de envasado y empaquetado',
  8299: 'Otras actividades de apoyo a las empresas n.c.o.p.',
  84: 'Administración Pública y defensa; Seguridad Social obligatoria',
  841: 'Administración Pública y de la política económica y social',
  8411: 'Actividades generales de la Administración Pública',
  8412: 'Regulación de las actividades sanitarias, educativas y culturales y otros servicios sociales, excepto Seguridad Social',
  8413: 'Regulación de la actividad económica y contribución a su mayor eficiencia',
  842: 'Prestación de servicios a la comunidad en general',
  8421: 'Asuntos exteriores',
  8422: 'Defensa',
  8423: 'Justicia',
  8424: 'Orden público y seguridad',
  8425: 'Protección civil',
  843: 'Seguridad Social obligatoria',
  8430: 'Seguridad Social obligatoria',
  85: 'Educación',
  851: 'Educación preprimaria',
  8510: 'Educación preprimaria',
  852: 'Educación primaria',
  8520: 'Educación primaria',
  853: 'Educación secundaria',
  8531: 'Educación secundaria general',
  8532: 'Educación secundaria técnica y profesional',
  854: 'Educación postsecundaria',
  8541: 'Educación postsecundaria no terciaria',
  8543: 'Educación universitaria',
  8544: 'Educación terciaria no universitaria',
  855: 'Otra educación',
  8551: 'Educación deportiva y recreativa',
  8552: 'Educación cultural',
  8553: 'Actividades de las escuelas de conducción y pilotaje',
  8559: 'Otra educación n.c.o.p.',
  856: 'Actividades auxiliares a la educación',
  8560: 'Actividades auxiliares a la educación',
  86: 'Actividades sanitarias',
  861: 'Actividades hospitalarias',
  8610: 'Actividades hospitalarias',
  862: 'Actividades médicas y odontológicas',
  8621: 'Actividades de medicina general',
  8622: 'Actividades de medicina especializada',
  8623: 'Actividades odontológicas',
  869: 'Otras actividades sanitarias',
  8690: 'Otras actividades sanitarias',
  87: 'Asistencia en establecimientos residenciales',
  871: 'Asistencia en establecimientos residenciales con cuidados sanitarios',
  8710: 'Asistencia en establecimientos residenciales con cuidados sanitarios',
  872: 'Asistencia en establecimientos residenciales para personas con discapacidad intelectual, enfermedad mental y drogodependencia',
  8720: 'Asistencia en establecimientos residenciales para personas con discapacidad intelectual, enfermedad mental y drogodependencia',
  873: 'Asistencia en establecimientos residenciales para personas mayores y con discapacidad física',
  8731: 'Asistencia en establecimientos residenciales para personas mayores',
  8732: 'Asistencia en establecimientos residenciales para personas con discapacidad física',
  879: 'Otras actividades de asistencia en establecimientos residenciales',
  8790: 'Otras actividades de asistencia en establecimientos residenciales',
  88: 'Actividades de servicios sociales sin alojamiento',
  881: 'Actividades de servicios sociales sin alojamiento para personas mayores y con discapacidad',
  8811: 'Actividades de servicios sociales sin alojamiento para personas mayores',
  8812: 'Actividades de servicios sociales sin alojamiento para personas con discapacidad',
  889: 'Otros actividades de servicios sociales sin alojamiento',
  8891: 'Actividades de cuidado diurno de niños',
  8899: 'Otros actividades de servicios sociales sin alojamiento n.c.o.p.',
  90: 'Actividades de creación, artísticas y espectáculos',
  900: 'Actividades de creación, artísticas y espectáculos',
  9001: 'Artes escénicas',
  9002: 'Actividades auxiliares a las artes escénicas',
  9003: 'Creación artística y literaria',
  9004: 'Gestión de salas de espectáculos',
  91: 'Actividades de bibliotecas, archivos, museos y otras actividades culturales',
  910: 'Actividades de bibliotecas, archivos, museos y otras actividades culturales',
  9102: 'Actividades de museos',
  9103: 'Gestión de lugares y edificios históricos',
  9104: 'Actividades de los jardines botánicos, parques zoológicos y reservas naturales',
  9105: 'Actividades de bibliotecas',
  9106: 'Actividades de archivos',
  92: 'Actividades de juegos de azar y apuestas',
  920: 'Actividades de juegos de azar y apuestas',
  9200: 'Actividades de juegos de azar y apuestas',
  93: 'Actividades deportivas, recreativas y de entretenimiento',
  931: 'Actividades deportivas',
  9311: 'Gestión de instalaciones deportivas',
  9312: 'Actividades de los clubes deportivos',
  9313: 'Actividades de los gimnasios',
  9319: 'Otras actividades deportivas',
  932: 'Actividades recreativas y de entretenimiento',
  9321: 'Actividades de los parques de atracciones y los parques temáticos',
  9329: 'Otras actividades recreativas y de entretenimiento',
  94: 'Actividades asociativas',
  941: 'Actividades de organizaciones empresariales, profesionales y patronales',
  9411: 'Actividades de organizaciones empresariales y patronales',
  9412: 'Actividades de organizaciones profesionales',
  942: 'Actividades sindicales',
  9420: 'Actividades sindicales',
  949: 'Otras actividades asociativas',
  9491: 'Actividades de organizaciones religiosas',
  9492: 'Actividades de organizaciones políticas',
  9499: 'Otras actividades asociativas n.c.o.p.',
  95: 'Reparación de ordenadores, efectos personales y artículos de uso doméstico',
  951: 'Reparación de ordenadores y equipos de comunicación',
  9511: 'Reparación de ordenadores y equipos periféricos',
  9512: 'Reparación de equipos de comunicación',
  952: 'Reparación de efectos personales y artículos de uso doméstico',
  9521: 'Reparación de aparatos electrónicos de audio y vídeo de uso doméstico',
  9522: 'Reparación de aparatos electrodomésticos y de equipos para el hogar y el jardín',
  9523: 'Reparación de calzado y artículos de cuero',
  9524: 'Reparación de muebles y artículos de menaje',
  9525: 'Reparación de relojes y joyería',
  9529: 'Reparación de otros efectos personales y artículos de uso doméstico',
  96: 'Otros servicios personales',
  960: 'Otros servicios personales',
  9601: 'Lavado y limpieza de prendas textiles y de piel',
  9602: 'Peluquería y otros tratamientos de belleza',
  9603: 'Pompas fúnebres y actividades relacionadas',
  9604: 'Actividades de mantenimiento físico',
  9609: 'Otras servicios personales n.c.o.p.',
  97: 'Actividades de los hogares como empleadores de personal doméstico',
  970: 'Actividades de los hogares como empleadores de personal doméstico',
  9700: 'Actividades de los hogares como empleadores de personal doméstico',
  98: 'Actividades de los hogares como productores de bienes y servicios para uso propio',
  981: 'Actividades de los hogares como productores de bienes para uso propio',
  9810: 'Actividades de los hogares como productores de bienes para uso propio',
  982: 'Actividades de los hogares como productores de servicios para uso propio',
  9820: 'Actividades de los hogares como productores de servicios para uso propio',
  99: 'Actividades de organizaciones y organismos extraterritoriales',
  990: 'Actividades de organizaciones y organismos extraterritoriales',
  9900: 'Actividades de organizaciones y organismos extraterritoriales',
}
export const ADMIN_ROLES = ['ROLE_ADMIN', 'ROLE_DELEGATE', 'ROLE_MANAGER', 'ROLE_CANAL', 'ROLE_SALES_MANAGER', 'ROLE_SALES_AGENT']

export const CUPS_ACTIVE_ASSOCIATED_WITH_CLIENT = '02'

export const IS_DISABLED_SERVICE_TENURE_CHECKING = true

export const IDE = '0021'
export const UFD = '0022'

export const DISTRIBUTORS = {
  '0021': 'I-DE Redes Eléctricas Inteligentes SAU (Iberdrola)',
  '0022': 'UFD Distribución electricidad SA (Fenosa)',
}

export const ATTACH_FILE_AUDIO_TYPES = ['audio', 'audio_tl']

export const ATTACH_FILE_NEW_SUPPLY_TYPES = ['cie', 'apm', 'ei', 'iri', 'rite']

export const ATTACH_FILE_FOR_PYMES = ['cif', 'nif', 'cae']

export const ATTACH_FILE_TYPES = [
  { value: 'audio', label: 'Audio' },
  { value: 'audio_tl', label: 'Audio_TL' },
  { value: 'cie', label: 'CIE' },
  { value: 'apm', label: 'APM' },
  { value: 'ei', label: 'EI' },
  { value: 'iri', label: 'IRI' },
  { value: 'rite', label: 'RITE' },
  { value: 'nif', label: 'NIF Representante' },
  { value: 'cif', label: 'CIF' },
  { value: 'cae', label: 'Poder de Autorización de firma' },
]

export const EXTRA_AUDIO_CHANNEL_NAMES = [
  'Fuerza de Ventas con Verificacion',
  'Operaciones con Verificacion',
  'Venta en Remoto Local',
  'servirenting',
  'Mayorista',
]

export const WHATS_APP_DISABLED = false

export const PORTABILITY = 'Portabilidad'

export const STREET_TYPE_DEFAULT = 'CALLE'

export const ERROR_RETARGETING = '100'
export const AUTHENTICATION_AZURE = true
