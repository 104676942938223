import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paperShow: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 8),
    },
  },
  paperHide: {
    boxShadow: 'none',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
  labelText: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  check: {
    marginRight: theme.spacing(1),
    color: theme.palette.feedback.success,
  },
}))

export default useStyles
