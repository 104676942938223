import { makeStyles } from '@material-ui/core/styles'
import fonts from '@zatopek/core/src/theme/base/fonts'

const useStyles = makeStyles((theme) => ({
  ...theme.typography,
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
  },
  iconLeft: {
    marginRight: theme.spacing(1),
  },
  iconRight: {
    marginLeft: theme.spacing(1),
  },
  disabled: {
    color: `${theme.palette.primary.light2}`,
    pointerEvents: 'none',
  },
  link: {
    fontFamily: fonts.fontFamily,
    ...fonts.link,
  },
}))

export default useStyles
