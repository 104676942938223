import { SERVICE } from './constants'

export const FETCH_TEMPLATE_FIELDS = `${SERVICE}/FETCH_TEMPLATE_FIELDS`
export const FETCH_TEMPLATE_FIELDS_REQUEST_START = `${SERVICE}/FETCH_TEMPLATE_FIELDS_REQUEST_START`
export const FETCH_TEMPLATE_FIELDS_REQUEST_SUCCESS = `${SERVICE}/FETCH_TEMPLATE_FIELDS_REQUEST_SUCCESS`
export const FETCH_TEMPLATE_FIELDS_REQUEST_ERROR = `${SERVICE}/FETCH_TEMPLATE_FIELDS_REQUEST_ERROR`
export const FETCH_TEMPLATE_FIELDS_REQUEST_CLEAR = `${SERVICE}/FETCH_TEMPLATE_FIELDS_REQUEST_CLEAR`

export const SET_VIEW_ORDERS_TEMPLATE_FIELDS = `${SERVICE}/SET_VIEW_ORDERS_TEMPLATE_FIELDS`
export const CLEAR_VIEW_ORDERS_TEMPLATE_FIELDS = `${SERVICE}/CLEAR_VIEW_ORDERS_TEMPLATE_FIELDS`
export const SET_EXPORT_ORDERS_TEMPLATE_FIELDS = `${SERVICE}/SET_EXPORT_ORDERS_TEMPLATE_FIELDS`
export const CLEAR_EXPORT_ORDERS_TEMPLATE_FIELDS = `${SERVICE}/CLEAR_EXPORT_ORDERS_TEMPLATE_FIELDS`
