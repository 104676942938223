import { SERVICE } from './constants'

export const SET_CAMPAIGNS = `${SERVICE}/SET_CAMPAIGNS`
export const CLEAR_CAMPAIGNS = `${SERVICE}/CLEAR_CAMPAIGNS`
export const SET_APPLIED_CAMPAIGN = `${SERVICE}/SET_APPLIED_CAMPAIGN`
export const CLEAR_APPLIED_CAMPAIGN = `${SERVICE}/CLEAR_APPLIED_CAMPAIGN`
export const START_CAMPAIGN_REQUEST = `${SERVICE}/START_CAMPAIGN_REQUEST`
export const SUCCESS_CAMPAIGN_REQUEST = `${SERVICE}/SUCCESS_CAMPAIGN_REQUEST`
export const ERROR_CAMPAIGN_REQUEST = `${SERVICE}/ERROR_CAMPAIGN_REQUEST`
export const CLEAR_CAMPAIGN_REQUEST = `${SERVICE}/CLEAR_CAMPAIGN_REQUEST`
