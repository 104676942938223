import { fade } from '@material-ui/core/styles'
import fonts from '../base/fonts'
import border from '../base/border'
import palette from '../base/palette'

const buttons = {
  props: {
    MuiButton: {
      disableFocusRipple: true,
    },
  },
  overrides: {
    button: { ...fonts.link },
    MuiButton: {
      root: {
        minWidth: 'initial',
        padding: '11px 25px',
        borderRadius: '25px',
        ...fonts.h5,
        textTransform: 'initial',
        whiteSpace: 'pre',
        '&.MuiLink-root:hover': {
          textDecoration: 'none',
        },
      },
      text: {
        color: palette.primary.main,
        ...fonts.h6,
      },
      containedPrimary: {
        backgroundColor: palette.primary.main,
        border: `${border.style.medium} solid ${palette.primary.main}`,
        '&:hover': {
          borderColor: 'transparent',
        },
        '&:focus': {
          borderColor: palette.primary.dark1,
        },
        '&:disabled': {
          backgroundColor: palette.neutral.light2,
          borderColor: palette.neutral.light2,
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.main,
        border: `${border.style.medium} solid ${palette.secondary.main}`,
        '&:hover': {
          backgroundColor: palette.secondary.dark1,
          borderColor: palette.secondary.dark1,
        },
        '&:focus': {
          borderColor: palette.secondary.dark1,
        },
        '&:disabled': {
          backgroundColor: fade(palette.secondary.main, 0.5),
          borderColor: 'transparent',
          color: palette.neutral.light3,
        },
      },
      outlined: {
        padding: '11px 25px',
      },
      outlinedPrimary: {
        border: 'none',
        color: palette.primary.main,
        boxShadow: `0px 0px 0px ${border.style.thin} ${palette.primary.main} inset`,
        backgroundColor: palette.neutral.light4,
        '&:hover': {
          border: 'none',
          backgroundColor: palette.neutral.light3,
        },
        '&:focus': {
          color: palette.primary.main,
          boxShadow: `0px 0px 0px ${border.style.medium} ${palette.primary.main} inset`,
        },
        '&:disabled': {
          color: palette.neutral.light2,
          boxShadow: `0px 0px 0px ${border.style.thin} ${palette.neutral.light2} inset`,
        },
      },
      outlinedSecondary: {
        border: 'none',
        color: palette.secondary.main,
        boxShadow: `0px 0px 0px ${border.style.thin} ${palette.secondary.main} inset`,
        backgroundColor: palette.neutral.light4,
        '&:hover': {
          border: 'none',
          backgroundColor: palette.neutral.light3,
          boxShadow: `0px 0px 0px ${border.style.thin} ${palette.secondary.dark1} inset`,
          color: palette.secondary.dark1,
        },
        '&:focus': {
          color: palette.secondary.main,
          border: 'none',
          boxShadow: `0px 0px 0px ${border.style.medium} ${palette.secondary.dark1} inset`,
        },
        '&:disabled': {
          color: palette.neutral.light2,
          boxShadow: `0px 0px 0px ${border.style.thin} ${palette.neutral.light2} inset`,
        },
      },
    },
  },
}

export default buttons
