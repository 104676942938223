import { get, put, post } from '../client'

const getDraft = (leadId) => get(`leads/${leadId}/draft`)

const getIncompleteOrder = (incompleteOrderId, token) =>
  token
    ? get(`incomplete_orders/${incompleteOrderId}?incomplete_order_token=${token}`)
    : get(`incomplete_orders/${incompleteOrderId}`)

const saveDraft = async (draftData, incompleteOrderId) =>
  incompleteOrderId
    ? await put(`incomplete_orders/${incompleteOrderId}`, draftData, true)
    : await post('incomplete_orders', draftData, true)

const putDuplicateOrder = async (orderId, isScoringFail = false) =>
  await put(`orders/${orderId}/duplicate`, {
    isScoringFail,
  })

export { getDraft, getIncompleteOrder, saveDraft, putDuplicateOrder }
