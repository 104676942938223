import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import { Box, Grid, Typography } from '@material-ui/core'
import { ELECTRICITY, GAS, BOTH_ENUM } from '@zatopek/core/src/utils/constants'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'
import { useSelector, useDispatch } from 'react-redux'
import { getChangeSuppyOwnerShip, getIsNewSupply } from 'services/customer/selectors'
import { setChangeSupplyOwnerShip, clearChangeSupplyOwnerShip } from 'services/customer/actions'
import { setStepDisabled, setStepEnabled } from 'services/stepper/actions'
import { getSelectedTenure } from 'services/customer/selectors'
import { getDraftSelectedProduct, getOnlineChannelDraft } from 'services/draftCopy/selectors'
import { sendGAEvent } from 'services/analytics/actions'
import { getIsOnlyToppings } from 'services/products/selectors'

const ChangeSupplyOwnership = ({ vcn, supplyModeService, supplyPoint, isOnlineChannel = false }) => {
  const { t } = useTranslation()
  const [supplyOwnershipElectricityChecked, setSuppyOwnershipElectricityChecked] = useState(null)
  const [supplyOwnershipGasChecked, setSupplyOwnershipGasChecked] = useState(null)
  const dispatch = useDispatch()
  const changeSupplyOwnership = useSelector(getChangeSuppyOwnerShip)
  const tenure = useSelector(getSelectedTenure)
  const onlineChannelDraft = useSelector(getOnlineChannelDraft)
  const draftSelectedProduct = useSelector(getDraftSelectedProduct)
  const isOnlyToppings = useSelector(getIsOnlyToppings)
  const isNewSupply = useSelector(getIsNewSupply)
  const fideElectricity =
    tenure?.products?.find((product) => product.type === ELECTRICITY.toUpperCase()) &&
    (supplyModeService === ELECTRICITY || supplyModeService === BOTH_ENUM)
  const fideGas =
    tenure?.products?.find((product) => product.type === GAS.toUpperCase()) &&
    (supplyModeService === GAS || supplyModeService === BOTH_ENUM)

  const supplyOwnershipValue = (type) => {
    return type === ELECTRICITY ? supplyOwnershipElectricityChecked : supplyOwnershipGasChecked
  }

  const handleChangeSupplyOwnership = (e, type) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.checkChangeSupplyOwnership, { currentStep: STEPS.owner }))
    if (type === ELECTRICITY) {
      setSuppyOwnershipElectricityChecked(e.target.value)
    } else {
      setSupplyOwnershipGasChecked(e.target.value)
    }
  }
  const draftChangeSupplyOwnership = onlineChannelDraft
    ? onlineChannelDraft.ownerStep.supplyOwnership?.map(({ changeSupplyOwnership, energyType }) => ({
        changeSupplyOwnership,
        energyType,
      }))
    : draftSelectedProduct?.map(({ changeSupplyOwnership, technicalInfoElectricity }) => {
        const energyType = technicalInfoElectricity ? ELECTRICITY : GAS
        return { changeSupplyOwnership, energyType }
      })
  const draftChangeSupplyOwnershipElectricity = draftChangeSupplyOwnership
    ? draftChangeSupplyOwnership?.find((supplyOwnership) => supplyOwnership.energyType === ELECTRICITY)?.changeSupplyOwnership
    : null
  const draftChangeSupplyOwnershipGas = draftChangeSupplyOwnership
    ? draftChangeSupplyOwnership?.find((supplyOwnership) => supplyOwnership.energyType === GAS)?.changeSupplyOwnership
    : null

  useEffect(() => {
    if (draftChangeSupplyOwnershipElectricity !== null) {
      dispatch(setChangeSupplyOwnerShip({ 'changeSupplyOwnership-electricity': draftChangeSupplyOwnershipElectricity }))
      setSuppyOwnershipElectricityChecked(draftChangeSupplyOwnershipElectricity ? 'true' : 'false')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftChangeSupplyOwnershipElectricity])

  useEffect(() => {
    if (draftChangeSupplyOwnershipGas !== null) {
      dispatch(setChangeSupplyOwnerShip({ 'changeSupplyOwnership-gas': draftChangeSupplyOwnershipGas }))
      setSupplyOwnershipGasChecked(draftChangeSupplyOwnershipGas ? 'true' : 'false')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftChangeSupplyOwnershipGas])

  useEffect(() => {
    if (supplyModeService === BOTH_ENUM && !isOnlineChannel) {
      if (supplyOwnershipElectricityChecked !== null && supplyOwnershipGasChecked !== null) {
        dispatch(setStepEnabled(STEPS.topping))
        dispatch(setStepEnabled(STEPS.billing))
      }
    } else if (supplyModeService === ELECTRICITY && supplyOwnershipElectricityChecked !== null && !isOnlineChannel) {
      dispatch(setStepEnabled(STEPS.topping))
      dispatch(setStepEnabled(STEPS.billing))
    } else if (supplyModeService === GAS && supplyOwnershipGasChecked !== null && !isOnlineChannel) {
      dispatch(setStepEnabled(STEPS.topping))
      dispatch(setStepEnabled(STEPS.billing))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyOwnershipElectricityChecked, supplyOwnershipGasChecked])

  useEffect(() => {
    if (supplyModeService === BOTH_ENUM) {
      if (vcn[ELECTRICITY] === null && vcn[GAS] !== null) {
        if (supplyOwnershipElectricityChecked !== null) {
          dispatch(
            setChangeSupplyOwnerShip({ 'changeSupplyOwnership-electricity': supplyOwnershipElectricityChecked === 'true' })
          )
        }
      }

      if (vcn[GAS] === null && vcn[ELECTRICITY] !== null) {
        if (supplyOwnershipGasChecked !== null) {
          dispatch(setChangeSupplyOwnerShip({ 'changeSupplyOwnership-gas': supplyOwnershipGasChecked === 'true' }))
        }
      }

      if (vcn[GAS] === null && vcn[ELECTRICITY] === null) {
        dispatch(
          setChangeSupplyOwnerShip({
            'changeSupplyOwnership-gas': supplyOwnershipGasChecked === null ? null : supplyOwnershipGasChecked === 'true',
            'changeSupplyOwnership-electricity':
              supplyOwnershipElectricityChecked === null ? null : supplyOwnershipElectricityChecked === 'true',
          })
        )
      }
    } else if (supplyModeService === ELECTRICITY) {
      if (supplyOwnershipElectricityChecked === null) return
      dispatch(setChangeSupplyOwnerShip({ 'changeSupplyOwnership-electricity': supplyOwnershipElectricityChecked === 'true' }))
    } else if (supplyModeService === GAS) {
      if (supplyOwnershipGasChecked === null) return
      dispatch(setChangeSupplyOwnerShip({ 'changeSupplyOwnership-gas': supplyOwnershipGasChecked === 'true' }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyOwnershipElectricityChecked, supplyOwnershipGasChecked])

  useEffect(() => {
    if (supplyModeService.split(',').filter((service) => vcn[service.toLowerCase()] === null).length === 0) {
      dispatch(clearChangeSupplyOwnerShip())
    } else {
      if (vcn.electricity === null && supplyModeService === ELECTRICITY) {
        if (fideElectricity || isNewSupply || isOnlyToppings) return
        if (
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== undefined &&
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== null
        ) {
          if (!isOnlineChannel) {
            dispatch(setStepEnabled(STEPS.topping))
            dispatch(setStepEnabled(STEPS.billing))
          }
        } else {
          if (!isOnlineChannel) {
            dispatch(setStepDisabled(STEPS.topping))
            dispatch(setStepDisabled(STEPS.billing))
          }
        }
      } else if (vcn.gas === null && supplyModeService === GAS) {
        if (fideGas || isNewSupply || isOnlyToppings) return
        if (
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== null &&
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== undefined
        ) {
          if (!isOnlineChannel) {
            dispatch(setStepEnabled(STEPS.topping))
            dispatch(setStepEnabled(STEPS.billing))
          }
        } else {
          if (!isOnlineChannel) {
            dispatch(setStepDisabled(STEPS.topping))
            dispatch(setStepDisabled(STEPS.billing))
          }
        }
      } else if ((vcn.electricity === null || vcn.gas === null) && supplyModeService === BOTH_ENUM) {
        if ((fideElectricity && fideGas) || isNewSupply || isOnlyToppings) return
        if (
          vcn.electricity === null &&
          vcn.gas !== null &&
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== undefined &&
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== null
        ) {
          if (!isOnlineChannel) {
            dispatch(setStepEnabled(STEPS.topping))
            dispatch(setStepEnabled(STEPS.billing))
          }
        } else if (
          vcn.gas === null &&
          vcn.electricity !== null &&
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== undefined &&
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== null
        ) {
          if (!isOnlineChannel) {
            dispatch(setStepEnabled(STEPS.topping))
            dispatch(setStepEnabled(STEPS.billing))
          }
        } else if (
          vcn.gas === null &&
          vcn.electricity === null &&
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== undefined &&
          changeSupplyOwnership['changeSupplyOwnership-gas'] !== null &&
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== undefined &&
          changeSupplyOwnership['changeSupplyOwnership-electricity'] !== null
        ) {
          if (!isOnlineChannel) {
            dispatch(setStepEnabled(STEPS.topping))
            dispatch(setStepEnabled(STEPS.billing))
          }
        } else {
          if (!isOnlineChannel) {
            dispatch(setStepDisabled(STEPS.topping))
            dispatch(setStepDisabled(STEPS.billing))
          }
        }
      }
    }
    return () => {
      dispatch(clearChangeSupplyOwnerShip())
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyModeService])

  useEffect(() => {
    if (
      (vcn.electricity !== null && vcn.gas !== null && Object.keys(changeSupplyOwnership).length > 0) ||
      isNewSupply ||
      isOnlyToppings
    ) {
      dispatch(clearChangeSupplyOwnerShip())
      setSuppyOwnershipElectricityChecked(null)
      setSupplyOwnershipGasChecked(null)
    }
    if (!isNewSupply && !fideElectricity && !fideGas && !isOnlyToppings) {
      if (
        vcn.electricity === null &&
        (changeSupplyOwnership['changeSupplyOwnership-electricity'] === null ||
          changeSupplyOwnership['changeSupplyOwnership-electricity'] === undefined)
      ) {
        setSuppyOwnershipElectricityChecked(null)
        if (!isOnlineChannel) {
          dispatch(setStepDisabled(STEPS.topping))
          dispatch(setStepDisabled(STEPS.billing))
        }
      }
      if (
        vcn.gas === null &&
        (changeSupplyOwnership['changeSupplyOwnership-gas'] === null ||
          changeSupplyOwnership['changeSupplyOwnership-gas'] === undefined)
      ) {
        setSupplyOwnershipGasChecked(null)
        if (!isOnlineChannel) {
          dispatch(setStepDisabled(STEPS.topping))
          dispatch(setStepDisabled(STEPS.billing))
        }
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyPoint])

  useEffect(() => {
    if (Object.keys(changeSupplyOwnership).length > 0) {
      if (changeSupplyOwnership['changeSupplyOwnership-electricity'] !== null) {
        setSuppyOwnershipElectricityChecked(changeSupplyOwnership['changeSupplyOwnership-electricity'] ? 'true' : 'false')
      }
      if (changeSupplyOwnership['changeSupplyOwnership-gas'] !== null) {
        setSupplyOwnershipGasChecked(changeSupplyOwnership['changeSupplyOwnership-gas'] ? 'true' : 'false')
      }
    }
  }, [changeSupplyOwnership])

  const checkFidelization = () => {
    if (isNewSupply) {
      return false
    }
    if (vcn.electricity === null && supplyModeService === ELECTRICITY && fideElectricity) {
      return false
    }
    if (vcn.gas === null && supplyModeService === GAS && fideGas) {
      return false
    }
    if (vcn.electricity === null && vcn.gas === null && supplyModeService === BOTH_ENUM && fideElectricity && fideGas) {
      return false
    }
    return true
  }

  return (
    <Grid container mt={4}>
      <Grid item xs={12}>
        {checkFidelization() && supplyModeService.split(',').filter((service) => vcn[service.toLowerCase()] === null).length > 0 && (
          <Typography variant="h5" color="primary">
            {t('supply.changeOwnerShipTitle')}
          </Typography>
        )}
        {checkFidelization() &&
          supplyModeService
            .split(',')
            .filter((service) => vcn[service.toLowerCase()] === null)
            .map((type, index) => (
              <Box mb={1} key={`${type}-${index}`}>
                <Grid container alignContent="center" alignItems="center">
                  <Grid
                    item
                    lg={8}
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography variant="body1" id={`changeSupplyOwnership-${type.toLowerCase()}`}>
                      {t('form.fields.changeSupplyOwnership', { service: t(type.toLowerCase()).toLowerCase() })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography noWrap>
                      Sí
                      <Radio
                        size="small"
                        onChange={(e) => handleChangeSupplyOwnership(e, type)}
                        value={'true'}
                        checked={supplyOwnershipValue(type) === 'true'}
                      />
                    </Typography>

                    <Typography noWrap>
                      No
                      <Radio
                        size="small"
                        onChange={(e) => handleChangeSupplyOwnership(e, type)}
                        value={'false'}
                        checked={supplyOwnershipValue(type) === 'false'}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
      </Grid>
    </Grid>
  )
}

export default ChangeSupplyOwnership
