import { request } from 'redukers'

export const getIsOnlineChannelRequesting = (state) => {
  const onlineChannelRequest = state.onlineChannel.requestOnlineChannel
  return request.getIsRequesting(onlineChannelRequest)
}

export const getIsUserLoggedOnlineChannel = (state) => state.onlineChannel.requestLoginOnlineChannel.hadSucceeded
export const gethasErrorUserLoggedOnlineChannel = (state) => state.onlineChannel.requestLoginOnlineChannel.hadErrored
export const getUserLoggedOnlineChannelState = (state) => state.onlineChannel.requestLoginOnlineChannel.state

export const getIsLoginOnlineChannelRequesting = (state) => {
  const loginOnlineChannelRequest = state.onlineChannel.requestLoginOnlineChannel
  return request.getIsRequesting(loginOnlineChannelRequest)
}

// TODO ADD ERROR
export const getIsOnlineChannelError = (state) => {
  const onlineChannelRequest = state.onlineChannel.requestOnlineChannel
  return request.getIsError(onlineChannelRequest)
}

export const getIsOnlineChannelErrorByUser = (state) => state.onlineChannel.loginErrorByUser

export const getOnlineChannelMetadata = (state) => state.onlineChannel.metadata
