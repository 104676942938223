const menu = {
  overrides: {
    MuiMenu: {
      paper: {
        minWidth: 200,
      },
    },
  },
}

export default menu
