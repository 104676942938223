const urlBase = process.env.REACT_APP_BACKEND_URL
const backEntryPoint = process.env.REACT_APP_BACKEND_ENTRY_POINT
const makeBody = (data) => (data != null ? JSON.stringify(data) : undefined)

const getItemSessionStorage = (key) => {
  try {
    return sessionStorage.getItem(key)
  } catch (err) {
    console.error(err)
    throw new Error(`Error getting ${key} on localStorage`)
  }
}

const makeCommonHeaders = (extraHeaders = {}, withAuthorizationHeader = false) => {
  let authorizationHeader = {}

  if (withAuthorizationHeader) {
    const accessToken = getItemSessionStorage('accessToken')
    const idToken = getItemSessionStorage('idToken')
    authorizationHeader = accessToken && idToken ? { Authorization: `Bearer ${accessToken}`, 'X-Id-Token': idToken } : {}
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...authorizationHeader,
    ...extraHeaders,
  }
}

const checkResponse = async (response) => {
  if (!response.ok) {
    throw response
  }

  if (response.status === 204) {
    // 204 status does not have data
    return response
  }

  const data = await response.json()
  return data
}

const readUrl = (url = '', entryPoint = backEntryPoint) => new URL(`${urlBase}/${entryPoint}/${url}`).href

const put = async (url, data, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'PUT',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
    body: makeBody(data),
  })
  return checkResponse(res)
}

export { put }
