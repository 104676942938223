import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Field, ErrorMessage } from 'formik'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  TextField as MuiTextField,
  Select,
  Tooltip,
  Divider,
  Collapse,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Link from 'ui/components/link'
import {
  ID_DOC_TYPES,
  ID_DOC_TYPES_PYMES,
  SELECTOR_LANGUAGES,
  PYME,
  ENERGY_USES,
  POSITIONS,
  NATIONALITIES,
} from 'utils/constants'
import Typography from 'ui/components/typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import useStyles from './styles'
import { useSelector } from 'react-redux'
import { getSelectedChannel } from 'services/operator/selectors'

const ContactDataForm = ({
  onChange,
  touched,
  values,
  errors,
  resetForm,
  onPrivacyPolicyClick,
  onBonoSocialClick,
  isOnlineChannel,
  isOnlyToppings,
  setFieldValue,
  userData,
  onlineInvoice,
  isCompletedBillingStep,
  emailDisabled,
  isDisabledByChannel,
  service,
  referenceSegment,
  isResetFunnelContract,
  isExClient,
  isNewClient,
  hasTenuresInCurrentCompany,
  isEnergyUseOnlineChannelValid,
  company,
  sendGAeventsCheck,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isAcceptancesExpanded, setIsAcceptancesExpanded] = useState(true)
  const selectedChannel = useSelector(getSelectedChannel)
  const CHANNELS_LIMITED = [
    'Fuerza de Ventas con Verificacion',
    'Operaciones con Verificacion',
    'Nuevos Colaboradores',
    'Expansión',
    'Venta en Remoto Local',
    'Venta en Remoto-Conecta Expandia',
    'Venta en Remoto-Dexo',
    'Venta en Remoto-Equality',
    'Venta en Remoto-Solvion',
    'Mayorista',
  ]

  const sendGACheckPrivacyPolicy = (name) => {
    sendGAeventsCheck(name)
  }

  const handleAcceptancesClick = () => {
    setFieldValue('entirePartyPolicy', !values.entirePartyPolicy)
    setFieldValue('thirdPartyPolicy', !values.entirePartyPolicy)
    setFieldValue('publicityPolicy', !values.entirePartyPolicy)
    setFieldValue('complexProfilePolicy', !values.entirePartyPolicy)
    sendGACheckPrivacyPolicy('datos')
    sendGACheckPrivacyPolicy('perfil')
    sendGACheckPrivacyPolicy('comunicados')
  }

  const maskUserData =
    userData &&
    !isEmptyObject(userData) &&
    !isOnlineChannel &&
    (!isNewClient || (isDisabledByChannel && !isExClient)) &&
    CHANNELS_LIMITED.includes(selectedChannel.name)

  const withNationalityDocTypes = [
    ID_DOC_TYPES.nie.value,
    ID_DOC_TYPES.passport.value,
    ID_DOC_TYPES_PYMES.nie.value,
    ID_DOC_TYPES_PYMES.passport.value,
  ]
  const maskEmail = (email) => {
    if (email && userData?.email && maskUserData) {
      const emailParts = email.split('@')
      const emailName = emailParts[0]
      const emailDomain = emailParts[1]
      const emailNameMasked = emailName.replace(/./g, '*')
      return `${emailNameMasked}@${emailDomain}`
    } else if (email) {
      return email
    }
    return ''
  }

  const OPTIONAL = t('common.optional')?.at(0)?.toLocaleLowerCase() + t('common.optional')?.slice(1)

  useEffect(() => {
    if (isResetFunnelContract) {
      resetForm()
    }
  }, [isResetFunnelContract, resetForm])

  return (
    <>
      <Grid container spacing={3}>
        {/* --- ID DOCUMENT TYPE --- */}
        <Grid
          item
          xs={12}
          sm={
            (isNewClient || isOnlineChannel || !hasTenuresInCurrentCompany) &&
            values.idDocumentType &&
            withNationalityDocTypes.includes(values.idDocumentType)
              ? 4
              : 6
          }
        >
          <FormControl fullWidth>
            <InputLabel error={!!(touched.idDocumentType && errors.idDocumentType)} htmlFor="idDocumentType">
              {t('form.fields.idDocumentType')}
            </InputLabel>
            <Field
              component={Select}
              native
              error={!!(touched.idDocumentType && errors.idDocumentType)}
              id="idDocumentType"
              name="idDocumentType"
              label={t('form.fields.idDocumentType')}
              value={t(values.idDocumentType)}
              onChange={onChange('idDocumentType')}
              disabled={!isOnlineChannel}
            >
              {Object.entries(referenceSegment === PYME ? ID_DOC_TYPES_PYMES : ID_DOC_TYPES)
                .filter(([key]) => key !== 'passport')
                .map(([key, { value, label }]) => (
                  <option key={key} value={value}>
                    {t(label)}
                  </option>
                ))}
            </Field>
          </FormControl>
          <ErrorMessage component={FormHelperText} name="idDocumentType" error />
        </Grid>

        {/* --- NATIONALITY --- */}
        {(isNewClient || isOnlineChannel || !hasTenuresInCurrentCompany) &&
          values.idDocumentType &&
          withNationalityDocTypes.includes(values.idDocumentType) && (
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel error={!!errors.nationality} htmlFor="nationality">
                  {t('form.fields.nationality')}
                </InputLabel>
                <Field
                  component={Select}
                  native
                  error={!!errors.nationality}
                  id="nationality"
                  name="nationality"
                  label={t('form.fields.nationality')}
                  value={t(values.nationality)}
                  onChange={onChange('nationality')}
                >
                  <option aria-label="None" value="" />
                  {NATIONALITIES[values.idDocumentType]?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormControl>
              <ErrorMessage component={FormHelperText} name="nationality" error />
            </Grid>
          )}

        {/* --- ID DOCUMENT NUMBER --- */}
        <Grid
          item
          xs={12}
          sm={
            (isNewClient || isOnlineChannel || !hasTenuresInCurrentCompany) &&
            values.idDocumentType &&
            withNationalityDocTypes.includes(values.idDocumentType)
              ? 4
              : 6
          }
        >
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.idDocumentNumber && errors.idDocumentNumber)}
            id="idDocumentNumber"
            label={t('form.fields.idDocumentNumber')}
            name="idDocumentNumber"
            value={values.idDocumentNumber}
            onChange={onChange('idDocumentNumber')}
            disabled={!isOnlineChannel}
          />
          <ErrorMessage component={FormHelperText} name="idDocumentNumber" error />
        </Grid>
        {!maskUserData && (
          <>
            {/* --- NAME or BUSINESS NAME--- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.name && errors.name)}
                id="name"
                label={values.idDocumentType === 'CIF' ? t('form.fields.businessName') : t('form.fields.firstName')}
                name="name"
                value={values.name}
                onChange={onChange('name')}
                disabled={!isOnlineChannel && (!isNewClient || (isDisabledByChannel && !isExClient))}
              />
              <ErrorMessage component={FormHelperText} name="name" error />
            </Grid>

            {/* --- LASTNAME --- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.lastName && errors.lastName)}
                id="lastName"
                label={t('form.fields.lastNameOpt')}
                name="lastName"
                value={values.lastName}
                onChange={onChange('lastName')}
                disabled={!isOnlineChannel && (!isNewClient || (isDisabledByChannel && !isExClient))}
              />
              <ErrorMessage component={FormHelperText} name="lastName" error />
            </Grid>

            {/* --- SECONDLASTNAME --- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.secondLastName && errors.secondLastName)}
                id="secondLastName"
                label={t('form.fields.secondLastNameOpt')}
                name="secondLastName"
                value={values.secondLastName}
                onChange={onChange('secondLastName')}
                disabled={!isOnlineChannel && (!isNewClient || (isDisabledByChannel && !isExClient))}
              />
              <ErrorMessage component={FormHelperText} name="secondLastName" error />
            </Grid>
          </>
        )}
        {/* --- PHONE --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.phone && errors.phone)}
            id="phone"
            label={t('form.fields.phone')}
            name="phone"
            value={values.phone}
            onChange={onChange('phone')}
          />
          <ErrorMessage component={FormHelperText} name="phone" error />
        </Grid>

        {/* --- EMAIL --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.email && errors.email)}
            id="email"
            label={isOnlineChannel ? t('form.fields.email') : `${t('form.fields.email')} (${OPTIONAL})`}
            name="email"
            value={maskEmail(values.email)}
            onChange={onChange('email')}
            disabled={!isOnlineChannel && (!isNewClient || emailDisabled) && userData?.email}
            InputProps={{
              endAdornment: emailDisabled && (
                <Tooltip placement="top" title={t('tooltip.emailDisabled')}>
                  <InfoOutlinedIcon color="primary" />
                </Tooltip>
              ),
            }}
          />
          <ErrorMessage component={FormHelperText} name="email" error />
        </Grid>

        {/* --- LANGUAGE --- */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="language">{t('form.fields.language')}</InputLabel>
            <Field
              component={Select}
              native
              error={!!(touched.language && errors.language)}
              id="language"
              name="language"
              label={t('form.fields.language')}
              value={values.language}
              onChange={onChange('language')}
              disabled={!isOnlineChannel && (!isNewClient || isDisabledByChannel)}
            >
              <option aria-label="None" value="" />
              {Object.entries(SELECTOR_LANGUAGES).map(([key, lang]) => (
                <option key={key} value={lang.value}>
                  {t(lang.label)}
                </option>
              ))}
            </Field>
          </FormControl>
          <ErrorMessage component={FormHelperText} name="language" error />
        </Grid>
      </Grid>

      {onlineInvoice && isCompletedBillingStep && !values.email && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} alignItems="center" severity="warning">
                <Typography variant="basicBig">{t('form.fields.emailRequiredOnlineInvoice')}</Typography>
              </Alert>
            </Box>
          </Grid>
        </Grid>
      )}

      {referenceSegment === PYME && (
        <>
          <Divider className={classes.block} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box mb={1} display="flex" alignItems="center">
                <Typography variant="h6" color="textPrimary">
                  {t('form.fields.energyUse')}
                </Typography>
                <Tooltip className={classes.tooltip} placement="top" title={t('pymeTooltip.energyUse')}>
                  <InfoOutlinedIcon color="primary" />
                </Tooltip>
              </Box>
              <FormControl fullWidth>
                <InputLabel error={!!(touched.energyUse && errors.energyUse)} htmlFor="energyUse">
                  {t('form.fields.energyUse')}
                </InputLabel>
                <Field
                  component={Select}
                  native
                  error={!!(touched.energyUse && errors.energyUse)}
                  id="energyUse"
                  name="energyUse"
                  label={t('form.fields.energyUse')}
                  value={t(values.energyUse)}
                  onChange={onChange('energyUse')}
                  disabled={isOnlineChannel && isEnergyUseOnlineChannelValid}
                >
                  {Object.entries(ENERGY_USES).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormControl>
              <ErrorMessage component={FormHelperText} name="energyUse" error />
            </Grid>

            {/* --- CNAE --- */}
            <Grid item xs={12} sm={6}>
              <Collapse in={values.energyUse === ENERGY_USES.commercial} timeout={0}>
                <Box mb={1} display="flex" alignItems="center">
                  <Typography variant="h6" color="textPrimary">
                    {t('form.fields.cnae')}
                  </Typography>
                  <Tooltip className={classes.tooltip} placement="top" title={t('pymeTooltip.cnae')}>
                    <InfoOutlinedIcon color="primary" />
                  </Tooltip>
                </Box>
                <Field
                  component={MuiTextField}
                  required
                  fullWidth
                  error={!!(touched.cnae && errors.cnae)}
                  id="cnae"
                  label={t('form.fields.cnae')}
                  name="cnae"
                  value={values.cnae}
                  onChange={onChange('cnae')}
                />
                <ErrorMessage component={FormHelperText} name="cnae" error />
              </Collapse>
            </Grid>
          </Grid>
          <Box mt={4} mb={2}>
            <Typography variant="h5" color="primary">
              {t('representativeData')}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {/* --- NAME --- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                id="contactName"
                label={t('form.fields.firstName')}
                name="contactName"
                value={values.contactName}
                onChange={onChange('contactName')}
                error={!!(touched.contactName && errors.contactName)}
              />
              <ErrorMessage component={FormHelperText} name="contactName" error />
            </Grid>

            {/* --- LAST NAME --- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                id="contactLastName"
                label={t('form.fields.lastNameOpt')}
                name="contactLastName"
                value={values.contactLastName}
                onChange={onChange('contactLastName')}
                error={!!(touched.contactLastName && errors.contactLastName)}
              />
              <ErrorMessage component={FormHelperText} name="contactLastName" error />
            </Grid>

            {/* --- SECOND LAST NAME --- */}
            <Grid item xs={12} sm={4}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                id="contactSecondLastName"
                label={t('form.fields.secondLastNameOpt')}
                name="contactSecondLastName"
                value={values.contactSecondLastName}
                onChange={onChange('contactSecondLastName')}
                error={!!(touched.contactSecondLastName && errors.contactSecondLastName)}
              />
              <ErrorMessage component={FormHelperText} name="contactSecondLastName" error />
            </Grid>

            {/* --- DEGREE --- */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel htmlFor="contactDegree">{t('form.fields.degree')}</InputLabel>
                <Field
                  component={Select}
                  fullWidth
                  native
                  id="contactDegree"
                  name="contactDegree"
                  value={values.contactDegree}
                  onChange={onChange('contactDegree')}
                  error={!!(touched.contactDegree && errors.contactDegree)}
                >
                  <option aria-label="none" value="" />
                  {POSITIONS.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </Field>
              </FormControl>
              <ErrorMessage component={FormHelperText} name="contactDegree" error />
            </Grid>

            {/* --- DOCUMENT NUMBER --- */}
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.contactDocumentNumber && errors.contactDocumentNumber)}
                id="contactDocumentNumber"
                label={`${t('form.fields.nif')} / ${t('form.fields.nie')}`}
                name="contactDocumentNumber"
                value={values.contactDocumentNumber}
                onChange={onChange('contactDocumentNumber')}
              />
              <ErrorMessage component={FormHelperText} name="contactDocumentNumber" error />
            </Grid>

            {/* --- PHONE --- */}
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.contactTelephone && errors.contactTelephone)}
                id="contactTelephone"
                label={t('form.fields.landline')}
                name="contactTelephone"
                value={values.contactTelephone}
                onChange={onChange('contactTelephone')}
              />
              <ErrorMessage component={FormHelperText} name="contactTelephone" error />
            </Grid>

            {/* --- MOBILE PHONE --- */}
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.contactMobilePhone && errors.contactMobilePhone)}
                id="contactMobilePhone"
                label={t('form.fields.phone')}
                name="contactMobilePhone"
                value={values.contactMobilePhone}
                onChange={onChange('contactMobilePhone')}
              />
              <ErrorMessage component={FormHelperText} name="contactMobilePhone" error />
            </Grid>

            {/* --- FAX --- */}
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                fullWidth
                error={!!(touched.contactFax && errors.contactFax)}
                id="contactFax"
                label={t('form.fields.fax')}
                name="contactFax"
                value={values.contactFax}
                onChange={onChange('contactFax')}
              />
              <ErrorMessage component={FormHelperText} name="contactFax" error />
            </Grid>

            {/* --- EMAIL --- */}
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                error={!!(touched.contactEmail && errors.contactEmail)}
                id="contactEmail"
                label={t('form.fields.optionalEmail')}
                name="contactEmail"
                value={values.contactEmail}
                onChange={onChange('contactEmail')}
              />
              <ErrorMessage component={FormHelperText} name="contactEmail" error />
            </Grid>
          </Grid>
        </>
      )}

      {isOnlineChannel && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" my={2}>
              <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} alignItems="center" severity="warning">
                <Typography variant="basic">{t('form.fields.keepPhoneClose')}</Typography>
              </Alert>
            </Box>
          </Grid>

          {referenceSegment === PYME ? (
            <>
              <Box mb={1} className={classes.acceptancesBox}>
                <Typography className={classes.acceptancesTitle}>{t('legal.acceptances.title')}</Typography>
              </Box>
              <Box mb={1}>
                <Typography className={classes.acceptancesText}>
                  <Trans i18nKey={`legal.acceptances.${company ? company : 'gns'}.firstParagraphPymes`}>
                    <Link underline="always" onClick={onPrivacyPolicyClick} target="_blank"></Link>
                  </Trans>
                </Typography>
              </Box>
            </>
          ) : (
            <Grid item xs={12}>
              <Box mb={1} onClick={() => setIsAcceptancesExpanded(!isAcceptancesExpanded)} className={classes.acceptancesBox}>
                <Typography className={classes.acceptancesTitle}>{t('legal.acceptances.title')}</Typography>
                {!isAcceptancesExpanded && <Typography className={classes.acceptancesSeeMore}>{t('common.seeMore')}</Typography>}
              </Box>
              <Collapse in={isAcceptancesExpanded} timeout="auto">
                <Box mb={1}>
                  <Typography className={classes.acceptancesText}>
                    <Trans i18nKey={`legal.acceptances.${company ? company : 'gns'}.firstParagraph`}>
                      <Link underline="always" onClick={onPrivacyPolicyClick} target="_blank"></Link>
                    </Trans>
                  </Typography>
                </Box>
                <FormControl component="fieldset">
                  <Box mb={1} px={1}>
                    <FormGroup row aria-label="entirePartyPolicy" name="entirePartyPolicy" onChange={handleAcceptancesClick}>
                      <FormControlLabel
                        name="entirePartyPolicy"
                        control={<Checkbox size="small" checked={values.entirePartyPolicy} />}
                        label={
                          <Typography className={classes.acceptancesTitle}>{t(`legal.acceptances.entirePartyPolicy`)}</Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                  <Box ml={2} mb={1} px={1}>
                    <FormGroup
                      row
                      aria-label="thirdPartyPolicy"
                      name="thirdPartyPolicy"
                      onClick={() => sendGACheckPrivacyPolicy('comunicados')}
                      onChange={onChange('thirdPartyPolicy')}
                    >
                      <FormControlLabel
                        name="thirdPartyPolicy"
                        control={<Checkbox size="small" checked={values.entirePartyPolicy || values.thirdPartyPolicy} />}
                        label={
                          <Typography className={classes.acceptancesText}>{t(`legal.acceptances.thirdPartyPolicy`)}</Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                  <Box ml={2} mb={1} px={1}>
                    <FormGroup
                      row
                      aria-label="publicityPolicy"
                      name="publicityPolicy"
                      onClick={() => sendGACheckPrivacyPolicy('datos')}
                      onChange={onChange('publicityPolicy')}
                    >
                      <FormControlLabel
                        name="publicityPolicy"
                        control={<Checkbox size="small" checked={values.entirePartyPolicy || values.publicityPolicy} />}
                        label={
                          <Typography className={classes.acceptancesText}>{t('legal.acceptances.publicityPolicy')}</Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                  <Box ml={2} mb={1} px={1}>
                    <FormGroup
                      row
                      aria-label="complexProfilePolicy"
                      name="complexProfilePolicy"
                      onClick={() => sendGACheckPrivacyPolicy('perfil')}
                      onChange={onChange('complexProfilePolicy')}
                    >
                      <FormControlLabel
                        name="complexProfilePolicy"
                        control={<Checkbox size="small" checked={values.entirePartyPolicy || values.complexProfilePolicy} />}
                        label={
                          <Typography className={classes.acceptancesText}>
                            {t('legal.acceptances.complexProfilePolicy')}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                  <Box mt={1} mb={2}>
                    <Typography className={classes.acceptancesText}>
                      <Trans i18nKey="legal.acceptances.secondParagraph">
                        <Link underline="always" onClick={onPrivacyPolicyClick} target="_blank"></Link>
                      </Trans>
                    </Typography>
                  </Box>
                  <Box>
                    <Box mb={1}>
                      <Typography className={classes.acceptancesTitle}>{t('legal.bonoSocialTitle')}</Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography className={classes.acceptancesText}>{t('form.fields.bonoSocialText')}</Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.acceptancesText}>
                        <Trans i18nKey="form.fields.bonoSocialLink">
                          <Link underline="always" onClick={onBonoSocialClick} target="_blank"></Link>
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </FormControl>
              </Collapse>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

export default ContactDataForm
