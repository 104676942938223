import { WITOUT_SELFCONSUMPTION } from './constants'
import { ELECTRICITY } from 'utils/constants'

export const isProductCompatible = (product, type, types) => {
  const noneType = types.find(({ code }) => code === WITOUT_SELFCONSUMPTION)
  const electricityProduct = product.find(({ energyType }) => energyType === ELECTRICITY)
  if (electricityProduct && type && type !== noneType?.code) {
    const withExcess = types.find(({ code }) => code === type)?.withExcess
    const isCompatible = withExcess
      ? !!electricityProduct.selfConsumptionWithExcessComp
      : !!electricityProduct.selfConsumptionWithoutExcessComp

    return isCompatible
  }

  return true
}

export const isSelfconsumptionTypeMigration = (formSelfConsumptionType, currentSelfConsumptionType) => {
  return (
    formSelfConsumptionType !== WITOUT_SELFCONSUMPTION &&
    !!currentSelfConsumptionType &&
    !!formSelfConsumptionType &&
    currentSelfConsumptionType !== WITOUT_SELFCONSUMPTION &&
    currentSelfConsumptionType !== formSelfConsumptionType
  )
}
