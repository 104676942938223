import { get, put, post, del } from '../client'

export const getEnergyById = (id) => get(`energies/${id}`, true)

export const getAllEnergies = () => get(`energies`, true)

export const getAllEnergiesLDJson = async (props = {}) => {
  const queryParams =
    Object.entries(props)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const response = await get(`energies?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return {
    energies: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  }
}

export const putEnergy = (id, params) => put(`energies/${id}`, params)

export const postEnergy = (params) => post(`energies`, params)

export const deleteEnergy = (energyId) => del(`energies/${energyId}`)
