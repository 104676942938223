import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  stateCaption: {
    color: theme.palette.feedback.danger,
    fontWeight: 'bold',
  },
}))

export default useStyles
