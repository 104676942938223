import { remove } from 'services/sessionStorage'
import { ADMIN_EXTERNAL_IDS } from 'utils/constants'

export const removeSessionStorageData = () => {
  remove('logged')
  remove('selectedChannel')
  remove('operatorCompanies')
  remove('referenceSegment')
  remove('accessToken')
  remove('idToken')
}

export const isAdminUser = (externalId) => ADMIN_EXTERNAL_IDS.includes(externalId)

export const getIsVTChannel = (channelName) => {
  const isVTChannel =
    (channelName.includes('VT') || channelName.includes('Venta Telefónica')) && !channelName.includes('Digital Partners')
  return isVTChannel
}
