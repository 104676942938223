import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'

import { encryptPassword } from 'utils/crypto'

import LoginForm from 'ui/forms/login'

import LoginView from 'ui/modals/login'
import { startLoginOnlineChannel } from 'services/onlineChannel/actions'
import { getUserLoggedOnlineChannelState, getIsLoginOnlineChannelRequesting } from 'services/onlineChannel/selectors'

const Login = ({ handleClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoginOnlineChannelRequesting = useSelector(getIsLoginOnlineChannelRequesting)
  const userLoggedOnlineChannelState = useSelector(getUserLoggedOnlineChannelState)

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['nif', 'password']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })

    if (values.nif && (values.nif.length > 9 || values.nif.includes('@'))) {
      errors.nif = t('form.errors.invalidNif')
    }

    return errors
  }

  const handleFormSubmit = async (formData) => {
    dispatch(startLoginOnlineChannel(formData.nif.toUpperCase(), encryptPassword(formData.password)))
  }

  return (
    <LoginView error={userLoggedOnlineChannelState === 'ERROR'} success={userLoggedOnlineChannelState === 'SUCCESS'}>
      <Formik initialValues={{ nif: '', password: '' }} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
        {({ handleChange, touched, errors, values }) => (
          <Form noValidate>
            <LoginForm
              onChange={handleChange}
              onCancel={handleClose}
              touched={touched}
              errors={errors}
              values={values}
              isFetching={isLoginOnlineChannelRequesting}
            />
          </Form>
        )}
      </Formik>
    </LoginView>
  )
}

export default Login
