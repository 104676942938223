import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2, 3, 6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7, 7, 6),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
  dialogActions: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(0, 3, 3),
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      margin: theme.spacing(0, 0, 2),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 7, 5),
      '& .MuiButton-root': {
        margin: theme.spacing(0, 0, 0, 3),
      },
    },
  },
  alertWrapper: {
    padding: theme.spacing(0, 3, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7, 6),
    },
  },
}))

export default useStyles
