import { useReducer } from 'react'

export default function useCalculatorTabs() {
  const initialStateElectricity = {
    completed: false,
    isFormClosed: false,
    hideCard: true,
  }
  const initialStateGas = {
    completed: false,
    isFormClosed: false,
    hideCard: true,
  }

  const reset = (state) => {
    return state
  }

  const reducerTypes = {
    COMPLETE: { type: 'COMPLETE' },
    HIDE: { type: 'HIDE' },
    HANDLE: { type: 'HANDLE' },
    EDIT: { type: 'EDIT' },
    TOGGLE_EDIT: { type: 'TOGGLE_EDIT' },
    RESET: (payload) => ({ type: 'RESET', payload }),
  }

  const reducer = (state, action) => {
    if (!action) return state
    const reducers = {
      COMPLETE: {
        ...state,
        completed: true,
        isFormClosed: true,
        hideCard: false,
      },
      HIDE: {
        ...state,
        isFormClosed: true,
        hideCard: true,
      },
      HANDLE: {
        ...state,
        isFormClosed: state.completed,
        hideCard: !state.completed,
      },
      TOGGLE_EDIT: {
        ...state,
        isFormClosed: !state.isFormClosed,
      },
      RESET: reset(action.payload),
    }
    return reducers[action.type] || 'Action does not exist'
  }

  const [stateGas, dispatchGas] = useReducer(reducer, initialStateGas)
  const [stateElectricity, dispatchElectricity] = useReducer(reducer, initialStateElectricity)

  return { stateGas, stateElectricity, initialStateGas, initialStateElectricity, dispatchGas, dispatchElectricity, reducerTypes }
}
