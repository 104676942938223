import { makeStyles, fade } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(3, 3, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6, 2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 10, 2),
    },
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 2),
    ...theme.typography.basic,
    color: theme.palette.primary.main,

    '& ul': {
      paddingLeft: '1em',
      marginTop: 0,
    },
    '& li': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 6, 2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 10, 2),
    },
  },
  dialogActions: {
    padding: theme.spacing(0, 3, 3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 6, 5),
    },
  },
  content: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 3),
    boxShadow: theme.shadows[2],
    flexWrap: 'nowrap',
    border: '2px solid transparent',
    transition: theme.transitions.create('border-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      cursor: 'pointer',
      borderColor: fade(theme.palette.secondary.main, 0.5),
    },
    '&.selected': {
      borderColor: fade(theme.palette.secondary.main, 0.5),
    },
  },
  radioContainer: {
    marginRight: theme.spacing(2),
  },
}))

export default useStyles
