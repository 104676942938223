import { SERVICE } from './constants'

export const SET_DRAFT_ORDER = `${SERVICE}/SET_DRAFT_ORDER`
export const CLEAR_DRAFT_ORDER = `${SERVICE}/CLEAR_DRAFT_ORDER`
export const SET_DRAFT_LEAD = `${SERVICE}/SET_DRAFT_LEAD`
export const CLEAR_DRAFT_LEAD = `${SERVICE}/CLEAR_DRAFT_LEAD`
export const SET_DRAFT_USER_ID = `${SERVICE}/SET_DRAFT_USER_ID`
export const CLEAR_DRAFT_USER_ID = `${SERVICE}/CLEAR_DRAFT_USER_ID`
export const SET_DRAFT_CHANNEL_ID = `${SERVICE}/SET_DRAFT_CHANNEL_ID`
export const CLEAR_DRAFT_CHANNEL_ID = `${SERVICE}/CLEAR_DRAFT_CHANNEL_ID`
export const SET_DRAFT_CHANNEL_OFFER_ID = `${SERVICE}/SET_DRAFT_CHANNEL_OFFER_ID`
export const CLEAR_DRAFT_CHANNEL_OFFER_ID = `${SERVICE}/CLEAR_DRAFT_CHANNEL_OFFER_ID`
export const FETCH_DRAFT = `${SERVICE}/FETCH_DRAFT`
export const START_DRAFT = `${SERVICE}/START_DRAFT`
export const SUCCESS_DRAFT = `${SERVICE}/SUCCESS_DRAFT`
export const ERROR_DRAFT = `${SERVICE}/ERROR_DRAFT`
export const CLEAR_DRAFT = `${SERVICE}/CLEAR_DRAFT`
export const START_FILL_FUNNEL_WITH_DRAFT = `${SERVICE}/START_FILL_FUNNEL_WITH_DRAFT`
export const SUCCESS_FILL_FUNNEL_WITH_DRAFT = `${SERVICE}/SUCCESS_FILL_FUNNEL_WITH_DRAFT`
export const ERROR_FILL_FUNNEL_WITH_DRAFT = `${SERVICE}/ERROR_FILL_FUNNEL_WITH_DRAFT`
export const CLEAR_FILL_FUNNEL_WITH_DRAFT = `${SERVICE}/CLEAR_FILL_FUNNEL_WITH_DRAFT`
export const SET_DRAFT_ONLINE_CHANNEL = `${SERVICE}/SET_DRAFT_ONLINE_CHANNEL`
export const START_DRAFT_ONLINE_CHANNEL = `${SERVICE}/START_DRAFT_ONLINE_CHANNEL`
export const SUCCESS_DRAFT_ONLINE_CHANNEL = `${SERVICE}/SUCCESS_DRAFT_ONLINE_CHANNEL`
export const ERROR_DRAFT_ONLINE_CHANNEL = `${SERVICE}/ERROR_DRAFT_ONLINE_CHANNEL`
export const CLEAR_DRAFT_ONLINE_CHANNEL = `${SERVICE}/CLEAR_DRAFT_ONLINE_CHANNEL`
export const START_SEND_DRAFT_DATA = `${SERVICE}/START_SEND_DRAFT_DATA`
export const SUCCESS_SEND_DRAFT_DATA = `${SERVICE}/SUCCESS_SEND_DRAFT_DATA`
export const ERROR_SEND_DRAFT_DATA = `${SERVICE}/ERROR_SEND_DRAFT_DATA`
export const CLEAR_SEND_DRAFT_DATA = `${SERVICE}/CLEAR_SEND_DRAFT_DATA`
export const CLEAR_DRAFT_ID = `${SERVICE}/CLEAR_DRAFT_ID`
export const SET_DRAFT_ID = `${SERVICE}/SET_DRAFT_ID`
export const SET_DRAFT_TOKEN = `${SERVICE}/SET_DRAFT_TOKEN`
export const CLEAR_DRAFT_TOKEN = `${SERVICE}/CLEAR_DRAFT_TOKEN`

export const SET_DUPLICATE_ORDER_DRAFT = `${SERVICE}/SET_DUPLICATE_ORDER_DRAFT`
export const CLEAR_DUPLICATE_ORDER_DRAFT = `${SERVICE}/CLEAR_DUPLICATE_ORDER_DRAFT`

export const START_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/START_RECOVER_DRAFT_DUPLICATE_ORDER`
export const SUCCESS_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/SUCCESS_RECOVER_DRAFT_DUPLICATE_ORDER`
export const ERROR_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/ERROR_RECOVER_DRAFT_DUPLICATE_ORDER`
export const CLEAR_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/CLEAR_RECOVER_DRAFT_DUPLICATE_ORDER`

export const FILL_IN_OWNER_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/FILL_IN_OWNER_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER`
export const FILL_IN_PRODUCT_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/FILL_IN_PRODUCT_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER`
export const FILL_IN_SUPPLY_POINT_FROM_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/FILL_IN_SUPPLY_POINT_FROM_RECOVER_DRAFT_DUPLICATE_ORDER`
export const FILL_IN_SERVICE_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/FILL_IN_SERVICE_SELECTION_FROM_RECOVER_DRAFT_DUPLICATE_ORDER`
export const FILL_IN_BILLING_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER = `${SERVICE}/FILL_IN_BILLING_DATA_FROM_RECOVER_DRAFT_DUPLICATE_ORDER`

export const START_DRAFT_DUPLICATE_ORDER = `${SERVICE}/START_DRAFT_DUPLICATE_ORDER`
export const SUCCESS_DRAFT_DUPLICATE_ORDER = `${SERVICE}/SUCCESS_DRAFT_DUPLICATE_ORDER`
export const ERROR_DRAFT_DUPLICATE_ORDER = `${SERVICE}/ERROR_DRAFT_DUPLICATE_ORDER`
export const CLEAR_DRAFT_DUPLICATE_ORDER = `${SERVICE}/CLEAR_DRAFT_DUPLICATE_ORDER`

export const CLEAR_DRAFT_FULL = `${SERVICE}/CLEAR_DRAFT_FULL`
