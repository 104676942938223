import { request } from 'redukers'

export const getCampaigns = (state) => state.campaigns
export const getAllCampaignsMatch = (state) => state.campaigns.all

export const getAppliedCampaign = (state) => state.campaigns.applied

export const getAppliedDiscounts = (state) => {
  const appliedCampaign = state.campaigns.applied
  let discounts = []

  if (appliedCampaign) {
    const {
      electricityVariantDiscounts,
      gasVariantDiscounts,
      electricityServiceDiscounts,
      gasServiceDiscounts,
      genericServiceDiscounts,
    } = appliedCampaign

    discounts = []
      .concat(electricityVariantDiscounts)
      .concat(gasVariantDiscounts)
      .concat(electricityServiceDiscounts)
      .concat(gasServiceDiscounts)
      .concat(genericServiceDiscounts)
  }

  return discounts
}

export const getIsCampaignRequesting = (state) => {
  const campaignRequest = state.campaigns.campaignRequest
  return request.getIsRequesting(campaignRequest)
}

export const getIsCampaignError = (state) => {
  const campaignRequest = state.campaigns.campaignRequest
  return request.getIsError(campaignRequest)
}
