import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(5, 0, 3),
  },
  subtitle: {
    marginBottom: theme.spacing(5),
  },
}))

export default useStyles
