import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  block: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
    direction: 'row',
  },
  icon: {
    color: theme.palette.feedback.success,
    marginRight: theme.spacing(1),
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  small: {
    fontSize: '16px',
  },
}))

export default useStyles
