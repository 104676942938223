import {
  ORDER_IRI,
  CHANNEL_IRI,
  CHANNEL_OFFERS_IRI,
  PRODUCT_VARIANTS_IRI,
  SERVICE_IRI,
  USER_IRI,
  CAMPAIGN_IRI,
  SELFCONSUMPTION_CUPS_TYPE_IRI,
  SELFCONSUMPTION_CUPS_SUBSECTION_TYPE_IRI,
} from './constants'
import { BOTH, COMPANY_CODES, DOMESTIC, ELECTRICITY, ENERGY_USES, GAS } from 'utils/constants'
import { formatNumber } from 'utils/commons'
import {
  SELFCONSUMPTION_DISABLED,
  COLLECTIVE,
  INDIVIDUAL,
  DEFAULT_TYPE_SUBSECTION,
  WITOUT_SELFCONSUMPTION,
  WITOUT_SELFCONSUMPTION_ID,
} from 'services/selfconsumption/constants'

const changeSupplyOwnershipFromDraft = (energyType, draft) => {
  if (!draft) return false
  const { orderlines } = draft
  let orderLine
  if (energyType === ELECTRICITY.toLowerCase()) {
    orderLine = orderlines?.find((orderline) => orderline?.technicalInfoElectricity !== null)
  } else {
    orderLine = orderlines?.find((orderline) => orderline?.technicalInfoGas !== null)
  }

  return orderLine?.changeSupplyOwnership
}

const changeSupplyOwnershipFromDuplicateDraft = (energyType, draft) => {
  if (!draft) return false
  const orderlines = draft?.ownerStep?.supplyOwnerShip
  let orderLine
  if (energyType === ELECTRICITY.toLowerCase()) {
    orderLine = orderlines?.find((orderline) => orderline?.energyType !== null)
  } else {
    orderLine = orderlines?.find((orderline) => orderline?.energyType !== null)
  }

  return orderLine?.changeSupplyOwnerShip
}
const getFlatDraftPrice = (type, flateRate) => {
  if (flateRate) {
    const flatRatePrice = flateRate.filter((item) => item?.pricingCalculation?.energyType === type)
    const flatRatePriceAmount = flatRatePrice[0]?.pricingCalculation
    return flatRatePriceAmount
  }
}

export const getOrders = (
  products,
  order,
  toppings,
  customer,
  selectedChannel,
  campaigns,
  userId,
  guidelineDate,
  isPyme,
  isPhoneChannel,
  selfconsumptionTypes,
  orderDraft,
  onlineChannelDraft,
  duplicateOrdeDraft,
  byCups
) => {
  let productOrderLines = []
  const supplyPoints = customer.supplyPoints
  const supplyOnlinePoints = onlineChannelDraft?.supplyStep
  const orderIdOnline = onlineChannelDraft?.orderId
  const selectedProduct = products.selected
  let selectedVariants = selectedProduct
    .map((product) => product?.variants)
    .flat()
    .filter((variant) => variant)

  if (selectedVariants.length === 0) {
    selectedVariants = undefined
  }
  const isDualTariff =
    supplyPoints?.some(({ energyType }) => energyType === ELECTRICITY) &&
    supplyPoints?.some(({ energyType }) => energyType === GAS)
  let orderIdOnlineChannel
  if (supplyOnlinePoints) {
    if (isDualTariff) {
      orderIdOnlineChannel = supplyOnlinePoints?.electricity?.supplies[0]?.orderId
    } else {
      if (selectedProduct[0].energyType === ELECTRICITY) {
        orderIdOnlineChannel = supplyOnlinePoints?.electricity?.supplies[0]?.orderId
      } else if (selectedProduct[0].energyType === GAS) {
        orderIdOnlineChannel = supplyOnlinePoints?.gas?.supplies[0]?.orderId
      }
    }
  }

  const flatRateFromDraft = orderDraft?.orderlines

  const productsVariants =
    products?.selectedVariants || selectedVariants || getProductVariantDual(isDualTariff, selectedProduct[0])
  const VCN = {
    electricity:
      customer.supplyPoints &&
      customer.supplyPoints.length &&
      customer.supplyPoints.find((sp) => sp?.energyType === ELECTRICITY)?.validationClientNode,
    gas:
      customer.supplyPoints &&
      customer.supplyPoints.length &&
      customer.supplyPoints.find((sp) => sp?.energyType === GAS)?.validationClientNode,
  }

  const changeSupplyOwnership = {
    electricity:
      VCN.electricity === null || VCN.electricity === undefined
        ? customer.changeSupplyOwnership['changeSupplyOwnership-electricity']
        : !VCN.electricity,
    gas: VCN.gas === null || VCN.gas === undefined ? customer.changeSupplyOwnership['changeSupplyOwnership-gas'] : !VCN.gas,
  }
  /*
  We do not take into account the P0 for the change of ownership.
const changeSupplyOwnership = {
  electricity: customer.data['changeSupplyOwnership-electricity'],
  gas: customer.data['changeSupplyOwnership-gas'],
}
*/

  const { locutionData } = order.locutionData || {}
  if (productsVariants) {
    let orderItem
    const supplyPoints = customer.supplyPoints
    const isDualTariff =
      supplyPoints?.some(({ energyType }) => energyType === ELECTRICITY) &&
      supplyPoints?.some(({ energyType }) => energyType === GAS)
    if (isDualTariff) {
      productsVariants.forEach((product) => {
        const techInfo = supplyPoints.find((supplyPoint) => supplyPoint?.energyType === product?.energyType)
        const {
          cups,
          accessTariff,
          postalCode,
          provinceName,
          municipalityName,
          supplyAddress,
          addressLine,
          consumption12Months,
          changeCapacity,
          newSupply,
          activationDate,
          selfConsumptionCupsType,
          selfConsumptionType,
          selfConsumptionCollective,
          selfConsumptionPowerInstalled,
          selfConsumptionRegister,
          selfConsumptionSubsectionType,
        } = techInfo
        const energyProp = product.energyType === GAS ? 'technicalInfoGas' : 'technicalInfoElectricity'
        const capacityProps = {}

        const newSupplyInfo = newSupply
          ? product.energyType === ELECTRICITY
            ? mapNewSupplyElectricityTechInfo(techInfo)
            : mapNewSupplyGasTechInfo(techInfo)
          : {}
        const selectedSelfConsumption =
          selfconsumptionTypes.length &&
          (selfconsumptionTypes.find((item) => item.code === selfConsumptionType) ||
            selfconsumptionTypes.find((item) => item.code === WITOUT_SELFCONSUMPTION))
        const selfConsumptionSubsectionId = selectedSelfConsumption?.selfConsumptionSubsectionType?.find(
          (subsection) => subsection.code === (selfConsumptionSubsectionType || DEFAULT_TYPE_SUBSECTION[selfConsumptionType])
        )?.id

        for (let key in techInfo) {
          if (key.indexOf('capacity') !== -1) {
            capacityProps[key] = typeof techInfo[key] === 'string' ? parseFloat(techInfo[key].replace(',', '.')) : techInfo[key]
          }
        }

        const draftPricingCalculation = getFlatDraftPrice(product.energyType.toUpperCase(), flatRateFromDraft)
        orderItem = {
          byCups: byCups ? (product.energyType === ELECTRICITY ? byCups.electricity : byCups.gas) : null,
          productVariant: `${PRODUCT_VARIANTS_IRI}/${product.id}`,
          [energyProp]: {
            cups,
            accessTariff,
            postalCode,
            provinceName,
            municipalityName,
            addressLine: addressLine || supplyAddress?.addressLine || customer.supplyAddress?.addressLine,
            consumption12Months: formatNumber(consumption12Months)
              ? formatNumber(consumption12Months).toString()
              : consumption12Months,
            validationClientNode: VCN[product.energyType.toLowerCase()],
            changeCapacity,
            ...newSupplyInfo,
            ...capacityProps,
            ...(SELFCONSUMPTION_DISABLED
              ? {
                  selfConsumptionType: `${SELFCONSUMPTION_CUPS_TYPE_IRI}/${
                    selectedSelfConsumption?.id || WITOUT_SELFCONSUMPTION_ID
                  }`,
                }
              : {
                  selfConsumptionCupsType,
                  selfConsumptionType: `${SELFCONSUMPTION_CUPS_TYPE_IRI}/${
                    selectedSelfConsumption?.id || WITOUT_SELFCONSUMPTION_ID
                  }`,
                  selfConsumptionPowerInstalled: selfConsumptionPowerInstalled
                    ? parseFloat(selfConsumptionPowerInstalled.replace(',', '.')) * 1000
                    : 0,
                  selfConsumptionRegister:
                    selfConsumptionRegister === true || selfConsumptionRegister === COLLECTIVE ? COLLECTIVE : INDIVIDUAL,
                  ...(selfConsumptionSubsectionId && {
                    selfConsumptionSubsectionType: `${SELFCONSUMPTION_CUPS_SUBSECTION_TYPE_IRI}/${selfConsumptionSubsectionId}`,
                  }),
                  selfConsumptionCollective:
                    selfConsumptionCollective === true || selfConsumptionCollective === COLLECTIVE ? COLLECTIVE : INDIVIDUAL,
                }),
          },
          changeSupplyOwnership:
            !!changeSupplyOwnership[product.energyType.toLowerCase()] ||
            changeSupplyOwnershipFromDraft(product.energyType.toLowerCase(), orderDraft) ||
            changeSupplyOwnershipFromDuplicateDraft(product.energyType.toLowerCase(), duplicateOrdeDraft),
          activationDate: !!newSupplyInfo?.activationDate
            ? new Date(newSupplyInfo.activationDate).toISOString()
            : activationDate || null,
          guidelineDate,
          newSupply,
          origin: customer?.supplyAddress?.origin || '',
          ...getProductAttributes(
            selectedProduct.length === 1 && selectedProduct[0]?.subProducts
              ? selectedProduct[0].subProducts.find((subProduct) => subProduct.energyType === product.energyType)
              : selectedProduct.find((elem) => elem.energyType === product.energyType),
            customer
          ),
        }

        if (
          selectedProduct.find((elem) => elem.energyType === product.energyType || elem.energyType === BOTH)?.flatRate &&
          (techInfo?.annualCost || draftPricingCalculation?.annualCostAmount)
        ) {
          orderItem.pricingCalculation = {
            annualCostNoTaxes: techInfo?.annualCostNoTaxes || draftPricingCalculation?.annualCostNoTaxes,
            monthlyCostNoTaxes: techInfo?.monthlyCostNoTaxes || draftPricingCalculation?.monthlyCostNoTaxes,
            newCalculation: techInfo?.newCalculation ?? draftPricingCalculation?.newCalculation,
            supplyCapacityP1Amount: techInfo.PowerP1 || draftPricingCalculation?.supplyCapacityP1Amount || 0,
            supplyCapacityP1Unit: 'kW',
            supplyCapacityP2Amount: techInfo.PowerP2 || draftPricingCalculation?.supplyCapacityP2Amount || 0,
            supplyCapacityP2Unit: 'kW',
            annualCostAmount: techInfo.annualCost || draftPricingCalculation?.annualCostAmount,
            annualCostUnit: '€',
            channel: `${CHANNEL_IRI}/${selectedChannel.channelId || selectedChannel.channel.id}`,
            supplyAnnualBaseConsumptionAmount:
              techInfo.consumptionThreshold || draftPricingCalculation?.supplyAnnualBaseConsumptionAmount,
            supplyAnnualBaseConsumptionUnit: 'kWh',
            cups,
            desiredCapacity: techInfo.desiredCapacity,
            energyType: techInfo.energyType,
            maxConsumptionAmount: techInfo.maxConsumptionThreshold || draftPricingCalculation?.maxConsumptionAmount,
            maxConsumptionUnit: 'kWh',
            monthlyCostAmount: techInfo.monthlyCost || draftPricingCalculation?.monthlyCostAmount,
            monthlyCostUnit: '€',
            postalCode,
            annualCostAmountReduced: techInfo.annualCostReduced || draftPricingCalculation?.annualCostAmountReduced,
            monthlyCostAmountReduced: techInfo.monthlyCostReduced || draftPricingCalculation?.monthlyCostAmountReduced,
          }
        }

        productOrderLines.push(orderItem)
      })

      const orderAdditionalInfo = getOrderAdditionalInfo({
        campaigns,
        orderId: order?.orders?.[0]?.id || order?.id || orderDraft.id || orderIdOnlineChannel || orderIdOnline,
        userId,
        channelOffer: selectedChannel.id,
      })

      productOrderLines = [
        {
          orderLines: [
            ...productOrderLines,
            ...getServicesOrderLines(toppings, productOrderLines[0]?.activationDate, guidelineDate, customer),
          ],
          energyUse: isPyme ? customer.data.energyUse : DOMESTIC,
          supplyAddress: mapAddressData(customer, customer.supplyPoints?.[0]),
          ...(isPhoneChannel ? { ...locutionData } : {}),
          ...orderAdditionalInfo,
        },
      ]
    } else if (supplyPoints?.length) {
      supplyPoints?.forEach((supplyPoint) => {
        const orderAdditionalInfo = getOrderAdditionalInfo({
          campaigns,
          orderId:
            supplyPoints.length <= 2
              ? order?.orders?.[0]?.id || order?.id || orderDraft.id || orderIdOnlineChannel || orderIdOnline
              : order?.orders?.find((order) => order.cups === supplyPoint.cups)?.id ||
                orderDraft.id ||
                orderIdOnlineChannel ||
                orderIdOnline,
          userId,
          channelOffer: selectedChannel.id,
        })
        const selectedVariant = productsVariants.find(({ tariff }) => supplyPoint.accessTariff === tariff.type)
        // IN TENURE IF USER HAS ELECTRICITY CUPS BUT THERES NO GAS CUPS AND IS CONTRACTING GAS
        // IT SHOULD NOT GET A SELECTED VARIANT. SAME CASE FOR GAS CUPS CONTRACTING ELECTRICITY.
        // USER SHOULD RETRIEVE ITS CUPS BEFORE SET ITS PRODUCT VARIANT. THAT'S THE REASON THAT
        // IT CAN RETRIEVE A SELECTED VARIANT.
        if (selectedVariant && (supplyPoint.supplyAddress || customer.supplyAddress)) {
          const {
            cups,
            accessTariff,
            postalCode,
            provinceName,
            municipalityName,
            supplyAddress,
            addressLine,
            consumption12Months,
            changeCapacity,
            newSupply,
            activationDate,
            energyType,
            selfConsumptionCupsType,
            selfConsumptionType,
            selfConsumptionCollective,
            selfConsumptionPowerInstalled,
            selfConsumptionRegister,
            selfConsumptionSubsectionType,
          } = supplyPoint

          let energyProp = 'technicalInfoElectricity'
          if (energyType === selectedProduct[0].energyType) {
            if (selectedProduct[0].energyType === GAS) {
              energyProp = 'technicalInfoGas'
            }
          } else {
            energyProp = `technicalInfo${capitalize(energyType)}`
          }
          const capacityProps = {}

          const newSupplyInfo = newSupply
            ? selectedProduct[0].energyType === ELECTRICITY
              ? mapNewSupplyElectricityTechInfo(supplyPoint)
              : mapNewSupplyGasTechInfo(supplyPoint)
            : {}
          const selectedSelfConsumption =
            selfconsumptionTypes.length &&
            (selfconsumptionTypes.find((item) => item.code === selfConsumptionType) ||
              selfconsumptionTypes.find((item) => item.code === WITOUT_SELFCONSUMPTION))
          const selfConsumptionSubsectionId = selectedSelfConsumption?.selfConsumptionSubsectionType?.find((subsection) => {
            return subsection.code === (selfConsumptionSubsectionType || DEFAULT_TYPE_SUBSECTION[selfConsumptionType])
          })?.id

          for (let key in supplyPoint) {
            if (key.indexOf('capacity') !== -1) {
              capacityProps[key] =
                typeof supplyPoint[key] === 'string' ? parseFloat(supplyPoint[key].replace(',', '.')) : supplyPoint[key]
            }
          }

          const draftPricingCalculation = getFlatDraftPrice(energyType.toUpperCase(), flatRateFromDraft)

          orderItem = {
            orderLines: [
              {
                byCups: byCups ? (selectedProduct[0].energyType === GAS ? byCups.gas : byCups.electricity) : null,
                productVariant: `${PRODUCT_VARIANTS_IRI}/${selectedVariant.id}`,
                [energyProp]: {
                  cups,
                  accessTariff,
                  postalCode,
                  provinceName,
                  municipalityName,
                  addressLine: addressLine || supplyAddress?.addressLine || customer.supplyAddress?.addressLine,
                  consumption12Months: formatNumber(consumption12Months)
                    ? formatNumber(consumption12Months).toString()
                    : consumption12Months,
                  validationClientNode: VCN[selectedProduct[0].energyType.toLowerCase()],
                  changeCapacity,
                  ...newSupplyInfo,
                  ...capacityProps,
                  ...(SELFCONSUMPTION_DISABLED
                    ? {
                        selfConsumptionType: `${SELFCONSUMPTION_CUPS_TYPE_IRI}/${
                          selectedSelfConsumption?.id || WITOUT_SELFCONSUMPTION_ID
                        }`,
                      }
                    : {
                        selfConsumptionCupsType,
                        selfConsumptionType: `${SELFCONSUMPTION_CUPS_TYPE_IRI}/${
                          selectedSelfConsumption?.id || WITOUT_SELFCONSUMPTION_ID
                        }`,
                        selfConsumptionPowerInstalled: selfConsumptionPowerInstalled
                          ? parseFloat(selfConsumptionPowerInstalled.replace(',', '.')) * 1000
                          : 0,
                        selfConsumptionRegister:
                          selfConsumptionRegister === true || selfConsumptionRegister === COLLECTIVE ? COLLECTIVE : INDIVIDUAL,
                        ...(selfConsumptionSubsectionId && {
                          selfConsumptionSubsectionType: `${SELFCONSUMPTION_CUPS_SUBSECTION_TYPE_IRI}/${selfConsumptionSubsectionId}`,
                        }),
                        selfConsumptionCollective:
                          selfConsumptionCollective === true || selfConsumptionCollective === COLLECTIVE
                            ? COLLECTIVE
                            : INDIVIDUAL,
                      }),
                },
                origin: customer?.supplyAddress?.origin || '',
                changeSupplyOwnership:
                  !!changeSupplyOwnership[selectedProduct[0].energyType.toLowerCase()] ||
                  changeSupplyOwnershipFromDraft(selectedProduct[0].energyType.toLowerCase(), orderDraft) ||
                  changeSupplyOwnershipFromDuplicateDraft(selectedProduct[0].energyType.toLowerCase(), duplicateOrdeDraft),
                activationDate: !!newSupplyInfo?.activationDate
                  ? new Date(newSupplyInfo.activationDate).toISOString()
                  : activationDate || null,
                guidelineDate,
                newSupply,
                ...getProductAttributes(selectedProduct[0], customer),
                ...(selectedProduct[0]?.flatRate &&
                  (supplyPoint?.annualCost || draftPricingCalculation) && {
                    pricingCalculation: {
                      annualCostNoTaxes: supplyPoint?.annualCostNoTaxes || draftPricingCalculation?.annualCostNoTaxes,
                      monthlyCostNoTaxes: supplyPoint?.monthlyCostNoTaxes || draftPricingCalculation?.monthlyCostNoTaxes,
                      newCalculation: supplyPoint?.newCalculation ?? draftPricingCalculation?.newCalculation,
                      supplyCapacityP1Amount: supplyPoint.PowerP1 || draftPricingCalculation?.supplyCapacityP1Amount || 0,
                      supplyCapacityP1Unit: 'kW',
                      supplyCapacityP2Amount: supplyPoint.PowerP2 || draftPricingCalculation?.supplyCapacityP2Amount || 0,
                      supplyCapacityP2Unit: 'kW',
                      annualCostAmount: supplyPoint.annualCost || draftPricingCalculation?.annualCostAmount,
                      annualCostUnit: '€',
                      channel: `${CHANNEL_IRI}/${selectedChannel.channelId || selectedChannel.channel.id}`,
                      supplyAnnualBaseConsumptionAmount:
                        supplyPoint.consumptionThreshold || draftPricingCalculation?.supplyAnnualBaseConsumptionAmount,
                      supplyAnnualBaseConsumptionUnit: 'kWh',
                      cups,
                      desiredCapacity: supplyPoint.desiredCapacity,
                      energyType: supplyPoint.energyType,
                      maxConsumptionAmount: supplyPoint.maxConsumptionThreshold || draftPricingCalculation?.maxConsumptionAmount,
                      maxConsumptionUnit: 'kWh',
                      monthlyCostAmount: supplyPoint.monthlyCost || draftPricingCalculation?.monthlyCostAmount,
                      monthlyCostUnit: '€',
                      postalCode,
                      monthlyCostAmountReduced:
                        supplyPoint.monthlyCostReduced || draftPricingCalculation?.monthlyCostAmountReduced,
                      monthlyCostUnitReduced: '€',
                      annualCostAmountReduced: supplyPoint.annualCostReduced || draftPricingCalculation?.annualCostAmountReduced,
                    },
                  }),
              },
              ...getServicesOrderLines(toppings, activationDate, guidelineDate, customer),
            ],
            energyUse: isPyme ? customer.data.energyUse : DOMESTIC,
            supplyAddress: mapAddressData(customer, supplyPoint),
            ...(isPhoneChannel ? { ...locutionData } : {}),
            ...orderAdditionalInfo,
          }
          // TODO put pricingCalculation inside orderLines
          // if (selectedProduct?.flatRate && supplyPoint?.annualCost) {
          //   orderItem.pricingCalculation = {
          //     supplyCapacityP1Amount: supplyPoint.PowerP1,
          //     supplyCapacityP1Unit: 'kW',
          //     supplyCapacityP2Amount: supplyPoint.PowerP2,
          //     supplyCapacityP2Unit: 'kW',
          //     annualCostAmount: supplyPoint.annualCost,
          //     annualCostUnit: '€',
          //     channel: `${CHANNEL_IRI}/${selectedChannel.channelId || selectedChannel.channel.id}`,
          //     supplyAnnualBaseConsumptionAmount: supplyPoint.consumptionThreshold,
          //     supplyAnnualBaseConsumptionUnit: 'kWh',
          //     cups,
          //     desiredCapacity: supplyPoint.desiredCapacity,
          //     energyType: supplyPoint.energyType,
          //     maxConsumptionAmount: supplyPoint.maxConsumptionThreshold,
          //     maxConsumptionUnit: 'kWh',
          //     monthlyCostAmount: supplyPoint.monthlyCost,
          //     monthlyCostUnit: '€',
          //     postalCode,
          //   }
          // }

          productOrderLines.push(orderItem)
        }
      })
    } else {
      const orderAdditionalInfo = getOrderAdditionalInfo({
        campaigns,
        orderId: order?.orders?.[0]?.id || order?.id || orderDraft.id || orderIdOnlineChannel || orderIdOnline,
        userId,
        channelOffer: selectedChannel.id,
      })
      productOrderLines = [
        {
          orderLines: [...getServicesOrderLines(toppings, null, guidelineDate, customer)],
          energyUse: isPyme ? customer.data.energyUse : DOMESTIC,
          supplyAddress: customer?.selectedTenure?.addressId
            ? mapAddressData(customer, customer.supplyPoints?.[0])
            : customer.billingAddress || customer.supplyAddress,
          ...(isPhoneChannel ? { ...locutionData } : {}),
          ...orderAdditionalInfo,
        },
      ]
    }
  } else {
    const orderAdditionalInfo = getOrderAdditionalInfo({
      campaigns,
      orderId: order?.orders?.[0]?.id || order?.id || orderDraft.id || orderIdOnlineChannel || orderIdOnline,
      userId,
      channelOffer: selectedChannel.id,
    })

    let supplyAddressOrder
    if (!customer.billingAddress && !customer.supplyAddress && order.supplyAddress) {
      supplyAddressOrder = order.supplyAddress
      supplyAddressOrder.id = '/middleware/addresses/' + order.supplyAddress.id
    }

    return [
      {
        orderLines: [...getServicesOrderLines(toppings, null, guidelineDate, customer)],
        energyUse: isPyme ? customer.data.energyUse : DOMESTIC,
        supplyAddress: customer?.selectedTenure?.addressId
          ? mapAddressData(customer, customer.supplyPoints?.[0])
          : customer.billingAddress || customer.supplyAddress || supplyAddressOrder,
        ...(isPhoneChannel ? { ...locutionData } : {}),
        ...orderAdditionalInfo,
      },
    ]
  }
  return productOrderLines
}

const getProductVariantDual = (isDualTariff, productSelected) => {
  if (!isDualTariff) return null
  const productVariantDual = []
  if (productSelected.subProducts && productSelected.subProducts.length) {
    productSelected.subProducts.forEach((product) => {
      if (product.variants) {
        productVariantDual.concat(product.variants)
      }
    })
  }
  return productVariantDual
}

export const getServicesOrderLines = (toppings, activationDate, guidelineDate, customer) => {
  const servicesOrderLines = []
  const services = toppings.selected
  const selectedToppingsIds = services ? Object.keys(services) : []

  if (selectedToppingsIds.length) {
    selectedToppingsIds.forEach((toppingId) => {
      servicesOrderLines.push({
        service: `${SERVICE_IRI}/${toppingId}`,
        portability: services[toppingId].portability || false,
        tenureServiceId: services[toppingId].tenureServiceId || null,
        activationDate: activationDate || null,
        guidelineDate,
        ...getServicesAttributes(services[toppingId]),
        origin: customer?.supplyAddress?.origin || '',
      })
    })
  }
  return servicesOrderLines
}
export const toCammelCase = (str) => {
  return str.replace(/-([a-z])/g, function (match, group1) {
    return group1.toUpperCase()
  })
}

export const getProductAttributes = (product, customer) => {
  let rawAttributes
  if (product.length > 1) {
    rawAttributes = product.find((subProduct) => subProduct?.energyType === ELECTRICITY)?.attributes
  } else {
    rawAttributes = (
      product?.subProducts ? product?.subProducts.find((subProduct) => subProduct?.energyType === ELECTRICITY) : product
    )?.attributes
  }

  const formattedAttributes =
    rawAttributes &&
    Object.keys(rawAttributes).reduce((acc, key) => {
      if (key === 'product-online' && customer?.company?.code === COMPANY_CODES.newco) {
        acc[toCammelCase(key.replace('product-', ''))] = customer.onlineInvoice
      } else {
        acc[toCammelCase(key.replace('product-', ''))] = rawAttributes[key]
      }
      return acc
    }, {})
  return formattedAttributes
}

export const getServicesAttributes = (service) => {
  const rawAttributes = service.serviceAttributes
  const acc = {}
  rawAttributes.forEach((item) => {
    acc[toCammelCase(item.attribute.name.replace('service-', ''))] = item.enabled
  })
  return acc
}

export const getOrderAdditionalInfo = ({ campaigns, orderId, userId, channelOffer }) => {
  const appliedCampaignId = campaigns.applied?.id
  return {
    ...(orderId && { id: `${ORDER_IRI}/${orderId}` }),
    priceReservationDate: new Date().toISOString(),
    user: `${USER_IRI}/${userId}`,
    channelOffer: `${CHANNEL_OFFERS_IRI}/${channelOffer}`,
    campaign: appliedCampaignId ? `${CAMPAIGN_IRI}/${appliedCampaignId}` : null,
  }
}

export const mapLeadData = ({ customer, isOnlineChannel, tenure }) => {
  let data = {}
  // Customer data
  const {
    email,
    phone,
    name: firstName,
    lastName,
    secondLastName,
    nationality,
    idDocumentType: documentType,
    idDocumentNumber: documentNumber,
    language: preferredLanguage,
    complexProfilePolicy: checkGDPR1,
    publicityPolicy: checkGDPR2,
    thirdPartyPolicy: checkGDPR3,
    retargeting,
    energyUse,
    cnae,
    contactName,
    contactLastName,
    contactSecondLastName,
    contactDegree,
    contactDocumentNumber,
    contactTelephone,
    contactMobilePhone,
    contactFax,
    contactEmail,
  } = customer.data
  const {
    birthdayDate,
    onBehalfContactId,
    referralCode,
    productReferralCode,
    buyapowaCode,
    selectedTenure,
    company: selectedCompany,
  } = customer

  let customerData = {
    email: email?.toLowerCase() || '',
    phone,
    firstName,
    lastName: lastName || '',
    secondLastName: secondLastName || '',
    documentType,
    nationality,
    documentNumber: documentNumber?.toUpperCase(),
    preferredLanguage,
    cnae: cnae && energyUse === ENERGY_USES.commercial ? cnae : null,
    referralCode,
    productReferralCode,
    buyapowaCode,
    contact: contactName
      ? {
          id: onBehalfContactId,
          name: contactName,
          lastName: contactLastName,
          secondLastName: contactSecondLastName,
          degree: contactDegree,
          documentNumber: contactDocumentNumber,
          phone: contactTelephone,
          mobilePhone: contactMobilePhone,
          fax: contactFax,
          email: contactEmail,
        }
      : null,
  }

  // Add GDPR Checks to customerData if is Online Channel
  if (isOnlineChannel) {
    customerData = {
      ...customerData,
      ...{
        checkGDPR1,
        checkGDPR2,
        checkGDPR3,
        retargeting,
        socialBonus: false,
      },
    }
  }

  // Billing data
  const {
    accountHolder,
    accountHolderSurnames,
    accountHolderDocumentNumber,
    accountHolderDocumentType,
    accountHolderLastName,
    accountHolderSecondLastName,
    accountIban,
  } = customer.billingAccount || {}
  const {
    tenureAddressId,
    externalId,
    postalCode,
    provinceName,
    municipalityName,
    municipalityCode,
    provinceCode,
    townName,
    streetType,
    streetName,
    number,
    portal,
    stair,
    floor,
    door,
    placeId,
    extraData,
    masterId,
    isNewAddress,
  } = customer.billingAddress || {}

  let { addressLine, electricityCups, gasCups, technicalInfoId, variants, products, services, ...supplyAddress } =
    customer?.normalizedAddress || {}
  const isPortabilityFromGnsToNewco =
    selectedTenure?.company === COMPANY_CODES.gns && selectedCompany.code === COMPANY_CODES.newco
  supplyAddress = customer?.normalizedAddress
    ? {
        tenureAddressId: customer.selectedTenure?.addressId || supplyAddress.addressId || '',
        externalId: customer.selectedTenure?.addressId || supplyAddress?.externalId,
        postalCode: customer.selectedTenure?.postalCode || supplyAddress?.postalCode || '',
        provinceName: customer.selectedTenure?.provinceName || supplyAddress?.provinceName || '',
        municipalityCode: customer.selectedTenure?.municipalityCode || supplyAddress?.municipalityCode || '',
        municipalityName: customer.selectedTenure?.municipalityName || supplyAddress?.municipalityName || '',
        provinceCode: customer.selectedTenure?.provinceCode || supplyAddress?.provinceCode || '',
        townName: customer.selectedTenure?.addressTown || supplyAddress?.townName || supplyAddress?.municipalityName || '',
        streetType: supplyAddress?.streetType?.toUpperCase() || '',
        streetName: customer.selectedTenure?.addressStreet || supplyAddress?.streetName || '',
        number: customer.selectedTenure?.addressNumber || supplyAddress?.number?.toString() || '',
        portal: customer.selectedTenure?.addressPortal || supplyAddress?.portal || '',
        stair: customer.selectedTenure?.addressStaircase || supplyAddress?.stair || '',
        floor: customer.selectedTenure?.addressFloor || supplyAddress?.floor || '',
        door: customer.selectedTenure?.addressDoor || supplyAddress?.door || '',
        placeId: supplyAddress?.placeId || '',
        masterId: customer.selectedTenure?.masterId || supplyAddress?.verticalId || '',
        extraData: supplyAddress?.extraData || '',
        isNewAddress: supplyAddress?.isNewAddress || false,
      }
    : customer.supplyAddress || undefined
  // TODO IMPROVE MAPPING
  if (isPortabilityFromGnsToNewco && supplyAddress?.tenureAddressId) {
    supplyAddress.tenureAddressId = ''
  } else if (supplyAddress && tenure?.addressId && supplyAddress?.tenureAddressId) {
    supplyAddress.tenureAddressId = tenure.addressId
  }
  const billingAddress = customer?.billingAddress
    ? {
        tenureAddressId: isPortabilityFromGnsToNewco ? '' : tenureAddressId || '',
        externalId,
        postalCode,
        provinceName,
        municipalityName,
        municipalityCode,
        provinceCode,
        townName,
        streetType,
        streetName,
        number,
        portal,
        stair,
        floor,
        door,
        placeId,
        extraData,
        masterId,
        isNewAddress,
      }
    : supplyAddress || undefined
  const billingData = {
    accountHolder,
    accountHolderSurnames,
    accountHolderDocumentNumber,
    accountHolderDocumentType,
    accountHolderLastName,
    accountHolderSecondLastName,
    accountIban: accountIban ? accountIban.replace(/\s/g, '') : undefined,
    billingAddress,
    birthdayDate,
  }

  // Online invoice
  const onlineInvoice = isOnlineChannel || (customer.onlineInvoice && !!email)

  data = {
    customerData,
    billingData,
    onlineInvoice: !!onlineInvoice,
  }

  return data
}

export const mapNewSupplyElectricityTechInfo = ({
  newSupply,
  activationDate,
  consumption12Months,
  name,
  lastName,
  secondLastName,
  degree,
  documentNumber,
  mobilePhone,
  phone,
  fax,
  email,
  ...technicalInfo
}) => {
  const tempNewSupplyObj = {
    ...technicalInfo,
    consumption12Months: formatNumber(consumption12Months) ? formatNumber(consumption12Months).toString() : consumption12Months,
    activationDate: activationDate ? new Date(activationDate).toISOString() : null,
  }

  if (
    Object.hasOwn(tempNewSupplyObj, 'selfConsumptionSubsectionType') &&
    tempNewSupplyObj.selfConsumptionSubsectionType == null
  ) {
    delete tempNewSupplyObj.selfConsumptionSubsectionType
  }
  if (Object.hasOwn(tempNewSupplyObj, 'selfConsumptionType') && tempNewSupplyObj.selfConsumptionType == null) {
    delete tempNewSupplyObj.selfConsumptionType
  }
  return tempNewSupplyObj
}

export const mapNewSupplyGasTechInfo = ({
  newSupply,
  consumption12Months,
  numberOfDevicesTransformed,
  iriCertificateDate,
  thermalInstallationDate,
  activationDate,
  designCapacity,
  ...technicalInfo
}) => {
  const tempNewSupplyObj = {
    ...technicalInfo,
    designCapacity: formatNumber(designCapacity) || designCapacity,
    consumption12Months: formatNumber(consumption12Months) ? formatNumber(consumption12Months).toString() : consumption12Months,
    numberOfDevicesTransformed: parseInt(numberOfDevicesTransformed),
    iriCertificateDate: iriCertificateDate ? new Date(iriCertificateDate).toISOString() : null,
    thermalInstallationDate: thermalInstallationDate ? new Date(thermalInstallationDate).toISOString() : null,
    activationDate: activationDate ? new Date(activationDate).toISOString() : null,
  }
  if (
    Object.hasOwn(tempNewSupplyObj, 'selfConsumptionSubsectionType') &&
    tempNewSupplyObj.selfConsumptionSubsectionType == null
  ) {
    delete tempNewSupplyObj.selfConsumptionSubsectionType
  }
  if (Object.hasOwn(tempNewSupplyObj, 'selfConsumptionType') && tempNewSupplyObj.selfConsumptionType == null) {
    delete tempNewSupplyObj.selfConsumptionType
  }
  return tempNewSupplyObj
}

const mapAddressData = (customer, supplyPoint) => {
  const isPortabilityFromGnsToNewco =
    customer.selectedTenure?.company === COMPANY_CODES.gns && customer.company.code === COMPANY_CODES.newco
  return {
    tenureAddressId: isPortabilityFromGnsToNewco
      ? ''
      : customer.selectedTenure?.addressId || supplyPoint?.supplyAddress?.addressId || customer.supplyAddress?.addressId || '',
    externalId:
      customer.selectedTenure?.externalId || supplyPoint?.supplyAddress?.externalId || customer.supplyAddress?.externalId,
    postalCode:
      customer.selectedTenure?.postalCode || supplyPoint?.supplyAddress?.postalCode || customer.supplyAddress?.postalCode || '',
    provinceName:
      customer.selectedTenure?.provinceName ||
      supplyPoint?.supplyAddress?.provinceName ||
      customer.supplyAddress?.provinceName ||
      '',
    municipalityName:
      customer.selectedTenure?.municipalityName ||
      supplyPoint?.supplyAddress?.municipalityName ||
      customer.supplyAddress?.municipalityName ||
      '',

    provinceCode:
      customer.selectedTenure?.provinceCode ||
      supplyPoint?.supplyAddress?.provinceCode ||
      customer.supplyAddress?.provinceCode ||
      '',
    municipalityCode:
      customer.selectedTenure?.municipalityCode ||
      supplyPoint?.supplyAddress?.municipalityCode ||
      customer.supplyAddress?.municipalityCode ||
      '',
    townName:
      customer.selectedTenure?.addressTown ||
      supplyPoint?.supplyAddress?.addressTown ||
      customer.supplyAddress?.townName ||
      supplyPoint?.supplyAddress?.provinceName ||
      customer.supplyAddress?.provinceName ||
      '',
    streetType: customer.supplyAddress?.streetType?.toUpperCase() || supplyPoint?.supplyAddress?.streetType?.toUpperCase() || '',
    streetName:
      customer.selectedTenure?.addressStreet ||
      supplyPoint?.supplyAddress?.streetName ||
      customer.supplyAddress?.streetName ||
      '',
    number:
      customer.selectedTenure?.addressNumber ||
      supplyPoint?.supplyAddress?.number ||
      customer.supplyAddress?.number?.toString() ||
      '',
    portal: customer.selectedTenure?.addressPortal || supplyPoint?.supplyAddress?.portal || customer.supplyAddress?.portal || '',
    stair: customer.selectedTenure?.addressStaircase || supplyPoint?.supplyAddress?.stair || customer.supplyAddress?.stair || '',
    floor: customer.selectedTenure?.addressFloor || supplyPoint?.supplyAddress?.floor || customer.supplyAddress?.floor || '',
    door: customer.selectedTenure?.addressDoor || supplyPoint?.supplyAddress?.door || customer.supplyAddress?.door || '',
    placeId: customer.selectedTenure?.placeId || supplyPoint?.supplyAddress?.placeId || customer.supplyAddress?.placeId || '',
    masterId:
      customer?.selectedTenure?.masterId ||
      customer.selectedTenure?.verticalId ||
      customer.supplyAddress?.verticalId ||
      supplyPoint?.supplyAddress?.verticalId ||
      '',
    isNewAddress: supplyPoint?.supplyAddress?.isNewAddress || customer.supplyAddress?.isNewAddress || false,
    extraData:
      customer.selectedTenure?.extraData || supplyPoint?.supplyAddress?.extraData || customer.supplyAddress?.extraData || '',
  }
}

const capitalize = (s) => (s && s[0].toUpperCase() + s.toLowerCase().slice(1)) || ''

export const isEqualAddress = (addressA, addressB) => {
  return (
    addressA?.postalCode?.trim().toUpperCase() === addressB?.postalCode?.trim().toUpperCase() &&
    addressA?.provinceName?.trim().toUpperCase() === addressB?.provinceName?.trim().toUpperCase() &&
    addressA?.townName?.trim().toUpperCase() === addressB?.townName?.trim().toUpperCase() &&
    addressA?.municipalityName?.trim().toUpperCase() === addressB?.municipalityName?.trim().toUpperCase() &&
    addressA?.streetName?.trim().toUpperCase() === addressB?.streetName?.trim().toUpperCase() &&
    `${+addressA?.number}` === `${+addressB?.number}` &&
    addressA?.portal?.trim().toUpperCase() === addressB?.portal?.trim().toUpperCase() &&
    addressA?.stair?.trim().toUpperCase() === addressB?.stair?.trim().toUpperCase() &&
    (addressA?.floor?.trim().toUpperCase() === addressB?.floor?.trim().toUpperCase() ||
      `${+addressA?.floor?.trim()}` === `${+addressB?.floor}`) &&
    addressA?.door?.trim().toUpperCase() === addressB?.door?.trim().toUpperCase()
  )
}
