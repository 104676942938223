import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

const MemberGetMemberConditions = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('mgmConditionsTitle')}
          </Typography>
          <Box my={3}>
            <Typography variant="basic" color="primary">
              <div dangerouslySetInnerHTML={{ __html: t('mgmConditionsBody') }} />
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box mb={2}>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            {t('common.close')}
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}

export default MemberGetMemberConditions
