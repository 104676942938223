import { useTranslation } from 'react-i18next'
import { Grid, TextField as MuiTextField } from '@material-ui/core'
import NumberFormatCustom from '@zatopek/core/src/ui/components/numberFormat'

import { CAPACITY_INPUT_CONFIG as INPUT_CONFIG } from 'utils/constants'

const Capacity = ({ onChange, values, errors, onKeyDown, capacity, index, tariff, isCapacityEditMode }) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12} sm={4} key={index}>
      <MuiTextField
        fullWidth
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={values[`capacityP${index + 1}`]}
        variant="outlined"
        label={t(`supply.${capacity}`)}
        name={`capacityP${index + 1}`}
        type="number"
        inputProps={{ ...INPUT_CONFIG[`${tariff}`], inputComponent: NumberFormatCustom }}
        disabled={!isCapacityEditMode}
        error={!!errors[`capacityP${index + 1}`]}
        helperText={errors[`capacityP${index + 1}`]}
        className="numericField"
      />
    </Grid>
  )
}

export default Capacity
