import { all, put, select, takeLatest, call } from 'redux-saga/effects'

import { clearSelectedProduct, clearProductVariants, clearProductsValidation, resetProducts } from 'services/products/actions'
import { removeAllSelectedToppings, resetAllToppings } from 'services/toppings/actions'
import {
  clearCustomer,
  clearBillingAccount,
  clearBillingAddress,
  clearSupplyPoints,
  clearOnlineInvoice,
  clearNormalizedAddress,
  clearSupplyAddress,
  clearSelectedTenure,
  clearSelectedCompany,
  clearTenureSelectionError,
  clearIsNewSupply,
  clearOnBehalfContactId,
  clearBirthdayDate,
  clearIsMultiPoint,
  clearReferralCode,
  clearBuyapowaCode,
  clearProductReferralCode,
  clearChangeOwnerCups,
} from 'services/customer/actions'
import { clearLead, clearLeadId, clearBillingAddressId, clearSupplyAddressesIds } from 'services/lead/actions'
import {
  clearOrderId,
  clearOrderTechnicalInfosIds,
  clearGdprSent,
  clearScoringSent,
  clearSign,
  clearForwardSms,
  resetCRMId,
  resetLocutionData,
  clearGuidelineDate,
} from 'services/order/actions'
import { clearCampaigns, clearAppliedCampaign, clearCampaignRequest } from 'services/campaigns/actions'
import {
  clearActiveStep,
  clearSteps,
  setStepDisabled,
  setStepEnabled,
  setStepUncompleted,
  setStepUnautocompleted,
} from 'services/stepper/actions'
import { setChannels, setIsResetFunnelContract, setUserData } from './actions'
import { clearDraftFull } from 'services/draftCopy/actions'
import { getChannels, getUser, getUserOnline, getChannelsAgv } from './api'
import { STEPS } from 'utils/constants'
import { getRouterQueries } from 'services/analytics/selectors'
import { history } from 'redux/store'

import * as actionTypes from './action-types'
import { removeSessionStorageData } from './business'
import { clearOnlineChannelMetaData } from 'services/onlineChannel/actions'
import { getIsResetFunnelContract } from './selectors'
import { getIsMultiPoint, getIsTenureSelected } from 'services/customer/selectors'
import { resetEnergies } from 'services/energies/actions'

import { clearFidelization, clearNewAddressProductChange, clearNewAddressServiceChange } from 'services/fidelization/actions'
import { clearProductSelection } from 'services/products/actions'
import { clearServiceSelection } from 'services/toppings/actions'
import { clearInvoiceData } from 'services/invoice/actions'

import { clearCupsSearcherByCups } from 'services/cupsSearcher/actions'
import { ONLINE_CHANNEL_PARAM_NOT_FOUND_URL } from 'utils/constants'

function* resetContractSaga() {
  yield put(clearSelectedProduct())
  yield put(clearProductVariants())
  yield put(clearProductsValidation())

  yield put(removeAllSelectedToppings())
  yield put(resetAllToppings())

  yield put(clearSelectedTenure())
  yield put(clearSelectedCompany())
  yield put(clearTenureSelectionError())
  yield put(clearCustomer())
  yield put(clearBillingAccount())
  yield put(clearBillingAddress())
  yield put(clearNormalizedAddress())
  yield put(clearSupplyAddress())
  yield put(clearSupplyPoints())
  yield put(clearOnlineInvoice())
  yield put(clearIsNewSupply())
  yield put(clearOnBehalfContactId())
  yield put(clearBirthdayDate())
  yield put(clearIsMultiPoint())

  yield put(clearLead())
  yield put(clearLeadId())
  yield put(clearBillingAddressId())
  yield put(clearSupplyAddressesIds())

  yield put(clearOrderId())
  yield put(clearOrderTechnicalInfosIds())
  yield put(resetCRMId())
  yield put(resetLocutionData())
  yield put(clearSign())
  yield put(clearForwardSms())
  yield put(clearGdprSent())
  yield put(clearScoringSent())
  yield put(clearGuidelineDate())

  yield put(clearInvoiceData())

  yield put(clearCampaigns())
  yield put(clearCampaignRequest())
  yield put(clearAppliedCampaign())

  yield put(clearActiveStep())
  yield put(clearSteps())
  yield put(setStepUncompleted(STEPS.rate))
  yield put(setStepUncompleted(STEPS.topping))
  yield put(setStepUncompleted(STEPS.supply))
  yield put(setStepUncompleted(STEPS.owner))
  yield put(setStepUncompleted(STEPS.billing))
  yield put(setStepEnabled(STEPS.owner))
  yield put(setStepDisabled(STEPS.supply))
  yield put(setStepEnabled(STEPS.rate))
  yield put(setStepEnabled(STEPS.topping))
  yield put(setStepUnautocompleted(STEPS.rate))
  yield put(setStepUnautocompleted(STEPS.topping))
  yield put(setStepUnautocompleted(STEPS.supply))

  yield put(resetProducts())
  yield put(resetEnergies())

  yield put(clearDraftFull())
  yield put(clearChangeOwnerCups())
  yield put(clearBuyapowaCode())
  yield put(clearReferralCode())
  yield put(clearProductReferralCode())

  yield put(clearFidelization())
  yield put(clearNewAddressProductChange())
  yield put(clearNewAddressServiceChange())
  yield put(clearCupsSearcherByCups())
  yield put(clearProductSelection())
  yield put(clearServiceSelection())
}

function* resetFunnelContractSaga() {
  yield put(clearSelectedProduct())
  yield put(clearProductVariants())
  yield put(clearProductsValidation())

  yield put(removeAllSelectedToppings())

  yield put(clearSelectedTenure())
  yield put(clearSelectedCompany())
  yield put(clearTenureSelectionError())
  yield put(clearBillingAccount())
  yield put(clearBillingAddress())
  yield put(clearNormalizedAddress())
  yield put(clearSupplyAddress())
  yield put(clearSupplyPoints())
  yield put(clearOnlineInvoice())
  yield put(clearOnlineChannelMetaData())
  yield put(clearLead())
  yield put(clearLeadId())
  yield put(clearBillingAddressId())
  yield put(clearSupplyAddressesIds())
  yield put(clearIsNewSupply())
  yield put(clearOnBehalfContactId())
  yield put(clearBirthdayDate())
  yield put(clearIsMultiPoint())

  yield put(clearOrderId())
  yield put(clearOrderTechnicalInfosIds())
  yield put(resetCRMId())
  yield put(resetLocutionData())
  yield put(clearSign())
  yield put(clearForwardSms())
  yield put(clearGdprSent())
  yield put(clearScoringSent())
  yield put(clearGuidelineDate())

  yield put(clearCampaigns())
  yield put(clearCampaignRequest())
  yield put(clearAppliedCampaign())

  yield put(clearInvoiceData())

  yield put(clearActiveStep())
  yield put(clearSteps())
  yield put(setStepUncompleted(STEPS.rate))
  yield put(setStepUncompleted(STEPS.topping))
  yield put(setStepUncompleted(STEPS.owner))
  yield put(setStepUncompleted(STEPS.billing))
  yield put(setStepEnabled(STEPS.owner))
  yield put(setStepEnabled(STEPS.rate))
  yield put(setStepEnabled(STEPS.topping))
  yield put(setStepDisabled(STEPS.supply))

  yield put(clearFidelization())
  yield put(clearNewAddressProductChange())
  yield put(clearNewAddressServiceChange())
  yield put(clearCupsSearcherByCups())
  yield put(clearProductSelection())
  yield put(clearServiceSelection())

  yield put(clearDraftFull())

  yield put(clearBuyapowaCode())
  yield put(clearReferralCode())
  yield put(clearProductReferralCode())

  yield put(setIsResetFunnelContract(true))
}

function* resetSupplyStepSaga() {
  const isResetFunnelContract = yield select(getIsResetFunnelContract)
  const isTenureSelected = yield select(getIsTenureSelected)
  const isMultiPoint = yield select(getIsMultiPoint)

  if (isResetFunnelContract) yield put(clearCustomer())
  if (!isTenureSelected) yield put(clearNormalizedAddress())
  if (isMultiPoint) yield put(clearSupplyPoints())
  yield put(clearSupplyAddress())
  yield put(clearSupplyPoints())
  yield put(clearIsMultiPoint())
  yield put(clearProductVariants())
  yield put(setStepUncompleted(STEPS.supply))
}

function* resetOrderSaga() {
  yield put(clearOrderId())
  yield put(clearOrderTechnicalInfosIds())
  yield put(resetCRMId())
  yield put(resetLocutionData())
  yield put(clearSign())
  yield put(clearForwardSms())
  yield put(clearGdprSent())
  yield put(clearScoringSent())
  yield put(clearGuidelineDate())
}

function* fetchChannelsSaga() {
  try {
    const queries = yield select(getRouterQueries)
    const data = queries?.agv ? yield call(getChannelsAgv, queries.agv) : yield call(getChannels)
    yield put(setChannels(data))
  } catch (e) {
    console.error(e)
  }
}

function* fetchUserSaga() {
  let data = yield call(getUser)
  try {
    const userData = { ...data[0], channelOffers: data[0].channelOffers }
    yield put(setUserData(userData))
  } catch (e) {
    console.error(e)
    yield put(setUserData(data[0]))
  }
}

function* fetchUserSagaOnline(payload) {
  let data = yield call(getUserOnline, payload.payload)
  if (data && data?.detail && data.detail === 'No user found for Web Channel') {
    yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.userOnlineNotFound)
  } else {
    try {
      const userData = { ...data[0], channelOffers: data[0].channelOffers }
      yield put(setUserData(userData))
    } catch (e) {
      console.error(e)
      yield put(setUserData(data[0]))
    }
  }
}

function* logOutSaga() {
  yield removeSessionStorageData()
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.RESET_CONTRACT], resetContractSaga),
    takeLatest([actionTypes.RESET_FUNNEL_CONTRACT], resetFunnelContractSaga),
    takeLatest([actionTypes.RESET_SUPPLY_STEP], resetSupplyStepSaga),
    takeLatest([actionTypes.RESET_ORDER], resetOrderSaga),
    takeLatest([actionTypes.FETCH_CHANNELS], fetchChannelsSaga),
    takeLatest([actionTypes.FETCH_USER], fetchUserSaga),
    takeLatest([actionTypes.FETCH_USER_API], fetchUserSagaOnline),
    takeLatest([actionTypes.LOGOUT], logOutSaga),
    takeLatest([actionTypes.RESET_SUPPLY_STEP], resetSupplyStepSaga),
  ])
}
