import { useTranslation } from 'react-i18next'

import { Box, Button, SvgIcon } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { ReactComponent as IconInvoice } from '@zatopek/core/src/assets/icons/invoice.svg'
import Typography from '@zatopek/core/src/ui/components/typography'
import useStyles from './styles'

const UploadInvoiceAction = (props) => {
  const { openInfoModal, openUploadImageModal, disabled, completed, sendGAinfoInvoiceEvent, sendGAEventUploadInvoiceImage } =
    props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box mb={completed ? 2 : 0}>
      <Alert
        icon={<SvgIcon component={IconInvoice} viewBox="0 5 20 20" fontSize="inherit" className={classes.invoiceIcon} />}
        alignItems="center"
        severity="info"
      >
        <Box className={classes.alert}>
          <Typography variant="basic">
            {t('scanInvoiceDescription')}
            <InfoOutlinedIcon
              className={classes.infoIcon}
              onClick={() => {
                openInfoModal()
                sendGAinfoInvoiceEvent()
              }}
              fontSize="inherit"
            />
          </Typography>
          <Button
            disabled={disabled}
            onClick={() => {
              openUploadImageModal()
              sendGAEventUploadInvoiceImage()
            }}
            variant="outlined"
            color="primary"
            className={classes.uploadImageBtn}
          >
            {t('common.uploadImage')}
          </Button>
        </Box>
      </Alert>
    </Box>
  )
}

export default UploadInvoiceAction
