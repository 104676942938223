import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

const ProductIncompatible = ({ data, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose } = data

  const onClick = () => {
    handleClose()
    onClose()
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('productIncompatible.selectedProductIsIncompatibleTitle')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('productIncompatible.selectedProductIsIncompatibleDescription')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={onClick}>
          {t('common.continue')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ProductIncompatible
