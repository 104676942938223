import { all, put, takeLatest, call } from 'redux-saga/effects'
import * as api from './api'
import * as portabilityActions from './action-types'
import {
  setPortability,
  fetchPortabilityStart,
  fetchPortabilitySuccess,
  fetchPortabilityError,
  exportFileStart,
  exportFileSuccess,
  exportFileError,
} from './actions'
import { rawTableDataIntoExcel, saveFile } from 'services/backoffice/excel-api'
function* fetchPortabilitySaga({ payload }) {
  try {
    yield put(fetchPortabilityStart())
    const portability = yield call(api.getPortability, payload)
    yield put(setPortability(portability))
    yield put(fetchPortabilitySuccess())
  } catch (error) {
    yield put(fetchPortabilityError(error))
  }
}

function* exportPortabilitySaga({ payload }) {
  try {
    yield put(exportFileStart())
    let orders = []
    let file
    let filter = { ...payload }
    filter.rowsPerPage = 2000
    filter.page = 0
    const portability = yield call(api.getPortability, filter)
    orders = orders.concat(portability.items)
    file = rawTableDataIntoExcel(orders)
    saveFile(file, 'portability')
    yield put(exportFileSuccess())
  } catch (error) {
    yield put(exportFileError(error))
  }
}

export default function* portabilitySaga() {
  yield all([
    takeLatest(portabilityActions.FETCH_PORTABILITY, fetchPortabilitySaga),
    takeLatest(portabilityActions.EXPORT_CSV, exportPortabilitySaga),
  ])
}
