import { get, post, put } from '../client'

export const getTemplateFieldsByTemplateType = (params = {}) => {
  const queryParams =
    Object.entries(params)
      ?.map((prop) => prop.join('='))
      .join('&') || ''
  return get(`template_field_list?${queryParams}`)
}

export const getAllTemplates = async (params = {}) => {
  const queryParams =
    Object.entries(params)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const response = await get(`templates?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return {
    data: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  }
}

export const getTemplateById = (id) => get(`templates/${id}`)

export const putTemplate = (id, params) => put(`templates/${id}`, params, true)

export const postTemplate = (params) => post(`templates`, params, true)
