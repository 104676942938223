import React from 'react'
import { useTranslation } from 'react-i18next'
import { ELECTRICITY, GAS } from 'utils/constants'
import clsx from 'clsx'
import { Grid, Box, Paper, Radio } from '@material-ui/core'
import { AddCircle, CloudDownload } from '@material-ui/icons'
import { getSelectedCompany } from 'services/customer/selectors'
import Typography from 'ui/components/typography'
import Link from 'ui/components/link'
import Prices from 'ui/components/prices'
import useStyles from './styles'
import { NEWCO, NATURGY_CLIENTS } from '@zatopek/core/src/utils/constants'
import { useSelector } from 'react-redux'
import formatNumber from '@zatopek/core/src/utils/formatNumber'
const Rate = ({
  product: {
    id,
    name,
    energyType,
    subProducts,
    flatRate,
    image,
    calculatedCost,
    title,
    subtitle,
    hasSimulator,
    reducedCost,
    monthlyCostNoTaxes,
    newCalculation,
  },
  selected,
  isRecommended,
  onChangeProduct,
  openModal,
  onSimulatorClick,
  loadingSimulatorLink,
  onFlatRateCalculate,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isSelected = selected.map((product) => product.id).includes(id)
  const isSingleProduct = !Boolean(subProducts && subProducts.length)
  const productLines = isSingleProduct
    ? [{ id, name, energyType, flatRate, calculatedCost, reducedCost, monthlyCostNoTaxes, newCalculation }]
    : subProducts
  const company = useSelector(getSelectedCompany)
  const companyCode = company?.code
  const companyName = company?.name

  return (
    <Paper elevation={3} className={clsx(classes.root, isSelected && 'selected')} onClick={() => onChangeProduct(id)}>
      {isRecommended && (
        <Grid container>
          <Box className={classes.recommendBox}>
            <Typography variant="h5">
              {t('product')} {t('recommended').toLowerCase()}
            </Typography>
          </Box>
        </Grid>
      )}
      <Box className={classes.content}>
        <Box mt={isRecommended ? 5 : 0} className={clsx(classes.radioContainer)}>
          <Radio color="primary" checked={isSelected} value={name} name="radio-rate" />
        </Box>
        {image && (
          <Box className={classes.logos}>
            <img className={classes.logo} src={image} alt={name} />
          </Box>
        )}
        <Typography variant="h4" color="primary" gutterBottom>
          {t(title)}
        </Typography>
        <Typography variant="h6" color="secondary" classes={{ root: classes.subtitle }}>
          {t(subtitle)}
        </Typography>
        {productLines.map((product, index) => (
          <Box key={product.id} className={classes.data}>
            <Box className={classes.details}>
              {!isSingleProduct && (
                <Typography variant="h5" classes={{ root: classes.detailsService }}>
                  {product.energyType === ELECTRICITY ? t('services.electricity') : t('services.gas')}
                </Typography>
              )}
              <Box display="block">
                <Typography
                  onClick={(e) => {
                    e.stopPropagation()
                    openModal(name)
                  }}
                  classes={{ root: classes.iconLink }}
                  variant="link"
                  color="primary"
                >
                  <AddCircle color="primary" />
                  <Link>{t('viewDetails')}</Link>
                </Typography>
                {hasSimulator && (
                  <Typography
                    onClick={(e) => {
                      if (!loadingSimulatorLink) {
                        e.stopPropagation()
                        onSimulatorClick(id)
                      }
                    }}
                    disabled={loadingSimulatorLink}
                    classes={{ root: classes.iconLink }}
                    variant="link"
                    color="primary"
                  >
                    <Link disabled={loadingSimulatorLink}>
                      <CloudDownload color="inherit" /> {t('funnelProductSimulatorLink')}
                    </Link>
                  </Typography>
                )}
              </Box>
            </Box>
            {product.calculatedCost && product.calculatedCost[product.energyType] && (
              <Box display="flex" flexWrap="wrap" classes={{ root: classes.priceBox }} justifyContent="flex-end">
                {companyCode === NEWCO || companyName === NATURGY_CLIENTS ? (
                  <>
                    {product?.newCalculation && product.monthlyCostNoTaxes && product.monthlyCostNoTaxes[product.energyType] && (
                      <Box className={classes.price}>
                        <Typography variant="h2" color="secondary" classes={{ root: classes.priceTitle }}>
                          {formatNumber(
                            product.monthlyCostNoTaxes[product.energyType] % 1 === 0
                              ? product.monthlyCostNoTaxes[product.energyType]?.toLocaleString('es-ES')
                              : product.monthlyCostNoTaxes[product.energyType]?.toLocaleString('es-ES', {
                                  minimumFractionDigits: 2,
                                })
                          )}
                          €
                        </Typography>
                        <Box ml={1}>
                          <Typography variant="body3" color="secondary">
                            {`/${t('common.month')}`}
                          </Typography>
                          <Typography variant="body3" color="secondary">
                            {t(`common.tax.noTaxes`)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {!product?.newCalculation && product.reducedCost && product.reducedCost[product.energyType] && (
                      <>
                        <Box className={classes.price}>
                          <Typography variant="h2" color="secondary" classes={{ root: classes.priceTitle }}>
                            {formatNumber(
                              product.reducedCost[product.energyType] % 1 === 0
                                ? product.reducedCost[product.energyType]?.toLocaleString('es-ES')
                                : product.reducedCost[product.energyType]?.toLocaleString('es-ES', { minimumFractionDigits: 2 })
                            )}
                            €
                          </Typography>
                          <Box ml={1}>
                            <Typography variant="body3" color="secondary">
                              {`/${t('common.month')}`}
                            </Typography>
                            <Typography variant="body3" color="secondary">
                              {t(`common.tax.reduced${product.energyType === GAS ? 'Gas' : 'Electricity'}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box className={classes.price}>
                      <Typography variant={product.energyType && 'h4'} color={'primary'} classes={{ root: classes.priceTitle }}>
                        {formatNumber(
                          product.calculatedCost[product.energyType] % 1 === 0
                            ? product.calculatedCost[product.energyType]?.toLocaleString('es-ES')
                            : product.calculatedCost[product.energyType]?.toLocaleString('es-ES', { minimumFractionDigits: 2 })
                        )}
                        €
                      </Typography>
                      <Box ml={1}>
                        <Typography variant="body3" color={'primary'}>
                          {`/${t('common.month')}`}
                        </Typography>
                        <Typography variant="body3" color={'primary'}>
                          {t(`common.tax.normal${product.energyType === GAS ? 'Gas' : 'Electricity'}`)}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {product.reducedCost && product.reducedCost[product.energyType] && (
                      <>
                        <Box className={classes.price}>
                          <Typography variant="h2" color="secondary" classes={{ root: classes.priceTitle }}>
                            {formatNumber(
                              product.reducedCost[product.energyType] % 1 === 0
                                ? product.reducedCost[product.energyType]?.toLocaleString('es-ES')
                                : product.reducedCost[product.energyType]?.toLocaleString('es-ES', { minimumFractionDigits: 2 })
                            )}
                            €
                          </Typography>
                          <Box ml={1}>
                            <Typography variant="body3" color="secondary">
                              {`/${t('common.month')}`}
                            </Typography>
                            <Typography variant="body3" color="secondary">
                              {t(`common.tax.reduced${product.energyType === GAS ? 'Gas' : 'Electricity'}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box className={classes.price}>
                      <Typography variant="h4" color="primary" classes={{ root: classes.priceTitle }}>
                        {formatNumber(
                          product.calculatedCost[product.energyType] % 1 === 0
                            ? product.calculatedCost[product.energyType]?.toLocaleString('es-ES')
                            : product.calculatedCost[product.energyType]?.toLocaleString('es-ES', { minimumFractionDigits: 2 })
                        )}
                        €
                      </Typography>
                      <Box ml={1}>
                        <Typography variant="body3" color="primary">
                          {`/${t('common.month')}`}
                        </Typography>
                        <Typography variant="body3" color="primary">
                          {t(`common.tax.normal${product.energyType === GAS ? 'Gas' : 'Electricity'}`)}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            )}
            {product.flatRate && !product.calculatedCost
              ? index + 1 === productLines.length && (
                  <Link
                    component="button"
                    classNames={[classes.calculateRate]}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onFlatRateCalculate(product)
                    }}
                  >
                    <Typography variant="link" color="primary" component="span">
                      {t('calculateMonthRate')}
                    </Typography>
                  </Link>
                )
              : null}

            {!product.flatRate ? (
              <Box className={classes.prices}>
                <Prices product={product.name} isSingleProduct={true} type={product.energyType.toLowerCase()} />
              </Box>
            ) : null}
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

export default Rate
