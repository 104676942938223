import React from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@material-ui/core/Container'

import Typography from 'ui/components/typography'

import useStyles from './styles'

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <footer className={classes.footerContainer}>
      <Container maxWidth="lg">
        <Typography variant="label" color="primary">
          © {t('common.brand')}
        </Typography>
        <Typography variant="helper" color="primary">
          {t('common.corpAddress')}
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
