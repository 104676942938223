import React, { useState, useEffect, useRef } from 'react'
import { Collapse, CircularProgress } from '@material-ui/core'
import uuid from 'react-uuid'

import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import mapEmergyaAddress from '@zatopek/core/src/utils/mapEmergyaAddress'
import widgetTranslations from '@zatopek/core/src/utils/widgetTranslations'
import findSupplyPoint from '@zatopek/core/src/utils/findSupplyPoint'
import usePrevious from '@zatopek/core/src/hooks/usePrevious'
import useCalculatorTabs from '@zatopek/core/src/hooks/useCalculatorTabs'
import { useTranslation } from 'react-i18next'
import { put } from '../utils/client'

import {
  ELECTRICITY,
  GAS,
  BOTH,
  BOTH_ENUM,
  PYME,
  PROVIDER_CODES,
  CUPS,
  ADDRESS,
  GA_SERVICES,
  GNS,
  NEWCO,
  STREET_TYPE_DEFAULT,
} from '../utils/constants'

import * as NATLOC from 'natloc-component'
import Loader from '../ui/components/loader'
import SupplyCard from '../ui/components/supplyCard'
import NewSupplySwitch from '../ui/components/newSupplySwitch'
import { Alert } from '@material-ui/lab'
import Typography from 'ui/components/typography'
import { getAddressByCups, getCalculatorInfo, getTechnicalInfoFromCups } from '../services/supplyPoint/api'
import UploadInvoiceAction from '@zatopek/core/src/ui/components/uploadInvoiceAction'

const SupplyPoint = React.memo(
  function ({
    index,
    isEnabledCupsByInvoice,
    openInfoModal,
    openUploadImageModal,
    service,
    dataFromInvoice,
    onSubmit,
    onError = () => {},
    getQuota = true,
    onSendAnalytics = () => {},
    onCancel,
    isDisabled = false,
    isLeadError,
    isOnlineChannel,
    isOpen,
    shouldGetCalculations,
    handleNormalizedAddress,
    normalizedAddress,
    isTenureSelected,
    handleSupplyAddress,
    openCupsModal,
    tenure,
    onTenureComplete,
    isResetFunnelContract,
    resetSupplyPoint,
    isPopUp = false,
    onAutoCompletion,
    energyType: preselectedEnergyType,
    draftTechnicalInfo,
    isDraft,
    onDraftComplete,
    isNewSupply,
    showNewSupplyModal,
    showNewSupplyAddressModalForm,
    showNewSupplyTechnicalModalForm,
    isMultiPoint,
    onRemoveSupplyPoint,
    cups,
    supplyPoint,
    referenceSegment,
    showPymesModal,
    setIsRepeatedCups,
    selectedProductVariants,
    selectedDualSubproducts,
    preSelectedRate,
    isNewSupplyChecked,
    isNewSupplySwitchDisabled,
    onNewSupplyChange,
    supplyAddress,
    isOnlineChannelDraft,
    onlineChannelDraftData,
    setDualSupplyPoint,
    completeSupplyStep,
    isFlatRate,
    isNewCo,
    isDuplicateOrderDraft,
    duplicateOrderDraft,
    setIsInvalidTariff,
    isSupplyStepCompleted,
    selectedCompany,
    step,
    showErrorModal,
    setIsErrorCups,
    userDocumentNumber,
    documentType,
    checkCups,
    wrongCupsValidation,
    isPortability,
    draftOrder,
    isPortabilityNewcoCheck,
    setPortabilityNewco,
    selectedProduct,
    channel,
    getTariffData,
    showNoCPModal,
    showNoSameAddressModal,
    showChangeOwnerModal,
    activeStepNumber,
    showNowPriceData,
    orders,
    sendEditGAEvent,
    setShowCheck,
    sendGAEventUploadInvoiceImage,
    sendGAinfoInvoiceEvent,
  }) {
    const { t } = useTranslation()
    const [electricityData, setElectricityData] = useState({})
    const [gasData, setGasData] = useState({})
    const [activeStep, setActiveStep] = useState(ELECTRICITY)
    const [hasGasCups, setHasGasCups] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [tenureData, setTenureData] = useState(false)
    const [draftData, setDraftData] = useState(false)
    const [auxNormalizedAddress, setAuxNormalizedAddress] = useState(false)
    const [isDuplicateOrderFetched, setIsDuplicateOrderFetched] = useState(false)
    const [addresAlert, setAddresAlert] = useState(false)
    const [ownerElectricityAlert, setOwnerElectricityAlert] = useState(false)
    const [ownerGasAlert, setOwnerGasAlert] = useState(false)
    const [tenureAlert, setTenureAlert] = useState(false)
    const cupsValue = useRef('')
    const orderId = orders && orders.length && orders[0].id
    const prevService = usePrevious(service)
    const companyCode = selectedCompany && selectedCompany.code
    const numeroRegex = /^-?\d*([.,]?\d+)?$/
    const {
      stateGas,
      stateElectricity,
      initialStateGas,
      initialStateElectricity,
      dispatchGas,
      dispatchElectricity,
      reducerTypes: { COMPLETE, HIDE, HANDLE, RESET, TOGGLE_EDIT },
    } = useCalculatorTabs()

    const isElectricitySelected = service === ELECTRICITY
    const isGasSelected = service === GAS
    const isBothSelected = service === BOTH || service === BOTH_ENUM
    const portabilityNewco = isNewCo && isPortability

    useEffect(() => {
      if (activeStepNumber === 1 && setShowCheck) {
        if (ownerElectricityAlert || ownerGasAlert || addresAlert) {
          setShowCheck(true)
        } else {
          setShowCheck(false)
        }
      }
      //eslint-disable-next-line
    }, [ownerElectricityAlert, ownerGasAlert, tenureAlert, addresAlert])

    useEffect(() => {
      if (normalizedAddress) {
        setAuxNormalizedAddress({ ...normalizedAddress })
      }
    }, [normalizedAddress])

    const setNormalizedAddress = (selectedAddress) => {
      if (handleNormalizedAddress) {
        if (
          (selectedAddress && !selectedAddress.technicalInfoId) ||
          (auxNormalizedAddress && !auxNormalizedAddress.technicalInfoId)
        ) {
          if (selectedAddress) setAuxNormalizedAddress({ ...selectedAddress })
          handleNormalizedAddress(selectedAddress || auxNormalizedAddress)
        } else {
          handleNormalizedAddress(false)
        }
      }
    }

    const normalizaAddress = (address) => {
      return address.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    const setSupplyAddress = (supplyAddress) => {
      if (handleSupplyAddress && supplyAddress) {
        handleSupplyAddress(supplyAddress)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      // If it is draft we have to autocomplete step 3
      if (isDraft && draftTechnicalInfo) {
        const data = draftTechnicalInfo
        if (data) {
          setDraftData(data)
          if (draftOrder) {
            onDraftComplete(data, draftOrder.supplyAddress)
            return
          }
          onDraftComplete(data)
        } else {
          emptyElectricity()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDraft, draftOrder])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      // If it is draft we have to autocomplete step 3
      if (isOnlineChannelDraft && onlineChannelDraftData && onlineChannelDraftData.supplyStep && !isFlatRate && !isMultiPoint) {
        const isDualTariff = !!onlineChannelDraftData.supplyStep.electricity && !!onlineChannelDraftData.supplyStep.gas
        if (isDualTariff)
          setDualSupplyPoint([
            onlineChannelDraftData.supplyStep.electricity.supplies[0].technicalInfo,
            onlineChannelDraftData.supplyStep.gas.supplies[0].technicalInfo,
          ])
        const CUPS = onlineChannelDraftData.supplyStep.electricity
          ? onlineChannelDraftData.supplyStep.electricity.supplies[0].technicalInfo.cups
          : onlineChannelDraftData.supplyStep.gas.supplies[0].technicalInfo.cups
        let data
        if (CUPS && !draftData) {
          data = await requestByCups(
            CUPS,
            onlineChannelDraftData.supplyStep.electricity ? ELECTRICITY : onlineChannelDraftData.supplyStep.gas ? GAS : ''
          )

          if (!data) {
            if (!isDualTariff) completeSupplyPointDraft()
            return
          }
        }

        if (data && !draftData) {
          setDraftData(data)
        }

        if (draftData && !isDualTariff) {
          completeElectricity(draftData)
          onDraftComplete(
            draftData,
            onlineChannelDraftData.supplyStep.electricity
              ? onlineChannelDraftData.supplyStep.electricity.supplies[0].address
              : onlineChannelDraftData.supplyStep.gas.supplies[0].address,
            true
          )
        } else {
          emptyElectricity()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnlineChannelDraft, onlineChannelDraftData, draftData])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      // If it is draft we have to autocomplete step
      if (isDuplicateOrderDraft && duplicateOrderDraft && duplicateOrderDraft.supplyStep && !isFlatRate) {
        const isDualTariff = !!duplicateOrderDraft.supplyStep.electricity && !!duplicateOrderDraft.supplyStep.gas
        if (isDualTariff) {
          const electricityTechnicalData = {
            ...duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo,
            newSupply: duplicateOrderDraft.supplyStep.electricity.nnss,
          }
          const electricityAddress = duplicateOrderDraft.supplyStep.electricity.supplies[0].address
          setElectricityData({
            ...electricityTechnicalData,
            ...electricityAddress,
          })
          setHasGasCups(false)
          setNormalizedAddress(false)
          setActiveStep(GAS)

          return
        }

        const CUPS = duplicateOrderDraft.supplyStep.electricity
          ? duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo.cups
          : duplicateOrderDraft.supplyStep.gas.supplies[0].technicalInfo.cups
        if (CUPS) {
          if (!isDualTariff) {
            completeSupplyPointDuplicateOrder()
            return
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDuplicateOrderDraft, duplicateOrderDraft])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      // If it is draft we have to autocomplete step
      if (isDuplicateOrderDraft && duplicateOrderDraft && duplicateOrderDraft.supplyStep && isFlatRate) {
        const isDualTariff = !!duplicateOrderDraft.supplyStep.electricity && !!duplicateOrderDraft.supplyStep.gas
        if (isDualTariff) {
          const cups = duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo.cups
          let calculatorInfo = {}

          if (
            selectedProduct.find(
              (item) => (item.energyType.toLowerCase() === ELECTRICITY || item.energyType.toLowerCase() === BOTH) && item.flatRate
            )
          )
            calculatorInfo = await getCalculatorInfo(cups, ELECTRICITY)

          const electricityTechnicalData = {
            ...duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo,
            ...calculatorInfo,
            newSupply: duplicateOrderDraft.supplyStep.electricity.nnss,
          }
          const electricityAddress = duplicateOrderDraft.supplyStep.electricity.supplies[0].address

          setElectricityData({
            ...electricityTechnicalData,
            ...electricityAddress,
          })
          setHasGasCups(false)
          setNormalizedAddress(false)
          setActiveStep(GAS)

          return
        }

        const CUPS = duplicateOrderDraft.supplyStep.electricity
          ? duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo.cups
          : duplicateOrderDraft.supplyStep.gas.supplies[0].technicalInfo.cups
        if (CUPS) {
          if (!isDualTariff) {
            completeSupplyPointDuplicateOrder()
            return
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDuplicateOrderDraft, duplicateOrderDraft, isFlatRate])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      if (
        electricityData &&
        !isEmptyObject(electricityData) &&
        activeStep === GAS &&
        isDuplicateOrderDraft &&
        !isDuplicateOrderFetched
      ) {
        const cups = duplicateOrderDraft.supplyStep.gas.supplies[0].technicalInfo.cups
        let calculatorInfo = {}

        if (
          selectedProduct.find(
            (item) => (item.energyType.toLowerCase() === ELECTRICITY || item.energyType.toLowerCase() === BOTH) && item.flatRate
          )
        )
          calculatorInfo = await getCalculatorInfo(cups, GAS)

        const gasTechnicalData = {
          ...duplicateOrderDraft.supplyStep.gas.supplies[0].technicalInfo,
          ...calculatorInfo,
          newSupply: duplicateOrderDraft.supplyStep.gas.nnss,
        }
        const gasAddress = duplicateOrderDraft.supplyStep.gas.supplies[0].address
        setGasData({ ...gasTechnicalData, ...calculatorInfo, ...gasAddress })
        setActiveStep(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, isDuplicateOrderDraft])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      if (tenure && !isMultiPoint) {
        const CUPS = tenure.electricityCups || tenure.gasCups
        let data
        if (CUPS && !tenureData) {
          data = await requestByCups(CUPS, tenure.electricityCups ? ELECTRICITY : tenure.gasCups ? GAS : '')
        }

        if (data && !tenureData) {
          setTenureData(data)
        }

        if (tenureData) {
          completeElectricity(tenureData)

          //check if onTenureComplete exists
          if (onTenureComplete) {
            onTenureComplete(tenureData, tenure, true)
          } else {
            handleSubmit(tenureData, tenure, true)
          }
        } else {
          emptyElectricity()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenure, tenureData])

    useEffect(() => {
      if (electricityData && !isEmptyObject(electricityData) && gasData && !isEmptyObject(gasData)) {
        ;(normalizedAddress || auxNormalizedAddress) &&
          handleSubmit([electricityData, gasData], auxNormalizedAddress || normalizedAddress)
        handleSteps()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electricityData, gasData])

    const handleElectricityCompletion = async () => {
      const { electricityCups } = { ...normalizedAddress }
      const selectedPoint = findSupplyPoint(supplyPoint, electricityCups, ELECTRICITY)
      const electricityFlatRate = selectedProduct.find(
        (item) => (item.energyType.toLowerCase() === ELECTRICITY || item.energyType.toLowerCase() === BOTH) && item.flatRate
      )
      let technicalData
      if (electricityCups) {
        setIsLoading(true)
        if (!isPortability && !electricityFlatRate && !selectedPoint) {
          const { isOk, code } = await checkCups([normalizedAddress.electricityCups], userDocumentNumber, selectedCompany)
          if (!isOk) {
            wrongCupsValidation(code)
            setIsLoading(false)
            return
          }
        }
        const { supplyAddress, ...rest } = { ...selectedPoint }
        technicalData = rest
        if (!technicalData || isEmptyObject(technicalData)) {
          technicalData = await requestByCups(electricityCups, ELECTRICITY)
        } else if (shouldGetCalculations[technicalData.energyType.toUpperCase()] && technicalData && !technicalData.monthlyCost) {
          const calculatorInfo = await getCalculatorData(electricityCups, technicalData.energyType)
          technicalData = { ...technicalData, ...calculatorInfo }
        }
        setIsLoading(false)
        if (!technicalData) return
        completeElectricity(technicalData)
        if (isPopUp) onAutoCompletion(technicalData)
      } else {
        setIsLoading(false)
        if (isEmptyObject(electricityData)) return
        completeElectricity(electricityData)
      }
      if (portabilityNewco && !isPortabilityNewcoCheck) {
        setPortabilityNewco(true)
      }
      setIsLoading(false)
    }

    const handleGasCompletion = async () => {
      if (!portabilityNewco || isPortabilityNewcoCheck) {
        const { gasCups } = { ...normalizedAddress }
        const selectedPoint = findSupplyPoint(supplyPoint, gasCups, GAS)
        const gasFlatRate = selectedProduct.find(
          (item) => (item.energyType.toLowerCase() === GAS || item.energyType.toLowerCase() === BOTH) && item.flatRate
        )
        let technicalData
        if (gasCups) {
          setIsLoading(true)
          if (!isPortability && !gasFlatRate && !selectedPoint) {
            const { isOk, code } = await checkCups([normalizedAddress.gasCups], userDocumentNumber, selectedCompany)
            if (!isOk) {
              wrongCupsValidation(code)
              setIsLoading(false)
              return
            }
          }
          const { supplyAddress, ...rest } = { ...selectedPoint }
          technicalData = rest
          if (!technicalData || isEmptyObject(technicalData)) {
            technicalData = await requestByCups(gasCups, GAS)
          } else if (shouldGetCalculations['GAS'] && technicalData && !technicalData.monthlyCost) {
            const calculatorInfo = await getCalculatorData(gasCups, technicalData.energyType)
            technicalData = { ...technicalData, ...calculatorInfo }
          }
          if (!technicalData) return
          completeGas(technicalData)
          if (isPopUp) onAutoCompletion(technicalData)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          if (isEmptyObject(gasData)) return
          completeGas(gasData)
        }
        if (portabilityNewco && !isPortabilityNewcoCheck) {
          setPortabilityNewco(true)
        }
        setIsLoading(false)
      }
    }

    const handleBothCompletion = async () => {
      const bothFlatRate = selectedProduct.find((item) => item.energyType.toLowerCase() === BOTH && item.flatRate)
      const electricityFlatRate = selectedProduct.find((item) => item.energyType.toLowerCase() === ELECTRICITY && item.flatRate)
      const gasFlatRate = selectedProduct.find((item) => item.energyType.toLowerCase() === GAS && item.flatRate)
      if (normalizedAddress && !isEmptyObject(normalizedAddress) && (!portabilityNewco || isPortabilityNewcoCheck)) {
        const { electricityCups, gasCups } = { ...normalizedAddress }

        if (electricityCups) {
          setIsLoading(true)
          const selectedPoint = findSupplyPoint(supplyPoint, electricityCups, ELECTRICITY)
          if (!isPortability && (bothFlatRate || electricityFlatRate) && !selectedPoint) {
            const { isOk, code } = await checkCups([electricityCups, gasCups], userDocumentNumber, selectedCompany)
            if (!isOk) {
              wrongCupsValidation(code)
              setIsLoading(false)
              return
            }
          }
          if (selectedPoint) {
            const { supplyAddress, ...rest } = { ...selectedPoint }
            await dualSubmit(rest, normalizedAddress, true)
            setIsLoading(false)
            if (portabilityNewco && !isPortabilityNewcoCheck) {
              setPortabilityNewco(true)
            }
            return
          } else {
            const cupsData = await requestByCups(electricityCups, ELECTRICITY)
            if (cupsData) {
              await dualSubmit(cupsData, normalizedAddress, true)
            } else {
              emptyElectricity()
            }
            setIsLoading(false)
          }
        } else if (gasCups) {
          setIsLoading(true)
          const selectedPoint = findSupplyPoint(supplyPoint, gasCups, GAS)
          if (!isPortability && (bothFlatRate || gasFlatRate) && !selectedPoint) {
            const { isOk, code } = await checkCups([gasCups], userDocumentNumber, selectedCompany)
            if (!isOk) {
              wrongCupsValidation(code)
              setIsLoading(false)
              return
            }
          }
          if (selectedPoint) {
            const { supplyAddress, ...rest } = { ...selectedPoint }
            await dualSubmitGas(rest, normalizedAddress)
            setIsLoading(false)
            return
          } else {
            const cupsData = await requestByCups(gasCups, GAS)
            if (cupsData) {
              await dualSubmitGas(cupsData, normalizedAddress)
            } else {
              emptyElectricity()
            }
          }
        }
      } else {
        if (electricityData && !isEmptyObject(electricityData) && (!portabilityNewco || isPortabilityNewcoCheck)) {
          completeElectricity(electricityData)
          setHasGasCups(false)
          setNormalizedAddress(false)
          setActiveStep(GAS)
          setIsLoading(false)
          return
        }
        setActiveStep(ELECTRICITY)
      }

      setIsLoading(false)
    }

    useEffect(() => {
      if (isElectricitySelected && prevService && prevService !== ELECTRICITY) {
        setActiveStep(ELECTRICITY)
        emptyGas()
        handleSteps()
        //handleElectricityCompletion()
        if (isMultiPoint) resetSupplyPoint(resetCalculator)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isElectricitySelected, prevService])

    useEffect(() => {
      if (isGasSelected && prevService && prevService !== GAS) {
        setActiveStep(GAS)
        emptyElectricity()
        handleSteps()
        //handleGasCompletion()
        if (isMultiPoint) resetSupplyPoint(resetCalculator)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGasSelected, prevService])

    useEffect(() => {
      if (isBothSelected && prevService && prevService !== BOTH && prevService !== BOTH_ENUM && !isDuplicateOrderFetched) {
        emptyElectricity()
        emptyGas()
        handleSteps()
        if (!isOpen) {
          handleBothCompletion()
        }
        if (isMultiPoint) resetSupplyPoint(resetCalculator)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBothSelected, prevService])

    // POP UP ON PRESELECTED
    useEffect(() => {
      if (!isPopUp) return
      if (!preselectedEnergyType) return
      handleSteps()
      if (preselectedEnergyType === BOTH || preselectedEnergyType === BOTH_ENUM.toUpperCase()) handleBothCompletion()
      if (preselectedEnergyType === ELECTRICITY.toUpperCase() && ((prevService && prevService === ELECTRICITY) || isFlatRate))
        handleElectricityCompletion()
      if (preselectedEnergyType === GAS.toUpperCase() && ((prevService && prevService === GAS) || isFlatRate))
        handleGasCompletion()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPopUp, preselectedEnergyType])

    // isOpen
    useEffect(() => {
      if (!isOpen) return
      if (isMultiPoint) return
      emptyElectricity()
      emptyGas()
      handleSteps()
      if (isBothSelected && (!portabilityNewco || isPortabilityNewcoCheck)) handleBothCompletion()
      if (isElectricitySelected && prevService && prevService === ELECTRICITY && (!portabilityNewco || isPortabilityNewcoCheck))
        handleElectricityCompletion()
      if (isGasSelected && prevService && prevService === GAS && (!portabilityNewco || isPortabilityNewcoCheck))
        handleGasCompletion()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
      if (
        isSupplyStepCompleted &&
        isDuplicateOrderDraft &&
        !isEmptyObject(electricityData) &&
        !isEmptyObject(gasData) &&
        supplyPoint &&
        supplyPoint.length === 2 &&
        !isDuplicateOrderFetched
      ) {
        setIsDuplicateOrderFetched(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDuplicateOrderDraft, isSupplyStepCompleted, electricityData, gasData, isDuplicateOrderFetched])

    const completeSupplyPointDraft = () => {
      const technicalInfoData =
        isOnlineChannelDraft &&
        onlineChannelDraftData &&
        onlineChannelDraftData.supplyStep &&
        onlineChannelDraftData.supplyStep.electricity
          ? onlineChannelDraftData.supplyStep.electricity.supplies[0].technicalInfo
          : onlineChannelDraftData.supplyStep.gas.supplies[0].technicalInfo
      const supplyAddress =
        isOnlineChannelDraft && onlineChannelDraftData.supplyStep && onlineChannelDraftData.supplyStep.electricity
          ? onlineChannelDraftData.supplyStep.electricity.supplies[0].address
          : onlineChannelDraftData.supplyStep.gas.supplies[0].address

      handleSupplyAddress(supplyAddress)
      completeElectricity({ ...technicalInfoData, ...supplyAddress })
      onDraftComplete(technicalInfoData, supplyAddress, true)
    }

    const completeSupplyPointDuplicateOrder = async () => {
      const technicalInfoData =
        isDuplicateOrderDraft &&
        duplicateOrderDraft &&
        duplicateOrderDraft.supplyStep &&
        duplicateOrderDraft.supplyStep.electricity
          ? duplicateOrderDraft.supplyStep.electricity.supplies[0].technicalInfo
          : duplicateOrderDraft.supplyStep.gas.supplies[0].technicalInfo
      const supplyAddress =
        isDuplicateOrderDraft && duplicateOrderDraft.supplyStep && duplicateOrderDraft.supplyStep.electricity
          ? duplicateOrderDraft.supplyStep.electricity.supplies[0].address
          : duplicateOrderDraft.supplyStep.gas.supplies[0].address

      let calculatorInfo = {}
      const flatRate = selectedProduct.find(
        (item) => item.energyType.toLowerCase() === technicalInfoData.energyType.toLowerCase() && item.flatRate
      )

      if (flatRate) calculatorInfo = await getCalculatorInfo(technicalInfoData.cups, technicalInfoData.energyType)

      handleSupplyAddress(supplyAddress)
      completeElectricity({ ...technicalInfoData, ...calculatorInfo, ...supplyAddress })
      onDraftComplete(
        {
          ...technicalInfoData,
          ...calculatorInfo,
          newSupply: duplicateOrderDraft.supplyStep.electricity
            ? duplicateOrderDraft.supplyStep.electricity.nnss
            : duplicateOrderDraft.supplyStep.gas.nnss || false,
        },
        supplyAddress,
        true
      )
    }

    const getCalculatorData = async (cups, energyType) => {
      try {
        return getCalculatorInfo(cups, energyType, companyCode)
      } catch (error) {
        console.log('error', error)
        return {}
      }
    }

    const requestByCups = async (cups, forceService) => {
      // If we recieve a CUPS it comes from tenureCups
      setIsLoading(true)
      const CUPS = cups ? cups : normalizedAddress.gasCups || normalizedAddress.electricityCups
      let NORMALIZED_ADDRESS = normalizedAddress
      if (isTenureSelected) {
        setNormalizedAddress(tenure || false)
      } else if (!NORMALIZED_ADDRESS) {
        try {
          const emergyAddress = await getAddressByCups(CUPS, forceService || activeStep, PROVIDER_CODES[companyCode], step)
          NORMALIZED_ADDRESS = mapEmergyaAddress(emergyAddress.results[0])
          setNormalizedAddress(NORMALIZED_ADDRESS || false)
        } catch (e) {
          console.error(e)
          NORMALIZED_ADDRESS = {}
          setNormalizedAddress(false)
          if (setIsErrorCups) setIsErrorCups(true)
        }
      }
      try {
        let technicalInfo = await getTechnicalInfoFromCups(forceService || service, CUPS, documentType, userDocumentNumber)
        if (
          technicalInfo &&
          technicalInfo.consumption12Months &&
          //eslint-disable-next-line
          (!numeroRegex.test(technicalInfo.consumption12Months) ||
            isNaN(parseFloat(technicalInfo.consumption12Months)) ||
            parseFloat(technicalInfo.consumption12Months) <= 0)
        ) {
          const tariffData = await getTariffData(forceService || service, selectedProduct, channel)
          technicalInfo.consumption12Months = tariffData.find(
            (tariff) => tariff.type === technicalInfo.accessTariff
          ).defaultAnnualConsumption
        }
        if (technicalInfo) {
          const calculatorInfo = shouldGetCalculations[technicalInfo.energyType.toUpperCase()]
            ? await getCalculatorInfo(technicalInfo.cups, technicalInfo.energyType, companyCode)
            : {}
          setIsLoading(false)
          return { ...technicalInfo, ...calculatorInfo }
        }
        setIsLoading(false)
      } catch (e) {
        console.error(e)
        setIsLoading(false)
        if (setIsErrorCups) setIsErrorCups(true)
        return false
      }
    }

    const completeGas = (data) => {
      setGasData(data)
      setActiveStep(null)
      dispatchGas(COMPLETE)
      if (isBothSelected && !isEmptyObject(electricityData)) {
        handleSubmit([electricityData, data], normalizedAddress)
        setIsLoading(false)
      } else {
        handleSubmit(data, normalizedAddress)
        setIsLoading(false)
      }
    }

    const completeElectricity = (data) => {
      setElectricityData(data)
      setActiveStep(null)
      dispatchElectricity(COMPLETE)
      if (isBothSelected && !isEmptyObject(gasData)) {
        handleSubmit([gasData, data], normalizedAddress)
        setIsLoading(false)
      } else {
        handleSubmit(data, normalizedAddress)
        setIsLoading(false)
      }
    }

    const emptyGas = () => {
      setGasData({})
      dispatchGas(RESET(initialStateGas))
    }

    const emptyElectricity = () => {
      setElectricityData({})
      dispatchElectricity(RESET(initialStateElectricity))
    }

    const resetCalculator = () => {
      emptyGas()
      emptyElectricity()
      setActiveStep(ELECTRICITY)
    }

    const handleSteps = () => {
      if (isElectricitySelected) {
        dispatchElectricity(HANDLE)
        dispatchGas(HIDE)
      } else {
        dispatchGas(HANDLE)
        dispatchElectricity(HIDE)
      }
    }

    const handleSubmit = (data, selectedAddress) => {
      if (!data) return
      if (setIsInvalidTariff && !isDuplicateOrderDraft) {
        setIsInvalidTariff(false)
        if (selectedProductVariants && selectedProduct.length === 1) {
          const validTariff = selectedProductVariants.some((variant) => variant.tariff.type === data.accessTariff)
          if (!validTariff) {
            setIsInvalidTariff(true)
            return
          }
        } else if (selectedDualSubproducts && selectedProduct.length === 1) {
          const selectedSupplyPointElectricity =
            data && data.length && data.find((supply) => supply.energyType === ELECTRICITY.toUpperCase())
          const selectedSupplyPointGas = data && data.length && data.find((supply) => supply.energyType === GAS.toUpperCase())
          const selectedDualSubproductWithInvalidTariffForEnergy = selectedDualSubproducts.find(({ energyType, variants }) =>
            selectedSupplyPointGas && energyType === selectedSupplyPointGas.energyType
              ? selectedSupplyPointGas &&
                selectedSupplyPointGas.accessTariff &&
                variants.every((variant) => selectedSupplyPointGas.accessTariff !== variant.tariff.type)
              : selectedSupplyPointElectricity &&
                selectedSupplyPointElectricity.accessTariff &&
                variants.every((variant) => selectedSupplyPointElectricity.accessTariff !== variant.tariff.type)
          )
          if (selectedDualSubproductWithInvalidTariffForEnergy) {
            setIsInvalidTariff(selectedDualSubproductWithInvalidTariffForEnergy.energyType)
            return
          }
        } else if (selectedProduct.length === 2) {
          const selectedSupplyPointElectricity =
            data && data.length && data.find((supply) => supply.energyType === ELECTRICITY.toUpperCase())
          const selectedSupplyPointGas = data && data.length && data.find((supply) => supply.energyType === GAS.toUpperCase())
          const selectedProductElectricity = selectedProduct.find((product) => product.energyType === 'ELECTRICITY')
          const selectedProductGas = selectedProduct.find((product) => product.energyType === 'GAS')
          const selectedProductElectricityVariants = selectedProductElectricity && selectedProductElectricity.variants
          const selectedProductGasVariants = selectedProductGas && selectedProductGas.variants
          const selectedSupplyPointElectricityVariants =
            selectedSupplyPointElectricity && selectedSupplyPointElectricity.accessTariff
          const selectedSupplyPointGasVariants = selectedSupplyPointGas && selectedSupplyPointGas.accessTariff
          const isValidTariffElectricity = selectedProductElectricityVariants.some(
            (variant) => variant.tariff.type === selectedSupplyPointElectricityVariants
          )
          const isValidTariffGas = selectedProductGasVariants.some(
            (variant) => variant.tariff.type === selectedSupplyPointGasVariants
          )
          if (!isValidTariffElectricity || !isValidTariffGas) {
            setIsInvalidTariff(true)
            return
          }
        }
      }

      // TODO: Change when IBM fix streetType value
      // Right now, we receive it as a null value, this has to be fixed by them
      // This happen only in development
      const address = selectedAddress
        ? { ...selectedAddress, streetType: selectedAddress.streetType || STREET_TYPE_DEFAULT }
        : selectedAddress
      // Avoid use ofsetRepeatedCups Function when we need to calculate.
      if (!shouldGetCalculations) {
        setIsRepeatedCups(false)
        if (isMultiPoint && supplyPoint && supplyPoint.some(({ cups }) => cups === data.cups)) {
          setIsRepeatedCups(true)
          return
        }
      }

      if (
        referenceSegment === PYME &&
        !isNewSupply &&
        !tenureData &&
        !isLeadError &&
        !isOnlineChannelDraft &&
        !isDuplicateOrderDraft
      ) {
        const validationClientNode = data && data.validationClientNode
        const tariff = data && data.accessTariff
        const isValidTariff = !selectedProductVariants
          ? true
          : selectedProductVariants.some((variant) => variant.tariff.type === tariff) || activeStepNumber === 1
        const tenureElectricity = tenure && tenure.products && tenure.products.find((product) => product.type === 'ELECTRICITY')
        const tenureGas = tenure && tenure.products && tenure.products.find((product) => product.type === 'GAS')
        const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)
        const selectedService = service === BOTH_ENUM ? ELECTRICITY : service

        const showOwnerModal =
          !validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff
        showPymesModal(data, showOwnerModal, selectedService)
      }

      if (
        referenceSegment === PYME &&
        !isNewSupply &&
        !tenureData &&
        !isLeadError &&
        !isOnlineChannelDraft &&
        !isDuplicateOrderDraft
      ) {
        if (selectedDualSubproducts) {
          const supplyService = service === BOTH_ENUM || service === BOTH ? activeStep || service : service
          const selectedSupplyPointElectricity =
            data && data.length && data.find((supply) => supply.energyType === ELECTRICITY.toUpperCase())
          const selectedSupplyPointGas = data && data.length && data.find((supply) => supply.energyType === GAS.toUpperCase())
          const validationClientNodeElectricity =
            selectedSupplyPointElectricity && selectedSupplyPointElectricity.validationClientNode
          const validationClientNodeGas = selectedSupplyPointGas && selectedSupplyPointGas.validationClientNode

          //const tariff = technicalInfo && technicalInfo.accessTariff
          //const isValidTariff = !selectedProductVariants ? true : selectedProductVariants.some((variant) => variant.tariff.type === tariff) || activeStepNumber === 1
          const tenureElectricity = tenure && tenure.products && tenure.products.find((product) => product.type === 'ELECTRICITY')
          const tenureGas = tenure && tenure.products && tenure.products.find((product) => product.type === 'GAS')
          const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)
          const selectedService = supplyService === BOTH_ENUM ? ELECTRICITY : supplyService

          const showOwnerModalElectricity =
            !validationClientNodeElectricity && validationClientNodeElectricity !== null && !isNewSupplyChecked && !fidelization
          const showOwnerModalGas =
            !validationClientNodeGas && validationClientNodeGas !== null && !isNewSupplyChecked && !fidelization
          if (supplyService === ELECTRICITY) {
            showPymesModal(selectedSupplyPointElectricity, showOwnerModalElectricity, selectedService)
          } else {
            showPymesModal(selectedSupplyPointGas, showOwnerModalGas, selectedService)
          }
        } else {
          const validationClientNode = data && data.validationClientNode
          const tariff = data && data.accessTariff
          const isValidTariff = !selectedProductVariants
            ? true
            : selectedProductVariants.some((variant) => variant.tariff.type === tariff) || activeStepNumber === 1
          const tenureElectricity = tenure && tenure.products && tenure.products.find((product) => product.type === 'ELECTRICITY')
          const tenureGas = tenure && tenure.products && tenure.products.find((product) => product.type === 'GAS')
          const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)
          const selectedService = service === BOTH_ENUM ? ELECTRICITY : service

          const showOwnerModal =
            !validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff
          showPymesModal(data, showOwnerModal, selectedService)
        }
      }
      const formattedData = data.length ? data.map((item) => item) : data
      onSubmit(formattedData, address, false, cups)
      if (data.energyType && data.energyType.toLowerCase() === ELECTRICITY && service === ELECTRICITY) {
        setElectricityData({ ...data, ...selectedAddress })
        dispatchElectricity(COMPLETE)
        dispatchGas(HANDLE)
      } else if (data.energyType && data.energyType.toLowerCase() === GAS && service === GAS) {
        handleGasSubmit(data, selectedAddress)
        dispatchGas(COMPLETE)
        dispatchElectricity(HIDE)
      }
      setIsLoading(false)
      setNormalizedAddress(address)
      setSupplyAddress(address)
    }

    const checkPower = (data) => {
      if (!data.accessTariff) return true
      if (data.accessTariff === '2.0TD') {
        return parseFloat(data.capacityP1) === 0 && parseFloat(data.capacityP2) === 0
      } else {
        return (
          parseFloat(data.capacityP1) === 0 &&
          parseFloat(data.capacityP2) === 0 &&
          parseFloat(data.capacityP3) === 0 &&
          parseFloat(data.capacityP4) === 0 &&
          parseFloat(data.capacityP5) === 0 &&
          parseFloat(data.capacityP6) === 0
        )
      }
    }
    const handleGasSubmit = (data, selectedAddress = {}) => {
      if (isEmptyObject(gasData)) {
        setElectricityData({})
      }
      setGasData({ ...data, ...selectedAddress })
      setActiveStep(null)
      setIsLoading(false)
    }

    /**
     * This function complete gas calculator tab when is dual tariff.
     * If it is funnel supply step, it takes the supply address selected in electricity form.
     * If it is calculator, it takes the selected address introduced by the user in gas form.
     * @param {} data
     * @param {} selectedAddress
     */
    const handleGasDualSubmit = (data, selectedAddress = {}) => {
      setIsLoading(true)
      let resAddress = false
      const addressData = selectedAddress ? { ...selectedAddress } : { ...supplyAddress }
      if (normalizedAddress && !isEmptyObject(normalizedAddress)) {
        resAddress = { ...normalizedAddress }
        resAddress.gasCups = data.cups
        addressData.gasCups = data.cups
      } else if (auxNormalizedAddress && !isEmptyObject(auxNormalizedAddress)) {
        resAddress = { ...auxNormalizedAddress }
        resAddress.gasCups = data.cups || data.gasCups
        addressData.gasCups = data.cups || data.gasCups
      }
      setGasData({ ...data, ...addressData })
      setNormalizedAddress(resAddress || normalizedAddress || addressData)
      setAuxNormalizedAddress(resAddress || normalizedAddress || addressData)
      setHasGasCups(true)
      dispatchGas(COMPLETE)
      setIsLoading(false)
      setActiveStep(null)
    }

    /**
     * Use this function in order to check wheather the electricity response includes a cups gas in the response
     * Also it receives the selected addres from the normaliced streets service
     * @param  {} technicalData
     * @param  {} selectedAddress
     */
    const dualSubmit = async (technicalData, selectedAddress, complete = false) => {
      // first set the Electrical data, if it has a selected address it will add it.
      setIsLoading(true)
      const technicalInfos = []
      let electricityDataCalculations = {}
      try {
        electricityDataCalculations =
          shouldGetCalculations[technicalData.energyType.toUpperCase()] && !technicalData.monthlyCost
            ? await getCalculatorInfo(technicalData.cups || technicalData.electricityCups, technicalData.energyType, companyCode)
            : {}
      } catch (error) {
        console.error(error)
      }
      setElectricityData({ ...technicalData, ...electricityDataCalculations, ...selectedAddress })
      dispatchElectricity(COMPLETE)
      technicalInfos.push({ ...technicalData, ...electricityDataCalculations, supplyAddress: selectedAddress })
      if (selectedAddress && selectedAddress.gasCups) {
        // as is a addres with electricity cups we Only show one form (visual fixes)
        const selectedGasPoint = findSupplyPoint(supplyPoint, selectedAddress.gasCups, GAS)
        if (!selectedGasPoint) {
          const { isOk, code } = await checkCups([selectedAddress.gasCups], userDocumentNumber, selectedCompany)
          setIsLoading(true)
          if (!isOk) {
            wrongCupsValidation(code)
            setActiveStep(GAS)
            setIsLoading(false)
            return
          }
        }
        setNormalizedAddress(selectedAddress)
        let gasTechnicalInfo, gasDataCalculations

        try {
          // we then call the 2 functions that retrieve the gas information

          if (
            !complete ||
            !selectedGasPoint ||
            (shouldGetCalculations[technicalData.energyType.toUpperCase()] && !selectedGasPoint.monthlyCost)
          ) {
            gasDataCalculations = shouldGetCalculations[GAS.toUpperCase()]
              ? await getCalculatorInfo(selectedAddress.gasCups, GAS.toUpperCase(), companyCode)
              : {}

            gasTechnicalInfo = await getTechnicalInfoFromCups(GAS, selectedAddress.gasCups, documentType, userDocumentNumber)
            if (!gasTechnicalInfo.accessTariff) {
              showNewSupplyTechnicalModalForm(gasTechnicalInfo, GAS, handleGasDualSubmit, selectedAddress)
            }
            //eslint-disable-next-line
            if (
              gasTechnicalInfo &&
              gasTechnicalInfo.consumption12Months &&
              //eslint-disable-next-line
              (!numeroRegex.test(gasTechnicalInfo.consumption12Months) ||
                isNaN(parseFloat(gasTechnicalInfo.consumption12Months)) ||
                parseFloat(gasTechnicalInfo.consumption12Months) <= 0)
            ) {
              const tariffData = await getTariffData(GAS, selectedProduct, channel)
              gasTechnicalInfo.consumption12Months = tariffData.find(
                (tariff) => tariff.type === gasTechnicalInfo.accessTariff
              ).defaultAnnualConsumption
            }

            setGasData({ ...gasTechnicalInfo, ...gasDataCalculations, ...selectedAddress })
            technicalInfos.push({ ...gasTechnicalInfo, ...gasDataCalculations, supplyAddress: selectedAddress })
          } else {
            setGasData({ ...selectedGasPoint })
            technicalInfos.push({ ...selectedGasPoint })
          }
          setHasGasCups(true)
          dispatchGas(COMPLETE)
          if (isPopUp) onAutoCompletion(technicalInfos)
          setSupplyAddress(selectedAddress)
          setActiveStep(null)
        } catch (error) {
          setActiveStep(GAS)
          setHasGasCups(false)
          setIsLoading(false)
          if ((typeof gasTechnicalInfo === 'undefined' || isNewSupplyChecked) && step === 3) {
            showNewSupplyTechnicalModalForm({ ...selectedAddress }, GAS, handleGasDualSubmit, { ...selectedAddress })
          }
          if (isPopUp) onAutoCompletion(technicalInfos)
          console.error(error)
        }
      } else {
        //no gasCups on searched address, request gas information
        if (isPopUp) onAutoCompletion(technicalInfos)
        setHasGasCups(false)
        setGasData({})
        if (normalizedAddress.gasCups && !selectedAddress.gasCups && gasData && !isEmptyObject(gasData)) {
          setHasGasCups(true)
          setNormalizedAddress({ ...selectedAddress, gasCups: normalizedAddress.gasCups || gasData.cups })
        } else {
          setNormalizedAddress({ ...selectedAddress })
        }
        setSupplyAddress(selectedAddress)
        setActiveStep(GAS)
      }
      setIsLoading(false)
    }

    // TODO mix method dualSubmit with dualSubmitGas
    const dualSubmitGas = async (technicalData, selectedAddress) => {
      // first set the Electrical data, if it has a selected address it will add it.
      const technicalInfos = []
      setGasData({ ...technicalData, ...selectedAddress })
      technicalInfos.push({ ...technicalData, ...selectedAddress })
      if (
        !selectedAddress ||
        !selectedAddress.electricityCups ||
        !(isFlatRate || !openCupsModal || isTenureSelected || isNewCo)
      ) {
        if (isPopUp) onAutoCompletion(technicalInfos)
        if (!Object.entries(electricityData).length) {
          setNormalizedAddress(false)
          setSupplyAddress(selectedAddress)
          setActiveStep(ELECTRICITY)
        } else {
          setActiveStep(null)
        }
      }
      setIsLoading(false)
    }

    const handleEdit = (step) => {
      if (sendEditGAEvent) {
        sendEditGAEvent()
      }
      const currentStep = step === BOTH ? ELECTRICITY : step
      setActiveStep(currentStep)
    }

    const handleSingleEdit = (step) => {
      if (sendEditGAEvent) {
        sendEditGAEvent()
      }
      if ([BOTH, ELECTRICITY].includes(step)) {
        setAuxNormalizedAddress(false)
        setNormalizedAddress(false)
        setGasData({})
        setElectricityData({})
        setActiveStep(ELECTRICITY)
        dispatchElectricity(TOGGLE_EDIT)
      } else {
        setGasData({})
        setActiveStep(GAS)
        dispatchGas(TOGGLE_EDIT)
      }
    }

    useEffect(() => {
      if (isOnlineChannelDraft && isMultiPoint && supplyPoint && cups) {
        const supply = supplyPoint.find((supply) => supply.cups === cups)
        setSupplyAddress(supply.supplyAddress)
        service === ELECTRICITY ? completeElectricity({ ...supply }) : completeGas({ ...supply })
        setIsRepeatedCups(false)
        completeSupplyStep()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnlineChannelDraft, isMultiPoint])

    const completeSupplyPoint = async (
      technicalInfo,
      address,
      calculatorInfo,
      handleClose,
      checkDualAddresAlert = false,
      validationClienNodeModal = false
    ) => {
      try {
        const normalizedAddress = address && address.data

        if (technicalInfo && technicalInfo.accessTariff && !isNewSupplyChecked) {
          selectSubmit({ ...technicalInfo, ...calculatorInfo }, normalizedAddress)
          const label = `${address.type === ADDRESS ? 'direccion' : CUPS}${GA_SERVICES[service]}`
          onSendAnalytics('comprobar_ok', label)

          //Show modal
        } else if (step === 3) {
          showNewSupplyTechnicalModalForm(technicalInfo, activeStep || service, selectSubmit, normalizedAddress)
          return
        }

        handleClose()
        if (technicalInfo && technicalInfo.accessTariff && !isNewSupplyChecked && checkDualAddresAlert) {
          if (showNoSameAddressModal) {
            showNoSameAddressModal(tenure, validationClienNodeModal, service)
          } else {
            showNoSameAddressModal(tenure)
          }
        } else if (validationClienNodeModal) {
          showChangeOwnerModal(service)
        }
      } catch (e) {
        setIsLoading(false)
        onError()
        switch (e.status) {
          case 404:
            onSendAnalytics('comprobar_ko', 'CUPSnoExiste')
            break
          case 422:
            onSendAnalytics('comprobar_ko', 'sinConsumo')
            break
          default:
            onSendAnalytics('comprobar_ko', 'CUPSerror')
            break
        }
      }
    }

    const selectSubmit = (finalSupplyData, address) => {
      if (isElectricitySelected || isGasSelected) {
        handleSubmit(finalSupplyData, address)
      } else if (activeStep === GAS) {
        handleGasDualSubmit(finalSupplyData, address)
      } else {
        dualSubmit(finalSupplyData, address)
      }
    }

    const checkDualAddress = (
      supplyPoint,
      stepNumber,
      checkAddress,
      tenureCheck,
      validationClientNode = true,
      service,
      isValidTariff
    ) => {
      const tenureElectricity = tenure && tenure.products && tenure.products.find((product) => product.type === 'ELECTRICITY')
      const tenureGas = tenure && tenure.products && tenure.products.find((product) => product.type === 'GAS')
      const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)
      if (
        checkAddress &&
        supplyPoint.length === 2 &&
        (stepNumber === 2 || stepNumber === 1) &&
        !Object.values(supplyPoint[0]).every((value) => value === undefined) &&
        !Object.values(supplyPoint[1]).every((value) => value === undefined)
      ) {
        if (supplyPoint[0].postalCode !== supplyPoint[1].postalCode) {
          if (stepNumber === 1) {
            onCancel()
          }
          put(`orders/${orderId}/dual_address_message`, { dualAddressMessage: 'error' })
          showNoCPModal(tenureCheck)
          return false
        }

        const address1 = supplyPoint[0]
        const address2 = supplyPoint[1]
        /* if (tenureCheck && companyCode === NEWCO) {
           if (!validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff) {
             if (stepNumber === 2) {
               showChangeOwnerModal(service)
             }
             if (stepNumber === 1) {
               if (service === ELECTRICITY) {
                 setOwnerElectricityAlert(true)
               } else if (service === GAS) {
                 setOwnerGasAlert(true)
               }
             }
 
           } else {
             setAddresAlert(false)
             setOwnerElectricityAlert(false)
           }
           return true
         }*/

        if (
          address1 &&
          address2 &&
          address1.streetName !== undefined &&
          address2.streetName !== undefined &&
          (address1.streetName === address2.streetName ||
            address1.streetName.includes(address2.streetName) ||
            normalizaAddress(address1.streetName)
              .replace(/[^a-zA-Z0-9]/g, '')
              .toUpperCase() ===
              normalizaAddress(address2.streetName)
                .replace(/[^a-zA-Z0-9]/g, '')
                .toUpperCase()) &&
          ((address1.number !== undefined &&
            address2.number !== undefined &&
            String(parseInt(address1.number, 10)) === String(parseInt(address2.number, 10))) ||
            (address1.addressNumber !== undefined &&
              address2.number !== undefined &&
              String(parseInt(address1.addressNumber, 10)) === String(parseInt(address2.number, 10)))) &&
          ((address1.floor !== undefined &&
            address2.floor !== undefined &&
            address1.floor.replace(/^[0\s]+/, '') === address2.floor.replace(/^[0\s]+/, '')) ||
            (address1.addressFloor !== undefined &&
              address2.floor !== undefined &&
              address1.addressFloor.replace(/^[0\s]+/, '') === address2.floor.replace(/^[0\s]+/, ''))) &&
          ((address1.door !== undefined &&
            address2.door !== undefined &&
            address1.door.replace(/^[0\s]+/, '') === address2.door.replace(/^[0\s]+/, '')) ||
            (address1.addressDoor !== undefined &&
              address2.door !== undefined &&
              address1.addressDoor.replace(/^[0\s]+/, '') === address2.door.replace(/^[0\s]+/, '')))
        ) {
          if (!validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff) {
            if (stepNumber === 2) {
              showChangeOwnerModal(service)
            }
            if (stepNumber === 1) {
              if (service === ELECTRICITY) {
                setOwnerElectricityAlert(true)
              } else if (service === GAS) {
                setOwnerGasAlert(true)
              }
            }
          }
          return true
        } else {
          put(`orders/${orderId}/dual_address_message`, { dualAddressMessage: 'warning' })
          if (stepNumber === 2) {
            const showOwnerModal = !validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization
            showNoSameAddressModal(tenureCheck, showOwnerModal, service)
            setAddresAlert(false)
            setOwnerElectricityAlert(false)
            setOwnerGasAlert(false)
          } else if (stepNumber === 1) {
            setAddresAlert(true)
            if (!validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff) {
              if (service === ELECTRICITY) {
                setOwnerElectricityAlert(true)
              } else if (service === GAS) {
                setOwnerGasAlert(true)
              }
            }
          }
          return true
        }
      }
      if (!validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff) {
        if (stepNumber === 2) {
          showChangeOwnerModal(service)
        }
        if (stepNumber === 1) {
          if (service === ELECTRICITY) {
            setOwnerElectricityAlert(true)
          } else if (service === GAS) {
            setOwnerGasAlert(true)
          }
        }
      } else {
        setAddresAlert(false)
        setOwnerElectricityAlert(false)
        setOwnerGasAlert(false)
        if (activeStepNumber === 1) {
          setShowCheck(false)
        }
      }
      return true
    }

    // TODO mix method dualSubmit with dualSubmitGas
    const handleNATLOC = async (emergyaAd) => {
      const { status } = emergyaAd.result
      if (status && status !== 'inputCups') {
        const { code, results, message } = emergyaAd.result
        const emergyaAddress = results[0]
        const mappedAddress = mapEmergyaAddress(emergyaAddress)
        cupsValue.current = ''
        setIsLoading(true)
        const numeroRegex = /^-?\d*([.,]?\d+)?$/
        const selectedCups =
          (service === ELECTRICITY || ((service === BOTH_ENUM || service === BOTH) && activeStep !== GAS)
            ? results[0].cupsID
            : results[0].gasCupsID) || results[0].cups
        if (emergyaAd && emergyaAd.requestParams) {
          if (emergyaAd.requestParams.provider) {
            if (service === BOTH_ENUM || service === BOTH) {
              onSendAnalytics('selectCupsByCups', emergyaAd.requestParams.category)
            } else {
              onSendAnalytics('selectCupsByCups', service)
            }
          } else {
            if (service === BOTH_ENUM || service === BOTH) {
              onSendAnalytics('selectCupsByAddress', emergyaAd.requestParams.category)
            } else {
              onSendAnalytics('selectCupsByAddress', service)
            }
          }
        } else if (emergyaAd && emergyaAd.type === 'alta') {
          if (service === BOTH_ENUM || service === BOTH) {
            onSendAnalytics('selectCupsByCups', emergyaAd.result.results[0].category)
          } else {
            onSendAnalytics('selectCupsByCups', service)
          }
        }

        const { isOk, code: codeCups } = await checkCups([selectedCups], userDocumentNumber, selectedCompany)
        if (!isOk) {
          wrongCupsValidation(codeCups)
          setIsLoading(false)
          return
        }

        const supplyService = service === BOTH_ENUM || service === BOTH ? activeStep || service : service

        const supplyData = { service, cups: selectedCups, energyStep: supplyService }
        const submitFunction = selectSubmit
        if (
          !isEmptyObject(results[0]) &&
          selectedCups &&
          (String(code)[0] === '2' || ((code === 0 || code === 409 || code === 500) && companyCode === NEWCO))
        ) {
          let technicalInfo
          const checkAddressObject = {
            isElectricitySelected: isElectricitySelected,
            isGasSelected: isGasSelected,
            mappedAddress: mappedAddress,
          }
          try {
            if (message === 'No encontrada la direccion' && !mappedAddress.placeId) {
              technicalInfo = await getTechnicalInfoFromCups(supplyService, selectedCups, documentType, userDocumentNumber)

              if (
                technicalInfo &&
                technicalInfo.accessTariff &&
                technicalInfo.consumption12Months &&
                (!numeroRegex.test(technicalInfo.consumption12Months) ||
                  isNaN(parseFloat(technicalInfo.consumption12Months)) ||
                  parseFloat(technicalInfo.consumption12Months) <= 0)
              ) {
                const tariffData = await getTariffData(supplyService, selectedProduct, channel)
                technicalInfo.consumption12Months = tariffData.find(
                  (tariff) => tariff.type === technicalInfo.accessTariff
                ).defaultAnnualConsumption
              }
              if (technicalInfo) {
                showNewSupplyAddressModalForm(completeSupplyPoint, supplyData, checkAddressObject)
              }
              setIsLoading(false)
              return
            }
            if (code === 201) {
              mappedAddress.isNewAddress = true
            }
            technicalInfo = await getTechnicalInfoFromCups(supplyService, selectedCups, documentType, userDocumentNumber)

            if (
              technicalInfo &&
              technicalInfo.accessTariff &&
              technicalInfo.consumption12Months &&
              (!numeroRegex.test(technicalInfo.consumption12Months) ||
                isNaN(parseFloat(technicalInfo.consumption12Months)) ||
                parseFloat(technicalInfo.consumption12Months) <= 0)
            ) {
              //show modal
              const tariffData = await getTariffData(supplyService, selectedProduct || preSelectedRate, channel)
              technicalInfo.consumption12Months = tariffData.find(
                (tariff) => tariff.type === technicalInfo.accessTariff
              ).defaultAnnualConsumption
            }

            if (isNewSupplyChecked) {
              const selectedService = supplyService === BOTH_ENUM ? ELECTRICITY : supplyService
              const supplyData = { service: selectedService, address: mappedAddress, technicalInfo }
              showNewSupplyModal(completeSupplyPoint, supplyData, submitFunction)
              setIsLoading(false)
              return
            }
            if ((!technicalInfo.accessTariff || checkPower(technicalInfo)) && step === 3 && supplyService === ELECTRICITY) {
              const validationClientNode = technicalInfo && technicalInfo.validationClientNode
              const tariff = technicalInfo && technicalInfo.accessTariff
              const isValidTariff = !selectedProductVariants
                ? true
                : selectedProductVariants.some((variant) => variant.tariff.type === tariff) || activeStepNumber === 1
              const tenureElectricity =
                tenure && tenure.products && tenure.products.find((product) => product.type === 'ELECTRICITY')
              const tenureGas = tenure && tenure.products && tenure.products.find((product) => product.type === 'GAS')
              const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)
              const selectedService = supplyService === BOTH_ENUM ? ELECTRICITY : supplyService

              const showOwnerModal =
                !validationClientNode && validationClientNode !== null && !isNewSupplyChecked && !fidelization && isValidTariff
              showNewSupplyTechnicalModalForm(technicalInfo, selectedService, submitFunction, mappedAddress, showOwnerModal)
            }

            const dataCalculations = shouldGetCalculations[technicalInfo.energyType.toUpperCase()]
              ? await getCalculatorInfo(technicalInfo.cups, technicalInfo.energyType, companyCode)
              : {}
            if (step === 2 && dataCalculations && dataCalculations.detail === 0) {
              showNowPriceData()
              return
            }

            let secondAddress = {}
            let checkAddress = false
            let tenureCheck = false
            if (isElectricitySelected || isGasSelected) {
              checkAddress = true

              if (tenure) {
                tenureCheck = true
                setTenureAlert(true)
                secondAddress = {
                  number: tenure.addressNumber,
                  floor: tenure.addressFloor,
                  door: tenure.addressDoor,
                  streetName: tenure.addressStreet,
                  postalCode: tenure.postalCode,
                }
              } else if (normalizedAddress) {
                setTenureAlert(false)
                secondAddress = {
                  number: normalizedAddress.addressNumber,
                  floor: normalizedAddress.addressFloor,
                  door: normalizedAddress.addressDoor,
                  streetName: normalizedAddress.addressStreet,
                  postalCode: normalizedAddress.postalCode,
                }
              }
            } else if (activeStep === GAS) {
              checkAddress = true
              secondAddress = supplyAddress ? supplyAddress : normalizedAddress ? normalizedAddress : auxNormalizedAddress
            }
            const validationClientNode = technicalInfo && technicalInfo.validationClientNode
            const tariff = technicalInfo && technicalInfo.accessTariff
            const isValidTariff = !selectedProductVariants
              ? true
              : selectedProductVariants.some((variant) => variant.tariff.type === tariff) || activeStepNumber === 1

            if (
              checkDualAddress(
                [mappedAddress, secondAddress],
                activeStepNumber,
                checkAddress,
                tenureCheck,
                validationClientNode,
                supplyService,
                isValidTariff
              )
            ) {
              submitFunction({ ...technicalInfo, ...dataCalculations }, mappedAddress)
            }
          } catch (error) {
            setIsLoading(false)
            if (typeof technicalInfo === 'undefined' || isNewSupplyChecked) {
              if (step === 3) {
                if (!mappedAddress.externalId || mappedAddress.externalId === 'undefined') {
                  showNewSupplyAddressModalForm(completeSupplyPoint, supplyData, checkAddressObject)

                  return
                }

                showNewSupplyTechnicalModalForm({ ...mappedAddress }, activeStep || service, submitFunction, mappedAddress)
              } else if (step === 2) {
                showErrorModal('technicalFlatRate')
              }
            }
            console.error(error)
          }
        } else if ((step === 2 || step === 3) && selectedCups && code === 0 && companyCode === GNS) {
          const checkAddressObject = {
            isElectricitySelected: isElectricitySelected,
            isGasSelected: isGasSelected,
            mappedAddress: mappedAddress,
          }
          if (step === 2) {
            try {
              let technicalInfo = await getTechnicalInfoFromCups(supplyService, selectedCups, documentType, userDocumentNumber)
              if (
                technicalInfo &&
                technicalInfo.accessTariff &&
                technicalInfo.consumption12Months &&
                (!numeroRegex.test(technicalInfo.consumption12Months) ||
                  isNaN(parseFloat(technicalInfo.consumption12Months)) ||
                  parseFloat(technicalInfo.consumption12Months) <= 0)
              ) {
                const tariffData = await getTariffData(supplyService, selectedProduct, channel)
                technicalInfo.consumption12Months = tariffData.find(
                  (tariff) => tariff.type === technicalInfo.accessTariff
                ).defaultAnnualConsumption
                //Show modal
              }
              if ((!technicalInfo.accessTariff || checkPower(technicalInfo)) && step === 3 && supplyService === ELECTRICITY) {
                const selectedService = supplyService === BOTH_ENUM ? ELECTRICITY : supplyService
                showNewSupplyTechnicalModalForm(technicalInfo, selectedService, submitFunction, mappedAddress)
              }

              supplyData.technicalInfo = technicalInfo
              showNewSupplyAddressModalForm(completeSupplyPoint, supplyData, checkAddressObject)

              setIsLoading(false)
              return
            } catch (error) {
              showErrorModal('technicalFlatRate')
              console.log(error)
              setIsLoading(false)
              return
            }
          }
          showNewSupplyAddressModalForm(completeSupplyPoint, supplyData, checkAddressObject)
        } else if (step === 0 && selectedCups) {
          try {
            let technicalInfo = await getTechnicalInfoFromCups(supplyService, selectedCups, documentType, userDocumentNumber)
            if (
              technicalInfo &&
              technicalInfo.accessTariff &&
              technicalInfo.consumption12Months &&
              (!numeroRegex.test(technicalInfo.consumption12Months) ||
                isNaN(parseFloat(technicalInfo.consumption12Months)) ||
                parseFloat(technicalInfo.consumption12Months) <= 0)
            ) {
              const tariffData = await getTariffData(supplyService, selectedProduct, channel)
              technicalInfo.consumption12Months = tariffData.find(
                (tariff) => tariff.type === technicalInfo.accessTariff
              ).defaultAnnualConsumption
            }
            if ((!technicalInfo.accessTariff || checkPower(technicalInfo)) && step === 3 && supplyService === ELECTRICITY) {
              const selectedService = supplyService === BOTH_ENUM ? ELECTRICITY : supplyService
              showNewSupplyTechnicalModalForm(technicalInfo, selectedService, submitFunction, mappedAddress)
            }

            const dataCalculations = shouldGetCalculations[technicalInfo.energyType.toUpperCase()]
              ? await getCalculatorInfo(technicalInfo.cups, technicalInfo.energyType, companyCode)
              : {}
            submitFunction({ ...technicalInfo, ...dataCalculations }, {})
          } catch (error) {
            setIsLoading(false)
            console.log(error)
            if (setIsErrorCups) setIsErrorCups(true)
            showErrorModal('technicalFlatRate')
          }
        }
        if (portabilityNewco && !isPortabilityNewcoCheck) {
          setPortabilityNewco(true)
        }
        setIsLoading(false)
      } else if (status && status === 'inputCups') {
        const { cups } = emergyaAd.result
        if (cups && cups !== cupsValue) {
          cupsValue.current = cups
        }
      }
    }

    const checkCupsExist = (type) => {
      let cups = ''
      if (supplyPoint !== null && supplyPoint !== undefined && supplyPoint.length > 0) {
        let supplyPointAux = supplyPoint.find((cups) => cups.energyType === type)
        if (supplyPointAux !== undefined && supplyPointAux.cups !== undefined) {
          cups = supplyPointAux.cups
        } else if (tenure) {
          if (type === 'ELECTRICITY' && tenure.electricityCups) {
            cups = tenure.electricityCups
          }
          if (type === 'GAS' && tenure.gasCups) {
            cups = tenure.gasCups
          }
        }
      }

      return cups
    }

    const UploadInvoice = () => {
      if (!isEnabledCupsByInvoice) return null

      return (
        <UploadInvoiceAction
          openInfoModal={openInfoModal}
          openUploadImageModal={() => openUploadImageModal(index)}
          disabled={stateElectricity.isFormClosed}
          completed={stateElectricity.completed}
          sendGAEventUploadInvoiceImage={sendGAEventUploadInvoiceImage}
          sendGAinfoInvoiceEvent={sendGAinfoInvoiceEvent}
        />
      )
    }

    return (
      <>
        <Loader open={isDisabled || isLoading} opacity={0.75}>
          <CircularProgress thickness={2} />
        </Loader>
        {isBothSelected ? (
          <>
            <SupplyCard
              type={hasGasCups ? BOTH : ELECTRICITY}
              collapsed={hasGasCups ? stateElectricity.isFormClosed : activeStep !== ELECTRICITY}
              isCompleted={
                (!isEmptyObject(electricityData) && !isEmptyObject(gasData)) ||
                (!isEmptyObject(electricityData) && isEmptyObject(gasData) && activeStep !== ELECTRICITY)
              }
              onEdit={hasGasCups ? handleSingleEdit : handleEdit}
              data={electricityData}
              secondaryData={electricityData && gasData && hasGasCups ? gasData : false}
              normalizedAddress={auxNormalizedAddress || normalizedAddress}
              openCupsModal={openCupsModal}
              isTenure={isTenureSelected}
              company={companyCode}
            >
              <NewSupplySwitch
                isNewSupplySwitchDisabled={isNewSupplySwitchDisabled}
                openCupsModal={openCupsModal}
                isNewSupplyChecked={isNewSupplyChecked}
                onNewSupplyChange={onNewSupplyChange}
              />
              <NATLOC
                sessionId={uuid()}
                step={step}
                cups={checkCupsExist('ELECTRICITY') || cupsValue.current}
                provider={PROVIDER_CODES[companyCode] || 'iberia'}
                category={'electricity'}
                finishEvent={(direccion) => {
                  handleNATLOC(direccion)
                }}
                translations={widgetTranslations(t)}
              />
            </SupplyCard>
            {!hasGasCups && (
              <SupplyCard
                type={GAS}
                collapsed={activeStep !== GAS}
                isCompleted={!isEmptyObject(gasData) && activeStep !== GAS}
                onEdit={handleEdit}
                data={gasData}
                openCupsModal={openCupsModal}
                isTenure={isTenureSelected}
                company={companyCode}
              >
                <Collapse in={isGasSelected || isBothSelected} timeout="auto">
                  <NewSupplySwitch
                    isNewSupplySwitchDisabled={isNewSupplySwitchDisabled}
                    openCupsModal={openCupsModal}
                    isNewSupplyChecked={isNewSupplyChecked}
                    onNewSupplyChange={onNewSupplyChange}
                  />
                  <NATLOC
                    sessionId={uuid()}
                    step={step}
                    provider={PROVIDER_CODES[companyCode] || 'iberia'}
                    category={'gas'}
                    cups={checkCupsExist('GAS') || cupsValue.current}
                    finishEvent={(direccion) => {
                      handleNATLOC(direccion)
                    }}
                    translations={widgetTranslations(t)}
                  />
                </Collapse>
              </SupplyCard>
            )}
            {ownerElectricityAlert && !ownerGasAlert && (
              <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                <Typography variant="basicBig">{t('ownerWarning.modal.description', { energy: t('common.light') })}</Typography>
              </Alert>
            )}
            {ownerGasAlert && !ownerElectricityAlert && (
              <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                <Typography variant="basicBig">{t('ownerWarning.modal.description', { energy: t('common.gas') })}</Typography>
              </Alert>
            )}
            {ownerElectricityAlert && ownerGasAlert && (
              <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                <Typography variant="basicBig">{t('ownerWarning.modal.description', { energy: t('common.both') })}</Typography>
              </Alert>
            )}
            {addresAlert && (
              <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                <Typography variant="basicBig">
                  {tenureAlert ? t('newSupplyPoint.modal.addresWarningTenure') : t('newSupplyPoint.modal.addresWarning')}
                </Typography>
              </Alert>
            )}
          </>
        ) : (
          <>
            {service === ELECTRICITY && (
              <>
                <UploadInvoice />
                <SupplyCard
                  type={ELECTRICITY}
                  collapsed={stateElectricity.isFormClosed}
                  isCompleted={stateElectricity.completed}
                  onEdit={handleSingleEdit}
                  data={electricityData}
                  hide={stateElectricity.hideCard}
                  openCupsModal={openCupsModal}
                  isTenure={isTenureSelected}
                  isMultiPoint={isMultiPoint}
                  onRemoveSupplyPoint={onRemoveSupplyPoint}
                  cups={cups}
                  supplyPoint={supplyPoint}
                  referenceSegment={referenceSegment}
                  company={companyCode}
                >
                  <NewSupplySwitch
                    isNewSupplySwitchDisabled={isNewSupplySwitchDisabled}
                    openCupsModal={openCupsModal}
                    isNewSupplyChecked={isNewSupplyChecked}
                    onNewSupplyChange={onNewSupplyChange}
                  />
                  <NATLOC
                    sessionId={uuid()}
                    cups={(dataFromInvoice ? dataFromInvoice.cups : '') || (cups ? cups : '') || cupsValue.current || ''}
                    step={step}
                    provider={PROVIDER_CODES[companyCode] || 'iberia'}
                    category={'electricity'}
                    finishEvent={(direccion) => {
                      handleNATLOC(direccion)
                    }}
                    translations={widgetTranslations(t)}
                  />
                </SupplyCard>
                {ownerElectricityAlert && !ownerGasAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {t('ownerWarning.modal.description', { energy: t('common.light') })}
                    </Typography>
                  </Alert>
                )}
                {ownerGasAlert && !ownerElectricityAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">{t('ownerWarning.modal.description', { energy: t('common.gas') })}</Typography>
                  </Alert>
                )}
                {ownerElectricityAlert && ownerGasAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {t('ownerWarning.modal.description', { energy: t('common.both') })}
                    </Typography>
                  </Alert>
                )}
                {addresAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {tenureAlert ? t('newSupplyPoint.modal.addresWarningTenure') : t('newSupplyPoint.modal.addresWarning')}
                    </Typography>
                  </Alert>
                )}
              </>
            )}
            {service === GAS && (
              <>
                <SupplyCard
                  type={GAS}
                  collapsed={stateGas.isFormClosed}
                  isCompleted={stateGas.completed}
                  onEdit={handleSingleEdit}
                  data={gasData}
                  hide={stateGas.hideCard}
                  openCupsModal={openCupsModal}
                  isTenure={isTenureSelected}
                  isMultiPoint={isMultiPoint}
                  onRemoveSupplyPoint={onRemoveSupplyPoint}
                  cups={cups}
                  supplyPoint={supplyPoint}
                  referenceSegment={referenceSegment}
                >
                  <Collapse in={service === GAS && !stateGas.isFormClosed} timeout="auto">
                    <NewSupplySwitch
                      isNewSupplySwitchDisabled={isNewSupplySwitchDisabled}
                      openCupsModal={openCupsModal}
                      isNewSupplyChecked={isNewSupplyChecked}
                      onNewSupplyChange={onNewSupplyChange}
                    />
                    <NATLOC
                      sessionId={uuid()}
                      step={step}
                      cups={(cups ? checkCupsExist('GAS') : '') || cupsValue.current || ''}
                      provider={PROVIDER_CODES[companyCode] || 'iberia'}
                      category={'gas'}
                      finishEvent={(direccion) => {
                        handleNATLOC(direccion)
                      }}
                      translations={widgetTranslations(t)}
                    />
                  </Collapse>
                </SupplyCard>

                {ownerElectricityAlert && !ownerGasAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {t('ownerWarning.modal.description', { energy: t('common.light') })}
                    </Typography>
                  </Alert>
                )}
                {ownerGasAlert && !ownerElectricityAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">{t('ownerWarning.modal.description', { energy: t('common.gas') })}</Typography>
                  </Alert>
                )}
                {ownerElectricityAlert && ownerGasAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {t('ownerWarning.modal.description', { energy: t('common.both') })}
                    </Typography>
                  </Alert>
                )}
                {addresAlert && (
                  <Alert style={{ marginTop: '20px', alignItems: 'center', marginBottom: '20px' }} severity="warning">
                    <Typography variant="basicBig">
                      {tenureAlert ? t('newSupplyPoint.modal.addresWarningTenure') : t('newSupplyPoint.modal.addresWarning')}
                    </Typography>
                  </Alert>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  },
  (prevProps, newProps) =>
    !newProps.isFirstSupply &&
    !newProps.getQuota &&
    prevProps.cups === newProps.cups &&
    prevProps.service === newProps.service &&
    prevProps.isNewSupplyElectricityChecked === newProps.isNewSupplyElectricityChecked &&
    prevProps.isNewSupplyGasChecked === newProps.isNewSupplyGasChecked &&
    prevProps.isMultiPoint === newProps.isMultiPoint
)

export default SupplyPoint
