import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: '2px dashed #003247',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5',

    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#003247',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  uploadImageBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    marginTop: theme.spacing(2),
  },
  file: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    gap: '10px',
  },
  closeIcon: {
    cursor: 'pointer',
    scale: '0.8',
  },
  attachFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: '60px 40px',
    background: '#f5f5f5',
    width: '100%',
    height: '100%',
  },
}))

export default useStyles
