import { createMuiTheme } from '@material-ui/core/styles'
import fonts from '../base/fonts'
import palette from '../base/palette'

const defaultTheme = createMuiTheme()

const table = {
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: defaultTheme.palette.common.white,
        '&.subtable': {
          backgroundColor: palette.neutral.light6,
          '& .MuiTableRow-head': {
            backgroundColor: palette.neutral.light5,
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        '&.expanded': {
          backgroundColor: palette.neutral.light4,
        },
      },
    },
    MuiTableCell: {
      root: {
        '&.subtableWrapper': {
          padding: 0,
          borderBottom: 'none',
        },
        '&.truncate': {
          '& span': {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            direction: 'rtl',
            textAlign: 'left',
            width: '10ch',
            color: palette.primary.main,
            fontWeight: 600,
            textDecoration: 'underline',
            '&:hover': {
              color: palette.secondary.main,
            },
          },
        },
      },
      head: {
        ...fonts.th,
        color: palette.neutral.main,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: palette.primary.main,
        },
        '&.MuiTableSortLabel-active': {
          color: palette.primary.main,
        },
        '& .MuiSvgIcon-root': {
          width: defaultTheme.spacing(14 / 8),
          height: defaultTheme.spacing(14 / 8),
          marginLeft: defaultTheme.spacing(6 / 8),
          verticalAlign: 'bottom',
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        justifyContent: 'flex-end',
      },
      spacer: {
        display: 'none',
      },
      select: {
        fontWeight: 600,
      },
      caption: {
        ...fonts.helper,
        color: palette.neutral.main,
        '& ~ &': {
          fontWeight: 600,
        },
      },
      actions: {
        '& .MuiIconButton-root': {
          color: palette.primary.main,
        },
      },
    },
  },
}

export default table
