import { SERVICE } from './constants'

export const SET_DATA = `${SERVICE}/SET_DATA`
export const CLEAR_DATA = `${SERVICE}/CLEAR_DATA`
export const SET_USER_DATA = `${SERVICE}/SET_USER_DATA`
export const CLEAR_USER_DATA = `${SERVICE}/CLEAR_USER_DATA`
export const SET_CHANNELS = `${SERVICE}/SET_CHANNELS`
export const CLEAR_CHANNELS = `${SERVICE}/CLEAR_CHANNELS`
export const SET_OPERATOR_COMPANIES = `${SERVICE}/SET_OPERATOR_COMPANIES`
export const SET_SELECTED_CHANNEL = `${SERVICE}/SET_SELECTED_CHANNEL`
export const CLEAR_SELECTED_CHANNEL = `${SERVICE}/CLEAR_SELECTED_CHANNEL`
export const SET_REFERENCE_SEGMENT = `${SERVICE}/SET_REFERENCE_SEGMENT`
export const LOGOUT = `${SERVICE}/LOGOUT`
export const RESET_CONTRACT = `${SERVICE}/RESET_CONTRACT`
export const RESET_FUNNEL_CONTRACT = `${SERVICE}/RESET_FUNNEL_CONTRACT`
export const SET_IS_RESET_FUNNEL_CONTRACT = `${SERVICE}/SET_IS_RESET_FUNNEL_CONTRACT`
export const RESET_ORDER = `${SERVICE}/RESET_ORDER`
export const FETCH_USER = `${SERVICE}/FETCH_USER`
export const FETCH_USER_API = `${SERVICE}/FETCH_USER_API`
export const FETCH_CHANNELS = `${SERVICE}/FETCH_CHANNELS`
export const RESET_SUPPLY_STEP = `${SERVICE}/RESET_SUPPLY_STEP`
