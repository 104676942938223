import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'ui/components/typography'
import { Box, Button } from '@material-ui/core'

const OwnerModal = ({ data, handleClose }) => {
  const { t } = useTranslation()
  const { service } = data

  return (
    <Box px={5} pb={6} pt={6}>
      <Box mb={3} textAlign="left">
        <Typography variant="h3" color="primary">
          {t('ownerWarning.modal.title')}
        </Typography>
      </Box>

      <>
        <Box textAlign="left">
          <Typography>
            {t('ownerWarning.modal.description', { energy: service === 'electricity' ? t('common.light') : t('common.gas') })}
          </Typography>
        </Box>
      </>

      <Box
        textAlign="right"
        style={{
          gap: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '2rem',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose()
          }}
        >
          {t('ownerWarning.modal.action')}
        </Button>
      </Box>
    </Box>
  )
}

export default OwnerModal
