import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: theme.spacing(4),
    overflow: 'hidden',
  },
  addIcon: {
    marginRight: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(3, 7),
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        paddingTop: theme.spacing(3),
      },
    },
  },
  invoiceIcon: {
    height: '30px',
    width: '20px',
  },
  uploadImageBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  infoIcon: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    scale: 1.2,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        flexWrap: 'wrap',
      },
    },
  },
}))

export default useStyles
