import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'

import Typography from 'ui/components/typography'
import getProductPriceKeys from 'utils/getProductPriceKeys'

import PriceVariant from './PriceVariant'
import useStyles from './styles'

const Prices = ({ product, type }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const terms = useMemo(() => getProductPriceKeys(i18n.getResourceBundle(i18n.language), product, type), [i18n, product, type])

  return (
    <>
      {terms.map((term) => (
        <Box mb={2} key={`price-${term.name}`}>
          {term.prices.length ? (
            <>
              <Box mb={0.5}>
                <Typography variant="label" color="textPrimary">
                  {t(`price-label-${term.name}`)}
                </Typography>
              </Box>
              <Box className={classes.prices}>
                {term.prices.map((price) => (
                  <PriceVariant
                    isSubPrice
                    name={price}
                    value={`entity-${product}-${type}-price-${term.name}-${price}`}
                    key={`price-variant-${price}`}
                  />
                ))}
              </Box>
            </>
          ) : (
            <PriceVariant name={term.name} value={`entity-${product}-${type}-price-${term.name}`} />
          )}
        </Box>
      ))}
    </>
  )
}

export default Prices
