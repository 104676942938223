import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import * as backofficeTemplatesActionTypes from './action-types'
import * as api from './api'
import {
  fetchTemplateFieldsStart,
  fetchTemplateFieldsError,
  fetchTemplateFieldsSuccess,
  setExportOrdersFields,
  setViewOrdersFields,
} from './actions'

import { getTemplateFieldsByType } from './selectors'

import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'

import { TEMPLATE_TYPES } from 'constants/settings'

function* fetchTemplateFieldsSaga({ payload: templateType }) {
  const fields = yield select(getTemplateFieldsByType(templateType))

  if (!isEmptyObject(fields)) {
    return
  }

  yield put(fetchTemplateFieldsStart())
  try {
    const templatefields = yield call(api.getTemplateFieldsByTemplateType, { type: templateType })

    switch (templateType) {
      case TEMPLATE_TYPES.orderExport:
        yield put(setExportOrdersFields(templatefields))
        break
      case TEMPLATE_TYPES.orderView:
        yield put(setViewOrdersFields(templatefields))
        break
      default:
        break
    }

    yield put(fetchTemplateFieldsSuccess())
  } catch (e) {
    console.error(e)
    yield put(fetchTemplateFieldsError())
  }
}

export default function* rootSaga() {
  yield all([takeLatest([backofficeTemplatesActionTypes.FETCH_TEMPLATE_FIELDS], fetchTemplateFieldsSaga)])
}
