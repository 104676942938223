import { SERVICE } from './constants'

export const LOAD_CONTROL_START = `${SERVICE}/LOAD_CONTROL_START`
export const LOAD_CONTROL_SUCCESS = `${SERVICE}/LOAD_CONTROL_SUCCESS`
export const LOAD_CONTROL_ERROR = `${SERVICE}/LOAD_CONTROL_ERROR`
export const LOAD_CONTROL_CLEAR = `${SERVICE}/LOAD_CONTROL_CLEAR`
export const LOAD_CONTROL_VISUALIZATION_START = `${SERVICE}/LOAD_CONTROL_VISUALIZATION_START`
export const LOAD_CONTROL_VISUALIZATION_SUCCESS = `${SERVICE}/LOAD_CONTROL_VISUALIZATION_SUCCESS`
export const LOAD_CONTROL_VISUALIZATION_ERROR = `${SERVICE}/LOAD_CONTROL_VISUALIZATION_ERROR`
export const LOAD_CONTROL_VISUALIZATION_CLEAR = `${SERVICE}/LOAD_CONTROL_VISUALIZATION_CLEAR`
export const LOAD_CONTROL_EXPORT_START = `${SERVICE}/LOAD_CONTROL_EXPORT_START`
export const LOAD_CONTROL_EXPORT_SUCCESS = `${SERVICE}/LOAD_CONTROL_EXPORT_SUCCESS`
export const LOAD_CONTROL_EXPORT_ERROR = `${SERVICE}/LOAD_CONTROL_EXPORT_ERROR`
export const LOAD_CONTROL_EXPORT_CLEAR = `${SERVICE}/LOAD_CONTROL_EXPORT_CLEAR`
export const FETCH_FILTERS_CONFIGURATION = `${SERVICE}/FETCH_FILTERS_CONFIGURATION`
export const FETCH_VISUALIZATION = `${SERVICE}/FETCH_VISUALIZATION`
export const STOP_VISUALIZATION = `${SERVICE}/STOP_VISUALIZATION`
export const FETCH_EXPORT = `${SERVICE}/FETCH_EXPORT`
export const SET_CHANNELS = `${SERVICE}/SET_CHANNELS`
export const SET_PRODUCTS = `${SERVICE}/SET_PRODUCTS`
export const SET_STATUS = `${SERVICE}/SET_STATUS`
export const SET_AGENCIES = `${SERVICE}/SET_AGENCIES`
export const SET_FILTERS = `${SERVICE}/SET_FILTERS`
export const CLEAR_FILTERS = `${SERVICE}/CLEAR_FILTERS`
export const SET_VISUALIZATION = `${SERVICE}/SET_VISUALIZATION`
export const CLEAR_VISUALIZATION = `${SERVICE}/CLEAR_VISUALIZATION`
export const SET_EXPORT = `${SERVICE}/SET_EXPORT`
export const CLEAR_EXPORT = `${SERVICE}/CLEAR_EXPORT`
