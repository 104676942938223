export const ORDERS_TABLE_HEADERS = [
  { id: 'crmId', sortable: true },
  { id: 'titular', sortable: false },
  { id: 'customerNif', sortable: false },
  { id: 'status', sortable: false },
  { id: 'channelName', sortable: false },
  { id: 'company', sortable: false },
  { id: 'orderAge', sortable: true, tooltip: true },
  { id: 'externalId', sortable: true },
  { id: 'sellerCode', sortable: true },
  { id: 'partialAccountIban', sortable: false },
  { id: 'address', sortable: false },
  { id: 'supplyAddress', sortable: false },
  { id: 'referralCode', sortable: false },
  { id: 'audioName', sortable: false },
  { id: 'saleAudio', sortable: false },
  { id: 'attachment', sortable: false, icon: true, iconType: 'attachment', showAlways: true },
  { id: 'detail', sortable: false, showAlways: true, noLabel: true },

  // { id: 'expand', sortable: false, showAlways: true },
]

export const ORDER_PORTABILITY_TABLE_HEADERS = [
  { id: 'file_name', sortable: true },
  { id: 'created_at', sortable: true },
  { id: 'order_crm_id', sortable: true },
  { id: 'contract_line_id', sortable: false },
  { id: 'first_name', sortable: false },
  { id: 'document_number', sortable: false },
  { id: 'online_invoice', sortable: false },
  { id: 'cups', sortable: false },
  { id: 'product_type', sortable: false },
  { id: 'campaign_id', sortable: false },
  { id: 'state', sortable: false },
  { id: 'context', sortable: false },
  { id: 'street_name', sortable: false },
  { id: 'bill_street_name', sortable: false },
]

export const ORDERS_TABLE_HEADERS_FAIL = [
  { id: 'crmId', sortable: true },
  { id: 'titular', sortable: false },
  { id: 'customerNif', sortable: false },
  { id: 'status', sortable: false },
  { id: 'channelName', sortable: false },
  { id: 'company', sortable: false },
  { id: 'orderAge', sortable: true, tooltip: true },
  { id: 'externalId', sortable: true },
  { id: 'sellerCode', sortable: true },
  { id: 'partialAccountIban', sortable: false },
  { id: 'address', sortable: false },
  { id: 'supplyAddress', sortable: false },
  { id: 'externalWriteId', sortable: false },
  { id: 'cnae', sortable: false },
  { id: 'referralCode', sortable: false },
  { id: 'audioName', sortable: false },
  { id: 'saleAudio', sortable: false },
  { id: 'attachment', sortable: false, icon: true, iconType: 'attachment', showAlways: true },
  { id: 'detail', sortable: false, showAlways: true, noLabel: true },

  // { id: 'expand', sortable: false, showAlways: true },
]

export const ORDERS_TABLE_HEADERS_ORDER = [
  'crmId',
  'titular',
  'customerNif',
  'status',
  'channelName',
  'company',
  'orderAge',
  'externalId',
  'sellerCode',
  'partialAccountIban',
  'address',
  'supplyAddress',
  'userModifier',
  'cnae',
  //'newSupply',
  'referralCode',
  'audioName',
  'saleAudio',
  'attachment',
]

export const ORDER_LINES_TABLE_HEADERS = [
  { id: 'crmId', sortable: true },
  { id: 'typeIcon', sortable: false },
  { id: 'type', sortable: true },
  { id: 'family', sortable: true },
  { id: 'name', sortable: true },
  { id: 'cups', sortable: false },
  { id: 'orderDate', sortable: true },
  { id: 'age', sortable: true, tooltip: true },
  { id: 'lastStateChange', sortable: true },
  { id: 'transactionalProductState', sortable: true },
  { id: 'clientProductState', sortable: true },
]

export const ORDER_LINES_TABLE_HEADERS_ORDER = [
  'crmId',
  'typeIcon',
  'type',
  'family',
  'name',
  'cups',
  'orderDate',
  'age',
  'lastStateChange',
  'transactionalProductState',
  'clientProductState',
]

export const ORDER_DETAILS_TABLE_HEADERS = [
  { id: 'type', label: 'Tipo' },
  { id: 'family', label: 'Familia' },
  { id: 'name', label: 'Nombre' },
  { id: 'cups', label: 'CUPS' },
  { id: 'orderDate', label: 'Fecha Pedido' },
  { id: 'lastStateChange', label: 'Fecha último cambio de estado' },
  { id: 'signContract', label: 'Fecha de firma' },
  { id: 'userModifier', label: 'Usuario modificador' },
  { id: 'consumption12Months', label: 'Consumo 12 meses' },
  { id: 'stateActivationDate', label: 'Fecha de activación' },
  { id: 'stateDeActivationDate', label: 'Fecha de baja' },
  { id: 'siebelProductServiceId', label: 'Línea de contrato' },
  { id: 'contractType', label: 'Tipo de contrato' },
  { id: 'transactionalProductState', label: 'Estado producto transaccional' },
  { id: 'campaignType', label: 'Tipo campaña' },
  { id: 'campaignCode', label: 'Código campaña' },
  { id: 'onlineInvoice', label: 'Factura online' },
  { id: 'clientProductState', label: 'Estado producto cliente' },
]

export const ORDER_DETAILS_FAIL_TABLE_HEADERS = [
  { id: 'type', label: 'Tipo' },
  { id: 'family', label: 'Familia' },
  { id: 'name', label: 'Nombre' },
  { id: 'cups', label: 'CUPS' },
  { id: 'orderDate', label: 'Fecha Pedido' },
  { id: 'lastStateChange', label: 'Fecha último cambio de estado' },
  { id: 'signContract', label: 'Fecha de firma' },
  { id: 'userModifier', label: 'Usuario modificador' },
  { id: 'consumption12Months', label: 'Consumo 12 meses' },
  { id: 'stateActivationDate', label: 'Fecha de activación' },
  { id: 'stateDeActivationDate', label: 'Fecha de baja' },
  { id: 'siebelProductServiceId', label: 'Línea de contrato' },
  { id: 'contractType', label: 'Tipo de contrato' },
  { id: 'transactionalProductState', label: 'Estado producto transaccional' },
  { id: 'campaignType', label: 'Tipo campaña' },
  { id: 'campaignCode', label: 'Código campaña' },
  { id: 'onlineInvoice', label: 'Factura online' },
  { id: 'eco', label: 'Eco', showAlways: true },
  { id: 'tariff', label: 'Tarifa' },
  { id: 'power_elect1', label: 'Potencia' },
  { id: 'price', label: 'Fecha precio' },
  { id: 'clientProductState', label: 'Estado producto cliente' },
]

export const NOTIFICATIONS_TABLE_HEADERS_ORDER = ['message', 'state', 'date', 'time', 'link']

export const NOTIFICATIONS_TABLE_HEADERS = [
  { id: 'message', sortable: false },
  { id: 'state', sortable: false },
  { id: 'date', sortable: false },
  { id: 'time', sortable: false },
  { id: 'link', sortable: false },
]

export const NO_TEMPLATE_FIELDS_HEADER = [
  { id: 'noFields', label: 'No se han seleccionado campos en la plantilla aplicada.', align: 'center' },
]
