import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@material-ui/lab'
import { Box, DialogContent, Divider, Link } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const CallMeBack = ({ children, error, phone, success, onClickPhone }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={2}>
          <Typography variant="h4" className={classes.title}>
            <span dangerouslySetInnerHTML={{ __html: t('callMeBack.title') }}></span>
          </Typography>
        </Box>
        {error && (
          <Box mb={2}>
            <Alert icon={false} severity="error">
              <Box mb={phone && 2}>
                <Typography variant="h5">{t('callMeBackErrorTitle')}</Typography>
              </Box>
              {phone && <Typography variant="basic">{t('callMeBackErrorDescription', { phone })}</Typography>}
            </Alert>
          </Box>
        )}
        {success && (
          <Box mb={2}>
            <Alert icon={false} severity="success">
              <Box mb={2}>
                <Typography variant="h5">{t('callMeBackSuccessTitle')}</Typography>
              </Box>
              <Typography variant="basic">{t('callMeBackSuccessDescription')}</Typography>
            </Alert>
          </Box>
        )}
        {children}
        <Divider className={classes.divider} />
        {phone && (
          <>
            <Box mb={2} mt={2} display="flex" flexDirection="column" alignItems="center">
              <Typography variant="basic">{t('callMeBackContactTitle')}</Typography>
              <Typography variant="h5">
                <Link color="secondary" href={`tel:${phone}`} onClick={() => onClickPhone(phone)}>
                  {phone}
                </Link>
              </Typography>
            </Box>
            <Divider className={classes.divider} />
          </>
        )}
        <Box mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5">{t('callMeBackScheduleTitle')}</Typography>
          <Typography variant="basic">{t('callMeBackLaboralSchedule')}</Typography>
          <Typography variant="basic">{t('callMeBackWeekendSchedule')}</Typography>
        </Box>
      </DialogContent>
    </>
  )
}

export default CallMeBack
