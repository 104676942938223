import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, DialogContent } from '@material-ui/core'

import useStyles from './styles'

const LimitChannel = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('limitChannel.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            <span
              dangerouslySetInnerHTML={{
                __html: t('limitChannel.text'),
              }}
            />
          </Typography>
        </Box>
      </DialogContent>
    </>
  )
}

export default LimitChannel
