import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Link from 'ui/components/link'
import { submitOwnerStep } from 'services/customer/actions'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'
import useStyles from './styles'

const Retargeting = ({ handleClose, data: formData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.openHelpModal, { currentStep: STEPS.owner }))
  }, [dispatch])

  const submitRetargeting = (valueRetargeting) => {
    const formDataWithRetargeting = {
      ...formData,
      retargeting: valueRetargeting,
    }
    handleClose()
    dispatch(submitOwnerStep(formDataWithRetargeting))
  }
  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography variant="h4">{t('retargeting.title')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="basic">
          <Trans i18nKey="retargeting.text">
            <Link underline="always" href={t('common.privacyPolicyUrl')} target="_blank"></Link>
          </Trans>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          type="submit"
          color="secondary"
          variant="outlined"
          onClick={() => {
            dispatch(sendGAEvent(GA_EVENT_NAMES.noAceptoModal, { currentStep: STEPS.owner }))
            submitRetargeting(false)
          }}
        >
          {t('common.dontAgree')}
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => {
            dispatch(sendGAEvent(GA_EVENT_NAMES.aceptoModal, { currentStep: STEPS.owner }))
            submitRetargeting(true)
          }}
        >
          {t('common.agree')}
        </Button>
      </DialogActions>
    </>
  )
}

export default Retargeting
