import { makeStyles } from '@material-ui/core/styles'
import background from '@zatopek/core/src/assets/images/wave.svg'

export const useMainLayoutStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  wrapper: {
    marginTop: theme.spacing(5),
  },
  background: {
    background: `url(${background})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
  },
}))

export const useAlternativeLayoutStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  wrapper: {
    maxWidth: '100vw',
    padding: '0px',
    display: 'flex',
    flexGrow: 1,
  },
  background: {
    display: 'flex',
    flexDirection: 'column',
    background: `url(${background})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
  },
}))
