import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  id: redukers.value(
    {
      set: actionTypes.SET_DRAFT_ID,
      reset: actionTypes.CLEAR_DRAFT_ID,
    },
    null
  ),
  token: redukers.value(
    {
      set: actionTypes.SET_DRAFT_TOKEN,
      reset: actionTypes.CLEAR_DRAFT_TOKEN,
    },
    null
  ),
  order: redukers.value(
    {
      set: actionTypes.SET_DRAFT_ORDER,
      reset: actionTypes.CLEAR_DRAFT_ORDER,
    },
    {}
  ),
  lead: redukers.value(
    {
      set: actionTypes.SET_DRAFT_LEAD,
      reset: actionTypes.CLEAR_DRAFT_LEAD,
    },
    {}
  ),
  userId: redukers.value(
    {
      set: actionTypes.SET_DRAFT_USER_ID,
      reset: actionTypes.CLEAR_DRAFT_USER_ID,
    },
    null
  ),
  channelId: redukers.value(
    {
      set: actionTypes.SET_DRAFT_CHANNEL_ID,
      reset: actionTypes.CLEAR_DRAFT_CHANNEL_ID,
    },
    null
  ),
  channelOfferId: redukers.value(
    {
      set: actionTypes.SET_DRAFT_CHANNEL_OFFER_ID,
      reset: actionTypes.CLEAR_DRAFT_CHANNEL_OFFER_ID,
    },
    null
  ),
  onlineChannelDraft: redukers.value(
    {
      set: actionTypes.SET_DRAFT_ONLINE_CHANNEL,
      reset: actionTypes.CLEAR_DRAFT_ONLINE_CHANNEL,
    },
    null
  ),
  duplicateOrderDraft: redukers.value(
    {
      set: actionTypes.SET_DUPLICATE_ORDER_DRAFT,
      reset: actionTypes.CLEAR_DUPLICATE_ORDER_DRAFT,
    },
    null
  ),
  requestDraft: redukers.request({
    start: actionTypes.START_DRAFT,
    success: actionTypes.SUCCESS_DRAFT,
    error: actionTypes.ERROR_DRAFT,
    clear: actionTypes.CLEAR_DRAFT,
  }),
  requestOnlineChannelDraft: redukers.request({
    start: actionTypes.START_DRAFT_ONLINE_CHANNEL,
    success: actionTypes.SUCCESS_DRAFT_ONLINE_CHANNEL,
    error: actionTypes.ERROR_DRAFT_ONLINE_CHANNEL,
    clear: actionTypes.CLEAR_DRAFT_ONLINE_CHANNEL,
  }),
  requestFillDraft: redukers.request({
    start: actionTypes.START_FILL_FUNNEL_WITH_DRAFT,
    success: actionTypes.SUCCESS_FILL_FUNNEL_WITH_DRAFT,
    error: actionTypes.ERROR_FILL_FUNNEL_WITH_DRAFT,
    clear: actionTypes.CLEAR_FILL_FUNNEL_WITH_DRAFT,
  }),
  requestSendDraftData: redukers.request({
    start: actionTypes.START_SEND_DRAFT_DATA,
    success: actionTypes.SUCCESS_SEND_DRAFT_DATA,
    error: actionTypes.ERROR_SEND_DRAFT_DATA,
    clear: actionTypes.CLEAR_SEND_DRAFT_DATA,
  }),
  requestDuplicateOrder: redukers.request({
    start: actionTypes.START_DRAFT_DUPLICATE_ORDER,
    success: actionTypes.SUCCESS_DRAFT_DUPLICATE_ORDER,
    error: actionTypes.ERROR_DRAFT_DUPLICATE_ORDER,
    clear: actionTypes.CLEAR_DRAFT_DUPLICATE_ORDER,
  }),
  requestRecoverDuplicateOrder: redukers.request({
    start: actionTypes.START_RECOVER_DRAFT_DUPLICATE_ORDER,
    success: actionTypes.SUCCESS_RECOVER_DRAFT_DUPLICATE_ORDER,
    error: actionTypes.ERROR_RECOVER_DRAFT_DUPLICATE_ORDER,
    clear: actionTypes.CLEAR_RECOVER_DRAFT_DUPLICATE_ORDER,
  }),
})
