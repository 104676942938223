export const getOrdersStatus = (state) => {
  return state.control.status
}

export const getChannels = (state) => {
  return state.control.channels
}

export const getAllProducts = (state) => {
  return state.control.products
}

export const getAgencies = (state) => {
  return state.control.agencies
}

export const getFilters = (state) => state.control.filters

export const getVisualization = (state) => state.control.visualization

export const getIsVisualizationRequesting = (state) => state.control.loadControlVisualizationRequest.state === 'REQUESTING'

export const getIsVisualizationStopped = (state) => state.control.loadControlVisualizationRequest.state === 'EMPTY'

export const getExport = (state) => state.control.export

export const getIsExportRequesting = (state) => state.control.loadControlExportRequest.state === 'REQUESTING'

export const getIsExportSuccesfullyRequested = (state) => state.control.loadControlExportRequest.state === 'SUCCESS'
