import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { showModal } from 'services/modal/actions'
import { logout, resetContract } from 'services/operator/actions'
import { getIsOnlineChannel, getOperatorAuth } from '../services/operator/selectors'
import { getStepsProgressPercentage } from '../services/stepper/selectors'

import { MODAL_TYPES } from 'services/modal/constants'

import MainLayout from 'ui/layout'
import { sendGAEvent } from 'services/analytics/actions'
import { AUTHENTICATION_AZURE, GA_EVENT_NAMES } from 'utils/constants'
import { useMsal } from '@azure/msal-react'

const MainRouteLayout = ({ children, noActions, showProgress, alternativeLayout, ...rest }) => {
  const dispatch = useDispatch()
  const operator = useSelector(getOperatorAuth)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const { logout: auth0Logout } = useAuth0()
  const { instance } = useMsal()
  const progressPercentage = useSelector(getStepsProgressPercentage)

  const handleLogout = () => {
    dispatch(logout())
    AUTHENTICATION_AZURE
      ? instance.logoutRedirect({
          postLogoutRedirectUri: process.env.REACT_APP_FRONT_URL,
        })
      : auth0Logout({ returnTo: process.env.REACT_APP_FRONT_URL })
  }

  const openCMBModal = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.weCallYou))
    dispatch(showModal({ modalType: MODAL_TYPES.callMeBack }))
  }

  const handleOnClickLogo = () => {
    dispatch(resetContract())
    dispatch(sendGAEvent(GA_EVENT_NAMES.logo))
  }

  return (
    <MainLayout
      logout={handleLogout}
      isLoggedIn={operator}
      openCMBModal={openCMBModal}
      isOnlineChannel={isOnlineChannel}
      noActions={noActions}
      onClickLogo={handleOnClickLogo}
      progress={progressPercentage}
      showProgress={showProgress}
      alternativeLayout={alternativeLayout}
    >
      {children}
    </MainLayout>
  )
}

export default MainRouteLayout
