import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3, 7),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 4),
    },
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        paddingTop: theme.spacing(3),
      },
    },
  },
  loginButton: {
    marginRight: theme.spacing(2),
  },
}))

export default useStyles
