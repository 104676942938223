import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Box, Button, Grid } from '@material-ui/core'
import Typography from 'ui/components/typography'
import Capacity from 'ui/components/capacity'
import ChangeSupplyOwnership from '../changeSupplyOwnerShip'
import { getChangeSuppyOwnerShip, getSelectedTenure } from 'services/customer/selectors'
import { useSelector } from 'react-redux'
import { ELECTRICITY, GAS, BOTH_ENUM } from '@zatopek/core/src/utils/constants'
const CapacityForm = ({
  initialValues,
  tariff,
  capacity30ValidationSchema,
  capacity206xValidationSchema,
  onContinue,
  capacities,
  onKeyDown,
  isCapacityEditMode,
  isDisabled,
  supplyModeService,
  checkChangeOwner,
  supplyPoint,
  isOnlineChannel,
  isNewSupply,
  vcn,
}) => {
  const { t } = useTranslation()
  const changeSupplyOwnerShip = useSelector(getChangeSuppyOwnerShip)
  const tenure = useSelector(getSelectedTenure)
  const CheckChangeOwner = () => {
    const fideElectricity =
      tenure?.products?.find((product) => product.type === ELECTRICITY.toUpperCase()) &&
      (supplyModeService === ELECTRICITY || supplyModeService === BOTH_ENUM)
    const fideGas =
      tenure?.products?.find((product) => product.type === GAS.toUpperCase()) &&
      (supplyModeService === GAS || supplyModeService === BOTH_ENUM)

    if (isNewSupply || fideElectricity || fideGas) {
      return false
    }
    if (vcn.electricity === null && vcn.gas !== null) {
      return (
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === undefined
      )
    }
    if (vcn.gas === null && vcn.electricity !== null) {
      return (
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === undefined
      )
    }
    if (vcn.gas === null && vcn.electricity === null) {
      return (
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === undefined ||
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === undefined
      )
    }
    return false
  }
  return (
    <>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h5" color="primary">
            {t('supply.yourContractedPowerTitle')}
          </Typography>
        </Box>
        <Typography variant="basic">{t('supply.yourContractedPowerDetails')}</Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={tariff === '3.0TD' ? capacity30ValidationSchema : capacity206xValidationSchema}
        enableReinitialize
      >
        {({ handleChange, values, errors, resetForm }) => (
          <Form>
            <Grid container spacing={3}>
              {Object.keys(capacities).map((capacity, index) => {
                return (
                  <Capacity
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    onKeyDown={onKeyDown}
                    capacity={capacity}
                    index={index}
                    tarif={isCapacityEditMode}
                  />
                )
              })}
            </Grid>
            <Box mt={4}>
              <ChangeSupplyOwnership
                isOnlineChannel={isOnlineChannel}
                supplyPoint={supplyPoint}
                vcn={vcn}
                supplyModeService={supplyModeService}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" my={4}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={errors.length > 0 || isDisabled || CheckChangeOwner()}
                onClick={onContinue}
              >
                {t('common.continue')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CapacityForm
