import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(5, 4, 4),
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  dialogContent: {
    padding: theme.spacing(0, 4),
  },
  text: {
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(4),
    justifyContent: 'flex-end',
  },
}))

export default useStyles
