import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'
import { sendGAEvent } from 'services/analytics/actions'
import { COMPANY_CODES, GA_EVENT_NAMES } from 'utils/constants'
import { resetContract } from 'services/operator/actions'
import { resetUserData } from 'services/customer/actions'
import { getCompany } from 'services/customer/selectors'

const Sirca = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const company = useSelector(getCompany)
  const companyText = company?.code === COMPANY_CODES.newco ? 'Naturgy Clientes S.A.U' : 'Naturgy Iberia S.A'

  const onCloseModal = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.sircaFail))
    dispatch(resetContract())
    dispatch(resetUserData())
    handleClose()
    window.location.replace('https://www.naturgy.es')
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('sirca.notApproved')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            dangerouslySetInnerHTML={{ __html: t('sirca.negativeEvaluation', { companyText }) }}
            variant="basic"
            color="textPrimary"
          ></Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={onCloseModal}>
          {t('sirca.cancel')}
        </Button>
      </DialogActions>
    </>
  )
}

export default Sirca
