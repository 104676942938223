import { Box, Button, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Typography from 'ui/components/typography'
import { formatAddress } from '../../../utils/formatAddress'
import MaskedCups from '../maskedCups'

import useStyles from './styles'

const CardContent = ({
  showCardContent,
  data,
  normalizedAddress,
  openCupsModal,
  onEditClick,
  supplyPoint,
  isTenure,
  isMultiPoint,
  showNewSupplyAddress,
  secondaryData,
  type,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const newAddress = data ? formatAddress(data) : ''

  return (
    showCardContent && (
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="basic">
            {newAddress}
            <br />
            {data.postalCode} {data.municipalityName && `- ${data.municipalityName}`}
            <br />
            {data.provinceName}
            <br />
            <MaskedCups
              data={data}
              secondaryData={secondaryData}
              normalizedAddress={normalizedAddress}
              openCupsModal={openCupsModal}
              type={type}
            />
          </Typography>
        </Grid>
        {(!isMultiPoint || (supplyPoint && supplyPoint.length === 2 && supplyPoint.some(({ cups }) => !cups))) &&
          !showNewSupplyAddress && (
            <Grid item xs={12} md={2}>
              <Box className={classes.button}>
                <Button variant="outlined" color="primary" onClick={onEditClick}>
                  {t('common.edit')}
                </Button>
              </Box>
            </Grid>
          )}
      </Grid>
    )
  )
}

export default CardContent
