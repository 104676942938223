import { SERVICE } from './constants'

export const FETCH_PORTABILITY_START = `${SERVICE}/FETCH_PORTABILITY_START`
export const FETCH_PORTABILITY_SUCCESS = `${SERVICE}/FETCH_PORTABILITY_SUCCESS`
export const FETCH_PORTABILITY_ERROR = `${SERVICE}/FETCH_PORTABILITY_ERROR`
export const FETCH_PORTABILITY_CLEAR = `${SERVICE}/FETCH_PORTABILITY_CLEAR`
export const FETCH_PORTABILITY = `${SERVICE}/FETCH_PORTABILITY`
export const SET_PORTABILITY = `${SERVICE}/SET_PORTABILITY`

export const EXPORT_CSV = `${SERVICE}/EXPORT_CSV`
export const EXPORT_REQUEST_START = `${SERVICE}/EXPORT_REQUEST_START`
export const EXPORT_REQUEST_SUCCESS = `${SERVICE}/EXPORT_REQUEST_SUCCESS`
export const EXPORT_REQUEST_CLEAR = `${SERVICE}/EXPORT_REQUEST_CLEAR`
export const EXPORT_REQUEST_ERROR = `${SERVICE}/EXPORT_REQUEST_ERROR`

export const CREATE_CSV = `${SERVICE}/CREATE_CSV`
export const SAVE_CSV = `${SERVICE}/SAVE_CSV`
