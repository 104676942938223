import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Typography from 'ui/components/typography'
import { Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import useStyles from './styles'
import { ReactComponent as CheckUploadFile } from '@zatopek/core/src/assets/icons/check-upload-file.svg'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'
import { useDispatch } from 'react-redux'

const VALID_FILE_TYPES = ['application/pdf', 'image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp', 'image/tiff']
const ERROR_TYPE = 'type'
const ERROR_SIZE = 'size'
const ERRORS = [ERROR_TYPE, ERROR_SIZE]

const FileUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const maxSize = props.maxSize ?? 1000000

  const fileValidator = (file) => {
    if (!VALID_FILE_TYPES.includes(file.type)) {
      return {
        code: ERROR_TYPE,
        message: t('form.errors.fileType', { file: file.name }),
      }
    }
    if (file.size > maxSize) {
      return {
        code: ERROR_SIZE,
        message: t('form.errors.fileSize', { size: formatBytes(file.size), maxsize: formatBytes(maxSize) }),
      }
    }

    return null
  }

  const handleAcceptedFiles = (files) => {
    var allFiles = files

    if (props.showPreview && VALID_FILE_TYPES.includes(files[0]?.type)) {
      files.map((file) =>
        Object.assign(file, {
          formattedSize: formatBytes(file.size),
        })
      )

      allFiles = [...selectedFiles]
      allFiles.push(...files)
      if (allFiles.length > 1) {
        allFiles.shift()
      }
      setSelectedFiles(allFiles)
    }

    if (props.onFileUpload) props.onFileUpload(allFiles)
  }
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }
  const removeFile = (file) => {
    const newFiles = [...selectedFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setSelectedFiles(newFiles)
    if (props.onFileUpload) props.onFileUpload([])
  }

  return (
    <>
      <Dropzone
        maxFiles={1}
        maxSize={maxSize}
        accept={{
          'image/*': ['gif', 'png', 'jpeg', 'bmp', 'webp', 'tiff'],
          'application/pdf': ['.pdf'],
        }}
        {...props}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        validator={fileValidator}
      >
        {({ getRootProps, getInputProps, acceptedFiles, fileRejections }) => (
          <>
            <Box className={classes.dropZone}>
              <Box className={classes.attachFileContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                {props.showPreview && selectedFiles.length > 0 ? (
                  <CheckUploadFile />
                ) : (
                  <>
                    <Typography variant="basic" color="textPrimary">
                      {t('common.selectFileText')}
                    </Typography>
                    <Button
                      onClick={() => {
                        dispatch(sendGAEvent(GA_EVENT_NAMES.clickSelectFile, { currentStep: STEPS.supply }))
                      }}
                      variant="outlined"
                      color="primary"
                      className={classes.uploadImageBtn}
                    >
                      {t('common.selectFile')}
                    </Button>
                  </>
                )}
              </Box>
              {props.showPreview && acceptedFiles.length > 0 && (
                <div>
                  {selectedFiles?.map((f, i) => {
                    return (
                      <div key={i} className={classes.file}>
                        <Typography variant="basic" color="textPrimary">
                          {f.name}
                        </Typography>
                        <Typography variant="basic" color="textPrimary">
                          {f.formattedSize}
                        </Typography>
                        <CloseIcon className={classes.closeIcon} onClick={() => removeFile(f)} />
                      </div>
                    )
                  })}
                </div>
              )}
            </Box>
            {fileRejections?.length > 0 ? (
              <Typography color="error">
                {fileRejections[0]?.errors.find(({ code }) => ERRORS.includes(code))?.message}
              </Typography>
            ) : null}
          </>
        )}
      </Dropzone>
    </>
  )
}

export default FileUpload
