import React from 'react'
import { useTranslation } from 'react-i18next'

import { DialogActions, DialogContent, DialogTitle, Box, Button } from '@material-ui/core'
import Typography from 'ui/components/typography'
import useStyles from './styles'

const DiscountSelector = ({ handleClose, data: name }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const image =
    name === 'plana'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifaplana_zen.png'
      : name === 'por-uso'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_poruso.png'
      : name === 'noche'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_noche.png'
      : name === 'digital-gas'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_gas.png'
      : name === 'digital-luz'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_luz.png'
      : name === 'digital-noche'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_noche_luz.png'
      : name === 'digital-moving'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_moving_luz.png'
      : name === 'servielectric-xpress'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servielectric-expres.png'
      : name === 'servielectric-complet'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servielectric-complet.png'
      : name === 'servihogar'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servihogar-gene%CC%81rico.png'
      : (name === 'servigas-complet') | (name === 'servigas-complet-plus') | (name === 'servigas-complet-con-calefaccion')
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servigas-complet.png'
      : name === 'servigas-basico'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servigas-gene%CC%81rico.png'
      : name === 'servigas-confort'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servigas-confort.png'
      : name === 'servigas-xpress'
      ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_servigas-expres.png'
      : ''

  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography component="h2" variant="h4" color="secondary">
          {t(`entity-${name}-title`)}
        </Typography>
        <Typography component="h4" variant="h2" color="secondary">
          {t(`entity-${name}-subtitle`)}
        </Typography>
        {image !== '' ? <img className={classes.logo} src={image} alt={`${t(`entity-${name}-title`)} logo`} /> : null}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mt={1} mb={4}>
          <div dangerouslySetInnerHTML={{ __html: t(`entity-${name}-details`) }} />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </>
  )
}

export default DiscountSelector
