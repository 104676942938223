import { htmlFontSize } from './constants'
import { createMuiTheme } from '@material-ui/core/styles'

import palette from './palette'
import EmericRegularWoff from '../../assets/fonts/fsemeric/FSEmericWeb-Regular.woff'
import EmericRegularWoff2 from '../../assets/fonts/fsemeric/FSEmericWeb-Regular.woff2'
import EmericSemiBoldWoff from '../../assets/fonts/fsemeric/FSEmericWeb-SemiBold.woff'
import EmericSemiBoldWoff2 from '../../assets/fonts/fsemeric/FSEmericWeb-SemiBold.woff2'
import EmericLightWoff from '../../assets/fonts/fsemeric/FSEmericWeb-Light.woff'
import EmericLightWoff2 from '../../assets/fonts/fsemeric/FSEmericWeb-Light.woff2'

const defaultTheme = createMuiTheme()

export const emeric = {
  fontFamily: 'Emeric',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${EmericRegularWoff2}) format('woff2'),
    url(${EmericRegularWoff}) format('woff')
  `,
}

export const emericBold = {
  fontFamily: 'Emeric',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${EmericSemiBoldWoff2}) format('woff2'),
    url(${EmericSemiBoldWoff}) format('woff')
  `,
}

export const emericLight = {
  fontFamily: 'Emeric',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    url(${EmericLightWoff2}) format('woff2'),
    url(${EmericLightWoff}) format('woff')
  `,
}

const fonts = {
  htmlFontSize,
  fontFamily: ['Emeric', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontWeight: 600,
    fontSize: 30,
    lineHeight: 1.2,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 48,
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: 30,
    lineHeight: 1.22,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 36,
    },
  },
  h3: {
    fontWeight: 300,
    fontSize: 24,
    lineHeight: 1.33,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1.16,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.33,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.375,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  blueh6: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.33,
    color: palette.primary.main,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  basic: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  basicNormal: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  basicBig: {
    fontWeight: 300,
    fontSize: 18,
    lineHeight: 1.33,
  },
  basicBigNormal: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.33,
  },
  link: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.42,
  },
  helper: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.42,
  },
  helperThrough: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.42,
    textDecoration: 'line-through',
  },
  body1Sm: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.125,
  },
  body1Xs: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.125,
  },
  body2Sb: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.2,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.42,
  },
  bodyXs: {
    fontSize: 9,
    lineHeight: 1.11,
    textTransform: 'uppercase',
  },
  body3: {
    fontSize: 12,
    lineHeight: 1.33,
  },
  th: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.14,
  },
}

export default fonts
