const border = {
  style: {
    thin: '1px',
    medium: '2px',
  },
  radius: {
    medium: '6px',
    high: '30px',
  },
}

export default border
