import { useTranslation } from 'react-i18next'
import { Button, DialogContent, DialogActions } from '@material-ui/core'
import useStyles from './styles'

const IframeModal = ({ handleClose, data: { contentUrl, title, withModalActions } }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const contentUrlAux = Array.isArray(contentUrl) ? contentUrl : [contentUrl]
  const titleAux = Array.isArray(title) ? title : [title]
  let withModalActionsAux = Array.isArray(withModalActions) ? withModalActions : [withModalActions]
  withModalActionsAux = withModalActionsAux.find((elem) => elem === true)
  return (
    <>
      {contentUrlAux.map((elem, index) => (
        <>
          <DialogContent className={classes.dialogContent}>
            <iframe src={t(elem)} title={t(titleAux[index])} className={classes.iframe} frameborder="0" />
          </DialogContent>
        </>
      ))}

      <DialogActions className={classes.dialogActions}>
        {withModalActionsAux && (
          <Button color="secondary" variant="contained" onClick={handleClose}>
            {t('common.close')}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

export default IframeModal
