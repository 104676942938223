const dialog = {
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 768,
      },
      paperWidthSm: {
        maxWidth: 472,
      },
    },
  },
}

export default dialog
