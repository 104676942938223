import { Connect, DEFAULT_PHONE_NUMBER, token } from './business'

export const initCallMeBack = () => {
  if (!window.Connect) return
  Connect.token = token
}

export const getCallMeBackPhone = async (idCampaign) => {
  if (!window.Connect) return
  try {
    // Solicitamos teléfono disponible para nuestra campaña
    const response = await Connect.Pools.getPhone(idCampaign, DEFAULT_PHONE_NUMBER)
    // Sustituimos el teléfono que tenemos puesto por defecto en la landing
    return response?.results?.FreeToll || DEFAULT_PHONE_NUMBER
  } catch (err) {
    // En caso de error en la petición, el teléfono cambia a false
    console.error(err)
    return false
  }
}

export const sendLead = (lead) => {
  if (!window.Connect) return
  try {
    // Enviamos lead a DBI
    Connect.ws
      .sendLead(lead)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
      })
  } catch (err) {
    console.error(err)
  }
}
