import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 7, 3, 7),
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        paddingTop: theme.spacing(3),
      },
    },
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 300,
  },
  loginButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

export default useStyles
