import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'ui/components/typography'
import { Button, Box, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { hideModal } from 'services/modal/actions'

const BonoSocial = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [socialBonus, setSocialBonus] = useState(true)

  const useStyles = makeStyles((theme) => ({
    alert: {
      padding: theme.spacing(0, 3),
    },
  }))
  const classes = useStyles()

  const onContinue = () => {
    dispatch(hideModal())
  }

  return (
    <Box padding={5}>
      <Box textAlign="justify">
        <Typography variant="h3" color="primary">
          <Trans i18nKey="bonoSocial.modal.title"></Trans>
        </Typography>
        <Typography variant="basic">
          <span dangerouslySetInnerHTML={{ __html: t('bonoSocial.modal.text') }}></span>
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="bonoSocialResignation"
                name="bonoSocialResignation"
                checked={socialBonus}
                onChange={() => setSocialBonus(!socialBonus)}
                color="primary"
              />
            }
            label={t('bonoSocial.modal.label')}
          />
        </FormGroup>
      </Box>
      {!socialBonus && (
        <Box mt={2} mb={2}>
          <Alert padding={0} icon={false} severity="warning" className={classes.alert}>
            <Typography>
              <span dangerouslySetInnerHTML={{ __html: t('bonoSocial.modal.error') }}></span>
            </Typography>
          </Alert>
        </Box>
      )}
      <Box mt={3}>
        <Button disabled={!socialBonus} type="submit" variant="contained" color="secondary" onClick={() => onContinue()}>
          {t('common.continue')}
        </Button>
      </Box>
    </Box>
  )
}

export default BonoSocial
