import { getError, get } from 'services/client'
import { get as getPricing } from '@zatopek/core/src/services/client'
import { COMPANY_CODES } from 'utils/constants'

export const getIsClient = async (documentNumber) => await get(`tenure/exist_customer/${documentNumber}`)

export const getUserData = async (documentNumber, companyId, channelId, isPyme) =>
  await getError(`tenure/user_data/${documentNumber}?company=${companyId}&channelId=${channelId}&pyme=${isPyme}`)

export const getCupsCheck = async (cups, docNumber, company) => {
  const selectedCompany = company === COMPANY_CODES.gns ? 'imperial' : company.toLowerCase()
  return await getPricing(`existingCups?cups=${cups}&docNumber=${docNumber}&comercializadora=${selectedCompany}`, {})
}
