import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import { getFidelizationAvailable } from './api'
import { loadControlStart, loadControlSuccess, loadControlError, setFidelization } from './actions'
import * as actionTypes from './action-types'

import { getFidelization } from './selectors'
import { getSelectedChannel, getIsOnlineChannel } from 'services/operator/selectors'
import { getSelectedCompany, getCompany } from 'services/customer/selectors'
import { getReferenceSegment } from 'services/operator/selectors'

function* fetchFidelizationSaga() {
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const { channelId } = (yield select(getSelectedChannel)) || {}
  const { id } = (yield select(isOnlineChannel ? getCompany : getSelectedCompany)) || {}
  const companyId = id ?? (yield select(getCompany))?.id
  const current = (yield select(getFidelization)) || {}
  const referenceSegment = yield select(getReferenceSegment)
  const isPyme = referenceSegment === 'pyme'

  if (current.channelId === channelId && current.companyId === companyId) {
    return
  }

  yield put(loadControlStart())
  try {
    const { fideAllowed } = yield call(getFidelizationAvailable, { channelId, companyId })
    let allowed = false
    if (fideAllowed === 'SI' || (fideAllowed === 'PS' && isPyme) || (fideAllowed === 'RS' && !isPyme)) {
      allowed = true
    }

    yield put(
      setFidelization({
        channelId,
        companyId,
        allowed,
      })
    )
    yield put(loadControlSuccess())
  } catch (e) {
    console.error(e)
    yield put(loadControlError())
  }
}

export default function* rootSaga() {
  yield all([takeLatest([actionTypes.FETCH_FIDELIZATION], fetchFidelizationSaga)])
}
