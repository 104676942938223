import i18n from 'i18n'
import { DICTIONARY, ORDERS, ORDER_FILTERS, ORDER_LINES, PRODUCT_FILTERS } from 'utils/constants'

import {
  ORDERS_TABLE_HEADERS,
  ORDERS_TABLE_HEADERS_ORDER,
  ORDER_LINES_TABLE_HEADERS,
  ORDER_LINES_TABLE_HEADERS_ORDER,
} from '@zatopek/funnel/src/constants'

export const obtainHeadersLabel = (headers) => {
  return headers.map((header) => {
    return { ...header, label: i18n.t(`backOffice.tableHeader.${header['id']}`) }
  })
}

export const getStates = (type, state) => {
  return type === ORDERS ? ORDER_FILTERS[state] : PRODUCT_FILTERS[state]
}

export const getRenamedFilters = (filters) => {
  return Object.keys(filters).reduce((acc, value) => {
    acc[DICTIONARY[value]] = filters[value]
    return acc
  }, {})
}

export const getHeaders = (type) => {
  if (type === ORDERS) {
    const labeledHeaders = obtainHeadersLabel(ORDERS_TABLE_HEADERS)
    return { headers: labeledHeaders, normalizedHeaders: ORDERS_TABLE_HEADERS_ORDER }
  }
  if (type === ORDER_LINES) {
    const labeledHeaders = obtainHeadersLabel(ORDER_LINES_TABLE_HEADERS)
    return { headers: labeledHeaders, normalizedHeaders: ORDER_LINES_TABLE_HEADERS_ORDER }
  }
}
