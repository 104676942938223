import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Field, ErrorMessage } from 'formik'

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField as MuiTextField,
  Select,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { getIsUserDataFetching } from 'services/customer/selectors'
import { ID_DOC_TYPES, ID_DOC_TYPES_PYMES, PYME } from 'utils/constants'
import useStyles from './styles'

const ContactDataCheckingForm = ({ onChange, touched, values, errors, resetForm, referenceSegment, isResetFunnelContract }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isFetching = useSelector(getIsUserDataFetching)
  const [preSelectedDocumentType] = useState(referenceSegment === PYME ? ID_DOC_TYPES_PYMES.cif.value : ID_DOC_TYPES.nif.value)

  useEffect(() => {
    if (isResetFunnelContract) {
      resetForm()
    }
  }, [isResetFunnelContract, resetForm])

  return (
    <Grid container spacing={3}>
      {/* --- CHECK CLIENT - ID DOCUMENT TYPE --- */}
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel
            error={!!(touched.idDocumentTypeChecking && errors.idDocumentTypeCheckingChecking)}
            htmlFor="idDocumentTypeChecking"
          >
            {t('form.fields.idDocumentType')}
          </InputLabel>
          <Field
            component={Select}
            native
            error={!!(touched.idDocumentTypeChecking && errors.idDocumentTypeChecking)}
            id="idDocumentTypeChecking"
            name="idDocumentTypeChecking"
            label={t('form.fields.idDocumentType')}
            onChange={onChange('idDocumentTypeChecking')}
          >
            {Object.entries(referenceSegment === PYME ? ID_DOC_TYPES_PYMES : ID_DOC_TYPES)
              .filter(([key]) => key !== 'passport')
              .map(([key, { value, label }]) => (
                <option key={key} value={value} selected={value === preSelectedDocumentType}>
                  {t(label)}
                </option>
              ))}
          </Field>
        </FormControl>
        <ErrorMessage component={FormHelperText} name="idDocumentTypeChecking" error />
      </Grid>

      {/* --- CHECK CLIENT - ID DOCUMENT NUMBER --- */}
      <Grid item xs={12} sm={5}>
        <Field
          component={MuiTextField}
          required
          fullWidth
          error={!!(touched.idDocumentNumberChecking && errors.idDocumentNumberChecking)}
          id="idDocumentNumberChecking"
          label={t('form.fields.idDocumentNumber')}
          name="idDocumentNumberChecking"
          onChange={onChange('idDocumentNumberChecking')}
          value={values.idDocumentNumberChecking}
        />
        <ErrorMessage component={FormHelperText} name="idDocumentNumberChecking" error />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Button type="submit" variant="outlined" color="secondary" className={classes.checkUserButton} disabled={isFetching}>
          {isFetching ? <CircularProgress color="inherit" size={23} thickness={1.4} /> : t('checkClient')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ContactDataCheckingForm
