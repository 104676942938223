import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'

import { Box, Button, FormHelperText, Select, TextField as MuiTextField, InputLabel, FormControl, Grid } from '@material-ui/core'

import { CANCEL_ORDER_REASONS, CANCEL_ORDER_DETAILS_CHARACTER_LIMIT } from 'utils/constants'

const CancelOrderForm = ({ onChange, onCancel, touched, values, errors, isCancelling }) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <InputLabel htmlFor="cancelReason">{t('modalCancelOrder.cancelReason')}</InputLabel>
            <Field
              component={Select}
              native
              label={t('modalCancelOrder.cancelReason')}
              error={Boolean(touched.cancelReason && errors.cancelReason)}
              id="cancelReason"
              name="cancelReason"
              value={values.cancelReason}
              onChange={onChange('cancelReason')}
            >
              <option aria-label="None" value="" />
              {Object.entries(CANCEL_ORDER_REASONS).map(([key, value]) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </Field>
          </FormControl>
          <ErrorMessage component={FormHelperText} name="cancelReason" error />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.cancelDetails && errors.cancelDetails)}
            id="cancelDetails"
            placeholder={t('modalCancelOrder.placeholderDetails')}
            name="cancelDetails"
            helperText={
              !!values.cancelDetails.length &&
              `${t('modalCancelOrder.characterCount')}: ${values.cancelDetails.length}/${CANCEL_ORDER_DETAILS_CHARACTER_LIMIT}`
            }
            multiline
            rows={4}
            value={values.cancelDetails}
            onChange={onChange('cancelDetails')}
            label={t('modalCancelOrder.cancelDetails')}
          />
          <ErrorMessage component={FormHelperText} name="cancelDetails" error />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" mt={2} mb={4}>
        <Button type="submit" variant="contained" color="secondary" disabled={isCancelling}>
          {t('modalCancelOrder.buttonConfirm')}
        </Button>
      </Box>
    </>
  )
}

export default CancelOrderForm
