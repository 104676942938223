import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.modal - 50,
    backgroundColor: 'white',
    height: theme.spacing(9),
    boxShadow: theme.shadows[3],
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
    [theme.breakpoints.down(420)]: {
      height: theme.spacing(13),
    },
  },
  topSpacer: {
    height: theme.spacing(9),
    visibility: 'hidden',
  },
  progress: {
    position: 'absolute',
    left: 0,
    bottom: -8,
    width: '100%',
  },
  appbar: {
    boxShadow: 'none',
  },
  toolbar: {
    background: theme.palette.common.white,
    paddingLeft: 0,
    paddingRight: 0,
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
    '& .MuiButton-root': {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(3),
      },
    },
  },
  mobileButton: {
    [theme.breakpoints.down(420)]: {
      fontSize: 14,
      alignItems: 'flex-end',
    },
  },
  hideableIcon: {
    [theme.breakpoints.down(420)]: {
      fontSize: 14,
      '& .MuiButton-endIcon': {
        display: 'none',
      },
    },
  },
  menuLink: {
    color: theme.palette.text.primary,
  },
  exitIcon: {
    display: 'inline-block',
    marginLeft: 7,
  },
  checkIcon: {
    position: 'absolute',
    right: -5,
    top: 0,
    width: 15,
    color: theme.palette.feedback.success,
  },
  helpItem: {
    paddingBottom: 0,
    cursor: 'auto',
    '&:hover': {
      background: 'none',
    },
  },
}))

export default useStyles
