import { request } from 'redukers'
import { getSelectedChannel } from 'services/operator/selectors'
import { CHANNEL_TYPES } from 'utils/constants'

export const getDraft = (state) => state.draft
export const getDraftOrder = (state) => state.draft.order
export const getDraftLead = (state) => state.draft.lead
export const getDraftUserId = (state) => state.draft.userId
export const getDraftId = (state) => state.draft.id
export const getDraftToken = (state) => state.draft.token
export const getDraftChannelId = (state) => state.draft.channelId
export const getDraftChannelOfferId = (state) => state.draft.channelOfferId
export const getIsDraft = (state) => !!state.draft.order.id
export const getOnlineChannelDraft = (state) => state.draft.onlineChannelDraft
export const getIsOnlineChannelDraft = (state) => !!state.draft.onlineChannelDraft
export const getDuplicateOrderDraft = (state) => state.draft.duplicateOrderDraft
export const getIsDuplicateOrderDraft = (state) => !!state.draft.duplicateOrderDraft

export const getDraftSelectedProductId = (state) =>
  state.draft.order.orderlines?.filter((orderline) => orderline.productVariant !== null)?.productVariant.product.id

export const getDraftSelectedProduct = (state) =>
  state.draft.order.orderlines?.filter((orderline) => orderline.productVariant !== null)

export const getDraftSelectedServices = (state) =>
  state.draft.order.orderlines?.filter((orderline) => orderline.productVariant === null)

export const getDraftBillingAddress = (state) => state.draft.lead.billingAddress

export const getDraftSupplyAddress = (state) =>
  state.draft.order.supplyAddress ||
  state.draft.duplicateOrderDraft?.supplyStep?.electricity?.supplies[0]?.address ||
  state.draft.duplicateOrderDraft?.supplyStep?.gas?.supplies[0]?.address

export const getIsPhoneChannelDraft = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return CHANNEL_TYPES.phone.indexOf(selectedChannel.channel.type) !== -1
}

export const getIsDraftFetching = (state) => {
  const draftRequest = state.lead.request
  return request.getIsRequesting(draftRequest)
}
export const getIsDraftError = (state) => {
  const draftRequest = state.lead.requestDraft
  return request.getIsError(draftRequest)
}

export const getIsFillDraftError = (state) => {
  const draftRequest = state.lead.requestFillDraft
  return request.getIsError(draftRequest)
}

export const getDraftOnlineChannel = (state) => state.getDraftOnlineChannel

export const getIsDraftOnlineChannelFetching = (state) => {
  const draftRequest = state.draft.requestOnlineChannelDraft
  return request.getIsRequesting(draftRequest)
}

export const getIsDraftOnlineChannelError = (state) => {
  const draftRequest = state.lead.requestDraft
  return request.getIsError(draftRequest)
}

export const getIsDuplicateOrderRequesting = (state) => {
  const requestDuplicateOrder = state.draft.requestDuplicateOrder
  return request.getIsRequesting(requestDuplicateOrder)
}

export const getIsRecoveringDuplicateOrder = (state) => {
  const requestRecoverDuplicateOrder = state.draft.requestRecoverDuplicateOrder
  return request.getIsRequesting(requestRecoverDuplicateOrder)
}

export const getIsRecoveringDuplicateOrderSuccess = (state) => {
  const requestRecoverDuplicateOrder = state.draft.requestRecoverDuplicateOrder
  return request.getIsRequestSucceeded(requestRecoverDuplicateOrder)
}
