import { COMMERCIAL } from 'utils/constants'

export const SERVICE = 'CUSTOMER'

export const NOT_TENURE_ERROR = 'tenureNotExists'
export const NOT_CLIENT_ERROR = 'nonexistentClient'
export const NOT_CONTRACT_ERROR = 'nonexistentContract'
export const SERVER_ERROR = 'serverError'

export const INITIAL_FORM_DATA = {
  idDocumentType: '',
  idDocumentNumber: '',
  name: '',
  lastName: '',
  secondLastName: '',
  fullName: '',
  phone: '',
  email: '',
  iban: '',
  entirePartyPolicy: false,
  thirdPartyPolicy: false,
  publicityPolicy: false,
  complexProfilePolicy: false,
  retargeting: false,
  language: '',
  energyUse: COMMERCIAL,
  cnae: '',
  contactName: '',
  contactLastName: '',
  contactSecondLastName: '',
  contactDegree: '',
  contactDocumentNumber: '',
  contactTelephone: '',
  contactMobilePhone: '',
  contactFax: '',
  contactEmail: '',
}

export const EXISTING_CLIENT_CODE = '0000'
export const EXISTING_CLIENT_NO_CONTRACT_CODE = '0001'

export const CHANNEL_RESTRICTED = ['1c46b60a-298d-4dba-8f4e-73047a811b6c', '6c8be876-ec99-4f65-8416-97aa963438b4']
