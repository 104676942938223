import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, DialogContent, Box, TextField } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerData } from 'services/customer/selectors'
import { setCustomer } from 'services/customer/actions'
import useStyles from './styles'

const CompleteEmail = ({ handleClose, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onNoEmail } = data

  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const checkEmailError =
    (email !== confirmEmail && confirmEmail !== '' && email !== '') ||
    (!regex.test(email) && email !== '') ||
    (!regex.test(confirmEmail) && confirmEmail !== '')
  const customerData = useSelector(getCustomerData)
  const dispatch = useDispatch()

  const sendEmail = (email) => {
    dispatch(setCustomer({ ...customerData, email }))
    handleClose()
  }

  const handleNoEmail = () => {
    onNoEmail()
    handleClose()
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('common.completeEmailTittle')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Box class={classes.addEmailInputContainer}>
            <TextField
              label={t('form.fields.email')}
              variant="outlined"
              error={checkEmailError}
              fullWidth
              value={email}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
            />
          </Box>
          <Box class={classes.addEmailInputContainer}>
            <TextField
              label={t('form.fields.confirmEmail')}
              variant="outlined"
              fullWidth
              error={checkEmailError}
              value={confirmEmail}
              onChange={(event) => {
                setConfirmEmail(event.target.value)
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" variant="outlined" onClick={handleNoEmail}>
          {t('common.noEmail')}
        </Button>
        <Button
          color="secondary"
          disabled={checkEmailError || email === '' || confirmEmail === ''}
          variant="contained"
          onClick={() => sendEmail(email)}
        >
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default CompleteEmail
