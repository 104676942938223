import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik, Field } from 'formik'
import { useSnackbar } from 'context/SnackbarContext'

import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core'

import { DialogContent } from '@material-ui/core'

import Typography from 'ui/components/typography'

import {
  ATTACH_FILE_TYPES,
  ATTACH_FILE_AUDIO_TYPES,
  EXTRA_AUDIO_CHANNEL_NAMES,
  ATTACH_FILE_NEW_SUPPLY_TYPES,
  ATTACH_FILE_FOR_PYMES,
} from 'utils/constants'

import { attachDocument, clearAttachDoc } from 'services/order/actions'
import { getIsAttachDocError, getIsAttachDocRequesting } from 'services/order/selectors'

import Alert from 'ui/components/commonAlert'

import useStyles from './styles'

const MAX_FILE_SIZE = 20_971_520 // 20MB
const AUDIO_TYPES = '.mp3'
const DOCUMENT_TYPES = 'application/pdf, image/png, image/jpeg'
const FILE_TYPES = `${AUDIO_TYPES}, ${DOCUMENT_TYPES}`

const AttachFile = ({ handleClose, data: { info } }) => {
  // eslint-disable-next-line no-unused-vars
  const fileEl = useRef(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setSnackMessage } = useSnackbar()

  const [selectedFile, setSelectedFile] = useState(null)

  const isAttachDocRequesting = useSelector(getIsAttachDocRequesting)
  const isAttachDocError = useSelector(getIsAttachDocError)

  const availableTypes = ATTACH_FILE_TYPES.filter(
    ({ value }) => info.isVt || EXTRA_AUDIO_CHANNEL_NAMES.includes(info.channelName) || !ATTACH_FILE_AUDIO_TYPES.includes(value)
  )
    .filter(({ value }) => info.newSupply || !ATTACH_FILE_NEW_SUPPLY_TYPES.includes(value))
    .filter(({ value }) => info.isPyme || !ATTACH_FILE_FOR_PYMES.includes(value))
  useEffect(() => {
    dispatch(clearAttachDoc())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFileAcceptTypes = (type) => {
    if (!type) {
      return FILE_TYPES
    }
    if (ATTACH_FILE_AUDIO_TYPES.includes(type)) {
      return AUDIO_TYPES
    }
    return DOCUMENT_TYPES
  }

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['documentType', 'document']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })

    if (values.document && selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        errors.document = t('form.errors.fileLimit', {
          limit: returnFileSize(MAX_FILE_SIZE),
          value: returnFileSize(selectedFile.size),
        })
      }
      const isAudioTypeIncompatible = ATTACH_FILE_AUDIO_TYPES.includes(values.documentType) && selectedFile.type !== 'audio/mpeg'
      if (isAudioTypeIncompatible) {
        errors.document = t('form.errors.fileTypeIncompatible', { file: selectedFile.name, type: values.type })
      }
    }

    return errors
  }

  const callbackResume = () => {
    handleClose()
    setSnackMessage(t('attachDoc.success'))
  }

  const handleFormSubmit = (formData) => {
    dispatch(
      attachDocument({
        data: {
          type: formData.documentType,
          file: selectedFile,
          orderId: info.orderId,
        },
        callbackResume,
      })
    )
  }

  const returnFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`
    }
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('attachDoc.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('attachDoc.subtitle')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Formik
            initialValues={{
              documentType: '',
              document: '',
            }}
            validate={validateForm}
            onSubmit={handleFormSubmit}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  {/* --- DOC TYPE --- */}
                  <Grid item xs={12} sm={12}>
                    <InputLabel>{t('form.fields.documentType')}</InputLabel>
                    <FormControl fullWidth>
                      <InputLabel id="documentType" error={touched.docummentType && errors.documentType}>
                        {t('form.fields.documentType')}
                      </InputLabel>
                      <Select
                        labelId="documentType"
                        fullWidth
                        value={values.documentType}
                        label={t('form.fields.documentType')}
                        error={touched.docummentType && errors.documentType}
                        onChange={handleChange('documentType')}
                        disabled={availableTypes.length === 0}
                      >
                        {availableTypes?.map(({ label, value }) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {touched.docummentType && errors.documentType ? (
                      <FormHelperText error>{errors.documentType}</FormHelperText>
                    ) : null}
                  </Grid>

                  {/* --- DOC --- */}
                  <Grid item xs={12} sm={9}>
                    <InputLabel>{t('form.fields.document')}</InputLabel>
                    <FormControl fullWidth>
                      <Field
                        component={MuiTextField}
                        required
                        readonly
                        disabled
                        fullWidth
                        error={!!(selectedFile && errors.document)}
                        id="document"
                        name="document"
                        label={t('form.fields.document')}
                        value={values.document}
                        onClick={() => fileEl.current.click()}
                      />
                    </FormControl>
                    {selectedFile && errors.document ? <FormHelperText error>{errors.document}</FormHelperText> : null}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.examine}
                      onClick={() => fileEl.current.click()}
                    >
                      {t('common.examine')}
                      <input
                        ref={fileEl}
                        accept={getFileAcceptTypes(values.documentType)}
                        id="documentFile"
                        name="documentFile"
                        type="file"
                        hidden
                        onChange={(e) => {
                          const [file] = e.target.files
                          setSelectedFile(file)
                          handleChange('document')(file.name)
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Box className={classes.actions} mt={4}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={isAttachDocRequesting || !values.documentType || !values.document}
                    >
                      {t('common.continue')}
                    </Button>
                  </Box>
                </Box>
                {isAttachDocError && <Alert severity="warning" title={t('errorLead')} />}
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </>
  )
}

export default AttachFile
