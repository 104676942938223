import React from 'react'
import { history } from 'redux/store'

import ForbiddenView from '../ui/views/forbidden'

const Forbidden = () => {
  const onRedirect = () => history.push('/')

  return <ForbiddenView redirect={onRedirect} />
}

export default Forbidden
