import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box } from '@material-ui/core'

const WarningModal = ({ data }) => {
  const { titleKey, textKey } = data
  const { t } = useTranslation()

  return (
    <Box px={5} pb={6}>
      <Box mb={3} textAlign="left">
        {titleKey && (
          <Typography variant="h3" color="primary">
            {t(titleKey)}
          </Typography>
        )}
      </Box>
      <Box textAlign="left">{textKey && <Typography>{t(textKey)}</Typography>}</Box>
    </Box>
  )
}

export default WarningModal
