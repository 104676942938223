import { all, takeLatest, call, put } from 'redux-saga/effects'

import { setCompanies } from './actions'
import * as actionTypes from './action-types'

import { getAllCompanies } from './api'

function* fetchCompaniesSaga() {
  try {
    const allCompanies = yield call(getAllCompanies) || []

    yield put(setCompanies(allCompanies))
  } catch (e) {
    console.error(e)
  }
}

export default function* rootSaga() {
  yield all([takeLatest([actionTypes.FETCH_COMPANIES], fetchCompaniesSaga)])
}
