import { request } from 'redukers'
import { TEMPLATE_TYPES } from 'constants/settings'

export const getTemplateFieldsByType = (templateType) => (state) => {
  switch (templateType) {
    case TEMPLATE_TYPES.orderExport:
      return state.backofficeTemplates.exportOrdersTemplateFields

    case TEMPLATE_TYPES.orderView:
      return state.backofficeTemplates.viewOrdersTemplateFields

    default:
      break
  }
}

export const getIsFetchTemplateFieldsRequesting = (state) => {
  const fetchTemplateFields = state.backofficeTemplates.fetchTemplateFieldsRequest
  return request.getIsRequesting(fetchTemplateFields)
}
