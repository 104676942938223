import { CHANNEL_TYPES } from 'utils/constants'
import { isAdminUser } from './business'

export const getOperator = (state) => state.operator
export const getOperatorAuth = (state) => state.operator.auth0
export const getIsAdmin = (state) => state.operator.user?.roles.includes('ROLE_ADMIN')
export const getIsSuperAdmin = (state) =>
  state.operator.user?.roles.includes('ROLE_ADMIN') && isAdminUser(state.operator.user?.externalId)
export const getIsSalesAgent = (state) => state.operator.user?.roles.includes('ROLE_SALES_AGENT')
export const getIsJustSalesAgent = (state) =>
  state.operator.user?.roles?.filter((role) => role !== 'ROLE_USER').length === 1 && getIsSalesAgent(state)
export const getIsUser = (state) => state.operator.user?.roles.includes('ROLE_USER')
export const getIsJustUser = (state) => state.operator.user?.roles?.length === 1 && getIsUser(state)
export const getIsManager = (state) => state.operator.user?.roles.includes('ROLE_MANAGER')
export const getIsRoleChannel = (state) => state.operator.user?.roles.includes('ROLE_CHANNEL')
export const getIsDocoutFailed = (state) => state.operator.user?.roles.includes('ROLE_FAILED_MGMT')
export const getIsPortability = (state) => state.operator.user?.roles.includes('ROLE_PORTABILITY')

export const getOperatorCompanies = (state) => state.operator.companies
export const getSelectedChannel = (state) =>
  state.operator.channels && state.operator.channels.selected ? state.operator.channels.selected : null
export const getUserChannelOffers = (state) => state.operator.user?.channelOffers || []
export const getRootChannels = (state) =>
  state.operator.channels.all?.length
    ? state.operator.channels.all.map(({ channel }) => channel).filter((v, i, a) => a.indexOf(a.find((c) => c.id === v.id)) === i)
    : []
export const getChannels = (state) =>
  state.operator.channels.all?.length
    ? state.operator.channels.all.map(({ id, channel, name }) => ({
        id,
        channelId: channel.id,
        channelName: channel.name,
        name,
        type: channel.type,
        tenure: channel.tenure,
      }))
    : []

export const getAgencyCodes = (state) =>
  state.operator.channels.all?.length
    ? state.operator.channels.all.map(({ channel: { agencyCode } }) => agencyCode).filter((code) => !!code)
    : []

export const getIsVtOutboundChannelType = (state) => state.operator.channels.selected.name.includes('VT')

export const getIsAdminOnlyInCustomerSupport = (state) => {
  const userChannelOffers = getUserChannelOffers(state)
  if (Object.keys(userChannelOffers).length === 0) return false
  return userChannelOffers.every((channel) => channel.name === 'Atención al cliente') && getIsAdmin(state)
}

export const getReferenceSegment = (state) => state.operator.referenceSegment
export const getUser = (state) => state.operator.user

export const getIsPhoneChannel = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return CHANNEL_TYPES.phone.indexOf(selectedChannel.type) !== -1
}

export const getIsServiceHomeChannel = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return CHANNEL_TYPES.serviceHome.indexOf(selectedChannel.type) !== -1
}

export const getIsOnlineChannel = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return CHANNEL_TYPES.web.indexOf(selectedChannel.type) !== -1
}

export const getIsFaceToFaceChannel = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return CHANNEL_TYPES.faceToFace.indexOf(selectedChannel.type) !== -1
}

export const getIsChannelWithTenure = (state) => {
  const selectedChannel = getSelectedChannel(state)
  return selectedChannel.tenure
}

export const getIsResetFunnelContract = (state) => state.operator.isResetFunnelContract
