import { get, post, put } from '../client'
import isEqual from 'lodash.isequal'

let cacheLeadDataRequest = {}
let cacheLeadDataResponse = {}

const removeUselessDates = (lead) => {
  const newLead = { ...lead }
  newLead.orders = newLead.orders?.map((order) => ({ ...order, priceReservationDate: order?.priceReservationDate.split('T')[0] }))

  return newLead
}

const getCacheLeadData = (data, id) => {
  const isCached = isEqual(removeUselessDates({ ...data, __id: id }), cacheLeadDataRequest)

  if (isCached) {
    return cacheLeadDataResponse
  }
}

const setCacheLeadData = (data, id, response) => {
  cacheLeadDataRequest = removeUselessDates({ ...data, __id: id })
  cacheLeadDataResponse = response
}

const sendLead = async (lead, leadId, cache = true) => {
  const cacheLeadData = cache && leadId && getCacheLeadData(lead, leadId)

  if (cacheLeadData) {
    return cacheLeadData
  }

  if (!leadId) {
    lead.orders = lead.orders?.map(({ supplyAddress, orderLines, ...order }) =>
      supplyAddress?.externalId && supplyAddress?.horizontalId && supplyAddress?.verticalId && supplyAddress?.postalCode
        ? { supplyAddress, orderLines, ...order }
        : order
    )
  }

  const response = leadId ? await put(`leads/${leadId}`, lead, true) : await post('leads', lead, true)

  if (cache) {
    setCacheLeadData(lead, leadId || response?.id, response)
  }

  return response
}

const getLead = async (leadId) => await get(`leads/${leadId}`)

export { sendLead, getLead }
