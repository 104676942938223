import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  TextField as MuiTextField,
  SvgIcon,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ReactComponent as IconNotepad } from '@zatopek/core/src/assets/icons/spiral-notepad.svg'
import Typography from 'ui/components/typography'
import useStyles from './styles'

import {
  DEFAULT_TYPE_SUBSECTION,
  WITOUT_SELFCONSUMPTION,
  WITHOUT_SURPLUS,
  WITHOUT_SURPLUS_WITHOUT_COMPENSATION,
  COLLECTIVE,
} from 'services/selfconsumption/constants'
import { getValidTypes, getCupsTypes, getIsDisallowed } from 'services/selfconsumption/selectors'
import { getIsNewSupply, getCurrentSubsection, getSelfconsumptionTypes } from 'services/customer/selectors'
import { setInitialSelfconsumptionType } from 'services/customer/actions'
import { useState, useMemo } from 'react'

const SelfconsumptionForm = ({ cups, supplyPoint, selfconsumptionFormRef, index }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const selfConsumptionTypes = useSelector(getValidTypes)
  const cupsTypes = useSelector(getCupsTypes)
  const isNewSupply = useSelector(getIsNewSupply)
  const currentSubsection = useSelector(getCurrentSubsection)
  const selfconsumptionTypes = useSelector(getSelfconsumptionTypes)
  const isDisallowed = useSelector(getIsDisallowed)

  const selectedPointIndex = useMemo(() => {
    return supplyPoint && supplyPoint.length && supplyPoint?.findIndex((point) => point.cups === cups)
  }, [supplyPoint, cups])
  const selectedPointVariable = useMemo(() => {
    return supplyPoint && { ...supplyPoint[selectedPointIndex] }
  }, [supplyPoint, selectedPointIndex])
  const [selectedSelfConsumptionType, setSelectedSelfConsumptionType] = useState(
    selfconsumptionTypes?.find(({ code }) => code === selectedPointVariable?.selfConsumptionType) || {}
  )
  const [initialSelectedSelfConsumptionType, setInitialSelectedSelfConsumptionType] = useState(selectedSelfConsumptionType)
  const [initialType, setInitialType] = useState(WITOUT_SELFCONSUMPTION)

  const isSelfconsumptionTypeChange = initialType !== (selectedPointVariable?.selfConsumptionType ?? WITOUT_SELFCONSUMPTION)

  const collectiveFromType = selectedSelfConsumptionType?.collective
  const subsections = selectedSelfConsumptionType?.selfConsumptionSubsectionType ?? []
  const initialValues = {
    selfConsumptionType: selectedPointVariable?.selfConsumptionType || WITOUT_SELFCONSUMPTION,
    selfConsumptionCollective: Boolean(
      collectiveFromType ? collectiveFromType === COLLECTIVE : selectedPointVariable?.selfConsumptionCollective
    ),
    selfConsumptionSubsectionType: selectedPointVariable?.selfConsumptionSubsectionType || null,
    selfConsumptionRegister:
      ((selectedPointVariable?.selfConsumptionRegister || isNewSupply || isSelfconsumptionTypeChange) &&
        selectedSelfConsumptionType?.valid) ||
      false,
    selfConsumptionPowerInstalled: selectedPointVariable?.selfConsumptionPowerInstalled ?? 0,
    selfConsumptionCupsType: selectedPointVariable?.selfConsumptionCupsType || '',
  }

  useEffect(() => {
    setInitialType(supplyPoint?.[selectedPointIndex]?.selfConsumptionType || WITOUT_SELFCONSUMPTION)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyPoint])

  useEffect(() => {
    selfconsumptionFormRef.current[index]?.resetForm()
    setInitialSelectedSelfConsumptionType(selectedSelfConsumptionType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPointVariable?.supplyAddress])

  useEffect(() => {
    dispatch(setInitialSelfconsumptionType(initialSelectedSelfConsumptionType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectedSelfConsumptionType])

  const storeValueSelfconsumptionByKey = (newValue, key) => {
    if (supplyPoint) {
      selectedPointVariable[key] = newValue
      if (key === 'selfConsumptionType') {
        const selConsTypeWithSameCode = selfconsumptionTypes?.find(
          ({ code }) => code === selectedPointVariable?.selfConsumptionType
        )
        setSelectedSelfConsumptionType(selConsTypeWithSameCode)
      }
    }
  }

  const storeValueInstalledPower = (newValue) => {
    if (newValue !== '' && validateInstalledPower(newValue)) return
    if (supplyPoint) {
      selectedPointVariable.selfConsumptionPowerInstalled = newValue
    }
  }

  const validateInstalledPower = (value) => {
    let error
    if (!value) {
      error = 'Required'
    } else if (value.length > 14) {
      error = 'Invalid decimal (max 14 char)'
    } else if (!/^[0-9]+,?[0-9]{0,2}$/.test(value)) {
      error = 'Invalid decimal'
    }

    return error
  }

  const validatePower = (values) => {
    const { selfConsumptionType, selfConsumptionPowerInstalled } = values
    if ((selfConsumptionType === '11' || selfConsumptionType === '12') && selfConsumptionPowerInstalled < 1) {
      return t('form.errors.installedPowerCupsType')
    }
    return ''
  }

  return (
    <>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h5" color="primary">
            {t('common.selfconsumption')}
          </Typography>
        </Box>

        {!isDisallowed ? (
          <Box mb={2}>
            <Alert
              className={classes.centered}
              icon={<SvgIcon component={IconNotepad} viewBox="0 0 40 40" fontSize="large" />}
              severity="warning"
            >
              <Typography variant="basicBig">
                <span dangerouslySetInnerHTML={{ __html: t('selfConsumptionRecommendation') }}></span>
              </Typography>
            </Alert>
          </Box>
        ) : null}

        <Formik initialValues={initialValues} enableReinitialize innerRef={(el) => (selfconsumptionFormRef.current[index] = el)}>
          {({ handleChange, values, touched, errors, resetForm }) => (
            <Grid container spacing={3}>
              {/* --- CONSUMPTION TYPE --- */}
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth disabled={isDisallowed}>
                  <InputLabel htmlFor="selfConsumptionType">{t('form.fields.selfConsumptionType')}</InputLabel>
                  <Field
                    component={Select}
                    native
                    id="selfConsumptionType"
                    name="selfConsumptionType"
                    label={t('form.fields.selfConsumptionType')}
                    value={selectedPointVariable?.selfConsumptionType || values.selfConsumptionType}
                    onChange={(element) => {
                      handleChange(element)
                      storeValueSelfconsumptionByKey(element.target.value, 'selfConsumptionType')
                    }}
                  >
                    {!initialSelectedSelfConsumptionType?.valid && (
                      <option
                        selected={initialSelectedSelfConsumptionType?.code === selectedSelfConsumptionType?.code}
                        aria-label="None"
                        value={initialSelectedSelfConsumptionType?.code}
                      />
                    )}
                    {Object.values(selfConsumptionTypes).map(({ code, description }) => {
                      return (
                        <option
                          key={code}
                          value={code}
                          selected={
                            code === selectedSelfConsumptionType?.code &&
                            initialSelectedSelfConsumptionType?.code !== selectedSelfConsumptionType?.code
                          }
                        >
                          {description}
                        </option>
                      )
                    })}
                  </Field>
                </FormControl>
              </Grid>
              {/* --- COLLECTIVE --- */}
              {values.selfConsumptionType !== WITOUT_SELFCONSUMPTION && selectedSelfConsumptionType?.valid ? (
                <Grid item xs={12} sm={subsections?.length > 0 ? 6 : 12}>
                  <FormControlLabel
                    name="selfConsumptionCollective"
                    control={
                      collectiveFromType !== '' || values?.selfConsumptionType === WITHOUT_SURPLUS ? (
                        <Checkbox
                          size="small"
                          checked={
                            values.selfConsumptionCollective ||
                            (values?.selfConsumptionType === WITHOUT_SURPLUS &&
                              values?.selfConsumptionSubsectionType !== WITHOUT_SURPLUS_WITHOUT_COMPENSATION)
                          }
                          disabled
                        />
                      ) : (
                        <Checkbox size="small" checked={values.selfConsumptionCollective} disabled={isDisallowed} />
                      )
                    }
                    onChange={(element, newValue) => {
                      handleChange(element)
                      storeValueSelfconsumptionByKey(newValue, 'selfConsumptionCollective')
                    }}
                    label={<Typography className={classes.collective}>{t('form.fields.collective')}</Typography>}
                  />
                </Grid>
              ) : null}

              {/* --- SUBSECTION --- */}
              {subsections?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  sm={
                    selectedSelfConsumptionType?.code !== WITOUT_SELFCONSUMPTION && selectedSelfConsumptionType?.collective === ''
                      ? 6
                      : 12
                  }
                >
                  <FormControl fullWidth disabled={isDisallowed}>
                    <InputLabel htmlFor="selfConsumptionSubsectionType">{t('form.fields.subsection')}</InputLabel>
                    <Field
                      component={Select}
                      native
                      id="selfConsumptionSubsectionType"
                      name="selfConsumptionSubsectionType"
                      label={t('form.fields.subsection')}
                      value={selectedPointVariable?.selfConsumptionSubsectionType || values.selfConsumptionSubsectionType}
                      onChange={(element) => {
                        handleChange(element)
                        storeValueSelfconsumptionByKey(element.target.value, 'selfConsumptionSubsectionType')
                      }}
                    >
                      {subsections.map(({ code, description }) => (
                        <option
                          key={code}
                          value={code}
                          selected={
                            code ===
                            (currentSubsection ?? DEFAULT_TYPE_SUBSECTION[currentSubsection || selectedSelfConsumptionType?.code])
                          }
                        >
                          {description}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              ) : null}

              {values.selfConsumptionType !== '00' && selectedSelfConsumptionType?.valid ? (
                <>
                  {/* --- INSTALLED POWER --- */}
                  <Grid
                    item
                    xs={12}
                    sm={
                      ((selectedPointVariable?.selfConsumptionPowerInstalled || values.selfConsumptionPowerInstalled) &&
                        parseInt(selectedPointVariable?.selfConsumptionPowerInstalled || values.selfConsumptionPowerInstalled) >=
                          100) ||
                      (selectedPointVariable?.selfConsumptionPowerInstalled &&
                        selectedPointVariable?.selfConsumptionPowerInstalled >= 100)
                        ? 6
                        : 12
                    }
                  >
                    <Field
                      component={MuiTextField}
                      fullWidth
                      value={selectedPointVariable?.selfConsumptionPowerInstalled || values.selfConsumptionPowerInstalled}
                      variant="outlined"
                      id="selfConsumptionPowerInstalled"
                      label={t('form.fields.installedPower')}
                      name="selfConsumptionPowerInstalled"
                      required={isNewSupply}
                      onChange={(element) => {
                        if (!element.target.value.includes('-')) {
                          handleChange(element)
                          storeValueInstalledPower(element.target.value)
                        }
                      }}
                      InputProps={{ inputProps: { maxLength: 14, min: 0 } }}
                      disabled={isDisallowed}
                      helperText={validatePower(values)}
                      error={validatePower(values)}
                      FormHelperTextProps={{ classes: classes }}
                    />
                  </Grid>

                  {/* --- CUPS TYPE --- */}
                  {(parseInt(selectedPointVariable?.selfConsumptionPowerInstalled || values.selfConsumptionPowerInstalled) >=
                    100 &&
                    values.selfConsumptionType !== '00') ||
                  (selectedPointVariable?.selfConsumptionPowerInstalled >= 100 &&
                    selectedPointVariable?.selfConsumptionCupsType !== '00') ? (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth disabled={isDisallowed}>
                        <InputLabel htmlFor="selfConsumptionCupsType">{t('form.fields.cupsType')}</InputLabel>
                        <Field
                          component={Select}
                          native
                          id="selfConsumptionCupsType"
                          name="selfConsumptionCupsType"
                          label={t('form.fields.cupsType')}
                          value={selectedPointVariable?.selfConsumptionCupsType || values.selfConsumptionCupsType}
                          onChange={(element) => {
                            handleChange(element)
                            storeValueSelfconsumptionByKey(element.target.value, 'selfConsumptionCupsType')
                          }}
                        >
                          {Object.values(cupsTypes).map(({ code, description }) => (
                            <option key={code} value={code} selected={code === selectedSelfConsumptionType?.code}>
                              {description}
                            </option>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  ) : null}
                </>
              ) : null}

              {/* --- SIGN UP --- */}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  name="selfConsumptionRegister"
                  control={
                    isNewSupply || isSelfconsumptionTypeChange || values.selfConsumptionType === WITOUT_SELFCONSUMPTION ? (
                      <Checkbox
                        size="small"
                        checked={
                          (values.selfConsumptionType !== WITOUT_SELFCONSUMPTION || isSelfconsumptionTypeChange) &&
                          values.selfConsumptionRegister &&
                          selectedSelfConsumptionType?.valid
                        }
                        disabled
                      />
                    ) : (
                      <Checkbox size="small" checked={values.selfConsumptionRegister} disabled={isDisallowed} />
                    )
                  }
                  onChange={(element, newValue) => {
                    handleChange(element)
                    storeValueSelfconsumptionByKey(newValue, 'selfConsumptionRegister')
                  }}
                  label={
                    <Typography className={classes.signUpForSelfConsumption}>
                      {t('form.fields.signUpForSelfConsumption')}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default SelfconsumptionForm
