const getDraftOnlineChannelDataForOwnerStep = (customer) => {
  return {
    documentType: customer?.data?.idDocumentType || null,
    documentNumber: customer?.data?.idDocumentNumber || null,
    firstName: customer?.data?.name || null,
    lastName: customer?.data?.lastName || null,
    secondLastName: customer?.data?.secondLastName || null,
    email: customer?.data?.email || null,
    phone: customer?.data?.phone || null,
    entirePartyPolicy: customer?.data?.entirePartyPolicy || false,
    checkGDPR1: customer?.data?.entirePartyPolicy || customer?.data?.complexProfilePolicy || false,
    checkGDPR2: customer?.data?.entirePartyPolicy || customer?.data?.publicityPolicy || false,
    checkGDPR3: customer?.data?.entirePartyPolicy || customer?.data?.thirdPartyPolicy || false,
    retargeting: customer?.data?.retargeting || false,
    preferredLanguage: customer?.data?.language || null,
    cnae: customer?.data?.cnae || null,
    energyUse: customer?.data?.energyUse || null,
    contact: {
      id: customer?.onBehalfContactId || null,
      contactName: customer?.data?.contactName || null,
      contactLastName: customer?.data?.contactLastName || null,
      contactSecondLastName: customer?.data?.contactSecondLastName || null,
      contactDegree: customer?.data?.contactDegree || null,
      contactDocumentNumber: customer?.data?.contactDocumentNumber || null,
      contactTelephone: customer?.data?.contactTelephone || null,
      contactMobilePhone: customer?.data?.contactMobilePhone || null,
      contactFax: customer?.data?.contactFax || null,
      contactEmail: customer?.data?.contactEmail || null,
    },
    supplyOwnership: [
      {
        changeSupplyOwnership: customer?.changeSupplyOwnership?.['changeSupplyOwnership-electricity'] || false,
        energyType: 'ELECTRICITY',
      },
      { changeSupplyOwnership: customer?.changeSupplyOwnership?.['changeSupplyOwnership-gas'] || false, energyType: 'GAS' },
    ],
  }
}

const getDraftOnlineChannelDataForRateStep = (onlineChannelParams, energyType) => {
  const productRateStep = () => {
    if (onlineChannelParams && onlineChannelParams?.sel) {
      return onlineChannelParams.sel.split('-')
    }
    return null
  }

  return {
    energyType: energyType || null,
    product: productRateStep(),
  }
}

const getDraftOnlineChannelDataForServiceStep = (toppingsSelected, isOnlyToppings) => {
  const externalCodeTopppingsSelected = []
  if (toppingsSelected) {
    Object.entries(toppingsSelected).forEach(([key, value]) => {
      externalCodeTopppingsSelected.push(value.col)
    })
  }

  return {
    services: externalCodeTopppingsSelected,
    isOnlyToppings,
  }
}

const getDraftOnlineChannelDataForSupplyStep = (supplyPoints, isNewSupply, supplyAddress, order) => {
  const supplyStep = {}

  if (supplyPoints) {
    supplyPoints.forEach(function (element) {
      if (element.energyType) {
        const energyKey = element.energyType.toLowerCase()

        if (!supplyStep[energyKey]) {
          supplyStep[energyKey] = {}
          supplyStep[energyKey].nnss = isNewSupply || false // TODO
          supplyStep[energyKey].supplies = []
        }

        const supplyElement = {}
        const orderSupplyPoint =
          order && order?.orders && element.cups ? order.orders.find((order) => order.cups === element.cups) : null
        supplyElement.orderId = orderSupplyPoint && orderSupplyPoint?.id ? orderSupplyPoint?.id : null
        supplyElement.technicalInfo = {}
        supplyElement.technicalInfo.accessTariff = element?.accessTariff || null
        supplyElement.technicalInfo.capacityP1 = element?.capacityP1 || null
        supplyElement.technicalInfo.capacityP2 = element?.capacityP2 || null
        supplyElement.technicalInfo.capacityP3 = element?.capacityP3 || null
        supplyElement.technicalInfo.capacityP4 = element?.capacityP4 || null
        supplyElement.technicalInfo.capacityP5 = element?.capacityP5 || null
        supplyElement.technicalInfo.capacityP6 = element?.capacityP6 || null
        supplyElement.technicalInfo.consumption12Months = element?.consumption12Months || null
        supplyElement.technicalInfo.cups = element?.cups || null
        supplyElement.technicalInfo.energyType = element?.energyType || null
        supplyElement.technicalInfo.iriCertificateDate = element?.iriCertificateDate || null
        supplyElement.technicalInfo.installerCode = element?.installerCode || null
        supplyElement.technicalInfo.equipmentOwnershipType = element?.equipmentOwnershipType || null
        supplyElement.technicalInfo.designCapacity = element?.designCapacity || null
        supplyElement.technicalInfo.maxCapacity = element?.maxCapacity || null
        supplyElement.technicalInfo.distributor = element?.distributor || null
        supplyElement.technicalInfo.activationDate = element?.activationDate || null
        supplyElement.technicalInfo.postalCode = element?.supplyAddress?.postalCode || supplyAddress?.postalCode || null
        supplyElement.technicalInfo.provinceName = element?.supplyAddress?.provinceName || supplyAddress?.provinceName || null
        supplyElement.technicalInfo.municipalityName =
          element?.supplyAddress?.municipalityName || supplyAddress?.municipalityName || null
        supplyElement.technicalInfo.addressLine = element?.supplyAddress?.addressLine || supplyAddress?.addressLine || null

        supplyElement.address = {}
        supplyElement.address.tenureAddressId = element?.supplyAddress?.tenureAddressId || supplyAddress?.tenureAddressId || null
        supplyElement.address.addressLine = element?.supplyAddress?.addressLine || supplyAddress?.addressLine || null
        supplyElement.address.externalId = element?.supplyAddress?.externalId || supplyAddress?.externalId || null
        supplyElement.address.postalCode = element?.supplyAddress?.postalCode || supplyAddress?.postalCode || null
        supplyElement.address.provinceName = element?.supplyAddress?.provinceName || supplyAddress?.provinceName || null
        supplyElement.address.municipalityName =
          element?.supplyAddress?.municipalityName || supplyAddress?.municipalityName || null
        supplyElement.address.townName = element?.supplyAddress?.townName || supplyAddress?.townName || null
        supplyElement.address.streetType = element?.supplyAddress?.streetType || supplyAddress?.streetType || null
        supplyElement.address.streetName = element?.supplyAddress?.streetName || supplyAddress?.streetName || null
        supplyElement.address.number = element?.supplyAddress?.number || supplyAddress?.number || null
        supplyElement.address.floor = element?.supplyAddress?.floor || supplyAddress?.floor || null
        supplyElement.address.door = element?.supplyAddress?.door || supplyAddress?.door || null

        supplyStep[energyKey].supplies.push(supplyElement)
      }
    })
  }

  return supplyStep
}

const getDraftOnlineChannelDataForBillingStep = (customer, productAttributes) => {
  return {
    accountHolder: customer?.billingAccount?.accountHolder || null,
    accountHolderSurnames: customer?.billingAccount?.accountHolderSurnames || null,
    accountIban: customer?.billingAccount?.accountIban || null,
    accountHolderLastName: customer?.billingAccount?.accountHolderLastName || null,
    accountHolderSecondLastName: customer?.billingAccount?.accountHolderSecondLastName || null,
    accountHolderDocumentType: customer?.billingAccount?.accountHolderDocumentType || null,
    accountHolderDocumentNumber: customer?.billingAccount?.accountHolderDocumentNumber || null,
    onlineInvoice: customer?.onlineInvoice || null,
    eco: productAttributes ? productAttributes?.['product-eco'] : null,
    spfService: customer?.birthdayDate ? true : false,
    spfConditions: customer.birthdayDate ? true : false,
    spfBirthdayDate: customer?.birthdayDate,
    addressDifferentCheck: customer?.isChangeBillingAddress,
    address:
      !customer || !customer?.billingAddress
        ? null
        : {
            tenureAddressId: customer?.billingAddress?.tenureAddressId || null,
            addressLine: customer?.billingAddress?.addressLine || null,
            externalId: customer?.billingAddress?.externalId || null,
            postalCode: customer?.billingAddress?.postalCode || null,
            provinceName: customer?.billingAddress?.provinceName || null,
            municipalityName: customer?.billingAddress?.municipalityName || null,
            townName: customer?.billingAddress?.townName || null,
            streetType: customer?.billingAddress?.streetType || null,
            streetName: customer?.billingAddress?.streetName || null,
            number: customer?.billingAddress?.number || null,
            floor: customer?.billingAddress?.floor || null,
            door: customer?.billingAddress?.door || null,
          },
  }
}

export const getDraftOnlineChannelData = (draftInfo, steps) => {
  const energyType = draftInfo?.product?.selectedRate[0]?.energyType
  const incompleteOrderData = {
    id: draftInfo?.leadId || null,
    crmId: draftInfo?.order?.CRMId || null,
    orderId: draftInfo?.order?.orders?.length ? draftInfo?.order?.orders[0]?.id : draftInfo?.order?.orders || null,
    metadata: draftInfo?.onlineChannelParams || null,
    ownerStep:
      steps?.isOwnerStepCompleted && draftInfo.customer ? getDraftOnlineChannelDataForOwnerStep(draftInfo.customer) : null,
    rateStep: steps?.isRateStepCompleted
      ? getDraftOnlineChannelDataForRateStep(draftInfo?.onlineChannelParams, energyType)
      : null,
    supplyStep:
      steps?.isSupplyStepCompleted && draftInfo.customer
        ? getDraftOnlineChannelDataForSupplyStep(
            draftInfo.customer?.supplyPoints,
            draftInfo.customer?.isNewSupply,
            draftInfo.customer?.supplyAddress,
            draftInfo?.order
          )
        : null,
    serviceStep: steps?.isServiceStepCompleted
      ? getDraftOnlineChannelDataForServiceStep(draftInfo?.toppingsSelected, draftInfo?.product?.isOnlyToppings)
      : null,
    billingStep:
      steps?.isBillingStepCompleted && draftInfo.customer
        ? getDraftOnlineChannelDataForBillingStep(draftInfo.customer, draftInfo?.product?.attributes)
        : null,
  }

  const draftData = {
    lead: `/middleware/leads/${draftInfo.leadId}`,
    data: incompleteOrderData,
    active: true,
    type_draft: draftInfo.customer?.autosaveCustomerDraft || false,
    type_retargeting: draftInfo.customer?.data?.retargeting || false,
    return: draftInfo.url,
  }

  return draftData
}
