import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, DialogContent } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const NewSupply = ({ children }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <DialogContent className={classes.dialogContent}>
      <Box mb={2}>
        <Typography variant="h3" color="primary">
          {t('newSupplyPoint.moreData')}
        </Typography>
      </Box>
      {children}
    </DialogContent>
  )
}

export default NewSupply
