export const formatAddress = (data) => {
  const number = data.number || ''
  const portal = data.portal || ''
  const stair = data.stair || ''
  const floor = data.floor || ''
  const door = data.door || ''
  const extraData = data.extraData || ''
  const roadTypeName = data.roadTypeName || data.streetType || ''

  const completeAddress = data.streetName
    ? `${roadTypeName} ${data.streetName}, ${number} ${portal} ${stair} ${floor} ${door} ${extraData}`
    : ''

  return completeAddress
}
