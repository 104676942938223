import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Box, Collapse, Link } from '@material-ui/core'
import Typography from '../typography'
import CheckCircle from '@material-ui/icons/CheckCircle'

import CardContent from '../cardContent'

import useStyles from './styles'
import isEmptyObject from '../../../utils/isEmptyObject'
import { PYME } from '../../../utils/constants'

const SupplyCard = ({
  type,
  isCompleted,
  children,
  collapsed,
  onEdit,
  data,
  secondaryData,
  normalizedAddress,
  openCupsModal,
  hide,
  isTenure,
  isMultiPoint,
  onRemoveSupplyPoint,
  cups,
  supplyPoint,
  referenceSegment,
  company,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const selectedSupplyPoint = supplyPoint && supplyPoint.find((supply) => supply.cups === cups)

  const showCardContent = isCompleted && !hide && data && !isEmptyObject(data)

  const handleEditClick = () => {
    onEdit(type)
  }

  return (
    <Paper className={classes[hide ? 'paperHide' : 'paperShow']}>
      {!hide && (
        <Box mb={2} className={classes.labelText}>
          {isCompleted && <CheckCircle className={classes.check} />}
          {!isCompleted && (
            <Box display="inline-block" width={32}>
              <Typography variant="h5" color="primary">
                {type === 'electricity' || type === 'both' ? 1 : 2}.
              </Typography>
            </Box>
          )}
          <Typography variant="h5" color="primary">
            {t('addressType', { type: t(`common.${type}`) })}
          </Typography>

          {isMultiPoint && supplyPoint && supplyPoint.length >= 2 && supplyPoint.every(({ cups }) => !!cups) && (
            <Box display="flex" justifyContent="flex-end" ml="auto">
              <Box mr={1.5}>
                <Link component="button" color="primary" onClick={handleEditClick}>
                  <Typography variant="link" color="primary" component="span">
                    {t('common.edit')}
                  </Typography>
                </Link>
              </Box>
              <Box ml={1.5}>
                <Link component="button" color="primary" onClick={() => onRemoveSupplyPoint(cups)}>
                  <Typography variant="link" color="primary" component="span">
                    {t('common.delete')}
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      )}

      <CardContent
        showCardContent={showCardContent}
        data={
          referenceSegment === PYME && selectedSupplyPoint && isMultiPoint
            ? { ...selectedSupplyPoint.supplyAddress, cups: selectedSupplyPoint.cups }
            : data
        }
        secondaryData={secondaryData}
        normalizedAddress={normalizedAddress}
        openCupsModal={openCupsModal}
        onEditClick={handleEditClick}
        supplyPoint={supplyPoint}
        isTenure={isTenure}
        isMultiPoint={isMultiPoint}
        company={company}
        type={type}
      />

      <Collapse in={!collapsed || hide} timeout="auto">
        {children}
      </Collapse>
    </Paper>
  )
}

export default SupplyCard
