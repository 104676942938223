import React from 'react'
import clsx from 'clsx'
import { Box, Paper, Radio } from '@material-ui/core'

import useStyles from './styles'

const SelectCard = ({ id, isSelected, onChange, children, disableSupplysPoints }) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.root, isSelected && classes.selected, disableSupplysPoints && classes.disabled)}
      onClick={onChange}
    >
      <Box className={classes.content}>
        <Box className={clsx(classes.radioContainer)}>
          <Radio color="primary" disabled={disableSupplysPoints} checked={isSelected} value={id} name="radio-rate" />
        </Box>
        <Box>{children}</Box>
      </Box>
    </Paper>
  )
}

export default SelectCard
