import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'

import { Box, Button, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { validateAnnualConsumption, validateActivationDate, validateAlphanumeric } from 'utils/validators'
import { formatNumber } from 'utils/commons'

import NewSupplyView from 'ui/modals/newSupply'
import Typography from 'ui/components/typography'
import { sendGAEvent } from 'services/analytics/actions'
import GasNewSupply from 'ui/forms/gasNewSupply'

import {
  CUSTOM_EQUIPMENT_OWNERSHIP,
  IDE,
  UFD,
  PLANNED_DISCHARGE_DATE,
  COMPANY_CODES,
  GA_EVENT_NAMES,
  STEPS,
} from 'utils/constants'
import ElectricityNewSupply from 'ui/forms/electricityNewSupply'
import { ELECTRICITY, GAS } from '@zatopek/core/src/utils/constants'
import { getIsNewSupply, getCompany, getAddresDualAlert } from 'services/customer/selectors'
import { getReferenceSegment } from 'services/operator/selectors'
import { MODAL_TYPES } from 'services/modal/constants'
import { showModal } from 'services/modal/actions'
import { setIsCIERequired, setRecordUFD } from 'services/customer/actions'
import { getSelectedRate } from 'services/products/selectors'
import Loader from '@zatopek/core/src/ui/components/loader'

const NewSupplyTechnical = ({ handleClose, data: { onSubmit, ownerModal, service, tariffData, technicalInfo, address } }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const newSupply = useSelector(getIsNewSupply)
  const referenceSegment = useSelector(getReferenceSegment)
  const addressDualAlert = useSelector(getAddresDualAlert)
  const selectedProductArray = useSelector(getSelectedRate)
  const rate = selectedProductArray
  const company = useSelector(getCompany)
  const [btnState, setBtnState] = useState(true)
  const isNaturgy = (company?.code || rate[0]?.company?.code) === COMPANY_CODES.gns

  const [isValidCapacities, setIsValidCapacities] = useState(true)
  const [transformDevices] = useState(!!technicalInfo?.numberOfDevicesTransformed)
  const [heatingInstallation] = useState(!!technicalInfo?.thermalInstallationDate)
  const [plannedDischargeDate, setPlannedDischargeData] = useState(
    !!technicalInfo?.activationDate ? PLANNED_DISCHARGE_DATE.fixedDate : PLANNED_DISCHARGE_DATE.asap
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isOptional, setIsOptional] = useState(false)

  const tariffs = tariffData?.map(({ type }) => type)
  const getVariantInfo = (accessTariff) => tariffData?.find(({ type }) => type === accessTariff)
  const getValidationClientNode = (technicalInfo) => {
    if (technicalInfo?.validationClientNode === true || technicalInfo?.validationClientNode === false) {
      return technicalInfo.validationClientNode
    } else {
      return null
    }
  }
  const [valueDefaultAnnualConsumption, setValueDefaultAnnualConsumption] = useState(
    getVariantInfo(technicalInfo?.accessTariff)?.defaultAnnualConsumption || ''
  )
  const electricityInitialValues = {
    accessTariff: technicalInfo?.accessTariff || '',
    consumption12Months: technicalInfo?.consumption12Months || '',
    maxCapacity: parseFloat(technicalInfo?.maxCapacity?.replace(/,/g, '.')) || '',
    capacityP1: parseFloat(technicalInfo?.capacityP1?.replace(/,/g, '.')) || '',
    capacityP2: parseFloat(technicalInfo?.capacityP2?.replace(/,/g, '.')) || '',
    capacityP3: parseFloat(technicalInfo?.capacityP3?.replace(/,/g, '.')) || '',
    capacityP4: parseFloat(technicalInfo?.capacityP4?.replace(/,/g, '.')) || '',
    capacityP5: parseFloat(technicalInfo?.capacityP5?.replace(/,/g, '.')) || '',
    capacityP6: parseFloat(technicalInfo?.capacityP6?.replace(/,/g, '.')) || '',
    activationDate: technicalInfo?.activationDate || '',
    newSupply,
    ...(newSupply && {
      isCieElectronic: 'N',
      cieCode: '',
      cieIssueDate: '',
      cieExpirationDate: '',
      cieNif: '',
      cieVoltage: '',
      cieSupplyType: '',
      cieValidityScope: '',
    }),
    validationClientNode: getValidationClientNode(technicalInfo),
  }

  const gasInitialValues = {
    accessTariff: technicalInfo?.accessTariff || '',
    consumption12Months: technicalInfo?.consumption12Months || '',
    numberOfDevicesTransformed: technicalInfo?.numberOfDevicesTransformed || '',
    thermalInstallationDate: technicalInfo?.thermalInstallationDate || '',
    equipmentOwnershipType: CUSTOM_EQUIPMENT_OWNERSHIP.rental,
    activationDate: technicalInfo?.activationDate || '',
    newSupply,
    validationClientNode: getValidationClientNode(technicalInfo),
  }

  const initialValues = service === ELECTRICITY ? { ...electricityInitialValues } : { ...gasInitialValues }

  const handleFormSubmit = async (formData) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.continueNNSS, { currentStep: STEPS.supply }))
    setIsLoading(true)
    const invalidCapacities =
      formData.accessTariff === '3.0TD' &&
      !Object.entries(formData).some(([key, value]) => key.includes('capacityP') && value > 15)

    if (invalidCapacities) {
      setIsValidCapacities(false)
      return
    }
    setIsValidCapacities(true)

    if (service === ELECTRICITY && newSupply) {
      const { extensionRights, distributor } = technicalInfo
      const { maxCapacity } = formData

      const isDistributorPowerOK = checkDistributorPower(extensionRights, distributor, maxCapacity)
      if (!isDistributorPowerOK) return
    }

    if (!technicalInfo?.energyType) {
      technicalInfo.energyType = service.toUpperCase()
    }
    if (!technicalInfo?.cups) {
      technicalInfo.cups = (service === ELECTRICITY ? address?.electricityCups : address?.gasCups) || address?.cups
    }
    if (Object.hasOwn(formData, 'isCieElectronic')) {
      formData.isCieElectronic = 'N'
    }
    if (Object.hasOwn(formData, 'cieExpirationDate')) {
      formData.cieExpirationDate = formData.cieExpirationDate.replaceAll('-', '/')
    }
    if (Object.hasOwn(formData, 'cieIssueDate')) {
      formData.cieIssueDate = formData.cieIssueDate.replaceAll('-', '/')
    }

    onSubmit({ ...technicalInfo, ...formData, ...address }, address)
    setIsLoading(false)
    handleClose()

    if (addressDualAlert) {
      dispatch(
        dispatch(
          showModal({
            modalType: MODAL_TYPES.checkAddress,
            modalData: {},
          })
        )
      )
    } else if (ownerModal) {
      ownerModal(service)
    }
  }

  const handleInputFieldsElectricity = (values) => {
    let fieldsFulled = []
    const { maxPowerBIE, distributor } = technicalInfo
    const isCIERequired = checkCIERequired(maxPowerBIE, values.maxCapacity)
    const requiredFields =
      distributor === IDE && isCIERequired && newSupply
        ? [
            'accessTariff',
            'consumption12Months',
            'maxCapacity',
            'cieCode',
            'cieIssueDate',
            'cieExpirationDate',
            'cieNif',
            'cieVoltage',
            'cieSupplyType',
            'cieValidityScope',
          ]
        : ['accessTariff', 'consumption12Months', 'maxCapacity']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        return
      } else {
        fieldsFulled.push(field)
      }
    })

    requiredFields?.length === fieldsFulled?.length ? setBtnState(false) : setBtnState(true)
  }

  const validateElectricityForm = (values) => {
    const errors = {}
    const { capacities, defaultMinConsumption, defaultMaxConsumption } = getVariantInfo(values.accessTariff) || []
    const { maxPowerBIE, distributor } = technicalInfo
    const isCIERequired = checkCIERequired(maxPowerBIE, values.maxCapacity)
    dispatch(setIsCIERequired(isCIERequired))

    const requiredFields =
      distributor === IDE && isCIERequired && newSupply
        ? [
            'accessTariff',
            'consumption12Months',
            'maxCapacity',
            'cieCode',
            'cieIssueDate',
            'cieExpirationDate',
            'cieNif',
            'cieVoltage',
            'cieSupplyType',
            'cieValidityScope',
          ]
        : ['accessTariff', 'consumption12Months', 'maxCapacity', 'capacityP1', 'capacityP2']

    distributor === IDE && isCIERequired && newSupply ? setIsOptional(true) : setIsOptional(false)

    requiredFields.forEach((field) => {
      if (values[field] === '' || values[field] === false) errors[field] = t('form.errors.required')
    })

    if (
      values.consumption12Months &&
      defaultMinConsumption &&
      defaultMaxConsumption &&
      !validateAnnualConsumption(formatNumber(values.consumption12Months), defaultMinConsumption, defaultMaxConsumption)
    ) {
      errors.consumption12Months = t('form.errors.annualConsumption', { min: defaultMinConsumption, max: defaultMaxConsumption })
    }

    capacities?.forEach((capacity, index) => {
      if (values[capacity] === '' || values[capacity] === false) {
        errors[capacity] = t('form.errors.required')
      } else if (values[capacity] <= 0) {
        errors[capacity] = t('form.errors.higherCapacity', { value: 0 })
      } else if (values[capacities[index - 1]] && values[capacity] && values[capacity] < values[capacities[index - 1]]) {
        errors[capacity] = t('form.errors.higherNextCapacity')
      }

      if (values.accessTariff === '2.0TD' && values[capacity] > 15) {
        errors[capacity] = t('form.errors.lowerCapacity', { value: 15 })
      }
    })

    if (!errors.maxCapacity && values.maxCapacity <= 0) {
      errors.maxCapacity = t('form.errors.higherMaxCapacity', { value: 0 })
    }

    if (values.maxCapacity > 0 && capacities && values.maxCapacity < values[capacities.at(-1)]) {
      errors.maxCapacity = t('form.errors.higherLastCapacity')
    }

    if (values.cieCode && (values.cieCode.length > 35 || !validateAlphanumeric(values.cieCode))) {
      errors.cieCode = t('form.errors.cieCode')
    }
    if (values.cieNif && (values.cieNif.length > 14 || !validateAlphanumeric(values.cieNif))) {
      errors.cieNif = t('form.errors.cieNif')
    }
    if (!isNaturgy && values.cieVoltage && (values.cieVoltage.length > 14 || !validateAlphanumeric(values.cieVoltage))) {
      errors.cieVoltage = t('form.errors.cieVoltage')
    }
    if (values.cieSupplyType && (values.cieSupplyType.length > 15 || !validateAlphanumeric(values.cieSupplyType))) {
      errors.cieSupplyType = t('form.errors.cieSupplyType')
    }
    if (
      !isNaturgy &&
      values.cieValidityScope &&
      (values.cieValidityScope.length > 2 || !validateAlphanumeric(values.cieValidityScope))
    ) {
      errors.cieValidityScope = t('form.errors.cieValidityScope')
    }

    if (plannedDischargeDate === PLANNED_DISCHARGE_DATE.fixedDate && !values.activationDate) {
      errors.activationDate = t('form.errors.required')
    }

    if (new Date(values.cieExpirationDate) < new Date(values.cieIssueDate)) {
      errors.cieIssueDate = t('form.errors.issueDateGreaterExpirationDate')
      errors.cieExpirationDate = t('form.errors.issueDateGreaterExpirationDate')
    }

    const cieIssueDate = values?.cieIssueDate?.split('-')[0]
    const cieExpirationDate = values?.cieExpirationDate?.split('-')[0]
    if (+cieIssueDate?.at(0) === 0 || +cieExpirationDate?.at(0) === 0) {
      errors.cieIssueDate = t('form.errors.invalidDate')
      errors.cieExpirationDate = t('form.errors.invalidDate')
    }

    if (
      (values?.cieIssueDate && values?.cieIssueDate.length > 10) ||
      (values?.cieExpirationDate && values?.cieExpirationDate.length > 10)
    ) {
      errors.cieIssueDate = t('form.errors.invalidDate')
      errors.cieExpirationDate = t('form.errors.invalidDate')
    }

    if (values.activationDate && !validateActivationDate(values.activationDate)) {
      errors.activationDate = t('form.errors.invalidActivateDate')
    }

    handleInputFieldsElectricity(values)

    return errors
  }

  const handleInputFieldsGas = (values) => {
    let fieldsFulled = []
    const requiredFields = ['accessTariff', 'consumption12Months']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        return
      } else {
        fieldsFulled.push(field)
      }
    })

    fieldsFulled?.length > 0 ? setBtnState(false) : setBtnState(true)
  }

  const validateGasForm = (values) => {
    const errors = {}
    const { defaultMinConsumption, defaultMaxConsumption } = getVariantInfo(values.accessTariff) || []
    let requiredFields = ['accessTariff', 'consumption12Months']

    requiredFields.forEach((field) => {
      if (values[field] === '' || values[field] === false) errors[field] = t('form.errors.required')
    })

    if (transformDevices && !values.numberOfDevicesTransformed) {
      errors.numberOfDevicesTransformed = t('form.errors.required')
    }

    if (
      values.consumption12Months &&
      !validateAnnualConsumption(formatNumber(values.consumption12Months), defaultMinConsumption, defaultMaxConsumption)
    ) {
      errors.consumption12Months = t('form.errors.annualConsumption', { min: defaultMinConsumption, max: defaultMaxConsumption })
    }

    if (heatingInstallation && !values.thermalInstallationDate) {
      errors.thermalInstallationDate = t('form.errors.required')
    }

    if (plannedDischargeDate === PLANNED_DISCHARGE_DATE.fixedDate && !values.activationDate) {
      errors.activationDate = t('form.errors.required')
    }

    if (values.activationDate && !validateActivationDate(values.activationDate)) {
      errors.activationDate = t('form.errors.invalidActivateDate')
    }

    handleInputFieldsGas(values)

    return errors
  }

  const handleTariffChange = (e, setFieldValue) => {
    setValueDefaultAnnualConsumption(getVariantInfo(e.target.value)?.defaultAnnualConsumption || '')
    setFieldValue('consumption12Months', getVariantInfo(e.target.value)?.defaultAnnualConsumption, false)
  }

  const handleMaxPowerChange = (e, setFieldValue, values) => {
    const capacities = ['capacityP1', 'capacityP2'] || []
    capacities?.forEach((capacity) => {
      setFieldValue(capacity, +e.target.value, false)
    })
  }

  const handlePlannedDischargeDateChange = (e) => {
    setPlannedDischargeData(e.target.value)
  }

  const checkDistributorPower = (extensionRights, distributor, maxCapacity) => {
    if (distributor === UFD && maxCapacity && extensionRights) {
      if (maxCapacity <= Number(extensionRights.replace(',', '.'))) {
        dispatch(setRecordUFD(false))
        return true
      }
      dispatch(sendGAEvent(GA_EVENT_NAMES.openPopUpTramit, { currentStep: STEPS.supply }))
      dispatch(
        showModal({
          modalType: MODAL_TYPES.error,
          modalData: {
            translationLabel: 'distributorPower',
          },
        })
      )
      dispatch(setRecordUFD(true))
      return false
    }
    dispatch(setRecordUFD(false))
    return true
  }

  const checkCIERequired = (maxPowerBIE, maxCapacity) => {
    if (maxPowerBIE && maxCapacity && maxCapacity > Number(maxPowerBIE.replace(',', '.'))) {
      return true
    }
    return false
  }

  const validateForm = service === ELECTRICITY ? validateElectricityForm : validateGasForm

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    service === ELECTRICITY ? validateElectricityForm(initialValues) : validateGasForm(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NewSupplyView>
      {isValidCapacities && (
        <Loader open={isLoading} opacity={0.75}>
          <CircularProgress thickness={2} />
        </Loader>
      )}
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            {service.includes(ELECTRICITY) && (
              <ElectricityNewSupply
                values={values}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                tariffs={tariffs}
                getVariantInfo={getVariantInfo}
                onTariffChange={handleTariffChange}
                onMaxPowerChange={handleMaxPowerChange}
                plannedDischargeDate={plannedDischargeDate}
                onPlannedDischargeDateChange={handlePlannedDischargeDateChange}
                newSupply={newSupply}
                referenceSegment={referenceSegment}
                valueDefaultAnnualConsumption={valueDefaultAnnualConsumption}
                isNaturgy={isNaturgy}
                isOptional={isOptional}
              />
            )}

            {service.includes(GAS) && (
              <GasNewSupply
                values={values}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                tariffs={tariffs}
                getVariantInfo={getVariantInfo}
                plannedDischargeDate={plannedDischargeDate}
                onPlannedDischargeDateChange={handlePlannedDischargeDateChange}
                newSupply={newSupply}
                referenceSegment={referenceSegment}
                valueDefaultAnnualConsumption={valueDefaultAnnualConsumption}
              />
            )}

            {!isValidCapacities && (
              <Box mt={4}>
                <Alert severity="warning">
                  <Typography variant="basic">{t('rateSelectedMinCapacity', { value: 15 })}</Typography>
                </Alert>
              </Box>
            )}

            <Box display="flex" justifyContent="flex-start" alignItems="center" mt={5} mb={3}>
              <Button type="submit" variant="contained" color="secondary" disabled={btnState}>
                {t('common.continue')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </NewSupplyView>
  )
}

export default NewSupplyTechnical
