import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    boxShadow: '0px 0px 1.8px rgba(0, 69, 113, 0.12), 0px 2.2px 7.2px rgba(0, 69, 113, 0.12)',
    marginTop: '1px',
    padding: theme.spacing(3, 2),
  },
}))

export default useStyles
