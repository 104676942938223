import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'ui/components/typography'
import { Box, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { setDualAddressAlert } from 'services/customer/actions'

const CheckAddress = ({ data, handleClose }) => {
  const dispatch = useDispatch()
  //eslint-disable-next-line
  const { t } = useTranslation()
  const { checkTenure, ownerModal, service } = data

  return (
    <Box px={5} pb={6} pt={6}>
      <Box mb={3} textAlign="left">
        <Typography variant="h3" color="primary">
          {t('addressValidation.modal.title')}
        </Typography>
      </Box>
      {checkTenure ? (
        <>
          <Box textAlign="left">
            <Typography>{t('addressValidation.modal.diferentAddress1Tenure')}</Typography>
          </Box>
          <Box textAlign="left">
            <Typography>{t('addressValidation.modal.diferentAddress2Tenure')}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Box textAlign="left">
            <Typography>{t('addressValidation.modal.diferentAddress1')}</Typography>
          </Box>
          <Box textAlign="left">
            <Typography>{t('addressValidation.modal.diferentAddress2')}</Typography>
          </Box>
        </>
      )}

      <Box
        textAlign="right"
        style={{
          gap: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '2rem',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(setDualAddressAlert(false))
            if (ownerModal) {
              handleClose()
              ownerModal(service)
            } else {
              handleClose()
            }
          }}
        >
          {t('addressValidation.modal.action1')}
        </Button>
      </Box>
    </Box>
  )
}

export default CheckAddress
