import { entities, request } from 'redukers'
import { formatOrderLinesData, formatOrdersData } from '../../utils/commons'
import { ORDER_STATES, PRODUCT_STATES } from '../../utils/constants'

export const getSummary = (state) => state.backoffice.summary

export const getOrderSummaryByChannel = (state) => {
  const orders = state.backoffice.summary.ordersByChannelSummaries || []
  const totalOrders = state.backoffice.summary.totalOrders
  return orders.map(({ channelName: label, totalOrders: number }) => ({
    label,
    number,
    width: number / totalOrders,
  }))
}

export const getOrdersSummary = (state) => {
  const orders = Object.values(state.backoffice.ordersSummary)
  return orders
    .filter((order) => ORDER_STATES.includes(order.state))
    .sort((a, b) => ORDER_STATES.indexOf(a.state) - ORDER_STATES.indexOf(b.state))
    .map(({ state: label, total, avgAge: days }) => ({ label, total, days }))
}

export const getProductsSummary = (state) => {
  const products = Object.values(state.backoffice.productsSummary)
  return products
    .filter((product) => PRODUCT_STATES.includes(product.state))
    .sort((a, b) => PRODUCT_STATES.indexOf(a.state) - PRODUCT_STATES.indexOf(b.state))
    .map(({ state: label, total, avgAge: days }) => ({ label, total, days }))
}

export const getOrders = (state) => {
  const orders = state.backoffice.orders
  const formattedOrders = formatOrdersData(orders)
  return formattedOrders.map((order) => {
    const details = getOrderDetail(order.orderId, state)
    return {
      info: {
        ...order,
      },
      details,
    }
  })
}

export const getOrdersFailed = (state) => {
  const orders = state.backoffice.ordersFail
  const formattedOrders = orders ? formatOrdersData(orders) : []
  return formattedOrders.map((order) => {
    const details = getOrderDetail(order.orderId, state)
    return {
      info: {
        ...order,
      },
      details,
    }
  })
}

export const getOrderDetail = (id, state) => {
  const details = state.backoffice.orderDetails
  return entities.getOne(id, details) || []
}

export const getTotalOrders = (state) => {
  return state.backoffice.totalOrders
}
export const getTotalOrdersFailed = (state) => {
  return state.backoffice.totalOrdersFail
}

export const getProducts = (state) => {
  const products = state.backoffice.products
  const formattedProducts = formatOrderLinesData(products)
  return formattedProducts
}

export const getTotalProducts = (state) => {
  return state.backoffice.totalProducts
}

export const getNotifications = (state) => state.backoffice.notifications

export const getTotalNotifications = (state) => state.backoffice.totalNotifications

export const getTotalUnmanagedNotifications = (state) => state.backoffice.totalUnmanagedNotifications

export const getFiltersConfiguration = (state) => {
  return state.backoffice.filtersConfiguration
}

export const getIsExportCSVRequesting = (state) => {
  const exportCSVRequest = state.backoffice.exportRequest
  return request.getIsRequesting(exportCSVRequest)
}

export const getOrderAttachmentExportId = (state) => {
  return state.backoffice.orderAttachmentExportId
}

export const getIsExportOrderDataRequesting = (state) => {
  const exportOrderDataRequest = state.backoffice.orderAttachmentExportRequest
  return request.getIsRequesting(exportOrderDataRequest)
}

export const getIsCancelOrderRequesting = (state) => {
  const cancelOrderRequest = state.backoffice.cancelOrderRequest
  return request.getIsRequesting(cancelOrderRequest)
}

export const getIsVerifyOrderRequesting = (state) => {
  const verifyOrderRequest = state.backoffice.verifyOrderRequest
  return request.getIsRequesting(verifyOrderRequest)
}

export const getIsLoadBackofficeRequesting = (state) => {
  const loadBackofficeRequest = state.backoffice.loadBackofficeRequest
  return request.getIsRequesting(loadBackofficeRequest)
}

export const getIsFetchOrderSummaryRequesting = (state) => {
  const fetchOrderSummaryRequest = state.backoffice.orderSummaryRequest
  return request.getIsRequesting(fetchOrderSummaryRequest)
}

export const getIsProductsSummaryRequesting = (state) => {
  const productsSummaryRequest = state.backoffice.productsSummaryRequest
  return request.getIsRequesting(productsSummaryRequest)
}

export const getIsFetchTableDataRequesting = (state) => {
  const fetchTableDataRequest = state.backoffice.fetchTableDataRequest
  return request.getIsRequesting(fetchTableDataRequest)
}

export const getIsFetchNotificationsRequesting = (state) => {
  const fetchNotificationsRequest = state.backoffice.fetchNotificationsRequest
  return request.getIsRequesting(fetchNotificationsRequest)
}

export const getManageNotificationsRequesting = (state) => {
  const manageNotificationsRequest = state.backoffice.manageNotificationsRequest
  return request.getIsRequesting(manageNotificationsRequest)
}

export const getIsRelaunchOrderRequesting = (state) => {
  const relaunchOrderRequest = state.backoffice.relaunchOrderRequest
  return request.getIsRequesting(relaunchOrderRequest)
}

export const getIsRelaunchOrderSuccess = (state) => {
  const relaunchOrderRequest = state.backoffice.relaunchOrderRequest
  return request.getIsRequestSucceeded(relaunchOrderRequest)
}

export const getIsFailedWithoutRecoveryRequesting = (state) => {
  const failedWithoutRecoveryRequest = state.backoffice.failedWithoutRecoveryRequest
  return request.getIsRequesting(failedWithoutRecoveryRequest)
}

export const getIsFailedWithoutRecoverySuccess = (state) => {
  const failedWithoutRecoveryRequest = state.backoffice.failedWithoutRecoveryRequest
  return request.getIsRequestSucceeded(failedWithoutRecoveryRequest)
}

export const getSearchFilters = (state) => state.backoffice.searchFilters

export const getSelectedTab = (state) => state.backoffice.selectedTab

export const getSelectedExportTemplateId = (state) => state.backoffice.selectedExportTemplateId

export const getNotificationState = (state) => state.notification.notificationState

export const getErrorNotification = (state) => state.notification.errorNotification
