import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

import { COMPANY_SESSION_STORAGE_KEY, CHANNEL_SESSION_STORAGE_KEY, REFERENCE_SEGMENT_SESSION_STORAGE_KEY } from 'utils/constants'
import { get as getItemSessionStorage } from 'services/sessionStorage'

const sessionSelectedChannel = getItemSessionStorage(CHANNEL_SESSION_STORAGE_KEY)
const sessionSelectedCompany = getItemSessionStorage(COMPANY_SESSION_STORAGE_KEY)
const initialSelectedChannel = sessionSelectedChannel ? JSON.parse(sessionSelectedChannel) : { id: '' }
const sessionReferenceSegment = getItemSessionStorage(REFERENCE_SEGMENT_SESSION_STORAGE_KEY)
const initialReferenceSegment = sessionReferenceSegment || 'residential'

/* eslint-disable-next-line */
export default combineReducers({
  auth0: redukers.value(
    {
      set: actionTypes.SET_DATA,
      reset: actionTypes.CLEAR_DATA,
    },
    null
  ),
  user: redukers.value(
    {
      set: actionTypes.SET_USER_DATA,
      reset: actionTypes.CLEAR_USER_DATA,
    },
    null
  ),
  companies: redukers.value(
    {
      set: actionTypes.SET_OPERATOR_COMPANIES,
    },
    JSON.parse(sessionSelectedCompany) ? JSON.parse(sessionSelectedCompany) : []
  ),
  channels: combineReducers({
    all: redukers.value(
      {
        set: actionTypes.SET_CHANNELS,
        reset: actionTypes.CLEAR_CHANNELS,
      },
      {}
    ),
    selected: redukers.value(
      {
        set: actionTypes.SET_SELECTED_CHANNEL,
        reset: actionTypes.CLEAR_SELECTED_CHANNEL,
      },
      initialSelectedChannel
    ),
  }),
  referenceSegment: redukers.value(
    {
      set: actionTypes.SET_REFERENCE_SEGMENT,
    },
    initialReferenceSegment
  ),
  isResetFunnelContract: redukers.value(
    {
      set: actionTypes.SET_IS_RESET_FUNNEL_CONTRACT,
    },
    false
  ),
})
