import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

import Typography from 'ui/components/typography'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import useStyles from './styles'

const ExistClientOnline = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onOpenAnotherModal = (modalType) => {
    dispatch(showModal({ modalType }))
  }
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('existClient.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="basic" color="textPrimary">
            {t('existClient.text')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={() => onOpenAnotherModal(MODAL_TYPES.login)}>
          {t('caLoginTitle')}
        </Button>
        <Button color="primary" variant="contained" onClick={() => onOpenAnotherModal(MODAL_TYPES.callMeBack)}>
          {t('callMeBackTitle')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ExistClientOnline
