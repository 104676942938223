import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'

import { Box, Button, CircularProgress } from '@material-ui/core'
import { validatePostalCode, validateNumber, validateStair, validatePortal, validateFloor, validateDoor } from 'utils/validators'
import { getNormalizedCities, getNormalizedStreets } from 'services/address/api'
import { getCalculatorInfo, getTechnicalInfoFromCups } from '@zatopek/core/src/services/supplyPoint/api'
import { capitalizeFirstLetter } from '@zatopek/core/src/utils/capitalizeFirstLetter'
import { isEmptyObject } from '@zatopek/core/src/utils/isEmptyObject'

import NewSupplyView from 'ui/modals/newSupply'
import AddressForm from 'ui/forms/address'
import Typography from 'ui/components/typography'
import Link from 'ui/components/link'
import Loader from '@zatopek/core/src/ui/components/loader'

import AddressErrorsAlert from 'ui/components/addressErrorsAlert'
import { getTariffData } from 'utils/commons'

import { ADDRESS, GA_EVENT_NAMES, STEPS } from 'utils/constants'
import { BOTH_ENUM, ELECTRICITY, GAS } from '@zatopek/core/src/utils/constants'
import { mapNewSupplyAddressLine } from 'services/customer/business'
import { cpProvinces } from '@zatopek/core/src/utils/enums'
import { setSupplyAddress, setDualAddressAlert } from 'services/customer/actions'
import { getIsMultiPoint, getSupplyAddress, getSelectedTenure, getAddresDualAlert } from 'services/customer/selectors'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { put } from 'services/client'
import { getOrders } from 'services/order/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { getSelectedChannel } from 'services/operator/selectors'
import { getActiveStep } from 'services/stepper/selectors'
import { getSelectedCompany } from 'services/customer/selectors'
import { sendGAEvent } from 'services/analytics/actions'
const NewSupplyAddress = ({
  handleClose,
  data: {
    completeSupplyPoint,
    service,
    supplyModeService,
    addressCups,
    shouldGetCalculations,
    step,
    energyStep,
    supplyTechnicalInfo,
    userDocumentNumber,
    documentType,
    checkAddressObject,
  },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const supplyAddress = useSelector(getSupplyAddress)
  const isMultiPoint = useSelector(getIsMultiPoint)
  const selectedTenure = useSelector(getSelectedTenure)
  const [municipalityCode, setMunicipalityCode] = useState()
  const [provinceCode, setProvinceCode] = useState()
  const [cities, setCities] = useState([])
  const [streetTypes, setStreetTypes] = useState([])
  const [allStreets, setAllStreets] = useState([])
  const [streetSuggestions, setStreetSuggestions] = useState([])
  const [notFoundAddress, setNotFoundAddress] = useState(false)
  const [cityInputValue, setCityInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const orders = useSelector(getOrders)
  const checkDualAddresAlert = useSelector(getAddresDualAlert)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const channel = useSelector(getSelectedChannel)
  const company = useSelector(getSelectedCompany)
  const companyCode = company?.code
  const [btnState, setBtnState] = useState(true)

  const activeStep = useSelector(getActiveStep)
  const { isGasSelected, isElectricitySelected } = checkAddressObject || {}
  const orderId = orders && orders.length && orders[0].id
  let checkDualDirection = false

  const addressAvailableEnergies =
    (supplyModeService === BOTH_ENUM && service === BOTH_ENUM) ||
    (supplyModeService === GAS && service === GAS) ||
    (supplyModeService === ELECTRICITY && service === ELECTRICITY)
  const showAddress = addressAvailableEnergies || isMultiPoint
  const isGasInDual = supplyModeService === BOTH_ENUM && service === GAS

  const addressInitialValues = {
    postalCode: '',
    city: '',
    province: '',
    streetType: '',
    street: '',
    number: '',
    portal: '',
    stair: '',
    floor: '',
    door: '',
  }

  const initialValues = { ...addressInitialValues }

  const handleNotFoundAddress = (e, setFieldValue) => {
    e.preventDefault()
    setNotFoundAddress(!notFoundAddress)
    if (setFieldValue) {
      setFieldValue('street', '')
    }
  }

  const checkIsContratable = (contratable) => {
    const isContratable = contratable !== 'N'

    if (!isContratable) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.error,
          modalData: {
            translationLabel: 'contratableField',
          },
        })
      )
    }
    return isContratable
  }
  const showNoCPModal = (tenureCheck) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.warningModal,
        modalData: {
          titleKey: 'addressValidation.modal.title',
          textKey: tenureCheck ? `addressValidation.modal.diferentCPTenure` : `addressValidation.modal.diferentCP`,
        },
      })
    )
  }

  const normalizaAddress = (address) => {
    return address.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const checkDualAddress = (supplyPoint, stepNumber, checkAddress, tenureCheck) => {
    if (activeStep === 4) return true
    if (
      checkAddress &&
      supplyPoint.length === 2 &&
      !Object.values(supplyPoint[0]).every((value) => value === undefined) &&
      !Object.values(supplyPoint[1]).every((value) => value === undefined)
    ) {
      if (supplyPoint[0].postalCode !== supplyPoint[1].postalCode) {
        put(`orders/${orderId}/dual_address_message`, { dualAddressMessage: 'error' })
        showNoCPModal(tenureCheck)
        return false
      }
      const address1 = supplyPoint[0]
      const address2 = supplyPoint[1]

      if (
        (address1?.streetName === address2?.streetName ||
          address1?.streetName?.includes(address2?.streetName) ||
          normalizaAddress(address1?.streetName)
            ?.replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase() ===
            normalizaAddress(address2?.streetName)
              ?.replace(/[^a-zA-Z0-9]/g, '')
              .toUpperCase()) &&
        (String(parseInt(address1?.number, 10)) === String(parseInt(address2?.number, 10)) ||
          String(parseInt(address1?.addressNumber, 10)) === String(parseInt(address2?.number, 10)) ||
          String(address1?.number)?.replace(/^[0\s]+/, '') === String(address2?.number)?.replace(/^[0\s]+/, '') ||
          String(address1?.addressNumber)?.replace(/^[0\s]+/, '') === String(address2?.number)?.replace(/^[0\s]+/, '')) &&
        (address1?.floor?.replace(/^[0\s]+/, '') === address2?.floor?.replace(/^[0\s]+/, '') ||
          address1?.addressFloor?.replace(/^[0\s]+/, '') === address2?.floor?.replace(/^[0\s]+/, '') ||
          (!address1.floor && !address2.floor && !address1.addressFloor)) &&
        (address1?.door?.replace(/^[0\s]+/, '') === address2?.door?.replace(/^[0\s]+/, '') ||
          address1?.addressDoor?.replace(/^[0\s]+/, '') === address2?.door?.replace(/^[0\s]+/, '') ||
          (!address1?.door && !address2?.door && !address1?.addressDoor))
      ) {
        return true
      } else {
        dispatch(setDualAddressAlert(true))
        checkDualDirection = true
        put(`orders/${orderId}/dual_address_message`, { dualAddressMessage: 'warning' })
        return true
      }
    }
    return true
  }

  const handleFormSubmit = async (formData) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.continueNNSS, { currentStep: STEPS.supply }))
    const city = cities?.find((city) => city.id === formData.city)
    const townName = city?.name
    const municipalityName = city?.name
    const province = cpProvinces[formData.province]
    const numeroRegex = /^-?\d*([.,]?\d+)?$/

    const street =
      streetSuggestions?.find((street) => street.externalId === formData.street)?.name?.toUpperCase() || formData.street

    const externalId = streetSuggestions?.find((street) => street.externalId === formData.street)?.externalId

    const { postalCode, streetType, number, portal, stair, floor, door } = formData

    let address = !isMultiPoint
      ? {
          tenureAddressId: '',
          addressLine: addressCups?.addressLine || mapNewSupplyAddressLine({ ...formData, street }) || supplyAddress?.addressLine,
          externalId: addressCups?.externalId || formData?.street || supplyAddress?.externalId,
          postalCode: addressCups?.postalCode || postalCode || supplyAddress?.postalCode,
          provinceName: addressCups?.provinceName || province || supplyAddress?.provinceName,
          municipalityName: addressCups?.municipalityName || municipalityName || supplyAddress?.municipalityName,
          townName: addressCups?.municipalityName || supplyAddress?.municipalityName || townName,
          streetType: addressCups?.streetType || streetType || supplyAddress?.streetType,
          streetName: addressCups?.streetName || street || supplyAddress?.streetName,
          number: addressCups?.number || number || supplyAddress?.number,
          portal: addressCups?.portal || portal || supplyAddress?.portal,
          stair: addressCups?.stair || stair || supplyAddress?.stair,
          floor: addressCups?.floor || floor || supplyAddress?.floor,
          door: addressCups?.door || door || supplyAddress?.door,
          cups: addressCups?.cupsID || addressCups?.gasCupsID || addressCups,
          electricityCups: energyStep.toLowerCase() === ELECTRICITY ? addressCups?.cupsID || addressCups : '',
          gasCups: energyStep.toLowerCase() === GAS ? addressCups?.gasCupsID || addressCups : '',
          provinceCode: provinceCode,
          municipalityCode: municipalityCode,
        }
      : {
          tenureAddressId: '',
          addressLine: mapNewSupplyAddressLine({ ...formData, street }) || '',
          externalId: externalId || '',
          postalCode: postalCode || '',
          provinceName: province || '',
          municipalityName: municipalityName || '',
          townName: townName || '',
          streetType: streetType || '',
          streetName: street || '',
          number: number || '',
          portal: portal || '',
          stair: stair || '',
          floor: floor || '',
          door: door || '',
          provinceCode: provinceCode,
          municipalityCode: municipalityCode,
        }
    let secondAddress = {}
    let checkAddress = false
    let tenureCheck = false
    if (isElectricitySelected || isGasSelected || isGasInDual) {
      checkAddress = true

      if (selectedTenure) {
        tenureCheck = true
        secondAddress = {
          number: selectedTenure?.addressNumber,
          floor: selectedTenure?.addressFloor,
          door: selectedTenure?.addressDoor,
          streetName: selectedTenure?.addressStreet,
          postalCode: selectedTenure?.postalCode,
        }
      } else {
        secondAddress = {
          number: supplyAddress?.number,
          floor: supplyAddress?.floor,
          door: supplyAddress?.door,
          streetName: supplyAddress?.streetName,
          postalCode: supplyAddress?.postalCode,
        }
      }
    } else {
      checkAddress = true
      secondAddress = {
        number: supplyAddress?.number,
        floor: supplyAddress?.floor,
        door: supplyAddress?.door,
        streetName: supplyAddress?.streetName,
        postalCode: supplyAddress?.postalCode,
      }
    }
    const tenureElectricity =
      selectedTenure && selectedTenure.products && selectedTenure.products.find((product) => product.type === 'ELECTRICITY')
    const tenureGas =
      selectedTenure && selectedTenure.products && selectedTenure.products.find((product) => product.type === 'GAS')
    const fidelization = (tenureElectricity && service === ELECTRICITY) || (tenureGas && service === GAS)

    const addresToCompare = [address, secondAddress]
    if (checkDualAddress(addresToCompare, step, checkAddress, tenureCheck)) {
      const addressData = isGasInDual ? supplyAddress : address
      let technicalInfo = { energyType: energyStep.toUpperCase(), cups: addressCups }
      let calculatorInfo
      let technicalInfoFromCUPS = false
      try {
        setIsLoading(true)
        if (supplyTechnicalInfo) {
          if (
            supplyTechnicalInfo &&
            supplyTechnicalInfo.accessTariff &&
            supplyTechnicalInfo.consumption12Months &&
            (!numeroRegex.test(supplyTechnicalInfo.consumption12Months) ||
              isNaN(parseFloat(supplyTechnicalInfo.consumption12Months)) ||
              parseFloat(technicalInfoFromCUPS.consumption12Months) <= 0)
          ) {
            const tariffData = await getTariffData(service, selectedProduct, channel)
            supplyTechnicalInfo.consumption12Months = tariffData.find(
              (tariff) => tariff.type === supplyTechnicalInfo.accessTariff
            ).defaultAnnualConsumption
          }
          technicalInfo = { ...supplyTechnicalInfo, ...technicalInfo }
        } else {
          technicalInfoFromCUPS = await getTechnicalInfoFromCups(service, addressCups, documentType, userDocumentNumber)
          if (
            technicalInfoFromCUPS &&
            technicalInfoFromCUPS.accessTariff &&
            technicalInfoFromCUPS.consumption12Months &&
            (!numeroRegex.test(technicalInfoFromCUPS.consumption12Months) ||
              isNaN(parseFloat(technicalInfoFromCUPS.consumption12Months)) ||
              parseFloat(technicalInfoFromCUPS.consumption12Months) <= 0)
          ) {
            const tariffData = await getTariffData(service, selectedProduct, channel)
            technicalInfoFromCUPS.consumption12Months = tariffData.find(
              (tariff) => tariff.type === technicalInfoFromCUPS.accessTariff
            ).defaultAnnualConsumption
          }

          if (technicalInfoFromCUPS.cups && technicalInfoFromCUPS.cups !== technicalInfo.cups) {
            technicalInfo.cups = technicalInfoFromCUPS.cups
            address.cups = technicalInfoFromCUPS.cups
          }
          technicalInfo = { ...technicalInfoFromCUPS, ...technicalInfo }
        }
        if (shouldGetCalculations || step === 2) {
          calculatorInfo = await getCalculatorInfo(addressCups, technicalInfo?.energyType, companyCode)
        }
      } catch (error) {
        console.log('error', error)
        if (step === 2) {
          handleClose()
          dispatch(
            showModal({
              modalType: MODAL_TYPES.error,
              modalData: {
                translationLabel: 'technicalFlatRate',
              },
            })
          )
          return
        }
      } finally {
        setIsLoading(false)
      }
      const validationClientNode = technicalInfo?.validationClientNode
      let validationClienNodeModal = false
      if (!validationClientNode && validationClientNode !== null && !fidelization && validationClientNode !== undefined) {
        validationClienNodeModal = true
      }
      if (addressAvailableEnergies && step !== 5) dispatch(setSupplyAddress(address))
      const finalAddress = { data: addressData || false, type: ADDRESS }

      // TODO: Move it to redux sagas, also in cointainers/SupplyStep
      const isContratable = checkIsContratable(technicalInfo?.contratable)

      if (isContratable) {
        completeSupplyPoint(
          technicalInfo,
          finalAddress,
          calculatorInfo,
          handleClose,
          checkDualAddresAlert || checkDualDirection,
          validationClienNodeModal
        )
      }
    }
  }

  const validateForm = (values) => {
    const errors = {}
    const requiredFields = ['postalCode', 'city', 'province', 'streetType', 'street', 'number']

    requiredFields.forEach((field) => {
      if (values[field] === '' || values[field] === false) {
        errors[field] = t('form.errors.required')
      }
    })

    if (values.number && !validateNumber(values.number)) {
      errors.number = t('form.errors.numberFormat')
    }

    if (values.portal && !validatePortal(values.portal)) {
      errors.portal = t('form.errors.portalFormat')
    }

    if (values.stair && !validateStair(values.stair)) {
      errors.stair = t('form.errors.stairFormat')
    }

    if (values.floor && !validateFloor(values.floor)) {
      errors.floor = t('form.errors.floorFormat')
    }

    if (values.door && !validateDoor(values.door)) {
      errors.door = t('form.errors.doorFormat')
    }

    //check if error has content
    Object.keys(errors).length > 0 ? setBtnState(true) : setBtnState(false)

    return errors
  }

  const handlePostalCodeChange = async (e, setFieldValue) => {
    if (e.target.value && validatePostalCode(e.target.value)) {
      const provinceCode = e.target.value.substring(0, 2)
      const postalCode = e.target.value
      setFieldValue('province', provinceCode, false)

      const cities = await getNormalizedCities(postalCode)
      const { id } = cities[0]
      const provinceId = id.slice(0, 2)
      const municipalityId = id.slice(2, 5)
      setMunicipalityCode(municipalityId)
      setProvinceCode(provinceId)
      setCities(cities)
      const streets = await getNormalizedStreets(postalCode)
      setAllStreets(streets)
      if (streets && streets.length) {
        const uniqStreetTypes = [...new Set(streets.map((item) => capitalizeFirstLetter(item.type)))].sort()
        setStreetTypes(uniqStreetTypes)
      }
    } else if (cities.length) {
      setCities([])
      setStreetTypes([])
      setAllStreets([])
      setFieldValue('city', '', false)
      setFieldValue('streetType', '', false)
      setFieldValue('street', '', false)
    }
  }

  const handleStreetTypeChange = (streetType) => {
    const filteredStreetSugestions = streetType ? allStreets?.filter((street) => street.type === streetType.toUpperCase()) : []
    setStreetSuggestions(filteredStreetSugestions)
  }

  return (
    <NewSupplyView>
      <Loader open={isLoading} opacity={0.75}>
        <CircularProgress thickness={2} />
      </Loader>
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <Box my={3}>
              <Typography variant="basic">
                {service === ELECTRICITY ? t('newSupplyPoint.electricityDescription') : t('newSupplyPoint.gasDescription')}
              </Typography>
            </Box>

            {/* {showNewSupplyAddress && !isMultiPoint && (
              <Box mt={5}>
                <Box mb={1.5}>
                  <Typography variant="h5" color="primary">
                    {t('addressType', { type: t(`common.${service.toLowerCase()}`) })}
                  </Typography>
                </Box>
                <CardContent data={{ ...supplyAddress, addressCups }} showCardContent showNewSupplyAddress />
              </Box>
            )} */}

            {showAddress && (
              <>
                <Box mt={4} mb={2}>
                  <Typography variant="h5" color="primary">
                    {t('newSupplyPoint.addressTitle')}
                  </Typography>
                </Box>
                <AddressForm
                  onChange={handleChange}
                  onPostalCodeChange={handlePostalCodeChange}
                  onStreetTypeChange={handleStreetTypeChange}
                  cities={cities}
                  cityInputValue={cityInputValue}
                  setFieldValue={setFieldValue}
                  setCityInputValue={setCityInputValue}
                  streetSuggestions={streetSuggestions}
                  streetTypes={streetTypes}
                  values={values}
                  errors={errors}
                  touched={touched}
                  notFoundAddress={notFoundAddress}
                />

                {values.streetType && (
                  <Box mt={3}>
                    <Typography color="textPrimary" variant="basic">
                      <Trans i18nKey="form.fields.notFoundAddress">
                        <Link variant="link" to="" onClick={(e) => handleNotFoundAddress(e, setFieldValue)} />
                      </Trans>
                    </Typography>
                  </Box>
                )}

                {!isEmptyObject(errors) && !isEmptyObject(touched) && <AddressErrorsAlert errors={errors} touched={touched} />}
              </>
            )}

            {/* {service.includes(ELECTRICITY) && (
              <ElectricityNewSupply
                values={values}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                tariffs={tariffs}
                getVariantInfo={getVariantInfo}
                onTariffChange={handleTariffChange}
                onMaxPowerChange={handleMaxPowerChange}
                plannedDischargeDate={plannedDischargeDate}
                onPlannedDischargeDateChange={handlePlannedDischargeDateChange}
                newSupply={newSupply}
                referenceSegment={referenceSegment}
                valueDefaultAnnualConsumption={valueDefaultAnnualConsumption}
              />
            )}

            {service.includes(GAS) && (
              <GasNewSupply
                values={values}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                tariffs={tariffs}
                getVariantInfo={getVariantInfo}
                plannedDischargeDate={plannedDischargeDate}
                onPlannedDischargeDateChange={handlePlannedDischargeDateChange}
                newSupply={isNewSupplyGasChecked}
                referenceSegment={referenceSegment}
                valueDefaultAnnualConsumption={valueDefaultAnnualConsumption}
              />
            )} */}

            {/* {!isValidCapacities && (
              <Box mt={4}>
                <Alert severity="warning">
                  <Typography variant="basic">{t('rateSelectedMinCapacity', { value: 15 })}</Typography>
                </Alert>
              </Box>
            )} */}

            <Box display="flex" justifyContent="flex-start" alignItems="center" mt={5} mb={3}>
              <Button type="submit" variant="contained" color="secondary" disabled={btnState}>
                {t('common.continue')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </NewSupplyView>
  )
}

export default NewSupplyAddress
