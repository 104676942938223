import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import useStyles from './styles'

const Loader = ({ children, open, opacity }) => {
  const classes = useStyles(opacity)

  return (
    open && (
      <Box className={classes.loader} height={1} width={1}>
        {children}
      </Box>
    )
  )
}

Loader.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  opacity: PropTypes.number,
}

export default Loader
