import { get, post, patch, del } from '../client'
import { pick } from '../../utils/commons'
import { SPECIFIC_ALLOWED_FILTERS, VISUALIZATION_ALLOWED_FILTERS } from 'utils/constants'

const getOrderFiltersConfig = async () => {
  return await get(`order_lists/setup`, true)
}

const getAgencies = () => get('agencies?pagination=false', true)

const getProducts = async () => (await get(`order_line_lists/setup`, true))?.setup

const getChannels = async () => (await get(`order_lists/setup`, true))?.setup?.channels

const getOrderStatus = async () => await get('order_statistics', true)

const getVisualizationOrderProducts = async ({
  stateGroup = [],
  channel = [],
  agency = [],
  product = [],
  contractType = [],
  ...filters
} = {}) => {
  const allowedFiltersKeys = [...SPECIFIC_ALLOWED_FILTERS, ...VISUALIZATION_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  let searchParams = new URLSearchParams([
    ...allowedFilters,
    ...stateGroup.map(({ id }) => ['stateGroup[]', id]),
    ...channel.map(({ id }) => ['channelIds[]', id]),
    ...product.map(({ id }) => ['productTypeIds[]', id]),
    ...contractType.map(({ id }) => ['contractTypeIds[]', id]),
    ...agency.map(({ id }) => ['agencyIds[]', id]),
  ]).toString()
  return await get(`sales_report/totals?${searchParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getExportOrderProducts = async ({
  stateGroup = [],
  channel = [],
  agency = [],
  product = [],
  contractType = [],
  ...filters
} = {}) => {
  const allowedFiltersKeys = [...SPECIFIC_ALLOWED_FILTERS, ...VISUALIZATION_ALLOWED_FILTERS]
  const allowedFilters = Object.entries(pick(filters, allowedFiltersKeys))
  let searchParams = new URLSearchParams([
    ...allowedFilters,
    ...stateGroup.map(({ id }) => ['stateGroup[]', id]),
    ...channel.map(({ id }) => ['channelIds[]', id]),
    ...product.map(({ id }) => ['productTypeIds[]', id]),
    ...contractType.map(({ id }) => ['contractTypeIds[]', id]),
    ...agency.map(({ id }) => ['agencyIds[]', id]),
  ]).toString()
  return await get(`sales_report/list?${searchParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getNext = async (next) => {
  return await get(next.replace(/^\/middleware/, ''), true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })
}

const getAllEmails = async (props = {}) => {
  const queryParams =
    Object.entries(props)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const { 'hydra:member': emails, 'hydra:totalItems': totalItems } = await get(`sales_recipients?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return { emails, totalItems }
}

const postEmail = (params) => post(`sales_recipients`, params)

const getEmailById = (id) => get(`sales_recipients/${id}`, true)

const putEmail = (id, params) => patch(`sales_recipients/${id}`, params)

const delEmail = (id) => del(`sales_recipients/${id}`)

export {
  getAgencies,
  getProducts,
  getChannels,
  getOrderFiltersConfig,
  getOrderStatus,
  getVisualizationOrderProducts,
  getExportOrderProducts,
  getNext,
  getAllEmails,
  postEmail,
  getEmailById,
  putEmail,
  delEmail,
}
