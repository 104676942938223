import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const MaxConsumptionAllowed = ({ handleClose, data: { maxConsumptionAllowed } }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle} id="customized-dialog-title">
        <Typography className={classes.title} variant="h4">
          {t('maxConsumptionAllowed.title')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.text} variant="basic">
          {t('maxConsumptionAllowed.text', { max: maxConsumptionAllowed.toString().replace('.', ',') })}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button type="submit" color="secondary" variant="contained" onClick={() => handleClose()}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </>
  )
}

export default MaxConsumptionAllowed
