import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    opacity: (opacity) => opacity,
  },
}))

export default useStyles
