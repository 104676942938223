import React from 'react'
import { useTranslation } from 'react-i18next'

import { Field, ErrorMessage } from 'formik'

import { Box, Button, FormHelperText, Grid, TextField as MuiTextField } from '@material-ui/core'

import Link from 'ui/components/link'
import Alert from 'ui/components/commonAlert'
import useStyles from './styles'

const LocutionForm = ({
  onChange,
  touched,
  values,
  errors,
  onCancel,
  handleSmsForwarding,
  isLeadFetching,
  isLeadError,
  isForwardSmsRequesting,
  isForwardSmsSuccess,
  isForwardSmsError,
  resumeLocution,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={3}>
        {/* --- RECOMMENDATION CODE --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.recommendationCode && errors.recommendationCode)}
            id="recommendationCode"
            label={t('form.fields.recommendationCode')}
            name="recommendationCode"
            onKeyUp={onChange('recommendationCode')}
          />
          <ErrorMessage component={FormHelperText} name="recommendationCode" error />
        </Grid>

        {/* --- NAME --- */}
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.externalWriteId && errors.externalWriteId)}
            id="externalWriteId"
            label={t('form.fields.externalWriteId')}
            name="externalWriteId"
            value={values.externalWriteId}
            onChange={onChange('externalWriteId')}
          />
          <ErrorMessage component={FormHelperText} name="externalWriteId" error />
        </Grid>
      </Grid>
      <Box className={!resumeLocution ? classes.actions : classes.actionsResumeLocution} mt={2}>
        {!resumeLocution && (
          <Button color="secondary" variant="outlined" onClick={handleSmsForwarding} disabled={isForwardSmsRequesting}>
            {t('common.forwardSms')}
          </Button>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mr={3}>
            <Link variant="link" color="secondary" to="/new-contract" onClick={onCancel}>
              {t('common.cancel')}
            </Link>
          </Box>
          <Button type="submit" color="secondary" variant="contained" disabled={isLeadFetching}>
            {t('common.continue')}
          </Button>
        </Box>
      </Box>
      {isLeadError && <Alert severity="warning" title={t('errorLead')} />}
      {isForwardSmsError && <Alert severity="warning" title={t('forwardSmsErrorTitle')} />}
      {isForwardSmsSuccess && <Alert severity="success" title={t('forwardSmsSuccessTitle')} />}
    </>
  )
}

export default LocutionForm
