import * as redukers from 'redukers'
import { combineReducers } from 'redux'
import * as actionTypes from './action-types'

export default combineReducers({
  portability: redukers.value(
    {
      set: actionTypes.SET_PORTABILITY,
      reset: actionTypes.FETCH_PORTABILITY_CLEAR,
    },
    {}
  ),
  portabilityRequest: redukers.request(
    {
      start: actionTypes.FETCH_PORTABILITY_START,
      success: actionTypes.FETCH_PORTABILITY_SUCCESS,
      error: actionTypes.FETCH_PORTABILITY_ERROR,
      clear: actionTypes.FETCH_PORTABILITY_CLEAR,
    },
    {}
  ),
  exportRequest: redukers.request(
    {
      start: actionTypes.EXPORT_REQUEST_START,
      success: actionTypes.EXPORT_REQUEST_SUCCESS,
      error: actionTypes.EXPORT_REQUEST_ERROR,
      clear: actionTypes.EXPORT_REQUEST_CLEAR,
    },
    {}
  ),
})
