/**
 * Validates CUPS number
 * @param {String} CUPS
 */
import { ELECTRICITY, GAS } from '../utils/constants'

export const validateCups = (CUPS, service = ELECTRICITY) => {
  let ret = false
  const reCUPS = {
    [ELECTRICITY]: /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i,
    [GAS]: /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})$/i,
  }
  if (Object.keys(reCUPS).includes(service) && reCUPS[service].test(CUPS)) {
    const mCUPS = CUPS.toUpperCase().match(reCUPS[service])
    const cups16 = mCUPS[1],
      control = mCUPS[2],
      letters = [
        'T',
        'R',
        'W',
        'A',
        'G',
        'M',
        'Y',
        'F',
        'P',
        'D',
        'X',
        'B',
        'N',
        'J',
        'Z',
        'S',
        'Q',
        'V',
        'H',
        'L',
        'C',
        'K',
        'E',
      ]

    const cup16Mod = parseInt(cups16, 10) % 529,
      quotient = Math.floor(cup16Mod / 23),
      remainder = cup16Mod % 23

    ret = control === letters[quotient] + letters[remainder]
  }

  return ret
}

/**
 * Validates spanish postal code
 * @param {String} postalCode
 */
export const validatePostalCode = (postalCode) => {
  return /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/.test(postalCode)
}
