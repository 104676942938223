const backdrop = {
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 13, 20, 0.4)',
      },
    },
  },
}

export default backdrop
