import { request } from 'redukers'

export const getLead = (state) => state.lead
export const getLeadId = (state) => state.lead.id
export const getLeadErrorMessage = (state) => state.lead.errorMessage
export const getIsLeadFetching = (state) => {
  const leadRequest = state.lead.request
  return request.getIsRequesting(leadRequest)
}
export const getIsLeadError = (state) => {
  const leadRequest = state.lead.request
  return request.getIsError(leadRequest)
}

export const getIsLeadRequestFetching = (state) => {
  const leadRequest = state.lead.requestLead
  return request.getIsRequesting(leadRequest)
}

export const getIsLeadRequestError = (state) => {
  const leadRequest = state.lead.requestLead
  return request.getIsError(leadRequest)
}

export const getBillingAddressId = (state) => state.lead.billingAddressId
export const getSupplyAddressesIds = (state) => state.lead.supplyAddressesIds
