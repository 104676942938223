import * as actionTypes from './action-types'
import { all, takeLatest, put, call, select } from '@redux-saga/core/effects'
import { history } from 'redux/store'

import { getOperatorAuth, getSelectedChannel, getUser } from 'services/operator/selectors'
import { getRouterQueries, getRouterSearch } from 'services/analytics/selectors'
import { fetchUser, fetchUserAPI, setSelectedChannel, setReferenceSegment } from 'services/operator/actions'
import { hideModal, resetModalData } from 'services/modal/actions'
import { sendGAecommerceEvent } from 'services/analytics/actions'
import {
  errorLoginOnlineChannel,
  errorOnlineChannel,
  setOnlineChannelMetaData,
  startOnlineChannel,
  successOnlineChannel,
  setLoginErrorByUser,
  clearLoginErrorByUser,
} from 'services/onlineChannel/actions'
import {
  disableInactiveSteps,
  setActiveStepById,
  setStepCompleted,
  setStepDisabled,
  setStepMode,
  setActiveStep,
  setStepUncompleted,
} from 'services/stepper/actions'
import { setProduct, setIsOnlyToppings } from 'services/products/actions'
import { setToppings, setDuplicatedServiceType } from 'services/toppings/actions'
import { setSupplyPointByIdCal, setUserData as setUser, clearCustomer, clearBillingAccount } from 'services/customer/actions'
import { formatUserDataOnline } from 'services/customer/business'
import { setAppliedCampaign } from 'services/campaigns/actions'
import { getCampaignById } from 'services/campaigns/api'
import { getOnlineChannel, loginOnlineChannel, getOnlineChannelAgv } from 'services/onlineChannel/api'
import { getOnlineChannelUrlParams, mapOnlineChannelObject } from 'services/onlineChannel/business'
import i18n from '../../i18n'
import { BUSINESS, HOME, LANG_CODES, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL, PYME, RESIDENTIAL, STEPS } from 'utils/constants'
import { MODAL_TYPES } from 'services/modal/constants'
import { showModal } from 'services/modal/actions'

function* checkOnlineChannelSaga() {
  //check if userSession
  const userAuth = yield select(getOperatorAuth)
  const selectedChannel = yield select(getSelectedChannel)
  const queries = yield select(getRouterQueries)
  const isColVisibleForUser = (!userAuth && !selectedChannel.id && !!queries?.sel) || !!queries?.src
  const { sel = undefined, sva = undefined, nnss = undefined, origen = undefined, vn = undefined } = queries

  const isLoggedButWithoutChannel = userAuth && !selectedChannel.id && !queries['dp_order_id']
  const isNotOnlineChannel = !selectedChannel.id && !Boolean(queries?.sel) && !queries['dp_order_id']

  if (isColVisibleForUser) {
    const user = yield select(getUser)
    yield put(startOnlineChannel())
    yield put(disableInactiveSteps())
    if (!user) {
      if (queries?.agv) {
        yield put(fetchUserAPI(queries?.agv))
      } else {
        yield put(fetchUser())
      }
    }
    try {
      const search = yield select(getRouterSearch)
      const onlineChannelParams = getOnlineChannelUrlParams(search)
      const userData = yield select(getUser)
      const onlineChannelResponse = queries?.agv ? yield call(getOnlineChannelAgv, queries?.agv) : yield call(getOnlineChannel)
      const onlineChannel = mapOnlineChannelObject(onlineChannelResponse, onlineChannelParams, userData)
      if (queries?.id && LANG_CODES.includes(queries?.id)) {
        i18n.changeLanguage(queries.id)
      }
      yield put(setSelectedChannel(onlineChannel))
      if (queries?.src === HOME) {
        yield put(setReferenceSegment(RESIDENTIAL))
      } else if (queries?.src === BUSINESS) {
        yield put(setReferenceSegment(PYME))
      }
      yield put(successOnlineChannel())
    } catch (e) {
      console.error(e)
      yield put(errorOnlineChannel())
    }
    yield put(setOnlineChannelMetaData({ ...queries, sel, sva, nnss, origen, vn }))
  } else if (isLoggedButWithoutChannel) {
    yield call(history.push, '/dashboard')
  } else if (isNotOnlineChannel) {
    // TODO REVISAR COMPORTAMIENTO ONLINE CHANNEL
    yield call(history.push, '/login')
  }
}

function* loginOnlineChannelSaga({ payload: { email, password } }) {
  const loginData = yield call(loginOnlineChannel, { username: email, password })

  if (loginData?.data) {
    const formattedData = formatUserDataOnline(loginData)
    // In case that customer or billing data was set before login we want to make sure to clear it and uncomplete these steps
    yield put(clearCustomer())
    yield put(setStepUncompleted(STEPS.owner))
    yield put(clearBillingAccount())
    yield put(setStepUncompleted(STEPS.billing))

    yield put(setUser(formattedData))
    yield put(hideModal())
    yield put(resetModalData())

    // In case that the customer has tenure. show modal to choose
    if (formattedData?.tenure) {
      yield put(
        showModal({
          modalType: MODAL_TYPES.tenureSelector,
        })
      )
    }
  } else {
    yield put(errorLoginOnlineChannel())
  }
  if (loginData?.result) {
    yield put(setLoginErrorByUser(true))
  } else {
    yield put(clearLoginErrorByUser())
  }
}

function* completeStepsOnlineChannel({ payload: { products, toppings, params } }) {
  // TODO redirección a naturgy.es en caso tarifa compromiso COL
  const compromisoRate = params?.sel === '42008' || params?.sel?.split('-')?.find((sapCode) => sapCode === '42008')
  if (compromisoRate) {
    window.location.href = 'https://www.naturgy.es'
  }
  if (products?.length === 1) {
    const product = Array.isArray(products[0]) ? products[0] : [products[0]]
    yield put(setProduct(product))
    yield put(setStepDisabled(STEPS.rate))
    if (products[0].flatRate) {
      yield put(setStepMode(STEPS.supply, products[0].energyType))
      if (params?.idCal) {
        yield put(setStepCompleted(STEPS.supply))
        yield put(setStepDisabled(STEPS.supply))
        yield put(setSupplyPointByIdCal(params.idCal, params.idCalSecond))
      } else {
        yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.calculationNotValid)
      }
    }
    if (!params?.sva) {
      yield put(setActiveStepById(STEPS.owner))
    }
    yield put(setStepCompleted(STEPS.rate))

    // In case of having dual tariff
  } else if (params?.sel?.includes('-') && products?.length) {
    const product = products.find((product) => product.subProducts)
    const productAux = Array.isArray(product) ? product : [product]
    yield put(setProduct(productAux))
    yield put(setStepDisabled(STEPS.rate))
    if (product.flatRate) {
      if (params.idCal) {
        yield put(setSupplyPointByIdCal(params.idCal, params.idCalSecond))
        yield put(setStepCompleted(STEPS.supply))
        yield put(setStepDisabled(STEPS.supply))
      } else {
        yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.calculationNotValid)
      }
    }
    if (!params?.sva) {
      yield put(setActiveStepById(STEPS.owner))
    }
    yield put(setStepCompleted(STEPS.rate))
  } else if (products?.length > 1) {
    // TODO seleccionar un producto cuando lleguen más de uno
    // TODO: refactor when there aren't duplicated products with the same sap
    // productSelected with low referenceCode (original product)
    const selectedProduct = products.sort((a, b) => (a.referenceCode > b.referenceCode ? 1 : -1))[0]
    const selectedProductAux = Array.isArray(selectedProduct) ? selectedProduct : [selectedProduct]
    yield put(setProduct(selectedProductAux))
    yield put(setStepDisabled(STEPS.rate))
    if (selectedProduct.flatRate) {
      yield put(setStepMode(STEPS.supply, selectedProduct.energyType))
      if (params?.idCal) {
        yield put(setSupplyPointByIdCal(params.idCal, params.idCalSecond))
        yield put(setStepCompleted(STEPS.supply))
        yield put(setStepDisabled(STEPS.supply))
      } else {
        yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.calculationNotValid)
      }
    }
    if (!params?.sva) {
      yield put(setActiveStepById(STEPS.owner))
    }
    yield put(setStepCompleted(STEPS.rate))
  }

  if (!params?.sel && params?.sva) {
    yield put(setIsOnlyToppings(true))
  }

  if (params?.sva && toppings?.length) {
    const svaParams = params.sva.split('_')
    const selectedToppings =
      svaParams
        ?.flatMap((selectedService) => toppings.filter((service) => service.col === selectedService))
        ?.reduce((obj, item) => ({ ...obj, [item.id]: item }), {}) || {}
    yield put(setToppings(selectedToppings))

    const countsToppings = {}
    const arraySelectedToppings = Object.entries(selectedToppings)
    arraySelectedToppings.forEach(
      ([key]) => (countsToppings[selectedToppings[key].type] = (countsToppings[selectedToppings[key].type] || 0) + 1)
    )
    const duplicatedServiceType = Object.entries(countsToppings).find(([key, value]) => value > 1)
    if (duplicatedServiceType) {
      yield put(setDuplicatedServiceType(true))
    } else {
      yield put(setStepDisabled(STEPS.topping))
      yield put(setStepCompleted(STEPS.topping))
    }
    if (params?.sel) {
      // TODO isOnlyToppings define activeStep === -2 and don't send pageView
      // fix to re-render the steps and complete/disable toppingsStep
      yield put(setActiveStep(-2))
      yield put(setActiveStepById(STEPS.owner))
    }
  }

  if (params?.idCampaign) {
    try {
      const selectedCampaign = yield call(getCampaignById, params.idCampaign)
      yield put(setAppliedCampaign(selectedCampaign))
    } catch (e) {
      console.error(e)
    }
  }
  yield put(sendGAecommerceEvent('begin_checkout'))
}

export default function* rootSaga() {
  yield all([
    takeLatest([actionTypes.CHECK_ONLINE_CHANNEL], checkOnlineChannelSaga),
    takeLatest([actionTypes.START_LOGIN_ONLINE_CHANNEL], loginOnlineChannelSaga),
    takeLatest([actionTypes.COMPLETE_STEPS_ONLINE_CHANNEL], completeStepsOnlineChannel),
  ])
}
