import React from 'react'
import { Paper, Collapse, Box, Button, Grid } from '@material-ui/core'

import CardContent from '../cardContent'

import useStyles from './styles'
import isEmptyObject from '../../../utils/isEmptyObject'
import { useTranslation } from 'react-i18next'

const BillingAddressCard = ({ isCompleted, children, collapsed, onEdit, data, hide, isEditing }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const showCardContent = isCompleted && !hide && data && !isEmptyObject(data) && collapsed

  const CancelButton = () => (
    <Grid container className={classes.cancelContainer}>
      <Box className={classes.button}>
        <Button variant="outlined" color="secondary" onClick={onEdit}>
          {t('common.cancel')}
        </Button>
      </Box>
    </Grid>
  )

  return (
    <Paper className={classes[hide ? 'paperHide' : 'paperShow']}>
      <CardContent showCardContent={showCardContent} data={data} onEditClick={onEdit} />
      <Collapse in={!collapsed || hide} timeout="auto">
        {children}
      </Collapse>
      {isEditing && <CancelButton />}
    </Paper>
  )
}

export default BillingAddressCard
