import { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, TextField as MuiTextField } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { Field, Formik, Form } from 'formik'
import { validateCups } from '@zatopek/core/src/utils/validators'
import { setInvoiceData, uploadInvoiceClear } from 'services/invoice/actions'
import { getInvoiceData } from 'services/invoice/selectors'
import ScanInvoiceContext from './scanInvoiceContext'
import useStyles from './styles'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'

const ScanInvoiceValidateForm = ({ handleClose, index }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const invoiceData = useSelector(getInvoiceData)

  const { invoiceDataFromFile } = useContext(ScanInvoiceContext)
  const [editMode, setEditMode] = useState(false)
  const [cupsError, setCupsError] = useState(false)

  const validateForm = (values) => {
    const requiredFields = ['cups']
    const errors = requiredFields.reduce(
      (errs, field) => ({
        ...errs,
        ...(values[field] === '' || values[field] === false ? { [field]: t('form.errors.required') } : {}),
      }),
      {}
    )

    if (values.cups && !validateCups(values.cups)) {
      errors.cups = t('form.errors.cups')
      if (!cupsError) setCupsError(true)
    } else {
      if (cupsError) setCupsError(false)
    }

    return errors
  }

  useEffect(() => {
    if (cupsError) dispatch(sendGAEvent(GA_EVENT_NAMES.errorCupsPopUp, { currentStep: STEPS.supply }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cupsError])

  const handleFormSubmit = (values) => {
    dispatch(
      setInvoiceData({
        ...invoiceData,
        [index]: { ...values, index },
      })
    )
    dispatch(uploadInvoiceClear())
    dispatch(sendGAEvent(GA_EVENT_NAMES.finishCupsPopUp, { currentStep: STEPS.supply }))
    handleClose()
  }

  const onClose = () => {
    dispatch(uploadInvoiceClear())
    handleClose()
  }
  const editCups = (errors) => {
    if (!editMode || !errors?.cups) setEditMode(!editMode)
    if (!editMode) {
      dispatch(sendGAEvent(GA_EVENT_NAMES.editCupsPopUp, { currentStep: STEPS.supply }))
    } else {
      dispatch(sendGAEvent(GA_EVENT_NAMES.saveCupsPopUp, { currentStep: STEPS.supply }))
    }
  }

  return (
    <>
      <Box mb={4}>
        <Typography className={classes.description} variant="basic" color="textPrimary">
          {t('verifyCupsInfo')}
        </Typography>
      </Box>
      <Formik initialValues={{ cups: invoiceDataFromFile?.cups }} validate={validateForm} onSubmit={handleFormSubmit}>
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={10} md={6} className={classes.cupsContainer}>
                <Typography className={classes.cupsText} variant="basic" color="textPrimary">
                  {t('supply.cups')}:
                </Typography>

                {editMode ? (
                  <>
                    <Field
                      component={MuiTextField}
                      fullWidth
                      id="cups"
                      name="cups"
                      label={t('supply.cups')}
                      value={values.cups}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ form: { autocomplete: 'off' } }}
                      onChange={(e) => setFieldValue('cups', e.target.value, true)}
                      error={errors.cups}
                    />
                  </>
                ) : (
                  <Field fullwidth value={values.cups} className={classes.cupsInputDisabled} disabled />
                )}
              </Grid>
              <Grid item xs={10} md={5} className={classes.cupsContainerError}>
                {errors.cups ? <Typography color="error">{errors.cups}</Typography> : null}
              </Grid>
            </Grid>
            <Box>
              <Box>
                <Typography
                  onClick={() => {
                    editCups(errors)
                  }}
                  className={editMode && errors?.cups ? classes.changeModeTextDisabled : classes.changeModeText}
                  alignItems="flex-start"
                  variant="basic"
                  color="secondary"
                >
                  {editMode ? t('saveCupsChange') : t('editCups')}
                </Typography>
              </Box>
              <Box className={classes.buttonsContainer} display="flex" alignItems="center" mb={4}>
                <Button
                  onClick={() => {
                    dispatch(sendGAEvent(GA_EVENT_NAMES.cancelCupsPopUp, { currentStep: STEPS.supply }))
                    onClose()
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={editMode} variant="contained" color="secondary">
                  {t('common.finish')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ScanInvoiceValidateForm
