import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  types: redukers.value(
    {
      set: actionTypes.SET_TYPES,
      reset: actionTypes.CLEAR_TYPES,
    },
    []
  ),
})
