import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import translationEs from './locales/es-ES/translation.json'
import translationCa from './locales/ca-ES/translation.json'
import translationGl from './locales/gl-ES/translation.json'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development' ? true : false,
    fallbackLng: 'es', // use en if detected lng is not available

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      es: {
        translations: translationEs,
      },
      ca: {
        translations: translationCa,
      },
      gl: {
        translations: translationGl,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    detection: {
      order: ['htmlTag', 'querystring', 'path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'subdomain'],
    },

    react: {
      useSuspense: false,
    },

    returnEmptyString: true,
  })

export default i18n
