import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 0,
    padding: theme.spacing(3, 10, 2, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 14, 2, 6),
    },
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: theme.spacing(9),
    height: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(4),
      right: theme.spacing(5),
    },
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 2),
    ...theme.typography.basic,
    color: theme.palette.primary.main,
    '& h5': {
      margin: 0,
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 1.33,
      '& + p': {
        marginBottom: theme.spacing(4),
      },
    },
    '& p': {
      margin: theme.spacing(0, 0, 1),
      fontWeight: 300,
      fontSize: 16,
      lineHeight: 1.5,
    },
    '& ul': {
      paddingLeft: '1em',
      marginTop: 0,
    },
    '& li': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 6, 0),
    },
  },
  dialogActions: {
    padding: theme.spacing(0, 3, 3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 6, 5),
    },
  },
}))

export default useStyles
