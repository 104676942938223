import { useContext, useState } from 'react'
import { createContext } from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'

const DEFAULT_DURATION = 3000
const DEFAULT_SEVERITY = 'success'
const DEFUALT_SNACK = {
  open: false,
  message: '',
  duration: DEFAULT_DURATION,
  severity: DEFAULT_SEVERITY,
  component: null,
}

const SnackbarContext = createContext({})

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

const SnackbarProvider = ({ children }) => {
  const [snack, setSnack] = useState(DEFUALT_SNACK)

  const setSnackMessage = (message, severity = DEFAULT_SEVERITY, duration = DEFAULT_DURATION, component = null) => {
    setSnack({
      message,
      severity,
      duration,
      component,
      open: true,
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnack({ ...snack, open: false })
  }

  return (
    <SnackbarContext.Provider value={{ setSnackMessage }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={snack.duration}
        open={snack.open}
        onClose={handleClose}
        TransitionComponent={Slide}
      >
        <Alert variant="filled" onClose={handleClose} severity={snack.severity} icon={false}>
          {snack.component ? snack.component : snack.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
