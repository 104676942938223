const urlBase = process.env.REACT_APP_BACKEND_URL
const backEntryPoint = process.env.REACT_APP_BACKEND_ENTRY_POINT

const makeCommonHeaders = (extraHeaders = {}) => {
  return {
    Accept: 'application/json',
    ...extraHeaders,
  }
}

const readUrl = (url = '', entryPoint = backEntryPoint) => new URL(`${urlBase}/${entryPoint}/${url}`).href
const readExternalUrl = (urlBase = '', entryPoint = backEntryPoint) => new URL(`${urlBase}/${entryPoint}`).href

const checkResponse = async (response) => {
  if (!response.ok) {
    throw response
  }
  const data = await response.json()
  return data
}

const get = async (url = '', headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'GET',
    headers: makeCommonHeaders(headers),
  })
  return await checkResponse(res)
}
const getExternal = async (urlBase = '', headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readExternalUrl(urlBase, entryPoint), {
    method: 'GET',
    headers: makeCommonHeaders(headers),
  })
  return await checkResponse(res)
}

export { get, getExternal }
