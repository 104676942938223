import * as actionTypes from './action-types'

export const setAllToppings = (toppings) => ({
  type: actionTypes.SET_ALL_TOPPINGS,
  payload: toppings,
})

export const resetAllToppings = () => ({
  type: actionTypes.CLEAR_TOPPINGS,
})

export const removeAllSelectedToppings = () => ({
  type: actionTypes.CLEAR_ALL_SELECTED_TOPPINGS,
})

export const setToppings = (topping) => ({
  type: actionTypes.SET_TOPPINGS,
  payload: topping ? { ...topping } : null,
})

export const addToppingToSelection = (topping) => ({
  type: actionTypes.ADD_TO_SELECTION,
  payload: topping,
})

export const removeToppings = (toppingIds) => ({
  type: actionTypes.REMOVE,
  payload: toppingIds,
})

export const fetchToppings = ({ referenceSegment, params }) => ({
  type: actionTypes.FETCH,
  payload: { referenceSegment, params },
})

export const setDuplicatedServiceType = (payload) => ({
  type: actionTypes.SET_DUPLICATED_SERVICE_TYPE,
  payload,
})

export const clearDuplicatedServiceType = () => ({
  type: actionTypes.CLEAR_DUPLICATED_SERVICE_TYPE,
})

export const setServiceSelection = ({ disallowed, message }) => ({
  type: actionTypes.SET_SERVICE_SELECTION,
  payload: { disallowed, message },
})

export const clearServiceSelection = () => ({
  type: actionTypes.CLEAR_SERVICE_SELECTION,
})

export const fetchVirtualBattery = () => ({
  type: actionTypes.FETCH_VIRTUAL_BATTERY,
})

export const setVirtualBattery = (payload) => ({
  type: actionTypes.SET_VIRTUAL_BATTERY,
  payload,
})

export const clearVirtualBattery = () => ({
  type: actionTypes.CLEAR_VIRTUAL_BATTERY,
})
