import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  all: redukers.value(
    {
      set: actionTypes.SET_CAMPAIGNS,
      reset: actionTypes.CLEAR_CAMPAIGNS,
    },
    null
  ),
  applied: redukers.value(
    {
      set: actionTypes.SET_APPLIED_CAMPAIGN,
      reset: actionTypes.CLEAR_APPLIED_CAMPAIGN,
    },
    null
  ),
  campaignRequest: redukers.request({
    start: actionTypes.START_CAMPAIGN_REQUEST,
    success: actionTypes.SUCCESS_CAMPAIGN_REQUEST,
    error: actionTypes.ERROR_CAMPAIGN_REQUEST,
    clear: actionTypes.CLEAR_CAMPAIGN_REQUEST,
  }),
})
