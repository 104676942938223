import { getReferenceSegmentString } from './business'
import { get, getFile, post, put, del } from '../client'

export const getProducts = async ({ channel, referenceSegment, tenure, params, isNewSupply, company }) => {
  const referenceSegmentString = getReferenceSegmentString(referenceSegment, params?.src)
  const isNewSupplyString = isNewSupply ? '&availableForNewSupplies=true' : ''
  const selectedCompany = company && company.code ? `&company.code=${company.code.toLowerCase()}` : '&company.code=gns'
  let sapCodes = params?.sel ? params.sel.split('-') : []
  let tenureProducts = tenure?.products || []

  const queryParams = new URLSearchParams([
    ...tenureProducts.map(({ id }) => ['tenure[]', id]),
    ...sapCodes.map((wc) => ['variants.sapCode[]', wc]),
  ])
  return await get(
    `channel_offers/${channel}/products?${queryParams}${selectedCompany}${referenceSegmentString}${isNewSupplyString}`,
    true
  )
}

/**
 * Check if products and services are blacklisted in give postalCode
 * @param {String} postalCode
 * @param {Array} productsIds
 * @param {Array} toppingsIds
 */
export const getBlacklistedProducts = async ({ postalCode, productsIds, toppingsIds }) => {
  let productsQueryString = ''
  let servicesQueryString = ''

  if (productsIds) {
    productsQueryString = `&${productsIds.map((productId) => `products[]=${productId}`).join('&')}`
  }

  if (toppingsIds) {
    servicesQueryString = `&${toppingsIds.map((toppingId) => `services[]=${toppingId}`).join('&')}`
  }

  return await get(`blacklists?postalCode=${postalCode}${productsQueryString}${servicesQueryString}`, true)
}

export const getProductVariants = async ({ productsIds, tariffType, channelId, tenure, company }) => {
  let productsQueryString = ''
  let tariffQueryString = tariffType ? `&tariff.type=${tariffType}` : ''
  let companyQuery = `&alt.company.code=${company?.code.toLowerCase()}`

  let tenureQueryString = ''
  if (tenure) {
    tenureQueryString = `&${tenure.variants.map(({ id }) => `tenure[]=${id}`).join('&')}`
  }

  productsIds.forEach((productId, index) => {
    if (index !== 0) {
      productsQueryString += '&'
    }
    productsQueryString += `product.id[]=${productId}`
  })

  return await get(
    `product_variants?${productsQueryString}${tariffQueryString}&product.channelOffers.id=${channelId}${tenureQueryString}${companyQuery}`,
    true
  )
}

export const getProductVariantsAlt = async ({ channelId, products, company }) => {
  const productsIds = products?.map((product) => product.productId) || []
  let productsQueryString = ''
  let companyQuery = `&alt.company.code=${company?.code ? company?.code.toLowerCase() : 'gns'}`

  productsIds.forEach((productId, index) => {
    if (index !== 0) {
      productsQueryString += '&'
    }
    productsQueryString += `id[]=${productId}`
  })

  return await get(
    `product_variants/alternatives?${productsQueryString}&product.channelOffers.id=${channelId}${companyQuery}`,
    true
  )
}

/**
 * Get product simulation file
 * @param {String} channelId
 * @param {String} productId
 */
export const getProductSimulatorFile = async ({ channelId, productId }) => {
  return await getFile(`channel_offers/${channelId}/products/${productId}/simulator`)
}

export const getProductById = (id) => get(`products/${id}`, true)

export const getAllProducts = async (props = {}) => {
  const queryParams =
    Object.entries(props)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const response = await get(`products?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return {
    products: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  }
}

export const putProduct = (id, params) => put(`products/${id}`, params)

export const postProduct = (params) => post(`products`, params)

export const deleteProduct = (productId) => del(`products/${productId}`)
/**
 *
 * @param {String} channelId
 * @param {String} productId
 */
export const getVariants = (channelId, productId) => get(`channel_offers/${channelId}/products/${productId}/variants`)

export const getAllFamilies = async (props = {}) => {
  const queryParams =
    Object.entries(props)
      ?.map((prop) => prop.join('='))
      .join('&') || ''

  const response = await get(`families?${queryParams}`, true, {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  })

  return {
    families: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  }
}

export const getFamilyById = (id) => get(`families/${id}`, true)

export const putFamily = (id, params) => put(`families/${id}`, params)

export const postFamily = (params) => post(`families`, params)
