import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  fields: {
    '& .MuiBox-root': {
      marginBottom: theme.spacing(2),
    },
  },
  policyBox: {
    overflowY: 'scroll',
    height: 80,
  },
}))

export default useStyles
