import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    border: `${theme.border.style.medium} solid ${theme.palette.primary.light3}`,
    boxShadow: theme.shadows[3],
    '&.selected': {
      border: `${theme.border.style.thin} solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
    cursor: 'pointer',
  },
  rootDisabled: {
    marginBottom: theme.spacing(2),
    border: `${theme.border.style.medium} solid ${theme.palette.primary.light3}`,
    boxShadow: theme.shadows[3],
    '&.selected': {
      border: `${theme.border.style.thin} solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
    cursor: 'not-allowed',
    opacity: '0.5',
  },
  content: {
    padding: theme.spacing(3, 2, 2, 9),
  },
  checkboxContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  logos: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  logo: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    maxWidth: '70%',
  },
  viewDetails: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
  longTitle: {
    marginRight: theme.spacing(7),
  },
  recommendBox: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: 'white',
    border: `${theme.border.style.thin} solid ${theme.palette.primary.light3}`,
    borderBottomRightRadius: '3px',
    padding: theme.spacing(7 / 8),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
}))

export default useStyles
