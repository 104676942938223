import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DialogContent, Box } from '@material-ui/core'
import Typography from 'ui/components/typography'
import { getIsUploadInvoiceRequesting, getIsUploadInvoiceSuccess, getIsUploadInvoiceError } from 'services/invoice/selectors'
import loadingImageUrl from '@zatopek/core/src/assets/images/loader-mariposa.gif'
import ScanInvoiceContext from './scanInvoiceContext'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'
import { useDispatch } from 'react-redux'

import ScanInvoiceForm from './scanInvoiceForm'
import ScanInvoiceLoading from './scanInvoiceLoading'
import ScanInvoiceError from './scanInvoiceError'
import ScanInvoiceValidateForm from './scanInvoiceValidateForm'

import useStyles from './styles'

const ScanInvoice = ({ handleClose, data: { index = 0 } }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isUploadInvoiceRequesting = useSelector(getIsUploadInvoiceRequesting)
  const isUploadInvoiceSuccess = useSelector(getIsUploadInvoiceSuccess)
  const isUploadInvoiceError = useSelector(getIsUploadInvoiceError)

  const [invoiceDataFromFile, setInvoiceDataFromFile] = useState({})

  useEffect(() => {
    // Preload loading image
    const img = new Image()
    img.src = loadingImageUrl
  }, [])

  useEffect(() => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.popUpUploadImage, { currentStep: STEPS.supply }))
    dispatch(sendGAEvent(GA_EVENT_NAMES.popUpSelectFile, { currentStep: STEPS.supply }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let Content = ScanInvoiceForm

  if (isUploadInvoiceRequesting) {
    Content = ScanInvoiceLoading
  } else if (isUploadInvoiceSuccess && Object.keys(invoiceDataFromFile).length > 0) {
    Content = ScanInvoiceValidateForm
  } else if (isUploadInvoiceError || (isUploadInvoiceSuccess && Object.keys(invoiceDataFromFile).length === 0)) {
    Content = ScanInvoiceError
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary" className={classes.title}>
            {t('scanInvoiceModalTitle')}
          </Typography>
        </Box>
        <ScanInvoiceContext.Provider value={{ invoiceDataFromFile, setInvoiceDataFromFile }}>
          <Content handleClose={handleClose} index={index} />
        </ScanInvoiceContext.Provider>
      </DialogContent>
    </>
  )
}

export default ScanInvoice
