import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField as MuiTextField, Grid, Button, FormHelperText } from '@material-ui/core'

import Typography from 'ui/components/typography'
import { Field, ErrorMessage } from 'formik'

import Alert from 'ui/components/commonAlert'

const MemberGetMemberForm = ({ handleClose, handleChange, touched, values, errors, isLeadFetching, isLeadError }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={3}>
        <Grid item xs={12} sm={6}>
          <Field
            component={MuiTextField}
            fullWidth
            error={!!(touched.referralCode && errors.referralCode)}
            id="referralCode"
            name="referralCode"
            label={t('mgmFriendCode')}
            placeholder={'RAF000X'}
            value={values?.referralCode}
            onChange={handleChange('referralCode')}
          />
          <ErrorMessage component={FormHelperText} name="referralCode" error />
        </Grid>
      </Box>
      <Box mb={3}>
        <Typography variant="body3">{t('mgmBenefitInformation')}</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body3">{t('mgmCompatibleProductsPart1')}</Typography>
        <Typography variant="body3">{t('mgmCompatibleProductsPart2')}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box mr={2}>
          <Button type="submit" color="secondary" variant="contained" disabled={isLeadFetching}>
            {t('mgmApplyReferralCodeExec')}
          </Button>
        </Box>
        <Box>
          <Button color="secondary" variant="outlined" disabled={isLeadFetching} onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
      {isLeadError && <Alert severity="warning" title={t('errorLead')} />}
    </>
  )
}

export default MemberGetMemberForm
