import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Typography from 'ui/components/typography'
import { Box, DialogContent } from '@material-ui/core'

import useStyles from './styles'
import { getCompany } from 'services/customer/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { COMPANY_CODES } from 'utils/constants'

const CheckCups = ({ data: { sameClient, cupsActive = false } }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const company = useSelector(getCompany)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const code = company?.code || selectedProduct[0]?.company?.code || COMPANY_CODES.gns

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          {cupsActive ? (
            <Typography variant="h3" color="primary">
              {t('checkCups.orderSignedSubmittedTitle')}
            </Typography>
          ) : (
            <Typography variant="h3" color="primary">
              {sameClient ? t('checkCups.sameClientTitle') : t('checkCups.notSameClientTitle')}
            </Typography>
          )}
        </Box>
        <Box mb={2}>
          {cupsActive ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t('checkCups.orderSignedSubmitted', { phoneNumber: t(`checkCups.${code.toLowerCase()}Phone`) }),
              }}
            />
          ) : (
            <Typography variant="basic" color="textPrimary">
              {sameClient ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('checkCups.sameClient', { phoneNumber: t(`checkCups.${code.toLowerCase()}Phone`) }),
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('checkCups.notSameClient', { phoneNumber: t(`checkCups.${code.toLowerCase()}Phone`) }),
                  }}
                />
              )}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </>
  )
}

export default CheckCups
