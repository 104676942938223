// TODO: add service constant and refactor names
export const SET_ORDERS = 'SET_ORDERS'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const CLEAR_ORDER_ID = 'CLEAR_ORDER_ID'
export const SET_GDPR_SENT = 'SET_GDPR_SENT'
export const CLEAR_GDPR_SENT = 'CLEAR_GDPR_SENT'
export const SET_SCORING_SENT = 'SET_SCORING_SENT'
export const CLEAR_SCORING_SENT = 'CLEAR_SCORING_SENT'
export const SET_ORDER_LINES_IDS = 'SET_ORDER_LINES_IDS'
export const CLEAR_ORDER_LINES_IDS = 'CLEAR_ORDER_LINES_IDS'
export const SET_ORDER_TECHNICAL_INFOS_IDS = 'SET_ORDER_TECHNICAL_INFOS_IDS'
export const CLEAR_ORDER_TECHNICAL_INFOS_IDS = 'CLEAR_ORDER_TECHNICAL_INFOS_IDS'
export const SET_ORDER_PRICING_CALCULATION_IDS = 'SET_ORDER_PRICING_CALCULATION_IDS'
export const CLEAR_ORDER_PRICING_CALCULATION_IDS = 'CLEAR_ORDER_PRICING_CALCULATION_IDS'
export const SET_CRM_ID = 'SET_CRM_ID'
export const RESET_CRM_ID = 'RESET_CRM_ID'
export const SET_GUIDELINE_DATE = 'SET_GUIDELINE_DATE'
export const CLEAR_GUIDELINE_DATE = 'CLEAR_GUIDELINE_DATE'
export const SET_SIGNATURE_DATE = 'SET_SIGNATURE_DATE'
export const CLEAR_SIGNATURE_DATE = 'CLEAR_SIGNATURE_DATE'
export const START_SIGN = 'START_SIGN'
export const SUCCESS_SIGN = 'SUCCESS_SIGN'
export const ERROR_SIGN = 'ERROR_SIGN'
export const CLEAR_SIGN = 'CLEAR_SIGN'
export const FINISH_LOCUTION = 'FINISH_LOCUTION'
export const CANCEL_LOCUTION = 'CANCEL_LOCUTION'
export const SET_LOCUTION_DATA = 'SET_LOCUTION_DATA'
export const RESET_LOCUTION_DATA = 'RESET_LOCUTION_DATA'
export const START_FORWARD_SMS = 'START_FORWARD_SMS'
export const SUCCESS_FORWARD_SMS = 'SUCCESS_FORWARD_SMS'
export const ERROR_FORWARD_SMS = 'ERROR_FORWARD_SMS'
export const CLEAR_FORWARD_SMS = 'CLEAR_FORWARD_SMS'
export const ATTACH_DOCUMENT = 'ATTACH_DOCUMENT'
export const START_ATTACH_DOC = 'START_ATTACH_DOC'
export const SUCCESS_ATTACH_DOC = 'SUCCESS_ATTACH_DOC'
export const ERROR_ATTACH_DOC = 'ERROR_ATTACH_DOC'
export const CLEAR_ATTACH_DOC = 'CLEAR_ATTACH_DOC'
