import * as redukers from 'redukers'
import * as actionTypes from './action-types'

/* eslint-disable-next-line */
export default {
  modal: redukers.value(
    {
      set: actionTypes.SHOW,
      reset: actionTypes.HIDE,
    },
    { type: null }
  ),
}
