import { createMuiTheme, fade } from '@material-ui/core/styles'
import palette from '../base/palette'

const defaultTheme = createMuiTheme()

const chips = {
  overrides: {
    MuiChip: {
      root: {
        height: 27,
        color: palette.primary.main,
        backgroundColor: palette.primary.light3,
        '&.grey': {
          backgroundColor: fade(palette.neutral.light2, 0.4),
        },
        '&.ok $icon': {
          color: palette.feedback.success,
        },
        '&.ko $icon': {
          color: palette.secondary.main,
        },
      },
      label: {
        fontSize: 12,
      },
      icon: {
        width: defaultTheme.spacing(2),
        height: defaultTheme.spacing(2),
        marginRight: defaultTheme.spacing(-1),
      },
      outlined: {
        borderColor: palette.primary.light1,
      },
      deletable: {
        height: 32,
        borderRadius: defaultTheme.shape.borderRadius,
        backgroundColor: palette.primary.main,
        '& $label': {
          color: palette.neutral.light4,
        },
      },
      deleteIcon: {
        width: defaultTheme.spacing(2),
        height: defaultTheme.spacing(2),
        color: '#80A1B7',
        '&:hover': {
          color: defaultTheme.palette.common.white,
        },
      },
    },
  },
}

export default chips
