import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(3, 7),
  },
  dialogContent: {
    padding: theme.spacing(0, 7, 10),
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
}))

export default useStyles
