import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStepByIndexFunc, getIsStepActiveFunc } from 'services/stepper/selectors'
import { goNextStep, goNextStepDuplicate } from 'services/stepper/actions'
import { getIsRecoveringDuplicateOrder, getIsDuplicateOrderDraft } from 'services/draftCopy/selectors'

export default function useStep({ stepIndex }) {
  const dispatch = useDispatch()

  const getStepByIndex = useSelector(getStepByIndexFunc)
  const getIsStepActive = useSelector(getIsStepActiveFunc)
  const isRecoveringDuplicateOrder = useSelector(getIsRecoveringDuplicateOrder)
  const isDuplicateOrder = useSelector(getIsDuplicateOrderDraft)

  const step = getStepByIndex(stepIndex)
  const isStepActive = getIsStepActive(stepIndex)

  useEffect(() => {
    if (isStepActive && step?.isDisabled && !isRecoveringDuplicateOrder && !isDuplicateOrder) {
      dispatch(goNextStep())
    } else if (isStepActive && step?.isDisabled && isRecoveringDuplicateOrder && isDuplicateOrder) {
      dispatch(goNextStepDuplicate())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isStepActive, step?.isDisabled])

  return { ...step, isActive: isStepActive }
}
