import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import * as productsActionTypes from 'services/products/action-types'
import * as toppingsActionTypes from 'services/toppings/action-types'

import { getAllCampaignsMatch } from 'services/campaigns/api'
import { getSelectedVariants, getIsOnlyToppings, getIsSelectionNotAllowed } from 'services/products/selectors'
import { getSelectedSingleToppings } from 'services/toppings/selectors'
import {
  getDuplicateOrderDraft,
  getIsDraft,
  getIsDuplicateOrderDraft,
  getIsOnlineChannelDraft,
} from 'services/draftCopy/selectors'
import { getUserData, getSelectedTenure, getIsNewSupply, getCompany, getSelectedCompany } from 'services/customer/selectors'
import {
  setCampaigns,
  setAppliedCampaign,
  clearAppliedCampaign,
  startCampaignRequest,
  errorCampaignRequest,
  successCampaignRequest,
} from 'services/campaigns/actions'
import { fetchLead } from 'services/lead/actions'
import { ELECTRICITY, FIDELIZATION, GAS, TOPPING_TYPES } from 'utils/constants'
import { getSelectedChannel } from 'services/operator/selectors'
import { getRouterSearch, getRouterQueries } from 'services/analytics/selectors'
import { getSelectedCampaign } from './business'
import { getAppliedCampaign } from './selectors'

function* fetchSaga() {
  const selectedVariants = yield select(getSelectedVariants)
  const services = yield select(getSelectedSingleToppings)
  const selectedToppingsIds = services ? Object.keys(services) : []
  const isOnlyToppings = yield select(getIsOnlyToppings)
  const userData = yield select(getUserData)
  const channelOffer = yield select(getSelectedChannel)
  const search = yield select(getRouterSearch)
  const isDraft = yield select(getIsDraft)
  const documentNumber = userData?.documentNumber
  const selectedTenureData = yield select(getSelectedTenure)
  const selectedCompany = yield select(getSelectedCompany)
  const { addressId: tenureAddressId } = selectedTenureData || {}
  const appliedCampaign = yield select(getAppliedCampaign)
  const isNewSupply = yield select(getIsNewSupply)
  const isOnlineChannelDraft = yield select(getIsOnlineChannelDraft)
  const company = yield select(getCompany)
  const isDuplicateOrderDraft = yield select(getIsDuplicateOrderDraft)
  const duplicateOrderDraft = yield select(getDuplicateOrderDraft)
  const queries = yield select(getRouterQueries)
  const selectionDisallowed = yield select(getIsSelectionNotAllowed)

  // In order to fetch campaigns the minimun information we want is provided in the steps Rate,Toppings & Supply
  // selectedVariants is completed either when a product and supply is selected or when isOnlyToppings
  // services default state is null and is filled either with the selected sevices or an empty object
  if (!((selectedVariants?.length || isOnlyToppings || selectionDisallowed) && services)) return

  let electricityProductId = {}
  let gasProductId = {}
  let electricityServiceId = {}
  let gasServiceId = {}
  let genericServiceId = {}
  let agv = queries?.agv ? queries.agv : {}
  let type = tenureAddressId ? { type: FIDELIZATION } : {}
  let channelOfferId = { channelOfferId: channelOffer?.id }

  if (selectedVariants?.length) {
    const electricityProduct = selectedVariants.find((product) => product.energyType === ELECTRICITY)
    const gasProduct = selectedVariants.find((product) => product.energyType === GAS)

    electricityProductId = electricityProduct && !isOnlyToppings ? { electricityProductId: electricityProduct.id } : {}
    gasProductId = gasProduct && !isOnlyToppings ? { gasProductId: gasProduct.id } : {}
  }

  if (selectedToppingsIds?.length) {
    selectedToppingsIds.forEach((toppingId) => {
      switch (services[toppingId].type) {
        case TOPPING_TYPES[ELECTRICITY]:
          electricityServiceId = { electricityServiceId: toppingId }
          break
        case TOPPING_TYPES[GAS]:
          gasServiceId = { gasServiceId: toppingId }
          break
        default:
          genericServiceId = { genericServiceId: toppingId }
          break
      }
    })
  }

  const orderLines = {
    ...electricityProductId,
    ...gasProductId,
    ...electricityServiceId,
    ...gasServiceId,
    ...genericServiceId,
    ...type,
    ...channelOfferId,
    documentNumber,
    tenureAddressId,
    isNewSupply,
    selectedCompany,
    company,
    agv,
  }

  let campaigns = []
  yield put(startCampaignRequest())
  try {
    campaigns = yield call(getAllCampaignsMatch, orderLines)
    yield put(setCampaigns(campaigns))
    yield put(successCampaignRequest())
  } catch (err) {
    yield put(errorCampaignRequest())
    console.error(err)
  }

  // If a campaing id is passed via search, check if we can apply it
  if (campaigns?.length && search) {
    const selectedCampaign = getSelectedCampaign(search, campaigns)
    if (selectedCampaign) {
      yield put(setAppliedCampaign(selectedCampaign))
    }
  }
  // If we already have an AppliedCampaign, we check if it is still available in the campaings fetched, if campaings are empty or do not contain the previously applied one clearAppliedCampaign
  const isAppliedCampaignAvailable = campaigns?.some(({ id }) => id === appliedCampaign?.id)

  if (appliedCampaign && !isAppliedCampaignAvailable) {
    yield put(clearAppliedCampaign())
    yield put(setAppliedCampaign(null))
  }

  // If we recover a campaign when we duplicate a order, we select it when is multipoint
  const hasSelectedCampaignDuplicateOrder =
    isDuplicateOrderDraft && campaigns?.length && duplicateOrderDraft.campaign && !appliedCampaign

  if (hasSelectedCampaignDuplicateOrder) {
    const selectedCampaign = campaigns.find((campaign) => campaign.id === duplicateOrderDraft.campaign) || null
    yield put(setAppliedCampaign(selectedCampaign))
  }

  if (isDraft || isOnlineChannelDraft) {
    yield put(fetchLead())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        productsActionTypes.SET_IS_ONLY_TOPPINGS,
        productsActionTypes.SET_PRODUCT_VARIANTS,
        productsActionTypes.SET_PRODUCT_SELECTION,
        toppingsActionTypes.SET_TOPPINGS,
      ],
      fetchSaga
    ),
  ])
}
