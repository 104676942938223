import { get as getItemSessionStorage } from './sessionStorage'

const { fetch: originalFetch } = window

const setup = ({ ok = {}, ko = {} }) => {
  window.fetch = async (...args) => {
    const [resource, config] = args
    const response = await originalFetch(resource, config)
    if (!response.ok && typeof ko[response.status] === 'function') {
      ko[response.status](response)
      return Promise.reject(response)
    }
    if (response.ok && typeof ok[response.status] === 'function') {
      ok[response.status](response)
      return Promise.resolve(response)
    }
    return response
  }
}

const urlBase = process.env.REACT_APP_BACKEND_URL
const backEntryPoint = process.env.REACT_APP_BACKEND_ENTRY_POINT

const makeCommonHeaders = (extraHeaders = {}, withAuthorizationHeader = false) => {
  let authorizationHeader = {}

  if (withAuthorizationHeader) {
    const accessToken = getItemSessionStorage('accessToken')
    const idToken = getItemSessionStorage('idToken')
    authorizationHeader = accessToken && idToken ? { Authorization: `Bearer ${accessToken}`, 'X-Id-Token': idToken } : {}
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...authorizationHeader,
    ...extraHeaders,
  }
}

const makeBody = (data) => (data != null ? JSON.stringify(data) : undefined)
const readUrl = (url = '', entryPoint = backEntryPoint) => new URL(`${urlBase}/${entryPoint}/${url}`).href

const checkResponse = async (response) => {
  if (!response.ok) {
    throw response
  }

  if (response.status === 204) {
    // 204 status does not have data
    return response
  }

  const data = await response.json()
  return data
}

const checkBlob = async (response) => {
  if (!response.ok) {
    throw response
  }

  if (response.status === 204) {
    return response
  }

  try {
    if (response.headers.get('content-type').includes('spreadsheetml')) {
      const data = await response.blob()
      const fileName = 'Import-users-failed.xlsx'
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      createFile(data, fileName, fileType)
      response.blob = true
      return response
    } else {
      response.blob = false
      return response
    }
  } catch {
    throw response
  }
}

const get = async (url = '', withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'GET',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
  })
  return await checkResponse(res)
}

const getError = async (url = '', withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  return await fetch(readUrl(url, entryPoint), {
    method: 'GET',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
}

const post = async (url, data, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'POST',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
    body: makeBody(data),
  })
  return checkResponse(res)
}
const postError = async (url, data, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  return await fetch(readUrl(url, entryPoint), {
    method: 'POST',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
    body: makeBody(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
}
const postMultipart = async (
  url,
  formData,
  withAuthorizationHeader = true,
  headers = {},
  entryPoint = backEntryPoint,
  checkFunction
) => {
  const requestHeaders = makeCommonHeaders(headers, withAuthorizationHeader)
  delete requestHeaders['Content-Type']
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'POST',
    headers: requestHeaders,
    body: formData,
  })
  return checkFunction ? checkFunction(res) : checkResponse(res)
}

const put = async (url, data, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'PUT',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
    body: makeBody(data),
  })
  return checkResponse(res)
}

const patch = async (url, data, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'PATCH',
    headers: makeCommonHeaders(
      {
        ...headers,
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
      },
      withAuthorizationHeader
    ),
    body: makeBody(data),
  })
  return checkResponse(res)
}

const del = async (url, withAuthorizationHeader = true, headers = {}, entryPoint = backEntryPoint) => {
  const res = await fetch(readUrl(url, entryPoint), {
    method: 'DELETE',
    headers: makeCommonHeaders(headers, withAuthorizationHeader),
  })

  return checkResponse(res)
}

// const del = (url, data, headers = {}) => {
//   const res = yield call(fetch, readUrl(url), {
//     method: 'DELETE',
//     headers: yield call(makeCommonHeaders, headers),
//     body: makeBody(data),
//   })
//   return checkResponse(res)
// }

const createFile = (blob, filename, filetype) => {
  const path = window.URL.createObjectURL(
    new Blob([blob], {
      type: filetype,
    })
  )
  const link = document.createElement('a')
  link.href = path
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

// method to download a file from endpoint
const getFile = async (url = '', withAuthorizationHeader = false, headers = {}, entryPoint = backEntryPoint) => {
  try {
    const res = await fetch(readUrl(url, entryPoint), {
      method: 'GET',
      headers: makeCommonHeaders(headers, withAuthorizationHeader),
      responseType: 'blob',
    })
    if (res.ok) {
      let blob = await res.blob()
      const contentDisposition = decodeURIComponent(res.headers.get('content-disposition'))
      const filename = contentDisposition?.split("''")[1] || contentDisposition?.split('filename=')[1]
      const fileType = res.headers.get('content-type')
      createFile(blob, filename, fileType)
    } else {
      throw res
    }
  } catch (error) {
    console.error(error)
  }
}

export { get, getFile, post, postMultipart, put, patch, del, checkBlob, setup, getError, postError }
