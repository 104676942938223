import React, { useState } from 'react'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const InputPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleEvent = () => setShowPassword(!showPassword)

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleEvent} onMouseDown={handleEvent}>
              {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputPassword
