import { MAX_CUPS_LENGTH } from './constants'

export const maskIban = (value) =>
  value && value.length === MAX_CUPS_LENGTH
    ? value.replace(/(\d{0})(\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\w{2})/, '$1** $3 **** **** **** **')
    : value.replace(/(\d{0})(\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})/, '$1** $3 **** **** **** **** ')

export const maskCups = (value) =>
  value && value.length === MAX_CUPS_LENGTH
    ? value.replace(/(\d{0})(\d{4})(\d{4})(\d{4})(\d{4})(\w{2})(\w{2})/, '$1 $2 **** **** $5 $6 $7')
    : value.replace(/(\d{0})(\d{4})(\d{4})(\d{4})(\d{4})(\w{2})/, '$1 $2 **** **** $5 $6')
