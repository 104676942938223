import * as actionTypes from './action-types'

export const loadControlStart = () => ({
  type: actionTypes.LOAD_CONTROL_START,
})

export const loadControlSuccess = () => ({
  type: actionTypes.LOAD_CONTROL_SUCCESS,
})

export const loadControlError = () => ({
  type: actionTypes.LOAD_CONTROL_ERROR,
})

export const fetchFidelization = () => ({
  type: actionTypes.FETCH_FIDELIZATION,
})

export const setFidelization = (companies) => ({
  type: actionTypes.SET_FIDELIZATION,
  payload: companies,
})

export const clearFidelization = () => ({
  type: actionTypes.CLEAR_FIDELIZATION,
})

export const setNewAddressProductChange = (payload) => ({
  type: actionTypes.SET_NEW_ADDRESS_PRODUCT_CHANGE,
  payload,
})

export const clearNewAddressProductChange = () => ({
  type: actionTypes.CLEAR_NEW_ADDRESS_PRODUCT_CHANGE,
})

export const setNewAddressServiceChange = (payload) => ({
  type: actionTypes.SET_NEW_ADDRESS_SERVICE_CHANGE,
  payload,
})

export const clearNewAddressServiceChange = () => ({
  type: actionTypes.CLEAR_NEW_ADDRESS_SERVICE_CHANGE,
})
