const getProductPriceKeys = (bundle, product, type) => {
  const keys = Object.keys(bundle)
    .filter((key) => {
      return key.includes(`entity-${product}-${type}-price`) && !key.includes(`entity-${product}-${type}-prices-url`)
    })
    .map((key) => {
      const [name, price] = key.replace(`entity-${product}-${type}-price-`, '').split('-')
      return { name, price }
    })
    .reduce((prev, curr) => {
      if (prev[curr.name]) {
        prev[curr.name].prices = [...prev[curr.name].prices, curr.price]
      } else {
        prev[curr.name] = { name: curr.name, prices: curr.price ? [curr.price] : [] }
      }
      return prev
    }, {})
  return Object.values(keys)
}

export default getProductPriceKeys
