import { STEPS, STREET_TYPE_DEFAULT } from '@zatopek/funnel/src/utils/constants'

export const mapEmergyaAddress = (emergyaAddress, step) => {
  const {
    niceAddress,
    cupsID,
    gasCupsID,
    id,
    postCode,
    province,
    town,
    roadType,
    roadTypeName,
    road,
    building,
    staircase,
    label,
    normalisedRoadID,
    placeID,
    origin,
    municipalityCode,
    provinceCode,
    ...rest
  } = emergyaAddress

  return step === STEPS.billing
    ? {
        addressLine: niceAddress,
        electricityCups: cupsID,
        gasCups: gasCupsID,
        externalId: id,
        postalCode: postCode,
        province,
        city: town,
        streetType: roadTypeName || STREET_TYPE_DEFAULT,
        street: road,
        portal: building,
        stair: staircase,
        placeId: placeID,
        provinceCode: provinceCode,
        municipalityCode: municipalityCode,
        origin,
        ...rest,
      }
    : {
        addressLine: niceAddress || label,
        electricityCups: cupsID,
        gasCups: gasCupsID,
        externalId: String(normalisedRoadID) || '',
        postalCode: postCode,
        provinceName: province,
        municipalityName: town,
        streetType: roadTypeName || STREET_TYPE_DEFAULT,
        streetName: road,
        portal: building,
        stair: staircase,
        placeId: placeID,
        provinceCode: provinceCode,
        municipalityCode: municipalityCode,
        origin,
        ...rest,
      }
}

export default mapEmergyaAddress
