import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  emphasize: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  emphasizeElectricity: {
    marginRight: theme.spacing(1.4),
  },
}))

export default useStyles
