import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Box, Button, CircularProgress, ClickAwayListener, Divider, List, Popover } from '@material-ui/core'
import { getIsFetchNotificationsRequesting, getNotifications } from 'services/backoffice/selectors'
import { fetchNotifications } from 'services/backoffice/actions'

import Typography from 'ui/components/typography'
import NotificationItem from '../notificationItem'
import useStyles from './styles'
import Loader from '@zatopek/core/src/ui/components/loader'

const NotificationsPopover = ({ notificationsPopoverId, notificationsAnchorEl, onClickAway }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const notifications = useSelector(getNotifications)
  const isFetchNotificationsRequesting = useSelector(getIsFetchNotificationsRequesting)

  const [isNotificationsFetched, setIsNotificationsFetched] = useState(false)

  const notificationsToShow = notifications?.slice(0, 5)

  const handleShowNotifications = () => {
    history.push('/backoffice/notifications')
    onClickAway()
  }

  useEffect(() => {
    if (!notifications && !isNotificationsFetched && notificationsPopoverId) {
      dispatch(fetchNotifications())
      setIsNotificationsFetched(true)
    }
  }, [notifications, dispatch, isNotificationsFetched, notificationsPopoverId])

  return (
    <Popover
      id={notificationsPopoverId}
      open={!!notificationsAnchorEl}
      anchorEl={notificationsAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Box pt={2} pb={1} position="relative">
          <Loader open={isFetchNotificationsRequesting} opacity={1}>
            <CircularProgress thickness={2} />
          </Loader>
          {!!notificationsToShow?.length ? (
            <>
              <Box ml={4} mr={{ xs: 4, md: 8, lg: 16 }}>
                <Typography className={classes.title} variant="bigh5" color="primary">
                  {t('notifications.lastNotificationsReceived')}
                </Typography>
              </Box>
              <List>
                {notificationsToShow.map((notification, index) => {
                  return (
                    <>
                      {index === 0 && <Divider />}
                      <NotificationItem
                        date={notification.date}
                        time={notification.time}
                        message={notification.notification}
                        isManaged={notification.managed}
                        orderId={notification.orderId}
                        orderCrmId={notification.orderCrmId}
                        orderState={notification.orderState}
                        onClickAway={onClickAway}
                      />
                      <Divider />
                    </>
                  )
                })}
              </List>
              <Box py={1} pr={4} display="flex" alignItems="center" justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={handleShowNotifications}>
                  {t('notifications.showAllNotifications')}
                </Button>
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="center" mx={4} mb={1}>
              <Typography className={classes.title} variant="bigh5" color="primary">
                No hay notificaciones que mostrar
              </Typography>
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </Popover>
  )
}

export default NotificationsPopover
