import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5 / 8),
    border: `${theme.border.style.thin} solid ${theme.palette.primary.light3}`,
    boxShadow: theme.shadows[3],
    transition: 'none',
    '& .MuiIconButton-label': {
      [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },
    },
    '&.selected': {
      boxShadow: `0 0 0 ${theme.border.style.medium} ${theme.palette.primary.main}`,
      borderColor: 'transparent',
    },
    '&.disabled': {
      boxShadow: `0 0 0 ${theme.border.style.medium} ${theme.palette.primary.main}`,
      borderColor: 'transparent',
    },
    cursor: 'pointer',
    '& ul': {
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        padding: '0',
      },
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 0, 7),
    },
    padding: theme.spacing(3, 2, 0, 9),
  },
  radioContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  selected: {
    backgroundColor: theme.palette.primary.light3,
  },
  disabled: {
    backgroundColor: theme.palette.neutral.light6,
    cursor: 'not-allowed',
    opacity: 0.5,
  },
}))

export default useStyles
