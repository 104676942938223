import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { showModal } from 'services/modal/actions'
import { getIsSuperAdmin, getIsDocoutFailed, getIsRoleChannel, getIsPortability } from 'services/operator/selectors'
import { getIsAutosave } from 'services/customer/selectors'

import { MODAL_TYPES } from 'services/modal/constants'

import { Link, useLocation } from 'react-router-dom'
import i18n from '../../i18n'
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  SvgIcon,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import AdminPanelIcon from '@material-ui/icons/BusinessCenter'
import DashboardIcon from '@material-ui/icons/Dashboard'
import InfoIcon from '@material-ui/icons/Info'
import SearchIcon from '@mui/icons-material/Search'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import StorefrontIcon from '@material-ui/icons/Storefront'
import EditIcon from '@material-ui/icons/Edit'

import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import Save from '@material-ui/icons/Save'
import { ReactComponent as IconPhone } from '@zatopek/core/src/assets/icons/phone.svg'
import { ReactComponent as IconCheck } from '@zatopek/core/src/assets/icons/check.svg'
import logo from '@zatopek/core/src/assets/images/logo-naturgy.svg'

import { NATURGY_HOME_URL } from '@zatopek/core/src/utils/constants'
import {
  BACKOFFICE_MANUAL_URL,
  COMMERCIAL_MANUAL_URL,
  PYMES_MANUAL_URL,
  ADMIN_PANEL_MANUAL_URL,
  BACKOFFICE,
  CHANNELS_MANUAL_URL,
  GA_EVENT_NAMES,
} from 'utils/constants'

import ManualItem from 'ui/components/manualItem'
import useStyles from './styles'
import NotificationsPopover from 'ui/components/notificationsPopover'
import { fetchTotalUnmanagedNotifications } from 'services/backoffice/actions'
import { getTotalUnmanagedNotifications } from 'services/backoffice/selectors'
import { sendGAEvent } from 'services/analytics/actions'

const Header = ({ logout, isLoggedIn, progress, showProgress, isOnlineChannel, openCMBModal, noActions, onClickLogo }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const [langAnchorEl, setLangAnchorEl] = useState(null)
  const [langOpen, setLangOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null)

  const isAdmin = useSelector(getIsSuperAdmin)
  const isFailDocout = useSelector(getIsDocoutFailed)
  const isPortability = useSelector(getIsPortability)
  const isChannelRole = useSelector(getIsRoleChannel)
  const isControlPanelAuthorized = isAdmin || isChannelRole
  const unmanagedNotifications = useSelector(getTotalUnmanagedNotifications)
  const isBackoffice = window.location.href.includes(BACKOFFICE)
  const isAutosave = useSelector(getIsAutosave)

  const showCMBButton = isOnlineChannel && !noActions
  const notificationsPopoverId = !!notificationsAnchorEl ? 'notifications-popover' : undefined

  const openSaveDraft = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.autoSave))
    dispatch(showModal({ modalType: MODAL_TYPES.autosave }))
    dispatch(sendGAEvent(GA_EVENT_NAMES.autoSavePopUp))
  }

  const handleLangClick = (event) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.menuLanguage))
    setLangAnchorEl(event.currentTarget)
    //dispatch(sendGAEvent(GA_EVENT_NAMES.selectionLanguage, { idioma: event.currentTarget }))
    setLangOpen(true)
  }

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setLangOpen(false)
    setMenuOpen(false)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    let lngAux = lng === 'es' ? 'castellano' : lng === 'gl' ? 'gallego' : 'catala'
    dispatch(sendGAEvent(GA_EVENT_NAMES.selectionLanguage, { idioma: lngAux }))
    handleClose()
  }

  const handleNotificationsClick = (e) => {
    setNotificationsAnchorEl(notificationsAnchorEl ? null : e.currentTarget)
  }

  const handleClickAway = () => {
    setNotificationsAnchorEl(null)
  }

  const menuItems = [
    {
      to: '/dashboard',
      onClick: handleClose,
      text: 'common.contracting',
      icon: <HomeRoundedIcon />,
      shouldShow: true,
    },
    {
      to: '/backoffice',
      onClick: handleClose,
      text: 'common.backoffice',
      icon: <StorefrontIcon />,
      shouldShow: true,
    },
    {
      to: '/portability',
      onClick: handleClose,
      text: 'common.portability',
      icon: <EditIcon />,
      shouldShow: !!isPortability,
    },
    {
      to: '/failed-management',
      onClick: handleClose,
      text: 'common.failedManagement',
      icon: <EditIcon />,
      shouldShow: !!isFailDocout,
    },
    {
      to: '/settings',
      onClick: handleClose,
      text: 'common.settings',
      icon: <SettingsIcon />,
      shouldShow: true,
    },
    {
      to: '/control-panel',
      onClick: handleClose,
      text: 'common.panel-control',
      icon: <DashboardIcon />,
      shouldShow: !!isControlPanelAuthorized,
    },
    {
      to: '/admin-panel',
      onClick: handleClose,
      text: 'common.administration',
      icon: <AdminPanelIcon />,
      shouldShow: !!isAdmin,
    },
    {
      to: '/technical-info',
      onClick: handleClose,
      text: 'common.technicalInfo',
      icon: <InfoIcon />,
      shouldShow: true,
    },
    {
      to: '/current-state',
      onClick: handleClose,
      text: 'common.currentState',
      icon: <SearchIcon />,
      shouldShow: true,
    },
  ]

  const manualItems = [
    {
      manualUrl: COMMERCIAL_MANUAL_URL,
      text: 'manual.commercial',
      shouldShow: true,
    },
    {
      manualUrl: PYMES_MANUAL_URL,
      text: 'manual.pymes',
      shouldShow: true,
    },
    {
      manualUrl: BACKOFFICE_MANUAL_URL,
      text: 'manual.backoffice',
      shouldShow: true,
    },

    {
      manualUrl: ADMIN_PANEL_MANUAL_URL,
      text: 'manual.administration',
      shouldShow: !!isAdmin,
    },
    {
      manualUrl: CHANNELS_MANUAL_URL,
      text: 'manual.channels',
      shouldShow: true,
    },
  ]

  const logoAnchor = (
    //eslint-disable-next-line
    <a href={NATURGY_HOME_URL} rel="logo" target="_blank" onClick={onClickLogo}>
      <img src={logo} alt="logo naturgy" />
    </a>
  )
  const logoLink = (
    <Link to="/dashboard" alt="logo naturgy" onClick={onClickLogo}>
      <img src={logo} alt="logo naturgy" />
    </Link>
  )
  const logoElement = isOnlineChannel || location.pathname.includes('current-state') ? logoAnchor : logoLink

  useEffect(() => {
    if (isBackoffice) {
      dispatch(fetchTotalUnmanagedNotifications())
    }
  }, [isBackoffice, dispatch])

  return (
    // TODO: las opciones del menu deberían estar en un array para que se puedan modificar mejor
    <>
      <Box component="header" className={classes.root}>
        {showProgress && <LinearProgress className={classes.progress} variant="determinate" color="primary" value={progress} />}
        <Container maxWidth="lg">
          <AppBar position="static" color="transparent" className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
              {logoElement}
              <Box className={classes.actions}>
                {isOnlineChannel && (
                  <Button disabled={isAutosave} variant="text" endIcon={<Save />} onClick={openSaveDraft}>
                    {t('common.autosave')}
                    {isAutosave && <SvgIcon component={IconCheck} className={classes.checkIcon} />}
                  </Button>
                )}
                {!noActions && (
                  <>
                    {isBackoffice && (
                      <>
                        <Button className={classes.notificationButton} onClick={handleNotificationsClick}>
                          <Badge
                            color="secondary"
                            badgeContent={unmanagedNotifications}
                            children={<NotificationsOutlinedIcon color="primary" />}
                          />
                        </Button>

                        <NotificationsPopover
                          notificationsPopoverId={notificationsPopoverId}
                          notificationsAnchorEl={notificationsAnchorEl}
                          onClickAway={handleClickAway}
                        />
                      </>
                    )}

                    <Button
                      variant="text"
                      className={classes.mobileButton}
                      aria-label="more"
                      aria-controls="languages"
                      aria-haspopup="true"
                      onClick={handleLangClick}
                      endIcon={<KeyboardArrowDownRoundedIcon />}
                    >
                      {t(`language.${i18n.language}`)}
                    </Button>
                    <Menu
                      id="languages"
                      anchorEl={langAnchorEl}
                      keepMounted
                      open={langOpen}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={() => changeLanguage('es')}>{t('language.spanish')}</MenuItem>
                      <MenuItem onClick={() => changeLanguage('gl')}>{t('language.galician')}</MenuItem>
                      <MenuItem onClick={() => changeLanguage('ca')}>{t('language.catalan')}</MenuItem>
                    </Menu>
                  </>
                )}
                {isLoggedIn ? (
                  <>
                    <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick}>
                      <MenuIcon color="primary" />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={menuAnchorEl}
                      keepMounted
                      open={menuOpen}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {menuItems
                        .filter((menuItem) => menuItem.shouldShow)
                        .map(({ to, onClick, text, icon }) => (
                          <MenuItem key={text} component={Link} onClick={onClick} to={to}>
                            <ListItemText primary={t(text)} />
                            <ListItemIcon>{icon}</ListItemIcon>
                          </MenuItem>
                        ))}
                      <Divider />
                      <MenuItem className={classes.helpItem}>
                        <ListItemText primary={t('common.help')} />
                      </MenuItem>
                      {manualItems
                        .filter((menuItem) => menuItem.shouldShow)
                        .map(({ manualUrl, text }) => (
                          <ManualItem key={text} manualUrl={manualUrl} manualText={t(text)} />
                        ))}
                      <Divider />
                      <MenuItem onClick={logout}>
                        <ListItemText primary={t('common.signOut')} />
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </>
                ) : showCMBButton ? (
                  <Button variant="text" className={classes.hideableIcon} endIcon={<IconPhone />} onClick={openCMBModal}>
                    {t('common.weCallYou')}
                  </Button>
                ) : null}
              </Box>
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
      <div className={classes.topSpacer} />
    </>
  )
}

export default Header
