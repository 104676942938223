import React from 'react'

import { Box, DialogContent } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const TechnicalData = ({ children }) => {
  const classes = useStyles()

  return (
    <DialogContent className={classes.dialogContent}>
      <Box mb={2}>
        <Typography variant="h3" color="primary">
          Datos del punto de suministro
        </Typography>
      </Box>
      {children}
    </DialogContent>
  )
}

export default TechnicalData
