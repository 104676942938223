import * as actionTypes from './action-types'

export const loadContractStatusStart = () => ({
  type: actionTypes.LOAD_CONTRACT_STATUS_START,
})

export const loadContractStatusSuccess = () => ({
  type: actionTypes.LOAD_CONTRACT_STATUS_SUCCESS,
})

export const loadContractStatusError = () => ({
  type: actionTypes.LOAD_CONTRACT_STATUS_ERROR,
})

export const fetchContractStatus = () => ({
  type: actionTypes.FETCH_CONTRACT_STATUS,
})

export const setContractStatus = (contract) => ({
  type: actionTypes.SET_CONTRACT_STATUS,
  payload: contract,
})

export const clearContractStatus = () => ({
  type: actionTypes.CLEAR_CONTRACT_STATUS,
})
