import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'

import TechnicalDataView from 'ui/modals/technicalData'
import TechnicalDataForm from 'ui/forms/technicalDataForm'

import { Box, Button } from '@material-ui/core'
import { setSupplyPoint } from 'services/customer/actions'
import { getSupplyPoint } from 'services/customer/selectors'
import { validateActivationDate } from 'utils/validators'

const TechnicalData = ({ handleClose, data: { technicalInfo, ownerModal, selectedService } }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const supplyPoint = useSelector(getSupplyPoint)
  const initialValues = {
    accessTariff: technicalInfo?.accessTariff || '',
    consumption12Months: technicalInfo?.consumption12Months?.toString().split(',')?.[0] || '',
    capacityP1:
      parseFloat(typeof technicalInfo?.capacityP1 === 'string' ? technicalInfo?.capacityP1?.replace(/,/g, '.') : '') || '',
    capacityP2:
      parseFloat(typeof technicalInfo?.capacityP2 === 'string' ? technicalInfo?.capacityP2?.replace(/,/g, '.') : '') || '',
    capacityP3:
      parseFloat(typeof technicalInfo?.capacityP3 === 'string' ? technicalInfo?.capacityP3?.replace(/,/g, '.') : '') || '',
    capacityP4:
      parseFloat(typeof technicalInfo?.capacityP4 === 'string' ? technicalInfo?.capacityP4?.replace(/,/g, '.') : '') || '',
    capacityP5:
      parseFloat(typeof technicalInfo?.capacityP5 === 'string' ? technicalInfo?.capacityP5?.replace(/,/g, '.') : '') || '',
    capacityP6:
      parseFloat(typeof technicalInfo?.capacityP6 === 'string' ? technicalInfo?.capacityP6?.replace(/,/g, '.') : '') || '',
    activationDate: '',
  }

  const handleSubmit = (values) => {
    if (values.activationDate) {
      const supplyPoints = supplyPoint.map((supply) => {
        if (supply.cups === technicalInfo?.cups) {
          supply.activationDate = values.activationDate
        }
        return supply
      })
      dispatch(setSupplyPoint(supplyPoints))
    }

    if (ownerModal) {
      handleClose()
      ownerModal(selectedService)
    } else {
      handleClose()
    }
  }

  const validateForm = (values) => {
    let errors = {}

    if (values.activationDate && !validateActivationDate(values.activationDate)) {
      errors.activationDate = t('form.errors.invalidActivateDate')
    }

    return errors
  }

  return (
    <TechnicalDataView>
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit} enableReinitialize>
        {({ values, handleChange, touched, errors, setFieldValue, setFieldError }) => (
          <Form noValidate autoComplete="off">
            <TechnicalDataForm
              values={values}
              energyType={technicalInfo?.energyType}
              onChange={handleChange}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
            />

            <Box display="flex" justifyContent="flex-start" alignItems="center" mt={5} mb={3}>
              <Button type="submit" variant="contained" color="secondary">
                {t('common.continue')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </TechnicalDataView>
  )
}

export default TechnicalData
