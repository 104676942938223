import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import Typography from 'ui/components/typography'
import loadingImage from '@zatopek/core/src/assets/images/loader-mariposa.gif'
import useStyles from './styles'

const ScanInvoiceFormLoading = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Box mb={4}>
        <img src={loadingImage} alt="loading" className={classes.loadingImage} />
        <Typography className={classes.description} variant="basic" color="textPrimary">
          {t('uploadLoadingInfo')}
        </Typography>
      </Box>
    </>
  )
}

export default ScanInvoiceFormLoading
