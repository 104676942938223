import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@material-ui/core'
import { uploadInvoiceClear } from 'services/invoice/actions'
import Typography from 'ui/components/typography'
import useStyles from './styles'

const ScanInvoiceFormError = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onRetry = () => {
    dispatch(uploadInvoiceClear())
  }

  const onClose = () => {
    dispatch(uploadInvoiceClear())
    handleClose()
  }

  return (
    <>
      <Box mb={4}>
        <Typography className={classes.description} variant="basic" color="textPrimary">
          {t('errorReadingFile')}
        </Typography>
      </Box>

      <Box className={classes.buttonsContainer} display="flex" alignItems="center" mb={4}>
        <Button onClick={() => onClose()} variant="outlined" color="primary">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => onRetry()} variant="contained" color="secondary">
          {t('common.retry')}
        </Button>
      </Box>
    </>
  )
}

export default ScanInvoiceFormError
