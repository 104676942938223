import * as actionTypes from './action-types'

export const setSummary = (summary) => ({
  type: actionTypes.SET_SUMMARY,
  payload: summary,
})

export const setOrders = (summary) => ({
  type: actionTypes.SET_ORDERS,
  payload: summary,
})

export const setOrdersFail = (summary) => ({
  type: actionTypes.SET_ORDERS_FAIL,
  payload: summary,
})

export const setProducts = (summary) => ({
  type: actionTypes.SET_PRODUCTS,
  payload: summary,
})

export const setNotifications = (notifications) => ({
  type: actionTypes.SET_NOTIFICATIONS,
  payload: notifications,
})

export const setTotalOrders = (total) => ({
  type: actionTypes.SET_TOTAL_ORDERS,
  payload: total,
})

export const setTotalOrdersFail = (total) => ({
  type: actionTypes.SET_TOTAL_ORDERS_FAIL,
  payload: total,
})

export const setTotalProducts = (total) => ({
  type: actionTypes.SET_TOTAL_ORDER_LINES,
  payload: total,
})

export const setTotalNotifications = (total) => ({
  type: actionTypes.SET_TOTAL_NOTIFICATIONS,
  payload: total,
})

export const fetchTotalUnmanagedNotifications = () => ({
  type: actionTypes.FETCH_TOTAL_UNMANAGED_NOTIFICATIONS,
})

export const setTotalUnmanagedNotifications = (totalUnmanagedNotifications) => ({
  type: actionTypes.SET_TOTAL_UNMANAGED_NOTIFICATIONS,
  payload: totalUnmanagedNotifications,
})

export const updateOrderSummary = (id, summary) => ({
  type: actionTypes.UPDATE_ORDER_SUMMARY,
  payload: {
    id,
    data: {
      state: id,
      ...summary,
    },
  },
})

export const updateProductSummary = (id, summary) => ({
  type: actionTypes.UPDATE_PRODUCT_SUMMARY,
  payload: {
    id,
    data: {
      state: id,
      ...summary,
    },
  },
})

export const fetchOrdersSummary = () => ({
  type: actionTypes.FETCH_ORDER_SUMMARY,
})

export const fetchProductsSummary = () => ({
  type: actionTypes.FETCH_PRODUCT_SUMMARY,
})

export const fetchOrders = () => ({
  type: actionTypes.FETCH_ORDERS,
})

export const fetchOrdersFail = () => ({
  type: actionTypes.FETCH_ORDERS_FAIL,
})

export const fetchOrderLines = () => ({
  type: actionTypes.FETCH_ORDER_LINES,
})

export const fetchOrderDetail = (id) => ({
  type: actionTypes.FETCH_ORDER_DETAIL,
  payload: { id },
})

export const fetchOrderDetailFail = (id) => ({
  type: actionTypes.FETCH_ORDER_DETAIL_FAIL,
  payload: { id },
})
export const fetchNotifications = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS,
})

export const fetchNotificationsStart = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_START,
})

export const fetchNotificationsSuccess = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
})

export const fetchNotificationsError = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_ERROR,
})

export const fetchNotificationsClear = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_CLEAR,
})

export const updateOrderDetail = (id, detail) => ({
  type: actionTypes.UPDATE_ORDER_DETAIL,
  payload: {
    id,
    data: detail,
  },
})

export const fetchFiltersConfiguration = () => ({
  type: actionTypes.FETCH_FILTERS_CONFIGURATION,
})

export const updateFilterConfiguration = (id, config) => ({
  type: actionTypes.UPDATE_FILTER_CONFIG,
  payload: {
    id,
    data: config,
  },
})

export const exportCSV = (selectedItems) => ({
  type: actionTypes.EXPORT_CSV,
  payload: selectedItems,
})

export const exportCSVFailed = (selectedItems) => ({
  type: actionTypes.EXPORT_CSV_FAILED,
  payload: selectedItems,
})

export const exportFileStart = () => ({
  type: actionTypes.EXPORT_REQUEST_START,
})

export const exportFileSuccess = () => ({
  type: actionTypes.EXPORT_REQUEST_SUCCESS,
})
export const exportFileError = () => ({
  type: actionTypes.EXPORT_REQUEST_ERROR,
})

export const saveCSV = (file, name) => ({
  type: actionTypes.SAVE_CSV,
  payload: {
    file,
    name,
  },
})

export const createCSV = (file) => ({
  type: actionTypes.CREATE_CSV,
  payload: {
    file,
  },
})

export const exportOrderAttachmentsId = (orderId) => ({
  type: actionTypes.ORDER_ATTACHMENTS_EXPORT_ID,
  payload: orderId,
})

export const exportOrderAttachments = (orderId) => ({
  type: actionTypes.EXPORT_ORDER_ATTACHMENTS,
  payload: orderId,
})

export const exportOrderAttachmentsStart = () => ({
  type: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_START,
})

export const exportOrderAttachmentsSuccess = () => ({
  type: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_SUCCESS,
})

export const exportOrderAttachmentsError = () => ({
  type: actionTypes.ORDER_ATTACHMENTS_EXPORT_REQUEST_ERROR,
})

export const cancelOrder = (leadId, cancelReason, cancelDetails) => ({
  type: actionTypes.CANCEL_ORDER,
  payload: { leadId, cancelReason, cancelDetails },
})

export const cancelOrderClear = () => ({
  type: actionTypes.CANCEL_ORDER_CLEAR,
})

export const cancelOrderStart = () => ({
  type: actionTypes.CANCEL_ORDER_START,
})

export const cancelOrderSuccess = () => ({
  type: actionTypes.CANCEL_ORDER_SUCCESS,
})

export const cancelOrderError = () => ({
  type: actionTypes.CANCEL_ORDER_ERROR,
})

export const verifyOrder = (orderId) => ({
  type: actionTypes.VERIFY_ORDER,
  payload: orderId,
})

export const verifyOrderClear = () => ({
  type: actionTypes.VERIFY_ORDER_CLEAR,
})

export const verifyOrderStart = () => ({
  type: actionTypes.VERIFY_ORDER_START,
})

export const verifyOrderSuccess = () => ({
  type: actionTypes.VERIFY_ORDER_SUCCESS,
})

export const verifyOrderError = () => ({
  type: actionTypes.VERIFY_ORDER_ERROR,
})

export const relaunchOrderStart = (orderId) => ({
  type: actionTypes.RELAUNCH_ORDER_START,
  payload: { orderId },
})

export const relaunchOrderClear = () => ({
  type: actionTypes.RELAUNCH_ORDER_CLEAR,
})

export const relaunchOrderSuccess = () => ({
  type: actionTypes.RELAUNCH_ORDER_SUCCESS,
})

export const relaunchOrderError = () => ({
  type: actionTypes.RELAUNCH_ORDER_ERROR,
})

export const setOrderStateFailedWithoutRecovery = (orderId) => ({
  type: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_START,
  payload: { orderId },
})

export const setOrderStateFailedWithoutRecoveryClear = () => ({
  type: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_CLEAR,
})

export const setOrderStateFailedWithoutRecoverySuccess = () => ({
  type: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_SUCCESS,
})

export const setOrderStateFailedWithoutRecoveryError = () => ({
  type: actionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_ERROR,
})

export const loadBackofficeStart = () => ({
  type: actionTypes.LOAD_BACKOFFICE_START,
})

export const loadBackofficeSuccess = () => ({
  type: actionTypes.LOAD_BACKOFFICE_SUCCESS,
})

export const loadBackofficeError = () => ({
  type: actionTypes.LOAD_BACKOFFICE_ERROR,
})

export const loadBackofficeClear = () => ({
  type: actionTypes.LOAD_BACKOFFICE_CLEAR,
})

export const fetchOrderSummaryStart = () => ({
  type: actionTypes.FETCH_ORDER_SUMMARY_START,
})

export const fetchOrderSummarySuccess = () => ({
  type: actionTypes.FETCH_ORDER_SUMMARY_SUCCESS,
})

export const fetchOrderSummaryError = () => ({
  type: actionTypes.FETCH_ORDER_SUMMARY_ERROR,
})

export const fetchOrderSummaryClear = () => ({
  type: actionTypes.FETCH_ORDER_SUMMARY_CLEAR,
})

export const productsSummaryStart = () => ({
  type: actionTypes.PRODUCTS_SUMMARY_START,
})

export const productsSummarySuccess = () => ({
  type: actionTypes.PRODUCTS_SUMMARY_SUCCESS,
})

export const productsSummaryError = () => ({
  type: actionTypes.PRODUCTS_SUMMARY_ERROR,
})

export const productsSummaryClear = () => ({
  type: actionTypes.PRODUCTS_SUMMARY_CLEAR,
})

export const fetchTableDataStart = () => ({
  type: actionTypes.FETCH_TABLE_DATA_START,
})

export const fetchTableDataSuccess = () => ({
  type: actionTypes.FETCH_TABLE_DATA_SUCCESS,
})

export const fetchTableDataError = () => ({
  type: actionTypes.FETCH_TABLE_DATA_ERROR,
})

export const fetchTableDataClear = () => ({
  type: actionTypes.FETCH_TABLE_DATA_CLEAR,
})

export const setSearchFilters = (newFilters) => ({
  type: actionTypes.SET_SEARCH_FILTERS,
  payload: newFilters,
})

export const clearSearchFilters = () => ({
  type: actionTypes.CLEAR_SEARCH_FILTERS,
})

export const setSelectedTab = (selectedTab) => ({
  type: actionTypes.SET_SELECTED_TAB,
  payload: selectedTab,
})

export const clearSelectedTab = () => ({
  type: actionTypes.CLEAR_SELECTED_TAB,
})

export const setSelectedExportTemplateId = (selectedExportTemplateId) => ({
  type: actionTypes.SET_SELECTED_EXPORT_TEMPLATE_ID,
  payload: selectedExportTemplateId,
})

export const clearSelectedExportTemplateId = () => ({
  type: actionTypes.CLEAR_SELECTED_EXPORT_TEMPLATE_ID,
})

export const deleteNotifications = (notificationIds) => ({
  type: actionTypes.DELETE_NOTIFICATIONS,
  payload: notificationIds,
})

export const deleteNotificationsStart = () => ({
  type: actionTypes.DELETE_NOTIFICATIONS_START,
})

export const deleteNotificationsSuccess = () => ({
  type: actionTypes.DELETE_NOTIFICATIONS_SUCCESS,
})

export const deleteNotificationsError = () => ({
  type: actionTypes.DELETE_NOTIFICATIONS_ERROR,
})

export const deleteNotificationsClear = () => ({
  type: actionTypes.DELETE_NOTIFICATIONS_CLEAR,
})

export const manageNotification = (notificationId, managed) => ({
  type: actionTypes.MANAGE_NOTIFICATION,
  payload: { notificationId, managed },
})

export const manageNotifications = (notificationIds) => ({
  type: actionTypes.MANAGE_NOTIFICATIONS,
  payload: { notificationIds },
})

export const manageNotificationsStart = () => ({
  type: actionTypes.MANAGE_NOTIFICATIONS_START,
})

export const manageNotificationsSuccess = () => ({
  type: actionTypes.MANAGE_NOTIFICATIONS_SUCCESS,
})

export const manageNotificationsError = () => ({
  type: actionTypes.MANAGE_NOTIFICATIONS_ERROR,
})

export const manageNotificationsClear = () => ({
  type: actionTypes.MANAGE_NOTIFICATIONS_CLEAR,
})

export const setNotificationState = (notification) => ({
  type: actionTypes.SET_NOTIFICATION_STATE,
  payload: notification,
})

export const clearNotificationState = () => ({
  type: actionTypes.CLEAR_NOTIFICATION_STATE,
})

export const setErrorNotification = (notification) => ({
  type: actionTypes.SET_ERROR_NOTIFICATION,
  payload: notification,
})

export const clearErrorNotification = () => ({
  type: actionTypes.CLEAR_ERROR_NOTIFICATION,
})
