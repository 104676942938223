import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2, 3, 6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7, 6),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7, 6),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
  addEmailInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    marginBottom: '20px',
  },
}))

export default useStyles
