import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  uploadInvoice: redukers.request({
    start: actionTypes.UPLOAD_INVOICE_START,
    success: actionTypes.UPLOAD_INVOICE_SUCCESS,
    error: actionTypes.UPLOAD_INVOICE_ERROR,
    clear: actionTypes.UPLOAD_INVOICE_CLEAR,
  }),
  data: redukers.value(
    {
      set: actionTypes.SET_INVOICE_DATA,
      reset: actionTypes.CLEAR_INVOICE_DATA,
    },
    {
      0: null,
    }
  ),
})
