import * as actionTypes from './action-types'

export const submitOwnerStep = (customerData) => ({
  type: actionTypes.SUBMIT_OWNER_STEP,
  payload: customerData,
})

export const submitOwnerStepDuplicate = (customerData) => ({
  type: actionTypes.SUBMIT_OWNER_STEP_DUPLICATE,
  payload: customerData,
})
export const submitOwnerStepDraft = (ownerData) => ({
  type: actionTypes.SUBMIT_OWNER_STEP_DRAFT,
  payload: ownerData,
})

export const submitBillingStep = () => ({
  type: actionTypes.SUBMIT_BILLING_STEP,
})
export const setChangeSupplyOwnerShip = (payload) => ({
  type: actionTypes.SET_CHANGE_SUPPLY_OWNERSHIP,
  payload,
})

export const clearChangeSupplyOwnerShip = () => ({
  type: actionTypes.CLEAR_CHANGE_SUPPLY_OWNERSHIP,
})

export const setCustomer = (customerData) => ({
  type: actionTypes.SET_DATA,
  payload: customerData,
})

export const clearCustomer = () => ({
  type: actionTypes.CLEAR_DATA,
})

export const setBillingAccount = (billingData) => ({
  type: actionTypes.SET_BILLING_ACCOUNT,
  payload: billingData,
})

export const clearBillingAccount = () => ({
  type: actionTypes.CLEAR_BILLING_ACCOUNT,
})

export const setBillingAddress = (billingData) => ({
  type: actionTypes.SET_BILLING_ADDRESS,
  payload: billingData,
})

export const clearBillingAddress = () => ({
  type: actionTypes.CLEAR_BILLING_ADDRESS,
})

export const setOnlineInvoice = (onlineInvoice) => ({
  type: actionTypes.SET_ONLINE_INVOICE,
  payload: onlineInvoice,
})

export const clearOnlineInvoice = () => ({
  type: actionTypes.CLEAR_ONLINE_INVOICE,
})

export const setSupplyPoint = (supplyPointData) => ({
  type: actionTypes.SET_SUPPLY_POINT,
  payload: supplyPointData,
})

export const clearSupplyPoints = () => ({
  type: actionTypes.CLEAR_SUPPLY_POINTS,
})

export const setReferralCode = (referralCode) => ({
  type: actionTypes.SET_REFERRAL_CODE,
  payload: referralCode,
})

export const clearReferralCode = () => ({
  type: actionTypes.CLEAR_REFERRAL_CODE,
})

export const setProductReferralCode = (productReferralCode) => ({
  type: actionTypes.SET_PRODUCT_REFERRAL_CODE,
  payload: productReferralCode,
})

export const clearProductReferralCode = () => ({
  type: actionTypes.CLEAR_PRODUCT_REFERRAL_CODE,
})

export const setBuyapowaCode = (buyapowaCode) => ({
  type: actionTypes.SET_BUYAPOWA_CODE,
  payload: buyapowaCode,
})

export const clearBuyapowaCode = () => ({
  type: actionTypes.CLEAR_BUYAPOWA_CODE,
})

export const fetchUserData = (documentNumber, documentType) => ({
  type: actionTypes.USER_DATA_REQUEST_START,
  payload: { documentNumber, documentType },
})

export const userDataSuccess = () => ({
  type: actionTypes.USER_DATA_REQUEST_SUCCESS,
})

export const userDataError = (error) => ({
  type: actionTypes.USER_DATA_REQUEST_ERROR,
  payload: error,
})

export const setUserData = (userData) => ({
  type: actionTypes.SET_USER_DATA,
  payload: userData,
})

export const resetUserData = () => ({
  type: actionTypes.RESET_USER_DATA,
})

export const setSelectedTenure = (selectedTenure) => ({
  type: actionTypes.SET_SELECTED_TENURE,
  payload: selectedTenure,
})

export const setSelectedCompany = (selectedCompany) => ({
  type: actionTypes.SET_SELECTED_COMPANY,
  payload: selectedCompany,
})

export const clearSelectedTenure = () => ({
  type: actionTypes.CLEAR_SELECTED_TENURE,
})

export const clearSelectedCompany = () => ({
  type: actionTypes.CLEAR_SELECTED_COMPANY,
})

export const setSupplyPointByIdCal = (idCal, idCalSecond) => ({
  type: actionTypes.SET_SUPPLY_POINT_BY_ID_CAL,
  payload: { idCal, idCalSecond },
})

export const setNormalizedAddress = (normalizedAddress) => ({
  type: actionTypes.SET_NORMALIZED_ADDRESS,
  payload: normalizedAddress,
})

export const clearNormalizedAddress = () => ({
  type: actionTypes.CLEAR_NORMALIZED_ADDRESS,
})

export const setSupplyAddress = (supplyAddress) => ({
  type: actionTypes.SET_SUPPLY_ADDRESS,
  payload: supplyAddress,
})

export const clearSupplyAddress = () => ({
  type: actionTypes.CLEAR_SUPPLY_ADDRESS,
})

export const setIsChangeBillingAddress = (value) => ({
  type: actionTypes.SET_CHANGE_BILLING_ADDRESS,
  payload: value,
})

export const clearIsChangeBillingAddress = () => ({
  type: actionTypes.CLEAR_CHANGE_BILLING_ADDRESS,
})

export const startTenureSelection = (selectedTenure) => ({
  type: actionTypes.START_TENURE_SELECTION,
  payload: selectedTenure,
})

export const startCompanySelection = (selectedCompany) => ({
  type: actionTypes.START_COMPANY_SELECTION,
  payload: selectedCompany,
})

export const setTenureSelectionError = (isTenureSelectionError) => ({
  type: actionTypes.SET_TENURE_SELECTION_ERROR,
  payload: isTenureSelectionError,
})

export const clearTenureSelectionError = () => ({
  type: actionTypes.CLEAR_TENURE_SELECTION_ERROR,
})

export const clearUserDataRequest = () => ({
  type: actionTypes.USER_DATA_REQUEST_CLEAR,
})

export const setExClient = (isExClient) => ({
  type: actionTypes.SET_EX_CLIENT,
  payload: isExClient,
})

export const resetExClient = () => ({
  type: actionTypes.RESET_EX_CLIENT,
})

export const setIsNewSupply = (isNewSupply) => ({
  type: actionTypes.SET_IS_NEW_SUPPLY_POINT,
  payload: isNewSupply,
})

export const clearIsNewSupply = () => ({
  type: actionTypes.CLEAR_IS_NEW_SUPPLY_POINT,
})

export const setCompany = (company) => ({
  type: actionTypes.SET_COMPANY,
  payload: company,
})

export const clearCompany = () => ({
  type: actionTypes.CLEAR_COMPANY,
})

export const setOnBehalfContactId = (id) => ({
  type: actionTypes.SET_ON_BEHALF_CONTACT_ID,
  payload: id,
})

export const clearOnBehalfContactId = () => ({
  type: actionTypes.CLEAR_ON_BEHALF_CONTACT_ID,
})

export const setBirthdayDate = (birthdayDate) => ({
  type: actionTypes.SET_BIRTHDAY_DATE,
  payload: birthdayDate,
})

export const clearBirthdayDate = () => ({
  type: actionTypes.CLEAR_BIRTHDAY_DATE,
})

export const setIsMultiPoint = () => ({
  type: actionTypes.SET_IS_MULTI_POINT,
  payload: true,
})

export const clearIsMultiPoint = () => ({
  type: actionTypes.CLEAR_IS_MULTI_POINT,
})

export const setAutosave = (payload) => ({
  type: actionTypes.SET_AUTOSAVE,
  payload,
})

export const setDifferentBillingAddress = (payload) => ({
  type: actionTypes.SET_DIFFERENT_BILLING_ADDRESS,
  payload,
})

export const clearDifferentBillingAddress = () => ({
  type: actionTypes.CLEAR_DIFFERENT_BILLING_ADDRESS,
})

export const setCupsOK = (payload) => ({
  type: actionTypes.SET_CUPS_OK,
  payload,
})

export const setSelfconsumptionType = (payload) => ({
  type: actionTypes.SET_SELFCONSUMPTION_TYPE,
  payload,
})

export const clearSelfconsumptionType = () => ({
  type: actionTypes.CLEAR_SELFCONSUMPTION_TYPE,
})

export const setInitialSelfconsumptionType = (payload) => ({
  type: actionTypes.SET_INITIAL_SELFCONSUMPTION_TYPE,
  payload,
})

export const clearInitialSelfconsumptionType = () => ({
  type: actionTypes.CLEAR_INITIAL_SELFCONSUMPTION_TYPE,
})

export const setSignUpForSelfConsumption = (payload) => ({
  type: actionTypes.SET_SIGNUP_FOR_SELFCONSUMPTION,
  payload,
})

export const clearSignUpForSelfConsumption = () => ({
  type: actionTypes.CLEAR_SIGNUP_FOR_SELFCONSUMPTION,
})

export const setCollective = (payload) => ({
  type: actionTypes.SET_COLLECTIVE,
  payload,
})

export const clearCollective = () => ({
  type: actionTypes.CLEAR_COLLECTIVE,
})

export const setSubsection = (payload) => ({
  type: actionTypes.SET_SUBSECTION,
  payload,
})

export const clearSubsection = () => ({
  type: actionTypes.CLEAR_SUBSECTION,
})

export const setInstalledPower = (payload) => ({
  type: actionTypes.SET_INSTALLED_POWER,
  payload,
})

export const clearInstalledPower = () => ({
  type: actionTypes.CLEAR_INSTALLED_POWER,
})

export const setCupsType = (payload) => ({
  type: actionTypes.SET_CUPS_TYPE,
  payload,
})

export const clearCupsType = () => ({
  type: actionTypes.CLEAR_CUPS_TYPE,
})

export const setIsCIERequired = (payload) => ({
  type: actionTypes.SET_IS_CIE_REQUIRED,
  payload,
})

export const setRecordUFD = (payload) => ({
  type: actionTypes.SET_RECORD_UFD,
  payload,
})
export const setPortabilityNewcoCheck = (checkNewco) => ({
  type: actionTypes.SET_PORTABILITY_NEWCO_CHECK,
  payload: checkNewco,
})
export const setDualAddressAlert = (payload) => ({
  type: actionTypes.SET_ADDRESS_DUAL_ALERT,
  payload,
})
export const setChangeOwnerCups = (payload) => ({
  type: actionTypes.SET_CHANGE_OWNER_CUPS,
  payload,
})

export const clearChangeOwnerCups = () => ({
  type: actionTypes.CLEAR_CHANGE_OWNER_CUPS,
})
