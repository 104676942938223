import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import {
  FormHelperText,
  InputLabel,
  Grid,
  TextField as MuiTextField,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedChannel } from 'services/operator/selectors'
import Link from 'ui/components/link'
import Typography from 'ui/components/typography'
import { ID_DOC_TYPES, ID_DOC_TYPES_PYMES, PYME } from 'utils/constants'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

const BankAccount = ({
  onChange,
  onSepaClick,
  errors,
  touched,
  values,
  handleSameHolder,
  setFieldValue,
  ibanReover,
  referenceSegment,
}) => {
  const { t } = useTranslation()
  const [sameHolder, setSameHolder] = useState(true)
  const dispatch = useDispatch()
  const selectedChannel = useSelector(getSelectedChannel)

  const openInfoModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.ibanRecoverInfo,
      })
    )
  }
  const CHANNELS_LIMITED = [
    'Fuerza de Ventas con Verificacion',
    'Operaciones con Verificacion',
    'Nuevos Colaboradores',
    'Expansión',
    'Venta en Remoto Local',
    'Venta en Remoto-Conecta Expandia',
    'Venta en Remoto-Dexo',
    'Venta en Remoto-Equality',
    'Venta en Remoto-Solvion',
    'Mayorista',
  ]

  const channelIsLimited = selectedChannel && CHANNELS_LIMITED.includes(selectedChannel.name) && sameHolder
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          id="sameHolder"
          name="sameHolder"
          control={<Checkbox size="small" />}
          checked={sameHolder}
          onChange={(event) => {
            setSameHolder(event.target.checked)
            handleSameHolder(event.target.checked, setFieldValue)
          }}
          disabled={ibanReover}
          label={
            <div>
              <Typography color="textPrimary" variant="basic">
                {t('form.fields.sameHolder')}
              </Typography>
            </div>
          }
        />
        {ibanReover && (
          <InfoOutlinedIcon
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              verticalAlign: 'middle',
              scale: '1.6',
            }}
            onClick={() => openInfoModal()}
            fontSize="inherit"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel
            error={!!(touched.accountHolderDocumentType && errors.accountHolderDocumentType)}
            htmlFor="accountHolderDocumentType"
          >
            {t('form.fields.idDocumentType')}
          </InputLabel>
          <Field
            component={Select}
            native
            error={!!(touched.accountHolderDocumentType && errors.accountHolderDocumentType)}
            id="idDocumentType"
            name="idDocumentType"
            label={t('form.fields.idDocumentType')}
            value={t(values.accountHolderDocumentType)}
            onChange={onChange('accountHolderDocumentType')}
            disabled={sameHolder}
          >
            <option aria-label="None" value="" />
            {Object.entries(referenceSegment === PYME ? ID_DOC_TYPES_PYMES : ID_DOC_TYPES)
              .filter(([key]) => key !== 'passport')
              .map(([key, { value, label }]) => (
                <option key={key} value={value}>
                  {t(label)}
                </option>
              ))}
          </Field>
        </FormControl>
        <ErrorMessage component={FormHelperText} name="idDocumentType" error />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          component={MuiTextField}
          required
          fullWidth
          id="accountHolderDocumentNumber"
          label={t('form.fields.idDocumentNumber')}
          name="accountHolderDocumentNumber"
          value={values.accountHolderDocumentNumber}
          onChange={onChange('accountHolderDocumentNumber')}
          disabled={sameHolder}
          error={!!(touched.accountHolderDocumentNumber && errors.accountHolderDocumentNumber)}
        />
        <ErrorMessage component={FormHelperText} name="accountHolderDocumentNumber" error />
      </Grid>

      {!channelIsLimited && (
        <>
          {/* --- NAME --- */}

          <Grid item xs={12} sm={4}>
            <Field
              component={MuiTextField}
              required
              fullWidth
              id="accountHolder"
              label={t('form.fields.ownerName')}
              name="accountHolder"
              value={values.accountHolder}
              onChange={onChange}
              error={!!(touched.accountHolder && errors.accountHolder)}
              disabled={sameHolder}
            />
            <ErrorMessage component={FormHelperText} name="contactName" error />
          </Grid>

          {/* --- LAST NAME --- */}
          <Grid item xs={12} sm={4}>
            <Field
              component={MuiTextField}
              required
              fullWidth
              id="accountHolderLastName"
              label={t('form.fields.lastNameOpt')}
              name="accountHolderLastName"
              value={values.accountHolderLastName}
              onChange={(event) => {
                onChange(event)
                const surnames = `${event.target.value} ${values.accountHolderSecondLastName || ''}`
                setFieldValue('accountHolderSurnames', surnames, false)
              }}
              error={!!(touched.accountHolderLastName && errors.accountHolderLastName)}
              disabled={sameHolder}
            />
            <ErrorMessage component={FormHelperText} name="contactLastName" error />
          </Grid>

          {/* --- SECOND LAST NAME --- */}
          <Grid item xs={12} sm={4}>
            <Field
              component={MuiTextField}
              required
              fullWidth
              id="accountHolderSecondLastName"
              label={t('form.fields.secondLastNameOpt')}
              name="accountHolderSecondLastName"
              value={values.accountHolderSecondLastName}
              onChange={(event) => {
                onChange(event)
                const surnames = `${values.accountHolderLastName || ''} ${event.target.value}`
                setFieldValue('accountHolderSurnames', surnames, false)
              }}
              disabled={sameHolder}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Field
          component={MuiTextField}
          required
          fullWidth
          id="accountIban"
          label={t('form.fields.iban')}
          name="accountIban"
          value={values.accountIban}
          disabled={ibanReover}
          onChange={(event) => {
            event.currentTarget.value = event.currentTarget.value.replaceAll(/[^A-Za-z0-9]/g, '').toUpperCase()
            onChange(event)
          }}
          error={!!(touched.accountIban && errors.accountIban)}
          autoComplete="off"
        />
        <ErrorMessage component={FormHelperText} name="accountIban" error />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          id="domiciliationOrder"
          name="domiciliationOrder"
          control={<Checkbox size="small" />}
          checked={values.domiciliationOrder}
          onChange={onChange}
          label={
            <Typography color="textPrimary" variant="basic">
              <Trans i18nKey="form.fields.domiciliationOrder">
                <Link variant="link" onClick={onSepaClick} target="_blank" href="#"></Link>
              </Trans>
            </Typography>
          }
        />
        <ErrorMessage component={FormHelperText} name="domiciliationOrder" error />
      </Grid>
    </Grid>
  )
}

export default BankAccount
