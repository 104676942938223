import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dialogContent: {
    height: ' 50vh',
    width: '85vw',
    overflow: 'hidden',
    position: 'relative',
    marginRight: theme.spacing(1), // this will counter the left displacement of the iframe to hide the scroll bar

    [theme.breakpoints.up('md')]: {
      height: '70vh',
      marginRight: theme.spacing(2), // this will counter the left displacement of the iframe to hide the scroll bar
      width: '60vw',
    },
  },
  dialogActions: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(0, 3, 3),
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      margin: theme.spacing(0, 0, 2),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 7, 5),
      '& .MuiButton-root': {
        margin: theme.spacing(0, 0, 0, 3),
      },
    },
  },
  iframe: {
    border: 0,
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: theme.spacing(1), // this will hide the scrollbar due to the parent having overflow: hidden
    right: theme.spacing(-1),

    [theme.breakpoints.up('md')]: {
      left: theme.spacing(2),
    },
  },
}))

export default useStyles
