import NumberFormat from 'react-number-format'

const NumberFormatCustom = ({ inputRef, onChange, isNumericString, decimalSeparator, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // TODO: Revisar si enviar siempre este param al onChange es correcto
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      isNumericString={isNumericString || true}
      decimalSeparator={decimalSeparator || ','}
    />
  )
}

export default NumberFormatCustom
