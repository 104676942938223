import { get, post, postError } from 'services/client'

const getOnlineChannel = async () => await get('channel_offers?page=1&channel.type=WEB', true)
const getOnlineChannelAgv = async (agv) => await get(`channel_offers?page=1&channel.type=WEB&agv=${agv}`, true)
const loginOnlineChannel = async (email, password) => await postError(`clients/login`, email, password, true)
const loginOnlineChannelToken = async (token) => {
  return await post(
    `clients/login`,
    { username: null, password: null }, // IF THERES NO BODY IT WILL RETURN A SYNTAX ERROR
    true,
    { 'x-fua-token': token }
  )
}
export { getOnlineChannel, loginOnlineChannel, loginOnlineChannelToken, getOnlineChannelAgv }
