import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { submitOwnerStep, fetchUserData } from 'services/customer/actions'
import { sendGAEvent } from 'services/analytics/actions'
import { NOT_CLIENT_ERROR, NOT_TENURE_ERROR, SERVER_ERROR, NOT_CONTRACT_ERROR } from 'services/customer/constants'
import {
  getFormData,
  getUserData,
  getOnlineInvoice,
  getIsExClient,
  getUserDataError,
  getCompany,
  getSupplyPoint,
  getUserDataChecked,
  getHasTenuresInCurrentCompany,
} from 'services/customer/selectors'
import { getIsLeadFetching } from 'services/lead/selectors'
import { getIsOnlyToppings, getSelectedRate } from 'services/products/selectors'
import {
  getIsOnlineChannel,
  getIsResetFunnelContract,
  getReferenceSegment,
  getIsChannelWithTenure,
  getSelectedChannel,
} from 'services/operator/selectors'
import { getDraftSelectedProduct, getOnlineChannelDraft, getIsDraft } from 'services/draftCopy/selectors'
import { getActiveStep, getStepStateByProperty } from 'services/stepper/selectors'
import { getRouterSearch } from 'services/analytics/selectors'

import { getOnlineChannelUrlParams } from 'services/onlineChannel/business'
import {
  validateMobilePhone,
  validateEmail,
  validateNif,
  validateNie,
  validatePassport,
  validateCif,
  validateNames,
  validateBusinessNames,
  validateCnae,
  validateTelephone,
  validatePhone,
} from 'utils/validators'
import {
  ID_DOC_TYPES,
  ID_DOC_TYPES_PYMES,
  ELECTRICITY,
  PYME,
  ENERGY_USES,
  GAS,
  STEPS,
  ENERGY_USE_ONLINE_CHANNEL_PARAMS,
  GA_EVENT_NAMES,
  PORTABILITY,
} from 'utils/constants'

import PersonalDataForm from 'ui/forms/personal'
import PersonalDataChekingForm from 'ui/forms/personalCheckClient'
import ErrorFocus from 'ui/forms/errorFocus'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { checkCups } from 'utils/commons'
import { getError } from '../services/client'

import useStep from '@zatopek/funnel/src/hooks/useStep'

const OwnerStep = () => {
  const [btnState, setBtnState] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const currentStep = useStep({ stepIndex: 0 })

  const isUserChecked = useSelector(getUserDataChecked)
  const isDraftCopy = useSelector(getIsDraft)
  const selectedChannel = useSelector(getSelectedChannel)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const activeStep = useSelector(getActiveStep)
  const steps = useSelector((state) => state.stepper.steps)
  // TODO: move this logic to a redux selector
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const isOnlyToppings = useSelector(getIsOnlyToppings)
  const isTenureContractFlow = useSelector(getIsChannelWithTenure)
  const userData = useSelector(getUserData)
  const company = useSelector(getCompany)
  const userDataError = useSelector(getUserDataError)
  let initialValues = useSelector(getFormData)
  const referenceSegment = useSelector(getReferenceSegment)
  const isResetFunnelContract = useSelector(getIsResetFunnelContract)
  const draftSelectedProduct = useSelector(getDraftSelectedProduct)
  const onlineInvoice = useSelector(getOnlineInvoice)
  const stepStateByProperty = useSelector(getStepStateByProperty)
  const isCompletedBillingStep = stepStateByProperty(STEPS.billing, 'isCompleted')
  const emailDisabled = userData && validateEmail(userData?.email || '')
  const isExClient = useSelector(getIsExClient)
  const onlineChannelDraft = useSelector(getOnlineChannelDraft)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const supplyPoints = useSelector(getSupplyPoint)
  const selectedCompany = company || selectedProduct[0]?.company
  const hasTenuresInCurrentCompany = useSelector(getHasTenuresInCurrentCompany)
  const CHANNELS_LIMITED = [
    'Fuerza de Ventas con Verificacion',
    'Operaciones con Verificacion',
    'Nuevos Colaboradores',
    'Expansión',
    'Venta en Remoto Local',
    'Venta en Remoto-Conecta Expandia',
    'Venta en Remoto-Dexo',
    'Venta en Remoto-Equality',
    'Venta en Remoto-Solvion',
    'Mayorista',
  ]

  const isNewClient = [NOT_CLIENT_ERROR, NOT_TENURE_ERROR, NOT_CONTRACT_ERROR, SERVER_ERROR].includes(userDataError)
  const isDisabledByChannel = (isTenureContractFlow || isOnlineChannel) && userData && !isEmptyObject(userData) && !userDataError

  const search = useSelector(getRouterSearch)
  const onlineChannelParams = getOnlineChannelUrlParams(search)

  const isEnergyUseOnlineChannelValid = Object.values(ENERGY_USE_ONLINE_CHANNEL_PARAMS).includes(onlineChannelParams?.usoenergia)

  const [preSelectedDocumentType] = useState(referenceSegment === PYME ? ID_DOC_TYPES_PYMES.cif.value : ID_DOC_TYPES.nif.value)

  const initialCheckingValues = {
    idDocumentTypeChecking: preSelectedDocumentType,
    idDocumentNumberChecking: '',
  }
  const maskUserData =
    userData &&
    !isEmptyObject(userData) &&
    !isOnlineChannel &&
    (!isNewClient || (isDisabledByChannel && !isExClient)) &&
    CHANNELS_LIMITED.includes(selectedChannel.name)

  if (maskUserData && initialValues.phone) {
    initialValues.phone = ''
  }

  const handleShowModal = (e, modalType, data, title) => {
    e.preventDefault()
    const modalData = data
      ? {
          contentUrl: data,
          title,
          withModalActions: false,
        }
      : {}
    dispatch(showModal({ modalType, modalData }))
  }

  const handleFormSubmit = async (formData) => {
    const eventData = {
      currentStep: steps[activeStep]?.id,
      nextStep: steps[activeStep + 1] && steps[activeStep + 1]?.id,
    }
    dispatch(sendGAEvent(GA_EVENT_NAMES.clickContinue, eventData))
    if (selectedChannel.name === PORTABILITY) {
      if (isOnlineChannel && !formData.retargeting) {
        if (supplyPoints) {
          for (let supplyPoint of supplyPoints) {
            const { cups, energyType, electricityCups, gasCups } = supplyPoint
            const supplyPointCups = cups || (energyType === ELECTRICITY ? electricityCups : gasCups)
            const { isOk, code } = await checkCups([supplyPointCups], formData.idDocumentNumber, selectedCompany)
            if (!isOk) {
              dispatch(
                showModal({
                  modalType: MODAL_TYPES.checkCups,
                  modalData: {
                    sameClient: code === '01',
                    cupsActive: code === '05',
                  },
                })
              )
              return
            }
          }
        }
        dispatch(showModal({ modalType: MODAL_TYPES.retargeting, modalData: formData }))
      } else {
        if (isNewClient) {
          dispatch(sendGAEvent(GA_EVENT_NAMES.newContract, selectedChannel.name))
        }
        dispatch(submitOwnerStep(formData))
      }
    } else {
      return await getError(
        `leads/checkLimit?phone=${formData.phone}` + (formData.email ? `&email=${formData.email}` : '&email=null')
      ).then(async (res) => {
        if (res.success) {
          if (isOnlineChannel && !formData.retargeting) {
            if (supplyPoints) {
              for (let supplyPoint of supplyPoints) {
                const { cups, energyType, electricityCups, gasCups } = supplyPoint
                const supplyPointCups = cups || (energyType === ELECTRICITY ? electricityCups : gasCups)
                const { isOk, code } = await checkCups([supplyPointCups], formData.idDocumentNumber, selectedCompany)
                if (!isOk) {
                  dispatch(
                    showModal({
                      modalType: MODAL_TYPES.checkCups,
                      modalData: {
                        sameClient: code === '01',
                        cupsActive: code === '05',
                      },
                    })
                  )
                  return
                }
              }
            }
            dispatch(showModal({ modalType: MODAL_TYPES.retargeting, modalData: formData }))
          } else {
            if (isNewClient) {
              dispatch(sendGAEvent(GA_EVENT_NAMES.newContract, selectedChannel.name))
            }
            dispatch(submitOwnerStep(formData))
          }
        } else {
          if (res.error.includes('teléfono')) {
            dispatch(
              showModal({
                modalType: MODAL_TYPES.phoneLimit,
              })
            )
          } else if (res.error.includes('mail')) {
            dispatch(
              showModal({
                modalType: MODAL_TYPES.emailLimit,
              })
            )
          }
        }
      })
    }
  }

  const handleChekingFormSubmit = (formData) => {
    dispatch(fetchUserData(formData.idDocumentNumberChecking, formData.idDocumentTypeChecking ?? preSelectedDocumentType))
  }

  const validateCheckingForm = (values) => {
    const requiredFields = ['idDocumentTypeChecking', 'idDocumentNumberChecking']
    const errors = requiredFields
      .filter((field) => !values[field])
      .reduce((acc, current) => ({ ...acc, [current]: t('form.errors.required') }), {})

    if (
      values.idDocumentNumberChecking &&
      ((values.idDocumentTypeChecking === ID_DOC_TYPES.nif.value && !validateNif(values.idDocumentNumberChecking)) ||
        (values.idDocumentTypeChecking === ID_DOC_TYPES.nie.value && !validateNie(values.idDocumentNumberChecking)) ||
        (values.idDocumentTypeChecking === ID_DOC_TYPES.passport.value && !validatePassport(values.idDocumentNumberChecking)) ||
        (values.idDocumentTypeChecking === ID_DOC_TYPES_PYMES.cif.value && !validateCif(values.idDocumentNumberChecking)))
    ) {
      errors.idDocumentNumberChecking = t('form.errors.invalidFormat')
    }

    //check if error has content
    Object.keys(errors).length > 0 ? setBtnState(true) : setBtnState(false)
    return errors
  }

  const handleInputFields = (values) => {
    let fieldsFulled = []
    const withNationalityDocTypes =
      isNewClient || isOnlineChannel || !hasTenuresInCurrentCompany
        ? [ID_DOC_TYPES.nie.value, ID_DOC_TYPES.passport.value, ID_DOC_TYPES_PYMES.nie.value, ID_DOC_TYPES_PYMES.passport.value]
        : []
    const requiredFields =
      referenceSegment === PYME
        ? values.idDocumentType === 'CIF'
          ? [
              'idDocumentType',
              'idDocumentNumber',
              'name',
              'phone',
              'language',
              'energyUse',
              'contactName',
              'contactLastName',
              'contactDegree',
              'contactDocumentNumber',
              values?.contactTelephone ? 'contactTelephone' : 'contactMobilePhone',
            ]
          : [
              'idDocumentType',
              'idDocumentNumber',
              'name',
              'lastName',
              'phone',
              'language',
              'energyUse',
              'contactName',
              'contactLastName',
              'contactDegree',
              'contactDocumentNumber',
            ]
        : ['idDocumentType', 'idDocumentNumber', 'name', 'lastName', 'phone', 'language']

    if (onlineInvoice || isOnlineChannel) {
      requiredFields.push('email')
    }
    if (referenceSegment === PYME && values.energyUse === ENERGY_USES.commercial) {
      requiredFields.push('cnae')
    }
    if (withNationalityDocTypes.includes(values.idDocumentType)) {
      requiredFields.push('nationality')
    }

    requiredFields.forEach((field) => {
      if (!values[field]) {
        return
      } else {
        fieldsFulled.push(field)
      }
    })

    requiredFields?.length === fieldsFulled?.length ? setBtnState(true) : setBtnState(false)
  }

  const validateForm = (values) => {
    const withNationalityDocTypes =
      isNewClient || isOnlineChannel || !hasTenuresInCurrentCompany
        ? [ID_DOC_TYPES.nie.value, ID_DOC_TYPES.passport.value, ID_DOC_TYPES_PYMES.nie.value, ID_DOC_TYPES_PYMES.passport.value]
        : []
    const errors = {}
    const requiredFields =
      referenceSegment === PYME
        ? values.idDocumentType === 'CIF'
          ? [
              'idDocumentType',
              'idDocumentNumber',
              'name',
              'phone',
              'language',
              'energyUse',
              'contactName',
              'contactLastName',
              'contactDegree',
              'contactDocumentNumber',
            ]
          : [
              'idDocumentType',
              'idDocumentNumber',
              'name',
              'lastName',
              'phone',
              'language',
              'energyUse',
              'contactName',
              'contactLastName',
              'contactDegree',
              'contactDocumentNumber',
            ]
        : ['idDocumentType', 'idDocumentNumber', 'name', 'lastName', 'phone', 'language']

    if (onlineInvoice || isOnlineChannel) {
      requiredFields.push('email')
    }
    if (referenceSegment === PYME && values.energyUse === ENERGY_USES.commercial) {
      requiredFields.push('cnae')
    }
    if (withNationalityDocTypes.includes(values.idDocumentType)) {
      requiredFields.push('nationality')
    }

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
      }
    })

    if (
      values.idDocumentNumber &&
      ((values.idDocumentType === ID_DOC_TYPES.nif.value && !validateNif(values.idDocumentNumber)) ||
        (values.idDocumentType === ID_DOC_TYPES.nie.value && !validateNie(values.idDocumentNumber)) ||
        (values.idDocumentType === ID_DOC_TYPES.passport.value && !validatePassport(values.idDocumentNumber)) ||
        (values.idDocumentType === ID_DOC_TYPES_PYMES.cif.value && !validateCif(values.idDocumentNumber)))
    ) {
      errors.idDocumentNumber = t('form.errors.invalidFormat')
    }

    if (values.idDocumentType === ID_DOC_TYPES_PYMES.cif.value) {
      if (!errors.name && !validateBusinessNames(values.name)) {
        errors.name = t('form.errors.invalidFormat')
      }
    } else {
      if (!errors.name && !validateNames(values.name) && !isDisabledByChannel) {
        errors.name = t('form.errors.invalidFormat')
      }
    }

    if (values.lastName && !validateNames(values.lastName) && !isDisabledByChannel) {
      errors.lastName = t('form.errors.invalidFormat')
    }

    if (values.secondLastName && !validateNames(values.secondLastName) && !isDisabledByChannel) {
      errors.secondLastName = t('form.errors.invalidFormat')
    }

    if (values.phone && !validateMobilePhone(values.phone)) {
      errors.phone = t('form.errors.invalidFormat')
    }

    if (values.email && !validateEmail(values.email)) {
      errors.email = t('form.errors.invalidFormat')
    }

    if (values.cnae && values.energyUse === ENERGY_USES.commercial && !errors.cnae && !validateCnae(values.cnae)) {
      errors.cnae = t('form.errors.invalidCnae')
    }

    if (values.contactName && (!validateNames(values.contactName) || values.contactName.length > 50)) {
      errors.contactName = t('form.errors.invalidFormat')
    }

    if (values.contactLastName && (!validateNames(values.contactLastName) || values.contactLastName.length > 50)) {
      errors.contactLastName = t('form.errors.invalidFormat')
    }

    if (
      values.contactSecondLastName &&
      (!validateNames(values.contactSecondLastName) || values.contactSecondLastName.length > 50)
    ) {
      errors.contactSecondLastName = t('form.errors.invalidFormat')
    }

    if (
      values.contactDocumentNumber &&
      !validateNif(values.contactDocumentNumber) &&
      !validateNie(values.contactDocumentNumber)
    ) {
      errors.contactDocumentNumber = t('form.errors.invalidFormat')
    }

    if (referenceSegment === PYME && !values.contactMobilePhone && !values.contactTelephone) {
      errors.contactMobilePhone = t('form.errors.required')
      errors.contactTelephone = t('form.errors.required')
    }

    if (values.contactMobilePhone && !validatePhone(values.contactMobilePhone)) {
      errors.contactMobilePhone = t('form.errors.invalidFormat')
    }

    if (values.contactTelephone && !validateTelephone(values.contactTelephone)) {
      errors.contactTelephone = t('form.errors.invalidFormat')
    }

    if (values.contactEmail && (!validateEmail(values.contactEmail) || values.contactEmail.length > 50)) {
      errors.contactEmail = t('form.errors.invalidFormat')
    }

    handleInputFields(values)

    return errors
  }
  const sendGAeventsCheck = (name) => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.checkAcceptancePrivacyPolicy, { checkName: name, currentStep: STEPS.owner }))
  }

  useEffect(() => {
    validateForm(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const draftChangeSupplyOwnership = onlineChannelDraft
    ? onlineChannelDraft.ownerStep.supplyOwnership?.map(({ changeSupplyOwnership, energyType }) => ({
        changeSupplyOwnership,
        energyType,
      }))
    : draftSelectedProduct?.map(({ changeSupplyOwnership, technicalInfoElectricity }) => {
        const energyType = technicalInfoElectricity ? ELECTRICITY : GAS
        return { changeSupplyOwnership, energyType }
      })

  return (
    <>
      {!selectedChannel?.id ? (
        <Box display="flex" height="15.5vh" alignItems="center" justifyContent="center">
          <CircularProgress size={48} thickness={1.4} />
        </Box>
      ) : isUserChecked || isOnlineChannel || isDraftCopy ? (
        <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
          {({ handleChange, touched, errors, values, resetForm, setFieldValue }) => (
            <Form noValidate className="owner-step">
              <Box mb={3}>
                <PersonalDataForm
                  sendGAeventsCheck={sendGAeventsCheck}
                  onChange={handleChange}
                  touched={touched}
                  errors={errors}
                  values={values}
                  userData={userData}
                  company={company?.code?.toLowerCase() || null}
                  emailDisabled={emailDisabled}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  isOnlineChannel={isOnlineChannel}
                  isOnlyToppings={isOnlyToppings}
                  onlineInvoice={onlineInvoice}
                  isCompletedBillingStep={isCompletedBillingStep}
                  isDisabledByChannel={isDisabledByChannel}
                  referenceSegment={referenceSegment}
                  draftChangeSupplyOwnership={draftChangeSupplyOwnership}
                  isResetFunnelContract={isResetFunnelContract}
                  isExClient={isExClient}
                  isNewClient={isNewClient}
                  hasTenuresInCurrentCompany={hasTenuresInCurrentCompany}
                  onPrivacyPolicyClick={(e) => {
                    dispatch(sendGAEvent(GA_EVENT_NAMES.privacyPolicy, { currentStep: STEPS.owner }))
                    handleShowModal(
                      e,
                      MODAL_TYPES.iframe,
                      t(
                        company?.code?.toLowerCase() === 'newco' || company?.code?.toLowerCase() === 'nycli'
                          ? 'common.privacyPolicyUrlNewco'
                          : 'common.privacyPolicyUrl'
                      ),
                      t('legal.privacyPolicyConditionsTitle')
                    )
                  }}
                  onBonoSocialClick={(e) => {
                    dispatch(sendGAEvent(GA_EVENT_NAMES.clickBonoSocial, { currentStep: STEPS.owner }))
                    handleShowModal(e, MODAL_TYPES.bonoSocial)
                  }}
                  isEnergyUseOnlineChannelValid={isEnergyUseOnlineChannelValid}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" mb={3}>
                <Button type="submit" variant="contained" color="secondary" disabled={isLeadFetching || !btnState}>
                  {t('common.continue')}
                </Button>
              </Box>
              <ErrorFocus errorWrapperClass="owner-step" />
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={initialCheckingValues}
          validate={validateCheckingForm}
          onSubmit={handleChekingFormSubmit}
          enableReinitialize
        >
          {({ handleChange, touched, errors, values, resetForm, setFieldValue }) => (
            <Form noValidate className="owner-step">
              <Box mb={3}>
                <PersonalDataChekingForm
                  onChange={handleChange}
                  touched={touched}
                  values={values}
                  errors={errors}
                  resetForm={resetForm}
                  referenceSegment={referenceSegment}
                  isResetFunnelContract={isResetFunnelContract}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" mb={3}>
                <Button type="button" variant="contained" color="secondary" disabled>
                  {t('common.continue')}
                </Button>
              </Box>
              <ErrorFocus errorWrapperClass="owner-step" />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default OwnerStep
