import React from 'react'
import { Container, Box } from '@material-ui/core'

import Header from 'ui/header'
import Footer from 'ui/footer'
import { useMainLayoutStyles, useAlternativeLayoutStyles } from './styles'

const MainLayout = ({
  children,
  logout,
  isLoggedIn,
  isOnlineChannel,
  openCMBModal,
  noActions,
  onClickLogo,
  progress,
  showProgress,
  alternativeLayout = false,
}) => {
  const mainLayoutClasses = useMainLayoutStyles()
  const alternativeLayoutClasses = useAlternativeLayoutStyles()
  const classes = alternativeLayout ? alternativeLayoutClasses : mainLayoutClasses

  return (
    <section role="main" className={classes.mainContainer}>
      <Header
        logout={logout}
        isLoggedIn={isLoggedIn}
        progress={progress}
        showProgress={showProgress}
        isOnlineChannel={isOnlineChannel}
        openCMBModal={openCMBModal}
        noActions={noActions}
        onClickLogo={onClickLogo}
      />
      <Box className={classes.background} flexGrow={1}>
        <Container maxWidth="lg" className={classes.wrapper}>
          {children}
        </Container>
      </Box>
      <Footer />
    </section>
  )
}

export default MainLayout
