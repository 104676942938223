import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import * as yup from 'yup'

import useQuery from '@zatopek/core/src/hooks/useQuery'
import {
  getIsOnlyToppings,
  getSelectedDualSubproducts,
  getSelectedProductVariants,
  getSelectedRate,
} from 'services/products/selectors'
import { getEnergies } from 'services/energies/selectors'
import { getConsumptionByEnergy } from 'services/energies/business'
import { getActiveStep, getIsSupplyStepCompleted, getStepStateByProperty, getSupplyStepMode } from 'services/stepper/selectors'
import { getIsLeadFetching, getIsLeadError, getLeadId } from 'services/lead/selectors'
import { getTypes as getSelfConsumptionTypes } from 'services/selfconsumption/selectors'
import { getCupsSearchByCups } from 'services/cupsSearcher/selectors'
import {
  getNormalizedAddress,
  getSelectedTenure,
  getIsTenureSelected,
  getBillingAddress,
  getIsNewSupply,
  getIsMultiPoint,
  getSupplyPoint,
  getSupplyAddress,
  getDifferentBillingAddress,
  getUserData,
  getCustomerData,
  getCompany,
  getInitialSelfconsumptionType,
  getSelfconsumptionTypes,
  getChangeOwnerCups,
} from 'services/customer/selectors'
import { getSupplyPointConsumption } from 'services/customer/business'
import {
  getIsOnlineChannel,
  getIsResetFunnelContract,
  getReferenceSegment,
  getSelectedChannel,
} from 'services/operator/selectors'
import {
  getDraftSelectedProduct,
  getDuplicateOrderDraft,
  getIsDraft,
  getIsDuplicateOrderDraft,
  getIsOnlineChannelDraft,
  getOnlineChannelDraft,
  getDraftOrder,
} from 'services/draftCopy/selectors'
import {
  setNormalizedAddress,
  setSupplyPoint,
  setBillingAddress,
  setSupplyAddress,
  setIsNewSupply,
  setIsMultiPoint,
  clearIsMultiPoint,
  setPortabilityNewcoCheck,
  setChangeOwnerCups,
} from 'services/customer/actions'
import { setActiveStep, setActiveStepById, setStepCompleted, setStepDisabled, setStepUncompleted } from 'services/stepper/actions'
import { setNewAddressProductChange } from 'services/fidelization/actions'
import {
  STEPS,
  BOTH,
  BOTH_CALCULATOR,
  ELECTRICITY,
  GA_EVENT_NAMES,
  GA_EVENT_ACTIONS,
  //CUPS_SELECTOR_BY_CUPS_ACTION,
  GAS,
  CAPACITY_FIELD_ALLOWED_KEYS,
  PYME,
  COL_NNSS_VALUES,
  MAX_NUMBER_ELECTRICITY_SUPPLY_POINTS,
  MAX_NUMBER_GAS_SUPPLY_POINTS,
  COMPANY_CODES,
  STREET_TYPE_DEFAULT,
} from 'utils/constants'
import { WITOUT_SELFCONSUMPTION } from 'services/selfconsumption/constants'

import cupsInfoEs from '@zatopek/core/src/assets/images/cups-es.png'
import cupsInfoCa from '@zatopek/core/src/assets/images/cups-ca.png'
import cupsInfoGl from '@zatopek/core/src/assets/images/cups-gl.png'
import { sendGAEvent } from 'services/analytics/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { showModal } from 'services/modal/actions'
import { fetchLead } from 'services/lead/actions'

import SupplyStepView from 'ui/views/supplyStep'
import SupplyPoint from '@zatopek/core/src/containers/SupplyPoint'
import { resetSupplyStep } from 'services/operator/actions'
import { clearProductVariants, clearSelectedProduct, fetchProducts } from 'services/products/actions'
import { fetchToppings } from 'services/toppings/actions'
import { getRouterQueries } from 'services/analytics/selectors'
import { getTariffData } from 'utils/commons'
import { getIsDisallowedNewAddressProductChange } from 'services/fidelization/selectors'
import { checkCups as checkCupsApi } from 'utils/commons'
import SelfconsumptionForm from 'ui/views/supplyStep/selfconsumptionForm'
import { useRef } from 'react'
import { isProductCompatible, isSelfconsumptionTypeMigration } from 'services/selfconsumption/business'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import { getIsSupplyStepActive } from 'services/stepper/selectors'
import { SELFCONSUMPTION_DISABLED } from 'services/selfconsumption/constants'
import { getInvoiceData } from 'services/invoice/selectors'
import { setInvoiceData } from 'services/invoice/actions'
import { setCupsSearcherByCups } from 'services/cupsSearcher/actions'
import { getPortabilityNewcoCheck, getCustomer } from 'services/customer/selectors'
import { clearChangeSupplyOwnerShip } from 'services/customer/actions'
import { put } from 'services/client'
import useStep from '@zatopek/funnel/src/hooks/useStep'
import { getOrders } from 'services/order/selectors'

const SupplyStep = ({ onContinue, preSelectedRate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const query = useQuery()
  // eslint-disable-next-line no-unused-vars
  const currentStep = useStep({ stepIndex: 2 })

  const checkCups = async (...args) => {
    const { code, ...others } = await checkCupsApi(...args)

    dispatch(setNewAddressProductChange(code))

    return { code, ...others }
  }

  const cupsSearchByCups = useSelector(getCupsSearchByCups)
  const selfConsumptionTypes = useSelector(getSelfConsumptionTypes)
  const isDisallowedNewAddressProductChange = useSelector(getIsDisallowedNewAddressProductChange)
  const selectedProduct = useSelector(getSelectedRate)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const isLeadError = useSelector(getIsLeadError)
  const supplyMode = useSelector(getSupplyStepMode)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const isOnlyToppings = useSelector(getIsOnlyToppings)
  const activeStep = useSelector(getActiveStep)
  const supplyPoint = useSelector(getSupplyPoint)
  const referenceSegment = useSelector(getReferenceSegment)
  const normalizedAddress = useSelector(getNormalizedAddress)
  const selectedTenure = useSelector(getSelectedTenure)
  const isTenureSelected = useSelector(getIsTenureSelected)
  const isResetFunnelContract = useSelector(getIsResetFunnelContract)
  const isSupplyStepCompleted = useSelector(getIsSupplyStepCompleted)
  const draftSelectedProduct = useSelector(getDraftSelectedProduct)
  const draftOrder = useSelector(getDraftOrder)
  const orders = useSelector(getOrders)
  const isDraft = useSelector(getIsDraft)
  const stepStateByProperty = useSelector(getStepStateByProperty)
  const billingAddress = useSelector(getBillingAddress)
  const channel = useSelector(getSelectedChannel)
  const isNewSupply = useSelector(getIsNewSupply)
  const queries = useSelector(getRouterQueries)
  const isMultiPoint = useSelector(getIsMultiPoint)
  const energies = useSelector(getEnergies)
  const selectedProductVariants = useSelector(getSelectedProductVariants)
  const selectedDualSubproducts = useSelector(getSelectedDualSubproducts)
  const supplyAddress = useSelector(getSupplyAddress)
  const isOnlineChannelDraft = useSelector(getIsOnlineChannelDraft)
  const onlineChannelDraftData = useSelector(getOnlineChannelDraft)
  const isDuplicateOrderDraft = useSelector(getIsDuplicateOrderDraft)
  const duplicateOrderDraft = useSelector(getDuplicateOrderDraft)
  const differentBillingAddress = useSelector(getDifferentBillingAddress)
  const userData = useSelector(getUserData)
  const customerData = useSelector(getCustomerData)
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const documentType = userData?.documentType || customerData?.idDocumentType
  const isPyme = referenceSegment === PYME
  const selectedCompany = useSelector(getCompany) || selectedProduct[0]?.company
  const isNewCo = selectedCompany?.code === COMPANY_CODES.newco
  const initialSelfconsumptionType = useSelector(getInitialSelfconsumptionType)
  const selfconsumptionTypes = useSelector(getSelfconsumptionTypes)
  const isStepActive = useSelector(getIsSupplyStepActive)
  const invoiceData = useSelector(getInvoiceData)
  const hideSelfConsumption = SELFCONSUMPTION_DISABLED || (isOnlineChannel && query.get('autocon') !== '1')
  const isPortabilityNewcoCheck = useSelector(getPortabilityNewcoCheck)
  const [tariff, setTariff] = useState('')
  const [capacities, setCapacities] = useState([])
  const [isCapacityEditMode, setIsCapacityEditMode] = useState(false)
  const [isContractCapacityVisible, setIsContractCapacityVisible] = useState(false)
  const [isRepeatedCups, setIsRepeatedCups] = useState(false)
  const [isLimitCups, setIsLimitCups] = useState(false)
  const [hasMinNumberSupplies, setHasMinNumberSupplies] = useState(true)
  const [isNewSupplyChecked, setIsNewSupplyChecked] = useState(false)
  const [isInvalidTariff, setIsInvalidTariff] = useState(false)
  const selfconsumptionFormRef = useRef([])
  const customer = useSelector(getCustomer)
  const leadId = useSelector(getLeadId)
  const changeOwnerCups = useSelector(getChangeOwnerCups)
  const cannotAddSupplies =
    supplyPoint?.some((supply) => !supply?.cups) ||
    (supplyPoint?.length === MAX_NUMBER_ELECTRICITY_SUPPLY_POINTS &&
      selectedProduct.find((elem) => elem?.energyType === ELECTRICITY)) ||
    (supplyPoint?.length === MAX_NUMBER_GAS_SUPPLY_POINTS && selectedProduct.find((elem) => elem?.energyType === GAS))

  const isNewSupplySwitchDisabled =
    (isOnlineChannel && [COL_NNSS_VALUES.isNotNewSupply, COL_NNSS_VALUES.isNewSupply].includes(queries?.nnss)) || isMultiPoint

  const capacityParse = (value) => {
    if (typeof value === 'number') {
      return value?.toString()
    }
    return value?.replace(',', '.') || ''
  }
  const isPortability = Boolean(
    selectedCompany?.code &&
      selectedTenure?.company &&
      selectedCompany?.code.toLowerCase() !== selectedTenure?.company.toLowerCase()
  )

  const initialValues = {
    capacityP1: capacityParse(capacities?.capacityP1),
    capacityP2: capacityParse(capacities?.capacityP2),
    capacityP3: capacityParse(capacities?.capacityP3),
    capacityP4: capacityParse(capacities?.capacityP4),
    capacityP5: capacityParse(capacities?.capacityP5),
    capacityP6: capacityParse(capacities?.capacityP6),
  }

  // TODO: Refactor to get flatRate in calculator in a better way
  const isFlatRate = selectedProduct.find((product) => product?.flatRate) || preSelectedRate.find((product) => product?.flatRate)

  const isFlatRateObject = {
    ELECTRICITY:
      selectedProduct.find(
        (product) =>
          (product?.energyType.toUpperCase() === ELECTRICITY || product?.energyType.toUpperCase() === BOTH) && product?.flatRate
      ) ||
      preSelectedRate.find(
        (product) =>
          (product?.energyType.toUpperCase() === ELECTRICITY || product?.energyType.toUpperCase() === BOTH) && product?.flatRate
      ),
    GAS:
      selectedProduct.find(
        (product) =>
          (product?.energyType.toUpperCase() === GAS || product?.energyType.toUpperCase() === BOTH) && product?.flatRate
      ) ||
      preSelectedRate.find(
        (product) =>
          (product?.energyType.toUpperCase() === GAS || product?.energyType.toUpperCase() === BOTH) && product?.flatRate
      ),
    'ELECTRICITY,GAS':
      preSelectedRate.find((product) => product?.energyType.toUpperCase() === BOTH && product?.flatRate) ||
      selectedProduct.find((product) => product?.energyType.toUpperCase() === BOTH && product?.flatRate),
  }

  const isStepCompleted = (stepId) => stepStateByProperty(stepId, 'isCompleted')
  const supplyModeService = useMemo(() => {
    if (selectedProduct.length > 0 || preSelectedRate.length > 0) {
      if (selectedProduct?.length > 1 || preSelectedRate?.length > 1) {
        return BOTH.toLowerCase()
      } else {
        const finalProduct = selectedProduct[0] || preSelectedRate[0]
        return finalProduct.energyType.toLowerCase()
      }
    }
    if (supplyMode) {
      return (supplyMode === BOTH ? BOTH_CALCULATOR : supplyMode).toLowerCase()
    }
    if (selectedTenure && Object.keys(selectedTenure).length > 0) {
      return selectedTenure.electricityCups || !selectedTenure.gasCups ? ELECTRICITY.toLowerCase() : GAS.toLowerCase()
    }
    if (supplyAddress && Object.keys(supplyAddress).length > 0) {
      return supplyAddress.electricityCups || !supplyAddress.gasCups ? ELECTRICITY.toLowerCase() : GAS.toLowerCase()
    }
    return ELECTRICITY.toLowerCase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, supplyMode, selectedTenure, supplyAddress, preSelectedRate])

  const onTenureComplete = (technicalInfo, tenureData) => {
    handleSubmit(technicalInfo, tenureData, true)
  }

  const onDraftComplete = (technicalInfo, supplyAddress) => {
    handleSubmit(technicalInfo, supplyAddress, true)
  }

  /**
   * Validates the capacities for tariff 3.0TD
   */
  const capacity30ValidationSchema = yup.object().shape({
    capacityP1: yup
      .number()
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      })
      .required(t('form.errors.required')),
    capacityP2: yup
      .number()
      .required(t('form.errors.required'))
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      })
      .min(yup.ref('capacityP1'), t('supply.errors.p2BiggerThanP1')),
    capacityP3: yup
      .number()
      .required(t('form.errors.required'))
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      }),
    capacityP4: yup
      .number()
      .required(t('form.errors.required'))
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      })
      .min(yup.ref('capacityP3'), t('supply.errors.p4BiggerThanP3')),
    capacityP5: yup
      .number()
      .required(t('form.errors.required'))
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      })
      .min(yup.ref('capacityP4'), t('supply.errors.p5BiggerThanP4')),
    capacityP6: yup
      .number()
      .required(t('form.errors.required'))
      .test('oneOfRequired', t('supply.errors.commonAtLeastOne15'), function (item) {
        return (
          this.parent.capacityP1 > 15 ||
          this.parent.capacityP2 > 15 ||
          this.parent.capacityP3 > 15 ||
          this.parent.capacityP4 > 15 ||
          this.parent.capacityP5 > 15 ||
          this.parent.capacityP6 > 15
        )
      })
      .min(yup.ref('capacityP5'), t('supply.errors.p6BiggerThanP5')),
  })

  /**
   * Validates the capacities for tariff 6.XTD and 2.0TD
   */
  const capacity206xValidationSchema = yup.object().shape({
    capacityP1: yup.number().required(t('form.errors.required')),
    capacityP2: yup.number().required(t('form.errors.required')).min(yup.ref('capacityP1'), t('supply.errors.p2BiggerThanP1')),
    capacityP3: yup.number().required(t('form.errors.required')),
    capacityP4: yup.number().required(t('form.errors.required')).min(yup.ref('capacityP3'), t('supply.errors.p4BiggerThanP3')),
    capacityP5: yup.number().required(t('form.errors.required')).min(yup.ref('capacityP4'), t('supply.errors.p5BiggerThanP4')),
    capacityP6: yup.number().required(t('form.errors.required')).min(yup.ref('capacityP5'), t('supply.errors.p6BiggerThanP5')),
  })

  const handleSubmit = async (technicalInfo, supplyAddress, isCompleted, prevCups) => {
    if (Object.keys(technicalInfo)?.length) {
      technicalInfo =
        supplyModeService === BOTH.toLowerCase()
          ? Array.isArray(technicalInfo)
            ? technicalInfo?.map((techInfo) => ({ ...techInfo, supplyAddress }))
            : [technicalInfo]
          : Array.isArray(technicalInfo)
          ? technicalInfo?.map((techInfo) => ({ ...techInfo, supplyAddress }))
          : [{ ...technicalInfo, supplyAddress }]
      if (isMultiPoint) {
        dispatch(setSupplyPoint([...supplyPoint, ...technicalInfo].filter(({ cups }) => !!cups && cups !== prevCups)))
      } else {
        dispatch(setSupplyPoint(technicalInfo?.map((techInfo) => ({ ...techInfo }))))
      }
      setIsContractCapacityVisible(
        [ELECTRICITY.toLowerCase(), BOTH.toLowerCase()].includes(supplyModeService) && !isOnlineChannel && !isOnlyToppings
      )
    }
  }

  const checkSelfConsumptionChanges = (newSelfConsumptionType, oldSelfConsumptionType) => {
    if (
      newSelfConsumptionType?.code === oldSelfConsumptionType?.code ||
      !oldSelfConsumptionType?.code ||
      oldSelfConsumptionType?.code === WITOUT_SELFCONSUMPTION
    ) {
      return
    }

    if (newSelfConsumptionType?.code === WITOUT_SELFCONSUMPTION) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.warningModal,
          modalData: {
            titleKey: 'selfconsumptionTypeDropOut.title',
            textKey: 'selfconsumptionTypeDropOut.description',
          },
        })
      )
    } else {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.selfconsumptionTypeMigration,
          modalData: {
            selfconsumptionTypeCode: newSelfConsumptionType?.description,
            currentSelfconsumptionType: oldSelfConsumptionType?.description,
          },
        })
      )
    }
  }
  const checkChangeOwnerSMS = () => {
    const changeOwner = { orders: [] }
    if (supplyPoint && supplyPoint.length > 0 && supplyPoint.length < 3) {
      const changeOrderLines = []
      supplyPoint.forEach((supply) => {
        if (supply?.validationClientNode === false && !changeOwnerCups?.find((elem) => elem === supply?.cups)) {
          changeOrderLines.push({
            cups: supply?.cups,
            energyType: supply?.energyType,
          })
        }
      })
      if (changeOrderLines.length > 0) {
        changeOwner.orders.push({
          orderlines: changeOrderLines,
          address: {
            externalId: supplyPoint[0]?.supplyAddress?.externalId,
            postalCode: supplyPoint[0]?.supplyAddress?.postalCode,
            provinceName: supplyPoint[0]?.supplyAddress?.provinceName,
            municipalityName: supplyPoint[0]?.supplyAddress?.municipalityName,
            streetType: supplyPoint[0]?.supplyAddress?.streetType,
            streetName: supplyPoint[0]?.supplyAddress?.streetName,
            number: supplyPoint[0]?.supplyAddress?.number,
            portal: supplyPoint[0]?.supplyAddress?.portal,
            stair: supplyPoint[0]?.supplyAddress?.stair,
            floor: supplyPoint[0]?.supplyAddress?.floor,
            door: supplyPoint[0]?.supplyAddress?.door,
            placeId: supplyPoint[0]?.supplyAddress?.placeId,
          },
        })
      }
    } else if (supplyPoint && supplyPoint.length > 0 && supplyPoint.length >= 3) {
      supplyPoint.forEach((supply) => {
        if (supply?.validationClientNode === false && !changeOwnerCups?.find((elem) => elem === supply?.cups)) {
          changeOwner.orders.push({
            orderlines: [
              {
                cups: supply?.cups,
                energyType: supply?.energyType,
              },
            ],
            address: {
              externalId: supply?.supplyAddress?.externalId,
              postalCode: supply?.supplyAddress?.postalCode,
              provinceName: supply?.supplyAddress?.provinceName,
              municipalityName: supply?.supplyAddress?.municipalityName,
              streetType: supply?.supplyAddress?.streetType,
              streetName: supply?.supplyAddress?.streetName,
              number: supply?.supplyAddress?.number,
              portal: supply?.supplyAddress?.portal,
              stair: supply?.supplyAddress?.stair,
              floor: supply?.supplyAddress?.floor,
              door: supply?.supplyAddress?.door,
              placeId: supply?.supplyAddress?.placeId,
            },
          })
        }
      })
    }
    if (changeOwner.orders.length > 0 && leadId && channel?.channelId) {
      put(`leads/${leadId}/change_titular/channel/${channel?.channelId}`, changeOwner)
      let cups =
        changeOwner.orders.length > 1
          ? changeOwner.orders.map((order) => order.orderlines.map((order) => order.cups)).flat()
          : changeOwner.orders[0].orderlines.map((order) => order.cups)

      const changeOwnerCupsAux = [...cups, ...changeOwnerCups]
      dispatch(setChangeOwnerCups(changeOwnerCupsAux))
    }
  }

  const handleContinue = (sp = undefined) => {
    if (isInvalidTariff) return
    let newSupplyArr = supplyPoint ? [...supplyPoint] : sp ? [...sp] : []
    const supplyPointAux = supplyPoint ? [...supplyPoint] : sp ? [...sp] : []
    if (supplyPointAux.length > 0 && supplyPointAux.length === selfconsumptionFormRef?.current.length) {
      let auxArr = []
      for (let index = 0; index < selfconsumptionFormRef.current.length; index++) {
        const formObj = selfconsumptionFormRef?.current[index]
        const validationErrors = validateForm(formObj?.values)
        if (!isEmptyObject(formObj?.errors) || !isEmptyObject(validationErrors)) return
        const isOKProductSelfconsumption = checkProductSelfConsumption(formObj, index)
        if (!isOKProductSelfconsumption) return
        const combinatedObj = { ...supplyPointAux[index], ...(formObj?.values ?? {}) }
        auxArr.push(combinatedObj)
      }
      newSupplyArr = [...auxArr]
    }
    checkChangeOwnerSMS()
    const shouldUncompleteBillingStep = isStepCompleted(STEPS.billing) && !normalizedAddress?.externalId
    if (shouldUncompleteBillingStep) {
      dispatch(setStepUncompleted(STEPS.billing))
    }
    dispatch(setSupplyPoint(newSupplyArr))
    dispatch(fetchLead())
    if (newSupplyArr?.length === 1 && initialSelfconsumptionType) {
      checkSelfConsumptionChanges(
        {
          code: newSupplyArr[0].selfConsumptionType,
          description: selfConsumptionTypes?.find(({ code }) => code === newSupplyArr[0].selfConsumptionType)?.description ?? '',
        },
        initialSelfconsumptionType
      )
    }
    onContinue()
  }
  const handleMultiPointContinue = () => {
    if (isInvalidTariff) return
    setIsLimitCups(false)
    const multiPoint = supplyPoint.filter(({ cups }) => !!cups)
    if (isMultiPoint && multiPoint?.length < 3) {
      setHasMinNumberSupplies(false)
      return
    }
    if (isPyme) {
      const sumConsumption = getSupplyPointConsumption(supplyPoint)
      const consumptionByEnergy = getConsumptionByEnergy(energies, selectedProduct)
      if (!!consumptionByEnergy && sumConsumption > consumptionByEnergy) {
        dispatch(setStepUncompleted(STEPS.supply))
        dispatch(
          showModal({
            modalType: MODAL_TYPES.maxConsumptionAllowed,
            modalData: {
              maxConsumptionAllowed: consumptionByEnergy,
            },
          })
        )
        return
      }
    }
    let newSupplyArr = [...supplyPoint]
    if (supplyPoint.length > 0 && supplyPoint.length === selfconsumptionFormRef?.current.length) {
      let auxArr = []
      for (let index = 0; index < selfconsumptionFormRef.current.length; index++) {
        const formObj = selfconsumptionFormRef?.current[index]
        const validationErrors = validateForm(formObj?.values)
        if (!isEmptyObject(formObj?.errors) || !isEmptyObject(validationErrors)) return
        const isOKProductSelfconsumption = checkProductSelfConsumption(formObj, index)
        if (!isOKProductSelfconsumption) return
        const combinatedObj = { ...supplyPoint[index], ...formObj.values }
        auxArr.push(combinatedObj)
      }
      newSupplyArr = [...auxArr]
    }
    checkChangeOwnerSMS()
    setHasMinNumberSupplies(true)
    dispatch(setSupplyPoint(newSupplyArr))
    dispatch(setStepCompleted(STEPS.supply))
    dispatch(fetchLead())
    onContinue()
  }
  const openProductIncompatibleModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.productIncompatible,
        modalData: {
          onClose: () => {
            if (isOnlineChannel) {
              window.location.href = 'https://www.naturgy.es'
            } else {
              dispatch(setStepUncompleted(STEPS.supply))
              dispatch(setActiveStepById(STEPS.rate))
            }
          },
        },
      })
    )
  }

  const openSelfconsumptionTypeMigrationModal = (formSelfconsumptionTypeCode, currentSelfconsumptionType) => {
    const selfconsumptionTypeDescription = selfconsumptionTypes.find(
      ({ code }) => code === formSelfconsumptionTypeCode
    )?.description
    const currentSelfconsumptionTypeDescription = selfconsumptionTypes.find(
      ({ code }) => code === currentSelfconsumptionType
    )?.description

    dispatch(
      showModal({
        modalType: MODAL_TYPES.selfconsumptionTypeMigration,
        modalData: {
          selfconsumptionTypeCode: selfconsumptionTypeDescription ?? formSelfconsumptionTypeCode,
          currentSelfconsumptionType: currentSelfconsumptionTypeDescription ?? currentSelfconsumptionType,
        },
      })
    )
  }

  const onSendAnalytics = (eventAction, eventLabel) => {
    if (GA_EVENT_ACTIONS[GA_EVENT_NAMES.selectCups].includes(eventAction)) {
      if (eventAction === 'selectCupsByCups') {
        dispatch(sendGAEvent(GA_EVENT_NAMES.selectCupsByCups, { currentStep: STEPS.supply }))
        if (supplyModeService === BOTH.toLowerCase()) {
          let searchByCups =
            cupsSearchByCups === true || cupsSearchByCups === false || cupsSearchByCups === null
              ? { electricity: null, gas: null }
              : cupsSearchByCups
          if (eventLabel === 'electricity') {
            searchByCups.electricity = true
            dispatch(setCupsSearcherByCups(searchByCups))
          } else {
            searchByCups.gas = true
            dispatch(setCupsSearcherByCups({ gas: true }))
          }
        } else {
          if (supplyModeService === ELECTRICITY.toLowerCase()) {
            dispatch(setCupsSearcherByCups({ electricity: true }))
          } else {
            dispatch(setCupsSearcherByCups({ gas: true }))
          }
        }
      } else {
        dispatch(sendGAEvent(GA_EVENT_NAMES.selectCupsByAddress, { currentStep: STEPS.supply }))
        if (supplyModeService === BOTH.toLowerCase()) {
          let searchByCups =
            cupsSearchByCups === true || cupsSearchByCups === false || cupsSearchByCups === null
              ? { electricity: null, gas: null }
              : cupsSearchByCups
          if (eventLabel === 'electricity') {
            searchByCups.electricity = false
            dispatch(setCupsSearcherByCups(searchByCups))
          } else {
            searchByCups.gas = false
            dispatch(setCupsSearcherByCups(searchByCups))
          }
        } else {
          if (supplyModeService === ELECTRICITY.toLowerCase()) {
            dispatch(setCupsSearcherByCups({ electricity: false }))
          } else {
            dispatch(setCupsSearcherByCups({ gas: false }))
          }
        }
      }
    } else {
      //dispatch(sendGAEvent(GA_EVENT_NAMES.calculatorEvents, { eventAction, eventLabel }))
    }
  }

  const handleEditCapacities = (resetForm) => {
    setIsCapacityEditMode(!isCapacityEditMode)
    if (isCapacityEditMode) {
      resetForm()
    }
  }

  // TODO: temporary workaround to allow only legal capacities. Find a way to restrict them when typing
  const onKeyDown = (e) => {
    if (!CAPACITY_FIELD_ALLOWED_KEYS.includes(e.keyCode)) {
      e.preventDefault()
      return false
    }
  }

  const handleNormalizedAddress = (addressData) => {
    // TODO: Change when IBM fix streetType value
    // Right now, we receive it as a null value, this has to be fixed by them
    // This happen only in development
    const normalizedAddress = addressData
      ? { ...addressData, streetType: addressData?.roadTypeName || addressData?.streetType || STREET_TYPE_DEFAULT }
      : false
    dispatch(setNormalizedAddress(normalizedAddress))

    if ((!billingAddress && stepStateByProperty(STEPS.billing, 'isCompleted')) || (billingAddress && !differentBillingAddress)) {
      dispatch(setBillingAddress(normalizedAddress))
    }
  }

  const handleSupplyAddress = (supplyAddress) => {
    // TODO: Change when IBM fix streetType value
    // Right now, we receive it as a null value, this has to be fixed by them
    // This happen only in development
    dispatch(
      setSupplyAddress({
        ...supplyAddress,
        streetType: supplyAddress?.roadTypeName || supplyAddress?.streetType || STREET_TYPE_DEFAULT,
      })
    )
  }

  const imageCups = () => {
    let imageUrl
    if (i18n.language === 'es') {
      imageUrl = cupsInfoEs
    } else if (i18n.language === 'ca') {
      imageUrl = cupsInfoCa
    } else if (i18n.language === 'gl') {
      imageUrl = cupsInfoGl
    }
    return imageUrl
  }

  const openCupsModal = () => {
    const url = imageCups()
    dispatch(
      sendGAEvent(GA_EVENT_NAMES.cupsInfo, { currentStep: STEPS.supply }),
      showModal({ modalType: MODAL_TYPES.cups, modalData: { url } }),
      sendGAEvent(GA_EVENT_NAMES.openCupsInfo, { currentStep: STEPS.supply })
    )
  }

  const resetSupplyPoint = (resetCalculator) => {
    resetCalculator()
    dispatch(resetSupplyStep())
    setIsContractCapacityVisible(false)
  }

  const getDraftTechnicalInfo = () => {
    const technicalInfo =
      draftSelectedProduct?.map(({ technicalInfoElectricity, technicalInfoGas, productVariant }) => {
        const techSelected = technicalInfoElectricity || technicalInfoGas
        const energyType = productVariant?.product?.energyType
        return { ...techSelected, energyType }
      }) || []
    return technicalInfo
  }

  // TODO: Move it to redux sagas, also in cointainers/modals/NewSupplyAddress
  const showNewSupplyModal = async (completeSupplyPoint, supplyData, handleSubmit) => {
    if (supplyData?.service.toLowerCase() === ELECTRICITY.toLowerCase()) {
      const isContratable = checkIsContratable(supplyData?.technicalInfo?.contratable)
      if (!isContratable) return
    }

    const tariffData = await getTariffData(supplyData.service, selectedProduct, channel)
    dispatch(
      showModal({
        modalType: MODAL_TYPES.confirmNewSupply,
        modalData: {
          completeSupplyPoint,
          supplyData,
          supplyModeService,
          step: 3,
          onSubmit: handleSubmit,
          tariffData,
        },
      })
    )
  }

  const showNewSupplyAddressModalForm = async (completeSupplyPoint, supplyData, checkAddressObject = null) => {
    const { service, cups, energyStep, technicalInfo } = supplyData
    dispatch(
      showModal({
        modalType: MODAL_TYPES.newSupplyAddress,
        modalData: {
          completeSupplyPoint,
          service,
          supplyModeService,
          addressCups: cups,
          shouldGetCalculations: isFlatRate,
          step: 3,
          energyStep,
          supplyTechnicalInfo: technicalInfo,
          userDocumentNumber,
          documentType,
          checkAddressObject,
        },
      })
    )
  }

  const showNewSupplyTechnicalModalForm = async (technicalInfo, service, onSubmit, address, showOwnerModal = false) => {
    const tariffData = await getTariffData(service, selectedProduct, channel)
    dispatch(
      showModal({
        modalType: MODAL_TYPES.newSupplyTechnical,
        modalData: {
          onSubmit,
          service,
          tariffData,
          technicalInfo,
          address,
          ownerModal: showOwnerModal ? showChangeOwnerModal : null,
        },
      }),
      sendGAEvent(GA_EVENT_NAMES.newSupplyTechModal, { currentStep: STEPS.supply })
    )
  }

  const handleAddSupplyPoint = (index) => {
    setHasMinNumberSupplies(true)
    if (cannotAddSupplies) {
      setIsLimitCups(true)
      return
    }
    let newSupplyArr = [...supplyPoint]
    if (supplyPoint.length > 0 && supplyPoint.length === selfconsumptionFormRef?.current.length) {
      let auxArr = []
      for (let index = 0; index < selfconsumptionFormRef.current.length; index++) {
        const formObj = selfconsumptionFormRef?.current[index]
        const validationErrors = validateForm(formObj?.values)
        if (!isEmptyObject(formObj?.errors) || !isEmptyObject(validationErrors)) return
        const isOKProductSelfconsumption = checkProductSelfConsumption(formObj, index)
        if (!isOKProductSelfconsumption) return
        const combinatedObj = { ...supplyPoint[index], ...formObj.values }
        auxArr.push(combinatedObj)
      }
      newSupplyArr = [...auxArr]
    }
    dispatch(setSupplyPoint([...newSupplyArr, { cups: null }]))
    dispatch(setIsMultiPoint())
    dispatch(setStepUncompleted(STEPS.supply))

    dispatch(
      setInvoiceData({
        ...invoiceData,
        [index]: null,
      })
    )
  }

  const handleRemoveSupplyPoint = (cups, index = 0) => {
    setIsLimitCups(false)
    setHasMinNumberSupplies(true)
    dispatch(showModal({ modalType: MODAL_TYPES.removeSupplyPoint, modalData: { cups, invoiceData, index } }))
  }

  const checkProductSelfConsumption = (formObj, index) => {
    const formSelfConsumptionType = formObj.values?.selfConsumptionType
    const currentSelfConsumptionType = supplyPoint[index]?.selfConsumptionType
    const isCompatible = isProductCompatible(selectedProduct, formSelfConsumptionType, selfconsumptionTypes)
    const isSelfconsumptionMigration = isSelfconsumptionTypeMigration(formSelfConsumptionType, currentSelfConsumptionType)
    if (!isCompatible) {
      openProductIncompatibleModal()
      return false
    }
    if (isSelfconsumptionMigration) {
      openSelfconsumptionTypeMigrationModal(formSelfConsumptionType, currentSelfConsumptionType)
    }
    return true
  }

  const handleCancelSupplyPoint = () => {
    setIsRepeatedCups(false)
    setIsInvalidTariff(false)
    const multiPoint = supplyPoint.filter(({ cups }) => !!cups)
    dispatch(setSupplyPoint(multiPoint))
    if (multiPoint?.length === 1) dispatch(clearIsMultiPoint())
  }

  const showPymesModal = (technicalInfo, showOwnerModal = false, selectedService) => {
    if (!isLeadError) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.technicalData,
          modalData: {
            technicalInfo:
              technicalInfo.length > 0
                ? technicalInfo.find((elem) => elem.energyType.toUpperCase() === selectedService.toUpperCase())
                : technicalInfo,
            ownerModal: showOwnerModal ? showChangeOwnerModal : null,
            selectedService,
          },
        })
      )
    }
  }

  const handleNewSupplyChecked = (e) => {
    const isNewSupply = e.target.checked
    if (e.target.checked) {
      dispatch(sendGAEvent(GA_EVENT_NAMES.clickNNSS, { currentStep: STEPS.supply }))
    }
    dispatch(setIsNewSupply(isNewSupply))
    setIsNewSupplyChecked(e.target.checked)
    if (!isOnlineChannel && !isDuplicateOrderDraft) {
      dispatch(fetchProducts({ channel: channel.id, referenceSegment, isNewSupply }))
    }
    dispatch(fetchToppings({}))
  }

  const setDualSupplyPoint = (supplyPoint) => {
    dispatch(setSupplyPoint(supplyPoint))
  }

  /**
   * Complete supply step function to use it in
   * supply point in case of online channel draft
   */
  const completeSupplyStep = () => {
    dispatch(setStepCompleted(STEPS.supply))
  }

  useEffect(() => {
    if (!!supplyPoint) {
      const supply = supplyPoint.find(({ energyType }) => energyType === ELECTRICITY)

      if (!supply) return

      const capacitiesArr = Object.keys(supply)
        .filter((key) => key.startsWith('capacityP'))
        .map((capacity) => ({
          name: capacity,
          value: supply[capacity],
        }))
        .filter((capacity) => !['0', ''].includes(capacity.value))
        .reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {})
      setCapacities(capacitiesArr)
      setTariff(supply.accessTariff)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyPoint])

  useEffect(() => {
    if (selectedProduct?.some((item) => item?.flatRate) && supplyPoint?.length) {
      const isElectricity = supplyPoint.some((item) => item.energyType === 'ELECTRICITY')
      setIsContractCapacityVisible(isElectricity && !isOnlineChannel && !isOnlyToppings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, supplyPoint?.length])

  useEffect(() => {
    if (supplyModeService === GAS.toLowerCase()) {
      setIsContractCapacityVisible(false)
    }
  }, [supplyModeService])

  useEffect(() => {
    if (isOnlineChannel && queries?.nnss === COL_NNSS_VALUES.isNewSupply) {
      dispatch(setIsNewSupply(true))
      setIsNewSupplyChecked(true)
      // setIsNewSupplyGasChecked(true)
      dispatch(fetchToppings({}))
    }
  }, [isOnlineChannel, queries?.nnss, dispatch])

  useEffect(() => {
    if (isDuplicateOrderDraft && duplicateOrderDraft) {
      setIsNewSupplyChecked(
        duplicateOrderDraft.supplyStep?.electricity?.nnss || duplicateOrderDraft.supplyStep?.gas?.nnss || false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateOrderDraft])

  useEffect(() => {
    if (!isStepActive || selectedProduct.length === 0) return
    if (isDisallowedNewAddressProductChange) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.warningModal,
          modalData: {
            titleKey: 'selectionDisallowedInNewDirection',
            textKey: `selectionDisallowedInNewDirection_${selectedCompany?.code}`,
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, isDisallowedNewAddressProductChange])

  const wrongCupsValidation = (code) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.checkCups,
        modalData: {
          sameClient: code === '01',
          cupsActive: code === '05',
        },
      })
    )
    if (isTenureSelected) {
      dispatch(clearSelectedProduct())
      dispatch(clearProductVariants())
      dispatch(setStepUncompleted(STEPS.supply))
      dispatch(setStepUncompleted(STEPS.rate))
      dispatch(setStepDisabled(STEPS.supply))
      dispatch(setActiveStep(1))
    }
  }

  const validateForm = (values) => {
    const { selfConsumptionType, selfConsumptionPowerInstalled } = values
    let errors = {}
    if ((selfConsumptionType === '11' || selfConsumptionType === '12') && selfConsumptionPowerInstalled < 1) {
      errors.selfConsumptionPowerInstalled = 'Para el tipo de autoconsumo seleccionado la potencia debe ser mayor de 0'
    }
    return errors
  }

  const checkIsContratable = (contratable) => {
    if (contratable === 'N') {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.error,
          modalData: {
            translationLabel: 'contratableField',
          },
        })
      )
      return false
    }
    return true
  }

  const openInfoModal = () => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.scanInvoiceInfo,
      })
    )
  }
  const sendEditGAEvent = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.editSupplyPoint, { currentStep: STEPS.supply }))
  }

  const sendGAEventUploadInvoiceImage = () => {
    dispatch(sendGAEvent(GA_EVENT_NAMES.uploadInvoiceImage, { currentStep: STEPS.supply }))
  }
  const sendGAinfoInvoiceEvent = () => {
    dispatch(
      sendGAEvent(GA_EVENT_NAMES.infoInvoice, { currentStep: STEPS.supply }),
      sendGAEvent(GA_EVENT_NAMES.openInfoModal, { currentStep: STEPS.supply })
    )
  }

  const openUploadImageModal = (index) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.scanInvoice,
        modalData: { index },
      })
    )
  }
  const setPortabilityNewco = (value) => {
    dispatch(setPortabilityNewcoCheck(value))
  }
  const showNoCPModal = (checkTenure) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.warningModal,
        modalData: {
          titleKey: 'addressValidation.modal.title',
          textKey: checkTenure ? `addressValidation.modal.diferentCPTenure` : `addressValidation.modal.diferentCP`,
        },
      })
    )
  }
  const showChangeOwnerModal = (service) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.ownerModal,
        modalData: {
          service,
        },
      })
    )
  }
  const showNoSameAddressModal = (checkTenure, showOwnerModal = false, service) => {
    dispatch(
      showModal({
        modalType: MODAL_TYPES.checkAddress,
        modalData: {
          checkTenure,
          service,
          ownerModal: showOwnerModal ? showChangeOwnerModal : null,
        },
      })
    )
  }

  const clearChangeOwner = () => {
    dispatch(clearChangeSupplyOwnerShip([]))
  }

  const supplyStepViewProps = {
    supplyMode,
    isSupplyStepCompleted,
    referenceSegment,
    isContractCapacityVisible,
    handleContinue,
    supplyModeService,
    initialValues,
    tariff,
    capacity30ValidationSchema,
    capacity206xValidationSchema,
    capacities,
    onKeyDown,
    isCapacityEditMode,
    handleEditCapacities,
    isNewSupply,
    supplyPoint,
    isMultiPoint,
    onAddSupplyPoint: handleAddSupplyPoint,
    onMultiPointContinue: handleMultiPointContinue,
    onCancelSupplyPoint: handleCancelSupplyPoint,
    isRepeatedCups,
    isLimitCups,
    hasMinNumberSupplies,
    isTenureSelected,
    isInvalidTariff,
    isLeadFetching,
    isDuplicateOrderDraft,
    isDisallowedNewAddressProductChange: isDisallowedNewAddressProductChange && selectedProduct.length > 0,
    selfconsumptionFormRef,
    isOnlineChannel,
    isPortabilityNewcoCheck,
    isNewCo,
    isPortability,
    supplyAddress,
  }

  const supplyPointProps = {
    isEnabledCupsByInvoice: true,
    openInfoModal,
    openUploadImageModal,
    sendGAEventUploadInvoiceImage,
    sendGAinfoInvoiceEvent,
    onSendAnalytics,
    service: supplyModeService,
    onSubmit: handleSubmit,
    getQuota: !!supplyMode,
    isDisabled: isLeadFetching,
    isLeadError: isLeadError,
    isOnlineChannel,
    isOpen: activeStep === 2,
    tariff,
    capacities,
    supplyPoint,
    shouldGetCalculations: isFlatRateObject,

    referenceSegment,
    handleNormalizedAddress,
    normalizedAddress,
    handleSupplyAddress,
    tenure: selectedTenure,
    isTenureSelected,
    sendEditGAEvent,
    onTenureComplete,
    isResetFunnelContract,
    resetSupplyPoint,
    isFlatRate: selectedProduct.find((elem) => elem?.flatRate),
    preSelectedRate,
    draftTechnicalInfo: getDraftTechnicalInfo(),
    isDraft,
    onDraftComplete,
    openCupsModal,
    isNewSupply,
    showNewSupplyModal,
    showNewSupplyAddressModalForm,
    showNewSupplyTechnicalModalForm,
    isMultiPoint,
    onRemoveSupplyPoint: handleRemoveSupplyPoint,
    showPymesModal,
    setIsRepeatedCups,
    selectedProductVariants,
    selectedDualSubproducts,
    isNewSupplyChecked,
    // isNewSupplyGasChecked,
    onNewSupplyChange: handleNewSupplyChecked,
    // onNewSupplyGasChange: handleNewSupplyGasChecked,
    isNewSupplySwitchDisabled,
    supplyAddress,
    isOnlineChannelDraft,
    onlineChannelDraftData,
    setDualSupplyPoint,
    completeSupplyStep,
    isNewCo,
    isDuplicateOrderDraft,
    duplicateOrderDraft,
    setIsInvalidTariff,
    isSupplyStepCompleted,
    userDocumentNumber,
    documentType,
    selectedCompany,
    step: 3,
    checkCups,
    wrongCupsValidation,
    isPortability,
    draftOrder,
    isPortabilityNewcoCheck,
    setPortabilityNewco,
    selectedProduct,
    channel,
    getTariffData,
    showNoCPModal,
    showNoSameAddressModal,
    showChangeOwnerModal,
    activeStepNumber: activeStep,
    customer,
    orders,
    clearChangeOwner,
  }

  return (
    <SupplyStepView
      {...{
        ...supplyStepViewProps,
      }}
    >
      <SupplyPoint
        index={0}
        {...{
          ...supplyPointProps,
        }}
        cups={supplyPoint?.[0]?.cups || selectedTenure?.electricityCups}
        dataFromInvoice={invoiceData ? invoiceData[0] : null}
        isFirstSupply
      />
      {supplyPoint &&
        referenceSegment === PYME &&
        supplyPoint.every((supply) => !!supply?.cups) &&
        supplyModeService.toUpperCase() === supplyPoint[0].energyType &&
        !hideSelfConsumption && (
          <>
            <SelfconsumptionForm
              cups={supplyPoint?.[0]?.cups}
              supplyPoint={supplyPoint}
              index={0}
              selfconsumptionFormRef={selfconsumptionFormRef}
            />
          </>
        )}
      {isMultiPoint &&
        supplyPoint?.map(
          (point, index) =>
            index !== 0 && (
              <>
                {invoiceData && invoiceData[index] ? (
                  <>
                    <SupplyPoint
                      key={index}
                      index={index}
                      {...{
                        ...supplyPointProps,
                      }}
                      cups={point.cups}
                      dataFromInvoice={invoiceData[index]}
                    />
                  </>
                ) : (
                  <SupplyPoint
                    key={index}
                    index={index}
                    {...{
                      ...supplyPointProps,
                    }}
                    cups={point.cups}
                    dataFromInvoice={null}
                  />
                )}
                {!!point?.cups && !hideSelfConsumption && (
                  <SelfconsumptionForm
                    cups={point.cups}
                    supplyPoint={supplyPoint}
                    selfconsumptionFormRef={selfconsumptionFormRef}
                    index={index}
                  />
                )}
              </>
            )
        )}
    </SupplyStepView>
  )
}

export default SupplyStep
