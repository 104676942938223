import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'

import { Box, FormControl, FormHelperText, Grid, TextField as MuiTextField, Select, InputLabel, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Typography from 'ui/components/typography'
import { CUSTOM_EQUIPMENT_OWNERSHIP, PLANNED_DISCHARGE_DATE, PYME } from 'utils/constants'

const GasNewSupply = ({
  values,
  touched,
  errors,
  onChange,
  setFieldValue,
  tariffs,
  getVariantInfo,
  plannedDischargeDate,
  onPlannedDischargeDateChange,
  newSupply,
  referenceSegment,
  valueDefaultAnnualConsumption,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h5" color="primary">
          {t('newSupplyPoint.gasTitle')}
        </Typography>
      </Box>

      <Box width={{ xs: '100%', md: '80%' }}>
        {/* --- TARIFF --- */}
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <FormControl fullWidth required>
              <InputLabel htmlFor="accessTariff">{t('newSupplyPoint.form.tariff')}</InputLabel>
              <Field
                component={Select}
                fullWidth
                native
                id="accessTariff"
                name="accessTariff"
                label="Tarifa"
                value={values.accessTariff}
                onChange={(e) => {
                  onChange(e)
                  setFieldValue('consumption12Months', getVariantInfo(e.target.value)?.defaultAnnualConsumption, false)
                }}
                error={!!(touched.accessTariff && errors.accessTariff)}
              >
                <option aria-label="None" value="" />
                {tariffs?.map((tariff, index) => (
                  <option key={index} value={tariff}>
                    {tariff}
                  </option>
                ))}
              </Field>
            </FormControl>
            <ErrorMessage component={FormHelperText} name="accessTariff" error />
          </Grid>

          {/* --- DEFAULT ANNUAL CONSUMPTION --- */}
          {values.accessTariff && (
            <Grid item xs={12} sm={6}>
              <Field
                component={MuiTextField}
                required
                fullWidth
                disabled
                id="consumption12Months"
                label={t('form.fields.annualConsumption')}
                name="consumption12Months"
                value={values.consumption12Months}
                onChange={onChange('consumption12Months')}
                error={!!(touched.consumption12Months && errors.consumption12Months)}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      placement="top"
                      title={
                        valueDefaultAnnualConsumption
                          ? t('pymeTooltip.defaultConsumption12Months')
                          : t('pymeTooltip.consumption12Months')
                      }
                    >
                      <InfoOutlinedIcon color="primary" />
                    </Tooltip>
                  ),
                }}
              />
              <ErrorMessage component={FormHelperText} name="consumption12Months" error />
            </Grid>
          )}

          {newSupply && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="equipmentOwnershipType">{t('newSupplyPoint.form.customEquipmentOwnership')}</InputLabel>
                  <Field
                    component={Select}
                    fullWidth
                    native
                    id="equipmentOwnershipType"
                    name="equipmentOwnershipType"
                    value={values.equipmentOwnershipType}
                    onChange={onChange('equipmentOwnershipType')}
                    error={!!(touched.equipmentOwnershipType && errors.equipmentOwnershipType)}
                  >
                    {Object.entries(CUSTOM_EQUIPMENT_OWNERSHIP).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <ErrorMessage component={FormHelperText} name="equipmentOwnershipType" error />
              </Grid>
            </>
          )}
          {referenceSegment === PYME && !newSupply && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="plannedDischargeDate">{t('newSupplyPoint.form.plannedDischargeDate')}</InputLabel>
                  <Field
                    component={Select}
                    fullWidth
                    native
                    id="plannedDischargeDate"
                    name="plannedDischargeDate"
                    value={values.plannedDischargeDate}
                    error={!!(touched.plannedDischargeDate && errors.plannedDischargeDate)}
                    onChange={(e) => {
                      onPlannedDischargeDateChange(e)
                      setFieldValue('activationDate', null, false)
                    }}
                    disabled
                  >
                    {Object.entries(PLANNED_DISCHARGE_DATE).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <ErrorMessage component={FormHelperText} name="plannedDischargeDate" error />
              </Grid>
              {plannedDischargeDate === PLANNED_DISCHARGE_DATE.fixedDate && (
                <Grid item xs={12}>
                  <Box width={{ xs: '100%', sm: '50%' }} ml="auto" pl={4}>
                    <Field
                      component={MuiTextField}
                      fullWidth
                      id="activationDate"
                      name="activationDate"
                      label={t('newSupplyPoint.form.fixedDate')}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={onChange('activationDate')}
                      error={!!(touched.activationDate && errors.activationDate)}
                    />
                    <ErrorMessage component={FormHelperText} name="activationDate" error />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default GasNewSupply
