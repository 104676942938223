import * as actionTypes from './action-types'

export const setProducts = (products) => ({
  type: actionTypes.SET_PRODUCTS,
  payload: products,
})

export const resetProducts = () => ({
  type: actionTypes.CLEAR_PRODUCTS,
})

export const setProduct = (product) => ({
  type: actionTypes.SET_SELECTED_PRODUCT,
  payload: product,
})

export const setProductWithAttribute = (product, attributes) => ({
  type: actionTypes.SET_SELECTED_PRODUCT_WITH_ATTRIBUTE,
  payload: { product, attributes },
})

export const clearSelectedProduct = (product) => ({
  type: actionTypes.CLEAR_SELECTED_PRODUCT,
  payload: product,
})

export const setFetchedVariants = (product) => ({
  type: actionTypes.SET_FETCHED_VARIANTS,
  payload: product,
})

export const clearFetchedVariants = () => ({
  type: actionTypes.CLEAR_FETCHED_VARIANTS,
})

export const setProductsValidation = (product) => ({
  type: actionTypes.SET_PRODUCTS_VALIDATION,
  payload: product,
})

export const clearProductsValidation = (product) => ({
  type: actionTypes.CLEAR_PRODUCTS_VALIDATION,
  payload: product,
})

export const setProductVariants = (productVariants) => ({
  type: actionTypes.SET_PRODUCT_VARIANTS,
  payload: productVariants,
})

export const clearProductVariants = () => ({
  type: actionTypes.CLEAR_PRODUCT_VARIANTS,
})

export const fetchProducts = ({
  channel,
  referenceSegment,
  tenure,
  params,
  isNewSupply,
  hasChannelOrSegmentChanged,
  company,
}) => ({
  type: actionTypes.FETCH_PRODUCTS,
  payload: { channel, referenceSegment, tenure, params, isNewSupply, hasChannelOrSegmentChanged, company },
})

export const fetchSimulatorFile = (productId) => ({
  type: actionTypes.FETCH_REQUEST_SIMULATOR_FILE,
  payload: productId,
})

export const startSimulatorFile = () => ({
  type: actionTypes.START_REQUEST_SIMULATOR_FILE,
})

export const successSimulatorFile = () => ({
  type: actionTypes.SUCCESS_REQUEST_SIMULATOR_FILE,
})

export const errorSimulatorFile = () => ({
  type: actionTypes.ERROR_REQUEST_SIMULATOR_FILE,
})

export const clearSimulatorFile = () => ({
  type: actionTypes.CLEAR_REQUEST_SIMULATOR_FILE,
})

export const setIsOnlyToppings = (payload) => ({
  type: actionTypes.SET_IS_ONLY_TOPPINGS,
  payload,
})
export const clearIsOnlyToppings = () => ({
  type: actionTypes.CLEAR_IS_ONLY_TOPPINGS,
})

export const resetCalculatedCost = () => ({
  type: actionTypes.RESET_CALCULATED_COST,
})

export const setProductSelection = ({ disallowed, message }) => ({
  type: actionTypes.SET_PRODUCT_SELECTION,
  payload: { disallowed, message },
})

export const clearProductSelection = () => ({
  type: actionTypes.CLEAR_PRODUCT_SELECTION,
})
