import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Box, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { getIsOnlineChannel, getReferenceSegment } from '../services/operator/selectors'
import Typography from 'ui/components/typography'
import SelectCard from 'ui/components/selectCard'
import TenureFullCard from 'ui/components/tenureFullCard'
import AccordionBasic from 'ui/components/accordionBasic'
import { getCompanies } from 'services/company/selectors'
import { fetchCompanies } from 'services/company/actions'
import { isTenureSelectionError } from 'services/customer/selectors'
import { getFidelization } from 'services/fidelization/selectors'
import { getSelectedRate } from 'services/products/selectors'
import { getSelectedToppingsType } from 'services/toppings/selectors'
import {
  PYME,
  ID_NATURGY_IMPERIAL,
  ID_NATURGY_CLIENTES,
  COMPANY_NAMES_BY_CODE,
  BOTH as BOTH_ENERGY,
  VARIANT_TYPES,
} from 'utils/constants'

const TenureSelector = ({ isFetching, onContinue, showTitle = true, userData, selectedChannel, operatorCompanies }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const referenceSegment = useSelector(getReferenceSegment)
  const fidelization = useSelector(getFidelization)
  const selectedProduct = useSelector(getSelectedRate)
  const selectedService = selectedProduct.length > 1 ? BOTH_ENERGY : selectedProduct[0]?.energyType
  const selectedToppingsType = useSelector(getSelectedToppingsType)
  const companies = operatorCompanies?.filter((elm) => {
    if (referenceSegment === PYME) {
      return elm.name === 'Naturgy'
    } else {
      return true
    }
  })
  const naturgyId = ID_NATURGY_IMPERIAL
  const newCoId = ID_NATURGY_CLIENTES
  const [selected, setSelected] = useState({})
  const [companySelected, setCompanySelected] = useState(companies?.length === 1 ? naturgyId : newCoId)
  const [companyObj, setCompanyObj] = useState(
    companies?.length === 1 ? companies[0] : operatorCompanies?.find((elm) => elm.id === newCoId)
  )
  const isOnlineChannel = useSelector(getIsOnlineChannel) || false
  const disableSupplysPoints = !selectedChannel?.tenure && userData && !isOnlineChannel
  const allCompanies = useSelector(getCompanies)
  const error = useSelector(isTenureSelectionError)

  useEffect(() => {
    if (!allCompanies.length) {
      dispatch(fetchCompanies())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDisabled = (companyMatch, supplyPoint) => {
    if (fidelization?.allowed || fidelization?.companyId !== companyMatch?.id) {
      return false
    }
    const selectedToppingsTypes = Object.keys(selectedToppingsType)

    return (
      (selectedService === BOTH_ENERGY
        ? supplyPoint?.variants?.length > 0
        : supplyPoint?.variants?.some(({ type }) => type === VARIANT_TYPES[selectedService])) ||
      (selectedToppingsTypes.length > 0 && supplyPoint?.services?.some(({ type }) => selectedToppingsTypes.includes(type)))
    )
  }

  return (
    <Box mt={2}>
      <Box>
        {showTitle && (
          <Box mb={2}>
            <Typography variant="h5" color="primary">
              {t('relatedAddresses')}
            </Typography>
            <Typography variant="variant" color="textPrimary">
              {t('selectAddressTenure')}
            </Typography>
          </Box>
        )}
        <Grid container mt={5} spacing={2}>
          {userData?.tenure?.map((company, idx) => {
            const companyMatch = allCompanies.find((elm) => elm.code === company.origin) || company

            return (
              <Grid item xs={12} key={idx}>
                <AccordionBasic
                  title={
                    companyMatch?.name
                      ? companyMatch.name
                      : COMPANY_NAMES_BY_CODE[company.origin?.toUpperCase()] ??
                        `${company.origin[0]?.toUpperCase()}${company.origin.slice(1)}`
                  }
                  expanded={true}
                >
                  {company?.tenure?.map((supplyPoint, supplyIdx) => {
                    supplyPoint.company = company.origin
                    const disabled = isDisabled(companyMatch, supplyPoint)
                    return (
                      <>
                        {disabled ? (
                          <Box width="100%">
                            <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} alignItems="center" severity="warning">
                              <Typography variant="basic">{t(`cupsDisallowed_${companyMatch.code}`)}</Typography>
                            </Alert>
                          </Box>
                        ) : null}
                        <Grid item xs={12} key={supplyIdx}>
                          <TenureFullCard
                            disableSupplysPoints={disableSupplysPoints}
                            supplyPoint={supplyPoint}
                            selected={selected}
                            handleSelect={setSelected}
                            key={supplyIdx}
                            disabled={disabled}
                          />
                        </Grid>
                      </>
                    )
                  })}
                </AccordionBasic>
              </Grid>
            )
          })}

          {/* Nueva contatacion */}
          <Grid item xs={12}>
            <SelectCard id={null} isSelected={!selected?.addressId} onChange={() => setSelected({})}>
              <Box mb={1.5}>
                <Typography variant="h5" color="textPrimary">
                  {t('tenure.selector.newAddress.title').toUpperCase()}
                </Typography>
              </Box>
              <Typography variant="basic" color="textPrimary">
                {t('tenure.selector.newAddress.text')}
              </Typography>
            </SelectCard>
          </Grid>
        </Grid>
      </Box>
      {companies?.length && (
        <>
          <Box mt={3}>
            <Typography variant="variant" color="textPrimary">
              {t('chooseSocietyContract')}
            </Typography>
          </Box>
          <Box mt={3}>
            <Grid container>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="society-label">{t('form.fields.society')}</InputLabel>
                  <Select
                    labelId="society-label"
                    value={companySelected}
                    onChange={(event) => {
                      setCompanySelected(event.target.value)
                      setCompanyObj(operatorCompanies.find((elm) => elm.id === event.target.value))
                    }}
                  >
                    {companies?.map((elm, idx) => {
                      return (
                        <MenuItem key={idx} value={elm.id}>
                          {elm.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {error && (
        <Box mt={3}>
          <Alert severity="error">
            <Typography>{t('tenure.selector.error')}</Typography>
          </Alert>
        </Box>
      )}
      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isFetching}
          onClick={() => onContinue(selected, companyObj)}
        >
          {t('continueContract')}
        </Button>
      </Box>
    </Box>
  )
}

export default TenureSelector
