import { BUSINESS, ELECTRICITY, GAS, PYME } from 'utils/constants'

export const getMonthlyCostByService = (technicalInfo, service) =>
  technicalInfo.find((info) => info.energyType === service)?.monthlyCost

export const getReducedMonthlyCostByService = (technicalInfo, service) =>
  technicalInfo.find((info) => info.energyType === service)?.monthlyCostReduced

export const addCalculateRateToProduct = (prices, product, reducedPrices, priceWithoutTaxes, newCalculation) => {
  const reducedCost = reducedPrices[GAS] || reducedPrices[ELECTRICITY] ? { reducedCost: reducedPrices } : {}
  const monthlyCostNoTaxes =
    priceWithoutTaxes && (priceWithoutTaxes[GAS] || priceWithoutTaxes[ELECTRICITY])
      ? { monthlyCostNoTaxes: priceWithoutTaxes }
      : {}
  if (product.subProducts) {
    const updatedSubProducts = product.subProducts.map((subProduct) => ({
      ...subProduct,
      calculatedCost: prices,
      ...reducedCost,
      ...monthlyCostNoTaxes,
      newCalculation: newCalculation,
    }))
    return { ...product, subProducts: updatedSubProducts }
  } else {
    return { ...product, calculatedCost: prices, ...reducedCost, ...monthlyCostNoTaxes, newCalculation: newCalculation }
  }
}

export const getReferenceSegmentString = (referenceSegment, src) => {
  if (src === BUSINESS || referenceSegment === PYME) {
    return '&pyme=true'
  }
  return '&residential=true'
}

export const getMonthlyCostWithoutTaxesByService = (technicalInfo, service) =>
  technicalInfo.find((info) => info.energyType === service)?.monthlyCostNoTaxes

export const getIsProductAvailableForNewSupply = (selectedProduct) => {
  if (!selectedProduct[0]?.subProducts) {
    return selectedProduct.every((product) => product.availableForNewSupplies)
  }
  return selectedProduct[0]?.subProducts?.every((subProduct) => subProduct.availableForNewSupplies)
}

export const getProductsAvailableForNewSupply = (products, energyType) => {
  return products.filter((product) => product.energyType === energyType && product.availableForNewSupplies)
}

export const getSelectedProductId = (selectedProduct, energyType) => {
  if (!selectedProduct?.subProducts) {
    return selectedProduct?.id
  }
  return selectedProduct?.subProducts?.find((product) => product.energyType === energyType)?.id
}
