import {
  ELECTRICITY,
  GAS,
  REQUIRED_DOCUMENTS_ELECTRICITY,
  REQUIRED_DOCUMENTS_GAS,
  REQUIRED_DOCUMENTS_BOTH,
  ENERGY_USES,
  ENERGY_USE_ONLINE_CHANNEL_PARAMS,
} from 'utils/constants'
import { validateEmail } from 'utils/validators'
// TODO: limpiar constantes, no hay que diferenciar entre pymes y residencial

export const formatUserData = (userData) => {
  const { firstName, lastName, secondLastName, tenure, documentNumber, documentType } = userData || {}
  const fullName = `${firstName} ${lastName} ${secondLastName}`
  const formattedTenure = Object.entries(tenure).map(([street, rest]) => {
    return { street, ...rest }
  })
  return { ...userData, fullName, tenure: formattedTenure, documentNumber, documentType }
}

export const formatUserDataOnline = (userData) => {
  const user = userData.data[0]?.customer || {}
  const tenureArray = userData.data.map(({ origin, tenure }) => {
    const tenureArr = Object.values(tenure)
    return {
      origin,
      tenure: tenureArr,
    }
  })
  const userDataLength = !!Object.keys(user)?.length
  const documentNumber = validateEmail(userData.username) ? user.documentNumber : userData.username
  const email = validateEmail(userData.username) ? userData.username : user.email

  if (userDataLength) {
    user.tenure = tenureArray
    user.documentNumber = documentNumber
    user.email = email
  }

  const formattedData = userDataLength
    ? formatUserData({ ...user, documentType: 'NIF', documentNumber })
    : { documentType: 'NIF', documentNumber }

  return formattedData
}

export const getCustomerFormData = (userData) => {
  let formData = {}

  if (userData) {
    const {
      documentNumber: idDocumentNumber,
      idDocumentType,
      documentType,
      firstName: name,
      lastName,
      secondLastName,
      fullName,
      email,
      phone,
      language,
      contactName,
      contactLastName,
      contactSecondLastName,
      contactDegree,
      contactDocumentNumber,
      contactTelephone,
      contactMobilePhone,
      contactFax,
      contactEmail,
    } = userData

    formData = {
      idDocumentType: documentType || idDocumentType,
      idDocumentNumber,
      name,
      lastName,
      secondLastName,
      fullName,
      email,
      phone,
      language,
      contactName: contactName || '',
      contactLastName: contactLastName || '',
      contactSecondLastName: contactSecondLastName || '',
      contactDegree: contactDegree || '',
      contactDocumentNumber: contactDocumentNumber || '',
      contactTelephone: contactTelephone || '',
      contactMobilePhone: contactMobilePhone || '',
      contactFax: contactFax || '',
      contactEmail: contactEmail || '',
    }
  }

  return formData
}

export const getIsExClient = (userData) => {
  const { firstName, email, phone } = userData || {}
  const isExClient = !firstName || !email || !phone || !Object.keys(userData).length

  return isExClient
}

export const mapNewSupplyData = (newSupplyData) => {
  const { cups, street, number, portal, stair, floor, door, province, city } = newSupplyData
  const addressLine = [street, number, portal, stair, floor, door].join(' ').trim()
  return { cups, ...newSupplyData, addressLine, provinceName: province, municipalityName: city }
}

export const mapNewSupplyAddressLine = ({ street, number, portal, stair, floor, door }) =>
  [street, number, portal, stair, floor, door].join(' ').trim()

const getBothRequiredDocuments = (supplyPoint) => {
  const isBothNewSupply = supplyPoint?.every((supply) => supply.newSupply)
  const isGasNewSupply = supplyPoint?.some((supply) => supply.energyType === GAS && supply.newSupply)

  if (isBothNewSupply) {
    return REQUIRED_DOCUMENTS_BOTH
  } else if (isGasNewSupply) {
    return REQUIRED_DOCUMENTS_GAS
  }
  return REQUIRED_DOCUMENTS_ELECTRICITY
}

export const requiredDocuments = (energyType, supplyPoint) => {
  switch (energyType) {
    case GAS:
      return REQUIRED_DOCUMENTS_GAS
    case ELECTRICITY:
      return REQUIRED_DOCUMENTS_ELECTRICITY
    default:
      return getBothRequiredDocuments(supplyPoint)
  }
}

export const getSupplyPointConsumption = (supplyPoint) => {
  return supplyPoint?.reduce((acc, supply) => {
    return acc + parseFloat(supply.consumption12Months)
  }, 0)
}

export const getEnergyUseFromParam = (energyUse) => {
  if (energyUse === ENERGY_USE_ONLINE_CHANNEL_PARAMS.community_of_owners) {
    return ENERGY_USES.community_of_owners
  }
  return ENERGY_USES.commercial
}

/**
 * It is necessary send referral code when selectedTenure contains only one product and selectedProducts contains an energy type
 * different from tenure's energy type.
 * The productReferralCode is the id from the product selected which energy type is different from selectedTenure
 */
export const getProductReferralCode = ({ selectedProducts, selectedTenure }) => {
  let productReferralCode = null

  if (selectedProducts && selectedTenure?.products?.length === 1) {
    const energyTypeTenure = selectedTenure.products[0].type
    const energyTypesSP = selectedProducts?.energyType && selectedProducts.energyType.trim().split(',')

    if (energyTypesSP && energyTypesSP.length === 1 && energyTypeTenure !== energyTypesSP[0]) {
      productReferralCode = selectedProducts.id
    }

    if (energyTypesSP && energyTypesSP.length > 1 && selectedProducts.subProducts) {
      const energyType = energyTypesSP.find((energyType) => !energyTypeTenure.includes(energyType))
      const product = selectedProducts?.subProducts.find((subProduct) => subProduct.energyType === energyType)
      if (product && product?.id) productReferralCode = product.id
    }
  }

  return productReferralCode
}
