import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import { setActiveStepById, goNextStep } from 'services/stepper/actions'
import { clearSelectedProduct, clearProductVariants } from 'services/products/actions'
import { ELECTRICITY, STEPS } from 'utils/constants'

import Typography from 'ui/components/typography'
import Link from 'ui/components/link'

import useStyles from './styles'

const PowerChange = ({ handleClose, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const changes = data.energyChanges

  const handleCancel = () => {
    dispatch(clearSelectedProduct())
    dispatch(clearProductVariants())
    dispatch(setActiveStepById(STEPS.rate))
    handleClose()
  }

  const handleAccept = () => {
    dispatch(goNextStep())
    handleClose()
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('needChangeTariff')}
          </Typography>
        </Box>
        <Box mb={3}>
          {changes.length === 1 ? (
            <Typography variant="basic" color="textPrimary">
              <Trans i18nKey="singleTariffChange" values={{ from: changes[0].from, to: changes[0].to, tariff: data.productName }}>
                <strong></strong>
                <strong></strong>
                <strong></strong>
              </Trans>
            </Typography>
          ) : (
            <Typography variant="basic" color="textPrimary">
              <Trans
                i18nKey="multiTariffChange"
                values={{
                  energy1: changes[0].type === ELECTRICITY ? t('services.electricity') : t('services.gas'),
                  from1: changes[0].from,
                  to1: changes[0].to,
                  energy2: changes[1].type === ELECTRICITY ? t('services.electricity') : t('services.gas'),
                  from2: changes[1].from,
                  to2: changes[1].to,
                  tariff: data.productName,
                }}
              >
                <strong></strong>
                <strong></strong>
                <strong></strong>
                <strong></strong>
                <strong></strong>
              </Trans>
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box mr={3}>
          <Link component="button" variant="link" onClick={handleCancel}>
            {t('common.cancel')}
          </Link>
        </Box>
        <Button color="secondary" variant="contained" onClick={handleAccept}>
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default PowerChange
