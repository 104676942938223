import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    border: `${theme.border.style.thin} solid ${theme.palette.primary.light3}`,
    boxShadow: theme.shadows[3],
    '&.selected': {
      border: `${theme.border.style.thin} solid ${theme.palette.primary.main}`,
    },
    cursor: 'pointer',
  },
  content: {
    padding: theme.spacing(3, 2, 2, 9),
  },
  radioContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  logos: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  logo: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    '& + &': {
      margin: theme.spacing(1, 0, 0, 0),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
  },
  subtitle: {
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  data: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: 43,
    },
  },
  details: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  priceBox: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexGrow: '1',
  },
  detailsService: {
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  prices: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      flex: '0 0 60%',
    },
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
    '& + &': {
      marginTop: theme.spacing(0.5),
    },
  },
  priceTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  iconLink: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
  calculateRate: {
    alignSelf: 'start',
  },
  recommendBox: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: 'white',
    border: `${theme.border.style.thin} solid ${theme.palette.primary.light3}`,
    borderBottomRightRadius: '3px',
    padding: theme.spacing(7 / 8),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
}))

export default useStyles
