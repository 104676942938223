import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: theme.spacing(2.25),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2.5),
    },
  },
}))

export default useStyles
