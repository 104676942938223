import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import { ReactComponent as IconCheck } from '@zatopek/core/src/assets/icons/check.svg'

import BankAccount from 'ui/forms/bankAccount'
//import ProtectionService from 'ui/forms/protectionService'
import Typography from 'ui/components/typography'
import ErrorFocus from 'ui/forms/errorFocus'
import useStyles from './styles'
import { PYME } from 'utils/constants'
import { useDispatch } from 'react-redux'
import { Box, Button, Collapse, Divider, FormControlLabel, Checkbox, SvgIcon } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AddressWrapper from 'ui/forms/addressWrapper'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'
import deleteEmptyFieldsObjects from '@zatopek/core/src/utils/deleteEmptyFieldsObject'
import AddressErrorsAlert from 'ui/components/addressErrorsAlert'
import { useSelector } from 'react-redux'
import { getSelectedTenure } from 'services/customer/selectors'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'

const BillingStep = ({
  setValues,
  setFieldValue,
  setFieldError,
  errors,
  touched,
  values,
  resetForm,
  normalizedAddress,
  isChangeBillingAddress,
  isDisabled,
  isOnlineChannel,
  onPostalCodeChange,
  onStreetTypeChange,
  onChangeBillingAddress,
  onChange,
  onSepaClick,
  cities,
  cityInputValue,
  setCityInputValue,
  streetSuggestions,
  streetTypes,
  referenceSegment,
  isResetFunnelContract,
  resetBillingStep,
  onChangeDifferentBillingAddress,
  differentBillingAddressChecked,
  isMultiPoint,
  hasEcoContract,
  isOnlyToppings,
  isDuplicateOrderDraft,
  userData,
  customerData,
  handleSameHolder,
  resetBillingAddress,
  isActive,
  ibanReover,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const teanure = useSelector(getSelectedTenure)
  const teanuerFol = teanure?.fol
  const { portal, stair, floor, door } = errors
  const addressFormErrors = { portal, stair, floor, door }
  const disabledIban = ibanReover || teanure?.iban
  const dispatch = useDispatch()
  deleteEmptyFieldsObjects(addressFormErrors)

  const OPTIONAL = t('common.optional').at(0)?.toLocaleLowerCase() + t('common.optional').slice(1)

  useEffect(() => {
    if (isResetFunnelContract) {
      resetBillingStep(resetForm)
    }
  }, [isResetFunnelContract, resetForm, resetBillingStep])

  const EcoContract = () => (
    <>
      <Divider className={classes.block} />
      <Box className={classes.block}>
        <Box mb={2}>
          <FormControlLabel
            name="ecoContract"
            control={<Checkbox size="small" />}
            checked={values.ecoContract}
            onChange={onChange}
            label={
              <Typography variant="h4" color="textPrimary">
                {t('form.fields.ecoContractLabel')}
              </Typography>
            }
          />
        </Box>
        <Alert icon={false} severity="info">
          {t('form.fields.ecoContractDescription')}
        </Alert>
      </Box>
    </>
  )

  return (
    <Form noValidate autoComplete="off" className="billing-step">
      <Box className={classes.block}>
        <Box mb={2}>
          <Typography variant="h4" color="textPrimary">
            {t('bankAccount')}
          </Typography>
        </Box>
        <BankAccount
          errors={errors}
          touched={touched}
          onChange={onChange}
          onSepaClick={onSepaClick}
          setFieldValue={setFieldValue}
          values={values}
          handleSameHolder={handleSameHolder}
          ibanReover={disabledIban}
          referenceSegment={referenceSegment}
        />
      </Box>
      {!isOnlineChannel && (
        <>
          <Divider className={classes.block} />
          <Box className={classes.block}>
            <Box mb={2} className={classes.row}>
              <FormControlLabel
                name="onlineInvoice"
                control={<Checkbox size="small" />}
                checked={values.onlineInvoice}
                onChange={onChange}
                label={
                  <Typography variant="h4" color="textPrimary">
                    {t('paperlessBill')}
                  </Typography>
                }
              />
              {teanuerFol && (
                <Box className={classes.check}>
                  <SvgIcon component={IconCheck} className={classes.icon} />

                  <Typography variant="h5" color="textPrimary">
                    {t('paperlessBillTenure')}
                  </Typography>
                </Box>
              )}
            </Box>
            <Alert icon={false} severity="info">
              {t('form.fields.paperlessBillDescription')}
            </Alert>
          </Box>
        </>
      )}

      {isOnlineChannel && referenceSegment === PYME && hasEcoContract && <EcoContract />}

      {/**  {referenceSegment !== PYME && !isOnlyToppings && (
        <>
          <Divider className={classes.block} />
          <Box className={classes.block}>
            <Box mb={2}>
              <Typography variant="h4" color="textPrimary">
                {t('protectionService')}
              </Typography>
            </Box>
            <ProtectionService
              errors={errors}
              touched={touched}
              onChange={onChange}
              setFieldValue={setFieldValue}
              values={values}
            />
          </Box>
        </>
      )} */}

      <Divider className={classes.block} />
      <Box className={classes.block}>
        <Box mb={2}>
          <FormControlLabel
            name="differentBillingAddress"
            control={<Checkbox size="small" />}
            checked={isMultiPoint || differentBillingAddressChecked || isOnlyToppings}
            onChange={(e) => {
              if (e.target.checked) dispatch(sendGAEvent(GA_EVENT_NAMES.differentBilling, { currentStep: STEPS.billing }))

              onChangeDifferentBillingAddress({ e, values, setValues, setFieldValue, setFieldError })
              onChangeBillingAddress(e)
            }}
            disabled={isMultiPoint || isOnlyToppings}
            label={
              <Typography variant="h4" color="textPrimary">
                {t(isOnlyToppings ? 'differentBillingAddressOnlyToppings' : 'differentBillingAddress')}
                {!isOnlyToppings && !isMultiPoint && <small className={classes.small}>{` (${OPTIONAL})`}</small>}
              </Typography>
            }
          />
        </Box>
      </Box>

      <Collapse in={isMultiPoint || differentBillingAddressChecked || isOnlyToppings}>
        <AddressWrapper
          onPostalCodeChange={onPostalCodeChange}
          onStreetTypeChange={onStreetTypeChange}
          cities={cities}
          cityInputValue={cityInputValue}
          setCityInputValue={setCityInputValue}
          streetSuggestions={streetSuggestions}
          streetTypes={streetTypes}
          normalizedAddress={normalizedAddress}
          errors={errors}
          touched={touched}
          onChange={onChange}
          setFieldValue={setFieldValue}
          values={values}
          setValues={setValues}
          setFieldError={setFieldError}
          onChangeBillingAddress={onChangeBillingAddress}
          isChangeBillingAddress={isChangeBillingAddress}
          classes={classes}
          isOnlyToppings={isOnlyToppings}
          resetBillingAddress={resetBillingAddress}
          isActive={isActive}
        />
      </Collapse>
      {!isEmptyObject(addressFormErrors) && !isEmptyObject(touched) && <AddressErrorsAlert errors={errors} touched={touched} />}

      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button type="submit" variant="contained" color="secondary" disabled={isDisabled}>
          {t('common.continue')}
        </Button>
      </Box>
      <ErrorFocus errorWrapperClass="billing-step" />
    </Form>
  )
}

export default BillingStep
