import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme()

const svgIcon = {
  overrides: {
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: defaultTheme.spacing(5),
      },
    },
  },
}

export default svgIcon
