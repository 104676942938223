export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const SET_ACTIVE_STEP_BY_ID = 'SET_ACTIVE_STEP_BY_ID'
export const CLEAR_ACTIVE_STEP = 'CLEAR_ACTIVE_STEP'
export const SET_STEPS = 'SET_STEPS'
export const CLEAR_STEPS = 'CLEAR_STEPS'
export const GO_NEXT_STEP = 'GO_NEXT_STEP'
export const UPDATE_STEP = 'UPDATE_STEP'
export const DISABLE_STEPS = 'DISABLE_STEPS'
export const PRESELECTED_STEP = 'PRESELECTED_STEP'
export const DISABLE_SUPPLY_STEP_TENURE = 'DISABLE_SUPPLY_STEP_TENURE'
export const GO_NEXT_STEP_DUPLICATE = 'GO_NEXT_STEP_DUPLICATE'
export const SET_ACTIVE_STEP_DUPLICATE = 'SET_ACTIVE_STEP_DUPLICATE'
export const CLEAR_ACTIVE_STEP_DUPLICATE = 'CLEAR_ACTIVE_STEP_DUPLICATE'
