import MuiTypography from '@material-ui/core/Typography'

import useStyles from './styles'

const Typography = ({ variant, children, ...props }) => {
  const classes = useStyles()

  return (
    <MuiTypography {...props} className={classes[variant]}>
      {children}
    </MuiTypography>
  )
}

export default Typography
