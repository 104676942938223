import { Box, Switch, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const NewSupplySwitch = ({ isNewSupplySwitchDisabled, openCupsModal, isNewSupplyChecked, onNewSupplyChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    !isNewSupplySwitchDisabled &&
    !!openCupsModal && (
      <Box width={1} display="flex" flexDirection="row" alignItems="center" mb={2}>
        <Switch
          color="primary"
          checked={isNewSupplyChecked}
          onChange={onNewSupplyChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box ml={1}>
          <Typography className={classes.newSupplyText}>{t('newSupplyPoint.staticTitle')}</Typography>
        </Box>
      </Box>
    )
  )
}

export default NewSupplySwitch
