import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(4),
  },
  dialogContent: {
    padding: theme.spacing(0, 4),
  },
  dialogActions: {
    padding: theme.spacing(4),
    justifyContent: 'flex-end',
  },
}))

export default useStyles
