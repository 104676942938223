import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'

import Typography from 'ui/components/typography'
import useStyles from './styles'

const LocutionInfo = ({ CRMId, resumeLocution }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4" color="textPrimary">
          {resumeLocution ? t('common.resumeLocution') : t('locutionFinish')}
        </Typography>
      </Box>
      <Typography variant="basic" color="textPrimary">
        <strong>{t('locutionCheck')}</strong>
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="basic" color="textPrimary">
            {t('locutionCRMId', { id: CRMId })}
          </Typography>
        </li>
        <li>
          <Typography variant="basic" color="textPrimary">
            {t('locutionCheckText1')}
          </Typography>
        </li>
        <li>
          <Typography variant="basic" color="textPrimary">
            {t('locutionCheckText2')}
          </Typography>
        </li>
        <li>
          <Typography variant="basic" color="textPrimary">
            {t('locutionCheckText3')}
          </Typography>
        </li>
      </ul>
    </>
  )
}

export default LocutionInfo
