import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Field, ErrorMessage } from 'formik'

import { Box, Button, FormHelperText, TextField as MuiTextField } from '@material-ui/core'
import Link from 'ui/components/link'
import Typography from 'ui/components/typography'

import useStyles from './styles'
import { useSelector } from 'react-redux'
import { getCompany } from 'services/customer/selectors'

const CallMeBack = ({ onChange, touched, values, errors, isFetching }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const company = useSelector(getCompany)

  return (
    <>
      <Box className={classes.fields}>
        <Box>
          <Field
            component={MuiTextField}
            required
            fullWidth
            error={!!(touched.phone && errors.phone)}
            id="phone"
            type="number"
            label={t('common.writePhone')}
            name="phone"
            maxLength={9}
            max={9}
            value={values.phone}
            onChange={onChange('phone')}
          />
          <ErrorMessage component={FormHelperText} name="phone" error />
        </Box>
      </Box>

      <Box mb={3} className={classes.policyBox}>
        <Typography variant="body2Sb" color="textSecondary">
          {t('callMeBack.policy.title')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Trans i18nKey={`callMeBack.policy.text-${company?.code?.toLowerCase() || 'gns'}`}>
            <Link
              underline="always"
              href={t(`callMeBack.policy.url-${company?.code?.toLowerCase() || 'gns'}`)}
              target="_blank"
            ></Link>
          </Trans>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={4}>
        <Button type="submit" variant="contained" color="secondary" disabled={isFetching}>
          {t('callMeBackButton')}
        </Button>
      </Box>
    </>
  )
}

export default CallMeBack
