import React from 'react'
import { useTranslation } from 'react-i18next'
import { BOTH, PYME } from 'utils/constants'
import { GAS, ELECTRICITY } from '@zatopek/core/src/utils/constants'
import useQuery from '@zatopek/core/src/hooks/useQuery'
import { Box, Button, Divider, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import SelfconsumptionForm from './selfconsumptionForm'
import CapacityForm from './capacityForm'
import Typography from 'ui/components/typography'
import { useSelector } from 'react-redux'
import { getChangeSuppyOwnerShip } from 'services/customer/selectors'
import { SELFCONSUMPTION_DISABLED } from 'services/selfconsumption/constants'
import ChangeSupplyOwnership from './changeSupplyOwnerShip'
import { getSelectedTenure } from 'services/customer/selectors'
import useStyles from './styles'

const SupplyStep = ({
  children,
  isSupplyStepCompleted,
  referenceSegment,
  isContractCapacityVisible,
  handleContinue,
  supplyModeService,
  initialValues,
  tariff,
  capacity30ValidationSchema,
  capacity206xValidationSchema,
  capacities,
  onKeyDown,
  isCapacityEditMode,
  handleEditCapacities,
  isNewSupply,
  supplyPoint,
  isMultiPoint,
  onAddSupplyPoint,
  onMultiPointContinue,
  onCancelSupplyPoint,
  isRepeatedCups,
  isLimitCups,
  hasMinNumberSupplies,
  isTenureSelected,
  isInvalidTariff,
  isLeadFetching,
  isDuplicateOrderDraft,
  isDisallowedNewAddressProductChange,
  selfconsumptionFormRef,
  isOnlineChannel,
  isNewCo,
  isPortability,
  supplyAddress,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const query = useQuery()
  const changeSupplyOwnerShip = useSelector(getChangeSuppyOwnerShip)
  const tenure = useSelector(getSelectedTenure)
  const fideElectricity =
    tenure?.products?.find((product) => product.type === ELECTRICITY.toUpperCase()) &&
    (supplyModeService === ELECTRICITY || supplyModeService === BOTH)
  const fideGas =
    tenure?.products?.find((product) => product.type === GAS.toUpperCase()) &&
    (supplyModeService === GAS || supplyModeService === BOTH)
  const VCN = {
    electricity:
      supplyPoint &&
      supplyPoint.length &&
      supplyPoint.find((sp) => sp?.energyType?.toLowerCase() === ELECTRICITY)?.validationClientNode,
    gas:
      supplyPoint && supplyPoint.length && supplyPoint.find((sp) => sp?.energyType?.toLowerCase() === GAS)?.validationClientNode,
  }

  const hideSelfConsumption = SELFCONSUMPTION_DISABLED || (isOnlineChannel && query.get('autocon') !== '1')
  const CheckCupsInfo = () => (
    <Box display="flex" justifyContent="center" mt={3} mb={4}>
      <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} alignItems="center" severity="info">
        <Typography variant="basicBig">{isNewSupply ? t('checkCupsInfoNewSupply') : t('checkCupsInfo')}</Typography>
      </Alert>
    </Box>
  )

  const ContinueButton = ({ handleClick }) => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={4}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        disabled={isLeadFetching || isDisallowedNewAddressProductChange || CheckChangeOwner()}
      >
        {t('common.continue')}
      </Button>
    </Box>
  )

  const CancelButton = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={4}>
      <Button variant="outlined" color="secondary" onClick={onCancelSupplyPoint}>
        {t('common.cancel')}
      </Button>
    </Box>
  )

  const AddSupplyPointButton = () => (
    <Box mt={4} mb={5} ml={3}>
      <Link component="button" variant="link" onClick={() => onAddSupplyPoint(supplyPoint.length)}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AddCircleIcon fontSize="small" className={classes.addIcon} />
          <Typography variant="basic" color="primary">
            {t('addNewSupplyPoint')}
          </Typography>
        </Box>
      </Link>
    </Box>
  )

  const CheckChangeOwner = () => {
    //check NNSS
    if (isNewSupply) {
      return false
    }
    if (isMultiPoint) {
      return false
    }
    // check fidelization
    if (supplyModeService === ELECTRICITY && fideElectricity) {
      return false
    } else if (supplyModeService === GAS && fideGas) {
      return false
    } else if (supplyModeService === BOTH && fideElectricity && fideGas) {
      return false
    }

    if (VCN.electricity === null) {
      return (
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-electricity'] === undefined
      )
    }
    if (VCN.gas === null) {
      return (
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === null ||
        changeSupplyOwnerShip['changeSupplyOwnership-gas'] === undefined
      )
    }
    return false
  }

  const InvalidTariff = () => (
    <Box mt={3} mb={4}>
      <Alert severity="warning">
        <Typography variant="basicBig">
          {typeof isInvalidTariff === 'boolean' ? t('invalidTariff') : t(`invalidTariffEnergyType.${isInvalidTariff}`)}
        </Typography>
      </Alert>
    </Box>
  )
  return (
    <Box component="section" className={!isSupplyStepCompleted ? classes.section : ''}>
      <Box mb={isSupplyStepCompleted && supplyModeService !== 'electricity' ? 2 : 0}>
        <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} alignItems="center" severity="info">
          <Typography variant="basic">{t('supplyPointIdentificationDescription')}</Typography>
        </Alert>
        {supplyModeService === 'electricity' && (
          <>
            <Divider className={classes.divider} />
          </>
        )}
      </Box>

      {children}

      {isSupplyStepCompleted && referenceSegment !== PYME && <CheckCupsInfo />}

      {referenceSegment === PYME && supplyPoint?.length > 0 && (
        <>
          <Box mt={3} mb={4}>
            <ChangeSupplyOwnership supplyPoint={supplyPoint} vcn={VCN} supplyModeService={supplyModeService} />
          </Box>

          {supplyPoint.every((supply) => !!supply?.cups) &&
            (supplyModeService.toUpperCase() === supplyPoint?.[0].energyType ||
              (supplyPoint.length > 1 && supplyModeService.toUpperCase() === BOTH)) && (
              <>
                <ContinueButton handleClick={onMultiPointContinue} />
              </>
            )}
          {!isNewSupply &&
            !isTenureSelected &&
            !isDuplicateOrderDraft &&
            supplyPoint.every(({ cups }) => !!cups) &&
            supplyModeService.toUpperCase() === supplyPoint?.[0].energyType && <AddSupplyPointButton />}

          {isRepeatedCups && (
            <Box mt={3} mb={4}>
              <Alert severity="warning">
                <Typography variant="basicBig">{t('repeatedCups')}</Typography>
              </Alert>
            </Box>
          )}

          {isLimitCups && (
            <Box mt={3} mb={4}>
              <Alert severity="warning">
                <Typography variant="basicBig">{t('maxLimitCups')}</Typography>
              </Alert>
            </Box>
          )}

          {!hasMinNumberSupplies && (
            <Box mt={3} mb={4}>
              <Alert severity="warning">
                <Typography variant="basicBig">{t('minMultipointCups')}</Typography>
              </Alert>
            </Box>
          )}
        </>
      )}

      {isInvalidTariff && <InvalidTariff />}

      {isMultiPoint && supplyPoint.some(({ cups }) => !cups) && <CancelButton />}

      {isSupplyStepCompleted && referenceSegment !== PYME && !isContractCapacityVisible && (!isOnlineChannel || supplyAddress) && (
        <>
          {!hideSelfConsumption && supplyModeService !== GAS ? (
            <SelfconsumptionForm
              cups={supplyPoint?.[0]?.cups}
              supplyPoint={supplyPoint}
              selfconsumptionFormRef={selfconsumptionFormRef}
              index={0}
            />
          ) : (
            <>
              {isSupplyStepCompleted && !isNewSupply && (
                <Box mt={3} mb={4}>
                  <ChangeSupplyOwnership
                    isOnlineChannel={isOnlineChannel}
                    supplyPoint={supplyPoint}
                    vcn={VCN}
                    supplyModeService={supplyModeService}
                  />
                </Box>
              )}
            </>
          )}
          <ContinueButton handleClick={handleContinue} />
        </>
      )}

      {referenceSegment !== PYME && isContractCapacityVisible && supplyModeService !== GAS && (
        <>
          {!hideSelfConsumption ? (
            <SelfconsumptionForm
              cups={supplyPoint?.[0]?.cups}
              supplyPoint={supplyPoint}
              selfconsumptionFormRef={selfconsumptionFormRef}
              index={0}
            />
          ) : null}
          <CapacityForm
            isNewSupply={isNewSupply}
            supplyModeService={supplyModeService}
            vcn={VCN}
            supplyPoint={supplyPoint}
            initialValues={initialValues}
            tariff={tariff}
            capacity30ValidationSchema={capacity30ValidationSchema}
            capacity206xValidationSchema={capacity206xValidationSchema}
            onContinue={handleContinue}
            capacities={capacities}
            onKeyDown={onKeyDown}
            isCapacityEditMode={isCapacityEditMode}
            handleEditCapacities={handleEditCapacities}
            isOnlineChannel={isOnlineChannel}
            isDisabled={isLeadFetching || isDisallowedNewAddressProductChange}
          />
        </>
      )}
    </Box>
  )
}

export default SupplyStep
