import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  byCups: redukers.value(
    {
      set: actionTypes.SET_CUPS_SEARCHER_BY_CUPS,
      reset: actionTypes.CLEAR_CUPS_SEARCHER_BY_CUP,
    },
    null
  ),
})
