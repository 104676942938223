import sortByKey from '@zatopek/core/src/utils/sortByKey'

import { get } from '../client'

/**
 * Given a postal code returns all normalized localities
 * @param {String} postalCode
 */
export const getNormalizedCities = async (postalCode) => {
  const response = await get(`normalized/towns?postalCode=${postalCode}`, true, {
    'Content-Type': 'application/ld+json',
  })

  return response || []
}

/**
 *
 * @param {String} postalCode
 */
export const getNormalizedStreets = async (postalCode) => {
  const response = await get(`normalized/streets?postalCode=${postalCode}`)
  return Array.isArray(response) ? sortByKey(response, 'name') : response
}
