import React from 'react'
import { Grid, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS, SERVICES_SEGMENT } from 'utils/constants'
import { useDispatch } from 'react-redux'
import useStyles from './styles'

const Filter = ({ options, setOption, selected, disabled, fullWidth }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const segment = SERVICES_SEGMENT.find((services) => services?.value === e.currentTarget.id)
    dispatch(sendGAEvent(GA_EVENT_NAMES.clickSericeSegment, { service: segment?.label, currentStep: STEPS.topping }))
    setOption(e.currentTarget.id)
  }

  return (
    <Grid container spacing={2}>
      {Object.entries(options).map(([key, label]) => (
        <Grid item key={`filter-${key}`} xs={fullWidth}>
          <Chip
            key={key}
            variant="outlined"
            size="medium"
            label={t(label)}
            onClick={handleChange}
            disabled={disabled || selected === key}
            classes={{
              root: classes.root,
              ...(disabled && selected !== key ? {} : { disabled: classes.disabled }),
              ...(disabled && selected !== key ? { unselectable: classes.unselectable } : {}),
            }}
            id={key}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default Filter
