import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  checkUserButton: {
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
  },
}))

export default useStyles
