import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Box, Button, DialogContent, FormControlLabel, Checkbox } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'
import { BOTH, ELECTRICITY } from 'utils/constants'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'
import { useSnackbar } from 'context/SnackbarContext'

const Calculator = ({
  children,
  supplyPoint,
  supplyModeService,
  isOnlineChannel,
  preSelectedRate,
  tenure,
  isPortability,
  selectedProduct,
  attributes,
  handleClose,
  setShowRequiredAlert,
  showCheck,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setSnackMessage } = useSnackbar()
  const dispatch = useDispatch()
  const [check, setCheck] = React.useState(false)
  const hasCalculatedCost = (service) => {
    if (!Array.isArray(supplyPoint)) {
      return false
    }

    return service.split(',').every((type) => {
      return supplyPoint.find((point) => {
        return type.includes(point.energyType) && point.monthlyCost
      })
    })
  }

  const handleContinue = async () => {
    if (isOnlineChannel) {
      handleClose()
      return
    }
    const flatRate = preSelectedRate?.find((rate) => rate?.flatRate)
    if (flatRate && !hasCalculatedCost(flatRate?.energyType, supplyPoint)) {
      if (flatRate.length === 1 && !hasCalculatedCost(flatRate?.energyType, supplyPoint)) {
        handleClose()
        dispatch(
          showModal({
            modalType: MODAL_TYPES.calculatorModal,
            modalData: {
              energyType: flatRate?.energyType,
              preSelectedRate: [flatRate],
              attributes,
              setShowRequiredAlert,
            },
          })
        )
      } else if (flatRate.length === 2 && !hasCalculatedCost(BOTH, supplyPoint)) {
        handleClose()
        dispatch(
          showModal({
            modalType: MODAL_TYPES.calculatorModal,
            modalData: {
              energyType: BOTH,
              preSelectedRate: [flatRate],
              attributes,
              setShowRequiredAlert,
            },
          })
        )
      }
    } else {
      if (tenure && isPortability) {
        const selectedRate = preSelectedRate || selectedProduct
        if (selectedRate[0]?.energyType === BOTH || selectedRate.length > 1) {
          setShowRequiredAlert(false)
        } else {
          let errorMsg
          if (tenure?.products?.length) {
            tenure?.products?.forEach((elm) => {
              const selected = preSelectedRate[0] || selectedRate[0]
              if (elm.id.length === 36 && elm.type && elm.type !== selected.energyType) {
                errorMsg = elm.type
              }
            })
          }
          if (errorMsg) {
            setSnackMessage(
              `Tienes que contratar un producto que incluya ${
                errorMsg === ELECTRICITY ? 'electricidad' : 'gas'
              } para realizar la portabilidad`,
              'error'
            )
          } else {
            setShowRequiredAlert(false)
          }
        }
      } else {
        setShowRequiredAlert(false)
      }
    }
    handleClose()
  }

  const checkDisable = () => {
    if (showCheck) {
      return !check
    }
    return false
  }

  const ContinueButton = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={4}>
      <Button disabled={checkDisable()} variant="contained" color="secondary" onClick={handleContinue}>
        {t('common.continue')}
      </Button>
    </Box>
  )

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={2}>
          <Typography variant="h3">{t('calculateRate')}</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="basic">{t('calculateRateDescription')}</Typography>
        </Box>

        {children}
        {showCheck && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={(event) => {
                    setCheck(event.target.checked)
                  }}
                  name={t('warning.TpAlert')}
                  color="primary"
                />
              }
              label={t('warning.TpAlert')}
            />
          </Box>
        )}

        {supplyPoint && supplyPoint.every((supply) => !!supply?.cups) && <ContinueButton />}
      </DialogContent>
    </>
  )
}

export default Calculator
