import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import * as backofficeActionTypes from 'services/backoffice/action-types'
import * as api from './api'
import {
  setOrders,
  setOrdersFail,
  setTotalOrders,
  setTotalOrdersFail,
  setProducts,
  setTotalProducts,
  updateOrderDetail,
  updateFilterConfiguration,
  createCSV,
  saveCSV,
  exportFileStart,
  exportFileSuccess,
  exportFileError,
  exportOrderAttachmentsStart,
  exportOrderAttachmentsSuccess,
  exportOrderAttachmentsError,
  cancelOrderStart,
  cancelOrderSuccess,
  cancelOrderError,
  fetchOrders,
  fetchOrdersFail,
  fetchOrderLines,
  verifyOrderStart,
  verifyOrderSuccess,
  verifyOrderError,
  cancelOrderClear,
  verifyOrderClear,
  updateOrderSummary,
  updateProductSummary,
  loadBackofficeStart,
  loadBackofficeSuccess,
  loadBackofficeError,
  fetchTableDataStart,
  fetchTableDataSuccess,
  fetchTableDataError,
  clearSelectedExportTemplateId,
  relaunchOrderError,
  relaunchOrderSuccess,
  setOrderStateFailedWithoutRecoverySuccess,
  setNotifications,
  setTotalNotifications,
  manageNotificationsStart,
  deleteNotificationsStart,
  deleteNotificationsSuccess,
  deleteNotificationsError,
  setTotalUnmanagedNotifications,
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsError,
  fetchOrderSummarySuccess,
  productsSummaryStart,
  fetchOrderSummaryError,
  productsSummarySuccess,
  productsSummaryError,
  fetchOrderSummaryStart,
  manageNotificationsSuccess,
  manageNotificationsError,
  fetchNotifications,
  fetchTotalUnmanagedNotifications,
  setOrderStateFailedWithoutRecoveryError,
  exportOrderAttachmentsId,
} from 'services/backoffice/actions'
import { getSearchFilters, getSelectedTab, getSelectedExportTemplateId } from 'services/backoffice/selectors'
import { resetContract } from 'services/operator/actions'
import { getRenamedFilters, getStates, getHeaders } from './business'
import { saveFile, rawTableDataIntoExcel } from './excel-api'
import { NOTIFICATIONS, ORDERS, ORDER_LINES, ORDER_FAIL } from '../../utils/constants'
import { hideModal } from 'services/modal/actions'
import { setNotificationState } from 'services/notification/actions'
import { getUser } from 'services/operator/selectors'

const TOTAL_ITEMS = 'hydra:totalItems'
const ITEMS = 'hydra:member'

function* fetchProductSummarySaga({ stateGroup, total, avgAge }) {
  yield put(updateProductSummary(stateGroup, { total, avgAge } || { total: 0, avgAge: 0 }))
}

function* fetchProductsSummarySaga() {
  yield put(productsSummaryStart())
  try {
    const productsSummary = yield call(api.getProductSummary)
    yield all(productsSummary?.map((productSummary) => fetchProductSummarySaga(productSummary)))
    yield put(productsSummarySuccess())
  } catch (e) {
    yield put(productsSummaryError())
    console.error(e)
  }
}

function* fetchOrderSummarySaga({ stateGroup, total, avgAge }) {
  yield put(updateOrderSummary(stateGroup, { total, avgAge } || { total: 0, avgAge: 0 }))
}

function* fetchOrdersSummarySaga() {
  yield put(fetchOrderSummaryStart())
  try {
    const ordersSummary = yield call(api.getOrderSummary)
    yield all(ordersSummary?.map((orderSummary) => fetchOrderSummarySaga(orderSummary)))
    yield put(fetchOrderSummarySuccess())
  } catch (e) {
    yield put(fetchOrderSummaryError())
    console.error(e)
  }
}
function* fetchOrdersFailedSaga() {
  yield put(fetchTableDataStart())
  yield put(setOrdersFail([]))
  const searchFilters = yield select(getSearchFilters)
  const renamedFilters = getRenamedFilters(searchFilters)
  const arrangedFilters = { ...renamedFilters, page: searchFilters.page + 1 }
  try {
    const rawResponse = yield call(api.getOrdersFail, {
      state: 'none',
      stateGroup: searchFilters.state,
      ...arrangedFilters,
    })
    yield put(setTotalOrdersFail(rawResponse[TOTAL_ITEMS]))
    yield put(setOrdersFail(rawResponse[ITEMS]))
    yield put(fetchTableDataSuccess())
  } catch (e) {
    console.error(e)
    yield put(setTotalOrdersFail(0))
    yield put(setOrdersFail([]))
    yield put(fetchTableDataError())
  }
}

function* fetchOrdersSaga() {
  yield put(fetchTableDataStart())
  yield put(setOrders([]))
  const searchFilters = yield select(getSearchFilters)
  const renamedFilters = getRenamedFilters(searchFilters)
  const arrangedFilters = { ...renamedFilters, page: searchFilters.page + 1 }
  try {
    const rawResponse = yield call(api.getOrders, {
      state: 'none',
      stateGroup: searchFilters.state,
      ...arrangedFilters,
    })
    yield put(setTotalOrders(rawResponse[TOTAL_ITEMS]))
    yield put(setOrders(rawResponse[ITEMS]))
    yield put(fetchTableDataSuccess())
  } catch (e) {
    console.error(e)
    yield put(setTotalOrders(0))
    yield put(setOrders([]))
    yield put(fetchTableDataError())
  }
}

function* fetchOrderDetailSaga({ payload: { id } }) {
  const { templateId } = yield select(getSearchFilters)
  const detail = yield call(api.getOrderLines, { orderId: id, ...(templateId ? { templateId: templateId } : '') })
  yield put(updateOrderDetail(id, detail))
}

function* fetchOrderDetailFailSaga({ payload: { id } }) {
  const { templateId } = yield select(getSearchFilters)
  const detail = yield call(api.getOrderLinesFailed, { orderId: id, ...(templateId ? { templateId: templateId } : '') })
  yield put(updateOrderDetail(id, detail))
}

function* fetchOrderLinesSaga() {
  const searchFilters = yield select(getSearchFilters)
  const renamedFilters = getRenamedFilters(searchFilters)
  const arrangedFilters = { ...renamedFilters, page: searchFilters.page + 1 }
  yield put(fetchTableDataStart())
  try {
    const rawResponse = yield call(api.getOrderLinesLDJson, {
      state: 'none',
      stateGroup: searchFilters.state,
      ...arrangedFilters,
    })
    yield put(setTotalProducts(rawResponse[TOTAL_ITEMS]))
    yield put(setProducts(rawResponse[ITEMS]))
    yield put(fetchTableDataSuccess())
  } catch (e) {
    console.error(e)
    yield put(setTotalProducts(0))
    yield put(setProducts([]))
    yield put(fetchTableDataError())
  }
}

function* fetchFiltersConfiguration() {
  const user = yield select(getUser)

  yield put(loadBackofficeStart())
  yield put(resetContract())

  try {
    const orders = yield call(api.getOrderFiltersConfig)
    const products = yield call(api.getProductFiltersConfig)
    if (user?.channelOffers) {
      const channelsArray = []
      const channelsNameArray = []
      orders.setup.channels = user.channelOffers
        .map(({ channel }) => {
          if (channelsArray.includes(channel.id) || channelsNameArray.includes(channel.name)) {
            // eslint-disable-next-line array-callback-return
            return
          }
          channelsArray.push(channel.id)
          channelsNameArray.push(channel.name)
          return {
            id: channel.id,
            name: channel.name,
          }
        })
        .filter((elm) => {
          return elm
        })
    }
    const filters = {
      ...orders.setup,
      ...products.setup,
    }
    yield all(Object.keys(filters).map((key) => put(updateFilterConfiguration(key, filters[key]))))
    yield put(loadBackofficeSuccess())
  } catch (e) {
    console.error(e)
    yield put(loadBackofficeError())
  }
}

function* exportCSVSaga({ payload: selectedItems }) {
  const type = yield select(getSelectedTab)
  const searchFilters = yield select(getSearchFilters)
  const templateId = yield select(getSelectedExportTemplateId)
  const renamedFilters = getRenamedFilters(searchFilters)
  const states = getStates(type, searchFilters.state)
  const allFilters = { states, ...renamedFilters, templateId }
  const titles = getHeaders(type)
  try {
    let file
    yield put(exportFileStart())
    if (type === ORDERS) {
      const { 'hydra:member': data } = yield call(api.getOrdersIds, { orderIds: [...selectedItems], ...allFilters })
      yield put(exportFileSuccess())
      file = rawTableDataIntoExcel(data, titles)
    }
    if (type === ORDER_LINES) {
      const { 'hydra:member': data } = yield call(api.getOrderLineIds, { products: [...selectedItems], ...allFilters })
      yield put(exportFileSuccess())
      file = rawTableDataIntoExcel(data, titles)
    }
    yield put(createCSV(file))
    saveFile(file, type)
    yield put(saveCSV(file))
  } catch (error) {
    yield put(exportFileError())
  } finally {
    yield put(clearSelectedExportTemplateId())
  }
}

function* exportCSVFailedSaga({ payload: selectedItems }) {
  const searchFilters = yield select(getSearchFilters)
  const renamedFilters = getRenamedFilters(searchFilters)
  const states = getStates('orders', searchFilters.state)
  const allFilters = { states, ...renamedFilters }
  const titles = getHeaders('orders')
  const excelRows = []
  let pageItem = 1
  let morePages = true
  try {
    let file
    yield put(exportFileStart())
    while (morePages) {
      const { orders, hasMorePages } = yield call(api.getOrdersFailedIds, {
        orderIds: [...selectedItems],
        pageItem: pageItem,
        ...allFilters,
      })
      morePages = hasMorePages
      pageItem++
      excelRows.push(...orders)
    }

    yield put(exportFileSuccess())
    file = rawTableDataIntoExcel(excelRows, titles)
    yield put(createCSV(file))
    saveFile(file, 'orders')
    yield put(saveCSV(file))
  } catch (error) {
    yield put(exportFileError())
  } finally {
    yield put(clearSelectedExportTemplateId())
  }
}

function* exportOrderAttachmentsSaga({ payload: orderId }) {
  try {
    yield put(exportOrderAttachmentsId(orderId))
    yield put(exportOrderAttachmentsStart())
    yield call(api.getOrderAttachments, orderId)
    yield put(exportOrderAttachmentsSuccess())
  } catch (error) {
    console.error(error)
    yield put(exportOrderAttachmentsError())
  }
}

function* cancelOrderSaga({ payload: { leadId, cancelReason, cancelDetails } }) {
  try {
    yield put(cancelOrderClear())
    yield put(cancelOrderStart())
    yield call(api.cancelOrder, { leadId, cancelReason, cancelDetails })
    yield put(fetchOrders())
    yield put(cancelOrderSuccess())
    yield put(hideModal())
  } catch (error) {
    console.error(error)
    yield put(cancelOrderError())
  }
}

function* relaunchOrderSaga({ payload: orderId }) {
  try {
    yield call(api.relaunchOrder, orderId)
    yield put(fetchOrders())
    yield put(relaunchOrderSuccess())
  } catch (error) {
    console.error(error)
    yield put(relaunchOrderError())
    setNotificationState({
      message: 'notificationAlerts.relaunchOrder',
      type: 'error',
      isVisible: true,
    })
  }
}

function* setOrderStateFailedWithoutRecoverySaga({ payload: orderId }) {
  try {
    yield call(api.setOrderStateFailedWithoutRecovery, orderId)
    yield put(fetchOrders())
    yield put(setOrderStateFailedWithoutRecoverySuccess())
  } catch (error) {
    console.error(error)
    yield put(setOrderStateFailedWithoutRecoveryError())
    setNotificationState({
      message: 'notificationAlerts.setOrderStateFailedWithoutRecovery',
      type: 'error',
      isVisible: true,
    })
  }
}

function* verifyOrderSaga({ payload: orderId }) {
  try {
    yield put(verifyOrderClear())
    yield put(verifyOrderStart())
    yield call(api.verifyOrder, orderId)
    yield put(fetchOrders())
    yield put(verifyOrderSuccess())
  } catch (error) {
    console.error(error)
    yield put(verifyOrderError())
  }
}

function* handleFilterChangesSaga() {
  const type = yield select(getSelectedTab)
  if (type === ORDERS) {
    yield put(fetchOrders())
  }
  if (type === ORDER_LINES) {
    yield put(fetchOrderLines())
  }
  if (type === NOTIFICATIONS) {
    yield put(fetchNotifications())
  }
  if (type === ORDER_FAIL) {
    yield put(fetchOrdersFail())
  }
}

function* fetchNotificationsSaga() {
  yield put(fetchNotificationsStart())
  yield put(setNotifications([]))
  const searchFilters = yield select(getSearchFilters)
  const renamedFilters = getRenamedFilters(searchFilters)
  const arrangedFilters = { ...renamedFilters, page: searchFilters.page + 1 || 1, itemsPerPage: searchFilters.rowsPerPage || 10 }
  try {
    const rawResponse = yield call(api.getNotifications, {
      state: 'none',
      stateGroup: searchFilters.state,
      ...arrangedFilters,
    })
    yield put(setTotalNotifications(rawResponse[TOTAL_ITEMS]))
    yield put(setNotifications(rawResponse[ITEMS]))
    yield put(fetchNotificationsSuccess())
  } catch (e) {
    console.error(e)
    yield put(setTotalNotifications(0))
    yield put(setNotifications([]))
    yield put(fetchNotificationsError())
  }
}

function* deleteNotificationsSaga({ payload: notificationIds }) {
  try {
    yield put(deleteNotificationsStart())
    yield call(api.deleteNotifications, { ids: notificationIds })
    // Add modal (?)
    yield put(fetchNotifications())
    yield put(fetchTotalUnmanagedNotifications())
    yield put(deleteNotificationsSuccess())
  } catch (e) {
    console.error(e)
    yield put(deleteNotificationsError())
    yield put(
      setNotificationState({
        message: 'notificationAlerts.deleteNotifications',
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* manageNotificationSagas({ payload: { notificationId, managed } }) {
  try {
    yield put(manageNotificationsStart())
    yield call(api.manageNotification, notificationId, { managed })
    yield put(fetchTotalUnmanagedNotifications())
    yield put(manageNotificationsSuccess())
  } catch (e) {
    console.error(e)
    yield put(manageNotificationsError())
    yield put(
      setNotificationState({
        message: 'notificationAlerts.manageNotifications',
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* manageNotificationsSagas({ payload: { notificationIds } }) {
  try {
    yield put(manageNotificationsStart())
    yield call(api.manageNotifications, { ids: notificationIds })
    yield put(fetchNotifications())
    yield put(fetchTotalUnmanagedNotifications())
    yield put(manageNotificationsSuccess())
  } catch (e) {
    console.error(e)
    yield put(manageNotificationsError())
    yield put(
      setNotificationState({
        message: 'notificationAlerts.manageNotifications',
        type: 'error',
        isVisible: true,
      })
    )
  }
}

function* fetchTotalUnmanagedNotificationsSagas() {
  try {
    const unmanagedNotifications = yield call(api.getTotalNotificationsUnmanaged)
    yield put(setTotalUnmanagedNotifications(unmanagedNotifications.new_alerts))
  } catch (e) {
    console.error(e)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([backofficeActionTypes.FETCH_ORDERS], fetchOrdersSaga),
    takeLatest([backofficeActionTypes.FETCH_ORDERS_FAIL], fetchOrdersFailedSaga),
    takeLatest([backofficeActionTypes.FETCH_ORDER_LINES], fetchOrderLinesSaga),
    takeLatest([backofficeActionTypes.FETCH_ORDER_DETAIL], fetchOrderDetailSaga),
    takeLatest([backofficeActionTypes.FETCH_ORDER_DETAIL_FAIL], fetchOrderDetailFailSaga),
    takeLatest([backofficeActionTypes.FETCH_PRODUCT_SUMMARY], fetchProductsSummarySaga),
    takeLatest([backofficeActionTypes.FETCH_ORDER_SUMMARY], fetchOrdersSummarySaga),
    takeLatest([backofficeActionTypes.FETCH_FILTERS_CONFIGURATION], fetchFiltersConfiguration),
    takeLatest([backofficeActionTypes.EXPORT_CSV], exportCSVSaga),
    takeLatest([backofficeActionTypes.EXPORT_CSV_FAILED], exportCSVFailedSaga),
    takeLatest([backofficeActionTypes.EXPORT_ORDER_ATTACHMENTS], exportOrderAttachmentsSaga),
    takeLatest([backofficeActionTypes.CANCEL_ORDER], cancelOrderSaga),
    takeLatest([backofficeActionTypes.VERIFY_ORDER], verifyOrderSaga),
    takeLatest([backofficeActionTypes.SET_SEARCH_FILTERS], handleFilterChangesSaga),
    takeLatest([backofficeActionTypes.FETCH_TOTAL_UNMANAGED_NOTIFICATIONS], fetchTotalUnmanagedNotificationsSagas),
    takeLatest([backofficeActionTypes.FETCH_NOTIFICATIONS], fetchNotificationsSaga),
    takeLatest([backofficeActionTypes.DELETE_NOTIFICATIONS], deleteNotificationsSaga),
    takeLatest([backofficeActionTypes.MANAGE_NOTIFICATION], manageNotificationSagas),
    takeLatest([backofficeActionTypes.MANAGE_NOTIFICATIONS], manageNotificationsSagas),
    takeLatest([backofficeActionTypes.RELAUNCH_ORDER_START], relaunchOrderSaga),
    takeLatest([backofficeActionTypes.SET_ORDER_STATE_FAILED_WITHOUT_RECOVERY_START], setOrderStateFailedWithoutRecoverySaga),
  ])
}
