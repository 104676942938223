import React from 'react'
import { useTranslation } from 'react-i18next'

import camelize from '@zatopek/core/src/utils/camelize'

import { Checkbox, FormControlLabel } from '@material-ui/core'

const CheckField = ({ name, value, defaultValue, onChange, disabled }) => {
  const { t } = useTranslation()

  const handleChange = (e) => onChange(e.target.checked)

  return (
    <FormControlLabel
      control={
        <Checkbox
          defaultChecked={defaultValue}
          checked={value}
          disabled={disabled}
          onChange={handleChange}
          name={name}
          color="primary"
        />
      }
      label={t(camelize(name))}
    />
  )
}

export default CheckField
