import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import { DialogContent } from '@material-ui/core'

import { finishLocution } from 'services/order/actions'
import { getIsVTChannel } from 'services/operator/business'
import { clearLead } from 'services/lead/actions'
import { getIsLeadError, getIsLeadFetching } from 'services/lead/selectors'
import { clearDraftLead } from 'services/draftCopy/actions'

import LocutionForm from 'ui/forms/locution'
import LocutionInfo from 'ui/components/locutionInfo'

import { RECOMMENDATION_CODE_LENGTH, EXTERNAL_WRITE_ID_LENGTH } from 'utils/constants'

import useStyles from './styles'

const ResumeLocution = ({ handleClose, data: { info } }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isVtChannelType = getIsVTChannel(info?.channelName)
  const CRMId = info?.crmId
  const isLeadFetching = useSelector(getIsLeadFetching)
  const isLeadError = useSelector(getIsLeadError)

  const validateForm = (values) => {
    const errors = {}
    const errorCount = []
    const requiredFields = isVtChannelType ? ['externalWriteId'] : []

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = t('form.errors.required')
        errorCount.push(errors[field])
      }
    })

    if (values.recommendationCode && values.recommendationCode.length !== RECOMMENDATION_CODE_LENGTH) {
      errors.recommendationCode = t('form.errors.invalidLength', { length: RECOMMENDATION_CODE_LENGTH })
    }

    if (values.externalWriteId && values.externalWriteId.length > EXTERNAL_WRITE_ID_LENGTH) {
      errors.externalWriteId = t('form.errors.lessEqualLength', { length: RECOMMENDATION_CODE_LENGTH })
    }

    return errors
  }

  const callbackResumeLocution = () => {
    dispatch(clearLead())
    dispatch(clearDraftLead())
    handleClose()
  }

  const handleFormSubmit = (formData) => {
    dispatch(
      finishLocution({
        data: { ...formData },
        resumeLocution: true,
        callbackResumeLocution,
      })
    )
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <LocutionInfo CRMId={CRMId} resumeLocution={true} />
        <Formik
          initialValues={{
            recommendationCode: '',
            externalWriteId: '',
          }}
          validate={validateForm}
          onSubmit={handleFormSubmit}
        >
          {({ handleChange, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <LocutionForm
                onChange={handleChange}
                touched={touched}
                values={values}
                errors={errors}
                onCancel={handleClose}
                isLeadFetching={isLeadFetching}
                isLeadError={isLeadError}
                resumeLocution={true}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  )
}

export default ResumeLocution
