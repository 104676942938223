import { request } from 'redukers'

export const getProducts = (state) => state.products
export const getRates = (state) => state.products.all
export const getSelectedRate = (state) => state.products.selected
export const getSelectedService = (state) => state.products.selected[0]?.energyType
export const getIsOnlyToppings = (state) => state.products.isOnlyToppings
export const getSelectedVariants = (state) => state.products.selectedVariants
export const getProductsValidation = (state) => state.products.areProductsValid
export const getProductAttributes = (state) => state.products.selected[0]?.attributes
export const getSelectedProductId = (state) => state.products.selected[0]?.id

export const getSelectedProductVariants = (state) => state.products.selected[0]?.variants
export const getSelectedDualSubproducts = (state) => state.products.selected[0]?.subProducts

export const getFetchedVariants = (state) => state.products.fetchedVariants
export const getIsSelectionNotAllowed = (state) => !!state.products.selection?.disallowed
export const getSelectionDisallowedMessage = (state) => state.products.selection?.message

export const getIsSimulatorFileFetching = (state) => {
  const simulatorFileRequest = state.products.requestSimulatorFile
  return request.getIsRequesting(simulatorFileRequest)
}
// TODO ADD ERROR
export const getIsSimulatorFileError = (state) => {
  const simulatorFileRequest = state.products.requestSimulatorFile
  return request.getIsError(simulatorFileRequest)
}
