import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2, 3, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
  dialogActions: {
    flexDirection: 'row',
    padding: theme.spacing(0, 3, 3),
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 7, 5),
    },
  },
}))

export default useStyles
