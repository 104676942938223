import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  loadControlRequest: redukers.request({
    start: actionTypes.LOAD_CONTROL_START,
    success: actionTypes.LOAD_CONTROL_SUCCESS,
    error: actionTypes.LOAD_CONTROL_ERROR,
    clear: actionTypes.LOAD_CONTROL_CLEAR,
  }),
  fidelization: redukers.value(
    {
      set: actionTypes.SET_FIDELIZATION,
      reset: actionTypes.CLEAR_FIDELIZATION,
    },
    {}
  ),
  newAddressProductChange: redukers.value(
    {
      set: actionTypes.SET_NEW_ADDRESS_PRODUCT_CHANGE,
      reset: actionTypes.CLEAR_NEW_ADDRESS_PRODUCT_CHANGE,
    },
    null
  ),
  newAddressServiceChange: redukers.value(
    {
      set: actionTypes.SET_NEW_ADDRESS_SERVICE_CHANGE,
      reset: actionTypes.CLEAR_NEW_ADDRESS_SERVICE_CHANGE,
    },
    null
  ),
})
