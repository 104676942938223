import { SERVICE } from './constants'

export const FETCH_PRODUCTS = `${SERVICE}/FETCH_PRODUCTS`
export const SET_PRODUCTS = `${SERVICE}/SET_PRODUCTS`
export const CLEAR_PRODUCTS = `${SERVICE}/CLEAR_PRODUCTS`
export const SET_SELECTED_PRODUCT = `${SERVICE}/SET_SELECTED_PRODUCT`
export const CLEAR_SELECTED_PRODUCT = `${SERVICE}/CLEAR_SELECTED_PRODUCT`
export const SET_PRODUCT_VARIANTS = `${SERVICE}/SET_PRODUCT_VARIANTS`
export const CLEAR_PRODUCT_VARIANTS = `${SERVICE}/CLEAR_PRODUCT_VARIANTS`
export const SET_PRODUCTS_VALIDATION = `${SERVICE}/SET_PRODUCTS_VALIDATION`
export const CLEAR_PRODUCTS_VALIDATION = `${SERVICE}/CLEAR_PRODUCTS_VALIDATION`
export const FETCH_REQUEST_SIMULATOR_FILE = `${SERVICE}/FETCH_REQUEST_SIMULATOR_FILE`
export const START_REQUEST_SIMULATOR_FILE = `${SERVICE}/START_REQUEST_SIMULATOR_FILE`
export const SUCCESS_REQUEST_SIMULATOR_FILE = `${SERVICE}/SUCCESS_REQUEST_SIMULATOR_FILE`
export const ERROR_REQUEST_SIMULATOR_FILE = `${SERVICE}/ERROR_REQUEST_SIMULATOR_FILE`
export const CLEAR_REQUEST_SIMULATOR_FILE = `${SERVICE}/CLEAR_REQUEST_SIMULATOR_FILE`
export const SET_SELECTED_PRODUCT_WITH_ATTRIBUTE = `${SERVICE}/SET_SELECTED_PRODUCT_WITH_ATTRIBUTE`
export const SET_IS_ONLY_TOPPINGS = `${SERVICE}/SET_IS_ONLY_TOPPINGS`
export const CLEAR_IS_ONLY_TOPPINGS = `${SERVICE}/CLEAR_IS_ONLY_TOPPINGS`
export const RESET_CALCULATED_COST = `${SERVICE}/RESET_CALCULATED_COST`
export const SET_FETCHED_VARIANTS = `${SERVICE}/SET_FETCHED_VARIANTS`
export const GET_FETCHED_VARIANTS = `${SERVICE}/GET_FETCHED_VARIANTS`
export const CLEAR_FETCHED_VARIANTS = `${SERVICE}/CLEAR_FETCHED_VARIANTS`
export const SET_PRODUCT_SELECTION = `${SERVICE}/SET_PRODUCT_SELECTION`
export const CLEAR_PRODUCT_SELECTION = `${SERVICE}/CLEAR_PRODUCT_SELECTION`
