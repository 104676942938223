import { STEPS } from 'utils/constants'
import { getStepIndex } from 'services/stepper/business'

export const getActiveStep = (state) => state.stepper.activeStep
export const getActiveStepDuplicate = (state) => state.stepper.activeStepDuplicate
export const getSteps = (state) => state.stepper.steps

export const getSupplyStepMode = (state) => {
  const stepIndex = Object.keys(STEPS).findIndex((step) => step === STEPS.supply)

  return state.stepper.steps[stepIndex]?.mode
}

export const getAllStepsValid = (state) => state.stepper.steps.every((step) => step.isCompleted)
export const getHasCompleteSteps = (state) => state.stepper.steps.some((step) => step.isCompleted)
export const getIsSupplyStepCompleted = (state) => state.stepper.steps.find((step) => step.id === STEPS.supply).isCompleted
export const getIsOwnerStepCompleted = (state) => state.stepper.steps.find((step) => step.id === STEPS.owner).isCompleted
export const getIsToppingStepCompleted = (state) => state.stepper.steps.find((step) => step.id === STEPS.topping).isCompleted
export const getIsRateStepCompleted = (state) => state.stepper.steps.find((step) => step.id === STEPS.rate).isCompleted
export const getIsBillingStepCompleted = (state) => state.stepper.steps.find((step) => step.id === STEPS.billing).isCompleted
export const getStepStateByProperty = (state) => (stepId, property) =>
  state.stepper.steps.find((step) => step.id === stepId)[property]

export const getIsOnlyOwnerStepCompleted = (state) =>
  getIsOwnerStepCompleted(state) &&
  state.stepper.steps.every((step) => step.id === STEPS.owner || !step.isCompleted || step.isAutocompleted)

export const getStepsProgressPercentage = (state) => {
  const steps = state.stepper.steps
  const totalSteps = steps.length
  const completedSteps = steps.filter((step) => step.isCompleted).length
  return steps ? (completedSteps / totalSteps) * 100 : 0
}

export const getIsRateStepActive = (state) => {
  const rateStepIndex = getStepIndex(STEPS.rate)
  const activeStep = getActiveStep(state)

  return activeStep === rateStepIndex
}

export const getIsSupplyStepActive = (state) => {
  const supplyStepIndex = getStepIndex(STEPS.supply)
  const activeStep = getActiveStep(state)

  return activeStep === supplyStepIndex
}

export const getStepByIndexFunc = (state) => (stepIndex) => state.stepper.steps[stepIndex]

export const getIsStepActiveFunc = (state) => (stepIndex) => stepIndex === state.stepper.activeStep

export const getStepPropertyFunc = (state) => (stepIndex, property) =>
  state.stepper.steps?.length < state.stepper.steps ? state.stepper.steps[stepIndex][property] : null

export const getIsStepCompletedFunc = (state) => (stepIndex) => getStepPropertyFunc(state)(stepIndex, 'isCompleted')
export const getIsStepDisabledFunc = (state) => (stepIndex) => getStepPropertyFunc(state)(stepIndex, 'isDisabled')
export const getIsStepAutoompletedFunc = (state) => (stepIndex) => getStepPropertyFunc(state)(stepIndex, 'isAutocompleted')
