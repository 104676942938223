import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  list: {
    margin: theme.spacing(0, 0, 3),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
}))

export default useStyles
