import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  orderTechnicalInfosIds: redukers.value(
    {
      set: actionTypes.SET_ORDER_TECHNICAL_INFOS_IDS,
      reset: actionTypes.CLEAR_ORDER_TECHNICAL_INFOS_IDS,
    },
    []
  ),
  orderPricingCalculationIds: redukers.value(
    {
      set: actionTypes.SET_ORDER_PRICING_CALCULATION_IDS,
      reset: actionTypes.CLEAR_ORDER_PRICING_CALCULATION_IDS,
    },
    {}
  ),
  CRMId: redukers.value(
    {
      set: actionTypes.SET_CRM_ID,
      reset: actionTypes.RESET_CRM_ID,
    },
    null
  ),
  orders: redukers.value({
    set: actionTypes.SET_ORDERS,
    reset: actionTypes.CLEAR_ORDERS,
  }),
  id: redukers.value({
    set: actionTypes.SET_ORDER_ID,
    reset: actionTypes.CLEAR_ORDER_ID,
  }),
  guidelineDate: redukers.value(
    {
      set: actionTypes.SET_GUIDELINE_DATE,
      reset: actionTypes.CLEAR_GUIDELINE_DATE,
    },
    null
  ),
  signatureDate: redukers.value(
    {
      set: actionTypes.SET_SIGNATURE_DATE,
      reset: actionTypes.CLEAR_SIGNATURE_DATE,
    },
    null
  ),
  locutionData: redukers.value(
    {
      set: actionTypes.SET_LOCUTION_DATA,
      reset: actionTypes.RESET_LOCUTION_DATA,
    },
    {}
  ),
  isGdprSent: redukers.value(
    {
      set: actionTypes.SET_GDPR_SENT,
      reset: actionTypes.CLEAR_GDPR_SENT,
    },
    false
  ),
  isScoringSent: redukers.value(
    {
      set: actionTypes.SET_SCORING_SENT,
      reset: actionTypes.CLEAR_SCORING_SENT,
    },
    false
  ),
  signRequest: redukers.request({
    start: actionTypes.START_SIGN,
    success: actionTypes.SUCCESS_SIGN,
    error: actionTypes.ERROR_SIGN,
    clear: actionTypes.CLEAR_SIGN,
  }),
  forwardSmsRequest: redukers.request({
    start: actionTypes.START_FORWARD_SMS,
    success: actionTypes.SUCCESS_FORWARD_SMS,
    error: actionTypes.ERROR_FORWARD_SMS,
    clear: actionTypes.CLEAR_FORWARD_SMS,
  }),
  attachDocRequest: redukers.request({
    start: actionTypes.START_ATTACH_DOC,
    success: actionTypes.SUCCESS_ATTACH_DOC,
    error: actionTypes.ERROR_ATTACH_DOC,
    clear: actionTypes.CLEAR_ATTACH_DOC,
  }),
})
