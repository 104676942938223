import React, { useState } from 'react'

import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from 'ui/components/typography'

const AccordionBasic = ({ title, expanded, children }) => {
  const [expandedAccordion, setExpandedAccordion] = useState(expanded)

  const handleExpand = () => {
    setExpandedAccordion(!expandedAccordion)
  }

  return (
    <Accordion onChange={handleExpand} elevation={3} expanded={expandedAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>{children}</Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionBasic
