import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Grid, Box, Paper, Checkbox } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import formatNumber from '@zatopek/core/src/utils/formatNumber'

import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

import Typography from 'ui/components/typography'
import Link from 'ui/components/link'

import useStyles from './styles'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES, STEPS } from 'utils/constants'

const Topping = ({ product, selected, onChangeProduct, isRecommended, isOnlineChannel, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const hasProduct = Object.keys(product).length
  const [disabledProduct, setDisabledProduct] = React.useState(false)

  React.useEffect(() => {
    setDisabledProduct(disabled)
  }, [disabled])

  // Botón sin mantenimiento
  if (hasProduct) {
    const { id, name, servicePrices, image, title, subtitle } = product.service
    /* BACK TODO: service will return a single price when param date is added */
    const { priceAmount, priceUnit } = servicePrices[0] || {}
    const isSelected = !!(selected && selected[id])
    // const service = CRMCode === 'SVE' ? GA_SERVICES['ELECTRICITY'] : GA_SERVICES['GAS']

    const openDetailModal = (e) => {
      e.stopPropagation()
      dispatch(showModal({ modalType: MODAL_TYPES.detail, modalData: name }))
      // Analytics for clicking details
      dispatch(sendGAEvent(GA_EVENT_NAMES.clickServiceConditions, { service: name, currentStep: STEPS.topping }))
      dispatch(sendGAEvent(GA_EVENT_NAMES.openServiceConditions, { service: name, currentStep: STEPS.topping }))
    }
    const changeProduct = (id) => {
      if (!disabledProduct) {
        onChangeProduct(id)
      }
    }

    return (
      <Paper
        elevation={3}
        className={disabledProduct ? classes.rootDisabled : clsx(classes.root, isSelected && 'selected')}
        onClick={() => changeProduct(id)}
      >
        {isRecommended && (
          <Grid container>
            <Box className={classes.recommendBox}>
              <Typography variant="h5">
                {t('service')} {t('recommended').toLowerCase()}
              </Typography>
            </Box>
          </Grid>
        )}
        <Box mt={isRecommended ? 1 : 0} className={classes.content}>
          <Box className={classes.logos}>
            <img className={classes.logo} src={image} alt={name} />
          </Box>
          <Box mt={isRecommended ? 4 : 0} className={clsx(classes.checkboxContainer)} xs={2} md={1}>
            <Checkbox
              classes={{ root: 'big' }}
              checkedIcon={<CheckBoxIcon color="primary" />}
              checked={!!isSelected}
              value={name}
              name="checkbox-rate"
            />
          </Box>
          <Typography variant="h4" color="primary" className={classes.longTitle} gutterBottom>
            {t(title)}
          </Typography>
          <Typography classes={{ root: classes.subtitle }} variant="h6" color="secondary">
            {t(subtitle)}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* <Typography variant="helper" color="primary"> */}
            {/* TODO: field editable from Loco */}
            {/* Detalle Lorem ipsum dolor sit amet */}
            {/* </Typography> */}

            {/* TODO: this should be deleted? */}
            {/* <Typography variant="helperThrough" color="primary"> */}
            {/* TODO: field editable from Loco */}
            {/* {`${oldPrice}€/mes`} */}
            {/* 8,23€/mes
            </Typography> */}
          </Box>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <Typography onClick={openDetailModal} classes={{ root: classes.viewDetails }} variant="link" color="primary">
              <AddCircle color="primary" />
              <Link>{t('viewDetails')}</Link>
            </Typography>
            <Box display="flex" alignItems="baseline">
              <Typography variant="h2" color="primary">
                {formatNumber(priceAmount?.toLocaleString('es-ES', { minimumFractionDigits: 2 }) || '')}
              </Typography>
              <Typography variant="label" color="primary" gutterBottom>
                {priceAmount && priceUnit ? priceUnit : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  }
}

export default Topping
