export const cpProvinces = {
  '01': 'ALAVA',
  '02': 'ALBACETE',
  '03': 'ALICANTE',
  '04': 'ALMERIA',
  '05': 'AVILA',
  '06': 'BADAJOZ',
  '07': 'BALEARES',
  '08': 'BARCELONA',
  '09': 'BURGOS',
  10: 'CACERES',
  11: 'CADIZ',
  12: 'CASTELLON',
  13: 'CIUDAD REAL',
  14: 'CORDOBA',
  15: 'A CORUÑA',
  16: 'CUENCA',
  17: 'GIRONA',
  18: 'GRANADA',
  19: 'GUADALAJARA',
  20: 'GUIPUZCOA',
  21: 'HUELVA',
  22: 'HUESCA',
  23: 'JAEN',
  24: 'LEON',
  25: 'LLEIDA',
  26: 'LA RIOJA',
  27: 'LUGO',
  28: 'MADRID',
  29: 'MALAGA',
  30: 'MURCIA',
  31: 'NAVARRA',
  32: 'OURENSE',
  33: 'ASTURIES',
  34: 'PALENCIA',
  35: 'LAS PALMAS',
  36: 'PONTEVEDRA',
  37: 'SALAMANCA',
  38: 'SANTA CRUZ DE TENERIFE',
  39: 'CANTABRIA',
  40: 'SEGOVIA',
  41: 'SEVILLA',
  42: 'SORIA',
  43: 'TARRAGONA',
  44: 'TERUEL',
  45: 'TOLEDO',
  46: 'VALENCIA',
  47: 'VALLADOLID',
  48: 'VIZCAYA',
  49: 'ZAMORA',
  50: 'ZARAGOZA',
  51: 'CEUTA',
  52: 'MELILLA',
}

export const streetTypes = {
  1: 'Acceso',
  2: 'Acera/Empresa',
  3: 'Alameda',
  4: 'Autopista',
  5: 'Autovía',
  6: 'Avenida',
  7: 'C. Comercial',
  8: 'Calle',
  9: 'Callejón',
  10: 'Camino',
  11: 'Cañada',
  12: 'Carrer',
  13: 'Carretera',
  14: 'Cuesta',
  15: 'Glorieta',
  16: 'Pasadizo',
  17: 'Pasaje',
  18: 'Paseo',
  19: 'Plaza',
  20: 'Rambla',
  21: 'Ronda',
  22: 'Sendero',
  23: 'Travesía',
  24: 'Urbanización',
  25: 'Vía',
}
