import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 4, 4),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  examine: {
    marginTop: '28px',
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    alignContent: 'center',
    textAlign: 'center',
  },
  description: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '18px',
    alignContent: 'center',
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  cupsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexDirection: 'row',
    marginTop: '20px',
    marginLeft: '20px',
  },
  cupsContainerError: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '10px',
    flexDirection: 'row',
    marginTop: '20px',
    marginLeft: '20px',
  },
  cupsInputDisabled: {
    width: '100%',
    display: 'flex',
    border: 'none',
    fontSize: '17px',
    lineHeight: '16px',
    margin: 'auto',
    padding: '10px',
    backgroundColor: 'transparent!important',
  },
  cupsText: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '16px',
  },
  changeModeText: {
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '16px',
    alignContent: 'flex-start',
    textAlign: 'flex-start',
    marginTop: '20px',
    borderBottom: '2px solid #E57200',
    display: 'inline-block',
    cursor: 'pointer',
    marginLeft: '20px',
  },
  changeModeTextDisabled: {
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '16px',
    alignContent: 'flex-start',
    textAlign: 'flex-start',
    marginTop: '20px',
    borderBottom: 'none',
    display: 'inline-block',
    cursor: 'default',
    marginLeft: '20px',
    opacity: '0.7',
  },
  loadingImage: {
    display: 'flex',
    margin: 'auto',
    width: '250px',
    height: '250px',
  },
}))

export default useStyles
