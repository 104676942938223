import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginLeft: theme.spacing(1.5),
  },
  block: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  acceptancesBox: {
    cursor: 'pointer',
  },
  acceptancesTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  acceptancesText: {
    fontWeight: 300,
    fontSize: 12,
  },
  acceptancesSeeMore: {
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.neutral.main,
  },
}))

export default useStyles
