import React from 'react'
import clsx from 'clsx'
import MuiTypography from '@material-ui/core/Typography'

import useStyles from './styles'

const Typography = ({ variant, children, className = '', ...props }) => {
  const classes = useStyles()

  return (
    <MuiTypography {...props} className={clsx(classes[variant], className)}>
      {children}
    </MuiTypography>
  )
}

export default Typography
