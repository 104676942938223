import { get, put, post, del } from '../client'

export const getTypeById = (id) => get(`self_consumption_types/${id}`, true)

export const getAllTypes = () => get(`self_consumption_types`, true)

export const putType = (id, params) => put(`self_consumption_types/${id}`, params)

export const postType = (params) => post(`self_consumption_types`, params)

export const deleteType = (id) => del(`self_consumption_types/${id}`)
