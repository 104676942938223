import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from 'ui/components/typography'
import Link from 'ui/components/link'

import useStyles from './styles'
import { sendGAEvent } from 'services/analytics/actions'
import { GA_EVENT_NAMES } from 'utils/constants'
import { useDispatch } from 'react-redux'

const ServiceTerms = ({ handleClose, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const nameArray = Array.isArray(data?.name) ? data?.name : [data?.name]
  const dispatch = useDispatch()
  const imageArray = nameArray.map((elem) => {
    const image =
      elem === 'plana'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifaplana_zen.png'
        : elem === 'por-uso'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_poruso.png'
        : elem === 'noche'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_noche.png'
        : elem === 'digital-gas'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_gas.png'
        : elem === 'digital-luz'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_luz.png'
        : elem === 'digital-noche'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_noche_luz.png'
        : elem === 'digital-moving'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_tarifa_digital_moving_luz.png'
        : elem === 'seguro-proteccion-facturas'
        ? '//naturgy-zapotek-pro.s3-eu-west-1.amazonaws.com/public_images/ico_SPF.png'
        : ''
    return image
  })

  const conditionsUrlArray = nameArray.map((elem) => t(`entity-${elem}-general-url`))
  return (
    <>
      {nameArray.map((elem, index) => (
        <>
          <DialogTitle key={index} disableTypography className={classes.dialogTitle}>
            <Typography variant="h4" color="secondary">
              {t(`entity-${elem}-title`)}
            </Typography>
            <Typography component="h4" variant="h2" color="secondary">
              {t(`entity-${elem}-subtitle`)}
            </Typography>
            {imageArray[index] !== '' ? (
              <img className={classes.logo} src={imageArray[index]} alt={`${t(`entity-${elem}-title`)} logo`} />
            ) : null}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box mt={1} mb={4}>
              <div dangerouslySetInnerHTML={{ __html: t(`entity-${elem}-terms`) }} />
            </Box>
            {conditionsUrlArray[index] && (
              <Box mb={1}>
                <Link iconLeft variant="link" color="secondary" href={conditionsUrlArray[index]}>
                  {t('generalConditions')}
                </Link>
              </Box>
            )}
            {/* <Link iconLeft variant="link" color="secondary" href="#">
            {t('particularConditions')}
          </Link> */}
          </DialogContent>
        </>
      ))}

      <DialogActions className={classes.dialogActions}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            data?.literal
              ? dispatch(sendGAEvent(GA_EVENT_NAMES.cancelDiscountPopUp))
              : dispatch(sendGAEvent(GA_EVENT_NAMES.cancelConditionsPopUp))
            handleClose()
          }}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ServiceTerms
