import { CHANNEL_TYPES } from 'utils/constants'
import { COMPANIES } from './constants'
import { ID_NATURGY_CLIENTES, ID_NATURGY_IMPERIAL } from 'utils/constants'

export const mapOnlineChannelObject = (onlineChannelResponse, onlineChannelParams, userData) => {
  const channelUser = userData?.channelOffers
  let channelSelected
  if (!onlineChannelParams?.company) {
    channelSelected = channelUser?.find(({ channel }) => {
      return channel?.companies[0]?.id === ID_NATURGY_IMPERIAL
    })
  } else {
    channelSelected = channelUser?.find(({ channel }) => {
      if (onlineChannelParams?.company === 'gns') {
        return channel?.companies[0]?.id === ID_NATURGY_IMPERIAL
      } else {
        return channel?.companies[0]?.id === ID_NATURGY_CLIENTES
      }
    })
  }
  let onlineChannel = onlineChannelResponse.find((item) => {
    const isWebChannel = CHANNEL_TYPES.web.indexOf(item?.channel?.type) !== -1
    if (!onlineChannelParams?.company) {
      return isWebChannel && COMPANIES[item?.channel?.companies[0]] === 'gns' && channelSelected?.id === item?.id
    }
    return (
      isWebChannel && onlineChannelParams?.company === COMPANIES[item?.channel?.companies[0]] && channelSelected?.id === item?.id
    )
  })
  onlineChannel = {
    id: onlineChannel.id,
    channelId: onlineChannel.channel.id,
    name: onlineChannel.channel.name,
    type: onlineChannel.channel.type,
  }
  return onlineChannel
}

export const getOnlineChannelUrlParams = (search) => {
  if (!search) return {}
  const query = search.substr(1)
  let result = {}
  query.split('&').forEach((part) => {
    let item = part.split('=')
    if (!!result['idCal[]'] && item[0] === 'idCal[]') {
      result['idCalSecond[]'] = decodeURIComponent(item[1])
    } else {
      result[item[0]] = decodeURIComponent(item[1])
    }
  })
  return result
}
