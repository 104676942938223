import * as actionTypes from './action-types'

export const uploadInvoice = (payload) => ({
  type: actionTypes.UPLOAD_INVOICE,
  payload,
})

export const uploadInvoiceStart = (payload) => ({
  type: actionTypes.UPLOAD_INVOICE_START,
  payload,
})

export const uploadInvoiceSuccess = () => ({
  type: actionTypes.UPLOAD_INVOICE_SUCCESS,
})

export const uploadInvoiceError = () => ({
  type: actionTypes.UPLOAD_INVOICE_ERROR,
})

export const uploadInvoiceClear = () => ({
  type: actionTypes.UPLOAD_INVOICE_CLEAR,
})

export const setInvoiceData = (payload) => ({
  type: actionTypes.SET_INVOICE_DATA,
  payload,
})

export const clearInvoiceData = (payload) => ({
  type: actionTypes.CLEAR_INVOICE_DATA,
  payload,
})
