import React from 'react'

import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Typography from 'ui/components/typography'

const CommonAlert = ({ severity, title }) => {
  return (
    <Box my={2}>
      <Alert icon={false} severity={severity}>
        <Typography variant="basic">{title}</Typography>
      </Alert>
    </Box>
  )
}

export default CommonAlert
