import React from 'react'
import { Box } from '@material-ui/core'

import useStyles from './styles'

/**
 * UI component used for Rates and Toppings.
 */

const Products = ({
  products,
  selectedProducts,
  onChangeProduct,
  Component,
  onFlatRateCalculate,
  openModal,
  onSimulatorClick,
  loadingSimulatorLink,
  isOnlineChannel,
  disabled,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {products
        .filter((product) => product.name !== 'por-uso_COPY')
        .map((product) => (
          <Component
            key={`product-${product.id}`}
            product={product}
            selected={selectedProducts}
            onChangeProduct={onChangeProduct}
            openModal={openModal}
            onFlatRateCalculate={onFlatRateCalculate}
            onSimulatorClick={onSimulatorClick}
            loadingSimulatorLink={loadingSimulatorLink}
            isOnlineChannel={isOnlineChannel}
            isRecommended={product.isRecommended}
            disabled={disabled}
          ></Component>
        ))}
    </Box>
  )
}

export default Products
