import { SERVICE } from './constants'

export const CHECK_ONLINE_CHANNEL = `${SERVICE}/CHECK_ONLINE_CHANNEL`
export const START_ONLINE_CHANNEL = `${SERVICE}/START_ONLINE_CHANNEL`
export const SUCCESS_ONLINE_CHANNEL = `${SERVICE}/SUCCESS_ONLINE_CHANNEL`
export const ERROR_ONLINE_CHANNEL = `${SERVICE}/ERROR_ONLINE_CHANNEL`
export const CLEAR_ONLINE_CHANNEL = `${SERVICE}/CLEAR_ONLINE_CHANNEL`
export const START_LOGIN_ONLINE_CHANNEL = `${SERVICE}/START_LOGIN_ONLINE_CHANNEL`
export const SUCCESS_LOGIN_ONLINE_CHANNEL = `${SERVICE}/SUCCESS_LOGIN_ONLINE_CHANNEL`
export const ERROR_LOGIN_ONLINE_CHANNEL = `${SERVICE}/ERROR_LOGIN_ONLINE_CHANNEL`
export const CLEAR_LOGIN_ONLINE_CHANNEL = `${SERVICE}/CLEAR_LOGIN_ONLINE_CHANNEL`
export const COMPLETE_STEPS_ONLINE_CHANNEL = `${SERVICE}/COMPLETE_STEPS_ONLINE_CHANNEL`
export const COMPLETE_ANALYTICS_ONLINE_CHANNEL = `${SERVICE}/COMPLETE_ANALYTICS_ONLINE_CHANNEL`
export const CHECK_PYME_ONLINE_CHANNEL = `${SERVICE}/CHECK_PYME_ONLINE_CHANNEL`
export const SET_PYME_ONLINE_CHANNEL = `${SERVICE}/SET_PYME_ONLINE_CHANNEL`
export const SET_META_DATA = `${SERVICE}/SET_META_DATA`
export const CLEAR_META_DATA = `${SERVICE}/CLEAR_META_DATA`
export const SET_LOGIN_ERROR_BY_USER = `${SERVICE}/SET_LOGIN_ERROR_BY_USER`
export const CLEAR_LOGIN_ERROR_BY_USER = `${SERVICE}/CLEAR_LOGIN_ERROR_BY_USER`
