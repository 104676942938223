import * as actionTypes from './action-types'

export const setPortability = (portability) => ({
  type: actionTypes.SET_PORTABILITY,
  payload: portability,
})

export const fetchPortability = (portability) => ({
  type: actionTypes.FETCH_PORTABILITY,
  payload: portability,
})

export const fetchPortabilityStart = () => ({
  type: actionTypes.FETCH_PORTABILITY_START,
})

export const fetchPortabilitySuccess = (portability) => ({
  type: actionTypes.FETCH_PORTABILITY_SUCCESS,
  payload: portability,
})

export const fetchPortabilityError = (error) => ({
  type: actionTypes.FETCH_PORTABILITY_ERROR,
  payload: error,
})
export const exportCSV = (selectedItems) => ({
  type: actionTypes.EXPORT_CSV,
  payload: selectedItems,
})
export const exportFileStart = () => ({
  type: actionTypes.EXPORT_REQUEST_START,
})

export const exportFileSuccess = () => ({
  type: actionTypes.EXPORT_REQUEST_SUCCESS,
})
export const exportFileError = () => ({
  type: actionTypes.EXPORT_REQUEST_ERROR,
})
export const saveCSV = (file, name) => ({
  type: actionTypes.SAVE_CSV,
  payload: {
    file,
    name,
  },
})
export const createCSV = (file) => ({
  type: actionTypes.CREATE_CSV,
  payload: {
    file,
  },
})
