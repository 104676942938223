import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, DialogContent } from '@material-ui/core'

import Link from 'ui/components/link'
import Typography from 'ui/components/typography'

import useStyles from './styles'
import { Alert } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { getIsOnlineChannelErrorByUser } from 'services/onlineChannel/selectors'

const Login = ({ children, error, success }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const errorByUser = useSelector(getIsOnlineChannelErrorByUser)

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('caLoginTitle')}
          </Typography>
        </Box>
        {error && (
          <Box mb={2}>
            <Alert icon={false} severity="error">
              <Typography variant="h5">{errorByUser ? t('caLoginErrorByUser') : t('caLoginErrorTitle')}</Typography>
            </Alert>
          </Box>
        )}
        {success && (
          <Box mb={2}>
            <Alert icon={false} severity="success">
              <Typography variant="h5">{t('caLoginSuccessTitle')}</Typography>
            </Alert>
          </Box>
        )}
        {children}

        <Box display="flex" justifyContent="center" mt={3} mb={2}>
          <Link variant="link" href={t('forgotPasswordLink')} target="_blank">
            {t('forgotPassword')}
          </Link>
        </Box>
      </DialogContent>
    </>
  )
}

export default Login
