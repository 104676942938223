import { SERVICE } from './constants'

export const INIT = `${SERVICE}/INIT`
export const REQUEST_PHONE_NUMBER = `${SERVICE}/REQUEST_PHONE_NUMBER`

export const SET_PHONE_NUMBER = `${SERVICE}/SET_PHONE_NUMBER`
export const CLEAR_PHONE_NUMBER = `${SERVICE}/CLEAR_PHONE_NUMBER`

export const SET_CALL_ME_BACK_LEAD = `${SERVICE}/SET_CALL_ME_BACK_LEAD`
export const CLEAR_CALL_ME_BACK_LEAD = `${SERVICE}/CLEAR_LEAD`

export const SET_ID_CAMPAIGN = `${SERVICE}/SET_ID_CAMPAIGN`
export const CLEAR_ID_CAMPAIGN = `${SERVICE}/CLEAR_ID_CAMPAIGN`

export const SEND_CALL_ME_BACK_LEAD = `${SERVICE}/SEND_CALL_ME_BACK_LEAD`
export const SET_IS_SEND_LEAD_REQUESTING = `${SERVICE}/SET_IS_SEND_LEAD_REQUESTING`
