import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  notificationsListItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  uncheckedItem: {
    background: theme.palette.neutral.light3,
  },
  label: {
    color: theme.palette.neutral.light1,
    fontSize: theme.spacing(1.5),
    fontWeight: '400',
  },
  dotIcon: {
    fontSize: theme.spacing(1.5),
    position: 'absolute',
    left: -theme.spacing(2),
    top: theme.spacing(0.3),
  },
}))

export default useStyles
