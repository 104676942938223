import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, StringParam } from 'use-query-params'
import { useSnackbar } from 'context/SnackbarContext'

import assoc from '@zatopek/core/src/utils/assoc'

import { getCompany, getSelectedTenure, getSupplyPoint, getUserData, getCustomerData } from 'services/customer/selectors'
import { CONTRACT_STEPS } from 'services/stepper/constants'
import { getIsSimulatorFileFetching, getProductAttributes, getRates, getSelectedRate } from 'services/products/selectors'
import { getIsOnlineChannel, getReferenceSegment } from 'services/operator/selectors'
import {
  clearSelectedProduct,
  fetchSimulatorFile,
  setProductWithAttribute,
  setIsOnlyToppings,
  clearProductVariants,
  setProductSelection,
} from 'services/products/actions'
import { getIsLeadFetching } from 'services/lead/selectors'
import {
  setStepDisabled,
  setStepCompleted,
  setStepUncompleted,
  setPreselectedStep,
  setStepEnabled,
  setStepAutocompleted,
} from 'services/stepper/actions'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

import { GAS, ELECTRICITY, BOTH, GA_EVENT_NAMES, SERVICES, STEPS, VARIANT_TYPES, COMPANY_CODES } from 'utils/constants'

import SelectRateView from '../ui/views/selectRate'
import { sendGAEvent } from 'services/analytics/actions'
import { getRouterQueries } from 'services/analytics/selectors'
//import usePrevious from '@zatopek/core/src/hooks/usePrevious'
import { getSingleToppings } from 'services/toppings/selectors'
import { removeToppings, clearVirtualBattery } from 'services/toppings/actions'
import { getVirtualBatteryStatus } from 'services/toppings/selectors'

import { fetchLead } from 'services/lead/actions'
import { fetchFidelization } from 'services/fidelization/actions'
import {
  getIsFidelizationFetching,
  getIsFidelizationAllowed,
  getIsDisallowedNewAddressProductChange,
} from 'services/fidelization/selectors'
import { getIsRateStepActive } from 'services/stepper/selectors'
import { checkCups } from 'utils/commons'

import { CircularProgress } from '@material-ui/core'
import Loader from '@zatopek/core/src/ui/components/loader'
import useStep from '@zatopek/funnel/src/hooks/useStep'
import { fetchVirtualBattery } from 'services/toppings/actions'

const SelectRate = ({ onContinue, preSelectedRate, setPreSelectedRate, serviceEventSent, setServiceEventSent }) => {
  const dispatch = useDispatch()
  const { setSnackMessage } = useSnackbar()
  // eslint-disable-next-line no-unused-vars
  const currentStep = useStep({ stepIndex: 1 })

  const productAttributes = useSelector(getProductAttributes)
  const [sel] = useQueryParam('sel', StringParam)
  const [service, setService] = useState(Object.keys(SERVICES)[0])
  const [filteredServices, setFilteredServices] = useState(SERVICES)
  const [showRequiredAlert, setShowRequiredAlert] = useState(false)
  const [attributes, setAttributes] = useState({})
  const userData = useSelector(getUserData)
  const customerData = useSelector(getCustomerData)
  const userDocumentNumber = userData?.documentNumber || customerData?.idDocumentNumber
  const supplyPoint = useSelector(getSupplyPoint)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const rates = useSelector(getRates)
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedRate = selectedProductArray
  const isSimulatorLinkFetching = useSelector(getIsSimulatorFileFetching)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const referenceSegment = useSelector(getReferenceSegment)
  const queries = useSelector(getRouterQueries)
  const tenure = useSelector(getSelectedTenure)
  const customerCompany = useSelector(getCompany)
  const isDisallowedNewAddressProductChange = useSelector(getIsDisallowedNewAddressProductChange)
  const isStepActive = useSelector(getIsRateStepActive)
  const company = customerCompany || selectedRate[0]?.company
  const virtualBattery = useSelector(getVirtualBatteryStatus)
  const isPortability = Boolean(company?.code && tenure?.company && company.code.toLowerCase() !== tenure.company.toLowerCase())
  const allToppings = useSelector(getSingleToppings)

  const isFidelizationFetching = useSelector(getIsFidelizationFetching)
  const isFidelizationAllowedRaw = useSelector(getIsFidelizationAllowed)
  const isFidelizationAllowed = isFidelizationAllowedRaw ?? true
  const [btnState, setBtnState] = useState(false)

  useEffect(() => {
    if (company && !isFidelizationFetching) {
      dispatch(fetchFidelization())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id])

  useEffect(() => {
    if (isOnlineChannel) {
      dispatch(setStepAutocompleted(STEPS.rate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlineChannel])

  useEffect(() => {
    // Dispatch analytics event on preselected rates COL
    if (selectedRate.length > 0 && isOnlineChannel) {
      dispatch(sendGAEvent(GA_EVENT_NAMES.selectRate, selectedRate))
    }
    return () => {
      setPreSelectedRate(selectedRate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* const previousRate = usePrevious(selectedRate)
 
   useEffect(() => {
     if (selectedRate && selectedRate.energyType !== previousRate?.energyType) {
       setService(selectedRate.energyType)
     }
   }, [selectedRate, previousRate])*/

  useEffect(() => {
    if (preSelectedRate.length <= 0) return

    const changedPreSelectedRate = preSelectedRate.map((rate) => {
      const foundRate = rates.find((r) => r.id === rate.id)
      return foundRate || rate
    })

    setPreSelectedRate(changedPreSelectedRate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates])

  useEffect(() => {
    if (isOnlineChannel && queries?.sel && selectedRate.length > 0 && Object.keys(attributes).length !== 0) {
      dispatch(setProductWithAttribute(selectedRate, attributes))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOnlineChannel, queries.sel, attributes, selectedRate])

  useEffect(() => {
    const shoudSentEvent = !serviceEventSent && isOnlineChannel && queries?.sel && selectedRate.length > 0
    if (!!shoudSentEvent) {
      // TODO: don't send pageView /paso-2 first
      // const rateStepIndex = CONTRACT_STEPS.findIndex((step) => step.id === STEPS.rate)
      // dispatch(setPreselectedStep(rateStepIndex))
      if (selectedRate.length > 1 || selectedRate[0]?.energyType === BOTH) {
        // dispatch(sendGAEvent(GA_EVENT_NAMES.selectService, BOTH))
      } else {
        // dispatch(sendGAEvent(GA_EVENT_NAMES.selectService, selectedRate[0]?.energyType))
      }

      setServiceEventSent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlineChannel, queries?.sel, selectedRate, serviceEventSent])

  /**
   * Send GA event on first render for pre-selected service
   */
  useEffect(() => {
    if (service && !sel && !serviceEventSent) {
      // dispatch(sendGAEvent(GA_EVENT_NAMES.selectService, service))
      const ownerStepIndex = CONTRACT_STEPS.findIndex((step) => step.id === STEPS.owner)
      dispatch(setPreselectedStep(ownerStepIndex))
      setServiceEventSent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceEventSent])

  useEffect(() => {
    const rate = preSelectedRate[0] || selectedRate[0]
    if (!isStepActive || !rate?.flatRate) return
    if (isDisallowedNewAddressProductChange) {
      const companyCode = (company ?? rate?.company ?? rate?.subProducts[0]?.company)?.code
      dispatch(
        showModal({
          modalType: MODAL_TYPES.warningModal,
          modalData: {
            titleKey: 'selectionDisallowedInNewDirection',
            textKey: `selectionDisallowedInNewDirection_${companyCode}`,
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedRate, selectedRate, isDisallowedNewAddressProductChange])

  const handleChangeRate = (id) => {
    id ? setBtnState(true) : setBtnState(false)
    const rate = rates.find((rate) => rate.id === id)
    if (preSelectedRate.find((elem) => elem.energyType === BOTH) && rate?.energyType !== BOTH) {
      setPreSelectedRate([rate])
    } else {
      if (rate?.energyType === BOTH) {
        setPreSelectedRate([rate])
      } else if (preSelectedRate.length === 1) {
        if (preSelectedRate[0].energyType === rate.energyType || preSelectedRate[0].energyType === BOTH) {
          setPreSelectedRate([rate])
        } else {
          if (preSelectedRate[0].flatRate && rate.flatRate) {
            const bothFlatRate = rates.filter((rate) => rate.energyType === BOTH).find((rate) => rate.flatRate)
            setPreSelectedRate([bothFlatRate])
            setService(BOTH)
          } else {
            setPreSelectedRate([...preSelectedRate, rate])
          }
        }
      } else if (preSelectedRate.length === 2) {
        const newPreSelectedRate = preSelectedRate.filter((elem) => elem.energyType !== rate.energyType)
        if (newPreSelectedRate[0].flatRate && rate.flatRate) {
          const bothFlatRate = rates.filter((rate) => rate.energyType === BOTH).find((rate) => rate.flatRate)
          setPreSelectedRate([bothFlatRate])
          setService(BOTH)
        } else {
          setPreSelectedRate(newPreSelectedRate)
        }
      } else {
        setPreSelectedRate([rate])
      }
    }

    dispatch(sendGAEvent(GA_EVENT_NAMES.selectRate, rate))

    /*if (rate?.flatRate) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.calculatorModal,
          modalData: {
            energyType: rate.energyType,
            preSelectedRate: [rate],
            attributes,
            setShowRequiredAlert,
            allowUploadInvoice: service === ELECTRICITY,
          },
        })
      )
    }*/
  }

  const hasCalculatedCost = (service) => {
    if (!Array.isArray(supplyPoint)) {
      return false
    }

    return service.split(',').every((type) => {
      return supplyPoint.find((point) => {
        return type.includes(point.energyType) && point.monthlyCost
      })
    })
  }

  // TODO: Refactor handle continue, optimize duplicate code
  const handleContinue = async () => {
    const flatRate = preSelectedRate.find((rate) => rate.flatRate)

    if (userDocumentNumber && company.code === COMPANY_CODES.gns) {
      dispatch(fetchVirtualBattery())
    } else if (company?.code === COMPANY_CODES.newco) {
      if (virtualBattery) {
        dispatch(clearVirtualBattery())
      }
    }
    if (isOnlineChannel) {
      dispatch(setStepEnabled(STEPS.supply))
      onContinue(preSelectedRate)
      return
    }
    if (selectedRate.length === 0 && preSelectedRate.length === 0) {
      setShowRequiredAlert(true)
    } else if (flatRate && !hasCalculatedCost(flatRate?.energyType)) {
      dispatch(setStepUncompleted(STEPS.rate))
      dispatch(clearSelectedProduct())
      handleFlatRateCalculate()
    } else {
      if (tenure && isPortability) {
        const selectedProduct = preSelectedRate || selectedRate
        if (selectedProduct[0]?.energyType === BOTH || selectedProduct.length > 1) {
          if (tenure.electricityCups || tenure.gasCups) {
            const { isOk, code } = await checkCups([tenure.electricityCups, tenure.gasCups], userDocumentNumber, company)
            if (!isOk) {
              dispatch(
                showModal({
                  modalType: MODAL_TYPES.checkCups,
                  modalData: {
                    sameClient: code === '01',
                    cupsActive: code === '05',
                  },
                })
              )
              return
            }
          }
          handleSuccess(selectedProduct)
        } else {
          let errorMsg
          if (tenure?.products?.length) {
            tenure?.products?.forEach((elm) => {
              const selected = preSelectedRate[0] || selectedRate[0]
              if (elm?.id?.length === 36 && elm?.type && elm?.type !== selected?.energyType) {
                errorMsg = elm.type
              }
            })
          }
          if (errorMsg) {
            setSnackMessage(
              `Tienes que contratar un producto que incluya ${
                errorMsg === ELECTRICITY ? 'electricidad' : 'gas'
              } para realizar la portabilidad`,
              'error'
            )
          } else {
            if (tenure.electricityCups || tenure.gasCups) {
              const { isOk, code } = await checkCups([tenure.electricityCups, tenure.gasCups], userDocumentNumber, company)
              if (!isOk) {
                dispatch(
                  showModal({
                    modalType: MODAL_TYPES.checkCups,
                    modalData: {
                      sameClient: code === '01',
                      cupsActive: code === '05',
                    },
                  })
                )
                return
              }
            }
            handleSuccess(selectedProduct)
          }
        }
      } else {
        const selectedProduct = preSelectedRate.length > 0 ? preSelectedRate : selectedRate
        handleSuccess(selectedProduct)
      }
    }
  }

  const handleSuccess = (selectedProduct) => {
    dispatch(setProductWithAttribute(selectedProduct, attributes))
    setShowRequiredAlert(false)
    dispatch(setStepCompleted(STEPS.rate))
    dispatch(setStepEnabled(STEPS.supply))
    onContinue(selectedProduct)
    dispatch(fetchLead())
  }

  const handleSkipStep = () => {
    if (userDocumentNumber && company.code === COMPANY_CODES.gns) {
      dispatch(fetchVirtualBattery())
    }
    const spfServiceType = allToppings?.find((topping) => topping?.service?.type === 'SPF')
    setShowRequiredAlert(false)
    dispatch(clearSelectedProduct())
    dispatch(clearProductVariants())
    dispatch(setIsOnlyToppings(true))
    dispatch(removeToppings([spfServiceType?.id]))
    dispatch(fetchLead())
  }

  const handleFlatRateCalculate = () => {
    const flatRate = preSelectedRate.find((rate) => rate.flatRate)
    dispatch(
      showModal({
        modalType: MODAL_TYPES.calculatorModal,
        modalData: {
          energyType: flatRate?.energyType,
          preSelectedRate: [flatRate],
          attributes,
          setShowRequiredAlert,
          allowUploadInvoice: service === ELECTRICITY,
        },
      })
    )
  }

  const handleOpenModal = (modalData) => {
    const eventData = { tariff: modalData, service }
    dispatch(sendGAEvent(GA_EVENT_NAMES.clickTariffDetail))
    dispatch(
      showModal({ modalType: MODAL_TYPES.detail, modalData }),
      sendGAEvent(GA_EVENT_NAMES.showTariffModal, { tariff: eventData })
    )
  }

  const handleSimulatorLinkClick = (productId) => {
    dispatch(fetchSimulatorFile(productId))
  }

  const handleChangeAttributes = (newAttributes, productId) => {
    setAttributes((oldAttributes) => assoc(productId, newAttributes, oldAttributes))
  }

  const handleSetService = (data) => {
    setService(data)
    // dispatch(sendGAEvent(GA_EVENT_NAMES.selectService, data))
  }

  // Show products separated by energytype
  // Avoid showing Tarifa Compromiso in GAS TAB.
  // Avoid showing entity-compromiso-digital on LUZ and on GAS TABS
  const filteredRates = rates.filter((rate) => {
    let filterCondition = rate.energyType === service && rate.name !== 'compromiso' && rate.name !== 'compromiso-digital'
    return filterCondition
  })

  const handleFilteredServices = () => {
    const filterService = {}

    if (tenure?.products?.length && isPortability && company?.code === COMPANY_CODES.gns) {
      const productsArrServices = []

      tenure.products.forEach((elm) => {
        if (elm?.type && !productsArrServices.includes(elm.type)) {
          productsArrServices.push(elm.type)
        }
      })

      if (productsArrServices.length >= 2) {
        // LUZ Y GAS CONTRATADOS
        filterService[BOTH] = SERVICES[BOTH]
      } else {
        for (const service in SERVICES) {
          if (service === BOTH) {
            filterService[service] = SERVICES[service]
          } else {
            tenure.products.forEach((product) => {
              if (product?.type === service) {
                filterService[service] = SERVICES[service]
              }
            })
          }
        }
      }

      return filterService
    }

    if (!isFidelizationAllowed && tenure?.variants?.length > 0) {
      const filterService = {}
      const tenureGas = tenure.variants.some(({ type }) => type === VARIANT_TYPES[GAS])
      const tenureElectricity = tenure.variants.some(({ type }) => type === VARIANT_TYPES[ELECTRICITY])
      if (!tenureGas) {
        filterService[GAS] = SERVICES[GAS]
      }
      if (!tenureElectricity) {
        filterService[ELECTRICITY] = SERVICES[ELECTRICITY]
      }
      return filterService
    }

    return SERVICES
  }

  useEffect(() => {
    const activeServices = handleFilteredServices()

    if (Object.keys(activeServices).length > 0 || !isFidelizationAllowed) {
      if (preSelectedRate.length === 0 && selectedRate.length === 0) {
        setService(Object.keys(activeServices)[0])
      }
      setFilteredServices(activeServices)
    }
    if (Object.keys(activeServices).length === 0 && !isFidelizationAllowed) {
      dispatch(setStepCompleted(STEPS.rate))
      dispatch(setStepAutocompleted(STEPS.rate))
      dispatch(setStepDisabled(STEPS.rate))
      dispatch(setStepCompleted(STEPS.supply))
      dispatch(setStepAutocompleted(STEPS.supply))
      dispatch(setStepDisabled(STEPS.supply))
      dispatch(
        setProductSelection({
          disallowed: true,
          message: `selectionDisallowed_${company?.code}`,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, rates, isFidelizationAllowed, tenure?.variants])

  const flateRate = preSelectedRate.find((rate) => rate.flatRate) || selectedRate.find((rate) => rate.flatRate)
  return isFidelizationFetching ? (
    <div style={{ position: 'relative', height: '150px' }}>
      <Loader open opacity={0.5}>
        <CircularProgress thickness={4} />
      </Loader>
    </div>
  ) : (
    <SelectRateView
      supplyData={supplyPoint}
      services={filteredServices}
      selectedService={service}
      company={company}
      setService={handleSetService}
      rates={filteredRates}
      rateSelected={preSelectedRate.length > 0 ? preSelectedRate : selectedRate}
      onChangeRate={handleChangeRate}
      onContinue={handleContinue}
      hasCalculatedCost={hasCalculatedCost}
      openModal={handleOpenModal}
      onSimulatorClick={handleSimulatorLinkClick}
      loadingSimulatorLink={isSimulatorLinkFetching}
      onSkipStep={handleSkipStep}
      onFlatRateCalculate={handleFlatRateCalculate}
      showRequiredAlert={showRequiredAlert}
      isDisabled={
        isLeadFetching ||
        isFidelizationFetching ||
        (!isFidelizationAllowed && Object.keys(filteredServices).length === 0) ||
        (flateRate && isDisallowedNewAddressProductChange) ||
        !btnState
      }
      attributes={attributes}
      setAttributes={handleChangeAttributes}
      productAttributes={productAttributes}
      isOnlineChannel={isOnlineChannel}
      referenceSegment={referenceSegment}
      isPortability={isPortability}
      energyDisallowedMessage={
        !isPortability && !isFidelizationAllowed && (!filteredServices[GAS] || !filteredServices[ELECTRICITY])
          ? `energyDisallowed_${company?.code}`
          : ''
      }
    />
  )
}

export default SelectRate
