import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { Box, CircularProgress } from '@material-ui/core'

import { setOperatorData, fetchChannels, fetchUser, logout, fetchUserAPI } from 'services/operator/actions'
import { getOperatorAuth, getUser } from 'services/operator/selectors'
import { get, remove, set } from 'services/sessionStorage'
import { getIsLoading } from 'services/loading/selectors'
import { showModal } from 'services/modal/actions'
import { MODAL_TYPES } from 'services/modal/constants'

import PublicRoute from 'containers/DefaultRoute'
import MainRouteLayout from 'containers/MainRouteLayout'
import SnackbarProvider from 'context/SnackbarContext'

import { setup as setupApiClient } from 'services/client'
import { getUser as getUserApi, getUserOnline } from 'services/operator/api'
import { loginRequest } from './authConfig'
import { AUTHENTICATION_AZURE } from 'utils/constants'
import Forbidden from './containers/Forbidden'

const LoginRoute = lazy(() => import('containers/LoginRoute'))
const AdminRoute = lazy(() => import('containers/AdminRoute'))
const FailedManagementRoute = lazy(() => import('containers/FailManagementRoute'))
const PortabilityRoute = lazy(() => import('containers/PortabilityRoute'))
const PrivateRoute = lazy(() => import('containers/PrivateRoute'))
const ControlRoute = lazy(() => import('containers/ControlRoute'))

const Login = lazy(() => import('containers/Login'))
const NewContract = lazy(() => import('containers/NewContract'))
const Dashboard = lazy(() => import('containers/Dashboard'))
const ThankYou = lazy(() => import('containers/ThankYou'))
const Sandbox = lazy(() => import('containers/Sandbox'))
const Locution = lazy(() => import('containers/Locution'))
const Backoffice = lazy(() => import('containers/Backoffice/Backoffice'))
const Portability = lazy(() => import('containers/Portability/Portability'))
const FailedManagement = lazy(() => import('containers/FailedManagement/FailedManagement'))
const OrderDetail = lazy(() => import('containers/Backoffice/OrderDetail'))
const OrderFailDetail = lazy(() => import('containers/FailedManagement/OrderFailedDetail'))
const Notifications = lazy(() => import('containers/Backoffice/NotificationsList'))
const AdminPanel = lazy(() => import('containers/AdminPanel/AdminPanel'))
const ControlPanel = lazy(() => import('containers/ControlPanel'))
const SignError = lazy(() => import('containers/SignError'))
const PageNotFound = lazy(() => import('containers/PageNotFound'))
const Settings = lazy(() => import('containers/settings/Settings'))
const ModalRoot = lazy(() => import('containers/modals/ModalRoot'))
const ErrorPageLayout = lazy(() => import('containers/ErrorRouteLayout'))
const ExternalCalc = lazy(() => import('containers/ExternalCalc/externalCalc'))
const TechnicalInfo = lazy(() => import('containers/TechnicalInfo'))
const CurrentState = lazy(() => import('containers/CurrentState/index'))
const CurrentStateDetail = lazy(() => import('containers/CurrentState/CurrentStateDetail/index'))

const App = () => {
  const dispatch = useDispatch()
  const [isSessionAuth, setIsSessionAuth] = useState(false)
  const userSession = useSelector(getUser)
  const operator = useSelector(getOperatorAuth)
  const isDraftLoading = useSelector(getIsLoading)
  const [tokenFlag, setTokenFlag] = useState(false)

  //AZURE AD
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()
  const activeAccount = instance.getActiveAccount()

  //AUTH0
  const {
    user,
    isAuthenticated: auth0IsAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: auth0Logout,
  } = useAuth0()

  useEffect(() => {
    if (AUTHENTICATION_AZURE) {
      if (activeAccount && isAuthenticated) {
        set('logged', 'ok')
      }
    }
  }, [activeAccount, isAuthenticated])

  const handleApiError440 = () => {
    if (AUTHENTICATION_AZURE) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.sessionExpired,
          modalData: {
            onCloseModal: () => {
              dispatch(logout())
              instance.logout({ postLogoutRedirectUri: process.env.REACT_APP_FRONT_URL })
            },
          },
        })
      )
    } else {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.sessionExpired,
          modalData: {
            onCloseModal: () => {
              dispatch(logout())
              auth0Logout({ returnTo: process.env.REACT_APP_FRONT_URL })
            },
          },
        })
      )
    }
  }

  useEffect(() => {
    if (AUTHENTICATION_AZURE) {
      if (isAuthenticated) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            set('accessToken', response.accessToken)
            set('idToken', response.idToken)
            setTokenFlag(true)
            const expirationDate = new Date(response.expiresOn)
            const currentTime = new Date()
            const timeUntilExpiration = expirationDate - currentTime

            if (timeUntilExpiration > 0) {
              setTimeout(() => {
                handleApiError440()
              }, timeUntilExpiration)
            } else {
              handleApiError440()
            }
          })
          .catch((error) => {
            console.error(error)
            handleApiError440()
          })
        dispatch(setOperatorData(accounts[0]))
        const urlParams = new URLSearchParams(window.location.search)
        const agv = urlParams.get('agv')
        if (agv) {
          dispatch(fetchUserAPI(agv))
        } else {
          if (!tokenFlag) return
          dispatch(fetchUser())
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tokenFlag])

  useEffect(() => {
    if (!AUTHENTICATION_AZURE) {
      if (isSessionAuth && auth0IsAuthenticated) {
        Promise.all([getAccessTokenSilently(), getIdTokenClaims()]).then(([accessToken, { __raw: idToken }]) => {
          set('accessToken', accessToken)
          set('idToken', idToken)
          dispatch(setOperatorData(user))
          const urlParams = new URLSearchParams(window.location.search)
          const agv = urlParams.get('agv')
          if (agv) {
            dispatch(fetchUserAPI(agv))
          } else {
            dispatch(fetchUser())
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0IsAuthenticated, isSessionAuth])

  useEffect(() => {
    const authSession = async () => {
      try {
        // get url params to get agv
        const urlParams = new URLSearchParams(window.location.search)
        const agv = urlParams.get('agv')
        if (agv) {
          await getUserOnline(agv)
        } else {
          if (!tokenFlag) return
          await getUserApi()
        }
      } catch {
      } finally {
        setIsSessionAuth(true)
      }
    }
    setupApiClient({
      // ok: {
      //   200: () => {},
      // },
      ko: {
        440: handleApiError440,
      },
    })
    authSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFlag])

  useEffect(() => {
    if (!userSession?.roles) return

    dispatch(fetchChannels())
    if (!AUTHENTICATION_AZURE) setIsSessionAuth(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession])

  const isAuthFinished = useMemo(
    () => isSessionAuth && (!isAuthenticated || (isAuthenticated && !!operator)),
    [isSessionAuth, isAuthenticated, operator]
  )

  const isAuthFinishedAuth0 = useMemo(
    () => !isLoading && isSessionAuth && (!auth0IsAuthenticated || (!isLoading && auth0IsAuthenticated && !!operator)),
    [isSessionAuth, auth0IsAuthenticated, isLoading, operator]
  )

  useEffect(() => {
    if (get('logged') && !auth0IsAuthenticated && isAuthFinishedAuth0 && !AUTHENTICATION_AZURE) {
      remove('logged')
      loginWithRedirect()
    }
  }, [loginWithRedirect, isAuthFinishedAuth0, auth0IsAuthenticated])

  useEffect(() => {
    const handleButtonClick = (event) => {
      if (event.target.tagName === 'BUTTON') {
        const button = event.target
        if (!button.onclick && !button.onsubmit) {
          setTimeout(() => {
            button.disabled = true
          }, 10)
        } else {
          button.disabled = true
        }
        setTimeout(() => {
          button.disabled = false
        }, 2500)
      }
      if (event.target.tagName === 'SPAN') {
        const button = event.target.closest('button')
        if (button) {
          if (!button.onclick && !button.onsubmit) {
            setTimeout(() => {
              button.disabled = true
            }, 10)
          } else {
            button.disabled = true
          }
          // Deshabilitar el componente button durante 3 segundos después de un clic en el span
          setTimeout(() => {
            button.disabled = false
          }, 2500)
          return
        }
      }
    }
    document.addEventListener('click', handleButtonClick)

    return () => {
      document.removeEventListener('click', handleButtonClick)
    }
  }, [])

  useEffect(() => {
    const handleSubmit = (event) => {
      const target = event.target
      if (target.tagName === 'BUTTON' && target.type === 'submit') {
        target.disabled = true
        setTimeout(() => {
          target.disabled = false
        }, 2000)
      }
    }

    document.addEventListener('submit', handleSubmit)

    return () => {
      document.removeEventListener('submit', handleSubmit)
    }
  }, [])

  const AppRoute = ({ layout: Layout, children, noActions, showProgress, alternativeLayout, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() => (
          <Layout noActions={!!noActions} showProgress={showProgress} alternativeLayout={alternativeLayout}>
            {children}
          </Layout>
        )}
      />
    )
  }

  const Loading = () => (
    <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
      <CircularProgress size={48} thickness={1.4} />
    </Box>
  )

  return (AUTHENTICATION_AZURE ? !isAuthFinished : !isAuthFinishedAuth0) || isDraftLoading ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>
      <SnackbarProvider>
        <Switch>
          <AppRoute layout={MainRouteLayout} path="/login" exact>
            <LoginRoute title="Login | Naturgy" exact component={Login} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/new-contract" exact showProgress>
            <PrivateRoute title="Nueva contratación | Naturgy" component={NewContract} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/dashboard" exact>
            <PrivateRoute title="Dashboard | Naturgy" component={Dashboard} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/sandbox" exact>
            <PrivateRoute title="Sandbox | Naturgy" component={Sandbox} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/thank-you" exact noActions>
            <PublicRoute title="Thank you | Naturgy" component={ThankYou} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/sign-error" exact>
            <PublicRoute title="Error en la firma | Naturgy" component={SignError} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/locution" exact>
            <PrivateRoute title="Locution | Naturgy" component={Locution} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/backoffice" exact>
            <PrivateRoute title="Trastienda | Naturgy" component={Backoffice} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/portability" exact>
            <PortabilityRoute title="Portabilidad | Naturgy" component={Portability} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/failed-management" exact>
            <FailedManagementRoute title="Gestión de fallidos | Naturgy" component={FailedManagement} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/backoffice/order/:orderId" exact>
            <PrivateRoute title="Detalle de pedido | Naturgy" component={OrderDetail} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/failedManagement/order/:orderId" exact>
            <PrivateRoute title="Detalle de pedido | Naturgy" component={OrderFailDetail} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/backoffice/notifications" exact>
            <PrivateRoute title="Notificaciones | Naturgy" component={Notifications} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/control-panel" alternativeLayout>
            <ControlRoute title="Cuadro  de  mando | Naturgy" component={ControlPanel} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/admin-panel" alternativeLayout>
            <AdminRoute title="Administración | Naturgy" component={AdminPanel} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/settings" alternativeLayout>
            <PrivateRoute title="Configuración | Naturgy" component={Settings} />
          </AppRoute>
          <AppRoute path="/externalCalc" component={ExternalCalc} />
          <AppRoute layout={MainRouteLayout} path="/" exact showProgress>
            <PublicRoute title="Nueva contratación | Naturgy" component={NewContract} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/technical-info" exact showProgress>
            <PrivateRoute title="Información técnica | Naturgy" component={TechnicalInfo} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/current-state" exact showProgress>
            <PublicRoute title="Estado actual | Naturgy" component={CurrentState} />
          </AppRoute>
          <AppRoute layout={MainRouteLayout} path="/current-state/:CurrentStateId" exact showProgress>
            <PublicRoute title="Estado actual | Naturgy" component={CurrentStateDetail} />
          </AppRoute>

          <AppRoute layout={ErrorPageLayout}>
            <PublicRoute component={PageNotFound} title="Error 404 | Naturgy" />
          </AppRoute>

          <AppRoute layout={ErrorPageLayout} path="/403" exact>
            <PublicRoute component={Forbidden} title="403 - Forbidden | Naturgy" />
          </AppRoute>
        </Switch>
        <ModalRoot />
      </SnackbarProvider>
    </Suspense>
  )
}

export default App
