import { get, getExternal } from '../client'
import decodeString from '../../utils/decodeString'
import roundNumber from '../../utils/roundNumber'
import { ELECTRICITY /* GAS*/, NEWCO } from '@zatopek/core/src/utils/constants'
import uuid from 'react-uuid'

const MOCK_TOKEN = process.env.REACT_APP_CALCULATOR_API_KEY
const calculatorEntryPoint = process.env.REACT_APP_BACKEND_CALCULATOR_ENTRY_POINT
const emergyAddressEntryPoint = process.env.REACT_EMERGYA_URL

const normalizeStreet = (street) => ({ ...street, addressLine: decodeString(street.addressLine) })
const normalizeTechnicalInfo = (technicalInfo) =>
  Object.assign(
    technicalInfo,
    technicalInfo.selfConsumptionPowerInstalled && {
      selfConsumptionPowerInstalled: roundNumber(technicalInfo.selfConsumptionPowerInstalled / 1000, 2),
    }
  )

export const getTechnicalInfoFromTechId = async (energy, technicalId) => {
  const response = await get(
    `sips/technical_infos/${energy.toUpperCase()}?technicalInfoId=${technicalId}`,
    {},
    calculatorEntryPoint
  )
  return normalizeStreet(response)
}

export const getTechnicalInfoFromCups = async (energy, cups, docType, document, entryPoint = calculatorEntryPoint) => {
  const url = `sips/technical_infos/${energy.toUpperCase()}?cups=${cups}`
  const response = await get(
    `${url}${docType ? '&docType=' + docType : ''}${document ? '&document=' + document : ''}${
      energy ? '&energyType=' + energy.toUpperCase() : ''
    }`,
    {},
    entryPoint
  )
  return normalizeTechnicalInfo(response)
}

export const getAddressByCups = async (cups, category = ELECTRICITY, provider = 'iberia', step = 0) => {
  return await getExternal(
    emergyAddressEntryPoint,
    {},
    `cups/${cups}?sessionId=${uuid()}&step=${step}&category=${category}&provider=${provider}&energyType=${category.toUpperCase()}`
  )
}

export const getCalculatorInfo = async (cups, energyType, company) => {
  if (!energyType) return
  if (company === NEWCO)
    return await get(
      `calculations?cups=${cups}&channel=00&energyType=${energyType.toUpperCase()}&company=NC`,
      { 'X-API-Key': MOCK_TOKEN },
      calculatorEntryPoint
    )
  else
    return await get(
      `calculations?cups=${cups}&channel=00&energyType=${energyType.toUpperCase()}`,
      { 'X-API-Key': MOCK_TOKEN },
      calculatorEntryPoint
    )
}

export const getCalculatorInfoFromIdCal = async (idCal) =>
  await get(`calculations/${idCal}`, { 'X-API-Key': MOCK_TOKEN }, calculatorEntryPoint)

/**
 *
 * @param {String} cups
 * @returns if the cups is nnss
 */
export const checkCupsExists = (cups) => get(`sips/existCups/${cups}`)
