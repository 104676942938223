import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2, 3, 6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7, 6),
      '&:first-child': {
        paddingTop: theme.spacing(7),
      },
    },
  },
}))

export default useStyles
