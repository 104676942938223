import * as redukers from 'redukers'
import * as actionTypes from './action-types'
import { combineReducers } from 'redux'

/* eslint-disable-next-line */
export default combineReducers({
  contractStatus: redukers.value(
    {
      set: actionTypes.SET_CONTRACT_STATUS,
      reset: actionTypes.CLEAR_CONTRACT_STATUS,
    },
    {}
  ),
})
