import { put, postMultipart } from '../client'

const validateLeadUser = (orderId) => put(`orders/${orderId}/lead/validate`, {}, true)

const getLeadUserValidation = (orderId) => put(`orders/${orderId}/lead/is_validated`, {}, true)

const triggerGdpr = (leadId, channelId) => put(`leads/${leadId}/gdpr/channel/${channelId}`, {}, true)

const triggerSign = (leadId, signByWhatsapp = false) => put(`leads/${leadId}/sign`, { signByWhatsapp }, true)

const finishLocution = (leadId) => put(`leads/${leadId}/locution`, { state: '0' })

const cancelLocution = (leadId) => put(`leads/${leadId}/locution`, { state: '-1' })

const forwardSms = (leadId) => put(`leads/${leadId}/sign?retryTransaction=1`, {}, true)

const triggerP0 = (leadId, p0Data) => put(`leads/${leadId}/p0`, p0Data, true)

const attachDocument = (orderId, type, file) => {
  const data = new FormData()
  data.append('order_id', orderId)
  data.append('type', type)
  data.append('file', file)

  return postMultipart('attach_documents', data)
}

export {
  validateLeadUser,
  getLeadUserValidation,
  triggerGdpr,
  triggerSign,
  finishLocution,
  cancelLocution,
  forwardSms,
  triggerP0,
  attachDocument,
}
