import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core'

import Typography from 'ui/components/typography'
import Link from 'ui/components/link'

import useStyles from './styles'

const PowerChange = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {t('wantContinue')}
          </Typography>
        </Box>
        <Box mb={5}>
          <Typography variant="h5" color="textPrimary">
            {t('saveData')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="basic" color="textPrimary">
            <Trans i18nKey="changeData">
              <Link variant="link" target="_blank" href={t('common.privacyPolicyUrl')}></Link>
            </Trans>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          <Link component="button" variant="link" onClick={handleClose}>
            {t('dontSaveMyData')}
          </Link>
        </Box>
        <Button color="secondary" variant="contained" onClick={() => {}}>
          {t('saveMyData')}
        </Button>
      </DialogActions>
    </>
  )
}

export default PowerChange
