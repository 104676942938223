import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import { history } from 'redux/store'
import * as actionTypes from './action-types'
import * as productsActionTypes from 'services/products/action-types'
import {
  getSupplyPoint,
  getCustomerData,
  getIsMultiPoint,
  getIsTenureSelected,
  getSelectedTenure,
  getCompany,
  getNormalizedAddress,
} from './selectors'
import { getSelectedDualSubproducts, getSelectedProductVariants, getSelectedRate } from 'services/products/selectors'
import { setStepUncompleted, goNextStep, goNextStepDuplicate, setStepCompleted, setStepEnabled } from 'services/stepper/actions'
import { getLeadId } from 'services/lead/selectors'
import { getIsOnlineChannel, getReferenceSegment, getSelectedChannel } from 'services/operator/selectors'
import { clearLeadId, clearBillingAddressId, clearSupplyAddressesIds, fetchLead } from 'services/lead/actions'
import {
  clearOrderId,
  clearOrderTechnicalInfosIds,
  clearGdprSent,
  clearScoringSent,
  clearGuidelineDate,
} from 'services/order/actions'
import { ONLINE_CHANNEL_PARAM_NOT_FOUND_URL, STEPS, ELECTRICITY, GAS, BOTH } from 'utils/constants'
import { MODAL_TYPES } from 'services/modal/constants'
import { clearUserDataRequest } from 'services/customer/actions'

import {
  setCustomer,
  userDataError,
  userDataSuccess,
  setUserData,
  resetUserData,
  setSupplyPoint,
  setNormalizedAddress,
  setTenureSelectionError,
  clearTenureSelectionError,
  setSelectedTenure,
  setSelectedCompany,
  setExClient,
  resetExClient,
  setSupplyAddress,
  setProductReferralCode,
  setCompany,
} from './actions'
import { hideModal, showModal } from 'services/modal/actions'
import { getIsClient, getUserData } from './api'
import {
  EXISTING_CLIENT_CODE,
  EXISTING_CLIENT_NO_CONTRACT_CODE,
  NOT_CONTRACT_ERROR,
  NOT_CLIENT_ERROR,
  NOT_TENURE_ERROR,
  SERVER_ERROR,
  INITIAL_FORM_DATA,
} from './constants'
import { formatUserData, getIsExClient, getProductReferralCode } from './business'
import {
  getAddressByCups,
  getCalculatorInfoFromIdCal,
  getTechnicalInfoFromCups,
} from '@zatopek/core/src/services/supplyPoint/api'
import { PROVIDER_CODES } from '@zatopek/core/src/utils/constants'
import mapEmergyaAddress from '@zatopek/core/src/utils/mapEmergyaAddress'

function* validatechangeRateSaga() {
  const selectedProductArray = yield select(getSelectedRate)
  const selectedRate = selectedProductArray
  const supplyPoints = yield select(getSupplyPoint)
  const isMultiPoint = yield select(getIsMultiPoint)
  const isOnlineChannel = yield select(getIsOnlineChannel)
  const isTenureSelected = yield select(getIsTenureSelected)
  const selectedProductVariants = yield select(getSelectedProductVariants)
  const selectedDualSubproducts = yield select(getSelectedDualSubproducts)
  const normalizedAddress = yield select(getNormalizedAddress)

  if (selectedRate.length === 0 || !supplyPoints) {
    return
  }

  const energyTypes =
    selectedRate.length > 1
      ? selectedRate.map((rate) => rate?.energyType)
      : selectedRate[0]?.energyType === BOTH
      ? [ELECTRICITY, GAS]
      : [selectedRate[0]?.energyType]
  const hasRequiredSupplyPoints =
    energyTypes.every((energyType) => supplyPoints.some((supplyPoint) => energyType === supplyPoint.energyType)) && !isMultiPoint
  let isInvalidTariff = false
  if (isTenureSelected && supplyPoints) {
    if (selectedProductVariants && selectedRate.length === 1) {
      isInvalidTariff = !selectedProductVariants.some((variant) => variant.tariff.type === supplyPoints[0].accessTariff)
    } else if (selectedDualSubproducts) {
      const selectedElectricitySupplyPoint = supplyPoints.find((supply) => supply.energyType === ELECTRICITY.toUpperCase())
      isInvalidTariff = !selectedDualSubproducts.some((subProduct) =>
        subProduct.variants.some((variant) => selectedElectricitySupplyPoint?.accessTariff === variant.tariff.type)
      )
    } else if (selectedRate.length > 1) {
      isInvalidTariff = !selectedRate.some((rate) =>
        supplyPoints.some((supplyPoint) => rate.variants.some((variant) => variant.tariff.type === supplyPoint.accessTariff))
      )
    }
  }

  if (
    hasRequiredSupplyPoints &&
    !isInvalidTariff &&
    (normalizedAddress?.postalCode || supplyPoints.some((point) => point?.supplyAddress?.postalCode || point?.postalCode))
  ) {
    yield put(setStepCompleted(STEPS.supply))
  } else {
    if (!isOnlineChannel) yield put(setStepUncompleted(STEPS.supply))
  }
}

function* submitOwnerStepSaga({ payload: data }) {
  const customerData = yield select(getCustomerData)
  const leadId = yield select(getLeadId)
  const hasChangedDocumentNumber =
    customerData.idDocumentNumber && customerData.idDocumentNumber !== data.idDocumentNumber && leadId

  if (hasChangedDocumentNumber) {
    yield put(clearLeadId())
    yield put(clearBillingAddressId())
    yield put(clearSupplyAddressesIds())
    yield put(clearOrderId())
    yield put(clearOrderTechnicalInfosIds())
    yield put(clearGdprSent())
    yield put(clearScoringSent())
    yield put(clearGuidelineDate())
  }

  yield put(setCustomer(data))
  yield put(setStepCompleted(STEPS.owner))
  yield put(goNextStep())
}

function* submitOwnerStepDuplicateSaga({ payload: data }) {
  const customerData = yield select(getCustomerData)
  const leadId = yield select(getLeadId)
  const hasChangedDocumentNumber =
    customerData.idDocumentNumber && customerData.idDocumentNumber !== data.idDocumentNumber && leadId

  if (hasChangedDocumentNumber) {
    yield put(clearLeadId())
    yield put(clearBillingAddressId())
    yield put(clearSupplyAddressesIds())
    yield put(clearOrderId())
    yield put(clearOrderTechnicalInfosIds())
    yield put(clearGdprSent())
    yield put(clearScoringSent())
    yield put(clearGuidelineDate())
  }

  yield put(setCustomer(data))
  yield put(setStepCompleted(STEPS.owner))
  yield put(goNextStepDuplicate())
  yield put(goNextStep())
}

function* submitOwnerStepDraftSaga({ payload: data }) {
  const customerData = yield select(getCustomerData)
  const leadId = yield select(getLeadId)
  const hasChangedDocumentNumber =
    customerData.idDocumentNumber && customerData.idDocumentNumber !== data.idDocumentNumber && leadId

  if (hasChangedDocumentNumber) {
    yield put(clearLeadId())
    yield put(clearBillingAddressId())
    yield put(clearSupplyAddressesIds())
    yield put(clearOrderId())
    yield put(clearOrderTechnicalInfosIds())
    yield put(clearGdprSent())
    yield put(clearScoringSent())
    yield put(clearGuidelineDate())
  }

  yield put(setCustomer(data))
  yield put(setStepCompleted(STEPS.owner))
  //yield put(goNextStepDuplicate())
}

function* getUserDataSaga({ payload: { documentNumber, documentType } }) {
  yield put(resetUserData())
  yield put(resetExClient())
  const companySelected = yield select(getCompany)
  const channelSelected = yield select(getSelectedChannel)
  const isPyme = yield select(getReferenceSegment)
  try {
    const isClient = yield call(getIsClient, documentNumber)

    if (isClient.code === EXISTING_CLIENT_CODE) {
      const userData = yield call(getUserData, documentNumber, companySelected.id, channelSelected.channelId, isPyme === 'pyme')
      if (userData.code === '403') {
        yield put(userDataSuccess())
        yield put(clearUserDataRequest())
        yield put(
          showModal({
            modalType: MODAL_TYPES.limitChannel,
          })
        )
      } else if (userData.code === EXISTING_CLIENT_NO_CONTRACT_CODE) {
        yield put(userDataError(NOT_CONTRACT_ERROR))
        yield put(setUserData({ ...INITIAL_FORM_DATA, documentNumber, documentType }))
      } else {
        const userDataArray = userData.data
        const user =
          userDataArray.find((item) => item?.origin.toUpperCase() === companySelected?.code.toUpperCase())?.customer ||
          userDataArray[0]?.customer ||
          {}

        const tenureArray = userData.data.map(({ origin, tenure }) => {
          const tenureArr = Object.values(tenure)
          return {
            origin,
            tenure: tenureArr,
          }
        })
        const userDataLength = !!Object.keys(user)?.length
        if (userDataLength) {
          user.tenure = tenureArray
          user.documentNumber = userData.documentNumber
        }
        const formattedData = userDataLength
          ? formatUserData({ ...user, documentType, documentNumber })
          : { documentType, documentNumber }
        const isExClient = getIsExClient(user)

        yield put(setUserData(formattedData))
        yield put(setExClient(isExClient))
        yield put(userDataSuccess())
      }
    } else {
      yield put(userDataError(NOT_CLIENT_ERROR))
      yield put(setUserData({ ...INITIAL_FORM_DATA, documentNumber, documentType }))
    }
  } catch (error) {
    yield put(setUserData({ ...INITIAL_FORM_DATA, documentNumber, documentType }))
    if (error.status === 404) {
      yield put(userDataError(NOT_TENURE_ERROR))
    } else {
      console.error(error)
      yield put(userDataError(SERVER_ERROR))
    }
  }
}

function* setSupplyPointByIdCalSaga({ payload: { idCal, idCalSecond } }) {
  // TODO now send preselectedStep in goNextStepSaga stepper/sagas
  // const supplyStepIndex = CONTRACT_STEPS.findIndex((step) => step.id === STEPS.supply)
  // yield put(setPreselectedStep(supplyStepIndex))
  const selectedCompany = yield select(getCompany)

  try {
    let supplyPointInfo = []
    if (!idCalSecond) {
      const supplyPoint = yield call(getCalculatorInfoFromIdCal, idCal)
      let technicalInfo = {}
      try {
        technicalInfo = yield call(getTechnicalInfoFromCups, supplyPoint.energyType, supplyPoint.cups)
      } catch (error) {
        console.log('error', error)
        technicalInfo = { cups: supplyPoint.cups, energyType: supplyPoint.energyType }
        yield put(setStepUncompleted(STEPS.supply))
        yield put(setStepEnabled(STEPS.supply))
      }
      let normalizedAddress = false
      try {
        const emergyaAddress = yield call(
          getAddressByCups,
          supplyPoint?.cups,
          supplyPoint.energyType.toLowerCase(),
          PROVIDER_CODES[selectedCompany.code]
        )
        normalizedAddress = mapEmergyaAddress(emergyaAddress.results[0])
      } catch (error) {
        yield put(setStepUncompleted(STEPS.supply))
        yield put(setStepEnabled(STEPS.supply))
      }
      technicalInfo = [Object.assign(supplyPoint, { ...technicalInfo, supplyAddress: normalizedAddress })]
      yield put(setSupplyPoint(technicalInfo))
      yield put(setNormalizedAddress(normalizedAddress || false))
      yield put(setSupplyAddress(normalizedAddress))
    } else {
      const idCals = { idCal, idCalSecond }
      for (let id in idCals) {
        const supplyPoint = yield call(getCalculatorInfoFromIdCal, idCals[id])
        let technicalInfo = {}
        try {
          technicalInfo = yield call(getTechnicalInfoFromCups, supplyPoint.energyType, supplyPoint.cups)
        } catch (error) {
          console.log('error', error)
          technicalInfo = { cups: supplyPoint.cups, energyType: supplyPoint.energyType }
          yield put(setStepUncompleted(STEPS.supply))
          yield put(setStepEnabled(STEPS.supply))
        }
        supplyPointInfo.push(Object.assign(supplyPoint, technicalInfo))
      }
      const electricityCups = supplyPointInfo?.find((supplyPoint) => supplyPoint.energyType === ELECTRICITY)?.cups
      const gasCups = supplyPointInfo?.find((supplyPoint) => supplyPoint.energyType === GAS)?.cups
      let normalizedAddress = false
      try {
        const emergyaAddress = yield call(
          getAddressByCups,
          electricityCups,
          ELECTRICITY.toLowerCase(),
          PROVIDER_CODES[selectedCompany.code]
        )
        normalizedAddress = mapEmergyaAddress(emergyaAddress.results[0])
        normalizedAddress.gasCups = gasCups
        normalizedAddress.cups = electricityCups
      } catch (error) {
        yield put(setStepUncompleted(STEPS.supply))
        yield put(setStepEnabled(STEPS.supply))
      }
      const supplyPoint = supplyPointInfo.map((supply) => ({ ...supply, supplyAddress: normalizedAddress }))
      yield put(setSupplyPoint(supplyPoint))
      const isNormalizedAddress = normalizedAddress?.electricityCups && normalizedAddress?.gasCups
      yield put(setNormalizedAddress(isNormalizedAddress ? normalizedAddress : false))
      yield put(setSupplyAddress(normalizedAddress))
    }
  } catch (e) {
    console.error(e)
    yield call(history.replace, ONLINE_CHANNEL_PARAM_NOT_FOUND_URL.calculationNotValid)
  }
}

function* tenureSelection({ payload: selectedTenure }) {
  yield put(clearTenureSelectionError())
  const selectedProductArray = yield select(getSelectedRate)
  // If selected product is the same as in the selected tenure, show an error
  const isSelectedProductSameTenure = selectedTenure?.products?.some((product) =>
    selectedProductArray.some((selectedProduct) => selectedProduct.id === product.id)
  )
  if (isSelectedProductSameTenure) {
    yield put(setTenureSelectionError(true))
  } else {
    const {
      gasCUPS: gasCups,
      electricityCUPS: electricityCups,
      addressDescription: addressLine,
      addressPostalCode: postalCode,
      addressMunicipality: municipalityName,
      addressProvince: provinceName,
      ...rest
    } = selectedTenure || {}
    const mappedTenureObject = { ...rest, gasCups, electricityCups, addressLine, postalCode, municipalityName, provinceName }
    const tenure = Object.values(mappedTenureObject).some((elm) => !!elm) ? mappedTenureObject : {}
    yield put(setSelectedTenure(tenure))
    yield put(hideModal())
  }
}

function* companySelection({ payload: selectedCompany }) {
  yield put(setSelectedCompany(selectedCompany))
  yield put(setCompany(selectedCompany))
}

function* setReferralCodeSaga(payload) {
  let productReferralCode = null
  if (payload?.payload) {
    const selectedProductArray = yield select(getSelectedRate)
    const selectedProducts = selectedProductArray

    const selectedTenure = yield select(getSelectedTenure)
    productReferralCode = selectedProducts.map((product) => getProductReferralCode({ product, selectedTenure }))
    if (productReferralCode.length === 0) {
      productReferralCode = null
    } else if (productReferralCode.length === 1) {
      productReferralCode = productReferralCode[0]
    }
  }
  yield put(setProductReferralCode(productReferralCode))
  yield put(fetchLead())
  yield put(hideModal())
}

export default function* rootSaga() {
  yield all([
    takeLatest([productsActionTypes.SET_SELECTED_PRODUCT], validatechangeRateSaga),
    takeLatest([actionTypes.SUBMIT_OWNER_STEP], submitOwnerStepSaga),
    takeLatest([actionTypes.SUBMIT_OWNER_STEP_DUPLICATE], submitOwnerStepDuplicateSaga),
    takeLatest([actionTypes.SUBMIT_OWNER_STEP_DRAFT], submitOwnerStepDraftSaga),
    takeLatest([actionTypes.USER_DATA_REQUEST_START], getUserDataSaga),
    takeLatest([actionTypes.SET_SUPPLY_POINT_BY_ID_CAL], setSupplyPointByIdCalSaga),
    takeLatest([actionTypes.START_TENURE_SELECTION], tenureSelection),
    takeLatest([actionTypes.START_COMPANY_SELECTION], companySelection),
    takeLatest([actionTypes.SET_REFERRAL_CODE], setReferralCodeSaga),
  ])
}
