import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import { setSelectedExportTemplateId } from 'services/backoffice/actions'
import { hideModal } from 'services/modal/actions'

import { Box, Button } from '@material-ui/core'

import TemplateSelector from '../settings/backofficeTemplates/TemplateSelector'

import Link from 'ui/components/link'
import Typography from 'ui/components/typography'

const ExportTemplateSelector = ({ data }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { exportToCSV, templateType } = data

  const handleTemplateChange = (templateId) => {
    setSelectedTemplateId(templateId)
  }

  const handleExport = () => {
    dispatch(setSelectedExportTemplateId(selectedTemplateId))
    exportToCSV()
    dispatch(hideModal())
  }

  const handleLink = () => {
    dispatch(hideModal())
    history.push('settings/backoffice-templates/ordersExportTemplateTab')
  }

  const handleLoading = (isLoading) => {
    setIsLoading(isLoading)
  }

  return (
    <Box px={5} pb={6}>
      <Box>
        <Typography variant="h3" color="primary">
          {t('backoffice.export.buttonTitle')}
        </Typography>
        <Typography>{t('settings.backofficeTemplates.templateSelectorModal.subtitle')}</Typography>
        <Box mt={2}>
          <Typography color="textPrimary" variant="basic">
            <Trans i18nKey="settings.backofficeTemplates.templateSelectorModal.noTemplates">
              <Link variant="link" onClick={handleLink}></Link>
            </Trans>
          </Typography>
        </Box>

        <Box mt={2}>
          <TemplateSelector templateType={templateType} onTemplateChange={handleTemplateChange} onLoadingChange={handleLoading} />
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="secondary" disabled={isLoading} onClick={handleExport}>
            {t('backoffice.export.buttonTitle')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ExportTemplateSelector
