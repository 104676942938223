import * as actionTypes from './action-types'

export const setOrders = (orders) => ({
  type: actionTypes.SET_ORDERS,
  payload: orders,
})

export const clearOrderId = () => ({
  type: actionTypes.CLEAR_ORDERS,
})

export const setOrderId = (orderId) => ({
  type: actionTypes.SET_ORDER_ID,
  payload: orderId,
})

export const clearOrder = () => ({
  type: actionTypes.CLEAR_ORDER_ID,
})

export const setOrderLinesIds = (orderLinesIds) => ({
  type: actionTypes.SET_ORDER_LINES_IDS,
  payload: orderLinesIds,
})

export const clearOrderLinesIds = () => ({
  type: actionTypes.CLEAR_ORDER_LINES_IDS,
})

export const setOrderTechnicalInfosIds = (orderTechnicalInfosIds) => ({
  type: actionTypes.SET_ORDER_TECHNICAL_INFOS_IDS,
  payload: orderTechnicalInfosIds,
})

export const clearOrderTechnicalInfosIds = () => ({
  type: actionTypes.CLEAR_ORDER_TECHNICAL_INFOS_IDS,
})

export const setOrderPricingCalculationIds = (pricingCalculationIds) => ({
  type: actionTypes.SET_ORDER_PRICING_CALCULATION_IDS,
  payload: pricingCalculationIds,
})

export const clearOrderPricingCalculationIds = () => ({
  type: actionTypes.CLEAR_ORDER_PRICING_CALCULATION_IDS,
})

export const setCRMId = (CRMId) => ({
  type: actionTypes.SET_CRM_ID,
  payload: CRMId,
})

export const resetCRMId = () => ({
  type: actionTypes.RESET_CRM_ID,
})

export const setGuidelineDate = (guidelineDate) => ({
  type: actionTypes.SET_GUIDELINE_DATE,
  payload: guidelineDate,
})

export const clearGuidelineDate = () => ({
  type: actionTypes.CLEAR_GUIDELINE_DATE,
})

export const setSignatureDate = (signatureDate) => ({
  type: actionTypes.SET_SIGNATURE_DATE,
  payload: signatureDate,
})

export const clearSignatureDate = () => ({
  type: actionTypes.CLEAR_SIGNATURE_DATE,
})

export const setLocutionData = (locutionData, isLocution) => ({
  type: actionTypes.SET_LOCUTION_DATA,
  payload: {
    locutionData,
    isLocution,
  },
})

export const resetLocutionData = () => ({
  type: actionTypes.RESET_LOCUTION_DATA,
})

export const setGdprSent = () => ({
  type: actionTypes.SET_GDPR_SENT,
  payload: true,
})

export const clearGdprSent = () => ({
  type: actionTypes.CLEAR_GDPR_SENT,
})

export const setScoringSent = () => ({
  type: actionTypes.SET_SCORING_SENT,
  payload: true,
})

export const clearScoringSent = () => ({
  type: actionTypes.CLEAR_SCORING_SENT,
})

export const startSign = (payload) => ({
  type: actionTypes.START_SIGN,
  payload,
})

export const successSign = () => ({
  type: actionTypes.SUCCESS_SIGN,
})

export const errorSign = () => ({
  type: actionTypes.ERROR_SIGN,
})

export const clearSign = () => ({
  type: actionTypes.CLEAR_SIGN,
})

export const finishLocution = ({ data, resumeLocution, callbackResumeLocution }) => ({
  type: actionTypes.FINISH_LOCUTION,
  payload: { data, resumeLocution, callbackResumeLocution },
})

export const cancelLocution = () => ({
  type: actionTypes.CANCEL_LOCUTION,
})

export const startForwardSms = (leadId) => ({
  type: actionTypes.START_FORWARD_SMS,
  payload: leadId,
})

export const successForwardSms = () => ({
  type: actionTypes.SUCCESS_FORWARD_SMS,
})

export const errorForwardSms = () => ({
  type: actionTypes.ERROR_FORWARD_SMS,
})

export const clearForwardSms = () => ({
  type: actionTypes.CLEAR_FORWARD_SMS,
})

export const attachDocument = (payload) => ({
  type: actionTypes.ATTACH_DOCUMENT,
  payload,
})

export const startAttachDoc = (payload) => ({
  type: actionTypes.START_ATTACH_DOC,
  payload,
})

export const successAttachDoc = () => ({
  type: actionTypes.SUCCESS_ATTACH_DOC,
})

export const errorAttachDoc = () => ({
  type: actionTypes.ERROR_ATTACH_DOC,
})

export const clearAttachDoc = () => ({
  type: actionTypes.CLEAR_ATTACH_DOC,
})
