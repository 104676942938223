import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import IBAN from 'iban'

import { showModal } from 'services/modal/actions'
import {
  setBillingAccount,
  setBillingAddress,
  setBirthdayDate,
  clearBirthdayDate,
  setIsChangeBillingAddress,
  setOnlineInvoice,
  submitBillingStep,
  setDifferentBillingAddress,
} from 'services/customer/actions'
//eslint-disable-next-line
import { setStepCompleted, setStepUncompleted, setActiveStepById, setActiveStep } from '../services/stepper/actions'
import { setIsResetFunnelContract } from 'services/operator/actions'
import { addToppingToSelection, removeToppings, fetchVirtualBattery, clearVirtualBattery } from 'services/toppings/actions'
import { setProductWithAttribute } from 'services/products/actions'
import { getSelectedRate } from 'services/products/selectors'
import { setGuidelineDate } from 'services/order/actions'
import { fetchLead } from 'services/lead/actions'
import { getSingleToppings, getSelectedSingleToppings } from 'services/toppings/selectors'
import { getIsLeadFetching } from 'services/lead/selectors'
import { isEqualAddress } from 'services/lead/business'
import {
  getIsChangeBillingAddress,
  getNormalizedAddress,
  getSelectedTenure,
  getSupplyAddress,
  getFormData,
  getCustomerData,
  getUserData,
  getIsMultiPoint,
  getBillingAddress,
  getCompany,
  getTenuresInCurrentCompany,
  getBillingAccount,
} from 'services/customer/selectors'
import { getIsOnlineChannel, getIsResetFunnelContract, getReferenceSegment } from 'services/operator/selectors'
import {
  getDraftBillingAddress,
  getDraftSupplyAddress,
  getDraftLead,
  getDuplicateOrderDraft,
  getOnlineChannelDraft,
} from 'services/draftCopy/selectors'
import { getStepStateByProperty } from 'services/stepper/selectors'
import { getIsOnlyToppings } from 'services/products/selectors'
import { getIsFidelizationAllowed } from 'services/fidelization/selectors'
import { setNewAddressServiceChange } from 'services/fidelization/actions'
import { getExistingFide } from 'services/fidelization/api'

import {
  validatePostalCode,
  validateBirthDate,
  validateNumber,
  validateStair,
  validatePortal,
  validateFloor,
  validateDoor,
  validateNie,
  validateNif,
} from 'utils/validators'
import { GAS, ID_DOC_TYPES, PYME, SPF_AGE_LIMITS, STEPS, GA_EVENT_NAMES } from 'utils/constants'
import { MODAL_TYPES } from 'services/modal/constants'
import { cpProvinces } from '@zatopek/core/src/utils/enums'
import { capitalizeFirstLetter } from '@zatopek/core/src/utils/capitalizeFirstLetter'
import { getNormalizedCities, getNormalizedStreets } from 'services/address/api'
import { getVirtualBatteryStatus } from 'services/toppings/selectors'
import { sendGAEvent } from 'services/analytics/actions'
import isEmptyObject from '@zatopek/core/src/utils/isEmptyObject'

import BillingStepView from 'ui/views/billingStep'

import { COMPANY_CODES } from 'utils/constants'

import useStep from '@zatopek/funnel/src/hooks/useStep'

const BillingStep = ({ onContinue }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentStep = useStep({ stepIndex: 4 })
  const selectedProductArray = useSelector(getSelectedRate)
  const selectedProduct = selectedProductArray
  const allToppings = useSelector(getSingleToppings)
  const isOnlineChannel = useSelector(getIsOnlineChannel)
  const isLeadFetching = useSelector(getIsLeadFetching)
  const dataForm = useSelector(getFormData)
  const draftLead = useSelector(getDraftLead)
  const draftBillingAddress = useSelector(getDraftBillingAddress)
  const draftSupplyAddress = useSelector(getDraftSupplyAddress)
  const referenceSegment = useSelector(getReferenceSegment)
  const normalizedAddress = useSelector(getNormalizedAddress)
  const isChangeBillingAddress = useSelector(getIsChangeBillingAddress)
  const selectedTenure = useSelector(getSelectedTenure)
  const isResetFunnelContract = useSelector(getIsResetFunnelContract)
  const stepStateByProperty = useSelector(getStepStateByProperty)
  const supplyAddress = useSelector(getSupplyAddress)
  const customerData = useSelector(getCustomerData)
  const userData = useSelector(getUserData)
  const isMultiPoint = useSelector(getIsMultiPoint)
  const billingAddress = useSelector(getBillingAddress)
  const isOnlyToppings = useSelector(getIsOnlyToppings)
  const onlineChannelDraft = useSelector(getOnlineChannelDraft)
  const selectedToppings = useSelector(getSelectedSingleToppings)
  const isDuplicateOrderDraft = useSelector(getDuplicateOrderDraft)
  const isFidelizationAllowed = useSelector(getIsFidelizationAllowed)
  const tenuresInCurrentCompany = useSelector(getTenuresInCurrentCompany)
  const company = useSelector(getCompany)
  const virtualBattery = useSelector(getVirtualBatteryStatus)
  const billingAccount = useSelector(getBillingAccount)
  const selectedCompany = company || selectedProduct[0]?.company

  const isPyme = referenceSegment === PYME
  const [loading, setLoading] = useState(false)
  const [cities, setCities] = useState([])
  const [streetTypes, setStreetTypes] = useState([])
  const [allStreets, setAllStreets] = useState([])
  const [streetSuggestions, setStreetSuggestions] = useState([])
  const [notFoundAddress, setNotFoundAddress] = useState(false)
  const [cityInputValue, setCityInputValue] = useState('')
  const [differentBillingAddressChecked, setDifferentBillingAddressChecked] = useState(false)

  const hasIban = dataForm.iban.length > 0
  const hasEcoContract = !selectedProduct?.find((product) => product.energyType === GAS) && !isOnlyToppings
  const billingDraftData = onlineChannelDraft?.billingStep
  const hasSpfSelectedTenure = selectedTenure?.services?.some((service) => service.type === 'SPF')

  useEffect(() => {
    if (billingAddress && !isMultiPoint) {
      setDifferentBillingAddressChecked(true)
      dispatch(setDifferentBillingAddress(true))
    } else if (billingAddress && isMultiPoint) {
      dispatch(setStepUncompleted(STEPS.billing))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiPoint])

  useEffect(() => {
    if (isOnlyToppings && !isDuplicateOrderDraft) {
      setDifferentBillingAddressChecked(true)
      dispatch(setDifferentBillingAddress(true))
    }
  }, [isOnlyToppings, isDuplicateOrderDraft, dispatch])

  useEffect(() => {
    if (billingDraftData?.addressDifferentCheck) {
      setDifferentBillingAddressChecked(true)
      dispatch(setDifferentBillingAddress(true))
    }
  }, [billingDraftData?.addressDifferentCheck, dispatch])

  useEffect(() => {
    const isDifferentBillingAddress =
      draftSupplyAddress && draftBillingAddress && !isEqualAddress(draftSupplyAddress, draftBillingAddress)
    setDifferentBillingAddressChecked(!!isDifferentBillingAddress)
  }, [draftSupplyAddress, draftBillingAddress])

  const handleNotFoundAddress = (e, setFieldValue) => {
    e.preventDefault()
    setNotFoundAddress(!notFoundAddress)
    if (setFieldValue) {
      setFieldValue('street', '')
    }
  }

  const setIban = (value) => {
    return value
      .split('')
      .map((digit, index) => (digit === '*' ? dataForm.iban.charAt(index) : digit))
      .join('')
  }

  const hideIban = (value) => {
    const characters = value.split('')

    const countryCode = characters.slice(0, 2).join('')
    const middleDigits = characters.slice(4, 8).join('')
    const lastDigits = characters.slice(8)
    const obscuredLastDigits = lastDigits.map(() => '*').join('')

    return `${countryCode}**${middleDigits}${obscuredLastDigits}`
  }
  const ibanReover = billingDraftData?.accountIban || draftLead?.accountIban
  const dataFormSurnames = dataForm?.lastName ? `${dataForm.lastName} ${dataForm.secondLastName || ''}` : ''

  const initialValues = {
    accountHolder: billingDraftData?.accountHolder || draftLead?.accountHolder || dataForm.name || '',
    accountHolderSurnames: billingDraftData?.accountHolderSurnames || draftLead?.accountHolderSurnames || dataFormSurnames || '',
    accountHolderDocumentType:
      billingDraftData?.accountHolderDocumentType || draftLead?.accountHolderDocumentType || dataForm?.idDocumentType || '',
    accountHolderDocumentNumber:
      billingDraftData?.accountHolderDocumentNumber || draftLead?.accountHolderDocumentNumber || dataForm?.idDocumentNumber || '',
    accountHolderLastName:
      billingDraftData?.accountHolderLastName || draftLead?.accountHolderLastName || dataForm?.lastName || '',
    accountHolderSecondLastName:
      billingDraftData?.accountHolderSecondLastName || draftLead?.accountHolderSecondLastName || dataForm?.secondLastName || '',
    accountIban: billingDraftData?.accountIban || draftLead?.accountIban || (hasIban ? hideIban(dataForm.iban) : ''),
    onlineInvoice:
      ((userData?.email || customerData?.email) && (billingDraftData?.onlineInvoice || draftLead?.onlineInvoice)) || true,
    ecoContract: billingDraftData?.eco || false,
    domiciliationOrder:
      !!billingDraftData?.iban ||
      !!draftLead?.accountIban ||
      (!!selectedTenure && !isEmptyObject(selectedTenure) && !!selectedTenure?.iban),
    billingProtectionService: billingDraftData?.spfService || !!draftLead?.birthdayDate || false,
    billingProtectionServiceConditions: billingDraftData?.spfConditions || !!draftLead?.birthdayDate || false,
    birthdayDate: billingDraftData?.spfBirthdayDate || draftLead?.birthdayDate?.date.substring(0, 10) || '',
    postalCode: billingDraftData?.address?.postalCode || draftBillingAddress?.postalCode || '',
    province: billingDraftData?.address?.province || draftBillingAddress?.provinceName || '',
    city: billingDraftData?.address?.city || draftBillingAddress?.townName || '',
    streetType: billingDraftData?.address?.streetType || draftBillingAddress?.streetType || '',
    street: billingDraftData?.address?.street || draftBillingAddress?.streetName || '',
    number: billingDraftData?.address?.number || draftBillingAddress?.number || '',
    portal: billingDraftData?.address?.portal || draftBillingAddress?.portal || '',
    stair: billingDraftData?.address?.stair || draftBillingAddress?.stair || '',
    floor: billingDraftData?.address?.floor || draftBillingAddress?.floor || '',
    door: billingDraftData?.address?.door || draftBillingAddress?.door || '',
    externalId: billingDraftData?.address?.externalId || draftBillingAddress?.externalId || '',
    extraData: billingDraftData?.address?.extraData || draftBillingAddress?.extraData || '',
    verticalId: billingDraftData?.address?.verticalId || draftBillingAddress?.verticalId || '',
    placeId: billingDraftData?.address?.placeId || draftBillingAddress?.placeId || '',
    isNewAddress: billingDraftData?.address?.isNewAddress || draftBillingAddress?.isNewAddress || false,
  }

  const checkIsDisallowedAddressForServiceChange = async (address) => {
    if (isOnlyToppings && !isFidelizationAllowed) {
      const companyId = company?.id
      const docNumber = userData?.documentNumber || customerData?.idDocumentNumber
      const families = Object.values(selectedToppings ?? {}).map(({ type }) => type)

      setLoading(true)

      try {
        const { existingFide } = await getExistingFide({ companyId, docNumber, families, address })
        let isServiceChange = false
        if (existingFide === 'SI' || (existingFide === 'PS' && isPyme) || (existingFide === 'RS' && !isPyme)) {
          isServiceChange = true
        }

        dispatch(setNewAddressServiceChange(isServiceChange))
        if (isServiceChange) {
          dispatch(
            showModal({
              modalType: MODAL_TYPES.warningModal,
              modalData: {
                titleKey: 'selectionDisallowedInNewDirection',
                textKey: `selectionDisallowedInNewDirection_${company?.code}`,
              },
            })
          )
        }
        return isServiceChange
      } catch {
      } finally {
        setLoading(false)
      }
    }
  }

  const checkIsDisallowedAddressForServiceChangeByTenure = ({
    postalCode,
    number,
    portal,
    stair,
    floor,
    door,
    provinceName,
    townName,
    municipalityName,
    streetName,
  }) => {
    if (isOnlyToppings && !isFidelizationAllowed) {
      const tenureServiceTypes = tenuresInCurrentCompany
        .filter((tenureAddress) =>
          isEqualAddress(
            {
              postalCode: tenureAddress.addressPostalCode,
              provinceName: tenureAddress.addressProvince,
              townName: tenureAddress.addressTown,
              municipalityName: tenureAddress.addressMunicipality,
              streetName: tenureAddress.addressStreet,
              number: tenureAddress.addressNumber,
              portal: tenureAddress.addressPortal,
              stair: tenureAddress.addressStaircase,
              floor: tenureAddress.addressFloor,
              door: tenureAddress.addressDoor,
            },
            {
              postalCode,
              provinceName,
              townName,
              municipalityName,
              streetName,
              number,
              portal,
              stair,
              floor,
              door,
            }
          )
        )
        .flatMap(({ services }) => services)
        .map(({ type }) => type)
      const isServiceChange = Object.values(selectedToppings).some(({ type }) => tenureServiceTypes.includes(type))

      dispatch(setNewAddressServiceChange(isServiceChange))
      if (isServiceChange) {
        dispatch(
          showModal({
            modalType: MODAL_TYPES.warningModal,
            modalData: {
              titleKey: 'selectionDisallowedInNewDirection',
              textKey: `selectionDisallowedInNewDirection_${company?.code}`,
            },
          })
        )
      }
      return isServiceChange
    }
  }

  const handleFormSubmit = async (formData) => {
    const {
      accountHolder,
      accountHolderSurnames,
      accountHolderDocumentType,
      accountHolderDocumentNumber,
      accountHolderLastName,
      accountHolderSecondLastName,
      accountIban,
      onlineInvoice,
      ecoContract,
      billingProtectionService,
      birthdayDate,
      postalCode,
      municipalityCode,
      provinceCode,
      province: provinceId,
      city: cityId,
      street: streetId,
      streetType,
      number,
      portal,
      stair,
      floor,
      door,
      externalId,
      placeId,
      extraData,
      verticalId,
      isNewAddress,
    } = formData

    let iban = hasIban ? setIban(accountIban) : accountIban
    dispatch(setOnlineInvoice(onlineInvoice))

    dispatch(
      setBillingAccount({
        accountHolder,
        accountHolderDocumentNumber,
        accountHolderSurnames,
        accountHolderDocumentType,
        accountHolderLastName,
        accountHolderSecondLastName,
        accountIban: iban,
      })
    )

    if (referenceSegment === PYME) {
      dispatch(setGuidelineDate(new Date()))
    }

    if (isOnlineChannel && referenceSegment === PYME && hasEcoContract) {
      const ecoProduct = selectedProduct.find((product) => product.energyType !== GAS)
      dispatch(
        setProductWithAttribute(ecoProduct, {
          [ecoProduct.id]: { ...ecoProduct.attributes, 'product-eco': ecoContract },
        })
      )
    }

    const provinceName = cpProvinces[provinceId] || provinceId
    const citySelected = cities?.find((city) => city.id === cityId) || cityId
    const townName = citySelected?.name || citySelected
    const municipalityName = citySelected?.name || citySelected
    const streetName = streetSuggestions?.find((street) => street.externalId === streetId)?.name?.toUpperCase() || streetId
    const hasAddress = selectedTenure?.addressPostalCode || normalizedAddress?.postalCode || (postalCode && municipalityName)
    const tenureAddressId = selectedTenure?.addressId || ''
    const addressData = {
      ...formData,
      provinceName,
      townName,
      municipalityName,
      streetName,
      municipalityCode,
      provinceCode,
    }
    const isDisallowedAddressForServiceChange =
      (selectedTenure && !isEmptyObject(selectedTenure)) || tenuresInCurrentCompany.length > 0
        ? checkIsDisallowedAddressForServiceChangeByTenure(addressData)
        : await checkIsDisallowedAddressForServiceChange(addressData)

    if (isDisallowedAddressForServiceChange) {
      return
    }

    try {
      if (hasAddress) {
        dispatch(
          setBillingAddress({
            tenureAddressId: !differentBillingAddressChecked && !isOnlyToppings && tenureAddressId ? tenureAddressId : '',
            externalId: differentBillingAddressChecked
              ? externalId || streetId || ''
              : externalId || streetId || selectedTenure?.addressId || normalizedAddress?.externalId || supplyAddress?.externalId,
            postalCode: differentBillingAddressChecked
              ? postalCode || ''
              : postalCode || selectedTenure?.postalCode || normalizedAddress?.postalCode || supplyAddress?.postalCode || '',
            provinceName: differentBillingAddressChecked
              ? provinceName || ''
              : provinceName ||
                selectedTenure?.provinceName ||
                normalizedAddress?.provinceName ||
                supplyAddress?.provinceName ||
                '',
            municipalityName: differentBillingAddressChecked
              ? municipalityName || townName || ''
              : municipalityName ||
                townName ||
                selectedTenure?.municipalityName ||
                normalizedAddress?.municipalityName ||
                supplyAddress?.municipalityName ||
                '',
            municipalityCode: differentBillingAddressChecked
              ? municipalityCode || ''
              : municipalityCode ||
                selectedTenure?.municipalityCode ||
                normalizedAddress?.municipalityCode ||
                supplyAddress?.municipalityCode ||
                '',
            provinceCode: differentBillingAddressChecked
              ? provinceCode || ''
              : provinceCode ||
                selectedTenure?.provinceCode ||
                normalizedAddress?.provinceCode ||
                supplyAddress?.provinceCode ||
                '',
            townName: differentBillingAddressChecked
              ? townName || ''
              : townName || selectedTenure?.addressTown || normalizedAddress?.townName || supplyAddress?.townName || '',
            streetType:
              streetType ||
              selectedTenure?.streetType ||
              normalizedAddress?.streetType?.toUpperCase() ||
              supplyAddress?.streetType?.toUpperCase(),
            streetName: differentBillingAddressChecked
              ? streetName || ''
              : streetName || selectedTenure?.addressStreet || normalizedAddress?.streetName || supplyAddress?.streetName || '',
            number: differentBillingAddressChecked
              ? number || ''
              : number ||
                selectedTenure?.addressNumber ||
                normalizedAddress?.number?.toString() ||
                supplyAddress?.number?.toString() ||
                '',
            portal: differentBillingAddressChecked
              ? portal || ''
              : portal || selectedTenure?.addressPortal || normalizedAddress?.portal || supplyAddress?.portal || '',
            stair: differentBillingAddressChecked
              ? stair || ''
              : stair || selectedTenure?.addressStaircase || normalizedAddress?.stair || supplyAddress?.stair || '',
            floor: differentBillingAddressChecked
              ? floor || ''
              : floor || selectedTenure?.addressFloor || normalizedAddress?.floor || supplyAddress?.floor || '',
            door: differentBillingAddressChecked
              ? door || ''
              : door || selectedTenure?.addressDoor || normalizedAddress?.door || supplyAddress?.door || '',
            placeId: differentBillingAddressChecked
              ? placeId || ''
              : placeId || selectedTenure?.placeId || normalizedAddress?.placeId || supplyAddress?.placeId || '',
            extraData: differentBillingAddressChecked
              ? extraData || ''
              : extraData || selectedTenure?.extraData || normalizedAddress?.extraData || supplyAddress?.extraData || '',
            masterId: differentBillingAddressChecked
              ? verticalId || ''
              : verticalId || selectedTenure?.masterId || normalizedAddress?.verticalId || supplyAddress?.verticalId || '',
            isNewAddress: differentBillingAddressChecked
              ? isNewAddress || false
              : isNewAddress ||
                selectedTenure?.isNewAddress ||
                normalizedAddress?.isNewAddress ||
                supplyAddress?.isNewAddress ||
                false,
          })
        )
      } else {
        dispatch(setBillingAddress(null))
      }
    } catch (e) {
      console.error(e)
    }

    const spfServiceType = allToppings?.find((topping) => topping?.service?.type === 'SPF')
    const prevSpfSelected = selectedToppings && Object.values(selectedToppings).some((topping) => topping.type === 'SPF')
    if (spfServiceType) {
      if (billingProtectionService) {
        dispatch(addToppingToSelection(spfServiceType.service))
        dispatch(setBirthdayDate(new Date(birthdayDate).toISOString()))
      } else if (prevSpfSelected) {
        dispatch(removeToppings([spfServiceType.id]))
        dispatch(clearBirthdayDate())
      }
    }
    if (company.code === 'GNS' && !supplyAddress && hasAddress && isOnlineChannel) {
      dispatch(fetchVirtualBattery())
    } else if (company?.code === COMPANY_CODES.newco) {
      if (virtualBattery) {
        dispatch(clearVirtualBattery())
      }
    }

    dispatch(setStepCompleted(STEPS.billing))
    dispatch(submitBillingStep()) // TODO: move all submit logic to saga. This is a provisional fix
    dispatch(fetchLead())

    const isOwnerStepCompleted = stepStateByProperty(STEPS.owner, 'isCompleted')
    const isOnlineInvoiceWithoutEmail = onlineInvoice && !customerData?.email && !userData?.email && isOwnerStepCompleted
    if (isOnlineInvoiceWithoutEmail) {
      dispatch(
        showModal({
          modalType: MODAL_TYPES.completeEmail,
          modalData: {
            onNoEmail: () => {
              dispatch(setOnlineInvoice(false))
              formData.onlineInvoice = false
            },
          },
        })
      )
    } else {
      onContinue()
    }
  }

  const handlePostalCodeChange = async (e, setFieldValue) => {
    if (e.target.value && validatePostalCode(e.target.value)) {
      const provinceCode = e.target.value.substring(0, 2)
      const postalCode = e.target.value
      setFieldValue('province', provinceCode, false)

      const cities = await getNormalizedCities(postalCode)
      setCities(cities)
      const streets = await getNormalizedStreets(postalCode)
      setAllStreets(streets)
      if (streets && streets.length) {
        const uniqStreetTypes = [...new Set(streets.map((item) => capitalizeFirstLetter(item.type)))].sort()
        setStreetTypes(uniqStreetTypes)
      }
    } else if (cities.length) {
      setCities([])
      setStreetTypes([])
      setAllStreets([])
      setFieldValue('city', '', false)
      setFieldValue('streetType', '', false)
      setFieldValue('street', '', false)
    }
  }

  const handleSameHolder = (e, setFieldValue) => {
    const isChecked = e
    if (isChecked) {
      const surnames = dataForm?.lastName ? `${dataForm.lastName} ${dataForm.secondLastName || ''}` : ''
      setFieldValue('accountHolder', dataForm.name, false)
      setFieldValue('accountHolderSurnames', surnames, false)
      setFieldValue('accountHolderDocumentType', dataForm.idDocumentType, false)
      setFieldValue('accountHolderDocumentNumber', dataForm.idDocumentNumber, false)
      setFieldValue('accountHolderLastName', dataForm.lastName, false)
      setFieldValue('accountHolderSecondLastName', dataForm.secondLastName, false)
    } else {
      setFieldValue('accountHolder', '', false)
      setFieldValue('accountHolderDocumentType', '', false)
      setFieldValue('accountHolderSurnames', '', false)
      setFieldValue('accountHolderDocumentNumber', '', false)
      setFieldValue('accountHolderLastName', '', false)
      setFieldValue('accountHolderSecondLastName', '', false)
    }
  }

  const handleStreetTypeChange = (streetType) => {
    const filteredStreetSugestions = streetType ? allStreets?.filter((street) => street.type === streetType.toUpperCase()) : []
    setStreetSuggestions(filteredStreetSugestions)
  }

  const handleSepaClick = (e) => {
    e.preventDefault()
    dispatch(sendGAEvent(GA_EVENT_NAMES.clickSEPA, { currentStep: STEPS.billing }))
    dispatch(showModal({ modalType: MODAL_TYPES.sepa }))
  }

  const onChangeBillingAddress = (e) => {
    const isChecked = e.target && e.target.checked
    dispatch(setIsChangeBillingAddress(isChecked))
  }

  const resetBillingAddress = (values, setValues, setFieldValue, setFieldError) => {
    const fields = [
      'postalCode',
      'city',
      'province',
      'streetType',
      'street',
      'number',
      'portal',
      'stair',
      'floor',
      'door',
      'extraData',
    ]
    const fieldValues = {
      postalCode: '',
      city: '',
      province: '',
      streetType: '',
      street: '',
      number: '',
      portal: '',
      stair: '',
      floor: '',
      door: '',
      extraData: '',
    }

    fields.forEach((field) => {
      setFieldValue(field, '', false)
      setFieldError(field, '')
    })

    setValues({ ...values, ...fieldValues })
    setCityInputValue('')
    setCities([])
  }

  const handleInputFields = (values) => {
    const requiredFields =
      !differentBillingAddressChecked && !isOnlyToppings && !isMultiPoint
        ? referenceSegment === PYME
          ? ['accountHolder', 'accountIban', 'domiciliationOrder']
          : [
              'accountHolder',
              'accountIban',
              'domiciliationOrder',
              'accountHolderDocumentType',
              'accountHolderDocumentNumber',
              'accountHolderLastName',
            ]
        : referenceSegment === PYME
        ? [
            'accountHolder',
            'accountIban',
            'domiciliationOrder',
            'postalCode',
            'city',
            'province',
            'streetType',
            'street',
            'number',
          ]
        : [
            'accountHolder',
            'accountHolderDocumentType',
            'accountHolderDocumentNumber',
            'accountHolderLastName',
            'accountIban',
            'domiciliationOrder',
            'postalCode',
            'city',
            'province',
            'streetType',
            'street',
            'number',
          ]

    let fieldsFulled = []
    requiredFields.forEach((field) => {
      if (!values[field]) {
        return
      } else {
        fieldsFulled.push(field)
      }
    })
    return requiredFields?.length === fieldsFulled?.length
  }

  const handleDifferentBillingAddress = (params) => {
    const { e, values, setValues, setFieldValue, setFieldError } = params
    setDifferentBillingAddressChecked(e.target.checked)
    dispatch(setDifferentBillingAddress(e.target.checked))
    if (!e.target.checked) {
      resetBillingAddress(values, setValues, setFieldValue, setFieldError)
    }
    validateForm(values)
  }

  const validateForm = (values) => {
    const requiredFields =
      !differentBillingAddressChecked && !isOnlyToppings && !isMultiPoint
        ? referenceSegment === PYME
          ? ['accountHolder', 'accountIban', 'domiciliationOrder']
          : [
              'accountHolder',
              'accountIban',
              'domiciliationOrder',
              'accountHolderDocumentType',
              'accountHolderDocumentNumber',
              'accountHolderLastName',
            ]
        : referenceSegment === PYME
        ? [
            'accountHolder',
            'accountIban',
            'domiciliationOrder',
            'postalCode',
            'city',
            'province',
            'streetType',
            'street',
            'number',
          ]
        : [
            'accountHolder',
            'accountHolderDocumentType',
            'accountHolderDocumentNumber',
            'accountHolderLastName',
            'accountIban',
            'domiciliationOrder',
            'postalCode',
            'city',
            'province',
            'streetType',
            'street',
            'number',
          ]

    const errors = {}

    const ibanValue = hasIban ? setIban(values.accountIban) : values.accountIban

    if (values.billingProtectionService && !values.birthdayDate) {
      requiredFields.push('birthdayDate')
    }

    if (values.billingProtectionService) {
      if (!values.billingProtectionServiceConditions) {
        errors.billingProtectionServiceConditions = t('form.errors.required')
      }

      if (!errors.birthdayDate && !validateBirthDate(values.birthdayDate, SPF_AGE_LIMITS.min, SPF_AGE_LIMITS.max)) {
        errors.birthdayDate = t('form.errors.spfInvalidDate')
      }
    }
    requiredFields.forEach((field) => {
      if (values[field] === '' || values[field] === false) {
        errors[field] = t('form.errors.required')
      }
    })

    if (
      values.accountHolderDocumentType &&
      ((values.accountHolderDocumentType === ID_DOC_TYPES.nif.value && !validateNif(values.accountHolderDocumentNumber)) ||
        (values.accountHolderDocumentType === ID_DOC_TYPES.nie.value && !validateNie(values.accountHolderDocumentNumber)))
    ) {
      errors.accountHolderDocumentNumber = t('form.errors.invalidFormat')
    }

    if (!errors.accountIban && !IBAN.isValid(ibanValue) && !ibanReover && !billingAccount?.partialAccountIban) {
      errors.accountIban = t('form.errors.invalidFormat')
    }

    if (!normalizedAddress && isSelectSupplyStepCompleted) {
      if (values.postalCode && !validatePostalCode(values.postalCode)) {
        errors.postalCode = t('form.errors.invalidFormat')
      }
    }

    if (values.number && !validateNumber(values.number)) {
      errors.number = t('form.errors.numberFormat')
    }

    if (values.portal && !validatePortal(values.portal)) {
      errors.portal = t('form.errors.portalFormat')
    }

    if (values.stair && !validateStair(values.stair)) {
      errors.stair = t('form.errors.stairFormat')
    }

    if (values.floor && !validateFloor(values.floor)) {
      errors.floor = t('form.errors.floorFormat')
    }

    if (selectedCompany?.code !== COMPANY_CODES.newco && values.door && !validateDoor(values.door)) {
      errors.door = t('form.errors.doorFormat')
    }

    return errors
  }

  const resetBillingStep = (resetForm) => {
    resetForm()
    setCityInputValue('')
    setCities([])
    dispatch(setIsResetFunnelContract(false))
  }

  const isSelectSupplyStepCompleted = stepStateByProperty(STEPS.supply, 'isCompleted')

  return (
    <>
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleFormSubmit} enableReinitialize>
        {({ handleChange, values, errors, touched, setValues, setFieldValue, setFieldError, resetForm }) => (
          <BillingStepView
            onChange={handleChange}
            onPostalCodeChange={handlePostalCodeChange}
            handleSameHolder={handleSameHolder}
            onStreetTypeChange={handleStreetTypeChange}
            onSepaClick={handleSepaClick}
            isDisabled={isLeadFetching || loading || !handleInputFields(values)}
            resetBillingAddress={resetBillingAddress}
            handleNotFoundAddress={(e) => handleNotFoundAddress(e, setFieldValue)}
            onChangeDifferentBillingAddress={handleDifferentBillingAddress}
            isActive={currentStep.isActive}
            ibanReover={ibanReover}
            isOnlyToppings={isOnlyToppings || selectedProduct.length === 0}
            {...{
              isOnlineChannel,
              errors,
              touched,
              setValues,
              setFieldValue,
              setFieldError,
              cityInputValue,
              resetForm,
              cities,
              setCityInputValue,
              streetSuggestions,
              streetTypes,
              values,
              referenceSegment,
              normalizedAddress,
              isChangeBillingAddress,
              onChangeBillingAddress,
              notFoundAddress,
              isResetFunnelContract,
              resetBillingStep,
              isSelectSupplyStepCompleted,
              differentBillingAddressChecked,
              isMultiPoint,
              hasEcoContract,
              isDuplicateOrderDraft,
              hasSpfSelectedTenure,
              userData,
              customerData,
            }}
          />
        )}
      </Formik>
    </>
  )
}

export default BillingStep
