import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogContent, DialogActions, Grid } from '@material-ui/core'
import Typography from 'ui/components/typography'

import useStyles from './styles'

const NoService = ({ handleClose, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const type = 1

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box mb={3}>
          <Typography variant="h3" color="primary">
            {type === 1 ? t('technicalProblems') : null}
            {type === 2 ? t('rateNotAvailable') : null}
            {type === 3 ? t('contactDistributor') : null}
            {type === 4 ? t('serviceNotAvailable') : null}
          </Typography>
        </Box>
        {type === 1 ? (
          <Typography variant="basic">
            <strong>{t('tryAgain')}</strong>
          </Typography>
        ) : null}
        {type === 2 || type === 3 || type === 4 ? (
          <Grid container>
            {type === 2 || type === 3 ? (
              <Grid item xs={12} sm={8}>
                <Box mb={2}>
                  <Button color="secondary" variant="contained">
                    {type === 2 ? t('chooseAnotherRate') : null}
                    {type === 3 ? t('consultDistributor') : null}
                  </Button>
                </Box>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={type === 4 ? 12 : 4}>
              <Typography variant="basic" color="textPrimary">
                <strong>{t('supply.address')}</strong>
              </Typography>
              <Typography variant="basic" color="textPrimary">
                Calle Hortaleza, 28. 3A
                <br />
                39700 - Castro Urdiales
                <br />
                Cantabria
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        <Box mt={3} mb={1}>
          <Typography variant="basic">
            <strong>{type === 4 ? t('doubts') : t('ifYouPrefer')}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="primary.light3">
          <Typography variant="basic" color="textPrimary">
            <strong>{t('common.contactUs')} </strong>
            {t('common.weHelpYou')}
          </Typography>
          <Button color="secondary" variant="outlined" onClick={() => {}}>
            {t('common.callMe')}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t('common.accept')}
        </Button>
      </DialogActions>
    </>
  )
}

export default NoService
